import Grid from "@mui/material/Grid";
import {TextField, Typography} from "@mui/material";
import React from "react";

const Section = ({ title, children }) => (
    <>
        <Grid item xs={12}>
            <Typography style={{ fontSize: '20px', fontWeight: '500', marginBottom: '20px' }}>
                {title}
            </Typography>
        </Grid>
        {children}
    </>
);

// Function to render TextField components
const renderTextField = (label, value, onChange, name) => (
    <TextField
        id="outlined-basic"
        label={label}
        name= {name}
        variant="outlined"
        defaultValue={value}
        onChange={onChange}
        InputLabelProps={{
            shrink: true,
        }}
        fullWidth
    />
);


export {Section, renderTextField}