


function addTrackedResponse(trackedResponseList, successBool, message, action_type, item ){

    trackedResponseList.push({success: successBool, message: message, type: action_type, item: item})

    return trackedResponseList;


}

export default addTrackedResponse;