




const DEFAULT_GPAP_RESPONSE = {
    "took": 0,
    "timed_out": false,
    "_shards": {
        "total": 0,
        "successful": 0,
        "skipped": 0,
        "failed": 0
    },
    "hits": {
        "total": (window.config.extra.es7 ? {"value": 0} : 0),
        "max_score": 0,
        "hits": []
    },
    "aggregations": {}
}

function createDefaultSample(sample_id, settings){

    if(settings.length > 0 && settings[0].gt_settings.length> 0 && settings[0].gt_settings[0]!== undefined){
        let setting_obj = settings[0].gt_settings[0];
        return {
            "sample_id": sample_id,
            "gq": setting_obj.gq,
            "gt": setting_obj.setting,
            "dp": setting_obj.dp,
            "ad_low": setting_obj.ad_low,
            "ad_high": setting_obj.ad_high,
            "index": setting_obj.index
        }
    }
    else{
        // default option;
        return {
            "sample_id": sample_id,
            "gq": 30,
            "gt": ["0/1"],
            "dp": 10,
            "ad_low": 0.2,
            "ad_high": 0.8,
            "index": 1
        }
    }

}




export {DEFAULT_GPAP_RESPONSE, createDefaultSample}