



export function getTotalFromJson(variants){
    let total  = 0;
    let snv_total = 0 ;
    let cnv_total = 0
    if(variants !== undefined && variants.snv !== undefined){
        snv_total = ( typeof variants.snv.hits.total === 'object' ) ? variants.snv.hits.total.value : variants.snv.hits.total;
        if(variants.hasOwnProperty("cnv") )
        {
            cnv_total = ( typeof variants.cnv.hits.total === 'object' ) ? variants.cnv.hits.total.value : variants.cnv.hits.total
        }

        if(snv_total === -1){
            snv_total = 0;
        }
        total = snv_total + cnv_total;
    }


    return {
        total:total,
        snv_total:snv_total,
        cnv_total:cnv_total};

}