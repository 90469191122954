import React from "react";
import {DATA_HEADER} from "./data_header";
import CustomTable from "../../../general-table/components/tables/CustomTable";


function Fusions_Table(props){

    return <CustomTable
        url={props.url}
        method= "POST"
        dataSend = {{
            "fields": [],
            "filtered": [
              {
                "id": "Experiment_ID",
                "value": [
                  "SRR866929"
                ]
              }
            ],
            "page": 1,
            "pageSize": 20,
            "sorted": []
          }}  
          
        token={props.token}
        // participant={props.participant}
        locationData="items"
        // classRow= "Fusions"
        customFeatures= {[
            { toggle: false },
            { expanding: false },
            { showHeader: true },
        ]}

        dataHeader={DATA_HEADER}
       
    />


}

export default Fusions_Table
