




const predictors_config = [
    {   predictor:'sift_pred',
        score: 'sift_score',
        outputs:[
            {key: 'D', label: 'Deleterious'},
            {key: 'T', label: 'tolerated'}]
    },
    {predictor:'polyphen2_hvar_pred', score: 'polyphen2_hvar_score',
        outputs:[
            {key: 'D', label: 'Probably damaging'},
            {key: 'P', label: 'Possibly damaging'},
            {key: 'B', label: 'Benign'}
        ]},
    {predictor:'mutationtaster_pred',
        score: 'mt',
        outputs:[
            {key: 'A', label: 'Disease_causing_automatic'},
            {key: 'D', label: 'Disease_causing'},
            {key: 'N', label: 'Probably harmless'},
            {key: 'N', label: 'Known to be harmless'}
        ]},
        {predictor:'Oncogenic',
        score: 'Oncogenic',
        outputs:[
            {key: 'O', label: 'Oncegenic'},
            {key: 'LO', label: 'Likely Oncogenic'},
            {key: 'VUS', label: 'VUS'},
            {key: 'B', label: 'Benign'},
            {key: 'LB', label: 'Likely Benign'}

        ]},
                {predictor:'intervarclasses',
        score: 'intervarclasses',
        outputs:[
            {key: 'B', label: 'Benign'},
            {key: 'LB', label: 'Likely Benign'},
            {key: 'VUS', label: 'VUS'},
            {key: 'P', label: 'Pathogenic'},
            {key: 'LP', label: 'Likely Pathogenic'},

        ]},
];


export default predictors_config;