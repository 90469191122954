import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Stack, TextField, Typography, Select,MenuItem, InputLabel } from "@mui/material";
import { useSelector } from 'react-redux';
import DataField from "./Datafield";
import InputBase from '@mui/material/InputBase';
import { GPAP_Button } from '../../../gpap-lib/components/Button_Collection';
import GPAP_Dialog from '../../../gpap-lib/components/GPAP_Dialog';
import MappedText from '../../study-page/create-study/analysis-page/components/clinical-info/mapText';
import { dir } from 'i18next';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

    export default function DIR_Overview() {
        const location = useLocation();
        const hideFooter = !location.pathname.includes("/new_clinical_report");
        const token = useSelector(state => state.authorization.token);
        const {allDataApis, allState  } = useSelector((state) => state.generaltable);
        const [selectedStatus, setSelectedStatus] = useState('');
        const [sampleQualityObservations, setSampleQualityObservations] = useState('');
        const [sequencingQualityObservations, setSequencingQualityObservations] = useState('');
        const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);
        const [openDialog, setDialog] = useState({
            status: false,
            title: "",
            text: "",
            open: false,
            type: "success"
        });

        const [formValues, setFormValues] = useState({
            filter_set: "",
            predefined_list: "",
        });

        const [filterSetList, setFilterSetList] = useState([]);
        const [predefinedGeneList, setPredefindedGeneList] = useState([]);

        useEffect(() => {
            if (selectedDir) {
                setSelectedStatus(selectedDir.status || '');
                setSampleQualityObservations(selectedDir.sample_quality_observations || '');
                setSequencingQualityObservations(selectedDir.sequencing_quality_observations || '');
            }
        }, [selectedDir]);

        const handleChange = (event) => {
            setSelectedStatus(event.target.value);
        };

        const uniqueStatus = Array.from(new Set(allDataApis?.dir?.map(item => item.status)));

        // Query possible values for filterSetList and Predefined Gene List
        useEffect(() => {
            // Get Filter Set Options
            const url = window.config.apis_configuration.api_nextgpap_server;
            fetch(url+ '/filter_set', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', "Authorization": token}
            })
            .then((response) => response.json())
            .then((data) => {
                setFilterSetList(data.data.filter(s => s.filter_set_name!=="").map(fs => ({name: fs.filter_set_name, id: fs.id})));
            })
            .catch((error) => {
                setDialog({
                    status: false, open: true, type: "error",
                    title: "Could not get filter set list",
                    text: "Broken connection to back-end API. Please try again later."
                });
            });
        }, []);

        // After Having the filter set loaded then require the gene list
        useEffect(() => {
            const url = window.config.apis_configuration.api_nextgpap_server;
            fetch( url + '/get_all_lists', {
                method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": token }
            })
            .then((response) => response.json())
            .then((data) => {
                setPredefindedGeneList(data.data.map(gl => ({name: gl.title, id: gl.id})));
            })
            .catch((error) => {
                setDialog({
                    status: false, open: true, type: "error",
                    title: "Could not get predefined gene lists",
                    text: "Broken connection to back-end API. Please try again later.",
                });
            });
            // Initialize Filter Set and Predefined List
            setFormValues({
                filter_set: allDataApis?.dirs?.filter_set, 
                predefined_list : allDataApis?.dirs?.predefined_list 
            });
        }, [filterSetList]);
        

        
        const handleInputChange = (event) => {
            const { name, value } = event.target;
            if (name === "sampleQualityObservations") {
                setSampleQualityObservations(value);
            } else if (name === "sequencingQualityObservations") {
                setSequencingQualityObservations(value);
            }
            else {
                setFormValues({ ...formValues, [name]: value });
            }
        };
        

        const updateDir = () => {
            const payload = { ...formValues };
            const dirId = allDataApis?.dirs?.dir_id;
            const url = window.config.apis_configuration.api_nextgpap_server;
            fetch( url+ '/dir/' + dirId, {
                method: 'PUT', headers: { 'Content-Type': 'application/json', "Authorization": token },
                body: JSON.stringify(payload),
            })
            .then((response) => response.json())
            .then((data) => {
                setDialog({ status: true, open: true, type: "success",
                    title: "DIR " +  dirId +  " updated successfully",
                    text: "Dir " + dirId + " has been properly updated."
                });
            })
            .catch((error) => {
                console.error('Error:', error);
                setDialog({ status: false, open: true, type: "error",
                    title: "Error updating DIR",
                    text: "Backend connection broken:",
                });
            });
        };
        const handleSave = async () => {
            let payload = {
                status: selectedStatus,
                sample_quality_observations: sampleQualityObservations,
                sequencing_quality_observations: sequencingQualityObservations,
            };
        
            const analysisType = selectedDir?.analysis;
            if (analysisType === "germline") {
                payload = {
                    ...payload,
                    control_experiment_id: selectedDir?.control_experiment_id || "",
                    tumor_experiment_id: "",
                };
            } else if (analysisType === "somatic_tumor_only" || analysisType === "somatic_fusion_analysis") {
                payload = {
                    ...payload,
                    control_experiment_id: "",
                    tumor_experiment_id: selectedDir?.tumor_experiment_id || "",
                };
            } else if (analysisType === "somatic_tumor_normal") {
                payload = {
                    ...payload,
                    control_experiment_id: selectedDir?.control_experiment_id || "",
                    tumor_experiment_id: selectedDir?.tumor_experiment_id || "",
                };
            }
        
            const dirId = selectedDir?.dir_id;
            const url = window.config.apis_configuration.api_nextgpap_server;
            try {
                const response = await axios.put(`${url}/dir/${dirId}`, payload, {
                    headers: { 
                        'Content-Type': 'application/json', 
                        'Authorization': token 
                    }
                });
        
                console.log('Server response:', response.data);
        
                if (response.status === 200 || response.status === 204) {
                    setDialog({
                        status: true,
                        open: true,
                        type: "success",
                        title: "DIR updated successfully",
                        text: "The DIR has been updated successfully."
                    });
        
                    setSampleQualityObservations(payload.sample_quality_observations);
                    setSequencingQualityObservations(payload.sequencing_quality_observations);
        
                } else {
                    throw new Error('Failed to update DIR');
                }
            } catch (error) {
                console.error('Error:', error);
                setDialog({
                    status: false,
                    open: true,
                    type: "error",
                    title: "Error updating DIR",
                    text: "An error occurred while updating the DIR."
                });
            }
        };
        
        
        const analysisType = useSelector(state => state.generaltable.allDataApis?.dirs?.analysis);
        // const dirData = jsonData;
        
        const handleCloseDialog = () => { setDialog({ status: false, title: "", text: "", open: false,type: "success"});}

        const renderDialog = () => {
            return <GPAP_Dialog
                open={openDialog.open}
                handleClose={handleCloseDialog}
                title={openDialog.title}
                text={openDialog.text}
                onExitText={false}
                onConfirmText={"Ok"}
                onExitAction={false}
                onConfirmAction={handleCloseDialog}
                type={openDialog.type}
            />
        }

        return (    
            <Box sx={{ flexGrow: 1, p: 2 }}>
            {renderDialog()}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Box>
                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"General Info"}/></Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={4} md={2}>
                        <DataField title="Request ID" value={allDataApis?.dirs?.dir_id || "NA"} />
                        </Grid>
                        {selectedDir && (
                        <Grid item xs={12} sm={4} md={2}>
                                <DataField title="Comments" value={selectedDir?.comments || "NA"} />
                        </Grid>
                        )}
                        <Grid item xs={12} sm={4} md={8}>
                            <DataField title="Analysis Type" value={""}/>
                            <Chip label={
                            allDataApis?.dirs?.analysis === 'somatic_fusion_analysis' ? "Somatic fusion analysis" :
                            allDataApis?.dirs?.analysis === 'somatic_tumor_only' ? "Somatic tumor only" :
                            allDataApis?.dirs?.analysis === 'somatic_tumor_normal' ? "Somatic tumor normal" :
                            allDataApis?.dirs?.analysis === 'germline' ? "Germline" :
                            allDataApis?.dirs?.analysis || "NA"
                        } style={{ backgroundColor: "#3E69A9", color:"#FFFFFF"}}/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={2}>
                        <InputLabel>Status</InputLabel>
                        <Select fullWidth sx={{ fontSize: '16px' }} value={selectedStatus} onChange={handleChange}>
                            {uniqueStatus.map((status, index) => (
                                <MenuItem key={index} value={status}>
                                    {status}
                                </MenuItem>
                             ))}
                        </Select>
                     

                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={2}>
                        <DataField title="Priority" value={allDataApis?.dirs?.priority || "NA"} />
                        </Grid> 
                        <Grid item  xs={12} sm={6} md={2}>
                                <DataField title="Deadline" value={allDataApis?.dirs?.deadline || "NA"} />
                              
                            </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Assignee(s)" value={allDataApis?.dirs?.assignee || "NA"} />
                        </Grid>
                        </Grid>

                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Administrative Info"}/></Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}   >
                            <DataField title="Creator" value={allDataApis?.dirs?.creator || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Creation date" value={allDataApis?.dirs?.creation_date || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Hospital name" value={allDataApis?.dirs?.hospital_name || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Clinical referrer" value={allDataApis?.dirs?.clinical_referrer || "NA"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DataField title="Clinical referrer contact info" value={allDataApis?.dirs?.clinical_referrer_contact || "NA"} />
                        </Grid>
                        </Grid>

                        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>Filters</Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                            <Grid item xs={12} sm={6} md={4}   >
                                <InputLabel>Filter Set</InputLabel>
                                <Select
                                    fullWidth name="filter_set"
                                    value={formValues.filter_set}
                                    onChange={handleInputChange}>
                                    {filterSetList.map(fs =>  <MenuItem value={fs.id}>{fs.name}</MenuItem>)}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel>Gene List</InputLabel>
                                <Select
                                    fullWidth name="predefined_list"
                                    value={formValues.predefined_list}
                                    onChange={handleInputChange}>
                                    {predefinedGeneList.map(gl =>  <MenuItem value={gl.id}>{gl.name}</MenuItem>)}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                            <Grid item xs={12} sm={6} md={2}>
                                <GPAP_Button onClick={updateDir} color="primary">
                                    Update Filters
                                </GPAP_Button>
                            </Grid>
                        </Grid>



                        {/* <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: '24px'  }}>Patient Info</Typography> */}

                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Patient Info"}/>
                            </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Patient ID" value={allDataApis?.participants?.[0].report_id || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <DataField title="Sex" value={allDataApis?.participants?.[0].sex || "NA"}  />
                        </Grid>
                        </Grid>

                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Clinical Info"}/>
                            </Typography>
                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Clinical referral" value={allDataApis?.participants?.[0]?.referral || "NA"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <DataField 
                        title="Observed Symptoms" 
                        value={
                            allDataApis?.participants?.[0]?.features
                            ?.filter(feature => feature.observed === true) 
                            .map(feature => feature.name) 
                            .join(", ") || "NA"
                        }
                    /> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Diagnostic date" value={allDataApis?.participants?.[0]?.diagnosis?.[0]?.diagnosis_date || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>  
                            <DataField title="Clinical diagnosis" value={allDataApis?.participants?.[0]?.diagnosis?.[0]?.ordo.name || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Case number pathology" value={allDataApis?.participants?.[0]?.case_number_pathology || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Treatment history and response" value={`${allDataApis?.participants?.[0]?.treatmenthistory}, ${allDataApis?.participants?.[0]?.treatment_response}`} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Cancer type" value={allDataApis?.participants?.[0]?.cancer_type || "NA"} />
                        </Grid>
                        </Grid>
                        {/*Tumor Sample */}
                        {analysisType !== "germline" && (
                            <>
                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Tumor Sample & Experiment Info"}/>       
                            </Typography>
                        <Typography 
                        variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Sample Info"}/>
                            </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Sample ID" value={allDataApis?.samples?.sampleID || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Sample site of origin" value={allDataApis?.samples?.sample_tissue_of_origin || "NA"} />
                            
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Sample site of origin status" value={allDataApis?.samples?.sample_tissue_of_origin_status || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Sample fixation" value={allDataApis?.samples?.sample_type || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Purity" value={allDataApis?.samples?.percentage_of_tumoral_cells || "NA"}/>
                            </Grid> 
                            </Grid> 
                            <Typography  variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Experiment Info"}/>
                               </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>

                        {selectedDir && (
                    <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Control Experiment ID" value={selectedDir?.tumor_experiment_id || "NA"} />
                    </Grid>
                )}
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Library strategy" value={allDataApis?.experiments?.[0].library_strategy || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Kit" value={allDataApis?.experiments?.[0].kit || "NA"}  />
                        </Grid>
                        </Grid> 
                        </>
                        )}
                        {/* Control Sample     */}
                      {(analysisType === "germline" || analysisType === "somatic_tumor_normal") && (
                         <>
                        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Control Sample & Experiment Info"}/>
                           </Typography>
                           <Typography variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Sample Info"}/>
                            </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={3}>
                          <DataField title="Sample ID" value={allDataApis?.samples?.sampleID || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Sample site of origin" value={allDataApis?.samples?.sample_tissue_of_origin || "NA"} />
                            
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Sample site of origin status" value={allDataApis?.samples?.sample_tissue_of_origin_status || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Sample fixation" value={allDataApis?.samples?.sample_type || "NA"} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Purity" value={allDataApis?.samples?.percentage_of_tumoral_cells || "NA"}/>
                        
                            </Grid> 
                            </Grid> 
                         
                            <Typography variant={"h6"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                            <MappedText text={"Experiment Info"}/>
                                </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        {selectedDir && (
                    <Grid item xs={12} sm={6} md={3}>
                        <DataField title="Control Experiment ID" value={selectedDir?.control_experiment_id || "NA"} />
                    </Grid>
                )}
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Library strategy" value={allDataApis?.experiments?.[0].library_strategy || "NA"}  />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataField title="Kit" value={allDataApis?.experiments?.[0].kit || "NA"}  />
                        </Grid>
                        </Grid> 
                        </>
                        )}
                      
                       <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
                        <MappedText text={"Relevant Observations"}/>
                            </Typography>

                        <Grid container spacing={2} sx={{ marginBottom: 4}}>
                        <Grid item xs={12} sm={6} md={6}>
                        <TextField 
                            fullWidth 
                            id="standard-multiline-static"
                            label="Sample quality observations"
                            multiline
                            rows={4}
                            name="sampleQualityObservations"
                            value={sampleQualityObservations}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField 
                            fullWidth 
                            id="standard-multiline-static"
                            label="Sequencing quality observations"
                            multiline
                            rows={4}
                            name="sequencingQualityObservations"
                            value={sequencingQualityObservations}
                            onChange={handleInputChange} 
                        />
                        </Grid>
                        </Grid>
                        {hideFooter && (
                        <Paper
                            elevation={3}
                            sx={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: "50px",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                zIndex: 1000
                            }}
                        >
                            <Button
                                type="button"
                                onClick={handleSave} 
                                variant="contained"
                                sx={{ m: 1 }}
                            >
                                <MappedText text="Save" />
                            </Button>
                        </Paper>
                    )}

                       
                </Box>
            </Grid>
            </Grid>
            
        </Box>
        
    );
    }