import React from "react";
import { Grid, Box, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { COVERAGE_GENES } from "../config/coverage_genes.js";


export default function CoverageGenes({data, report_id}) {
        //const token = useSelector(state => state.authorization.token);
    //     const [sectionData, setData] = useState(data)
    
    // useEffect(() => {
    //     if(report_id!== undefined && sectionData.length === data.length){
    //         setData(data)}
    // }, [report_id]);

    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <GeneralTable data={ data }
                              columns={ COVERAGE_GENES} />
                </Box>
            </Box>
        </Grid> 
    );
}
