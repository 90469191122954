import FlagIcon from '@material-ui/icons/Flag';
import React from "react";

export function getTreatmentAnnotationIcon(row, participant) {
    const { hgvs_p, gene_name } = row.values;

    if (!Object.isExtensible(participant)) {
        participant = { ...participant };
      }

    //participant.tagged_treatments = [{"gene":"BRCA", "variant":"p.Pro278Thr", "type":"prognosis", "evidence": "B","treatment":"tamoxifen","interpretation":"sarpeilips cancer response"},
    //{"gene":"POLE", "variant":"p.Glu961stop", "type":"prognosis", "evidence": "B","treatment":"tamoxifen","interpretation":"cancer response"}]
  
    // Check if tagged_treatments array exists in participant
    if (participant.tagged_treatments) {
      // Iterate over each tagged treatment
      for (const treatment of participant.tagged_treatments) {
        // Check if gene_name and hgvs_p match
        if (
          treatment.gene === gene_name &&
          treatment.variant === hgvs_p
        ) {
          // Return the FlagIcon if there is a match
          return <FlagIcon style={{ color: "#3182bd" }} />;
        }
      }
    }
  
    // Return null or an empty string if no match is found
    return null;
  }


export function treatmentFinder(selected, participant){
       const { hgvs_p, gene_name } = selected;

        let found = false;
        let interpretation = ""

        if (!Object.isExtensible(participant)) {
            participant = { ...participant };
        }

        // Check if tagged_treatments array exists in participant
        if (participant.tagged_treatments) {
            // Iterate over each tagged treatment
            for (const treatment of participant.tagged_treatments) {
              // Check if gene_name and hgvs_p match
              if (
                treatment.gene === gene_name &&
                treatment.variant === hgvs_p
              ) {
                found = true
                interpretation = treatment.interpretation
              }
            }
          }

          let found_info = {"found": found, "interpretation":interpretation}

          return found_info
}