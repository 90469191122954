

import React, {useState} from "react";

import AddToQueueIcon from '@material-ui/icons/AddToQueue';

import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../../../reducers/study_actions";
import * as SidebarActions from "../../../../../../../../components/SideNavigation/sidebar_actions";
import {connect} from "react-redux";
import AddAnalysisDialog from "../../../side-panels/study-store/components/add-analysis-dialog/AddAnalysisDialog";
import Tooltip from '@material-ui/core/Tooltip';
import {GPAP_Button} from "../../../../../../../../gpap-lib/components/Button_Collection";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";




function AddAnalysisButton(props){

    const [analysisOpen, setAnalysisOpen] = useState(false);

    const onClose = () =>{
        setAnalysisOpen(false)
    }

    return <React.Fragment>
        <AddAnalysisDialog
            open={analysisOpen}
            setClose = { onClose }
            queryRunner={props.queryRunner}
            instandGeneticFindingsTab={props.instandGeneticFindingsTab}
        />
        <GPAP_Button
            id={"newAnalysis"}
            style={{margin: "0.5%", float: "left"}}
            onClick={() => setAnalysisOpen(true)}
            startIcon={<AddToQueueIcon/>}><MappedText text={"New Analysis"} /></GPAP_Button>
    </React.Fragment>


}



// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});


export default connect(null, mapDispatchToProps)(AddAnalysisButton);