


function createQuery(localID, analysisID, query, date, extra){

    return {
        "localId":  localID ,
        "name": "query_" + 1,
        "analysis_id": analysisID,
        "filter_set_id": -1,
        "query_name": "query_" + 1,
        "filters": JSON.stringify(query),
        "creationDate": date,
        "outputs": [{"n_variants": -1, "run_date": date }],
        "extra": extra
    }

}


// assign the settings to the cohort samples by taking the ones from ALL_SAMPLES;
function assignSettings(current_samples, samples_to_analyze ){

    let all_samples = [];
    if(current_samples !== undefined){
        all_samples = [...current_samples];
        // be sure that all samples have the parameters;
        let settings_missing = false;
        all_samples.forEach(function(sample){

            if(!sample.hasOwnProperty("dp")){
                sample.dp = 10;
                settings_missing = true
            }
            else if(sample.hasOwnProperty("gq")){
                sample.gq= 30;
                settings_missing = true;
            }
        })
        // alert if something is not correct;
        if(settings_missing){
           // alert("Some samples did not have all required quality parameters configured correctly. Please review them. If problems persist, contact our support: " + window.config.help_email);
        }

    }
    else{
        all_samples = [{sample_id: 'ALL_SAMPLES', gt: [], index: 'yes',ad_high: 1,ad_low: 0.8,dp: 10,gq: 30}];

    }

    if(Array.isArray(samples_to_analyze)){
        let setting = (samples_to_analyze[0].gt_settings!== undefined) ? samples_to_analyze[0].gt_settings[0].setting : samples_to_analyze[0].gt[0].setting
        all_samples.forEach(s => s.gt = setting);
    }


    return all_samples;
}


// prepare the cohort correctly

function prepareCohort  (cohortSamples, samples){

    let setting = [];
    let gq, dp, ad_low, ad_high = false;
    if(samples.length > 0 ){
        setting = samples[0].gt_settings[0].setting;
        gq = samples[0].gt_settings[0].gq;
        ad_low = samples[0].gt_settings[0].ad_low;
        ad_high = samples[0].gt_settings[0].ad_high;
        dp = samples[0].gt_settings[0].dp;
    }
    cohortSamples.forEach(c => {
        c.gt[0].setting = setting
        c.gt[0].gq = gq;
        c.gt[0].ad_low = ad_low
        c.gt[0].ad_high=  ad_high;
        c.gt[0].dp = dp
    });

    return cohortSamples;

}




export {createQuery, prepareCohort, assignSettings}