import React, { useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {GPAP_Button} from "../../gpap-lib/components/Button_Collection";
import {connect} from "react-redux";

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {getGenesEnsembl, getHPO_terms, getMatchableParticipants, getParticipant, mme_query} from "../../services/api/get";
import MMEResultsTable from "./MatchMakerResults";

import GeneralAutoComplete
    from "../../scenes/dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/gene-section/components/GeneralAutoComplete";
import {handleResponse} from "../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/gene-section/components/async-selection/handlers";
import Box from "@material-ui/core/Box";
import {AlertInfo} from "../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import {inheritance_dict, onset_dict} from "../../config-files/matchmaker_config";


const useStyles = makeStyles({
    root: {
      textAlign: 'left'
    },

    accordion:{
      textAlign: 'left',
      display:'inherit'
    },

    loading:{
      padding: '0px 350px'
    },

    list:{
        position: 'relative',
        overflow: 'auto',
        maxHeight: 200
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });



function MatchMakerPage(props){

    const classes = useStyles();

    const gene_typeahead = useRef(null);
    const hpo_typeahead = useRef(null);

    let {api_phenostore} = config.apis_configuration;

    const [resultsLoading, setResultsLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState(null);
    const [requestInfo, setRequestInfo] = useState(null);

    const [loadingOptions, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [options, setOptions] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState('panel1');
    const loading = open && options.length === 0;

    const [permissionView, setPermissionView] = useState(null)
    const [participantId, setParticipantId] = useState(null)
    const [experimentId, setExperimentId] = useState(null)
    const [participantData, setParticipantData] = useState(null)
    const [radioValue, setRadioValue] = React.useState('rd');

    const [mmeResults, setmmeResults] = React.useState([]);
    const [displayTable, setDisplayTable] = React.useState(false);
    const [dataMail, setDataMail] = React.useState({});

    const [currentHPOs, setCurrentHPOs] = React.useState([])
    const [currentGenes, setCurrentGenes] = React.useState([])

    //nEW STUFF
    const [hpo_search, setHPOs] = useState([]);
    const [hpo_list, setFullList] = useState([]);
    const [hpoSelected, setHPOSelected] = useState([]);

    const [gene_search, setGenes] = useState([]);
    const [gene_list, setGeneList] = useState([]);
    const [geneSelected, setGeneSelected] = useState([]);


    //Current date
    const today = new Date();
    const day = today.getDate();
    const month = today.toLocaleString('default', { month: 'short' });
    const year = today.getFullYear();
    
    const cur_date = day + "-" + month + "-" + year

    //Api calls errors
    function handleErrors(response) {
      if (!response.ok) {
          setResultsLoading(false)
          setErrorMsg("An error occured")
          throw Error(response.statusText);
      }
      return response;
    }

    //Clear async autocomplete values
    const clearAsyncInput = () => {
      if (gene_typeahead.current) 
        gene_typeahead.current.clear()
      
      if (hpo_typeahead.current)
        hpo_typeahead.current.clear()            
    }
  

    //Get all matchable participants
    useEffect(function(){
        if(!loaded){
            setLoading(true);
            getMatchableParticipants(api_phenostore, props.token)
                .then(resp =>{
                        if(resp.ok){
                            return resp.json()
                        }
                        else{
                            setLoading(false);
                            throw new Error('Something went wrong');
                        }
                }
                   )
                .then(json => {

                    if(json.results){
                        setLoaded(true);
                        setOptions(json.results);
                        setLoading(false);

                    }
                })
        }

    },[]);

     //Target endpoint
     const handleRadioChange = (event) => {
      setRadioValue(event.target.value);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
  

    const handleAddHPO = (hpo) => {
      if (hpo.length > 0 ) {
  
        let current_ids = currentHPOs.map(s => s.id);
  
        hpo.forEach(function(el){
            // if not in the list, includes...
            if ((el!==undefined) && (el.refCode!==undefined) && (!current_ids.includes(el.refCode))){
                let newElement = {"id": el.refCode, "label": el.value, "type": "phenotype", "observed": "yes", "severity": []};
                setCurrentHPOs(currentHPOs => [...currentHPOs, newElement]);
            }
  
        })
  
  
      }
    };

   
    const handleAddGene = (gene) => {
      if (gene.length > 0 ) {
  
        let current_ids = currentGenes.map(s => s.label);
  
        gene.forEach(function(el){
            // if not in the list, includes...
            if ((el!==undefined) && (el.label!==undefined) && (!current_ids.includes(el.label))){
                let newElement = {"label":el.label, "value":el.label};
                setCurrentGenes(currentGenes => [...currentGenes, newElement]);
            }
  
        })
  
      }
    };

   
    //MME request
    const handleSubmit = () => {
      setExpanded(false);
      setResultsLoading(true);
      setDisplayTable(false);
      setErrorMsg(null);

      let participant = participantData.report_id;
      let inheritance = participantData.inheritance.map(item => item).toString();
      let onset = participantData.onset;

      let hpos  = currentHPOs.map(item => item.id).toString();
      let genes = currentGenes.map(item => item.label).toString();

      let par_data = {"gene":genes,"hpo":hpos,"ageonset":onset,"modeinheritance":inheritance,"target":radioValue,"participant": participant, "sample":experimentId}
      
      setDataMail(par_data)
      
      if (currentHPOs.length === 0 && currentGenes.length === 0){
         setErrorMsg("No genes or HPO terms are selected")
         setResultsLoading(false);

      }

      else{
        mme_query(config.api_endpoint, props.token, par_data)
        .then(handleErrors)
        .then(resp => resp.json())
        .then(json => {

            if(json.message || json.error){
                setErrorMsg("Request Failed")
                setResultsLoading(false);
                setmmeResults([]);
            }
            else{
                if(json.results){
                    setResultsLoading(false)
                    //Remove "yourself" from results
                    let _self_pat = json.results.find(o => o.patient.id === participantId);
                    let _index_self_pat = json.results.indexOf(_self_pat)
                    if (_index_self_pat > -1)
                        json.results.splice(_index_self_pat,1)

                    setmmeResults(json.results)
                    setDisplayTable(true)
                    setRequestInfo(null);
                }
            }
        })
     }


    };

    
    //Autocomplete value
    const handleChange = (option,par) => {
  
        setExpanded('panel1')

        let participant_id = par.Participant_ID;
        let experiment_id  = par.RD_Connect_ID_Experiment.toString();

        if (participant_id){
          getParticipant(api_phenostore, props.token, participant_id)
            .then(resp => resp.json())
            .then(json => {

                if(!json.message){
                    setParticipantData(json)

                    if (json.features)
                     setCurrentHPOs(json.features.filter(item => item.observed === true).map(item => ( { label: item.name, id:item.id } )))
                    
                    else
                     setCurrentHPOs([])

                    if (json.genes)
                     setCurrentGenes(json.genes.filter(item => item.status === "Causative" || item.status === "Candidate").map(item => ( { label: item.gene.name, value: item.gene.name } )))
                    
                    else{
                        setCurrentGenes([])
                    }


                    setErrorMsg(null)
                    setDisplayTable(false)
                    setPermissionView(true)
                }

                else {
                    setPermissionView(false)
                }
                setParticipantId(participant_id)
                setExperimentId(experiment_id)
            })

            clearAsyncInput()
        }      
      };

   const getHPOResults = (s) => {
        if(s!== ""){
            getHPO_terms(config.apis_configuration.api_phenostore, props.token, s).then(r => r.json()).then(json => {
                const resp = handleResponse("HPO", json);
                setHPOs(resp);
                setFullList(json.terms);
            })
        }
    
    };

    const getGeneResults = (s) => {
      if(s!== ""){
        getGenesEnsembl(s).then(r => r.json()).then(json => {
              const resp = handleResponse("genes", json);
              setGenes(resp);
              setGeneList(resp);
          })
      }
  
  };
    
    const hpo_passSelected = (obj, reason) => {
    
        // get the hpo item and add it;
        //let hpo_to_add_array = obj.map(function(hpoSelected){ return hpo_list.find(s => s.id === hpoSelected.refCode);});
        //handleAddHPO(hpo_to_add_array);

        handleAddHPO(obj);

        if(reason === "clear"){
            setHPOSelected([])
            setCurrentHPOs([])
        }
        else{
            setHPOSelected(obj)
        }
        
        //When removing an HPO 
        if(reason === "remove-option"){
          setCurrentHPOs(obj)
        }
    
    };


    const gene_passSelected = (obj, reason) => {
    
      // get the hpo item and add it;
      //let gene_to_add_array = obj.map(function(geneSelected){ return gene_list.find(s => s.label === geneSelected.label);});
      //handleAddGene(gene_to_add_array);

      handleAddGene(obj)

      if(reason === "clear"){
        setGeneSelected([])
        setCurrentGenes([])
      }
      else{
        setGeneSelected(obj)
      }
      
      //When removing an HPO 
      if(reason === "remove-option"){
        setCurrentGenes(obj)
      }
  
  };


    //Render
    const renderView = () => {

            return <div className={"mme_home"}>
               <Grid container
                     direction="column"
                     justifyContent="center"
                     alignItems="center">
                <Grid item lg={12} alignItems={"center"}>
                    <Typography variant="h4" align={"center"} >
                        Patient Matchmaking
                    </Typography>
                    <Typography
                        align={"center"}
                        className={classes.title} color="textSecondary"  style={{marginBottom:"40px"}}>
                        MatchMaker Exchange facilitates the matching of cases with similar phenotypic and genotypic profiles
                    </Typography>
                </Grid>
                   <Grid item lg={6}>
                       <div className = "whiteContainer" >
                       <Box p={1}>
                           { (requestInfo !== null) ? <AlertInfo severity={"warning"} title={requestInfo}/> : null}
                           <Autocomplete
                               id="asynchronous-demo"
                               style={{ width: 300 }}
                               open={open}
                               onOpen={() => {
                                   setOpen(true);
                               }}
                               onClose={() => {
                                   setOpen(false);
                               }}
                               getOptionSelected={(option, value) => option === value}
                               getOptionLabel={(option) => option.Participant_ID}
                               onChange = {(option,value) => handleChange(option,value)}
                               options={options}
                               loading={loading}
                               renderInput={(params) => (
                                   <TextField
                                       {...params}
                                       label="Search for a Participant ID"
                                       variant="outlined"
                                       InputProps={{
                                           ...params.InputProps,
                                           endAdornment: (
                                               <React.Fragment>
                                                   {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                   {params.InputProps.endAdornment}
                                               </React.Fragment>
                                           ),
                                       }}
                                   />
                               )}
                           />
                       </Box>
                       </div>
                   </Grid>
                <Grid item lg={6} alignItems={"center"}>
                    <div className = "whiteContainer" >

                        <Box p={2}>
                            {(loadingOptions)
                                ? <React.Fragment>
                                    <CircularProgress/>
                                    <Typography variant={"subtitle1"}>Collecting patients with MatchMaker consent</Typography>
                                </React.Fragment>
                                :
                                <Typography variant={"subtitle1"}>There are <strong>{options.length}</strong> patients with Matchmaker consent in
                                    the database</Typography>
                            }
                        </Box>



                        {!permissionView && participantId &&
                        <div> Not authorized to see the participant details</div>
                        }


                        {permissionView &&

                        <Box p={2}>
                            <GPAP_Button onClick={handleSubmit}> Submit </GPAP_Button>

                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Target endpoint
                            </Typography>

                            <FormControl style= {{marginTop:''}} component="fieldset">
                                <RadioGroup aria-label="date" name="date1" value={radioValue} onChange={handleRadioChange} row>
                                    <FormControlLabel value="internal" control={<Radio />} label="RD-Connect" />
                                    <FormControlLabel value="pc" control={<Radio />} label="PhenomeCentral" />
                                    <FormControlLabel value="dc" control={<Radio />} label="Decipher" />
                                    <FormControlLabel value="gm" control={<Radio />} label="GeneMatcher" />
                                    <FormControlLabel value="mg2" control={<Radio />} label="MyGene2" />
                                    <FormControlLabel value="broad" control={<Radio />} label="Broad" />
                                </RadioGroup>
                            </FormControl>

                            {errorMsg &&
                            <Box p={2} style={{width: "400px"}}>
                                <AlertInfo severity={"error"} message={errorMsg} title={"Error"}/>
                            </Box>
                            }

                            <Accordion style={{width: "100%"}} expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Request info</Typography>
                                </AccordionSummary>

                                <AccordionDetails className={classes.accordion}>

                                    <Typography className={classes.title} color="textSecondary" component="h2" gutterBottom>
                                        Organisation Requesting Match
                                    </Typography>
                                    <Typography variant="h6" >
                                        RD-Connect
                                    </Typography>

                                    <br></br>

                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Date
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        {cur_date}
                                    </Typography>

                                    <br></br>


                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Global age of onset
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        {onset_dict[participantData.onset]}
                                    </Typography>

                                    <br></br>


                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Mode of inheritance
                                    </Typography>


                                    {participantData.inheritance.length === 0 &&
                                    <Typography variant="h6" component="h2">
                                        Unknown
                                    </Typography> }


                                    {participantData.inheritance.map((item) => (
                                        <Typography variant="h6" component="h2">
                                            {inheritance_dict[item]}
                                        </Typography>
                                    ))}

                                    <br></br>

                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Candidate gene(s)
                                    </Typography>

                                    <Grid item lg={12} id="async_gene">

                                        <GeneralAutoComplete
                                            //noOptionsText={"Start your search..."}
                                            results = {gene_search}
                                            onInputChange = {getGeneResults}
                                            title={"Search for genes"}
                                            passSelected = {gene_passSelected}
                                            default_tags = {currentGenes}
                                        />

                                    </Grid>

                                    {currentGenes.length === 0 &&
                                    <Typography variant="h6" component="h2">
                                        None
                                    </Typography> }

                                    <br></br>

                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        HPO term(s)
                                    </Typography>

                                    <Grid item lg={12} id="async_hpo">

                                        <GeneralAutoComplete
                                            //noOptionsText={"Start your search..."}
                                            results = {hpo_search}
                                            onInputChange = {getHPOResults}
                                            title={"Search for HPO terms"}
                                            passSelected = {hpo_passSelected}
                                            default_tags = {currentHPOs}
                                        />

                                    </Grid>

                                    {currentHPOs.length === 0 &&
                                    <Typography variant="h6" component="h2">
                                        None
                                    </Typography> }


                                </AccordionDetails>
                            </Accordion>


                            {resultsLoading &&
                            <div className={classes.loading}>
                                <CircularProgress />
                            </div>
                            }



                            {displayTable &&
                            <MMEResultsTable queryResults={mmeResults} mailInfo={dataMail}> </MMEResultsTable>
                            }

                        </Box>
                        }
                    </div>
                </Grid>

            </Grid>
            </div>
    };

    return renderView();

}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token, studySettings: state.studySettings}
    }
}

export default connect(mapStateToProps)(MatchMakerPage);
