
import React, {useState} from "react";
import {Alert, AlertTitle} from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from '@material-ui/core/Collapse';
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";




function AlertInfo(props){

    const [open, setOpen] = useState(true);

    let severity = (props.message && typeof props.message === "string" && props.message.includes("Error")) ? "error" : props.severity;
    let title = (props.message && typeof props.message === "string" && props.message.includes("Error")) ? "Error" : props.title;


    return   (props.message)
        ?  <div>
            <Collapse in={open}>
                <Alert
                    style={{zIndex:"1000"}}
                    severity= { severity }
                    /*action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }*/
                >
            <AlertTitle>{title}</AlertTitle>
            {props.message}
        </Alert>
            </Collapse>
        </div>
        : null

}

export { AlertInfo }

