import React, { useState } from 'react';
import { IconButton, Popover, List, ListItem, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Box from "@mui/material/Box";
import ReportIcon from '@mui/icons-material/Report';

function TrackedResponsesPopOver({ trackedResponses }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let errors = trackedResponses.filter(s => !s.success);

    return (
        <div style={{float:"left", marginTop: "10px" }}>
            {errors.length > 0 && (
                <IconButton onClick={handleClick}>
                    <ErrorIcon style={{ color: '#f44336' }}/>
                </IconButton>
            )}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {trackedResponses.length > 0 ? (
                    <List>
                        {errors.map((item, index) => (
                            <ListItem key={index}>
                                {!item.success ? (
                                    <ErrorIcon style={{ color: '#f44336' }} />
                                ) : (
                                    <CheckCircleIcon style={{ color: 'green' }} />
                                )}
                                <ListItemText primary={item.item}
                                              secondary={item.message} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box p={1}>Study saved correctly</Box>
                )}
            </Popover>
        </div>
    );
}

export default TrackedResponsesPopOver;
