
const SOMATIC_DATA_HEADER_FULL = [
    {
        header: "Gene Info",
        columns: [
            { nameTable: "Gene", dataSearch: "gene_name", tooltip: "" },

        ],
    },
    {
        header: "Variant Information",
        columns: [
            { nameTable: "indel", dataSearch: "indel", tooltip: "" },
            { nameTable: "Chrom", dataSearch: "chrom", tooltip: "" },
            { nameTable: "ref", dataSearch: "ref", tooltip: "" },
            { nameTable: "alt", dataSearch: "alt", tooltip: "" },
            { nameTable: "pos", dataSearch: "pos", tooltip: "" },
        ],
    },
    {
        header: "Population",
        columns: [
            { nameTable: "freqIntGermline", dataSearch: "freqIntGermline", tooltip: "" },
            { nameTable: "gnomad_an_popmax", dataSearch: "gnomad_an_popmax", tooltip: "" },
            { nameTable: "gnomad_an", dataSearch: "gnomad_an", tooltip: "" },
            { nameTable: "freqIntGermlineNum", dataSearch: "freqIntGermlineNum", tooltip: "" },
            { nameTable: "freqIntGermlineDem", dataSearch: "freqIntGermlineDem", tooltip: "" },
            { nameTable: "gnomad_ac", dataSearch: "gnomad_ac", tooltip: "" },
            { nameTable: "gnomad_af", dataSearch: "gnomad_af", tooltip: "" },
            { nameTable: "gnomad_nhomalt", dataSearch: "gnomad_nhomalt", tooltip: "" },
            { nameTable: "gnomad_ac_popmax", dataSearch: "gnomad_ac_popmax", tooltip: "" },
            { nameTable: "gnomad_af_popmax", dataSearch: "gnomad_af_popmax", tooltip: "" },
        ]
    },
    {
        header: "Predictors",
        columns: [
            { nameTable: "spliceai_dp_al", dataSearch: "spliceai_dp_al", tooltip: "" },
            { nameTable: "sift_score", dataSearch: "sift_score", tooltip: "" },
            { nameTable: "mm_poly_ac", dataSearch: "mm_poly_ac", tooltip: "" },
            { nameTable: "gnomad_faf95_popmax", dataSearch: "gnomad_faf95_popmax", tooltip: "" },
            { nameTable: "spliceai_ds_ag", dataSearch: "spliceai_ds_ag", tooltip: "" },
            { nameTable: "spliceai_dp_dg", dataSearch: "spliceai_dp_dg", tooltip: "" },
            { nameTable: "spliceai_max_ds", dataSearch: "spliceai_max_ds", tooltip: "" },
            { nameTable: "spliceai_ds_al", dataSearch: "spliceai_ds_al", tooltip: "" },
            { nameTable: "spliceai_ds_dl", dataSearch: "spliceai_ds_dl", tooltip: "" },
            { nameTable: "spliceai_dp_ag", dataSearch: "spliceai_dp_ag", tooltip: "" },
            { nameTable: "spliceai_dp_dl", dataSearch: "spliceai_dp_dl", tooltip: "" },

            { nameTable: "spliceai_ds_dg", dataSearch: "spliceai_ds_dg", tooltip: "" },
            { nameTable: "revel_score", dataSearch: "revel_score", tooltip: "" },
        ]
    },
    {
        header: "Additional Information",
        columns: [
            { nameTable: "vep_proc_id.block_idx", dataSearch: "vep_proc_id.block_idx", tooltip: "" },
            { nameTable: "mt", dataSearch: "mt", tooltip: "" },
            { nameTable: "mm_dis_disease", dataSearch: "mm_dis_disease", tooltip: "" },
            { nameTable: "intervar_automated", dataSearch: "intervar_automated", tooltip: "" },
            { nameTable: "mm_dis_disease_status", dataSearch: "mm_dis_disease_status", tooltip: "" },
            { nameTable: "vep_proc_id.part_idx", dataSearch: "vep_proc_id.part_idx", tooltip: "" },
            { nameTable: "intervar_evidence", dataSearch: "intervar_evidence", tooltip: "" },
            { nameTable: "mm_poly_af", dataSearch: "mm_poly_af", tooltip: "" },
        ],
    },
];



export default SOMATIC_DATA_HEADER_FULL;