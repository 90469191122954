




export const STATIC_VARIABLES = {
    SIDEBAR_OPTIONS: ["Query", "cases", "cohorts", "history"],
    SUCCESS_QUERY: "Query Loaded Successfully!",
    SUCCESS_DELETE: "Query deleted successfully",
    SUCCESS_RUN: "SNV Query Run Successfully!",
    FAILED_REQUEST: "Request Failed",
    UNAVAILABLE_SERVER: "The server is currently not available",
    TOO_MANY_RESULTS: "Too Many Results. The query failed.",
    QUERY_CREATION_ERROR: "The query could not be added to the study"
}