import React, { useState, useEffect } from 'react';
import {Grid, Typography} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { SearchSample } from './SearchSample';
import { ControlExperiment } from './ControlExperiment';


export const ExperimentsAssociated = (props) => {

    const [searchByParticipant, setSearchByParticipant] = useState(false);

    const {analysis_type, error_tumor_experiment_id, error_control_experiment_id} = props;
    const {handleSearchExperiment, handleSearchParticipant} = props;
    const {control_experiment_id, tumor_experiment_id} = props;
    const {control_participant_id, tumor_participant_id} = props;

    const handleChangeSearchType = (event) => {        
        setSearchByParticipant(event.target.checked);
    };

    const analysisTypeShowExperiments = () => {

        if (analysis_type === "germline") {
            return(
                <>
                    { searchByParticipant ?
                        <Grid item container>
                            <SearchSample
                                title={"Control Patient ID"}
                                searchExperiment={false}
                                errorMessage={error_control_experiment_id}
                                field={"control_participant_id"}
                                searchText={"Search Participant ID"}
                                sampleType={"germline"}
                                handleSearch={handleSearchParticipant}
                                filterParticipant={false}
                                participantID={control_participant_id}      
                            />
                        </Grid>
                    :
                        null
                    }
                    <SearchSample
                        title={"Control Experiment ID"}
                        searchExperiment={true}
                        errorMessage={error_control_experiment_id}
                        field={"control_experiment_id"}
                        searchText={"Search Experiment ID"}
                        sampleType={"germline"}
                        handleSearch={handleSearchExperiment}
                        filterParticipant={searchByParticipant}
                        participantID={control_participant_id}      
                    />
                </>
            );
        } 
        else if(analysis_type === "somatic_tumor_normal") {
            return(
                <>
                    { searchByParticipant ?
                        <Grid item container>
                            <SearchSample
                                title={"Tumor Patient ID"}
                                searchExperiment={false}
                                errorMessage={error_tumor_experiment_id}
                                field={"tumor_participant_id"}
                                searchText={"Search Participant ID"}
                                sampleType={"somatic"}
                                handleSearch={handleSearchParticipant}
                                filterParticipant={false}
                                participantID={tumor_participant_id}      
                            />
                        </Grid>
                    :
                        null
                    }
                    <SearchSample
                        title={"Tumor Experiment ID"}
                        searchExperiment={true}
                        errorMessage={error_tumor_experiment_id}
                        field={"tumor_experiment_id"}
                        searchText={"Search Experiment ID"}
                        sampleType={"somatic"}
                        handleSearch={handleSearchExperiment}
                        filterParticipant={searchByParticipant}
                        participantID={tumor_participant_id}      
                    />
                    {/* Automatic assignation Control Experiment ID */}
                    <ControlExperiment 
                        title={"Control Experiment ID"}
                        errorMessage={error_control_experiment_id}
                        value={control_experiment_id}
                        id={"control_experiment_id"}
                    />
                </>
            );
        } 
        else if(analysis_type === "somatic_fusion_analysis" || analysis_type === "somatic_tumor_only") {
            return(
                <>
                    { searchByParticipant ?
                        <Grid item container>
                            <SearchSample
                                title={"Tumor Patient ID"}
                                searchExperiment={false}
                                errorMessage={error_tumor_experiment_id}
                                field={"tumor_participant_id"}
                                searchText={"Search Participant ID"}
                                sampleType={"somatic"}
                                handleSearch={handleSearchParticipant}
                                filterParticipant={false}
                                participantID={tumor_participant_id}      
                            />
                        </Grid>
                    :
                        null
                    }
                    <SearchSample
                        title={"Tumor Experiment ID"}
                        searchExperiment={true}
                        errorMessage={error_tumor_experiment_id}
                        field={"tumor_experiment_id"}
                        searchText={"Search Experiment ID"}
                        sampleType={"somatic"}
                        handleSearch={handleSearchExperiment}
                        filterParticipant={searchByParticipant}
                        participantID={tumor_participant_id}      
                    />
                </>
            );
        }
    }

    // Typography weigth
    let weigthExperiment = (searchByParticipant === true ? '300' : '600' );
    let weigthParticipant = (searchByParticipant === true ? '600' : '300' );

    return(
        <>
            <Grid item lg={12}>
                <Typography variant={"h6"}>Experiments</Typography>
                
                
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography sx={{ fontWeight:weigthExperiment }}> Search by Experiment ID </Typography>
                    <Switch checked={searchByParticipant} onChange={handleChangeSearchType}/>
                    <Typography sx={{ fontWeight:weigthParticipant }}>Search by Patient ID</Typography>
                </Stack>
                

                <Typography variant="caption" display="block" gutterBottom>
                    Associate an Experiment ID to the request.
                </Typography>
            </Grid>

            {/*Conditional Experiment Viewer*/}
            { analysisTypeShowExperiments() }
        </>
    );
}
