import React from "react";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";

export const DATA_HEADER = [
    {
        header: <MappedText text="Fusion"/>,
        columns: [
            { filterType: {type: "none"}, nameTable: <MappedText text="Fusioned genes"/>, dataSearch: "FusionName", tooltip: "" },
            { filterType: {type: "none"}, nameTable: <MappedText text="Junction read count"/>, dataSearch: "JunctionReadCount", tooltip: "" },
           
        ],
    },
    {
        header: <MappedText text="Left Gene"/>,
        columns: [
            { filterType: {type: "none"}, nameTable: <MappedText text="Left gene"/>, dataSearch: "LeftGene", tooltip: "" },
            { filterType: {type: "none"}, nameTable: <MappedText text="Left breakpoint"/>, dataSearch: "LeftBreakpoint", tooltip: "" },
        ],
    },
    {
        header: <MappedText text="Right Gene"/>,
        columns: [
            { filterType: {type: "none"}, nameTable: <MappedText text="Right gene"/>, dataSearch: "RightGene", tooltip: "" },
            { filterType: {type: "none"}, nameTable: <MappedText text="Right breakpoint"/>, dataSearch: "RightBreakpoint", tooltip: "" },
        ],
    },
];
