import {AlignmentType, HeadingLevel, Paragraph, TextRun, BorderStyle} from "docx";
import { drawGeneticFindingsTable } from "../write_utils/drawGeneticFindingsTable.js";
import {bodyText, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE, TEXT_FOR_NEGATIVE_VARIANTS} from "../../config/template_config.js";
import {splitSNVandCNV} from "../../../create-report/sections/genetic_findings/utils.js";
import { createSpacer } from "../write_utils/documentHelpers.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";

const headers_CNV = [
    "Genomic breakpoints detected",
    "Clinically relevant gene",
    "Origin",
    "Clinical significance**",
    "Zygosity",
    "cDNA",
    "Protein"
]

const dataKeys_CNV = [
    "region",
    "associated_genes",
    "origin",
    "significance",
    "zygosity",
    "cDNA",
    "start_pos"
]



const headers_SNV = [
   "Gene",
   "Transcript",
   "cDNA",
   "Protein",
   "Clinical significance**",
   "Zygosity",
   "Origin"
]


const dataKeys_SNV = [
    "geneName",
    "transcript",
    "cDNA",
    "protein",
    "significance",
    "zygosity",
    "origin"
]


function WriteGeneticFindingsSection(sectionData){


    let snv_data = [];
    let cnv_data = []


    if(sectionData){
        if(sectionData.rows && sectionData.rows.length>0){
            let obj  = splitSNVandCNV(sectionData.rows);
            snv_data=obj.snv_data;
            cnv_data = obj.cnv_data;
        }
    }


    return [
        
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("GENETIC FINDINGS"),
                    size: 11*2,
                    underline: {
                        type: 'single',
                        color: '000000',
                    },
                    bold: true,
                }),
            ],            
        }),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: "SNVs",
                    size: 11*2,
                    bold: true,
                }),
            ],
        }),
        drawGeneticFindingsTable(snv_data, headers_SNV, dataKeys_SNV, TEXT_FOR_NEGATIVE_VARIANTS),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("**Based upon ACMG recommendations (Richards et al. 2015, Riggs et al. 2020 and https://clinicalgenome.org/) "),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
        }),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: "CNVs",
                    size: 11*2,
                    bold: true,
                }),
            ],
        }),
        drawGeneticFindingsTable(cnv_data, headers_CNV, dataKeys_CNV, TEXT_FOR_NEGATIVE_VARIANTS),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("*Based upon reference genome version GRCh38"),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
            
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("**Based upon ACMG recommendations (Richards et al. 2015, Riggs et al. 2020 and https://clinicalgenome.org/) "),
                    size: 8*2,
                    color: "#757575"
                }),
            ],
        }),
        createSpacer(50, 50),

    ];


}


export default WriteGeneticFindingsSection;