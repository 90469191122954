


import React from 'react';
import SectionCards from './SectionCards';
import {configuration_tree} from "../../../config-files/external_resources_config";
import {Accordion, AccordionDetails, AccordionSummary, Chip} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import configuration_tree_CNVs from "../../../config-files/external_resources_cnvs_config";
import Box from "@mui/material/Box";


const SectionAccordion = ({ sectionData, title }) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant={"h6"}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <SectionCards sectionData={sectionData} />
            </AccordionDetails>
        </Accordion>
    );
};

const PreviewExternalLinks = () => {


    const data_type = (window.config.genome_ref === "hg38")
        ? <Chip color={"primary"} label={"Dataset hg38"}/>
        : <Chip color={"primary"} label={"Dataset hg19"}/>

    return (
        <div>
            <Typography>Preview of External resources accessible in this instance:</Typography>
            <Box p={1}>
                {data_type}
            </Box>
            <Box p={1}>
                <SectionAccordion sectionData={configuration_tree} title="SNV external resources" />
            </Box>
           <Box p={1}>
               <SectionAccordion sectionData={configuration_tree_CNVs} title="CNV external resources" />
           </Box>
        </div>
    );
};

export default PreviewExternalLinks;