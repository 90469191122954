
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {clinical_evidence_columns, tagged_treatments_sample} from "./clinical_evidence_columns.js";
import GeneralTable from "../../../create-report/shared-components/general-table/GeneralTable.js";
import Box from "@mui/material/Box";
import {Chip, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import SelectedRowInfoBox from "../../../create-report/shared-components/SelectedRowInfoBox.js";
import {checkIfSelected} from "../utils.js";
import MappedText from "../../shared-components/mapText.js";


function ClinicalEvidenceTable({data, setSelectedRows}){



    const handleSelectedRows = (rows) => {

        //setSelected(rows);
        setSelectedRows(rows);



    };

    return <GeneralTable columns={clinical_evidence_columns}
                         data={data}
                         handleSelectedRows={handleSelectedRows}
                         showCheckbox={true}/>


}


function ClinicalEvidenceSection({participant, handleSectionUpdate}){

    //let tagged_treatments = (participant!== undefined && Array.isArray(participant) && participant.length>0) ? participant[0].tagged_treatments : [];

    // this tagged
    let tagged_treatments = tagged_treatments_sample;
       /* .map(s => {
        return {
            treatment: s.treatment,
            comment: s.comment
        };
    });*/


    const [treatment_data, setTreatmentData] = useState(tagged_treatments);
    const [conclusionText, setConclusionText] = useState("Not Provided");

    const [selectedRows, storeSelectedRows] = useState(tagged_treatments);

    const handleSelectedRows = (rows) => {

        // I d rather save the index...


        let selectedRowIndexes = rows.map(s =>parseInt(s.id));
        storeSelectedRows(selectedRowIndexes);

        // return with the right comment

        let tagged_selected = treatment_data
            .filter((row, index) => selectedRowIndexes.includes(index));

        //let data_rows = rows.map(s => s.original);

        handleSectionUpdate({rows: tagged_selected, conclusion: <MappedText text={conclusionText}/>}, "Clinical_Evidence");
    }

    const handleTextChange = (index, value) => {

        // get prevState of the Tagged Treatments and update comment;
        setTreatmentData(prevState => {
            const updatedTreatments = [...prevState];
            updatedTreatments[index].comment = value;
            handleSectionUpdate({rows: updatedTreatments, conclusion: <MappedText text={conclusionText}/>}, "Clinical_Evidence");
            return updatedTreatments;
        });
    };


    const handleConclusionChange = (event)  => {

        // handle Conclusion


        setConclusionText(event.target.value);
        handleSectionUpdate({rows: treatment_data, conclusion: event.target.value}, "Clinical_Evidence");

    }


    const renderTreatmentBoxes = (treatment_data) => {

        return treatment_data.map((s,i) => {

            const selected= selectedRows.includes(i);
            const fields = { "field1": s.therapy, "field2": s.gene};
            //const selected = checkIfSelected(s, selectedRows);
            return (
                <SelectedRowInfoBox
                    key={s.gene + "_"+ i}
                    fields = {fields}
                    comments={s.comment} // different that the variant where it is commentS.
                    selected={selected}
                    dataType={"evidence"}
                    handleOnTextChange={(event) => handleTextChange(i, event.target.value)}
                />
            );
        });

    }

    return <div>
        <Typography variant={"body1"} color="textSecondary"><MappedText text="These are the treatment options that were tagged in the" /> <b><MappedText text="Clinical Evidence"/></b><MappedText text=" tab of this Interpretation Request."/> </Typography>
            <Typography variant={"body1"} color="textSecondary"><MappedText text="Check all the treatments that you want to display in the final report."/> </Typography>
            <br/>
            <Typography variant={"body1"} color="textSecondary"><MappedText text="The"/> <b><MappedText text=" Clinical Significance"/></b> <MappedText text="field shows the comments that were added when tagging an evidence."/></Typography>
            <Typography variant={"body1"} color="textSecondary"><MappedText text="The original tag comment "/><b><MappedText text="will not"/></b><MappedText text=" be modified here. Your modifications will only be visible in the exported report."/></Typography>
            <Box pt={1}>
                <ClinicalEvidenceTable data={tagged_treatments}
                                       setSelectedRows={handleSelectedRows}/>
            </Box>
            <Box p={1}>
                <Typography variant={"body1"}><b><MappedText text="Conclusion"/></b></Typography>
                <TextField
                    id="outlined-multiline-flexible"
                    onChange = {handleConclusionChange}
                    multiline
                    maxRows={4}
                    style={{ width: '100%' }}
                />
            </Box>
            <Box p={1}>
                <Box>
                    <Typography variant={"h6"}><b><MappedText text="Clinical Significance"/></b></Typography>
                </Box>
                <Box pt={1}>
                <Typography variant={"body1"} color="textSecondary"><MappedText text="These are the interpretation comments that were written in each evidence tag."/></Typography>
                <Typography variant={"body1"} color="textSecondary"><MappedText text="The original tag comment "/><b><MappedText text="will not"/></b><MappedText text=" be modified here. Your modifications will only be visible in the exported report."/></Typography>
                </Box>
                {renderTreatmentBoxes(tagged_treatments)}

            </Box>
         </div>


}



export default ClinicalEvidenceSection;