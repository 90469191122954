

/* REDUCER TO UPDATE THE QUERY ON THE STORE */


const initialState = {
    query: {}
};

const currentQuery = {
    currentQuery: {}
};

const currentFilterSet = {}

const sa_query = {
    sa_query: {}
};



export const queryReducer = (state =initialState, action) => {

    switch (action.type) {
        case 'UPDATE_QUERY':
            return {...action.query};
        case "ADD_CHROM":
            let query = {...state};
            query.chrom = [];
            query.chrom = query.chrom.concat(action.chrom);
            return query;
        case 'RESET_QUERY':
            break;
        default:
            return state
    }
};


export const currentQueryReducer = (state =currentQuery, action) => {

    switch (action.type) {
        case 'SET_CURRENT_QUERY':
            return action.query;
        case 'RESET_QUERY':
            return currentQuery;
        default:
            return state
    }
};


export const currentFilterSetReducer = (state =currentFilterSet, action) => {

    switch (action.type) {
        case 'SET_FILTER_SET':
            return action.currentFilterSet;
        case 'RESET_FILTER_SET':
            return currentFilterSet;
        default:
            return state
    }
};




export const sa_queryReducer = (state =sa_query, action) => {

    switch (action.type) {
        case 'SET_SA_QUERY':
            return action.sa_query;
        case 'RESET_SA_QUERY':
            return sa_query;
        default:
            return state
    }
};

export const select_cohort = (state =null, action) => {
    switch (action.type) {
        case 'SET_SELECTED_COHORT':
            return action.cohort;
        case 'RESET_SELECTED_COHORT':
            return null;
        default:
            return state
    }
};




