import { Button } from "@mui/material";
import { styled } from "@mui/system";

const OrangeButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#feb24c",
    '&:hover': {
        backgroundColor: "#91BFDB",
    },
    margin: "1%"
}));

const GPAP_Button = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#4575B4",
    '&:hover': {
        backgroundColor: "#91BFDB",
    },
    margin: "5px"
}));

const GPAP_RunButton = styled(Button)(({ theme }) => ({
    color: "white",
    fontSize: "15px",
    backgroundColor: "#4575B4",
    '&:hover': {
        backgroundColor: "#feb24c",
    },
    margin: "1%"
}));

const GPAP_Link_Button = styled(Button)(({ theme }) => ({
    color: "#4575B4",
    backgroundColor: "white",
    '&:hover': {
        backgroundColor: "#91BFDB",
    },
    fontSize: "18px",
    margin: "1%"
}));

const big_rounded_button = styled(Button)(({ theme }) => ({
    marginLeft: "1%",
    borderRadius: "100px",
    fontSize: "20px",
    padding: "15px 40px 15px 40px",
    boxShadow: "1px 1px 5px black",
    '&:hover': {
        backgroundColor: "#91BFDB",
    },
    margin: "1%"
}));

const GPAP_Button_light = styled(Button)(({ theme }) => ({
    color: "#4575B4",
    backgroundColor: "#FAFAFA",
    '&:hover': {
        backgroundColor: "#f0f0f0",
    },
}));

const GPAP_Button_Orange = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "orange",
    '&:hover': {
        backgroundColor: "#e6550d",
        color: "black"
    },
}));

const GPAP_Button_Gray = styled(Button)(({ theme }) => ({
    disableelevation: "true",
    color: "black",
    variant: "contained",
    backgroundColor: "#EAEAEA",
    '&:hover': {
        backgroundColor: "#D5D5D5",
        color: "black"
    },
}));

const GPAP_Button_LightOrange = styled(Button)(({ theme }) => ({
    margin: "2px",
    color: "black",
    backgroundColor: "#feb24c",
    '&:hover': {
        backgroundColor: "orange",
        color: "black"
    },
}));

const OrangeTextButton = styled(Button)(({ theme }) => ({
    color: "orange"
}));

export {
    OrangeTextButton,
    GPAP_RunButton,
    GPAP_Button,
    GPAP_Button_light,
    GPAP_Button_Orange,
    GPAP_Link_Button,
    OrangeButton,
    big_rounded_button,
    GPAP_Button_LightOrange,
    GPAP_Button_Gray,
};
