



//Set the styles
import makeStyles from "@material-ui/core/styles/makeStyles";

const useFilterStyles = makeStyles(() => ({
    table: {
        minWidth: "600px"
    },
    header: {marginLeft: "20px", fontWeight: "500", width: "100%",  color: "white"},
    accordion_header: {background: "#3182bd", color: "white"},
    icon_style: {color: "white"}
}));

export default useFilterStyles;