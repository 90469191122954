


/*This is a React component that displays a form for searching for participants and experiments.
It is connected to a Redux store and uses the useState and useEffect hooks to manage its internal state.
The component makes use of the SearchID component, which is a form that allows the user to search for participants
and experiments based on either the GPAP PhenoStore ID or the Local Participant ID. The form input is passed
to the handleSearchID function, which updates the state of the component.
The getSample_Case function returns a string that describes the relationship of a participant to the proband,
based on the participant's member_id and the proband's father and mother IDs.
The component also displays error messages if there are issues with retrieving data from the API,
such as a server error or a lack of experiments for a participant. The error messages are stored in the messages object.
The component makes use of the Material UI library to display a loading spinner while data is being retrieved
from the API and a dialog box for displaying error messages.*/


import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import * as Actions from "../../../reducers/sample_actions";
import {connect} from "react-redux";
import {
    experiments_view_new, getParticipantBySample,
    getParticipants_Aggregations,
} from "../../../../../../../services/api/get";
import "../selection_container.css";
import {getFamilyMembers} from "../functions/getFamilyMembers";
import SearchID from "../SearchID";

import {AlertInfo} from "../SelectionInfo";
import {
    CircularProgress,
    Dialog,
    DialogContentText,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core";

import messages from "./message_config";


const getSample_Case = (proband, member_id) => {

    if(proband!== undefined){
        if (proband.father !== undefined && member_id === proband.father)
            return "father";
        else if (proband.mother !== undefined && member_id === proband.mother)
            return "mother";
        else if (member_id === proband.name)
            return "index";
        else
            return "other";
    }
    else{
        return "other";
    }



};


const label_config = {
    "report_id": "GPAP PhenoStore ID",
    "id": "Local Participant ID",
    "case": "GPAP Experiment ID",
    "LocalExperimentID": "Local Experiment ID",
    "famid": "Family ID"
};


const SelectionContainer = props => {

    const [family, setFamily] = useState([]);
    const [NoFamily, setNoFamily] = useState(false);
    const [message, setMessage] = useState(false);
    const [multiple, setMultple] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentFamilies, setFamilyID] = useState([]);
    const [open, setOpen] = useState(false);

    const fields = ["ExperimentID", "Participant_ID", "project", "erns", "library_contruction_protocol", "kit", "tissue", "Owner", "library_strategy", "in_platform"]


    useEffect(function () {


        if (props.samples.length === 0) {

            setFamilyID([]);
            setFamily([])

        }


    }, [props.samples.length])


    useEffect(function () {

        // if there are patients in the study...look for family to restrict the analysis..

        if (props.patients.patients.length !== 0 && family.length === 0) {

            // check for family ID
            let ids = props.patients.patients.filter(p => p.famid !== undefined).map(s => s.famid.split('.')[1]);
            let newSet = new Set(ids);
            let familyIDs = [...newSet]
            if (familyIDs.length!== 0 ) {
                setFamilyID(familyIDs)
            }


        } else {
            setFamilyID([]);
            setFamily([])
        }

    }, [props.patients])


    const handleSearchID = (values) => {

        let type = props.sampleType;

        handleChange(values, type);

    };

    const openDialog = () => {

        setOpen(true);
    }


    const checkFamily = (famid) => {


        if (currentFamilies.length === 1 && currentFamilies.includes(famid) && famid !== "") {
            return true;
        } else if (currentFamilies.length === 0) {
            if (famid !== "") {
                setFamilyID([famid])
                return true;
            } else {
                return false;
            }

        } else if (famid === "") {
            return false;
        } else {
            return false;
        }

    }

    const handleClose = () => {
        setOpen(false);
    }


    const getDataFromEntry = async (entry, report_id, selectedValue) => {

        let {token} = props;
        console.log(selectedValue);
        let participant_ids = []
        let dict = {members: []};
        let ExperimentType = "";
        participant_ids = [report_id]

        //Handle ENOD error
        if (entry.enod_message !== undefined){
            return
        }

        if (entry.famid !== undefined) {
            if (props.fetchFamily) {
                dict = await getFamilyMembers(entry, token);
                // display_name is the PhenoStore ID
                // before I was using name, that is not always the PhenoStore ID
                if(dict.members.length !== 0){
                    participant_ids = dict.members.map(s => s.display_name);
                }

                else{
                    setMessage(messages["message_10"])
                }

            }
            
            else{
                setMessage(false)
            }
        }


        let postObject = {
            page: 1,
            pageSize: 1000,
            filtered: [{id: "Participant_ID", value: participant_ids}],
            fields: fields
        };

        const response = await experiments_view_new(config.apis_configuration.api_data_management, props.token, postObject)

        // if experiments and participants are same number, this turns to true;

        let aligned = true;
        if (response.ok) {
            let sample_array = await response.json()
            setLoading(false);
            if (sample_array.items.length !== 0) {

                //participants with more than 1 experiment?
                aligned = sample_array.items.length === participant_ids.length;
                setLoading(false);

                if(entry.famid === "" || entry.famid === undefined){  // no family exists;
                    setMessage(messages["message_3"]);
                    //setNoFamily(true);
                    sample_array.items.forEach(function (experiment) {
                        ExperimentType = experiment.library_strategy;
                        let affected = (entry.affectedStatus);
                        props.actions.setSamples(experiment["ExperimentID"], entry.report_id, "other", affected, undefined, undefined, ExperimentType);
                    })

                }
                else{
                    if (props.fetchFamily) {
                        // if participant has family;
                        if (dict.members.length !== 0) {
                            if (checkFamily(entry.famid)) {

                                dict.members.forEach(function (member) {
                                    let affected = (member.unaffected === undefined);
                                    setFamily(dict.members);
                                    // name is sth like f21, m21 etc ...
                                    let case_name = getSample_Case(dict.proband, member.name);

                                    // load the exact experiments (Load the selected one in the field...)

                                    // load the selected one and the family members
                                    let experiment;

                                    if(!aligned){
                                        if(selectedValue !== undefined && member.display_name === selectedValue.Participant_ID){
                                            experiment = sample_array.items.find(s => s.Participant_ID === member.display_name && s.ExperimentID === selectedValue.ExperimentID);
                                        }
                                        else{
                                            // adding all;
                                            experiment = sample_array.items.filter(s => s.Participant_ID === member.display_name);
                                        }
                                    }
                                    else{
                                        experiment = sample_array.items.find(s => s.Participant_ID === member.display_name);
                                    }

                                    if (experiment !== undefined) {
                                        // adding all experiments associated;
                                        if(Array.isArray(experiment)) {
                                            experiment.forEach(function(exp){
                                                ExperimentType = exp.library_strategy;
                                                if (exp.ExperimentID !== "") {
                                                    props.actions.setSamples(exp.ExperimentID, member.display_name, case_name, affected, undefined, undefined, ExperimentType);
                                                }
                                            })
                                        }
                                        else{
                                            // adding all experiments associated;
                                            ExperimentType = experiment.library_strategy;
                                            if (experiment.ExperimentID !== "") {
                                                props.actions.setSamples(experiment.ExperimentID, member.display_name, case_name, affected, undefined, undefined, ExperimentType);
                                            }
                                        }

                                    }
                                })
                            }
                            else {
                                openDialog();
                            }
                        }
                    }
                    else {
                        let affected = (entry.affectedStatus);
                        if (checkFamily(entry.famid)) {
                            sample_array.items.forEach(function (experiment) {
                                ExperimentType = experiment.library_strategy;
                                props.actions.setSamples(experiment["ExperimentID"], entry.report_id, "other", affected, undefined, undefined, ExperimentType);
                            })
                        } else if (entry.famid === "") {
                            sample_array.items.forEach(function (experiment) {
                                ExperimentType = experiment.library_strategy;
                                props.actions.setSamples(experiment["ExperimentID"], entry.report_id, "other", affected, undefined, undefined, ExperimentType);
                            })

                        } else {
                            openDialog();
                        }
                    }
                }

                if(aligned){
                    setMultple(false)
                }
                else{
                    setMultple(messages["message_7"])
                }
            } else {
                setMessage(messages["message_1"])
            }
        } else {
            setMessage(messages["message_5"])
        }

    }


    const handleChange = async (selectedValue, type) => {

        if (selectedValue !== undefined) {
            setMessage(false);
            setLoading(true);
            // value can be an experiment ID or a PhenoStore ID
            let {token} = props;
            let {api_phenostore} = config.apis_configuration;
            let entry = "";

            if (type === "report_id" || type === "id" || type === "famid") {

                // fetch family members from selected sample;
                // get Family from sample;

                let filtered = [{"id": type, "value": [selectedValue[type]]}];
                let data_post = {
                    "page": 1,
                    "pageSize": 10,
                    "sorted": [],
                    "filtered": filtered,
                    "aggrKeys": [],
                    "fields": []
                };

                const participants = await getParticipantBySample(api_phenostore, token, data_post).then(resp => resp.json());

                // check if famid is a property for all participants;

                if (participants.length!== 0 && participants.rows.length !== 0) {
                    participants.entry = participants.rows[0]
                    setMessage(false)
                    await getDataFromEntry(participants.entry, participants.entry.report_id);
                    setLoading(false);
                    //Handle ENOD error
                    if (participants.entry.enod_message !== undefined){
                        setMessage(messages["message_9"])
                    }
                }
                else {
                    setMessage(messages["message_8"])
                    setLoading(false);
                }


            }
            else if (type === "case" || type === "LocalExperimentID") {

                // get
                let filtered = [{"id": "report_id", "value": [selectedValue["Participant_ID"]]}];
                let data_post = {
                    "page": 1,
                    "pageSize": 10,
                    "sorted": [],
                    "filtered": filtered,
                    "aggrKeys": [],
                    "fields": []
                };

                const participants = await getParticipantBySample(api_phenostore, token, data_post)
                    .then(resp => {
                        setLoading(false)
                        return resp.json()
                    });

                if (participants.length!== 0 && participants.rows.length !== 0) {
                    participants.entry = participants.rows[0]
                    setMessage(false)
                    await getDataFromEntry(participants.entry, participants.entry.report_id, selectedValue);
                    setLoading(false);
                    //Handle ENOD error
                    if (participants.entry.enod_message !== undefined){
                        setMessage(messages["message_9"])
                    }

                } else {
                    setMessage(messages["message_8"])
                    setLoading(false);
                }

            }

        }
    };



    return <div>
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Only one Family Allowed"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Currently, we only support analysis of one family at a time. Please remove the current family to
                    analyze a new one
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
        <SearchID
            field={props.sampleType}
            title={"Search by " + label_config[props.sampleType]}
            passSelected={handleSearchID}
            sampleType={props.sampleType}
        />
        {(loading)
            ? <CircularProgress color="primary"/>
            : null
        }
        {(family.length !== 0 && props.fetchFamily)
            ? <AlertInfo message={"Found " + family.length + " family members in Family ID " + family[0].report_id}
                         severity={"success"}
                         title={"Info"}
            />
            : null
        }
        {(NoFamily) ? <AlertInfo title={"Warning"} message={"No Family Found"} severity={"warning"}/> : null}
        {(multiple) ? <AlertInfo title={"Warning"} message={multiple} severity={"warning"}/> : null}
        <AlertInfo message={message} severity={"warning"} title={"Warning"}/>
    </div>


};


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps(state) {
    return {
        token: state.authorization.token,
        samples: state.sample_list.samples,
        patients: state.patients
    }
}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(SelectionContainer);