

import React, {Component}from 'react';
import { scaleBand, scaleLinear } from 'd3-scale';
import Axes from './Axes';
import Bars from "./Bars";
import Tooltip from "./Tooltip";
import ChartComponent from "./ChartComponent";



class Chart extends Component {
    constructor(props) {
        super(props);
        this.xScale = scaleBand();
        this.yScale = scaleLinear();

        this.state = {
            datatorender:[],
            clicked: false,
            processed: true,
            hoveredData: false,
            hovering: false,
            selectedData: false,
            filterAction:false,
        }
    }


    // to move
    filterData(datum){

    }

    render() {

        if (this.state.processed) {

            let data= this.props.initData;
            const margins = {top: 10, right: 30, bottom: 10, left: 30};
            const svgDimensions = {
                width: Math.max(this.props.parentWidth, 120),
                height: 50
            };

            const maxValue = Math.max(...data.map(d => d.value));

            // scaleBand type
            const xScale = this.xScale
                .padding(0.5)
                .domain(data.map(d => d.d))
                .range([margins.left, svgDimensions.width - margins.right]);

            // scaleLinear type
            const yScale = this.yScale
                .domain([0, maxValue])
                .range([svgDimensions.height - margins.bottom, margins.top]);

            const chartTitle = (<text
                    x={margins.left}
                    y={margins.top}>{this.props.chartTitle}</text>);


            return (
                <div className="Chart">
                    <svg width={svgDimensions.width} height={svgDimensions.height}>
                        {chartTitle}
                        <Axes
                            scales={{xScale, yScale}}
                            margins={margins}
                            svgDimensions={svgDimensions}
                        />
                       <Bars
                            scales={{xScale, yScale}}
                            margins={margins}
                            data={this.props.data}
                            initData = {this.props.initData}
                            color={"default"}
                            maxValue={maxValue}
                            svgDimensions={svgDimensions}
                            hoveredData = {this.state.hoveredData}
                            hovering = {this.state.hovering}
                            clicked = {this.state.clicked}
                            selectedData = {this.props.selectedData}
                            onMouseOverCallback={datum => {
                                this.setState({hoveredData: datum, hovering:true})}}
                            onMouseOutCallback={datum => this.setState({hoveredData: false, hovering:false})}
                            onMouseClickCallback={datum => { this.filterData(datum);}}
                        />

                    </svg>

                    { this.state.hoveredData ?
                        <Tooltip
                            hoveredBar={this.state.hoveredData}
                            scales={{ xScale, yScale }}
                        /> : null
                    }

                </div>
            )
        }
    else
        {
            return (<div>Processing data</div>)
        }
    }
}


export default ChartComponent(Chart)