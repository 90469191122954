



import React from "react";
import {connect} from "react-redux";

import GenotypeBuilder from "./GenotypeBuilder";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card/Card";
import cardStyles from "../card-style";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MappedText from "../clinical-info/mapText";


const GB_renderer = (props) => {

    const classes = cardStyles();

    if(props.render){
        return (
            <React.Fragment>
                <Box p={1}>
                    <Typography variant={"h6"} color={"textSecondary"}>
                        <MappedText text="Configuration details" />
                    </Typography>
                </Box>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <GenotypeBuilder instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
    else{
        return null
    }


};



function inheritance_selected (state) {
    if(state.inheritance_key!== "")
    {
        return {render:true}
    }
    else{ return {render:false}}

}

export default connect(inheritance_selected,null)(GB_renderer);



