import React, {Fragment, useState} from "react";
import {DialogTitle} from "../../../../../side-panels/study-store/dialog_components";
import MappedText
    from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import getHelperText from "../helper-text/HelperText";
import {checkIfNameExists} from "../../../../../side-panels/filter-panel/components/query-top-bar/func";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import {Dialog, DialogActions, DialogContent} from "@mui/material";


const getAnalysisInfo = (currentStudy) => {

    let analysis_without_queries = false;
    if (currentStudy) {
        currentStudy.analyses.forEach(function (s) {
            if (s.queries.length === 0) {
                analysis_without_queries = true;
            }
        })
    }

    return (analysis_without_queries) ?
        <Alert severity={"warning"}><MappedText text="Analyses without queries will not be saved"/></Alert> : null;
}

function StudySaveDialog({handleClose, currentStudy, open, studyDetails, setStudyDetails, handleChangePermission, saveAllStudy, list_of_studies, permission}) {


    const [nameError, setNameError] = useState(false);



    return (<Fragment>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {currentStudy && currentStudy.id !== undefined ? (
                    <MappedText text="Update Study Information"/>
                ) : (
                    <MappedText text="Save Study"/>
                )}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="row">
                    <Grid item lg={12}>
                        <MappedText text="Fill in some information to save your work."/>
                    </Grid>
                    <Grid item lg={12}>
                        {getAnalysisInfo(currentStudy)}
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <TextField
                                style={{width: "70%"}}
                                id="study_name"
                                inputProps={{maxLength: 50}}
                                fullWidth
                                error={nameError}
                                label={currentStudy.studyName}
                                helperText={getHelperText(currentStudy, studyDetails, list_of_studies)}
                                onChange={e => {
                                    let val = e.target.value;
                                    let info = {...studyDetails};
                                    let bool = false;
                                    bool = checkIfNameExists(
                                        val,
                                        list_of_studies,
                                        currentStudy && currentStudy.id !== undefined
                                            ? "update"
                                            : "save"
                                    );
                                    setNameError(bool);
                                    info.studyName = val;
                                    setStudyDetails(info);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <TextField
                                id="description"
                                helperText={<MappedText text="Study description. Max 50 chars"/>}
                                inputProps={{maxLength: 50}}
                                label={currentStudy.description}
                                multiline
                                fullWidth
                                onChange={e => {
                                    let val = e.target.value;
                                    let info = {...studyDetails};
                                    info.description = val;
                                    setStudyDetails(info);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <TextField
                                id="standard-select-permission"
                                select
                                label={<MappedText text="Select"/>}
                                value={permission}
                                onChange={handleChangePermission}
                                helperText={<MappedText text="Please select study visibility"/>}
                            >
                                {[
                                    {value: "user", label: "Private"},
                                    {value: "group", label: "Share with your groups"},
                                    {value: "public", label: "Public"}
                                ].map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <MappedText text={option.label}/>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Box p={2}>
                            <Typography variant="subtitle1">
                                # analysis: {currentStudy.analyses.length}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    <MappedText text="Cancel"/>
                </Button>
                <Button autoFocus onClick={saveAllStudy} color="primary" disabled={nameError}>
                    <MappedText text="Save changes"/>
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>)

}


export default StudySaveDialog;


