import axios from "axios";
import { getValueByPath, setValueByPath } from "./utilsValues";
import createVariantRow from "../../../../createVariantRow";
import {Alert} from "@material-ui/lab";

export const axiosApi = (options) => {
  const {
    url, method,
    token,
    isCheck,
    split,
    dataHeader,
    nestedInfo,
    nestedInfoB,
    setMainData,
    locationData,
    setData,
    setResApi, 
    body,
    dataType,
    getTotal,
  } = options;





  if(method === "GET"){
      axios
          .get(url)
          .then((res) => {
              setResApi(res.data);

              // check if results are over 10k.
              // if so...inform the user

              if("items" in res.data){
                  getTotal(res.data.items.length);
                  if(res.data.items.length === 10000){
                      alert("More than 10000 CNVs have been found. Please, apply a gene list to your query to limit the number of results.")
                  }
              }
              else{
                  getTotal(0);
              }
              const dataToMap = locationData ? res.data[locationData] : res.data;
              setMainData(dataToMap);

              const respuesta = dataToMap.map((item) => {
                  const data = {};

                  const mapInfo = isCheck ? split : dataHeader;

                  mapInfo.forEach(({ columns }) => {
                      columns.forEach((col) => {
                          let propertyValue = getValueByPath(item, col.dataSearch);
                          if (propertyValue !== undefined) {
                              setValueByPath(data, col.dataSearch, propertyValue);
                          }
                      });
                  });

                  const fullRespApi = dataToMap;

                  const nested = isCheck
                      ? nestedInfoB.map(({ dataSearch }) => {
                          if (typeof item[dataSearch] === "object") {
                              return item[dataSearch];
                          }

                          return {
                              [dataSearch]: Array.isArray(item[dataSearch])
                                  ? item[dataSearch]
                                  : [item[dataSearch]],
                          };
                      })
                      : nestedInfo.map(({ dataSearch }) => {
                          return {
                              [dataSearch]: Array.isArray(item[dataSearch])
                                  ? item[dataSearch]
                                  : [item[dataSearch]],
                          };
                      });

                  return { ...data, fullRespApi, nested };
              });

              setData(respuesta);
          })
          .catch((error) => {
              if (error.response && error.response.status === 400) {
                  // Handle HTTP 400 error here
                  console.error("HTTP 400 Bad Request:", error.response.data);
              } else {
                  // Handle other errors here
                  console.error("An error occurred:", error);
              }
          });
  }
  else if (method === "POST"){


      let headers =  {
          "Authorization": token
      };

      if(dataType === "somatic"){

          headers = {"X-TOKEN-AUTH": token}

      }

      axios.post(url,body, {headers: headers} )
          .then((res) => {

              console.log("Calling CNVs");
              if( dataType=== "somatic"){
                  // process data

                  let data = []
                  if("snv" in res.data){
                      let variants =  res.data.snv.hits.hits.map(s => s);

                      variants.forEach(function(hit){

                          let obj = hit._source;

                          // how many genes in effs;

                          let gene_names = [...new Set(hit.fields.effs.map(s => s.gene_name))];

                          // filter out undefined;

                          gene_names = gene_names.filter(s => s !== undefined);

                          gene_names.forEach(function(geneName){

                              let row=  createVariantRow(obj, hit, [], "family_trio", [], geneName);
                              data.push(row.source);

                          })


                      });

                      console.log(res.data.snv.hits.total.value)
                      getTotal(res.data.snv.hits.total.value);
                      setMainData(data);
                      setData(data);
                  }
              }
              else{

                  
                  if("items" in res.data){
                    getTotal(res.data.items.length);
                    if(res.data.items.length >= 10000){
                        alert("More than 10,000 CNVs have been identified. Note that only the first 10,000 CNVs (in chromosomal order) will be sorted unless you apply a gene list to your query to limit the results")
                    }
                    
                    setResApi(res.data);
                }
                else{
                    getTotal(0);
                }
 
                  const dataToMap = locationData ? res.data[locationData] : res.data;
                  setMainData(dataToMap);

                  const respuesta = dataToMap.map((item) => {
                      const data = {};

                      const mapInfo = isCheck ? split : dataHeader;

                      mapInfo.forEach(({ columns }) => {
                          columns.forEach((col) => {
                              let propertyValue = getValueByPath(item, col.dataSearch);
                              if (propertyValue !== undefined) {
                                  setValueByPath(data, col.dataSearch, propertyValue);
                              }
                          });
                      });

                      const fullRespApi = dataToMap;

                      const nested = isCheck
                          ? nestedInfoB.map(({ dataSearch }) => {
                              if (typeof item[dataSearch] === "object") {
                                  return item[dataSearch];
                              }

                              return {
                                  [dataSearch]: Array.isArray(item[dataSearch])
                                      ? item[dataSearch]
                                      : [item[dataSearch]],
                              };
                          })
                          : nestedInfo.map(({ dataSearch }) => {
                              return {
                                  [dataSearch]: Array.isArray(item[dataSearch])
                                      ? item[dataSearch]
                                      : [item[dataSearch]],
                              };
                          });

                      return { ...data, fullRespApi, nested };
                  });

                  setData(respuesta);
              }



          })
          .catch((error) => {
              if (error.response && error.response.status === 400) {
                  // Handle HTTP 400 error here
                  console.error("HTTP 400 Bad Request:", error.response.data);
              } else {
                  // Handle other errors here
                  console.error("An error occurred:", error);
              }
              if (error.response?.data?.message) {
                // Both 'data' and 'message' properties exist
                  getTotal(-1);


              } else {
                // Either 'data' or 'message' or both do not exist
               alert("error when retrieving data from the server");
              }

          });
  }



};