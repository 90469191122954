

import React, {useState,Fragment} from 'react';
import ExtensionIcon from '@material-ui/icons/Extension';import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import VarSomeDialog from "../../variant-table/gpap-table/components/VarSomeDialog";
import {ListItemIcon} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExomiserDialog from "../../variant-table/gpap-table/components/ExomiserPage";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';



export default function PluginMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [exomiserFlag, setExomiserFlag] = useState(false);
    const [varsomeFlag, setVarsomeFlag] = useState(false);

    //Get variants from props
    let variants_data = props.data


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setExomiserFlag(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExomiserFlag(false);
        setVarsomeFlag(false);
    };

    const handleExomiser = () => {
        setAnchorEl(null);
        setExomiserFlag(true);
    };

    const handleVarsome = () => {
        setAnchorEl(null);
        setVarsomeFlag(!varsomeFlag);
    }


    return (
        <Fragment>
            <GPAP_Tooltip title=<MappedText text={"List of Integrated Plugins"} /> >
            <IconButton
                id={"plugins"}
                color = {( anchorEl!== null ? "primary" : "default" )}
                aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <ExtensionIcon/>
            </IconButton>
            </GPAP_Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleVarsome}>
                    <ListItemIcon style={{width: "100px"}}>
                        <img src={window.config.static_files + "/img/nextgpap/varsome_img.png"}
                             style={{width: "80px"}}/>
                    </ListItemIcon>
                    <Typography>  Annotate with VarSome</Typography>
                </MenuItem>

                <MenuItem onClick={handleExomiser}>
                    <ListItemIcon style={{width: "100px"}}>
                        <img src={window.config.static_files + "/img/nextgpap/exo_logo.png"}
                             style={{width: "80px"}}/>
                    </ListItemIcon>
                    <Typography>  Exomiser & Genomiser</Typography>

                </MenuItem>
            </Menu>

            { exomiserFlag &&
            <ExomiserDialog variants={variants_data}/>
            }
            { varsomeFlag &&
            <VarSomeDialog
                handleVarsome={handleVarsome}
                variants={variants_data}
                passAnnotations={props.passAnnotations}
            />
            }
        </Fragment>

    );
}