

import React from "react";
import GenotypeForm from "./GenotypeForm";
import {inheritances} from "../../../../../../../../config-files/config_inheritances";
import SearchAcross_GenotypeForm_ from "./SearchAcross_GenotypeForm_";

import {useSelector} from "react-redux";


const ShowGenotypeForm = (props) => {


    const renderGenotypeForm = () => {

        const inheritance_modes = inheritances;
        let selected = inheritance_modes.find(IM => IM.key === props.inheritance_key);
        let main_label = selected.label;
        let configuration = selected.config;

        let inst_patients = useSelector(state => state.generaltable.allDataApis.participants);
        const {allDataApis } = useSelector((state) => state.generaltable);
        const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);

        if(props.search_across){
            return <SearchAcross_GenotypeForm_
                editable = {(props.inheritance_key === "custom")}
                analysis_type = {props.analysis_type}
                inheritance_key = {props.inheritance_key}
                config = { configuration }/>;
        }
        else{
            if(props.inheritance_key!== "")
                return <GenotypeForm
                    parent={main_label}
                    editable = {(props.inheritance_key === "custom")}
                    inheritance_key = {props.inheritance_key}
                    inst_patients = {inst_patients}
                    selectedDir = {selectedDir}
                    instandGeneticFindingsTab={props.instandGeneticFindingsTab}
                    config = { configuration }/>;
            else
                return null;
        }

    };


    return renderGenotypeForm();


};



export default ShowGenotypeForm;