import {ADD_PATIENTS, REMOVE_PATIENT, RESET_PATIENTS} from "./patient_actions";


const patients_init = {
    patients:[]
};




export const patientsReducer = (state= patients_init, action) => {

    switch (action.type) {
        case RESET_PATIENTS:
            return patients_init;
        case REMOVE_PATIENT:
            if(state.patients.length!== 0){
                let participants = state.patients.filter(d => d.report_id !== action.participant_id);
        
                return Object.assign({}, state, { patients: participants})
            }
            else{
                return state;
            }
        case ADD_PATIENTS:
            if(state.patients.length!== 0){
                let pat_ids = state.patients.map(d => d.id);
                let pats = action.patients.filter(pt => !pat_ids.includes(pt.id));
                return Object.assign({}, state, { patients: state.patients.concat(pats)})
            }
            else{

                let add_pats = action.patients.filter(d=> d !== undefined);
                return Object.assign({}, state, {
                    patients: state.patients.concat(add_pats)});
            }
        default:
            return state
    }

};