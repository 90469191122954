


import React from 'react';
import "./css/tooltip.css";


export default ({hoveredBar, scales}) => {
    const { xScale, yScale } = scales;

    const styles = {
       left: `${xScale(hoveredBar.d)-30}px`,
        top: `${yScale(hoveredBar.value)-20}px`
    };

    if(hoveredBar){
        return <div className="ToolTip" style={styles}>
            <div className="tooltip-name">{hoveredBar.key} : {hoveredBar.d} - </div>
            <div className="tooltip-number">({hoveredBar.value})</div>
        </div>
    }



}