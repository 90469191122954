



const Filters_Accordions = [
    {
        key: "variants", label: "Variant Type",
        description: "In this panel you have to select functional annotations criteria",
        formName: "variants",
        queryKeys: ["variantclasses", "clinvarclasses", "svn", "genotypefeatures", "indel", "variant_type","Mane","variantconsequences"],
        active: false,
    },
    {
        key: "population", label: "Population",
        description: "The population filters return true if the value is lower than filter " +
            "value or the field value is 0 or n.a; multiple populations filters result are treated with AND logical operator",
        formName: "population",
        queryKeys: ["gnomad_af", "gp1_af", "freqInt"],
        active: false,
    },
    {
        key: "snv", label: "SNV Effect Predictions",
        description: "The single effect predictor outcome will be handled with OR logical operator",
        formName: "population",

        queryKeys: ["mutationtaster", "onco_classifier","siftpred", "polyphen2hvarpred","intervarclasses", "cadd_phred","revel","spliceai_max_ds","OCScore"],
        active: false,
    },
    {
        key: "position", label: "Position Specific and Runs Of Homozygosity",
        description: "Select specific chromosomes, upload coordinates and BED files",
        formName: "population",
        queryKeys: ["start", "end", "chrom"],
        active: false,
    }
];



export default Filters_Accordions;