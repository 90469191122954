

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GrainIcon from '@material-ui/icons/Grain';


import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        color: "gray",
        fontSize: "0.8em"
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function GPAP_Breadcrumb() {
    const classes = useStyles();

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="textPrimary" component={RouterLink} to={"/gpap_home"} className={classes.link}>
                <HomeIcon className={classes.icon} />
                GPAP Home
            </Link>
            <Link
                color="textPrimary"
                href=""
                component={RouterLink} to={"/home"}
                className={classes.link}
            >
                <CloudUploadIcon className={classes.icon} />
                Data Analysis
            </Link>
            <Typography color="textPrimary"
                        component={RouterLink} to={"/genomics"}
                        className={classes.link}>
                <GrainIcon className={classes.icon} />
                Genomic Analysis
            </Typography>
        </Breadcrumbs>
    );
}