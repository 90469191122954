import React from 'react';
import { Typography } from '@mui/material';
import MappedText from "./mapText.js";


const DataField = ({ title, value, helperText }) => {

  const capitalizedValue = typeof value === 'string'
    ? value.charAt(0).toUpperCase() + value.slice(1)
    : '';

  return (
    <>
      <Typography sx={{ color: '#626262', fontWeight:'400', fontSize:'14px' }}> <MappedText text={title}/></Typography>
      <Typography sx={{ color: '#202020', fontWeight:'400', fontSize:'16px' }}>
      <Typography>  <MappedText text={capitalizedValue}/></Typography>
      </Typography>
      {helperText && (
      <Typography sx={{ color: '#757575', fontWeight: '400', fontSize: '12px', marginTop: '4px' }}>
        {helperText}
      </Typography>
      )}
    </>
  );
};




export default DataField;
