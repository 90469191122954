import React from "react";
import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


export const DIR_DATA_HEADER = [
    {
        header: "DIR LIST",
        columns: [
            { nameTable: <MappedText text="Case ID"/>, dataSearch: "dir_id", tooltip: "" },
            { nameTable: <MappedText text="Patient ID"/>, dataSearch: "Participant_ID", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: <MappedText text="Analysis Type"/>, dataSearch: "analysis", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: <MappedText text="Priority"/>, dataSearch: "priority", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: <MappedText text="Status"/>, dataSearch: "status", tooltip: "" },
            { filterType: { type: "date" }, filter: "dateFilter", nameTable: <MappedText text="Deadline"/>, dataSearch: "deadline", tooltip: "" },
            { nameTable: <MappedText text="Assignee(s)"/>, dataSearch: "assignee", tooltip: "" },
            { nameTable: <MappedText text="Creation Date"/>, dataSearch: "creation_date", tooltip: "" },
            { nameTable: <MappedText text="Tumor Experiment"/>, dataSearch: "tumor_experiment_id", tooltip: "" },
            { nameTable: <MappedText text="Control Experiment"/>, dataSearch: "control_experiment_id", tooltip: "" },
            { nameTable: <MappedText text="Clinical referrer"/>, dataSearch: "clinical_referrer", tooltip: "" },
            { nameTable: <MappedText text="Clinical contact"/>, dataSearch: "clinical_referrer_contact", tooltip: "" },
            { nameTable: <MappedText text="Hospital Name"/>, dataSearch: "hospital_name", tooltip: "" },
        ],
    },
];

