

import {SelectSingleArea} from "../../../../../../../../../../../../gpap-lib/components/shared-components/renderer";
import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions";
import Box from "@material-ui/core/Box";
import {get_all_lists, get_list_data} from "../../../../../../../../../../../../services/api/lists_api";
import GPAP_Tooltip from "../../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import {CircularProgress} from "@material-ui/core";
import {initialize} from "redux-form";
import {reset_list_types} from "../../../actions";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


const LIST_TYPE = "Predefined List";


class LoadPredefinedList extends Component{

    constructor(props){
        super(props);
        this.state = {
            listArray:[],
            selectedList: false,
            reset:false
        }

        this.LIST_TYPE= LIST_TYPE;
        this.clearList = this.clearList.bind(this);
        this.updateGeneList =   this.updateGeneList.bind(this);
        this.renderSelector = this.renderSelector.bind(this);
        this.getGeneLists = this.getGeneLists.bind(this);
        this.storeGeneList = this.storeGeneList.bind(this);
    }

    componentDidMount() {
        // load predefined gene lists;

        // here, filter by tag "gene-list"

        this.getGeneLists()

        if(this.props.applied_lists.length!== 0 ){
            this.setState({
                selectedList: this.props.applied_lists[0]
            });
        }


    }

    getGeneLists = () => {

        get_all_lists(this.props.token, window.config.apis_configuration.api_nextgpap_server)
            .then(r => r.json())
            .then(json => {


                let listArray = json.data.map(function(list){
                    return {
                        // TODO: This adds the tooltip. However, this also triggers an error when selecting the predefined gene filters.
                  /*  label: <GPAP_Tooltip title={list.description}><span>{list.title}</span></GPAP_Tooltip>,*/
                    label: list.title,
                    value: list.id,
                        date: list.creationDate,
                    tooltip: list.description }});

                // sorted

                listArray.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

                this.setState({listArray: listArray})

            });


    }


    storeGeneList = (list) => {

        const self = this;
        self.props.actions.reset_list_types(self.LIST_TYPE);

        if(list){
            list["type"] = self.LIST_TYPE;
            list["id"] = list.value;
            self.setState({selectedList: list});
            self.props.actions.add_applied_list(list);

        }


    }


    updateGeneList = () => {
        let {list, actions, operator} = this.props;
        let self = this;
        if(list !== this.state.selectedList){

            let list_to_use = (list!== undefined) ? list : this.state.selectedList;

            if(list_to_use!== undefined)
            {
                // get genes from API;

                get_list_data(self.props.token, window.config.apis_configuration.api_nextgpap_server, {list_id: [list_to_use.value]})
                    .then(r => r.json())
                    .then(json => {

                        // genes
                        if(operator=== "intersection"){
                            // intersect genes with current selections;
                            if(json.data.length > 0){
                                actions.intersect_genelist(JSON.parse(JSON.stringify(json.data[0].data)), "predefined_genes");
                            }
                        }
                        else{
                            if(json.data.length > 0) {
                                actions.replace_genes_to_specific_genelist(JSON.parse(JSON.stringify(json.data[0].data)), "predefined_genes");
                            }
                        }

                        self.storeGeneList(list_to_use);

                    });
            }
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.list !== prevProps.list ){
            this.updateGeneList();
        }
        else if(prevState.selectedList !== this.state.selectedList && (prevState.selectedList !== this.props.list)){
            this.updateGeneList();
        }
        if(prevState.reset !== this.state.reset){
            this.getGeneLists();
            setTimeout(()=> this.setState({reset: false}), 1000);
        }


    }

    // reset sections;
    clearList(){

        this.props.actions.reset_geneList("predefined_genes");

        this.props.actions.remove_applied_list(this.state.selectedList.id,"predefined_genes");

        this.props.resetList();

        this.setState({
            reset: true,
            selectedList: false,
            listArray:[]
        })


    }


    renderSelector = () => {


        if(this.state.reset){

            return <CircularProgress/>


        }
        else{

            return   <div id="select-predefined-gene-list"><SelectSingleArea
                header = {<MappedText text={"Select a predefined gene list"}/>}
                collection_name = {"predefined_list"}
                fields = {this.state.listArray} 
                /></div>   


        }


    }

    render(){

        return <Box m={2}>
            {this.renderSelector()}
            {
                (this.state.selectedList)
                    ? <React.Fragment>
                        <GPAP_Tooltip title={"Delete genes from Predefined Gene List"}>
                            <IconButton onClick={()=> this.clearList()}>
                                <DeleteSweepOutlinedIcon/>
                            </IconButton>
                        </GPAP_Tooltip>
                        {this.state.selectedList.label} selected
                    </React.Fragment>
                    : null
            }
                </Box>
    }


}


function mapStateToProps (state) {
    if(state.form!==undefined)
    {
        let form = state.form;
        if(form.gene_section!== undefined && form.gene_section.values!== undefined){
            if(form.gene_section.values.predefined_list!== undefined)
                return {
                    list:form.gene_section.values.predefined_list,
                    operator: state.geneList.operator,
                    token: state.authorization.token,
                    applied_lists: state.geneList.applied_lists.filter(s => s.type === LIST_TYPE)
            };
            else{
                return {token: state.authorization.token,  applied_lists: state.geneList.applied_lists.filter(s => s.type === LIST_TYPE)}
            }
        }
        else{
            return {token: state.authorization.token, applied_lists: state.geneList.applied_lists.filter(s => s.type === LIST_TYPE)}
        }
    }
    else{
        return {
            applied_lists: state.geneList.applied_lists.filter(s => s.type === LIST_TYPE),
            token: state.authorization.token}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(LoadPredefinedList);


