

export const SET_SAMPLES = "SET_SAMPLES";
export const SET_COHORT_SAMPLE = "SET_COHORT_SAMPLE";
export const SET_SA_SAMPLES = "SET_SA_SAMPLES";
export const RESET_SAMPLES = "RESET_SAMPLES";
export const DELETE_SAMPLE = "DELETE_SAMPLE";
export const SET_GENOTYPE = "SET_GENOTYPE";
export const RESET_GENOTYPES = "RESET_GENOTYPES";
export const SET_GQ_PARAMETER = "SET_GQ_PARAMETER";
export const UPDATE_GENOTYPE = "UPDATE_GENOTYPE";
export const REMOVE_GENOTYPE = "REMOVE_GENOTYPE";
export const SET_AFFECTED_STATUS = "SET_AFFECTED_STATUS";

export const SET_SOMATIC_SAMPLES = "SET_SOMATIC_SAMPLES";


export function setSamples(sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt, experiment_type){
    return {
        type: SET_SAMPLES,
        sample_id:sample_id,
        pheno_id: pheno_id,
        sample_type: sample_type,
        affectedStatus:affectedStatus,
        sample_from: sample_from,
        gt: (gt!== undefined) ? gt : [],
        experiment_type: experiment_type
    }
}

export function setSomaticSamples(sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt, experiment_type,experiment_somatic){
    return {
        type: SET_SOMATIC_SAMPLES,
        sample_id:sample_id,
        pheno_id: pheno_id,
        sample_type: sample_type,
        affectedStatus:affectedStatus,
        sample_from: sample_from,
        gt: (gt!== undefined) ? gt : [],
        experiment_type: experiment_type,
        experiment_somatic:experiment_somatic
    }
}



export function setCohortSample(sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt, experiment_type){
    return {
        type: SET_COHORT_SAMPLE,
        sample_id:sample_id,
        pheno_id: pheno_id,
        sample_type: sample_type,
        affectedStatus:affectedStatus,
        sample_from: sample_from,
        gt: (gt!== undefined) ? gt : [],
        experiment_type: experiment_type
    }
}

export function setSearchAcrossSample(sample_obj){
    return {
        type: SET_SA_SAMPLES,
        sample: sample_obj
    }
}



export function setGenotype(sample_id, gt, report_id, index, settings_id){
    return {
        type: SET_GENOTYPE,
        sample_id: sample_id,
        gt:gt,
        report_id: report_id,
        index: index,
        settings_id: settings_id
    }
}

export function removeGenotypeFromSamples(inheritance_key, inheritance_type){
    return {
        type: REMOVE_GENOTYPE,
        inheritance_key: inheritance_key,
        inheritance_type: inheritance_type
    }

}

export function updateGenotype(sample_id, gt, inheritance, inheritance_type){
    return {
        type: UPDATE_GENOTYPE,
        sample_id: sample_id,
        gt:gt,
        inheritance_key: inheritance,
        inheritance_type: inheritance_type
    }
}

export function resetSamples(){
    return {
        type: RESET_SAMPLES

    }
}

export function delete_sample(sample_id){
    return {
        type: DELETE_SAMPLE,
        sample_id: sample_id,
    }
}

export function resetGenotypes(){
    return {
        type: RESET_GENOTYPES

    }
}

export function setGQ_parameter(sample_id, gq_key, value, inheritance_key, inheritance_type, analysisType){
    return {
        type: SET_GQ_PARAMETER,
        sample_id: sample_id,
        gq_key: gq_key,
        value:value,
        inheritance_key: inheritance_key,
        inheritance_type: inheritance_type,
        analysisType: analysisType
    }
}

export function setAffectedStatus(sample_id, affectedStatus){
    return {
        type: SET_AFFECTED_STATUS,
        sample_id: sample_id,
        affectedStatus: affectedStatus
    }
}