

export const UPDATE_ROH_VALUE = 'UPDATE_ROH_VALUE';


export function update_roh_value(roh_value) {
    return {
        type: UPDATE_ROH_VALUE,
        roh_value: roh_value
    }
}