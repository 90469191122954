




export const SET_VIEW = "SET_VIEW";


// CREATE ANALYSIS


export function setView(settings){
    return {
        type: SET_VIEW,
        settings: settings
    }
}