


export const somatic_query_default = {
    "size": 3000,
    "from": 0,
    "fromCNV": 0,
    "chrom": [],
    "indel": false,
    "freqInt": null,
    "svn": false,
    "genotypefeatures": {
        "other": false,
        "coding": false,
        "RNA": false
    },
    "variantclasses": {
        "high": true,
        "low": false,
        "moderate": false,
        "modifier": false
    },
    "mutationtaster": {
        "A": false,
        "D": false,
        "P": false
    },
    "clinvarclasses": {
        "P": false,
        "L": false,
        "A": false,
        "U": false,
        "C": false,
        "D": false
    },
    "onco_filter": {
        "K": false,
        "P1": false,
        "P2": false,
        "PP": false
    },
    "polyphen2hvarpred": {
        "D": false,
        "P": false,
        "B": false
    },
    "population": {
        "gnomad_af": null,
        "gp1_af": null,
        "exac": null
    },
    "siftpred": {
        "D": false,
        "T": false
    },
    "gnomad_filter": {
        "pass": false,
        "nonpass": false
    },
    "gene": [],
    "samples_germline": [],
    "samples_somatic": [
        {
            //"sample_id_tumor": "AX7171",
            //"sample_id_control": "AX7172",
            "gt_tumor": [
                "0/1"
            ],
            "dp_tumor": 10,
            "dp_control": 10,
            "ad_tumor_low": 0.2,
            "ad_tumor_high": 0.8,
            "ad_control": 0.0,
            "pass_tumor": true,
            "pass_control": true,
            "min_num_tools": 0,
            "tools": [],
            //"min_num_tools": 1,
            //"tools": ["dragen","mutect"]
        }
    ],
    "ref_samples_somatic": [
        {
            //"sample_id_tumor": "AX7171",
            //"sample_id_control": "AX7172",
            "gt_tumor": [
                "0/1"
            ],
            "dp_tumor": 10,
            "dp_control": 10,
            "ad_tumor_low": 0.2,
            "ad_tumor_high": 0.8,
            "ad_control": 0.0,
            "pass_tumor": true,
            "pass_control": true,
            "min_num_tools": 0,
            "tools": [],
            //"min_num_tools": 1,
            //"tools": ["dragen","mutect"]
        }
    ],
    "compound_in": false,
    "cadd_phred": null,
    "cnv_germline": true,
    "cnv_somatic": false
};