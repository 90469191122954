import React from "react";
import { useTable } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import BootstrapTooltip from "../tooltips/BootstrapTooltip";

const NestedTable = ({ columns, states, data = {} }) => {
  const [expandedRows, setExpandedRows] = React.useState({});

  //const indexOriginal =
  //  states?.dataFilter[states?.idExpanded.id]?.originalIndex;


  if (data.every((item) => Array.isArray(item))) {
    if (data[0].every((item) => Array.isArray(item))) {
      console.log(data[0]);
    }
  }

  const transformData = (data) => {
    const test = data.reduce((acc, item) => {
      for (let key in item) {
        acc[key] = item[key];
      }

      return acc;
    }, {});

    return [test];
  };

  const dataNew = transformData(data);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: dataNew });

  return (
    <TableContainer>
      <Table {...getTableProps()}>
      <TableHeadCustom>
          {headerGroups.map((headerGroup) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{ maxWidth: "20px", overflow: "hidden" }}
            >
              {headerGroup.headers.map((column) => (
                  <TableCell
                  {...column.getHeaderProps()}
                  style={{ maxWidth: "20px", overflow: "hidden" }}
                >
                    <div style={{display: "inline-block"}}>
                    <BootstrapTooltip title={column.tooltip} forceUnderline={true}>
                   {column.render("Header")}
                 </BootstrapTooltip>
                 
                    </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
         </TableHeadCustom>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <TableRow
                {...row.getRowProps()}
                style={{ maxWidth: "20px", overflow: "hidden" }}
              >
                {row.cells.map((cell) => {
                  let truncate = false;
                  let content;
                  if (Array.isArray(cell?.value)) {
                    content = cell?.value.join(", ");

                    if (content.length > 50) {
                      truncate = true;
                    }
                  }

                  if (cell.value?.length > 50) {
                    truncate = true;
                    content = cell.render("Cell");
                  } else {
                    content = cell.render("Cell");
                  }

                  return (
                    <TableCellCustom
                      {...cell.getCellProps()}
                      style={{
                        maxWidth: "20px",
                      }}
                    >
                      <Tooltip
                        title={truncate ? content : ""}
                        style={{ textOverflow: "ellipsis" }}
                      >
                        <div className="text-cell">{content}</div>
                      </Tooltip>
                    </TableCellCustom>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NestedTable;
const TableHeadCustom = styled(TableHead)`
  & > tr {
    background-color: #EAEAEA;
    & > th {
      color: black;
      font-weight: 500;
    }
  }
`;

const TableCellCustom = styled(TableCell)`
  & > div {
    overflow: hidden;
    white-space: nowrap;
  }

  & > td {
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;