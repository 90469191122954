import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import React from "react";


function getTitle(tagVisibility, dataType, found) {


    const dataTypeTitles = {
        "SNV_Germline": {
            "extra_tag": "Tag SNV Variant",
            "sample_tag": "Update/Delete Tag",
            "no_tag": "Update/Delete Tag"
        },
        "Treatment_Somatic": "Tag Clinical Evidence",
        "Pharmacogenomics_Germline": "Tag Haplotype",
        "SNV_Somatic": "Tag for SNV Somatic"
    };

    if (dataType in dataTypeTitles) {
        const titleMapping = dataTypeTitles[dataType];
        if (typeof titleMapping === "string") {
            return <MappedText text={titleMapping} />;
        } else if (tagVisibility in titleMapping) {
            return <MappedText text={titleMapping[tagVisibility]} />;
        }
    } else if (dataType === "CNV") {
        if (found !== undefined && found.length > 0) {
            return <MappedText text={"CNV variant already Tagged"} />;
        } else {
            return <MappedText text={"Tag CNV Variant"} />;
        }
    }
}


export default getTitle;