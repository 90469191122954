import React from 'react';
import { Chip } from '@material-ui/core';

const QueryChips = ({ queryNumbers }) => {
    const { total, snv_total, cnv_total } = queryNumbers;

    return (
        <div className={"nVariants"}>
           {/* <Chip size="small" label={`Total: ${total}`} />*/}
            <Chip size="small" label={`SNV positions: ${snv_total !== -1 ? snv_total : 'NA'}`} />
            <Chip size="small" label={`CNV regions: ${cnv_total !== -1 ? cnv_total : 'NA'}`} />
        </div>
    );
};

export default QueryChips;
