import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";


//Set the styles
const useStyles = makeStyles(() => ({
    table: {
        minWidth: "600px"
    },
}));


function TableAnalyses(props){

    const table_classes = useStyles();

    if(props.data.length>0){
        return <Box p={2} style={{width: "700px" }}>
            <Grid container direction={"row"}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className={table_classes.table}>
                        <TableRow>
                            <TableCell><strong>Username</strong></TableCell>
                            <TableCell ><strong>Date</strong></TableCell>
                            <TableCell ><strong>Analysed (T/F)</strong></TableCell>
                            <TableCell ><strong>Analyzed Status</strong></TableCell>
                        </TableRow>
                        <TableBody>
                            {props.data.map((row, index) => {

                                //let data = new Date(row.date);
                                //let variant_date = row.date;

                                return (
                                    <TableRow
                                        key={index}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.username}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell >{JSON.stringify(row.analysed)}</TableCell>
                                        <TableCell >
                                            <GPAP_Tooltip title={row.comment}>
                                                <div>
                                                    {row.comment}
                                                </div>
                                            </GPAP_Tooltip>
                                        </TableCell>

                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    }
    else{
        return <Typography variant={"h6"}>There are no previous analyses</Typography>
    }




}


export default TableAnalyses;