import auth from '../session/Auth'

const config = window.config;
const URL_API_NS = config.apis_configuration.api_nextgpap_server; 

// POST to insert a new DIR
export const dir_insert = (data) => {
    
    return fetch(URL_API_NS + "/dir", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': auth.getToken()},
    });
}

