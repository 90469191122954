import {
    EXPORT_COLUMNS,
    POPULATION_COLUMNS,
    PREDICTIVE_COLUMNS, TRANSCRIPT_COLUMNS
} from "../../../../../../../../../config-files/export_columns";


const columns_keys = EXPORT_COLUMNS;



function getFormattedDate(date){
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let minute = date.getMinutes();
    let hour = date.getHours();

    return day + "-" + (month+1) + "-" + year + "-" + hour + "." + minute;

}

function create_string(item, keys, separator){

    let col_array = [];

    keys.forEach(key => {
        let val = item[key];
        if(typeof val === "string"){
            val = val.replaceAll(",", " ");
        }
        else if(val === undefined){
            val = "NA"
        }

        col_array.push(val)

    });
    return col_array.toString() + separator
}


const formatField  = (value) => {


return (value !== undefined) ? value : "NA" 

}

// pass the variants;
const save_CSV_file = function(json){

    //separators
    let token_separator_field = ",";
    let token_separator_line = '\n';

    //info of tab lines
    let main_line_string = "";
    let transcript_line_string = "";
    let predictive_line_string = "";
    let population_line_string = "";
    let sample_line_string = "";
    let full_line_string = "";
    let version_line_string = "";

    let data = json.snv.hits;

    //init column titles
    //samples array must be sorted before

    if(data.length!== 0 && data.hits.length!== 0 ){
        data.hits[0].fields.samples_germline.sort(function(a,b){ return a.sample.localeCompare(b.sample);});

        for ( let t = 0; t < data.hits[0].fields.samples_germline.length; t++) {
            let sample_id = data.hits[0].fields.samples_germline[t];
            let sample = (sample_id!== undefined) ? sample_id.sample : "_sample";
            sample_line_string =  sample_line_string + "GT_" + sample + token_separator_field + "GQ_" + sample + token_separator_field
                + "DP_" + sample + token_separator_field + "AD_" + sample + token_separator_field;
        }

        let all_keys =  columns_keys.concat(sample_line_string.split(','));
        let columns_header =all_keys.toString() + token_separator_line;

        //fit column titles
        const MAX =  (window.config.max_sortable_svn_rows) ? window.config.max_sortable_svn_rows : 1000;
        for (let i = 0; i < MAX; i++) {
            //main table
        
            let item = data.hits[i];

            if(item !== undefined){
                main_line_string = int_To_Chrom(item._source.chrom) + token_separator_field + item._source.pos + token_separator_field + item._source.ref + token_separator_field
                + item._source.alt + token_separator_field + formatField(item._source.rs) + token_separator_field;

            //predictive tab

            predictive_line_string = create_string(item._source, PREDICTIVE_COLUMNS, token_separator_field);

            //population tab

            population_line_string = create_string(item._source, POPULATION_COLUMNS, token_separator_field);

            //samples array must be sorted before
            item.fields.samples_germline.sort(function(a,b){ return a.sample.localeCompare(b.sample);});
            sample_line_string = "";
            for (let k = 0; k < item.fields.samples_germline.length; k++) {
                let sample_id  = item.fields.samples_germline[k];
                sample_line_string = sample_line_string + formatField(sample_id.gt) + token_separator_field + formatField(sample_id.gq)
                    + token_separator_field + formatField(sample_id.dp) + token_separator_field + formatField(sample_id.ad) + token_separator_field;
            }

            //functional tab
            for (let j = 0; j < item.fields.effs.length; j++) {

                let transcript = item.fields.effs[j];
                transcript_line_string = create_string(transcript, TRANSCRIPT_COLUMNS, token_separator_field);
                full_line_string = full_line_string + (main_line_string + transcript_line_string + predictive_line_string + population_line_string + sample_line_string + token_separator_line);
            }
            }
            else{
                console.log("Error. Can't prepare data for export.")
            }
           
        }

        //timestamp and versions of platform & dataset
        let date = new Date();
        let now = getFormattedDate(date);

        //generating downloading .csv file
        let blob = new Blob([columns_header + full_line_string], {type: "octet/stream"});
        let fileName = "gpap_" + now + "_" +version_line_string + ".csv";
        let downloadElement = document.createElement("a");
        downloadElement.href = window.URL.createObjectURL(blob);
        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();
    }else{
        alert("Error. Can't prepare data for export.")
    }

};


const int_To_Chrom= function(chrom){
    let output=chrom;
    if (chrom==="23") output="MT";
    if (chrom==="24") output="X";
    if (chrom==="25") output="Y";
    return output
};



export default save_CSV_file;