



import withStyles from "@material-ui/core/styles/withStyles";
import AccordionSummary from "@material-ui/core/AccordionSummary";


const IconLeftExpansionPanelSummary = withStyles({
    expandIcon: {
        order: -1,
        transform: "rotate(-90deg)"
    }
})(AccordionSummary);


export default IconLeftExpansionPanelSummary;