import {
  createTitle,
  createKeyValueParagraph,
  createParagraphWithSpacing,
  createBoldUnderlinedParagraph,
  createHeadTitle,
  createSpacer
} from "../../write-sections/write_utils/documentHelpers";

function AnnexesSomatic(allDataApis) {

  
  return [
    createHeadTitle("ANNEXES"),
    createSpacer(50, 50),
    createTitle("The following annexes complete the report, which must be evaluated in its entirety."),
    createSpacer(50, 50),
    createTitle("ANNEX I: CLINICAL RECORD"),
    createTitle("ANNEX II: TUMOUR GENOMICS CHARACTERISTICS"),
    createTitle("ANNEX III: OTHER BIOMARKERS"),
    createTitle("ANNEX IV: METHODOLOGY"),
    createTitle("ANNEX V: QUALITY CONTROL METRICS"),
    createBoldUnderlinedParagraph("ANNEX I: CLINICAL RECORD"),
    createParagraphWithSpacing("Cancer type", true),
    createParagraphWithSpacing(allDataApis?.participants?.[0].cancer_type || "NA"),
    createParagraphWithSpacing("Family history", true),
    createParagraphWithSpacing(allDataApis?.annexesData?.family_history || "NA"),
    createParagraphWithSpacing("Treatment", true),
    createParagraphWithSpacing(allDataApis?.annexesData?.treatment || "NA"),
    createBoldUnderlinedParagraph("ANNEX II: TUMOUR GENOMICS CHARACTERISTICS"),
    createKeyValueParagraph("Purity", allDataApis?.tumor?.tumor_purity || "NA"),
    createKeyValueParagraph("Average ploidy", allDataApis?.tumor?.average_ploidy || "NA"),
    createBoldUnderlinedParagraph("ANNEX III: OTHER BIOMARKERS"),
    createKeyValueParagraph("Microsatellite status", allDataApis?.tumor?.microsatellite_status || "NA"),
    createKeyValueParagraph("Tumour mutation load", allDataApis?.tumor?.tumor_mutational_load || "NA"),
    createKeyValueParagraph("Tumour mutational burden", allDataApis?.tumor?.tumor_mutational_burden || "NA"),
    createKeyValueParagraph("Homologous Recombination Deficiency", allDataApis?.tumor?.homologous_recombination_deficiency_status|| "NA"),
    createKeyValueParagraph("Mutational profiles", allDataApis?.tumor?.mutational_profiles || "NA"),
    createKeyValueParagraph("Molecular tissue of origin prediction", allDataApis?.tumor?.molecular_tissue || "NA"),//Pending
    createKeyValueParagraph("Tumor specific viral insertions", allDataApis?.tumor?.tumor_specific || "NA"),//Pending
    createBoldUnderlinedParagraph("ANNEX IV: METHODOLOGY"),
    createKeyValueParagraph("Test method", `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` || "NA"),
    createKeyValueParagraph("Type of variants assessed", allDataApis?.type_of_variants_assessed || "NA"),//Pending
    createKeyValueParagraph("Sample received", allDataApis?.annexesData?.sample_received || "NA"),//Pending
    createKeyValueParagraph("Sample type", allDataApis?.samples.sample_type|| "NA"),
    createKeyValueParagraph("Sample condition", allDataApis?.samples.sample_status),
    createKeyValueParagraph("Site of tumour", allDataApis?.samples.sample_tissue_of_origin),
    createKeyValueParagraph("Treatment given before sample was taken", allDataApis?.annexesData?.treatment || "NA"),//Pending
    createKeyValueParagraph("Date of sample collection", allDataApis?.samples.date_of_sample_collection || "NA"),
    createSpacer(50, 50),
    createTitle("Sample information:"),
    createSpacer(50, 50),
    createKeyValueParagraph("DNA purity", allDataApis?.dna_purity || "NA"),//Pending
    createKeyValueParagraph("DNA integrity", allDataApis?.dna_integrity || "NA") ,//Pending
    createSpacer(50, 50),
    createTitle("Sample processing:"),
    createSpacer(50, 50),
    createKeyValueParagraph("Center", allDataApis?.centre  || "NA"),//Pending
    createKeyValueParagraph("Sequencing platform", allDataApis?.sequencing_platform || "NA"),//Pending
    createKeyValueParagraph("Fragment (size and type)", allDataApis?.fragment || "NA"),//Pending
    createSpacer(50, 50),
    createTitle("Bioinformatics analysis:"),
    createSpacer(50, 50),
    createKeyValueParagraph("Human reference genome", allDataApis?.human_reference_genome || "NA"),//Pending
    createKeyValueParagraph("Aligner", allDataApis?.aligner || "NA"),//Pending
    createKeyValueParagraph("Mark duplicates", allDataApis?.mark_duplicates || "NA"),//Pending
    createKeyValueParagraph("Base recalibration", allDataApis?.base_recalibration || "NA"),//Pending
    createKeyValueParagraph("Smart variants caller", allDataApis?.smart_variants_caller || "NA"),//Pending
    createKeyValueParagraph("Analysis pipeline", allDataApis?.analysis_pipeline || "NA"),//Pending
    createSpacer(50, 50),
    createBoldUnderlinedParagraph("ANNEX V: QUALITY CONTROL METRICS"),  
  ];  
}

export default AnnexesSomatic;