export const preFilter = (
  mainData,
  isCheck,
  configFilter,
  compactColumns = [],
  compactColumnsSplit = [],
  setPreFilteredData,
  setDataFilter
) => {

  const preFilter = mainData?.reduce((acc, originalItem, index) => {

    let item = { ...originalItem };

    const data = isCheck ? compactColumnsSplit : compactColumns;

    if (compactColumns || compactColumnsSplit) {
      data.forEach((compactColumn) => {
        const valueBelong = item[compactColumn.dataSearch]?.toLowerCase();
        compactColumn.rowSelect.forEach((element) => {
          if (valueBelong === element.value.toLowerCase()) {
            let value = (item[element.dataSearch] === "") ? "N/A" : item[element.dataSearch]
            item[compactColumn.nameColumn] = value;
          }
        });
      });
    }

    if (
      item[
        isCheck
          ? configFilter[1].split.dataSearch
          : configFilter[0].full.dataSearch
      ].toLowerCase() ===
      (isCheck
        ? configFilter[1].split.keyFilter.toLowerCase()
        : configFilter[0].full.keyFilter.toLowerCase())
    ) {
      acc.push({ ...item, originalIndex: index });
    }
    return acc;
  }, []);

  setPreFilteredData(preFilter);
  setDataFilter(preFilter);
};