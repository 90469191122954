import getGPAPLabelIcon from "../../../../../../../../../gpap-lib/icons/GetLabelIcon";


const getMenuItems  = (select_label_options) => {


    return [{
        key: "important",
        label: "Important",
        icon: getGPAPLabelIcon("Important"),
        onClick: () => select_label_options("Important")
    },
        {
            key: "follow-up",
            label: "Follow-Up",
            icon: getGPAPLabelIcon("Follow-Up"),
            onClick: () => select_label_options("Follow-Up")
        },
        {
            key: "discarded",
            label: "Discarded",
            icon: getGPAPLabelIcon("Discarded"),
            onClick: () => select_label_options("Discarded")
        },
        {
            key: "candidate",
            label: "Candidate",
            icon: getGPAPLabelIcon("Candidate"),
            onClick: () => select_label_options("Candidate")
        }]

}


export default getMenuItems;