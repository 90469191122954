import {min} from "react-table/src/aggregations";


function roh_parser(minsize, data){


   /* let data = {
        "rohs_id":2099,
        "hom": "1 166247063 167032796 785.734 21 0.095 E000003\n2 209036582 210010180 973.599 26 0.077 E000003\n5 129073197 131730807 2657.611 26 0.077 E000003\n6 42194286 42896837 702.552 23 0.087 E000003\n8 61750860 62531670 780.811 22 0.091 E000003\n8 142470210 143603418 1133.209 34 0.059 E000003\n9 97050295 97873957 823.663 22 0.045 E000003\n9 114982798 115759519 776.722 25 0.080 E000003\n11 44640792 45264519 623.728 20 0.050 E000003\n11 48145247 49196652 1051.406 62 0.032 E000003\n11 124958844 125827209 868.366 36 0.056 E000003\n14 64908845 65568215 659.371 33 0.030 E000003\n15 52005713 52556429 550.717 26 0.077 E000003\n15 72071236 72902518 831.283 32 0.063 E000003\n15 74529130 75561253 1032.124 37 0.054 E000003\n16 7759164 8807100 1047.937 23 0.087 E000003\n17 18539304 19445905 906.602 58 0.052 E000003\n17 37761185 38556770 795.586 55 0.055 E000003\n17 46607021 47114392 507.372 28 0.071 E000003\n18 55685630 56296138 610.509 32 0.063 E000003\n23 46314975 49178536 2863.562 73 0.041 E000003\n23 149013727 152228523 3214.797 47 0.043 E000003\n23 152228735 155004280 2775.546 90 0.033 E000003\n"
    };*/

    let chromosome_list = [];


    if (data.hom) {

        let lines = data.hom.split(/\n/);
        const TH = 0;
        const MIN_SIZE = (minsize >= TH ) ? minsize : TH;


        let filtered = lines.filter(roh=> {
            let roh_l = roh.split(' ')[3];
            return roh_l>= MIN_SIZE
        });

        chromosome_list= filtered.map(function(roh_line){
            let splitted_line= roh_line.split(' ');
            let chr = splitted_line[0];
            if (parseInt(chr) === 23 ){
                let chr = 24;
            }

            return {
                "chrom": chr,
                "pos": splitted_line[1],
                "end_pos": splitted_line[2]
            }
        });

    }

    return chromosome_list
}



//warning;

/*if ((serviceA.rohs[0].checked == true) || (serviceA.rohs[1].checked == true) || (serviceA.rohs[2].checked == true)){
    serviceA.all_ranges.push('{"chrom":1,"pos":1,"end_pos":2}');
}

if (serviceA.chrom.chrom == "MT") {
    serviceA.chrom.chrom = 23
}

if (serviceA.chrom.chrom == "X") {
    serviceA.chrom.chrom = 24
}

if (serviceA.chrom.chrom == "Y") {
    serviceA.chrom.chrom = 25
}

//Warning only if we have not run PLINK(no warning if there are just 0 RoH for the sample)
if (data.hom === undefined){
    var rohexis = "Noroh";
}

var str = JSON.stringify(serviceA.chrom);
var str2 = str.replace(/end/g, 'end_pos');

serviceA.chrom_ranges.push(str2);

if (((serviceA.rohs[0].checked == false) && (serviceA.rohs[1].checked == false) && (serviceA.rohs[2].checked == false)) && (serviceA.chrom.chrom) ){
    //If start or end positions are null search all the chromosome
    if (serviceA.chrom.pos === null || serviceA.chrom.end === null){
        serviceA.all_ranges = [{"chrom":serviceA.chrom.chrom,"pos":-1,"end_pos":-1}]
    }

    else{
        serviceA.all_ranges = serviceA.chrom_ranges;
    }
}
}

//SHow a warning if there are no ROH for the sample
if ((rohexis == "Noroh") && ((serviceA.rohs[0].checked == true) || (serviceA.rohs[1].checked == true) || (serviceA.rohs[2].checked == true))){
    serviceA.warning = "Runs of homozygosity were not investigated for the affected sample. Please uncheck the runs of homozygosity filter, and re-run your query."
}

//SHow a warning if there is no selected affected sample
if ((affexis == "Noaff") && ((serviceA.rohs[0].checked == true) || (serviceA.rohs[1].checked == true) || (serviceA.rohs[2].checked == true))){
    serviceA.warning = "In order to use the runs of homozygosity filter you must select a single sample as affected."
}*/

export {roh_parser};
