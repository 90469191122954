


import React, {Component} from "react";
import "./toolbar_results.css"
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from '@material-ui/icons/FilterList';
import Collapse from "@material-ui/core/Collapse";
import VariantSummary from "../../../variant-tabs/VariantSummary";
import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as SidebarActions from "../../../../../../../../components/SideNavigation/sidebar_actions";
import PluginMenu from "./Plugins/PluginMenu";
import ViewAppliedFilters from "../view-applied-filters/ViewAppliedFilters";
import ExportVariants from "./export/ExportVariants";
import {GPAP_Button} from "../../../../../../../../gpap-lib/components/Button_Collection";
import {BarChartOutlined} from "@material-ui/icons";

class ToolBar extends Component{

    constructor(props){
        super(props);

        this.state = {
            checked:false,
            mode: true,   // true is variants, false is functional
            open:false,
            tableType: true,  // true is variants, false is participants;
            table_checked: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeMode = this.handleChangeMode.bind(this);
        this.handleTableType = this.handleTableType.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleOpen(){
        this.setState({open: !this.state.open})
    }


    handleChange(){

        this.setState({checked: !this.state.checked})

    }

    handleChangeMode(){

        this.setState({mode: !this.state.mode});
        this.props.setViewMode(!this.state.mode);
    }

    handleTableType(){

        this.setState({tableType: !this.state.tableType});
        this.props.setTableType(!this.state.tableType);
    }


    render(){

        let self = this;
        return <div className={"toolbar_results"}>
            <GPAP_Tooltip title="Filters">
                <GPAP_Button
                    id={"new_query"}
                    aria-label="filters"
                    onClick={()=> self.props.sidebarActions.setView({page:"Query"})} startIcon={  <FilterListIcon />}>
                    New Query
                </GPAP_Button>
            </GPAP_Tooltip>
            <GPAP_Tooltip title="Variants distributions - (based on max 3000 entries)" >
                <IconButton
                    id={"charts_variants"}
                    color = {(this.state.checked ? "primary" : "default" )}
                    aria-label="Details" onClick={this.handleChange}>
                     <BarChartOutlined/>
                </IconButton>
            </GPAP_Tooltip>
                <PluginMenu data={self.props.data} passAnnotations = {self.props.passAnnotations}/>
                <ExportVariants/>
            <ViewAppliedFilters whichQuery="current"/>

            <div>
                <Collapse in={self.state.checked}>
                    <VariantSummary
                        data = {self.props.data}
                        total = {[]}
                    />
                </Collapse>
            </div>

        </div>

    }


}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.queryContainer !== undefined)
        {
            return {studySettings: state.studySettings}
        }

    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});


export default connect(null,mapDispatchToProps)(ToolBar);