
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const styles = {
    root: {
        backgroundColor: '#3182bd',
        color: '#fff',
    },
};

export const GPAPChip = withStyles(styles)(({ classes, label, ...props }) => (
    <Chip
        label={label}
        className={classes.root}
        {...props}
    />
));



