import TableCell from "@material-ui/core/TableCell/TableCell";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import React from "react";


function ExperimentRow(props){

    let { experimentRow } = props;

    const origin = window.location.origin;

    if(experimentRow.hasOwnProperty("ExperimentID"))
    {
        return <TableRow key={experimentRow.ExperimentID}>
            <TableCell component="th" scope="row">
                <Button target="_blank" href={origin + "/datamanagement_2/#/experimentinfo/" + experimentRow.ExperimentID}>{experimentRow.ExperimentID}</Button>
            </TableCell>
            <TableCell>{experimentRow.erns}</TableCell>
            <TableCell align="right">{experimentRow.processed}</TableCell>
            <TableCell align="right">
                {experimentRow.kit}
            </TableCell>
            <TableCell align="right">
                {experimentRow.library_strategy}
            </TableCell>
            <TableCell align="right">
                {experimentRow.tissue}
            </TableCell>
        </TableRow>
    }
    else{
        return <TableRow key={experimentRow.RD_Connect_ID_Experiment}>
            <TableCell component="th" scope="row">
                <Button target="_blank" href={origin + "/datamanagement_2/#/experimentinfo/" + experimentRow.RD_Connect_ID_Experiment}>{experimentRow.RD_Connect_ID_Experiment}</Button>
            </TableCell>
            <TableCell>{experimentRow.erns}</TableCell>
            <TableCell align="right">{experimentRow.processed}</TableCell>
            <TableCell align="right">
                {experimentRow.kit}
            </TableCell>
            <TableCell align="right">
                {experimentRow.library_strategy}
            </TableCell>
            <TableCell align="right">
                {experimentRow.tissue}
            </TableCell>
        </TableRow>
    }



}


export default ExperimentRow;