import React from "react";
import { Grid, Typography  } from "@mui/material";
import getDisclaimer from "./disclaimerConfig";


export default function Disclaimer ({analysisType}){
    let disclaimer = getDisclaimer(analysisType);
    
    return (
        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body1">
                {disclaimer}
                </Typography>
            </Grid>
        </Grid>
    )
}