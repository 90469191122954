
import Typography from "@material-ui/core/Typography";
import Gene_Section_View from "../gene-section/Gene_SectionView";
import React from "react";

import Box from "@material-ui/core/Box";



function GeneFilters () {



    return <Box p={2}>
            <Gene_Section_View/>
        </Box>


}

export default GeneFilters;