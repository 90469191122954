import React, {useEffect, useState} from "react";

import {connect} from "react-redux";
import RenderPatientCard from "../../components/clinical-info/PatientCard/renderPatientCard";
import WarningBox from "../../../WarningBox";
import {getCurrentAnalysis} from "../../../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/getAnnotations";
import Box from "@material-ui/core/Box";
import {
    getParticipantBySample,
    getSingleCohort
} from "../../../../../../services/api/get";
import {CircularProgress, Typography} from "@material-ui/core";
import FiltersTable from "./FiltersTable";
import {parse_date} from "../../../../helpers";
import {GPAP_Button} from "../../../../../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import CreateReportBtn from "./CreateReportBtn";


function SampleSummary(props){


    const [patients, setPatients] = useState([])
    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(false)


    let {selected_cohort} = props;

    let analysis_current = getCurrentAnalysis(props.studySettings, props.studyBucket);

    let samples = (analysis_current.analysis) ? analysis_current.analysis.samples : [];

    const [cohort, setCohort] = useState(false);



    useEffect( function () {

        if(!fetched){
            let participants_ids = samples.map(s => s.pheno_id);
            getParticipantEntries(participants_ids)
            setFetched(true);
        }


    })


    const getDate = (creationDate) => {

        let data = new Date(creationDate);
        return parse_date(data);

    }

    const getParticipantEntries = async (array_ids) => {

        if(array_ids.filter(s => s!== null).length>0){

            let filtered = [{"id": "report_id", "value": array_ids}];
            let data_post = {"page": 1, "pageSize": 10, "sorted": [], "filtered": filtered, "aggrKeys": [], "fields": []};
            setLoading(true);
            getParticipantBySample(config.apis_configuration.api_phenostore, props.token, data_post)
                .then(function(resp){
                    if(resp.ok){
                        setLoading(false);
                        return resp.json();
                    }
                    else{
                        setLoading(false);
                    }
                })
                .then(json => {
                    setPatients(json.rows);
                })
        }

    }

    const renderPatientView = () => {

        if(fetched){

            let first = "Yes";
            patients.sort(function(x,y){ return x.index === first ? -1 : y.index === first ? 1 : 0; });

            return patients.map(function (patient, index) {

                // get samples if exists
                let sample = false;
                if (samples !== undefined && Array.isArray(samples)) {
                    sample = samples.find(sa => sa.pheno_id === patient.report_id)
                }
                if (sample !== undefined) {
                    return <RenderPatientCard
                        key={"card_" + index}
                        readOnly={true}
                        participant={patient}
                        sample={sample}
                        experiment_id={sample.sample_id}/>
                } else {
                    return <WarningBox
                        key={"warning_" + index}
                        text={"An Experiment could not be found"}
                    />
                }
            })
        }
        else{
            return <Typography>
                Can't get info
            </Typography>
        }




    }


    const getReportTool = () => {

        if(fetched && patients.length>0){

            let index_case = patients.find(s => s.index === "Yes");

            let disable= true;
            let report_id = undefined;
            if(index_case!== undefined){
                disable = false;
                report_id = (index_case.report_id!== undefined) ? index_case.report_id : undefined;
            }

            if(report_id!== undefined){
                return <div >
                    <Box p={0}>
                        <CreateReportBtn
                            disable={disable}
                            participant_id={report_id}
                            label={"Create a Report- Draft"}
                            title={"Create a Report - Draft"}
                            tooltipText={"Create a report for the index case in the family"}
                        />
                    </Box>
                    </div>
            }
            else{
                return null;
            }
        }
        else{
            return null;
        }



    }

    /*const renderPedigree = () => {

        let patients = props.c_patients;

        if(!cohort){
            let index = false;
            if(Array.isArray(patients)){

                index = patients.find(p => p.index === "Yes");
                if(index !== undefined){
                    return <Box p={2}>
                        <Typography variant={"h6"} color={"textSecondary"}>Pedigree of Index Case</Typography>
                        <PedigreeViewerOnly index_case_id={index}/>
                    </Box>
                }
                else{
                    return null;
                }
            }
            else{
                return null;
            }



        }


    }*/


    const renderCohort = () =>{

        return <Box p={1}>
            <Typography> {cohort.cohort.data.length} Experiments from selected cohort</Typography>
            <div><strong>Cohort Name: </strong>{cohort.cohort.cohortName}</div>
            <div><strong>Creation Date: </strong>{getDate(cohort.cohort.creationDate)}</div>

            <Box p={1}>
                <Typography variant={"body1"} color={"textSecondary"}>Applied Filters</Typography>
                <FiltersTable filters={ cohort.cohort.filters }/>
            </Box>
        </Box>

    }

    if(selected_cohort!== undefined && !cohort){

        if (typeof selected_cohort !== "object"){
            getSingleCohort(config.apis_configuration.api_cohortapp, props.token, selected_cohort)
                .then(s => s.json())
                .then(json => setCohort(json));
        }

    }

    if(cohort){

        return renderCohort();

    }
    else{
        return <React.Fragment>
            {getReportTool()}
            { (loading)
                ? <CircularProgress/>
                : renderPatientView()  }
            {/* {renderPedigree()}*/}
        </React.Fragment>
    }


}



//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            c_patients: state.patients,
            token: state.authorization.token,
            samples: state.sample_list.samples,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            selected_cohort: state.selected_cohort
        }
    }
    else{
        return {patients: [], token: state.authorization.token}
    }
}




export default connect(mapStateToProps,null)(SampleSummary);