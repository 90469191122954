

import React, {useEffect, useMemo, useRef} from 'react'
import styled from 'styled-components'
import {useTable, useRowSelect, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy  } from "react-table";

import Box from "@material-ui/core/Box";
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {matchSorter} from "match-sorter";
import Checkbox from '@mui/material/Checkbox';
import {GPAP_Button} from "../Button_Collection";

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

const Styles = styled.div`
  padding: 1rem;
  border-radius: 10px;

  table {
    border-spacing: 0;
    /*
    border: 1px solid black;
    */

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      //border-bottom: 1px solid #3E69A9;
      border-right:  1px solid var(--primary-contrast, #FFF);
      gap: 30px;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`





// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length

    return (
        <input
            id="search-study-home"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
            style={{ width:"143px", height:"40px", borderRadius: "4px", border: "solid 1px", borderColor:"rgba(0, 0, 0, 0.23)", padding:"0 0 0 20px"}}
        />
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
                                column: { filterValue, setFilter, preFilteredRows, id },
                            }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table({ columns, data, showCheckbox }) {
    // Use the state and functions returned from useTable to build your UI

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const modifiedColumns = useMemo(() => {
        if (showCheckbox) {
            return [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <Checkbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ];
        }
        return columns;
    }, [columns, showCheckbox]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        state,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
        {
            columns: modifiedColumns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageSize: 10 }
        },


        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!,
        useSortBy,
        usePagination,
        useRowSelect
        ,
       /* hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }*/
    )

    // Render the UI for your table
    return (<div>
            <Box   style={{    overflow: "auto"}}>
                <MaUTable
                    //style ={{ tableLayout:"fixed", }}
                    {...getTableProps()}
                >
                    <TableHead style={{background: "#3E69A9", color: "white"}}>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} style={{color: "white"}}>
                            {headerGroup.headers.map(column => (
                                <TableCell  {...column.getHeaderProps(column.getSortByToggleProps(), {
                                })} style={{color: "white", width: "20px"}} >
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                  </span>
                                </TableCell>
                            ))}
                        </TableRow>

                    ))}


                      {/*  <TableRow>
                            <TableHead
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: 'left',
                                }}
                            >
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                            </TableHead>
                        </TableRow>*/}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} style={{color: "white"}}>
                            {headerGroup.headers.map(column => (
                                <TableCell {...column.getHeaderProps()} style={{color: "white"}} >

                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>

                                </TableCell>
                            ))}
                        </TableRow>

                    ))}
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <TableRow {...row.getRowProps()}>

                                {row.cells.map(cell => {
                                    return <TableCell {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </TableCell>
                                })}
                            </TableRow>
                        )
                    })}
                    </TableBody>
                </MaUTable>
            </Box>
            <Box>
                <div className="pagination">
                    <GPAP_Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </GPAP_Button>{' '}
                    <GPAP_Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </GPAP_Button>{' '}
                    <GPAP_Button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </GPAP_Button>{' '}
                    <GPAP_Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </GPAP_Button>{' '}
                    <span>
          Page{' '}<strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
                    <span>
          | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px',  border: "solid 1px", borderColor:"rgba(0, 0, 0, 0.23)" }}
                        />
        </span>{' '}
                    <select
                        value={pageSize}
                        style={{border: "solid 1px", borderColor:"rgba(0, 0, 0, 0.23)" }}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                        {[5,10,15].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </Box>
        </div>
    )
}

function GeneralTable(props) {
    const columns = useMemo(
        () => props.columns,
        []
    )

    const data = props.data;
    const showCheckbox = props.showCheckbox;

    return (
        <Styles>
            <Table columns={columns} data={data} showCheckbox={showCheckbox} />
        </Styles>
    )
}

export default GeneralTable
