import React, {useState, useEffect} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Success from "../Success";
import {GPAP_RunButton} from "../../../../../../gpap-lib/components/Button_Collection";
import Sample_Section from "../samples-section/Sample_Section";
import ClinicalInfo from "../clinical-info/ClinicalInfo";
import Inheritance_Mode_renderer from "../InheritanceMode/Inheritance_Mode_renderer";
import GB_renderer from "../GenotypeBuilder/GB_renderer";
import StartFilters
    from "../../../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/start-filters/StartFilters";
import {connect} from "react-redux";
import {Container} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {QueryBuilder} from "@material-ui/icons";
import {step_form_labels} from "../../../../../../config-files/family_stepform_sections";
import AlertDialogStepForm from "./AlertDialogStepForm";
import {AlertInfo} from "../samples-section/SelectionInfo";
import GPAP_Dialog from "../../../../../../gpap-lib/components/GPAP_Dialog";
import { checkCustomGenotype } from "../GenotypeBuilder/helpers/checkCustomGenotype";
import CustomNoGenotypeMessage from "../GenotypeBuilder/components/GenotypeForm/CustomInheritance_NoGenotype";

// Step titles

const labels= step_form_labels;

const StepForm = (props) => {

    let {experiments}  = props;
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);

    const [dialogIndexes, setDialogIndexes] = useState(false);
    const [openDialogIndex, setOpenDialogIndex]= useState(false)


    const [customNoGQ, setCustomNoGQ] = useState(false);

   /* const handleClickOpen = () => {
        setDialogOpen(true);
    };*/
    useEffect(() => {
        if (dialogIndexes) {
            setOpenDialogIndex(true);
        }
    }, [dialogIndexes]);

    useEffect(() =>{
        let indexSamplesCount= props.samples.filter(sample=> sample.sample_type ==="index").length;
        if(indexSamplesCount > 1){
            setDialogIndexes(true);
        
        }else{
            setDialogIndexes(false);
        }
    },[props.samples])



    useEffect(() => {
        checkCustomGenotype(props.samples, props.inheritance, setCustomNoGQ);
      }, [props.samples, props.inheritance]);


    const handleCloseDialogIndex = () => {
        setOpenDialogIndex(false);
        setDialogIndexes(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const checkGenotypes = () => {

        let {samples, inheritance} = props;
        if(inheritance === "custom" && activeStep === 2){

            //check samples inheritances. if there is any empty.
            return samples.filter(sample => sample.gt_settings[0].setting.length === 0).length === 0;

        }
        else{
            return true;
        }


    }

    // Proceed to next step
    const handleNext = () => {

        let bool = checkGenotypes();

        if(bool){
            window.scrollTo(0, 0);
            setActiveStep((prev) => prev + 1);
        }
        else{
            setDialogOpen(true)
        }

    }
    // Go back to prev step
    const handleBack = () => setActiveStep((prev) => prev - 1);

   const getChetCheck = () => {

       return (props.studySettings.analysis_ids.map(s => s.label).includes("compound heterozygosity") && !props.formTouched)

   }



    const handleSteps = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Container>
                            <Box m={2} style={{textAlign: "center"}}>
                                    <GPAP_RunButton
                                        variant="contained"
                                        disabled={dialogIndexes ||props.samples.length === 0 }
                                        color="primary"
                                        startIcon={<ArrowForwardIcon/>}
                                        onClick={handleNext}>
                                        Next
                                    </GPAP_RunButton>
                                </Box>
                       {/*     <Typography
                                variant="h6"
                                align={"center"}
                                style={{
                                    color: "#4575B4",
                                    padding: "2px",
                                    textTransform: "uppercase"
                                }}
                            >
                                {section_titles[0]}
                            </Typography>*/}
                        </Container>
                        <Sample_Section samples={experiments}/>
                    </Box>
                );
            case 1:
                return (
                    <React.Fragment>
                        <Box m={2} style={{textAlign: "center"}}>
                            <GPAP_RunButton
                                variant="contained"
                                disabled={false}
                                color="primary"
                                startIcon={<ArrowBackIcon/>}
                                onClick={handleBack}
                            >Back</GPAP_RunButton>
                            <GPAP_RunButton
                                variant="contained"
                                disabled={(Array.isArray(props.patients.patients) && props.patients.patients.length === 0)}
                                startIcon={<ArrowForwardIcon/>}
                                color="primary"
                                onClick={handleNext}>
                                Next
                            </GPAP_RunButton>
                        </Box>
                        <Box p={2}>
                           {/* <Typography
                                variant="h6"
                                align={"center"}
                                style={{
                                    color: "#4575B4",
                                    padding: "2px",
                                    textTransform: "uppercase"
                                }}
                            >
                                {section_titles[1]}
                            </Typography>*/}
                        </Box>
                        <Box p={2}>
                        <ClinicalInfo/>
                    </Box>
                    </React.Fragment>
                );
            case 2:
                return (
                    <Box>
                        <Box m={2} style={{textAlign: "center"}}>
                            <GPAP_RunButton
                                variant="contained"
                                disabled={false}
                                onClick={handleBack}
                                startIcon={<ArrowBackIcon/>}
                                color="primary">Back</GPAP_RunButton>
                            <GPAP_RunButton
                                    variant="contained"
                                    disabled={(Array.isArray(props.inheritance) && props.inheritance.length === 0) || (customNoGQ)}
                                    color="primary"
                                    startIcon={<ArrowForwardIcon/>}
                                    onClick={handleNext}>
                                    Next
                                </GPAP_RunButton>
                            </Box>
                           
                        <CustomNoGenotypeMessage show={customNoGQ} />

                        <Inheritance_Mode_renderer/>
                        <GB_renderer/>
                    </Box>
                );

            case 3:
                return (
                    <React.Fragment>
                        <Box m={2} style={{textAlign: "center"}}>
                            <GPAP_RunButton
                                variant="contained"
                                disabled={false}
                                color="primary"
                                startIcon={<ArrowBackIcon/>}
                                onClick={handleBack}
                            >Back</GPAP_RunButton>
                            <GPAP_RunButton id="run_analysis"
                                            variant="contained"
                                            color="primary"
                                            disabled = {getChetCheck()}
                                            startIcon={<QueryBuilder/>}
                                            onClick={() => props.run_analysis()}>Start Analysis</GPAP_RunButton>
                        </Box>
                    <Box>
                        {
                            (getChetCheck())
                            ?  <AlertInfo severity="warning"
                                        message={"To run a C-Het analysis you must add at least one filter."}
                                        title={"No filter selected"}/>
                                : null

                        }
                        <StartFilters/>
                    </Box>
                    </React.Fragment>
                );
            default:
                break;
        }
    };

    return (
        <>
            {activeStep === labels.length ? (
                // Last Component
                <Success values={formValues} />
            ) : (
                <>
                    <AlertDialogStepForm handleClose={handleClose} open={dialogOpen}/>
                    <GPAP_Dialog
                        open={openDialogIndex}
                        handleClose={handleCloseDialogIndex}
                        title={"Multiple Index Cases Detected"}
                        text={"More than one index case was found. Please ensure that you have only a single index case to proceed with the analysis by eliminating any unnecessary experiments."}
                        onConfirmText={"ok"}
                        onExitText={false}
                        onConfirmAction={handleCloseDialogIndex}
                        type="warning"
                    />
                    <Stepper
                        activeStep={activeStep}
                        style={{ margin: "30px 0 15px" }}
                        alternativeLabel>
                        {labels.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {handleSteps(activeStep)}
                </>
            )}
        </>
    );
};


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.sample_list!== undefined)
        {
            // get form variants, populations, snv?effects, position_roh, gene_section
            let touched = false;
            Object.keys(state.form).forEach(function(formEl){
                let VALUES = state.form[formEl].values;
                if(VALUES!== undefined){
                    Object.keys(VALUES).forEach(function(formElKey){

                            let val = VALUES[formElKey];
                            if(Array.isArray(val) && val.length !== 0){
                                touched = true
                            }
                            if (val !== null && !Array.isArray(val) ) {
                                touched = true;
                            }
                        })

                    }})


            return {
                samples: state.sample_list.samples,
                patients: state.patients,
                inheritance: state.inheritance_key,
                token:state.authorization.token,
                studySettings: state.studySettings,
                query: state.query,
                form: state.form,
                studies: state.studies,
                formTouched:touched
            }
        }
        else{
            return {samples: [],  inheritance: state.inheritance, token:state.authorization.token}
        }
    }
}



export default connect(mapStateToProps,null)(StepForm);