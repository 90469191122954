import React from "react";
import { TextField } from "@mui/material";
import OptionSelect from "../selects/OptionSelect";
import DateFilter from "../dates/DateFilter";

function DefaultColumnFilter({
    column: {
      filterValue,
      preFilteredRows,
      setFilter,
      width,
      id,
      filterType,
      data,
    },
  }) {
    const count = preFilteredRows.length;
    //console.log(filterType); 
    if (filterType && filterType.type === 'none') {
      return null;
    }
    if (filterType.type === "select") {
      const options = preFilteredRows.reduce((options, row) => {
        options.add(row.values[id]); 
        return options;
      }, new Set());
  
      const optionsForAutoComplete = Array.from(options).map((option) => ({
        label: option,
      }));
  
      return (
        <>
          <OptionSelect
            optionType={filterType.option}
            options={optionsForAutoComplete}
            setFilter={setFilter}
          />
        </>
      );
    }
    if (filterType.type === "date") {
      return <DateFilter setFilter={setFilter}  />;
    }
    return (
      <TextField
        id="standard-basic"
        size={"small"}
        placeholder={`Search...`}
        variant="outlined"
        onChange={(e) => {
          setFilter(e.target.value || undefined); 
        }}
      />
    );
  }
  

export default DefaultColumnFilter;