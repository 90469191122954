import React , { useEffect }from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import getReferences from "./referenceConfig.js";


const ReferenceField = ({ references }) => (
    <Box>
        <Typography variant="body1">
        {references.map((reference, index) => (
            <li key={index}>{reference}</li>
        ))}
        </Typography>
    </Box>
);



// TODO: can be deleted;
function References({analysisType}) {

    let references = getReferences(analysisType);

    return <ReferenceField references={references} />;
}

export default References;