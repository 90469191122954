

import MuiAlert from "@material-ui/lab/Alert/Alert";
import {ERRORS} from "../../scenes/dashboard/components/genomics-dashboard/check_variant_response";
import React from "react";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getSnackColor = (text) => {

    if(typeof text === 'string' || text instanceof String){
        return ((ERRORS.includes(text) || text.includes("Error"))) ? "error" : "success";
    }
    else{
        return "error";
    }


}

export {Alert, getSnackColor}