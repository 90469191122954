const exomeCoverageData = [
    {
        "mean_coverage": "NA",
        "duplication_rate": "NA",
        "uniformity": "NA",
        "median_coverage": "NA",
        "c10": "NA",
        "c15": "NA",
    },
    
];
export default exomeCoverageData;