

import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {get_ebi_quickGo} from "../../../../../../../../../services/api/variant_external_calls";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function EbiPanel(props){


    const [idArray, setIdArray] = useState([]);

    let dataGoArray = [];

    useEffect(function(){

        if(props.data && props.data.results!== undefined){

            let arrayGoId = props.data.results.map(s => s.goId);
            arrayGoId.forEach(function(d){
                let dataGo_copy = [...dataGo];
                get_ebi_quickGo(d)
                    .then(r => r.json())
                    .then(json => {
                        if (json.results !== undefined) {
                            dataGoArray = dataGo_copy.concat(json.results);

                        }
                    }
                )
            });

            setIdArray(arrayGoId);

        }
    });







    return <Box p={1}>
        <Typography variant={"subtitle1"}>EBI Annotations</Typography>
        <div>
            {(props.data.ebi_annotation) ? JSON.stringify(dataGoArray) : <MappedText text="No Associations"/>} </div>
    </Box>


}





export default EbiPanel;