
import React from "react";
import List from "@material-ui/core/List";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {parse_date} from "../../../../helpers";
import FlagIcon from "@material-ui/icons/Flag";


function TaggedVariants(props){


    let {variants} = props;

  // sort;
    variants.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date((b["timestamp"])) - new Date(a["timestamp"])
    });

    let variantItems= variants.map(function(variant, index){


            let d = variant.timestamp;
            let data = new Date(d);
            let date_formatted;

            if(isNaN(data)){
                date_formatted = d;
            }else{
                date_formatted = parse_date(data);
            }

            return <ListItem key={index}>
            <ListItemIcon>
                <Box p={1} style={{width: "120px"}}>
                    {(["Likely pathogenic", "Pathogenic"].includes(variant.significance))
                        ? <span style={{color: "red", fontWeight: "500"}}> {variant.significance}</span>
                        : <span style={{fontWeight: "500"}}> {variant.significance}</span>
                    }
                </Box>
            </ListItemIcon>
            <ListItemText
                primary={ variant.gene_name + ",chrom " + variant.chromosome + ", pos " + variant.start_pos }
                secondary={date_formatted}
            />
        </ListItem>

    })

    let emptyList = <Box p={2}> No variants have been tagged for these experiments yet </Box>

    return <Box p={1}>
        <Typography variant={"h6"} style={{color: "#4575B4"}}> <FlagIcon  style={{color: "#4575B4"}}/>Tagged variants: {variants.length}</Typography>
        <Divider/>
        <Box style={{maxHeight: "300px", overflow: "auto"}}>
                {(variantItems.length!==0)
                    ? <List dense={true}> {variantItems}</List>
                    : emptyList }
        </Box>
    </Box>
}


export default TaggedVariants;