import React from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";

const BootstrapTooltip = styled(({ className, title, children, isTruncated, forceUnderline, ...props }) => {
  
  const shouldRenderTooltip = Boolean(title);
  
  return shouldRenderTooltip ? (
    <Tooltip placement="top" title={title} arrow classes={{ popper: className }} {...props}>
      <span style={(isTruncated || forceUnderline) ? { borderBottom: "2px dotted #BDBDBD", paddingBottom: "2px" } : {}}>
        {children}
      </span>
    </Tooltip>
  ) : (
    <span style={(isTruncated || forceUnderline) ? { borderBottom: "2px dotted #BDBDBD", paddingBottom: "2px" } : {}}>
      {children}
    </span>
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#717171",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#717171",
    color: "white",
    fontSize: 11,
  },
}));

export default BootstrapTooltip;
