


import {Link, Redirect} from "react-router-dom";
import {GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import React, {Fragment, useState} from "react";
import {connect} from "react-redux";

function BackToStudy_Button(props){

    const [redirect, setRedirect] = useState(false);

    if(props.current_query !== null && props.current_query !== undefined ){
        return (redirect ) ?  <Redirect to={{pathname: "genomics", state: { loadStudy: true } }}/> : <GPAP_Button onClick={setRedirect}> Back to Your Current Work </GPAP_Button>
    }
    else{
        return null;
    }



}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.query !== undefined)
        {
            return { query: {... state.query},
                currentAnalysis: state.studySettings.current_analysis,
                study_id: state.studySettings.id,
                current_query: state.studySettings.current_query

            }
        }

    }
}






export default connect(mapStateToProps,null)(BackToStudy_Button);


