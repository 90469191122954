



import React  from "react";
import AnalysisSummary from "../../../../study-page/create-study/analysis-page/analysis-summary/AnalysisSummary";
import HistoryView from "./side-panels/study-store/StudyStore";
import FilterDialog from "./side-panels/filter-panel/FilterDialog";


// This components manages the Left SideBar Views.
// TODO: now handled by redux but I would prefer to keep it separately as it also seems to be affected in terms of performances

function DrawerProvider(props){

        let view = props.sidebarPage;
        switch(view){
            case "cases":
                return <AnalysisSummary/>;
            case 'Query':
                return (<FilterDialog
                    samples={props.samples}
                    resetQuery = {props.resetQuery}
                    filters = {props.filters}
                    queryRunner = { props.queryRunner }/>);
            case 'history':
                return (<div className="query_view">
                    <HistoryView variants = {props.variants}
                                 queryViewer = { props.queryViewer }
                                 queryDelete = { props.queryDelete }
                    /></div>);
            case 'cohorts':
                return (<div className="query_view">
                </div>);
            default:
                // to check this..
                return null;

        }
}

export default DrawerProvider;