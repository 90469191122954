import React from 'react';
import 'regenerator-runtime/runtime'
import ReactDOM from 'react-dom';
import './index.scss';
import "./scroll_custom.css";
import App from './App';
import WebFont from 'webfontloader';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { InstandTheme, themeOptions } from './src/gpap-lib/themes/instand-theme';
import { ThemeProvider } from 'styled-components';



WebFont.load({
    google: {
        families: ['Roboto:300,400,700', 'sans-serif']
    }
});


import allReducers from './src/reducers'
const middleware = [thunk];  

const store = createStore(
    allReducers,
    compose(
      applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    )
  );

ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={InstandTheme}>
            <App/>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root'));