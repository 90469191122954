import withStyles from "@material-ui/core/styles/withStyles";
import {Tooltip} from "@material-ui/core";

const GPAP_Tooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "#3182bd",
        fontSize: "12px"
    }
})(Tooltip);


export default GPAP_Tooltip