


export const SET_INHERITANCE = "SET_INHERITANCE";






// inheritance;

export function setInheritance(inheritance_key){
    return {
        type: SET_INHERITANCE,
        inheritance_key: inheritance_key
    }
}