import React from 'react';
import { Box, Typography, Chip, Grid, TextField } from '@mui/material';
import MappedText from './mapText';

function Title({ field1, field2, field3, field4, dataType }) {
    const getTitle = () => {
        if (dataType === "variant") {
            return <b>{field1} ({field2}): {field3}</b>;
        } else {
            return <b>{field1} ({field2})</b>;
        }
    };

    return getTitle();
}

const SelectedRowInfoBox = ({ fields, comments, selected, handleOnTextChange, dataType }) => {
    return (
        <Box pt={2}>
            <Box>
                <Typography variant="body1" color={selected ? "inherit" : "textSecondary"}>
                    <Title {...fields} dataType={dataType} />
                    {!selected && <Chip label={"Hidden " + dataType} />}
                </Typography>
                {dataType === "variant" && <Typography variant="body2"><b><MappedText text="Genomic Position:"/> {fields.field4}</b></Typography>}
            </Box>
            <Box pt={1}>
                <Grid container direction="row">
                    <Grid item lg={12}>
                        <Box pt={1}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label=<MappedText text="Comment"/>
                                multiline
                                defaultValue={comments}
                                maxRows={4}
                                style={{ width: '100%' }}
                                disabled={!selected}
                                onChange={handleOnTextChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SelectedRowInfoBox;
