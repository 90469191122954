
const variantMetricsData = [
    {
        "gene": "NA",
        "transcript_cdna": "NA",
        "gt": "NA",
        "gq": "NA",
        "dp": "NA",
        "ref_count": "NA",
        "alt_count": "NA",
        "aaf": "NA"
    }
 
];


    export default variantMetricsData;