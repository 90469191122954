
import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import VChart from "../../../../../../../../../../gpap-lib/components/bar-chart/VChart";



function AggregationView(props){


    let sample_bj = [{d: "", key: "", value: ""}];

    //
   /* let obj = {
        "initData": {
        "sex": "{F: 18, M: 25}",
            "affectedStatus": "{Affected: 16, Unaffected: 19, Unknown: 8}",
            "solved": "{Solved: 5, Unsolved: 38}",
            "index": "{No: 28, Yes: 15}",
            "consanguinity": "{No: 7, Unknown: 33, Yes: 3}"
    }
    }*/

    let data = [];

    Object.keys(props.initData).forEach(function(itemKey){

            let chart_data = [];

            let all_subItemKeys =  Object.keys(props.initData[itemKey]);  //e.g. affected, unaffected;

            all_subItemKeys.forEach(function(subItem){

                let val = props.initData[itemKey][subItem];
                chart_data.push({d: subItem, key: subItem, value: val})

            })

            let obj = {label: itemKey, data: chart_data};
            data.push(obj);

    })


    const showCharts = (data) => {


       return data.map(function(item){

            return  <Grid item lg={4}>
                <div className={'small_panel_title'}>{item.label}</div>
                <VChart initData = {item.data} data = {item.data} title={item.label} />
            </Grid>


        })

    }

    return <Box p={1}>
       {/* <Typography variant={"h6"}>
            Aggregation View
        </Typography>*/}
        <Grid container >
            {showCharts(data)}
        </Grid>

    </Box>


}



export default AggregationView;