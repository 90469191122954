

export function getObjectDifference(obj1, obj2) {
    const diff = {};

    // Check if obj2 is undefined
    if (obj2 === undefined) {
        // Return obj1 as the difference if obj2 is undefined
        return obj1;
    }

    // Check if obj2 is undefined
    if (obj1 === undefined) {
        // Return obj1 as the difference if obj2 is undefined
        return obj2;
    }

    // Compare obj1 properties with obj2
    for (const key in obj1) {
        if (obj1[key] !== obj2[key]) {
            diff[key] = obj1[key];
        }
    }

    // Compare obj2 properties with obj1
    for (const key in obj2) {
        if (obj1[key] !== obj2[key]) {
            diff[key] = obj2[key];
        }
    }

    return Object.keys(diff).length>0;
}

