import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Chip from "@material-ui/core/Chip";


export function SampleCell(props){

    let {sample_case} = props;

    return <Grid item lg={2}>
        <Typography variant="h7" component="h7" style={{color: "#4575B4", fontWeight: "bold"}}>
            {(sample_case.id === "ALL_SAMPLES") ? "All Experiments" : sample_case.id}
        </Typography>
    </Grid>

}


export function IndexCell(props){

    let {sample_case} = props;

    return <Grid item lg={1}>
        {((sample_case.case === "index") || (sample_case.case === "tumor"))
            ? <Chip label={ sample_case.case} color="primary"/>
            : <Chip label={ sample_case.case}/>
        }
    </Grid>
}