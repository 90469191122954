import {Typography} from "@mui/material";
import MappedText from '../../../../../create-report/shared-components/mapText.js';
import React from "react";
import ExomeCoverage from "../components/ExomeCoverageTable.js";
import SomaticGenomeCoverage from "../../somatic/components/SomaticGenomeCoverageTable.js";
import { useSelector } from 'react-redux';

export function sectionConfigSomatic(annexesData, clinicalReportName, setClinicalReportName, additionalData) {

    const { allDataApis } = useSelector((state) => state.generaltable);
    
    return [
        {
            title: 'The following annexes complete the report, which must be evaluated in its entirety',
            columns: 1,
            render: () => (
                <Typography style={{ fontSize: '16px', marginBottom: '20px' }}>
                    <MappedText text="The following annexes complete the report, which must be evaluated in its entirety" />
                </Typography>
            ),
        },
        {
            title: 'Annex 1: Clinical record',
            columns: 1,
            fields: [
                { title: 'Cancer Type', value: allDataApis?.participants?.[0].cancer_type  },
                { title:<MappedText text='Family History'/>, component: 'TextField', value: clinicalReportName , setValue: setClinicalReportName },//Pending Phenostore
                { title: <MappedText text='Treatment'/>, component: 'TextField', value: clinicalReportName , setValue: setClinicalReportName },//Pending Phenostore
                
            ],
        },
        {
            title: 'Annex 2: Tumor genomic characteristics',
            columns: 2,
            fields: [
                { title: 'Purity', value: allDataApis?.tumor?.tumor_purity },
                { title: 'Average ploidy', value: allDataApis?.tumor?.average_ploidy},
            ],
        },
        {
            title: 'Annex 3: Other biomarkers',
            columns: 2,
            fields: [
                { title: 'Microsatellite status', value: allDataApis?.tumor?.microsatellite_status},
                { title: 'Tumor mutation load', value: allDataApis?.tumor?.tumor_mutational_load},
                { title: 'Tumor mutational burden', value: allDataApis?.tumor?.tumor_mutational_burden },
                { title: 'Homologous recombination deficiency', value: allDataApis?.tumor?.homologous_recombination_deficiency_status || 'NA'},
                { title: 'Mutational profiles', value: allDataApis?.tumor?.mutational_profiles },
            ],
        },
        {
            title: 'Annex 4: Methodology',
            columns: 2,
            fields: [
                { 
                    title: 'Test method', 
                    value: `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` 
                  },                
                { title: 'Type of variants assessed', value: allDataApis?.type_of_variants_assessed  },//Pending
                { title: <MappedText text='Sample received'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName  },//Pending
                { title: 'Sample type', value: allDataApis?.samples?.sample_type  },
                { title: 'Sample condition', value: allDataApis?.samples?.sample_status },
                { title: 'Site of tumor', value:  allDataApis?.samples?.sample_tissue_of_origin },
                { title: <MappedText text='Treatment given before sample was taken'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName },//samples - se guarda en treatment_given_before_sample_was_taken
                { title: 'Date of sample collection', value: allDataApis?.samples?.date_of_sample_collection },
            ],
        },
            {title: 'Sample information',
            columns: 2,
            fields: [
                { title: <MappedText text='DNA purity'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName },//Pending
                { title: <MappedText text='DNA integrity'/>, component: 'TextField', value: clinicalReportName , setValue: setClinicalReportName },//Pending
            ],
            title: 'Sample processing',
            columns: 2,
            fields: [
                { title: 'Centre', value: allDataApis?.centre },
                { title: 'Sequencing platform', value: allDataApis?.sequencing_platform },
                { title: 'Fragment (size and type)', value: allDataApis?.fragment },
            ],
        },
        {
            title: 'Bioinformatic analysis',
            columns: 2,
            fields: [
                { title: 'Human reference genome', value: allDataApis?.human_reference_genome },
                { title: 'Aligner', value: allDataApis?.aligner },
                { title: 'Mark duplicates', value: allDataApis?.mark_duplicates},
                { title: 'Base recalibration', value: allDataApis?.base_recalibration },
                { title: 'Smart variants caller', value: allDataApis?.smart_variants_caller },
                { title: 'Analysis pipeline', value: allDataApis?.analysis_pipeline},
            ],
        },
        {
            title: 'Annex 5: Quality control metrics',       
        },
        {
            subTitle: 'Exome coverage metrics',
            render: () => <ExomeCoverage data={additionalData.exomeCoverageData} />
        },  
        {
            paragraph: 'C10/C15: percentage of bases covered by at least 10,15 and 20 reads,respectively.',
           
        },
        {

            fields: [
    
                { title: <MappedText text='List of diagnostically targeted genes'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName },
            ],
        },
       
        {
            subTitle: 'Coverage of the genes of interest (RefSeq coding sections)',
        },
        {
            paragraph: 'These genes are the one that do NOT fit the coverage threshold (90%).',
           
        },
        {
        
            render: () => <SomaticGenomeCoverage data={additionalData.somaticCoverageGenesData} />
        },
    ];
}