




//REDUCER


import {SET_VIEW} from "./sidebar_actions";

const initialState = {
    page: "home"
};


export const sidebar_settings = (state =initialState, action) => {
    if (action.type === SET_VIEW) {
        return action.settings;
    } else {
        return state
    }
};
