import React from "react";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


export const mapping = [
    { key: "variantclasses" , label: <MappedText text="Variant Effect"/>},
    { key: "genotypefeatures" , label: <MappedText text="Genotype Features"/>},
    { key: "mutationtaster" , label: <MappedText text="Mutation Taster"/>},
    { key: "clinvarclasses" , label: <MappedText text="ClinVar Classes"/>},
    { key: "Mane" , label: <MappedText text="Mane"/>},
    { key: "onco_classifier" , label: <MappedText text="Onco Filter"/>},
    { key: "polyphen2hvarpred" , label: <MappedText text="PolyPhen-2 Pred."/>},
    { key: "gnomad_filter" , label: <MappedText text="GnomAD Filter"/>},
    { key: "programs_filter" , label: <MappedText text="Programs Filter"/>},
    { key: "variantconsequences" , label: <MappedText text="Variant Consequences"/>},

];


// maps values;


