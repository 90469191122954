import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DefaultSelect({ setFilter, options }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };

  React.useEffect(() => {
    setFilter(personName);
  }, [personName]);

  const sortedOptions = [...options].sort((a, b) => {
    const numA = Number(a.label);
    const numB = Number(b.label);

    if (isNaN(numA) && isNaN(numB)) {
      return a.label?.localeCompare(b.label);
    } else if (isNaN(numA)) {
      return 1;
    } else if (isNaN(numB)) {
      return -1;
    } else {
      return numA - numB;
    }
  });

  return (
    <div>
      <FormControl sx={{ m: 1, width: "100%"}}>
      <Select
          sx={{ color: '#B9B9B9', minWidth: 100, width: '100%' }}
          size="small"
          multiple
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <>Select</>;
            }
            const text = selected.join(', ');
            const maxLength = 10; 
          
            return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
          }}
          
          MenuProps={MenuProps}
        >
          {sortedOptions.map((name) => (
            <MenuItem
              key={name.label}
              value={name.label}
              style={getStyles(name.label, personName, theme)}
            >
              {name.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
