export const preFilter = (
  mainData,
  isCheck,
  configFilter,
  compactColumns = [],
  compactColumnsSplit = [],
  setPreFilteredData,
  setDataFilter
) => {

  const preFilter = mainData?.reduce((acc, originalItem, index) => {

    let item = { ...originalItem };

    const data = isCheck ? compactColumnsSplit : compactColumns;

    if (compactColumns || compactColumnsSplit) {
      data.forEach((compactColumn) => {
        const valueBelong = item[compactColumn.dataSearch]?.toLowerCase();
        compactColumn.rowSelect.forEach((element) => {
          if (valueBelong === element.value.toLowerCase()) {
            item[compactColumn.nameColumn] = item[element.dataSearch];
          }
        });
      });
    }

    const dataSearchKey = isCheck
    ? configFilter[1].split.dataSearch
    : configFilter[0].full.dataSearch;
  const keyFilter = isCheck
    ? configFilter[1].split.keyFilter
    : configFilter[0].full.keyFilter;
  const itemValue = item[dataSearchKey];
  const itemValueToLower =
    typeof itemValue === "string" ? itemValue.toLowerCase() : itemValue;
  const keyFilterToLower =
    typeof keyFilter === "string" ? keyFilter.toLowerCase() : keyFilter;
  if (itemValueToLower == keyFilterToLower) {

      acc.push({ ...item, originalIndex: index });
    }
    return acc;
  }, []);

  setPreFilteredData(preFilter);
  setDataFilter(preFilter);
};