import { Stack } from "@mui/material";
import React from "react";
import ComponentSwitch from "../switches/Switch";

const useViewFeatures = (states, customFeatures) => {
  return (
    <Stack flexDirection="row">
      {customFeatures?.map((item) => {
        if (item.switchFS) {
          return <ComponentSwitch states={states} />;
        } 
      })}
    </Stack>
  );
};

export default useViewFeatures;