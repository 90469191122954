



import React from "react";

export const hpscreg_columns = [
    {
        Header: 'Gene Name',
        accessor: "gene_name",
        filter: 'fuzzyText',
        Cell: function({ value, row }){
            return <div>
                {value}
            </div>
        }
    },
    {
        Header: 'URL',
        accessor: "hpscreg_url",
        filter: 'fuzzyText',
        Cell: function({ value, row }){
            return <div><a href={value} target={"_blank"}>
                {value}
            </a>
            </div>
        }
    },
    {
        Header: 'Disease',
        accessor: "disease_name",
        filter: 'fuzzyText',
    },
    {
        Header: 'Cell Line Name',
        accessor: "cell_line_name",
        filter: 'fuzzyText',

        Cell: function({ value, row }){

            return <div > {value}</div>
        }

    },
    {
        Header: 'Cell Line',
        accessor: "cell_line",
        filter: 'fuzzyText',

        Cell: function({ value, row }){

            return <div > <a href={value} target={"_blank"}>{value}</a></div>
        }

    },
    {
        Header: 'Provider',
        accessor: "provider",
        filter: 'fuzzyText',
        maxWidth: 50,
        minWidth: 50,
        width: 50,
    }
]