
const genotypePhenotypeData = [
    {
        "gene": "NA",
        "gene_mim_number": "NA",
        "phenotype": "NA",
        "phenotype_mim_number": "NA",
        "inheritance": "NA"
    },
    {
        "gene": "NA",
        "gene_mim_number": "NA",
        "phenotype": "NA",
        "phenotype_mim_number": "NA",
        "inheritance": "NA"
    },
    {
        "gene": "NA",
        "gene_mim_number": "NA",
        "phenotype": "NA",
        "phenotype_mim_number": "NA",
        "inheritance": "NA"
    }
];


    export default genotypePhenotypeData;