import React from "react";
import {AlignmentType, HeadingLevel, Paragraph, TextRun} from "docx";
import {bodyText} from "../../config/template_config.js";
import {render} from "react-dom";
import { createSpacer } from "../write_utils/documentHelpers.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";

function WriteInterpretationData(sectionData, analysisType) {
    let snv_rows = [];
    let cnv_rows = []

    if (sectionData !== null && Object.keys(sectionData).length>0) {

        snv_rows = (sectionData.rows) ? sectionData.rows.filter(s => s.associated_genes === undefined) : [];
        cnv_rows = (sectionData.rows) ? sectionData.rows.filter(s => s.associated_genes !== undefined) : [];
    }

    const renderRows = (rows) => {

        const sections = rows.map(row => {
            let transcript = (row.transcript!== undefined) ? row.transcript : "NA";
            let gene = (row.gene!== undefined) ? row.gene : "NA";
            let cDNA = (row.cDNA!== undefined) ? row.cDNA : "NA";

            const text = transcript + "(" + (gene) + ")" + ":" + cDNA;
            const titlePar = new Paragraph({
                children: [
                    new TextRun({
                        text: text,
                        bold: true, // Make the text bold
                    })
                ],
                spacing: { after: 40, before: 40 },
            });

            const CommentPar = new Paragraph({
                children: [ bodyText(row.comments)],
            });
            const spacerPar = createSpacer(50, 50);

            return [titlePar,CommentPar, spacerPar]; // Return an array of paragraphs
        });
        return sections.flat(); // Flatten the array of arrays
    }

    // snv and cnv sections
    const snv_sections = renderRows(snv_rows);
    const cnv_sections= renderRows(cnv_rows);

    const title = new Paragraph({
        children: [
            new TextRun({
                text: renderToString("INTERPRETATION"),
                size: 24,
                color: '#000000',
                bold: true,
                heading: HeadingLevel.HEADING_2,
                underline: true
            }),
        ]
    });

    const titleConclusions = new Paragraph({
        children: [
            new TextRun({
                text: renderToString("CONCLUSION"),
                size: 24,
                color: '#000000',
                bold: true,
                heading: HeadingLevel.HEADING_2,
                underline: true
            }),
        ]
    });

    const conclusionPar = new Paragraph({
        text: (sectionData.conclusions!== "") ? sectionData.conclusions : renderToString("Not available"),
    })

    const spacerPar = createSpacer(50, 50);

    const titleRecommendations = new Paragraph({
        children: [
            new TextRun({
                text: renderToString("RECOMENDATION"),
                size: 24,
                color: '#000000',
                bold: true,
                heading: HeadingLevel.HEADING_2,
                underline: true
            }),
        ]
    });

    const recommendationsPar = new Paragraph({
        text: (sectionData.recommendations!== "") ? sectionData.recommendations : renderToString("Not available"),
          
    })

    if (analysisType.toLowerCase().includes("somatic") || analysisType === "germline"){
        return [title, ...snv_sections, ...cnv_sections, titleConclusions, conclusionPar, spacerPar, titleRecommendations, recommendationsPar];
    } else {
        return [title, ...snv_sections, ...cnv_sections, titleConclusions, conclusionPar, spacerPar, titleRecommendations, recommendationsPar];
    }


}

export default WriteInterpretationData;



