import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {CircularProgress} from "@material-ui/core";

import GPAP_InfoIcon from "../../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import ErrorIcon from '@mui/icons-material/Error';

function ParticipantHeader(props){

    let {rowsL, total, status} = props;

    const [loading, setLoading] = useState(false);


    useEffect(function(){

        setLoading(props.loadingParticipants)

    },[props.loadingParticipants])


    const loadingIcon = () => {

        return  <React.Fragment>
            <div style={{float: "left", width: "100%"}}>
                <Typography variant="h6"
                            style={{
                                flex: '1 1 100%',
                                color: "rgb(69, 117, 180)"
                            }}
                            component="div"> <CircularProgress size={"14px"}/> Loading Participant Data ...
                </Typography>
            </div>
        </React.Fragment>

    }

    if(rowsL!== undefined){

        //let s = total + "participants";

        let s= rowsL +  " participants with selected variant(s)"
            //+  ", total cohort size: " + total

        if(rowsL === 1){
            //s= rowsL +  " participants out of " + total + " that match the query ";
            s = rowsL +  " participant with selected variant(s)"
                //+  ", total cohort size: " + total
        }

        if(total !== undefined && total!== null){
            return  <React.Fragment>
                <div style={{float: "left", width: "95%"}}>
                    <Typography variant="h6"
                                style={{
                                    flex: '1 1 100%',
                                    color: "rgb(69, 117, 180)"
                                }}
                                component="div"> {s}
                    </Typography>
                </div>
                <div>
                    <GPAP_InfoIcon title={"For technical limitations of our servers, we only display the first 1000 participants for a query. Please narrow your search if needed."}/>
                </div>
            </React.Fragment>
        }
        else if(loading){
            return loadingIcon();
        }
        else if(total === null){

            return  <>
                <div style={{float: "left", width: "95%"}}>
                    <Typography variant="h6"
                                style={{
                                    flex: '1 1 100%',
                                    color: "rgb(69, 117, 180)"
                                }}
                                component="div"> <ErrorIcon color={"danger"}/> Error fetching participants
                    </Typography>
                </div>
            </>
        }
        else{
            return loadingIcon();
        }
    }
    else{
        return null;
    }



}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            token: state.authorization.token
        }
    }
    else{
        return {patients: [], token: state.authorization.token}
    }
}




export default connect(mapStateToProps,null)(ParticipantHeader);