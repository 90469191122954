


import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import PhotoCamera from '@material-ui/icons/PhotoCamera';

import ListIcon from '@material-ui/icons/List';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {bindActionCreators} from "redux";
import * as Actions from "../actions";
import * as Position_Actions from "../position-section/position_actions"
import {connect} from "react-redux";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import {GPAP_Button, GPAP_Button_light} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import BackupIcon from '@material-ui/icons/Backup';
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

function parseFile(file, fileType){


    const bedlines = file;
    let ranges = [];
    let read_error = undefined;


    bedlines.forEach(function(row){
        let values = row.split("\t");
        let chr = values[0];
        let startpos = parseInt(values[1]);
        let endpos =   parseInt(values[2]);

        //If chr columns starts with 'chr'

        if (chr.startsWith("chr")){
            chr = chr.split("chr")[1];
        }
        //Sex chromosomes handling
        switch (chr) {
            case 'X':
                chr = 24;
                break;
            case 'Y':
                chr = 25;
                break;
            case 'MT':
                chr = 23;
                break;
            case "M":
                chr = 23
        }

        //Push all rows except for headers (can start with # of just text)
        if (parseInt(chr)){

            chr = chr.toString().replace(/ /g, '');
            let start_pos = startpos.toString().replace(/ /g, '');
            let end_pos =   endpos.toString().replace(/ /g, '');

            //If it is comma separated,space separated or does not have 3 columns
            if ((chr.indexOf(',') !== -1) || (start_pos.indexOf(',') !== -1) || (end_pos.indexOf(',') !== -1) || (chr.indexOf(' ') !== -1) || (start_pos.indexOf(' ') !== -1) || (end_pos.indexOf(' ') !== -1) || isNaN(chr) || isNaN(start_pos) || isNaN(end_pos) ) {

                read_error = (fileType === "BED")
                    ? "Wrong bed file format (it must be tab-delimited). Please see here: https://genome.ucsc.edu/FAQ/FAQformat.html#format1"
                    : "Wrong variant file format (it must be tab-delimited)"
            }

            ranges.push('{"chrom"' + ":" + chr + ',"pos"' + ":" + start_pos + ',"end_pos"' + ":" + end_pos + "}");

        }


    });





    return {"ranges": ranges, error: read_error}
}


function UploadFile(props) {
    const classes = useStyles();
    let {fileType, position_actions} = props;

    // variant_ranges
    // bed_ranges

    const [list, setList] = useState([]);


    const readFile = (file) => {

        let obj = parseFile(file, fileType);
        if(obj.error === undefined){
            setList(obj.ranges);
            if(fileType === "BED"){

                position_actions.add_bed_ranges(obj.ranges)
            }
            else{
                position_actions.add_coordinate_ranges(obj.ranges)
            }
        }
        else{
            alert("Error");
        }

    };





    const handleFile = (input) => {

        const reader = new FileReader();
        reader.onload = function(){
            //let text = reader.result;
            //let node = document.getElementById('output');
            //node.innerText = text;
            //let file = reader.result.replace(/(\r\n|\n|\r)/gm,"");


            let file = reader.result.split('\r\n');


            readFile(file)



        };
        reader.readAsText(input.target.files[0]);

    };

    const clear = () => {

        setList([]);
        props.position_actions.reset_ranges(fileType)

    };


    let title = (fileType === "BED")
                ? "Tab delimited file e.g:\n" +
                "1 13868 14652\n" +
                "3 39100 41808"
                : "Tab delimited file e.g:\n" +
                  "#chr Position\n" +
                  "8 14652";

    return (
        <Box p={0}>
            {/*{(fileType === "BED")
                ? <Typography variant={"subtitle1"}>Upload BED File</Typography>
                : <Typography variant={"subtitle1"}>Upload Coordinate File</Typography>
            }*/}
            {(list.length === 0)
                ? <div className={classes.root}>
                    <input
                        accept="text/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleFile}
                    />
                    <label htmlFor="contained-button-file" style={{width: "100%"}}>
                        <GPAP_Tooltip title={title}>
                        <GPAP_Button variant="contained" component="span" size = {"small"} startIcon={<BackupIcon/>}>
                            <MappedText text= "Upload" /> {fileType} File
                        </GPAP_Button>
                        </GPAP_Tooltip>
                    </label>
                </div>
                : <Typography variant={"subtitle1"}> <DoneIcon color={"primary"}/> Uploaded {list.length} range from {fileType} File <IconButton onClick={clear}><ClearIcon/></IconButton></Typography>
            }
        </Box>
    );
}


function mapStateToProps (state) {
    if(state.authorization!==undefined) {
        return {token: state.authorization.token}

    }
    else{
        return {token: ""}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    position_actions: bindActionCreators(Position_Actions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(UploadFile);