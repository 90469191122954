import React, {Component, Fragment} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../actions";
import {connect} from "react-redux";
import "./sample-view.css";
import * as StudyActions from "../../../dashboard/reducers/study_actions.js"
import NewStudyButton from "./components/NewStudyButton";
import StepForm from "./components/step-form/StepForm";
import {Link} from "react-router-dom";
import {GPAP_Button} from "../../../../gpap-lib/components/Button_Collection";
import BackToStudy_Button from "../BackToStudy_Button";
import Typography from "@material-ui/core/Typography";
import {getStudy} from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/getAnnotations";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from "@material-ui/core/Box";
import IntroStudy from "./IntroStudy";
import Grid from "@material-ui/core/Grid";
import {Alert} from "@material-ui/lab";
import {Redirect} from "react-router-dom";
import {AlertInfo} from "./components/samples-section/SelectionInfo";
import GPAP_Tooltip from "../../../../gpap-lib/components/GPAP_Tooltip";
import {X_CHROM_OBJ} from "../../helpers";
import formatSamples_getLabel from "./formatSample_getLabel";
import {date_generator} from "../../../func/helpers";


class Analysis_Page  extends Component {


    constructor(props){
        super(props);
        this.state = {
            expanded: "samples",
            can_run: false,
            studySaved: false,
            studyName: "",
            hide_study_intro: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleRun = this.handleRun.bind(this);
        this.onSave = this.onSave.bind(this);
        this.renderStudyIntro = this.renderStudyIntro.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.renderAnalysisPage = this.renderAnalysisPage.bind(this);

    }


    componentDidMount() {

        let {studySettings} = this.props;

        if(studySettings.localId!== null){
            this.setState({studySaved:true})
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        let {samples, inheritance} = this.props;

        if(prevProps.inheritance!== inheritance)
        {
            if(samples.length>0 && inheritance!== "")
            {
               this.setState({can_run: true})
            }
        }
    }


    handleChange = (panel) => (event, isExpanded) => {

        if(isExpanded){
            this.setState({expanded: false});
        }
        else{
            this.setState({expanded: panel});
        }
    };

    onSave = (val) =>{

        this.setState({studySaved:val})
    };



    handleRun = () =>{

        const self = this;
        let studyActions = this.props.studyActions;
        let { samples, studySettings, query } = this.props;

        // assign a query to each analysis. It would include the genotype for now.... then we will edit accordingly.
        let first_query_id = 0;
        let date = date_generator();


        function createAnalysis_and_query(analysis, index){
            // create new query taking the query from the REDUX Store
            const new_query = JSON.parse(JSON.stringify(query))

            // chromosome query

            // save variant type and delete;

            let variant_type= new_query["variant_type"];

            delete new_query["variant_type"];

            // analysis label

            let label = analysis.label;
            new_query.samples_germline = formatSamples_getLabel(samples, label);

            // save samples only with that gt;
            let samples_for_analysis = [];
            samples.forEach(function(sample){
                let new_sample = {...sample};
                new_sample.gt_settings = JSON.parse(JSON.stringify([sample.gt_settings.find(s => s.label === label)]))
                samples_for_analysis.push(new_sample);
            })

            const analysis_id = Math.floor(Math.random() * 200);

            let count = 1;
            const localID = analysis_id + "_" + count

            // first analysis to save
            let new_analysis = {
                "studyLocalId": studySettings.id,
                "localId": analysis_id,
                "analysis_type": studySettings.analysisType,
                "inheritance_mode_id": analysis.id,
                "creationDate": date,
                "samples": samples_for_analysis,
                "description": "",
                "queries": [],
                "labelled": {},
                "tagged": []
            };

            if(index === 0) first_query_id = localID;

            let v_type= (variant_type!== undefined)
                ? {variant_type: variant_type}
                : {variant_type: "GPAP"};
            let extra = [];

            // add predefined gene lists,
            if(self.props.geneList.applied_lists!== undefined){
                extra.push(...self.props.geneList.applied_lists);
            }


            extra.push(v_type);

            // add gene operator used for genes;
            if(new_query.gene.length>0){
                extra.push({gene_list_operator: self.props.geneList.operator});

            }



            // query - check for compound het

            if(analysis.label.includes("compound heterozygosity")){
                new_query.compound_in = true
            }

            // take into account X-Linked Inheritances;
            // avoid problems with array reference;
            //let query_f = JSON.parse(JSON.stringify(new_query))
            if(analysis.label.includes("X")){
                new_query["chrom"].push(X_CHROM_OBJ);
            }


            // first query to save

            // check if any filter set was applied...


            let filter_set_id = -1;
            let filter_set_name = "";

            if(Object.keys(self.props.currentFilterSet).length>0){
                filter_set_id = self.props.currentFilterSet.id;
                filter_set_name = self.props.currentFilterSet.filter_set_name;
            }

            const data = {
                study_id: studySettings.id ,
                analysis_id: analysis_id,
                query: {
                    "localId": localID ,
                    "name": "query_" + 1,
                    "analysis_id": analysis_id,
                    "filter_set_id": filter_set_id,
                    "filter_set_name": filter_set_name,
                    "query_name": "query_" + 1,
                    "filters": JSON.stringify(new_query),
                    "creationDate": date,
                    "outputs": [{"n_variants": -1, "run_date": date }],
                    "extra": extra

                }
            };

            // add analysis to the study; we should specify to which study;
            studyActions.create_analysis(new_analysis);
            studyActions.create_query(data);
        }

        let which_analyses = studySettings.analysis_ids;

        let all_analyses = []
        which_analyses.forEach(function(analysis){

            all_analyses = all_analyses.concat(analysis);

        });

        all_analyses.forEach( (a,i) => createAnalysis_and_query(a, i));

        studyActions.setStudySettings({current_query: first_query_id})
        self.props.queryRunner();

    };


    handleHide() {

        this.setState({
            hide_study_intro: true
        })


    }

    renderStudyIntro(){

        let studyID =   this.props.studySettings.id ;
        let analysis_exists = (this.props.studySettings.current_analysis!== null)

        if(!this.state.hide_study_intro){
            if(studyID != null ){
                if(!analysis_exists){
                    return <Grid container
                                 direction="column"
                                 justifyContent="center"
                                 alignItems="center">
                        <Grid item lg={5}>
                            <IntroStudy
                                studyID = { studyID }
                                handleHide = { this.handleHide }/>
                        </Grid>
                    </Grid>
                }
                else{

                    return null;
                }

            }
            else{

                return <Redirect to={{
                    pathname: "home",
                    state:{
                        key:"home"
                    }
                }}/>;
            }

        }
        else{

            return null;
        }

    }




    renderAnalysisPage ()  {

        const self = this;
        let {studySettings, studyBucket, experiments} = this.props;


       /* if(self.state.hide_study_intro || studySettings.current_analysis!== null){*/

            return  <Box>
                <div className={"familyContainer"}>
                {(studySettings.current_analysis!== null)
                    ? <React.Fragment>
                        <div style={{float: "left", width: "100%"}} >
                            <Box p={1}>
                                <Alert severity="info"> <div style={{float: "left"}}>
                                    <Typography variant={"body1"}>Adding a new analysis to current study:
                                    <span style={{ color: "black", fontWeight: "bold" }}>
                                {getStudy(studySettings, studyBucket).study.studyName}
                            </span>
                                </Typography></div>
                                </Alert>
                            </Box>
                            <Box p={1}>
                                <AlertInfo severity={"warning"} message={ "If you want to analyze new experiments and run new analyses, create a New Study"}/>
                            </Box>

                        </div>
                    </React.Fragment>
                    : null}
                {(self.state.studySaved) ?
                    <Fragment>
                        <StepForm
                            experiments={experiments}
                            run_analysis = {self.handleRun}
                        />
                    </Fragment>
                    : null
                }
            </div>
            </Box>
       /* }*/
        /*else{
            return null;
        }*/


    }



    render() {

        let self = this;


        return <Fragment>
            <Grid container direction={"row"}>
                <Grid item lg={12}>
                    <GPAP_Tooltip title={"Please, mind that if you haven't saved your current study, you will lose your analyses by going back to home."}>
                        <Link
                            style={{marginLeft: "10px"}}
                            to={"home"}
                            component={GPAP_Button}
                            startIcon={<ArrowBackIcon/>}
                        >
                            to MYGPAP
                        </Link>
                    </GPAP_Tooltip>
                    <BackToStudy_Button/>
                    <NewStudyButton />
                </Grid>
                <Grid item lg={12}>
                    <Typography variant="h5" color={"textSecondary"} align="center">
                        New Study
                    </Typography>
                </Grid>

            </Grid>

           {/* {self.renderStudyIntro()}*/}
            {self.renderAnalysisPage()}
        </Fragment>
    }


}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.sample_list!== undefined)
        {
            return {
                samples: state.sample_list.samples,
                inheritance: state.inheritance_key,
                token:state.authorization.token,
                studySettings: state.studySettings,
                query: state.query,
                studyBucket: state.studyBucket,
                geneList: state.geneList,
                currentFilterSet: state.currentFilterSet
            }
        }
        else{
            return {samples: [],  "inheritance": state.inheritance, token:state.authorization.token}
        }
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(Analysis_Page);
