import { AlignmentType, HeadingLevel, Paragraph } from "docx";
import { drawBasicTable } from "../../write-sections/write_utils/drawTables_examples.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";

const headers = [
    "Gene symbol",
    "Transcript (RefSeq)",
    "Mean coverage",
    "Median coverage",
    "C1",
    "C10",
    "C15",
    "C20",
];

const dataKeys = [
    "gene_symbol",
    "transcript",
    "mean_coverage",
    "median_coverage",
    "c1",
    "c10",
    "c15",
    "c20"
];

function WriteGenesInterestSection(sectionData) {
    let rows = sectionData && Array.isArray(sectionData) ? sectionData : [];
    
    return [
        new Paragraph({
            text: renderToString("Coverage of the genes of interest (RefSeq coding regions):"),
            heading: HeadingLevel.HEADING_2
        }),
        new Paragraph({
            text: "",
        }),
        drawBasicTable(rows, headers, dataKeys, renderToString("No data available")),
      
        new Paragraph({
            text: "",
        }),
    ];
}

export default WriteGenesInterestSection;