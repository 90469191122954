import React, {Component} from "react";
import {connect} from "react-redux";
import "./clinical-info.css";
import {
    getAnalysisInformation,
    getParticipantBySample,
    getROHs
} from "../../../../../../services/api/get";
import {bindActionCreators} from "redux";
import * as Actions from "./patient_actions";
import * as Sample_Actions from "../../reducers/sample_actions";
import * as ROHs_actions from "../../../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/RadioGroupForm/roh_actions"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import PatientCard from "./PatientCard/PatientCard";
import PatientsTabs from "./PatientsTabs";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider"
import {roh_parser} from "../../../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/RadioGroupForm/ROH_helper";
import {get_sample_variants} from "../../../../../../services/api/variants_api";
import * as StudyActions from "../../../../../dashboard/reducers/study_actions";
import PedigreeViewerOnly from "./pedigree/PedigreeViewerOnly";
import ROHs from "./ROHs";
import TaggedVariants from "./TaggedVariants";
import PeopleIcon from "@material-ui/icons/People";
import {Description} from "@material-ui/icons";
import {AlertInfo} from "../samples-section/SelectionInfo";
import mapText from "./mapText";
import MappedText from "./mapText";


class ClinicalInfo  extends Component {

    constructor(props){
        super(props);
        // edit later on;
        this.state = {
            phenostore_participants:[],
            index_case_id: null,
            rohs: null,
            analysis_info: undefined,
            tagged_variants: [],
            samples: [],
            view_only: false,
            study_type: false,
            success: null
        };

        this.getClinicalInfo = this.getClinicalInfo.bind(this);
        this.collectROHs = this.collectROHs.bind(this);
        this.createPatientCard = this.createPatientCard.bind(this);
       // this.getSampleList = this.getSampleList.bind(this);
        this.updatePhenoStore_Info = this.updatePhenoStore_Info.bind(this);
        this.reset = this.reset.bind(this);

    }


    componentDidMount() {


        this.getClinicalInfo();

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.samples === undefined && prevProps.samples_selected!== this.props.samples_selected )
        {

            this.getClinicalInfo();
        }
      /*  else if(this.state.phenostore_participants.length!== prevState.phenostore_participants.length){
            console.log("GET CLINICAL INFO - component did update")
            this.getClinicalInfo();
        }*/
    }


    collectROHs(sample_id) {
        let { ROHs_Actions } = this.props;
        let {apis_configuration} = config;
        let self = this;

        getROHs(apis_configuration.api_data_management, sample_id, this.props.token)
            .then(s => s.json())
            .then(function(rohs){
                ROHs_Actions.update_roh_value({...rohs});
                self.setState({
                    rohs: {...rohs}
                })
            });
    }

    updatePhenoStore_Info(sample_list){

        let{ token, actions, view_only } = this.props;

        if(sample_list.length>0)
        {

            let participant_array = sample_list.map(s => s.id);


            // get data from phenostore;

            let payload =  {
            "page": 1,
            "pageSize": 1000,
            "sorted": [],
            "filtered": [{"id": "report_id","value": participant_array}],
            "aggrKeys": [
                "sex",
                "affectedStatus",
                "solved",
                "index",
                "consanguinity"
            ]
        };

            getParticipantBySample(config.apis_configuration.api_phenostore, token, payload)
                .then(r => r.json())
                .then(participants => {

                    let rows = participants["rows"];

                    if(rows.length === 0){
                        this.setState({success: false});
                    }else{
                        let index_id = false;
                        let pats = [];
                        rows.forEach(function(participant){
                            if(participant.index === "Yes"){index_id = participant}
                            pats.push(participant);

                        })

                        let index = pats.filter(p => p.index === "Yes").map(f => f.report_id);

                        // get sample id

                        let sample_id = sample_list.find(s => s.id === index[0]);
                        if(sample_id!== undefined){
                            this.collectROHs(sample_id.sample_id);
                        }


                        this.setState({
                            phenostore_participants: pats,
                            index_case_id: index_id,
                            view_only: view_only,
                            study_type: "family",
                            success: true
                        });


                        actions.add_patients(pats);
                    }




                })

        }
    }


    async getClinicalInfo() {


        let {samples_selected, token, groups, study_actions, studySettings} = this.props;

        // if samples are passed in the props rather than redux...use this instead.
        samples_selected = (this.props.samples !== undefined) ? this.props.samples : samples_selected.samples;

        if (samples_selected.length === 1 && samples_selected[0].sample_id === "ALL_SAMPLES") {
            this.setState({
                study_type: "search_across"
            })
        } else {
            let samples_list = samples_selected.map(sample => (
                {
                    "id": sample.pheno_id,
                    "sample_id": sample.sample_id
                }));

            let index_case = samples_selected.filter(x => x.sample_type === "index").map(sample => (sample.sample_id));

            //let index_case_id = samples_selected.filter(x => x.sample_type === "index").map(sample => sample.pheno_id);
            // extract the phenotips ids
            this.updatePhenoStore_Info(samples_list);


            let api_phenostore = config.apis_configuration.api_phenostore;

            if (Array.isArray(index_case.length)) {
                // TODO: this should be better...review
                getAnalysisInformation(token, index_case[0], groups, "")
                    .then(resp => resp.json())
                    .then(json => {

                        this.setState({
                            analysis_info: json
                        })

                    });
            }

            // concatenate variants

            let pheno_ids = samples_selected.map(s => s.pheno_id);
            //let variants = [];
            let variants_array = []

            pheno_ids = [...new Set(pheno_ids)];

            for (const id of pheno_ids) {
                let json = await get_sample_variants(api_phenostore, token, id)
                    .then(resp => resp.json())

                if (json.data !== undefined) {
                    let arr = []

                    if(json.hasOwnProperty("data") && Array.isArray(json.data)){
                        json.data.forEach(function(t)
                            {
                                let tags = t.map(s => s);
                                arr = arr.concat(tags)
                            }
                        )
                    }

                    arr = arr.filter(s => s !== undefined)

                    variants_array = variants_array.concat(arr);

                }
            }


            study_actions.set_samples_tagged_variants(
                {

                    study_id: studySettings.id,
                    variants: variants_array,
                }
            );

            this.setState({
                tagged_variants: variants_array
            });


            if (this.props.passTaggedVariants !== undefined) {
                this.props.passTaggedVariants(variants_array);
            }


        }

    }


    reset(){

        let actions = this.props.actions;
        let samples_actions = this.props.samples_actions;

        actions.reset_patients();
        samples_actions.resetSamples();

    }


    createPatientCard(sampleID) {

        // PhenoStore id
        let el = this.props.samples_selected.samples.find(s => s.pheno_id === sampleID);
        let experiment_id = el.sample_id;

        if(this.state.phenostore_participants.length>0){
            return <PatientCard phenostore_participants = { this.state.phenostore_participants }
                                sampleID={ sampleID }
                                experiment_id = {experiment_id}
                                />
        }
        else{
            return <div>Clinical Information not available</div>
        }

    }




    render(){

        let sample_list= [];
        let{ samples_selected } = this.props;
        let self = this;
        if(samples_selected!== undefined && samples_selected.samples!== undefined) {

            // iterate the selected samples
            let patients = (this.props.samples!== undefined) ?  this.props.samples : samples_selected.samples;
            patients.forEach(function(item,i){
                if(item.sample_type === "index"){
                    patients.splice(i, 1);
                    patients.unshift(item);
                }
            });

        }

        // check ROHs

        let rohs = false;
        let n_rohs= 0;
        if(self.state.rohs !== null && typeof self.state.rohs === "object" && Object.keys(self.state.rohs).length!==0){
            rohs= true;
            let list_chr = roh_parser(0, self.state.rohs);
            n_rohs = list_chr.length;
        }


        // number tagged variants:

        //let n_variants= self.state.tagged_variants.length;

        let RENDER = (Array.isArray(self.props.samples) || Array.isArray(samples_selected.samples));

        if(RENDER){
            return (<Fade in = {true} timeout={1000}>
                        <div className={"clinical_info"}>
                            <Grid container>
                                <Grid item>
                                    {(!this.state.success)
                                        ? <AlertInfo severity={"Error"} title={"No participants associated to selected experiments"}/>
                                        : null}
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                    <Typography
                                        component={'span'}
                                        variant={"h6"} style={{color: "#4575B4"}}> <PeopleIcon style={{color: "#4575B4"}}/>
                                        <MappedText text={"Participants included in the study"}/>
                                        </Typography>
                                    <PatientsTabs
                                        patients={(this.props.samples!== undefined) ? this.props.samples :  samples_selected.samples}
                                        phenostore_participants={self.state.phenostore_participants}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <Typography
                                            component={'span'}
                                            variant={"h6"}
                                            style={{color: "#4575B4"}}>
                                            Pedigree
                                        </Typography>
                                        <Divider/>
                                        <Box p={1}>
                                            <Box p={0}>
                                            <Typography
                                                component={'span'}
                                                variant={"subtitle1"}>
                                                    <PedigreeViewerOnly index_case_id={self.state.index_case_id}/>
                                            </Typography>
                                            </Box>
                                            <Divider/>
                                            <TaggedVariants variants={this.state.tagged_variants}/>
                                                <Divider style={{float: "left", width: "100%"}}/>
                                            <Box p={2} style={{float: "left"}}>
                                                            <Typography variant={"h6"} style={{color: "#4575B4"}}>
                                                                <Description style={{color: "#4575B4"}}/>  Additional Information</Typography>
                                                            <Typography variant={"subtitle1"}>
                                                                <ROHs n_rohs={n_rohs} rohs={rohs}/>
                                                </Typography>
                                                </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
            </Fade>)
        }
        else{
            return null;
        }

    }



}



//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.sample_list.length!==0)
    {
        return {samples_selected: state.sample_list, token: state.authorization.token , groups:  state.authorization.groups, studySettings: state.studySettings, studyBucket: state.studyBucket,}
    }
    else{
        return {samples_selected: [], token: state.authorization.token, groups:  state.authorization.groups}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    ROHs_Actions:  bindActionCreators(ROHs_actions, dispatch),
    study_actions: bindActionCreators(StudyActions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(ClinicalInfo);