

import React from "react";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import {connect} from "react-redux";


function GQ_SettingsInfo(props){


    let info_sample = props.samples.find(s => s.sample_id === props.sample.id);

    // gt settings;
    let gt_settings = info_sample.gt_settings.find(gt => gt.inheritance_key === props.inheritance_key && gt.label === props.inheritance_type);


    if(props.sample && gt_settings!== undefined){
        return (
            <div style={{ float: "left", width: "100%", marginLeft: "3%" }}>
              <GPAP_Tooltip title="Minimum read depth at variant position">
                <div className="boxSettings">{gt_settings.dp}</div>
              </GPAP_Tooltip>
          
              {props.sample.case !== "control" && (
                <GPAP_Tooltip title="Min-Max Alternate Allele Freq">
                  <div className="boxSettings">{gt_settings.ad_low} - {gt_settings.ad_high}</div>
                </GPAP_Tooltip>
              )}
          
              {props.sample.case !== "control" && props.sample.case !== "tumor" && (
                <GPAP_Tooltip title="Minimum genotype quality (Phred-scaled) for variant position">
                  <div className="boxSettings">{gt_settings.gq}</div>
                </GPAP_Tooltip>
              )}
            </div>
          );
    }
    else{
        return null;
    }

}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.sample_list.samples!== undefined && state.sample_list.samples.length!==0)
    {
        return {patients: state.patients.patients, samples: state.sample_list.samples, token: state.authorization.token}
    }
    else{
        return {patients: [], samples:[], token: state.authorization.token}
    }
}


export default connect(mapStateToProps,null)(GQ_SettingsInfo);
