




export function parseSamples(analyses){

    let samples_array = [];
    analyses.map( s => s.samples).forEach(function(el){
        if(el!== undefined){
            el.forEach(sample => {{
                samples_array = samples_array.concat(sample)}})
        }

    });


    return samples_array

}