
// ==================
import React, {Component} from "react";

import {HashRouter, Route, withRouter} from "react-router-dom";

// ===== IMPORT VIEWS
import Home from "../../../../scenes/home/Home";
import Login from "../../../../services/session/Login";
import Dashboard from "../../../../scenes/dashboard/Dashboard";
import AuthenticatedRoute from "../AuthenticatedRoute";
import {connect} from "react-redux";
import StudyPage from "../../../../scenes/study-page/StudyPage";
import SideNavigation from "../../../SideNavigation/SideNavigation";
import Analysis_Page from "../../../../scenes/study-page/create-study/analysis-page/Analysis_Page";
import Genomics from "../../../../scenes/dashboard/components/genomics-dashboard/genomics/Genomics";
import "./main.css";
import New_Study from "../../../../scenes/study-page/create-study/new_study/New_Study";
import MatchMakerPage from "../../../../scenes/matchmaker/MatchMakerPage";
import StudyRedirect from "../../../../scenes/study-page/study-overview/StudyRedirect";
import StudyParser from "../../../../scenes/study-page/study-overview/StudyParser";
import ScrollToTop from "../../../../../ScrollToTop";
import GPAP_Header from "../../../header/GPAP_Header";
import CreateStudy from "../../../../scenes/study-page/create-study/CreateStudy";
import Cases from "../../../../scenes/home/playground_cases/Cases";
import CohortStudy from "../../../../scenes/study-page/create-study/new_study/cohort-study/CohortStudy";

import DirDashboard from "../../../../scenes/home/instand-home/DirDashboard";
import DirList from "../../../../scenes/instand/dir_list/DirList";

import SettingsHome from "../../../../scenes/gpap-manager/SettingsHome";
import DIR_Menu from "../../../../scenes/instand/dir-menu/DIR_Menu";
import CreateDir from "../../../../scenes/instand/create-dir/CreateDir";
import Contact from "../../../../scenes/instand/contact/Contact";
import ContactGPAP from "../../../header/ContactGPAP";
import NewClinicalReport from "../../../../scenes/instand/reports/create-report/sections/NewClinicalReport";
import HomeInstand from "../../../../scenes/home/instand-home/HomeInstand";





class Main extends Component {


    render() {
        const { location } = this.props;

        const pathsToHideSideNav = [
            '/dir_overview/',  
            '/instand_dashboard',
            '/dir_list',
            '/create_dir',
            '/instand_settings',
            '/contact',
            '/create_dir',
            '/reporting'
        ];
        const shouldHideSideNav = pathsToHideSideNav.some(path => location.pathname.startsWith(path));
        const isProjectInstand =
            location.pathname.includes('/dir_overview/') ||
            location.pathname === '/instand_dashboard' ||
            location.pathname === '/dir_list'||
            location.pathname === '/instand_settings'||
            location.pathname === '/contact'||
            location.pathname === '/create_dir'||           
            location.pathname === '/reporting'
        return (
            <HashRouter>
            <ScrollToTop>
                <React.Fragment>
                {!shouldHideSideNav && <SideNavigation />}
                <div className={"main" + (isProjectInstand ? " instand" : "")}>
                    <div className="myContainer">
                        <div className="content">
                            
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/playground_cases" component={Cases}/>

                            {/* default nextgpap pages*/}
                            <AuthenticatedRoute path="/home" component={StudyPage} enabled={window.config.security} />
                            <AuthenticatedRoute path="/create_new_study" component={ CreateStudy } enabled={window.config.security} />
                            <AuthenticatedRoute path="/new_study" component={New_Study} enabled={window.config.security} />
                            <AuthenticatedRoute path="/genomics" component={ Genomics } enabled={window.config.security} />
                            <AuthenticatedRoute path="/study_overview" component={ StudyParser } enabled={window.config.security} />
                            <AuthenticatedRoute path="/add_analysis" component={Analysis_Page} enabled={window.config.security} />
                            <AuthenticatedRoute path="/analysis" component={Dashboard} enabled={window.config.security} />
                            <AuthenticatedRoute path="/matchmaker" component={MatchMakerPage} enabled={window.config.security} />
                            <AuthenticatedRoute path="/study/:handle" component={StudyRedirect} enabled={window.config.security} />
                            <AuthenticatedRoute path="/cohort_study/:handle" component={CohortStudy} enabled={window.config.security} />
                            <AuthenticatedRoute path="/gpap_settings" component={SettingsHome} enabled={window.config.security} />
                            <AuthenticatedRoute path="/instand_settings" component={SettingsHome} enabled={window.config.security} project={"instand"}/>
                            {/*instand routes*/}
                            <AuthenticatedRoute path="/instand_dashboard" component={DirDashboard} enabled={window.config.security} project={ "instand" }/>
                            <AuthenticatedRoute exact path="/dir_list" component={DirList} enabled={window.config.security} project={ "instand" }/>
                            <AuthenticatedRoute exact path="/create_dir" component={CreateDir} enabled={window.config.security} project={ "instand" }/>
                            <AuthenticatedRoute exact path="/dir_overview/:id/new_clinical_report" component={NewClinicalReport} enabled={window.config.security} project={"instand"}/>
                            <AuthenticatedRoute exact path="/contact" component={Contact} enabled={window.config.security} project={ "instand" }/>
                            <AuthenticatedRoute exact path="/contactus" component={ContactGPAP} enabled={window.config.security }/>
                            <AuthenticatedRoute exact path="/reporting" component={HomeInstand} project={ "instand" }/>
                            {/*  gpap pages*/}
                            <AuthenticatedRoute path="/dir_overview/:id" component={DIR_Menu} enabled={window.config.security} project={ "instand" } />

                            <Route path="/login" component={Login}/>

                             <Route path='/gpap_home' component={() => {

                                window.location.href = window.location.origin;
                                return null;
                            }}/>
                            <Route path='/phenobrowse' component={() => {
                                window.location.href = window.location.origin + '/phenostore/#/browse/patient';
                                return null;
                            }}/>
                            <Route path='/phenostore' component={() => {
                                window.location.href = window.location.origin + '/phenostore';
                                return null;
                            }}/>
                            <Route path='/management' component={() => {
                                window.location.href = window.location.origin + '/datamanagement/#/management';
                                return null;
                            }}/>
                            <Route path='/submission' component={() => {
                                window.location.href = window.location.origin + '/datamanagement/#/submission';
                                return null;
                            }}/>
                            <Route path='/cohortapp' component={() => {
                                window.location.href= window.location.origin + '/cohortapp';
                                return null;

                            }}/>
                            <Route path='/instand_doc' component={() => {
                                window.location.href= window.location.origin + '/gpap_doc/';
                                return null;

                            }}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            </ScrollToTop>
            </HashRouter>
           )
    }
}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token}
    }
}


const MainWithRouter = withRouter(Main);

export default connect(mapStateToProps)(MainWithRouter);