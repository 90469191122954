import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React from "react";
import MappedText from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



// TODO: Convert to switch;
function OperatorChoice(props){


    let {selectedOption, handleOptionChange} = props;

    return  <FormControl component="fieldset"
                         style={{float: "left"}}>
        <FormLabel component="legend"><MappedText text={"Operator"}/></FormLabel>
        <RadioGroup row aria-label="operator"
                    name="operator"
                    value={selectedOption}
                    onChange={handleOptionChange}>
            <FormControlLabel value="union" control={<Radio />} label=<MappedText text={"Union"}/> />
            <FormControlLabel value="intersection" control={<Radio />} label= <MappedText text={"Intersection"}/>/>
        </RadioGroup>
    </FormControl>



}

export default OperatorChoice;