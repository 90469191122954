
import React, {useEffect, useState} from "react";
import {inheritances} from "../../../../../../config-files/config_inheritances";

import {bindActionCreators} from "redux";
import * as Inheritance_Actions from "../../reducers/inheritance_actions";
import * as Sample_Actions from "../../reducers/sample_actions";
import * as Study_Actions from "../../../../../dashboard/reducers/study_actions";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {filterConditions} from "../GenotypeBuilder/helpers/filterConditions";
import Box from "@material-ui/core/Box";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from "../clinical-info/mapText";

import {useSelector} from "react-redux";


const Inheritance_Mode = (props) => {

    const [values, setValues] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const inheritance_modes = inheritances;

    const participants = props.patients.patients.length !== 0 ? props.patients.patients : useSelector(state => state.generaltable.allDataApis.participants);



    useEffect(function(){

        if(!initialized){
            if(props.inheritance_key!== null){

                if(Array.isArray(props.inheritance_key)){
                    setValues(props.inheritance_key)
                }
                else{
                    setValues([props.inheritance_key])
                }

                setInitialized(true)
            }
        }
        else{
            //
            setInitialized(true);
        }


    })




    const handleChange = (evt) => {

        // check box, add multiple options;

        let index = values.indexOf(evt.target.value);
        let val_arr = [];

        if ( index > -1)
        { // only splice array when item is found
            let tmp_vals = [...values];
            tmp_vals.splice(index, 1); // 2nd parameter means remove one item only
            val_arr = tmp_vals;
        }
        else{
            val_arr = values.concat([evt.target.value]);
        }

        setValues(val_arr);

        let selected = inheritance_modes.filter(inheritance => val_arr.includes(inheritance.key));


        let analysis_ids = []

        // for each selected inheritance, add the configuration for that one;
        selected.forEach(function(selectedItem){
            let configurations= selectedItem.config.map(s => {
                return { id: s.id, label: s.label, condition: s.condition}
            })
            analysis_ids = analysis_ids.concat(configurations);
        })

        // if inheritance is x-linked...select which one..
 
        if(evt.target.value === "x-linked-dominant"){
            analysis_ids = analysis_ids.filter(function(s){
                let matches = filterConditions(participants, s.condition );
                return matches.length !== 0;
            })
        }

        //Gender Tab handling for X-linked Dominant
        let sex_index;
        
        //Get sex of index case
        if (props.patients.patients.length > 1) {
            const filteredPatients = props.patients.patients.filter(sample => sample.index === "Yes");
            if (filteredPatients.length > 0) {
              sex_index = filteredPatients[0].sex;
            } 
            else {
              sex_index = "U"; 
            }
        } 
        //Or get sex of the only member if there is no family
        else if (props.patients.patients.length == 1){
            sex_index = props.patients.patients[0].sex;
        }

        else{
            sex_index = "U"
        }
        
        //If Male remove the "X-Linked Dominant - Index Case Female" by id
        if (sex_index === "M"){
            analysis_ids = analysis_ids.filter(obj => obj.id !== 6);
        }

        //If Female remove the "X-Linked Dominant - Index Case Male" by id
        if (sex_index === "F"){
            analysis_ids = analysis_ids.filter(obj => obj.id !== 5);
        }


        let settings = {
            analysis_ids: analysis_ids
        };

        // configure inheritance;
        props.inheritance_actions.setInheritance(val_arr);
        props.study_actions.setStudySettings(settings);
    };


    const createOptions = (inheritances) => {
        
        //Remove somatic option if we are not coming from instand somatic tab
        if (props.instandGeneticFindingsTab != "somatic")
          inheritances = inheritances.filter(obj => obj.key !== "somatic");

        return inheritances.map(function(mode){

            let disabled = false;
            if(participants!== undefined){
                let any = filterConditions(participants, mode.condition);
                if(any.length === 0){
                    disabled = true;
                }
            }

            let key= mode.key;
            let label = mode.label;

            let gridItem =  <Grid item lg={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={key}
                            value={key}
                            key={key}
                            onChange={handleChange}
                            checked={values.includes(key)}
                            name={key}
                            color="primary"
                            disabled={disabled}
                        />
                    }
                    label={<MappedText text={label} />}
                />
                {/*<FormCheck
                    key={key}
                    onChange={handleChange}
                    type={"checkbox"}
                    id={key}
                    disabled={disabled}
                    value={key}
                    label={label}
                    checked={values.includes(key)}
                />*/}
                {(disabled) ? <div><Typography variant={"subtitle2"}>{mode.explanation} </Typography></div> :null}
            </Grid>

            return <React.Fragment>
                {gridItem}
            </React.Fragment>

    })
    }

    const options_automatic = createOptions(inheritances.filter(mode => !mode.label.includes("Custom")))
    const option_custom = createOptions(inheritances.filter(mode => mode.label.includes("Custom")));

    return <div id="inheritance_mode_selection">
        <Grid container direction={"row"}>
            <Grid item lg={12}>
                <GPAP_Tooltip title={<MappedText
                        text={"Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis\n"}/>}>
                    <Typography variant={"h5"}>
                    <MappedText text={"Select a simulated mode of inheritance configuration:"}/>
                    </Typography>
                </GPAP_Tooltip>
                <Box p={1}>
                    {options_automatic}
                </Box>
            </Grid>
            <Grid item lg={12}>
            <Typography variant={"h5"}>
            <MappedText text={"or configure a custom mode of inheritance"}/>
            </Typography>
            <Box p={1}>
            {option_custom}
            </Box>
            </Grid>
        </Grid>

    </div>;
};


function getPatients (state) {
    if(state.sample_list.samples.length!==0)
    {
        return {
            patients:state.patients.length !== 0 ? state.patients : state.generaltable.allDataApis.participants,
            inheritance_key: state.inheritance_key
        }
    }
    else{ return {render:false}}

}

const mapDispatchToProps = (dispatch) => ({
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    study_actions: bindActionCreators(Study_Actions, dispatch),
    inheritance_actions: bindActionCreators(Inheritance_Actions, dispatch),
});



export default connect(getPatients,mapDispatchToProps)(Inheritance_Mode);