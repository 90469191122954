

import React, {useRef, useState} from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../../gpap-lib/components/GPAP_TabPanel";
import Grid from "@material-ui/core/Grid";
import {Container, FormControlLabel, TextField, Button, InputLabel, Select, Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Switch from '@material-ui/core/Switch';
import List_Upload
    from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/gene-section/functions/List_Upload";

import {connect} from "react-redux";
import Predefined_List_View from "./Predefined_List_View";
import {UploadJsonFile} from "./UploadJsonFile";
import {date_generator} from "../../func/helpers";
import { UploadListFile } from "./UploadListFile";

import {save_new_list} from "../../../services/api/lists_api";
import GPAP_Dialog from "../../../gpap-lib/components/GPAP_Dialog";
import { GPAP_Button_Gray } from "../../../gpap-lib/components/Button_Collection";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import { useTranslation } from 'react-i18next';




const useStyles = makeStyles((theme) => ({
    margin: {
        //margin: theme.spacing(1),
        //width: "80%" ,
    },
    inputLabel: {
        //margin: theme.spacing(1),
        color: "black",
    },
    textField: {
        width: "450px"
    },
}));



function PredefinedGeneLists_Manager(props){

    const classes = useStyles();


    const [listType, setListType] = useState();
    const [permission, setPermission] = useState("user");
    const [value, setValue] = useState(0);

    const [state, setActive] = useState(true);
    const [genes, setGenes] = useState([]);

    const [saved, setSave] = useState(false);
    const [uploadListOption, setUploadListOption] = useState("gene-file");
    const [openDialog, setOpenDialog] = useState(false);


    // refs
    const titleRef = useRef('')
    const descriptionRef = useRef('')
    const notesRef = useRef('')
    const sourceRef = useRef('')
    //

    const { t } = useTranslation();


    const handleSwitch = () => {

        setActive(!state)

    }

    //



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handlePermission = (event) => {
        setPermission(event.target.value);
    }

    const handleListType = (event) => {

        setListType(event.target.value)

    }

    const handleOptionChange = (event) =>{
        setUploadListOption(event.target.value);
    }

    const getGenes = (array) => {

        setGenes(array);

    }

    const reset = () => {

        // reset list;
        setSave(false);

    }

    const saveFile = (list) => {

        save_new_list(props.token, window.config.apis_configuration.api_nextgpap_server, list)
            .then(r => {
                if(r.ok){
                    setOpenDialog(true)
                    return r.json()
                }
                else{
                    return r.json();
                }

            })
            .then(json => {
                // console.log()
            })
    }

    const handleCloseDialog = () => {

        setOpenDialog(false);

    }

    const renderDialog = () => {

        return <GPAP_Dialog
            open = {openDialog}
            handleClose = {handleCloseDialog}
            title= <MappedText text={"Upload Successful"}/>
            text=<MappedText text={"The GPAP gene file was successfully uploaded and it's ready to be used."}/>
            onExitText={false}
            onConfirmText=<MappedText text={"Close"}/>
            onExitAction={false}
            onConfirmAction={handleCloseDialog}
            type="success"

        />
    }

    const handleSaveList = (list) => {

        if(uploadListOption === "comma-separated-list"){

            let list_to_save =  {
                "title": titleRef.current.value,
                "description": descriptionRef.current.value,
                "source": sourceRef.current.value,
                "notes": notesRef.current.value,
                "data": genes,
                "permission": "public",
                "creator": props.username,
                "version": 0.1,
                "size": genes.length,
                "type": "gene-list",
                "active": state,
                "creationDate": date_generator()
            }

            //setOpenDialog(true);
            saveFile(list_to_save);

        }
        else if(uploadListOption === "list-file"){

            let mappedItem = {
                "title": list.name,
                "description": list.tooltip,
                "source": (list.source!== undefined) ? list.source : "",
                "notes": "",
                "data": list.genes,
                "permission": "public",
                "creator": "CNAG",
                "version": 0.1,
                "size": list.genes.length,
                "type": "gene-list",
                "active": true,
                "creationDate": list.date
            }

            saveFile(mappedItem);
        }
        else{

            let mappedItem = {
                "title": list.name,
                "description": list.description,
                "source": (list.source!== undefined) ? list.source : "",
                "notes": "",
                "data": list.genes,
                "permission": "public",
                "creator": list.creator,
                "version": 0.1,
                "size": list.genes.length,
                "type": "gene-list",
                "active": true,
                "creationDate": list.date
            }

            saveFile(mappedItem);

        }

    }


    const saveListArray = (arrayList) => {


        arrayList.forEach(function(list){

            // map list correctly;

            handleSaveList(list);

        })

    }


    const renderUploadBox =() => {
        if (uploadListOption === 'gene-file') {
            return  <div>
                {renderDialog()}
                <React.Fragment>
                    <UploadJsonFile token = {props.token}
                                    handleSaveList = {handleSaveList}/>
                    {renderStatusBox()}
                </React.Fragment>

            </div>
        } else if (uploadListOption === 'list-file') {
            return  <div>
                <UploadListFile
                    token = {props.token}
                    saveListArray = {saveListArray}
                />
                {renderDialog()}
            </div>
        } else if (uploadListOption === 'comma-separated-list') {
            return <div>
                <Grid container direction={"column"}>

                    <Grid item lg={12}>
                        {UploadBox()}
                    </Grid>
                    <Grid item lg={12}>
                        <Box pt={2}>
                            { (!saved) ?
                                <GPAP_Button_Gray variant="contained"
                                        onClick={handleSaveList}><MappedText text="Save List"/></GPAP_Button_Gray>
                                : <GPAP_Button_Gray variant="contained"
                                          disableElevationonClick={reset}><MappedText text="Create a new List"/></GPAP_Button_Gray> }
                        </Box>
                    </Grid>
                </Grid>


            </div>
        }
        return null
        }


    const UploadBox = () => {

        return  <div style={{width: "900px"}}>
            <form className={classes.root} noValidate autoComplete="off">
                <Typography variant={"h6"}><MappedText text="List Information"/></Typography>
                <Box pt={2}>
                    <Grid container
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start">
                        <Grid item lg={6}>
                            <InputLabel required htmlFor="list-title-required" className={classes.inputLabel}><MappedText text="List Title"/></InputLabel>
                            <TextField
                                style={{width: "100%"}}
                                required
                                id="outlined-required"
                                placeholder={t("List Title")}
                                defaultValue=""
                                variant="outlined"
                                inputRef={titleRef}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <InputLabel required htmlFor="description-required" className={classes.inputLabel}><MappedText text="Description"/></InputLabel>
                            <TextField
                                style={{width: "100%"}}
                                className={classes.textField}
                                required
                                id="outlined-required"
                                placeholder={t("Description")}
                                defaultValue=""
                                variant="outlined"
                                inputRef={descriptionRef}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <InputLabel  required htmlFor="list-source-required" className={classes.inputLabel}><MappedText text="List Source"/></InputLabel>
                            <TextField
                                style={{width: "100%"}}
                                required
                                id="outlined-required"
                                placeholder={t("List Source")}
                                defaultValue=""
                                variant="outlined"
                                inputRef={sourceRef}
                            />

                        </Grid>
                        <Grid item lg={6}>
                            <InputLabel required htmlFor="list-notes-required" className={classes.inputLabel}><MappedText text="Notes"/></InputLabel>
                            <TextField
                                required
                                style={{width: "100%"}}
                                className={classes.textField}
                                id="outlined-required"
                                placeholder={t("Notes")}
                                defaultValue=""
                                variant="outlined"
                                inputRef={notesRef}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <InputLabel required htmlFor="list-select-required" className={classes.inputLabel}><MappedText text="Select"/></InputLabel>
                            <Select
                                required
                                style={{width: "100%"}}
                                className={classes.margin}
                                id="standard-select-permission"
                                select
                                placeholder={t("Select")}
                                value={listType || "gene-list"}
                                onChange={handleListType}
                                variant="outlined"
                                helperText={t("Please select list type ")}>
                                {[{value: "gene-list", label: "Gene List"}, {value: "position", label: "Variant Positions"}].map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <MappedText text={option.label}/>
                                    </MenuItem>
                                ))}
                            </Select>

                        </Grid>
                        <Grid item lg={6}>
                            <InputLabel required htmlFor="list-visibility-required" className={classes.inputLabel}><MappedText text="Visibility"/></InputLabel>
                            <TextField
                                required
                                style={{width: "100%"}}
                                className={classes.margin}
                                id="standard-select-permission"
                                select
                                variant="outlined"
                                value={permission}
                                onChange={handlePermission}
                                helperText={t("Please select study visibility")}>
                                {[{value: "user", label: "Private"}, {value: "group", label:"Share with your groups" }, {value: "public", label: "Public"}].map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <MappedText text={option.label}/>
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid item lg={12}>
                            <List_Upload
                                getGenes = {getGenes}
                            />
                        </Grid>
                        {renderStatusBox()}
                    </Grid>
                </Box>
            </form>
        </div>



    }

    const renderStatusBox = () => {

        return   <Grid item lg={4}>
            <Box pt={3}>
                <Typography variant={"body1"} style={{ marginBottom: '12px' }}><MappedText text="List Visibility"/></Typography>
                <Typography align="left" variant={"body2"} color="#8E8E8E" style={{ width: '233px', wordWrap: 'break-word' }}>
                    <MappedText text="When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users."/>
                </Typography>
                <FormControlLabel
                    control={<Switch checked={state} onChange={handleSwitch} name="Visible" />}
                    label=<MappedText text="Active"/>
                />
            </Box>
        </Grid>

    }



    return <div style={{margin:"2%"}}>
        <Container>
            <Grid container direction={"row"}>
                <Box pt={2}>
                    <Typography variant={"h5"}><MappedText text="Predefined Gene Lists"/></Typography>
                </Box>

            </Grid>

        { (props.allowed)
            ? <Grid item lg={12}>
                <Box>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label=<MappedText text="Upload a List"/>/>
                    <Tab label=<MappedText text="View Lists"/>/>
                </Tabs>
                <Divider/>

                <TabPanel value={value} index={0} className={classes.root}>
                    <Grid container direction={"row"}>
                        <Grid item lg={12}>
                            <Box>
                                <InputLabel required htmlFor="file-type" style={{margin: "33px 0 20px 0" , color: "black"}}><MappedText text="File type"/></InputLabel>
                                <Select
                                    style={{width: "612px", marginBottom: "30px"}}
                                    id="file-type-select"
                                    select
                                    placeholder="Select"
                                    value={uploadListOption} onChange={handleOptionChange}
                                    variant="outlined"
                                    helperText=<MappedText text="Please select file type"/>>
                                    {[{
                                        value: "gene-file", label: "GPAP Gene File"},
                                        {value: "list-file", label: ".list File"},
                                        {value: "comma-separated-list", label: "Comma Separated List"}].map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <MappedText text={option.label}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {renderUploadBox()}
                            </Box>
                        </Grid>
                    </Grid>

            </TabPanel>

                {/* grid de view lists */}
                <TabPanel value={value} index={1}>
                    <Box>
                        <Predefined_List_View/>
                    </Box>
                </TabPanel>
                </Box>
            </Grid>
            : <Grid item lg={12}>
                <Typography variant={"h6"}> <MappedText text="You don't have the rights to do edits here"/> ;)</Typography>
                <img src={window.config.static_files + "/img/nextgpap/403forbiddenerror.jpg"}/>
            </Grid>}
        </Container>
    </div>
}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
            return {
                token: state.authorization.token,
                allowed: state.authorization.groups.includes("admin"),
                username: state.authorization.preferredName
            }
    }
}



export default connect(mapStateToProps,null)(PredefinedGeneLists_Manager);


