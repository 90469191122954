




const formatSamples = (samples, index) => {


    return samples.filter(function (s) {
        let copy_sample = {...s};

        copy_sample.gt_settings = (copy_sample.gt_settings !== undefined) ? copy_sample.gt_settings[index] : copy_sample.gt[index] ;
        return copy_sample.gt_settings!== undefined;
    }).map(function (sample) {
        let copy_sample = {...sample};
        copy_sample.gt_settings = (copy_sample.gt_settings !== undefined) ? copy_sample.gt_settings[index] : copy_sample.gt[index] ;
        let gt_settings = [];
        // handle multiple gt settings;
        if (copy_sample.gt_settings.length !== 0) {
            gt_settings = copy_sample.gt_settings.setting
        }

        let val_index = copy_sample.index;
        if(typeof copy_sample.index !== "boolean"){
            val_index = (copy_sample.index === "Yes");

        }


        return {
            "sample_id": copy_sample.sample_id,
            "gt": [...new Set(gt_settings)],
            "index": val_index,
            "dp": copy_sample.gt_settings.dp ?? 10,
            "gq": copy_sample.gt_settings.gq ?? 30,
            "ad_low": copy_sample.gt_settings.ad_low,
            "ad_high": copy_sample.gt_settings.ad_high
        };
    });
}


export default formatSamples;
