
import React from "react";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { InputLabel } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CNAG_LOGO from '../../../../resources/CNAG_LOGO.png'
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles } from "@mui/styles";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText"

const useStyles = makeStyles((theme) => ({
    margin: {
        //margin: theme.spacing(1),
        //width: "80%" ,
    },
    inputLabel: {
        //margin: theme.spacing(1),
        color: "black",
    },
    textField: {
        width: "413px",
        textDecoration:'none',
        color:'black',
        border:'black',
        "&:hover": {
            color: "black"
        }
    },
    button:{
        width:'106px',
        height:'42px',
        background: '#3e699e',
    }
}));

function Contact(){

    const classes= useStyles()

    return <Box style={{margin:'3.5rem', width: "75%" }}>
    <Typography variant="h3" style={{margin:'30px 0', }}><MappedText text="Get in touch"/></Typography>
    <Grid container spacing={2}>
        <Grid item lg={6}>
            <Box display="flex" flexDirection='column'>
                <Typography variant="body1"><MappedText text="Thank you for using our product."/></Typography>
                <Typography variant="body1" style={{marginBottom:'30px', }}><MappedText text="Feel free to reach out if you have any questions or comments."/></Typography>
            <Box >
            <a href="mailto:instand-help@cnag.eu" style={{ textDecoration: 'none', color: '#3e699e', display:'inline-flex', marginBottom:'30px' }}><MailIcon style={{width:'25px', height:'25px' }}/>
                <Typography variant="body1"> instand-help@cnag.eu</Typography>
            </a>
            </Box>
            <Box>
                <img src={CNAG_LOGO} alt="GPAP" style={{marginBottom:'30px', }}/></Box>
                <Typography variant="body1" style={{ fontWeight: '700'}}>Centre Nacional d’Anàlisi Genòmica</Typography>
                <Typography variant="body1" style={{marginBottom:'30px', }}><MappedText text="Data Platforms & Tools Development Team"/></Typography>
                <Typography variant="body1"><MappedText text="Barcelona, Spain"/></Typography>
            </Box>
        </Grid>

        {/* form to send suggestions  */}

        {/* <Grid item lg={6}>
            <Box display="flex" flexDirection='column'  >
                <InputLabel required htmlFor="name" className={classes.inputLabel}>Name</InputLabel>
                <TextField  variant="outlined" placeholder="Your name" margin="normal" className={classes.textField} />
                <InputLabel required htmlFor="email" className={classes.inputLabel}>Email</InputLabel>
                <TextField variant="outlined" placeholder="name@email.com" margin="normal" className={classes.textField}/>
                <InputLabel required htmlFor="message" className={classes.inputLabel}>Message</InputLabel>
                <TextField variant="outlined" multiline rows={4}  margin="normal" placeholder="Hey" className={classes.textField}/>
                </Box>
        <Grid item lg={6} style={{ display: 'flex', justifyContent: 'flex-end',  }}>
            <Button variant="contained" color="primary" type="submit" className={classes.button} style={{backgroundColor:'#3e699e'}}> <SendIcon/> Send </Button>
        </Grid>
                
        </Grid> */}
    </Grid>
</Box>

}

export default Contact;