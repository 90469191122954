


import React from "react";
import {check_samples, check_snv_effects, check_variants, getFormValues, getPosition} from "./data-retrieve";
import {formValueSelector } from 'redux-form';

const selector = formValueSelector('samples');

export function StateMapper(state, inheritance_key, analysis_ids)
{
    //let sample_list = [];
    let variant_type;
    let snv_effects;
    let population;
    let position;

    let inheritance = analysis_ids[0];


    const SAMPLES_BOOL = state.sample_list.samples.length !== 0;
    const SETTINGS_BOOL = window.location.href.includes("settings");

    if( SAMPLES_BOOL || SETTINGS_BOOL )
    {
        let sample_list = [];
        if(SAMPLES_BOOL){
            let samples_state = [...state.sample_list.samples]
            sample_list = samples_state.map(function(sample_row){
                let extra_settings = {"dp": 30,"gq":30 ,"ad_low":0.2,"ad_high":0.8};
                if(inheritance!== undefined){
                    let final_gt_settings = sample_row.gt_settings.find(el => el.inheritance_key === inheritance_key && el.label === inheritance.label);
                    if(final_gt_settings!== undefined){
                        extra_settings = {"dp":final_gt_settings.dp,"gq":final_gt_settings.gq,"ad_low":final_gt_settings.ad_low,"ad_high":final_gt_settings.ad_high};
                    }
                }


                // get real quality  settings

                let gt_settings = [];
                // handle multiple gt settings;
                if(sample_row.gt_settings.length!==0){gt_settings = sample_row.gt_settings[0].setting}

                let gt_config = (gt_settings!== undefined) ? gt_settings : sample_row.gt_settings;
                let obj = {"sample_id": sample_row.sample_id, "gt": gt_config, "index": sample_row.affectedStatus};
                return Object.assign(obj, extra_settings);
            });
        }

        variant_type = check_variants(state.form.variants);
        let genes = [];
        if(state.geneList!== undefined){
            genes= state.geneList.geneList
        }
        snv_effects = check_snv_effects(state.form.snv_effects);
        population = getFormValues(state.form.population, 'population');
        position  = getPosition(state.form.position_roh, 'position_roh');

        return {
            samples: sample_list,
            variant_type : variant_type,
            snv_effects: snv_effects,
            population : population,
            position: position,
            genes:genes
        }
    }
    else{
        return {samples:[]}
    }

}



