



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import igv from "./img/igv_genomics.png";
import gpap_old from "./img/genomics_old.png";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {ListItemSecondaryAction, Paper} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {GPAP_Button} from "../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../gpap-lib/components/GPAP_Tooltip";

const useStyles = makeStyles({
    root: {
        /*   minWidth: 275,*/
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    large: {
        width: "300px",
        height: "200px",
    },
});


function getImage(image){


    switch (image) {
        case "genomics_old":
            return gpap_old;
        case "igv":
            return igv;
    }

}





export default function AppCard({title, description, home_link, doc_link, instance, image, link, playground}) {


    const classes = useStyles();

    const url_prefix = window.location.origin;
    const link_key = (instance === "playground" ) ? window.config.playground_url : link;

    let link_url = "";

    if(instance === "playground"){
        link_url =  url_prefix + playground
    }
    else{
        link_url =(home_link) ? link_key : url_prefix + link
    }

    const getUserGuideBtn =  (doc_link) => {

        if(doc_link.includes("youtube")){
            return <GPAP_Button
                color={"primary"}
                size="small"
                href={ doc_link} target={"_blank"}>See YouTube Tutorials</GPAP_Button>
        }
        else{
            return <GPAP_Button
                color={"primary"}
                size="small"
                href={ window.config.doc_url + doc_link} target={"_blank"}>User guide</GPAP_Button>
        }

    }


    return  <React.Fragment>
        <Paper>
            <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar variant="square" alt="Remy Sharp" src={getImage(image)} className={classes.large}/>
            </ListItemAvatar>
            <ListItemText
                primary={<Box p={2}>
                    <Typography variant={"h6"}>{title}</Typography>
                </Box>}
                secondary={
                    <React.Fragment>
                        <Box p={2}>
                            <Typography variant={"subtitle2"}>
                                {description}
                            </Typography>
                        </Box>
                        <Box p={2}>
                            <GPAP_Button color="primary" size="small" href={ link_url } >Access</GPAP_Button>
                            <GPAP_Tooltip title={(doc_link=== "") ? "User guide not available for this module" : "See the user documentation" }>
                                {getUserGuideBtn(doc_link)}
                            </GPAP_Tooltip>
                        </Box>
                    </React.Fragment>
                }
            />
        </ListItem>
        </Paper>
    </React.Fragment>

}