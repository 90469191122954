import React, { useState, useEffect } from 'react';
import "../../../dashboard/components/genomics-dashboard/components/results/results.css";
import Variant_Table from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/Variant_Table";
import GeneralQueryView from "../../../dashboard/components/genomics-dashboard/components/results/components/general-query-view/GeneralQueryView";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { connect } from 'react-redux';
import { getTotalFromJson } from "../../../dashboard/components/genomics-dashboard/components/results/getTotalFromJson";
import { getTableData } from "../../../dashboard/components/genomics-dashboard/components/results/getTableData";
import MainView_ManualFiltering from "./MainView_ManualFiltering";

function ManualFiltering(props) {
    const [index, setIndex] = useState(0);
    const [annotations, setAnnotations] = useState([]);
    const [snvTotal, setSnvTotal] = useState(0);
    const [variant_data, setVariantData] = useState([]);


    useEffect(() => {
        // Your code that runs when props or state change goes here
        let snv = undefined;
        let variant_data = undefined;

        if (props.variants !== undefined && props.variants.snv !== undefined) {
            snv = props.variants.snv;

            // Create Variant Table Data;
            variant_data = getTableData(snv, annotations, props.analysisType, props.samples);
            setVariantData(variant_data);
        }

        const { snv_total } = getTotalFromJson(props.variants);

        setSnvTotal(snv_total);


        // Update state or perform side effects based on the changes in props or state
        // For example, you can set the state using setAnnotations, setIndex, setCnvTotal, etc.
    }, [props.variants, props.analysisType, props.samples, annotations]);


    const renderMainFindings = () => {


        return  <MainView_ManualFiltering
            // mandatory props
            loading={props.loading}
            variants={variant_data}
            setIndex={setIndex}
            total={snvTotal}
            type={props.type}
            summary={props.summary}
            queryRunner={props.queryRunner}
            fetchData={props.fetchData}
            all_tagged_variants={props.all_tagged_variants}
            getAllTaggedVariants={props.getAllTaggedVariants}
            passAnnotations={setAnnotations}
            resetAll={props.resetAll}
            requestStatus={props.requestStatus}
            switchAnalysis={props.switchAnalysis}
            switchQuery={props.switchQuery}
            // optional props;
            // search across all
            loadingParticipants={props.loadingParticipants}
            cnv_data={true}
            sampleList={props.sampleList}
            participants={props.participants}
            sample_agg={props.sample_agg}
            instandGeneticFindingsTab = {props.instandGeneticFindingsTab}
            getCNVTotal={() => {return 0}}
        />



    }


    return (
        <div className={"results_view_compact"}>
            <Backdrop style={{ color: "white", zIndex: "100" }} open={props.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* <GeneralQueryView
          variants_n = {total}
          queryRunner={props.run_query}
      /> */}
            {renderMainFindings()}
        </div>
    );
}

function mapStateToProps(state) {
    if (state.authorization !== undefined) {
        return {
            patients: state.patients.patients,
            analysisType: state.studySettings.analysisType,
            samples: state.sample_list.samples,
        };
    }
}

export default connect(mapStateToProps)(ManualFiltering);
