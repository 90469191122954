



//ACTIONS


export const ADD_TO_QUERY_LIST = 'ADD_TO_QUERY_LIST';
export const ADD_AUTHORIZATION = 'authorization';
export const ADD_VARIANTS = 'variants';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const SET_CURRENT_QUERY = 'SET_CURRENT_QUERY';
export const RESET_CURRENT_QUERY = 'RESET_CURRENT_QUERY';

export const SET_FILTER_SET = 'SET_FILTER_SET';
export const RESET_FILTER_SET = 'RESET_FILTER_SET';


export const ADD_CHROM = "ADD_CHROM";
export const SET_SA_QUERY = 'SET_SA_QUERY';
export const RESET_SA_QUERY = 'RESET_SA_QUERY';
export const SET_SELECTED_COHORT = "SET_SELECTED_COHORT";
export const RESET_SELECTED_COHORT = "RESET_SELECTED_COHORT";


export const addAuthorization = (authorization) => ({
    type: ADD_AUTHORIZATION,
    authorization: authorization
});


export const addVariants = (variants) => ({
    type: ADD_VARIANTS,
    variants: variants
});


export function addToQuery(new_query) {
    return {
        type: ADD_TO_QUERY_LIST,
        new_query: new_query }
}


/*
 * action creators
 */
export function updateQuery(query) {
    return {
        type: UPDATE_QUERY,
        query: query }
}

export function add_chrom(chrom){
    return {
        type: ADD_CHROM,
        chrom: chrom
    }
}

/*
 * action creators
 */
export function set_current_query(query) {
    return {
        type: SET_CURRENT_QUERY,
        query: query }
}

export function reset_current_query(query) {
    return { type: RESET_CURRENT_QUERY }
}


/*
 * action creators
 */
export function set_filter_set(filter_set) {
    return {
        type: SET_FILTER_SET,
        currentFilterSet: filter_set
    }
}

export function reset_filter_set() {
    return {
        type: RESET_FILTER_SET
    }
}



/*
 * action creators
 */
export function set_sa_query(query) {
    return {
        type: SET_SA_QUERY,
        sa_query: query }
}

export function reset_sa_query(query) {
    return { type: RESET_SA_QUERY }
}


/*
 * select cohort action creators
 */
export function set_selected_cohort(cohort) {
    return {
        type: SET_SELECTED_COHORT,
        cohort: cohort }
}

export function reset_selected_cohort() {
    return {
        type: RESET_SELECTED_COHORT
    }
}










