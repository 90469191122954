import axios from "axios";

export const apiData = async (data, config) => {
  const { url, method, endpoint, idRelation, files, token } = config[0];

  let response;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const promises = data.map(async (item) => {
    const idValue = item?.[idRelation];

    if (!idValue) {
      const naFilesData = files.reduce((acc, file) => {
        return {...acc, [file]: "N/A"};
      }, {});

      return {...item, ...naFilesData};
    }

    if (method.toLowerCase() === "get") {
      response = await axios[method.toLowerCase()](
        `${url}/${endpoint}/${idValue}`,
        { headers }
      );
    } else {
      const body = {  
        "page": 1,
        "pageSize": 1,
        "filtered": [{
            "id": "RD_Connect_ID_Experiment", 
            "value": [idValue]
        }],
        "fields": files,
        "sorted": [],
      };

      response = await axios[method.toLowerCase()](`${url}/${endpoint}/`, body, { headers });
    }


    const filesData = files.reduce((acc, file) => {
      const value = response.data.items && response.data.items.length > 0
                    ? response.data.items[0][file]
                    : "N/A";
      return {...acc, [file]: value};
    }, {});

    return {...item, ...filesData};
  });

  const dataPromise = await Promise.all(promises);

  return dataPromise;
};
