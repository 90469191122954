import {h37_endpoint, h38_endpoint} from "./ensembl";


export function get_biosoftware(chr, start, end){
    return fetch("https://rd-connect.interactive-biosoftware.com/alfa/server/request.php?chr=" + chr + "&start=" + start + "&end=" + end,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
              /*  'Authorization': token*/
            }
        }

    )
}

// NOT USED;
export function get_ensembled( transcript ){


    let url=h37_endpoint;
    if(window.config.genome_ref === "hg38"){
        url = h38_endpoint
    }
    return fetch(url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                /*  'Authorization': token*/
            }
        }

    )
}



export function get_wikipathways( gene ){
    return fetch("https://webservice.wikipathways.org/findPathwaysByText?query=" + gene + "&species=Homo+sapiens&format=json",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                /*  'Authorization': token*/
            }
        }

    )
}

export function get_pathways_reactome( gene ){
    return fetch("https://www.pathwaycommons.org/pc2/search.json?q=" + gene + "&type=pathway&datasource=reactome",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                /*  'Authorization': token*/
            }
        }

    )
}


export function get_quickGo( gene ){
    return fetch("https://www.ebi.ac.uk/QuickGO/services/geneproduct/search?taxonId=9606&dbSubset=Swiss-Prot&query=" + gene,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                /*  'Authorization': token*/
            }
        }

    )
}

export function get_alfa( chrom, start, end, ref, alt  ){
    return fetch("https://rd-connect.interactive-biosoftware.com/alfa/server/annotation.php?variant_chromosome=" + 1 + "&variant_start=" + start + "&variant_end=" + end + "&db=GRCh37" + "&variant_referenceBases=" + ref + "&variant_alternateBases=" + alt +"&",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                /*  'Authorization': token*/
            }
        }

    )
}




export function get_hmtdb( chrom, start, end, ref, alt, token  ){
    return fetch("https://www.hmtvar.uniba.it/api/main/mutation/" + ref + start + alt,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}

export function get_edgar( gene ){
    return fetch("https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/annotation.py?gene_id=" + gene,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }

    )
}

export function get_diseaseCard( gene, token  ){
    return fetch("https://bioinformatics.ua.pt/diseasecard/hgnc/" + gene + "/js",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}

export function get_ebi_quickGo_Annotation( gene, token  ){
    return fetch("https://www.ebi.ac.uk/QuickGO/services/annotation/search?limit=50&geneProductId=" + gene,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}

export function get_ebi_quickGo( go_id  ){
    return fetch("https://www.ebi.ac.uk/QuickGO/services/ontology/go/terms/" + go_id,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;"
            }
        }

    )
}

export function getHpsCreg(payload){
    return fetch("https://ejp-rd-dev1.vm.cesnet.cz/semantic-services/graphdb/repositories/hpscreg",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/x-www-form-urlencoded"
            },
            body: payload
        }

    )

}

