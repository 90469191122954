


export const INTERSECT_GENE_LIST = 'INTERSECT_GENE_LIST';
export const REPLACE_GENES_TO_SPECIFIC_GENELIST = "REPLACE_GENES_TO_SPECIFIC_GENELIST";
export const ADD_GENES_TO_SPECIFIC_GENELIST = "ADD_GENES_TO_SPECIFIC_GENELIST";
export const SET_GENE_OPERATION = 'SET_GENE_OPERATION';
export const RESET_GENES = 'RESET_GENES';
export const RESET_GENELIST = 'RESET_GENELIST';
export const REMOVE_GENES_FROM_LIST = "REMOVE_GENES_FROM_LIST";
export const  REMOVE_APPLIED_LIST = "REMOVE_APPLIED_LIST";
export const INIT_APPLIED_LISTS = "INIT_APPLIED_LISTS";
export const ADD_APPLIED_LIST = "ADD_APPLIED_LIST";
export const REMOVE_LIST_TYPE = "REMOVE_LIST_TYPE";
export const RESET_LIST_TYPE = "RESET_LIST_TYPE";
export const ADD_TO_ALL_GENES = "ADD_TO_ALL_GENES";
export const REMOVE_ALL_APPLIED_LISTS = "REMOVE_ALL_APPLIED_LISTS";

export function intersect_genelist(genes, key) {
    return {
        type: INTERSECT_GENE_LIST,
        genes: genes,
        key: key
    }
}

export function replace_genes_to_specific_genelist(genes,key) {
    return {
        type: REPLACE_GENES_TO_SPECIFIC_GENELIST,
        genes: genes,
        key: key
    }
}


export function add_genes_to_specific_genelist(genes,key) {
    return {
        type: ADD_GENES_TO_SPECIFIC_GENELIST,
        genes: genes,
        key: key
    }
}


export function setGeneOperation(gene_operation) {
    return {
        type: SET_GENE_OPERATION,
        gene_operation: gene_operation }
}
export function resetGenes(genes_black) {
    return {
        type: RESET_GENES,
        genes: genes_black}
}

export function reset_geneList(genekey){
    return {
        type: RESET_GENELIST,
        key: genekey
    }
}

export function remove_genes_from_list(genes, key){
    return {
        type:REMOVE_GENES_FROM_LIST,
        key: key,
        genes: genes
    }
}

export function init_applied_lists(lists){
    return {
        type:INIT_APPLIED_LISTS,
        lists: lists
    }
}


export function add_applied_list(list){
    return {
        type:ADD_APPLIED_LIST,
        list: list
    }
}

export function add_to_all_genes(genes){
    return {
        type: ADD_TO_ALL_GENES,
        genes: genes
    }
}

export function remove_applied_list(id, list_type){
    return {
        type:REMOVE_APPLIED_LIST,
        id: id,
        list_type: list_type
    }
}

export function remove_list_types(list_type){
    return {
        type:REMOVE_LIST_TYPE,
        list_type: list_type
    }
}

export function remove_all_lists(){
    return {
        type:REMOVE_ALL_APPLIED_LISTS
    }
}

export function reset_list_types(list_type){
    return {
        type:RESET_LIST_TYPE,
        list_type: list_type
    }
}

