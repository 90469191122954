




import React, {useState} from 'react'

import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../../../actions";
import {connect} from "react-redux";

import "../../css/query-view.css"
import Gene_Section_View from "../filter-sections/gene-section/Gene_SectionView";

import All_Filters_Accordion from "./All_Filters_Accordion";

import Box from "@material-ui/core/Box";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from '@material-ui/core/Collapse';
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';




function FiltersContainer(props) {

    const [openVariant, setOpenVariant] = useState(false);
    const [openGenes, setOpenGenes] = useState(false);

    const renderAccordionOptions = () => {

       return <All_Filters_Accordion
           default_filters = { props.default_filters }
           pointers = { props.pointers }
           somatic = {props.somatic}
       />
    };


        return  (<div className={"querview_content"}>
            <Box p={0}>
                <div className={"title_section_blue"}>
                <MappedText text={"Create/Adjust Filters"}/>
                </div>
            </Box>
            <Box p={1}>
                <Box p={1}>
                    <GPAP_Tooltip title={<MappedText text={"Filter by variants"}/>}>
                        <div className={"title_section"} onClick={() => setOpenVariant(!openVariant) }>
                            <div style={{float: "left"}}>
                                <IconButton
                                    style={{padding: "0px"}}
                                    onClick={() => setOpenVariant(!openVariant)  } >
                                    {openVariant ? <ExpandLess /> : <ExpandMore style={{order: -1,
                                        transform: "rotate(-90deg)"}}/>}
                                </IconButton> </div>
                            <span > <MappedText text={"Filter by variants"}/></span>
                        </div>
                    </GPAP_Tooltip>
                </Box>
                <Collapse in={openVariant}>
                {renderAccordionOptions()}
                </Collapse>
                <Box p={1}>
                    <Box p={0}>
                        <GPAP_Tooltip title={<MappedText text={"Gene Filters"}/>}>
                            <div className={"title_section"} onClick={() => setOpenGenes(!openGenes) }>
                                <div style={{float: "left"}}>
                                    <IconButton
                                        style={{padding: "0px"}}
                                        onClick={() => setOpenGenes(!openGenes)  } >
                                        {openGenes ? <ExpandLess /> : <ExpandMore style={{order: -1,
                                            transform: "rotate(-90deg)"}}/>}
                                    </IconButton> </div>
                                <span ><MappedText text={"Filter by genes"}/> </span>
                            </div>
                        </GPAP_Tooltip>
                    </Box>
                    <Collapse in={openGenes}>
                        <Gene_Section_View/>
                    </Collapse>

                </Box>

            </Box>
        </div>)

}



function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            gene_tot: (state.geneList.geneList) ? state.geneList.geneList.length : 0


        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(FiltersContainer);