



function count_Distinct(variable, data){

    let b = [];

    let allvalues = data.map(item => item[variable]);

    //distinct values;
    let distinct=[...new Set(allvalues)];

    distinct.forEach(function(d){
        let count = allvalues.filter(function(v){return v===d;}).length;
        if(d === undefined) {d= "NA"}
        b.push({key: variable, d: d, value: count})
    });

    return b;


}



export function extractPills(data){


    let effect = count_Distinct('effect_impact', data);
    let clinvar = count_Distinct('clinvar_clnsig', data);
    let genes = count_Distinct('gene_name', data);
    let populations = count_Distinct('gnomad_af', data);


    return [
        {key: 'effect', values : effect},
        {key: 'clinvar', values : clinvar},
        {key: 'gene', values: genes},
        {key: 'population_gnomad_af_na', values: populations }
        ]
}