



const additional_info_1 = [
    { nameColumn: "Gene Count", dataSearch: "Gene_count", tooltip: "Number of overlapped genes with the SV" },
    { nameColumn: "GnomAD pLI", dataSearch: "GnomAD_pLI", tooltip: "pLI score indicates the probability that a gene is intolerant to a loss-of-function variation. A gene with pLI>=0.9 is considered as an extremely LoF intolerant gene.  For SV covering several genes, only the most pathogenic score is reported in Complete view." },
    { nameColumn: "LOEUF bin", dataSearch: "LOEUF_bin", tooltip: "Loss-of-function observed/expected upper bound fraction. Low scores (e.g. 0) indicate strong selection against predicted loss-of-function (pLoF) variation in a given gene. High scores (e.g. 9) suggest a relatively higher tolerance to inactivation.  For SV covering several genes, only the most pathogenic score is reported in Complete view." },
    { nameColumn: "ACMG evidences", dataSearch: "AnnotSV_ranking_criteria", tooltip: "According to https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7313390/" },
    { nameColumn: "ACMG score", dataSearch: "AnnotSV_ranking_score", tooltip: "According to https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7313390/" },
    { nameColumn: "Regulatory Element", dataSearch: "Re_gene", tooltip: "Name of the genes regulated by a regulatory element overlapped with the SV" },
    { nameColumn: "Pathogenic Loss", dataSearch: "P_loss_phen", tooltip: "Phenotype of the pathogenic loss genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (HI3), morbid." },
    { nameColumn: "Pathogenic Gain", dataSearch: "P_gain_phen", tooltip: "Phenotype of the pathogenic gain genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (HI3)." },
    { nameColumn: "Pathogenic Insertion", dataSearch: "P_ins_phen", tooltip: "Phenotype of the pathogenic insertion genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN)." },
    { nameColumn: "Pathogenic SNV-InDel", dataSearch: "P_snvindel_phen", tooltip: "Phenotypes of pathogenic snv/indel from public databases completely overlapped with the SV" },
];

const additional_info_2 = [
    { nameColumn: "Transcript", dataSearch: "Tx"},
    { nameColumn: "Exon Count", dataSearch: "Exon_count"},
    { nameColumn: "Region", dataSearch: "Location2", tooltip: "SV location in the gene’s coding regions" },
    { nameColumn: "Haploinsufficiency", dataSearch: "HI", tooltip: "ClinGen Haploinsufficiency Score" },
    { nameColumn: "Triplosensitivity", dataSearch: "TS", tooltip: "ClinGen Triplosensitivity Score" },
    { nameColumn: "ACMG Evidences", dataSearch: "AnnotSV_ranking_criteria", tooltip: "According to https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7313390/" },
    { nameColumn: "ACMG Score", dataSearch: "AnnotSV_ranking_score", tooltip: "According to https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7313390/" },
    { nameColumn: "GnomAD pLI", dataSearch: "GnomAD_pLI", tooltip: "pLI score indicates the probability that a gene is intolerant to a loss-of-function variation. A gene with pLI>=0.9 is considered as an extremely LoF intolerant gene.  For SV covering several genes, only the most pathogenic score is reported in Complete view." },
    { nameColumn: "P ins phen", dataSearch: "LOEUF_bin", tooltip: "Loss-of-function observed/expected upper bound fraction. Low scores (e.g. 0) indicate strong selection against predicted loss-of-function (pLoF) variation in a given gene. High scores (e.g. 9) suggest a relatively higher tolerance to inactivation.  For SV covering several genes, only the most pathogenic score is reported in Complete view." },
    { nameColumn: "Pathogenic Loss", dataSearch: "P_loss_phen", tooltip: "Phenotype of the pathogenic loss genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (HI3), morbid." },
    { nameColumn: "Pathogenic Gain", dataSearch: "P_gain_phen", tooltip: "Phenotype of the pathogenic gain genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (HI3)." },
    { nameColumn: "Pathogenic Insertion", dataSearch: "P_ins_phen", tooltip: "Phenotype of the pathogenic insertion genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN)." },
    { nameColumn: "Pathogenic SNV-InDel", dataSearch: "P_snvindel_phen", tooltip: "Phenotypes of pathogenic snv/indel from public databases completely overlapped with the SV" },
];


export {additional_info_1, additional_info_2}