import React, {useEffect, useState} from "react";
import MUITable from "./MUI-table/MUI-Table";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions";
import {connect} from "react-redux";
import {Accordion, AccordionDetails} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {ExpandMoreOutlined} from "@material-ui/icons";
import IconLeftExpansionPanelSummary from "./LeftIconExpansion";
import useTableStyles from "./styles_table";
import ParticipantHeader from "./ParticipantHeader";


function ParticipantTable(props){


    const [rows, setRows] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState([]);
    // handle selection variants;

    useEffect(function(){

        let par_array = [];
        if(props.selectedVariant!== undefined && props.selectedVariant.length > 0){

            let variant_obj = props.selectedVariant[0].variant;
            if(props.participants.map_by_exp!== undefined){
                 let sample_array = [];
                variant_obj.forEach(s => sample_array = sample_array.concat(s.samples_germline.map(g => g.sample)));
                 par_array = props.participants.map_by_exp.filter(s => sample_array.includes(s.ExperimentID));
                 let my_list = par_array.map(s=> s.Participant_ID);
                 setParticipants(my_list);
                 let data = (props.participants.participants!== undefined) ? props.participants.participants : [];
                 let filtered = data.filter(s =>  my_list.includes(s.report_id));
                 setRows(filtered)
            }
            }
        else{

            // TODO: check the selected participant file;
            let data = (props.participants.participants!== undefined)
                ? props.participants.participants
                : [];

            setRows(data)
           /* if(selectedParticipant.length === 0){

            }*/
        }


    },[props.selectedVariant, props.participants]);



    const handleSelectedParticipant = (selected, fromParticipantTable)=> {

        // reset selected variants;
        props.actions.selectedVariant([]);
        // pass samples;
        props.selectParticipants(selected, fromParticipantTable);

        if(fromParticipantTable){
            setSelectedParticipant(selected);
        }
        else{
            setSelectedParticipant([])
        }

    };

    const getTableTitleText = () => {
        return <ParticipantHeader total={props.participants.total}
                                  loadingParticipants={props.loadingParticipants}
                                  rowsL={rows.length}/>
    }


    return <Box p={1} style={{float: "left", width: "100%"}}>
        <Accordion defaultExpanded={false}>
            <IconLeftExpansionPanelSummary
                style={{backgroundColor: "#f0f0f0"}}
                expandIcon={<ExpandMoreOutlined />}
                IconButtonProps={{edge: 'start'}}
                aria-controls="panel1a-content"
                id="panel1a-header">
                    {getTableTitleText()}
            </IconLeftExpansionPanelSummary>
            <AccordionDetails>
                 <MUITable
                        loadingParticipants = {props.loadingParticipants}
                        table_page={1}
                        selectParticipants = {handleSelectedParticipant}
                        loadMore= {function(d){console.log("xxx")}}
                        total_participants={rows.length}
                        rows={rows}
                        experiments={props.sampleList}
                        total={props.participants.total}
                        aggregations={props.participants.aggregations}
                        query_id={props.query_id}
                    />
            </AccordionDetails>
        </Accordion>
    </Box>




}



function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
          /*  studySettings: state.studySettings,
            studyBucket: state.studyBucket,*/
            selectedVariant: state.selected_variant
        }
    }
    else{
        return {snv: []}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



//export default Variant_Table


export default connect(mapStateToProps,mapDispatchToProps)(ParticipantTable);