import {Paragraph, TextRun, HeadingLevel, AlignmentType} from 'docx';
import React from "react";
import { renderToString } from '../../../create-report/shared-components/renderToString';


const WriteDisclaimer = (title, disclaimer) => {
    const disclaimerText = convertReactElementToPlainText(disclaimer);
    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("DISCLAIMER"),
                    size: 24,
                    color: '#000000',
                    bold: true,
                    heading: HeadingLevel.HEADING_2,
                    
                }),
            ],
        }),
        
        new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: disclaimerText,
                    size: 24,
                    font: 'calibri'
                    
                }),
            ],
        }),
    ];
};


const convertReactElementToPlainText = (reactElement) => {
    if (!reactElement) {
        return ""; 
    }
    if (typeof reactElement === "string") {
        return reactElement; 
    }
    if (Array.isArray(reactElement)) {
        return reactElement.map(element => convertReactElementToPlainText(element)).join(""); 
    }
    if (reactElement.props && reactElement.props.children) {
        return convertReactElementToPlainText(reactElement.props.children); 
    }
    return ""; 
};

export default WriteDisclaimer;