  import axios from "axios";

  export const fetchApi = async (options) => {
  
    const { urlBase, token, data, method, endpoint, identifier } = options;

    const instanceAxios = axios.create({
      baseURL: urlBase,
      headers: {
        Authorization: token,
      },

    });

    if (method === "GET") {
      const response = await instanceAxios.get(identifier ? `${endpoint}/${identifier}` : `${endpoint}` );
      return response.data;
    } else if (method === "POST") {
      if (identifier) {
        const response = await instanceAxios.post(
          `${endpoint}/${identifier}`,
          data
        );
        return response.data;
      } else {
        const response = await instanceAxios.post(`${endpoint}`, data);
        return response.data;
      }
    } else if (method === "PUT") {
      const response = await instanceAxios.put(identifier, data);
      return response.data;
    } else if (method === "DELETE") {
      const response = await instanceAxios.delete(identifier);
      return response.data;
    }
  };
