import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel } from '@mui/material';
import { setSomaticSamples } from '../../../../reducers/sample_actions';
import { useDispatch } from 'react-redux';
import {connect} from "react-redux";
import { useEffect } from 'react';

const GenotypeToolsRow = (props) => {

  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(0);
  const [tools, setTools] = useState({
    Dragen: false,
    Mutect: false
  });

  useEffect(() => {

    let updatedSomaticSamples = props.somatic_samples[0];

    // Transform tools object into an array of selected tools
    const selectedTools = Object.entries(tools).filter(([key, value]) => value === true).map(([key]) => key.toLowerCase());

    // Update only gt_somatic_settings in the existing somaticSamples object
    updatedSomaticSamples.gt_settings[0].min_num_tools = selectedValue
    updatedSomaticSamples.gt_settings[0].tools = selectedTools

    
    // Define a function to dispatch the action and setSomaticSamples
    const updateSamples = () => {
      //dispatch(setSomaticSamples(selectedValue, tools));

      dispatch(setSomaticSamples(
        updatedSomaticSamples.sample_id,
        updatedSomaticSamples.pheno_id,
        updatedSomaticSamples.sample_type,
        updatedSomaticSamples.affectedStatus,
        "",
        updatedSomaticSamples.gt_settings,
        "WGS",
        "somatic"
       )
      )

    };

    // Call the updateSamples function whenever selectedValue or tools change
    if (updatedSomaticSamples.sample_id)
       updateSamples();

  }, [selectedValue, tools]);


  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setTools({ ...tools, [event.target.name]: event.target.checked });
  };

  const dropdownOptions = [0, 1, 2];

  return (
    <Grid container spacing={2}>
      {/* Min number of tools in not currently used
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="minimum-tools-label">Minimum Tools</InputLabel>
          <Select
            labelId="minimum-tools-label"
            id="minimum-tools-select"
            value={selectedValue}
            label="Minimum Tools"
            onChange={handleDropdownChange}
          >
            {dropdownOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      */}
      <Grid item xs={6}>
        <FormControl component="fieldset">
          <div id="somatic-tools-label">Tools</div>
          <div style={{ display: 'flex' }}>
            {Object.entries(tools).map(([tool, isChecked]) => (
              <FormControlLabel
                key={tool}
                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} name={tool} />}
                label={tool}
              />
            ))}
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
  if(state.patients.length!==0)
  {
      return {
          patients: state.patients.patients,
          samples: state.sample_list.samples,
          somatic_samples: state.sample_list.somatic_samples,
          token: state.authorization.token,
          studySettings: state.studySettings,
          studyBucket: state.studyBucket
      }
  }
  else{
      return {patients: [], samples:[], token: state.authorization.token}
  }
}

export default connect(mapStateToProps)(GenotypeToolsRow);
//export default GenotypeToolsRow;
