import React from "react";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";

export const DATA_HEADER = [
  
    {
        header: <MappedText text="Pharmacogenomics"/>,
            columns: [
                { nameTable: <MappedText text="Gene"/>, dataSearch: "Gene", tooltip: "" },
                { filterType: {type: "none"}, nameTable: <MappedText text="Gene Evidence"/>, dataSearch: "pgx_uri"},
                { nameTable: <MappedText text="Genotype"/>, dataSearch: "Genotype", tooltip: "" },
                { filterType: { type: "select", option: "default" }, nameTable: <MappedText text="Phenotype"/>, dataSearch: "Phenotype", tooltip: "" },
                { nameTable: <MappedText text="Haplotype1"/>, dataSearch: "Haplotype1", tooltip: "" },
                { filterType: {type: "none"}, nameTable: <MappedText text="Haplotype1 Evidence"/>, dataSearch: "pgx_haplo1_uri" },
                { nameTable: <MappedText text="Haplotype2"/>, dataSearch: "Haplotype2", tooltip: "" },
                { filterType: {type: "none"}, nameTable: <MappedText text="Haplotype2 Evidence"/>, dataSearch: "pgx_haplo2_uri" },
                { nameTable: <MappedText text="Variant Data"/>, dataSearch: "VariantData", tooltip: "" },
            ],
    },
];


