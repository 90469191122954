
import React, {useEffect, useState} from "react";
import SelectedRowInfoBox from "../../../create-report/shared-components/SelectedRowInfoBox.js";
import ReportTypography from "../../../create-report/shared-components/ReportTypography.js";
import {renderTextField} from "../annexes/annexes_components.js";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import MappedText from "../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText.js";




function Interpretation({variants, handleSectionUpdate, variant_type, analysisType}){


    const [variants_data, setVariantData] = useState([]);
    const [interpretation, setInterpretation] = useState({ conclusions: "", recommendations: "" });



    const handleInterpretationChange = (event) => {
        const { name, value } = event.target;
        setInterpretation(prevState => ({
            ...prevState,
            [name]: value
        }));
        handleSectionUpdate({
            rows: variants_data,
            conclusions: name === 'conclusions' ? value : interpretation.conclusions,
            recommendations: name === 'recommendations' ? value : interpretation.recommendations
        }, "Interpretation");
    }

    useEffect(function(){

        if(variants && variants.rows){
            setVariantData(variants.rows)
        }

    }, [variants])






    const handleTextChange = (index, value) => {

        // get prevState of the Tagged Treatments and update comment;
        setVariantData(prevState => {
            const updatedVariants = [...prevState];
            updatedVariants[index].comments = value;
            handleSectionUpdate({rows: updatedVariants, conclusions: interpretation.conclusions, recommendations: interpretation.recommendations}, "Interpretation", variant_type);
            return updatedVariants;
        });
    };




    const renderInfoBoxes = () => {

        return variants_data.map((s,i) => {

            const selected= true;

            // TODO: review transcript format and difference SNV vs CNV

            const field1 = s.transcript;

            // difference CNV vs SNV
            let positionField;
            if(s.start_pos){

                let end_pos = (s.end_pos) ? ":" + s.end_pos : "";
                positionField = s.chromosome  + ":"+ s.start_pos + end_pos;

            }
            else{

                //positionField = s.chromosome  + ":"+ s.SV_start + ":" + s.SV_end;
                positionField    = "//"
            }

            const fields = { "field1": field1, "field2": s.gene, "field3": s.cDNA, "field4": positionField};


            return (
                <SelectedRowInfoBox
                    key={s.gene + "_"+ i}
                    fields={fields}
                    comments={s.comments}
                    selected={selected}
                    dataType={"variant"}
                    handleOnTextChange={(event) => handleTextChange(i, event.target.value)}
                />
            );
        });
    }

    return (<>
        <ReportTypography text={"These are the interpretation comments that were written in each variant tag."}/>
        <ReportTypography text={"The original tag comment will not be modified here. Your modifications will only be visible in the exported report."}/>
        <Box p={1}>
            {renderInfoBoxes()}
        </Box>
        <Box p={1}>
            <Typography variant={"h6"}><MappedText text="Conclusion"/></Typography>
            {renderTextField("", "", handleInterpretationChange, <MappedText text="conclusions" /> )}
        </Box>
        {
            (analysisType === "germline")
                ? <Box p={1}>
                    <Typography variant={"h6"}><MappedText text="Recomendations"/></Typography>
                    {renderTextField("", "", handleInterpretationChange, "recommendations" )}
                </Box>
                : null

        }


{/*
        default value empty
*/}

        </>)

}



export default Interpretation;