

import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config_cases_playground from "./config_cases_playground";
import {Container} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import "./playground.css";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 400,
    },
}));

function Cases(props){

    const classes = useStyles();



    const renderSolutions = ()=> {


        return config_cases_playground.map(function(item, index) {

            return <Box p={1}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={"panel" + index}
                        id={"panel" + index + "a-header"}>
                        <Typography className={classes.heading}>{item.experiment_id}  - PhenoStore ID: <a target="_blank" href={window.config.apis_configuration.url_phenostore + "/#/summary/" + item.participant_id}>{item.participant_id}</a></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{display: "inline-block"}}>
                        <Box p={2}>
                            <div style={{float: "left", width: "100%"}}>
                                <Typography>  {(item.disease !== "") ? "Diagnosis: " + item.disease : "No Diagnosis Reported"}</Typography>
                                <Typography>  {(item.inheritance !== "") ? "Inheritance: " + item.inheritance : "NA"}</Typography>
                                <Divider/>
                            </div>
                        </Box>

                        <Box p={2}>
                            <div className={"c_variants"}>


                            <Typography variant={"h6"}>
                               Causative Variants
                            </Typography>
                            <div style={{float: "left", width: "100%"}}>
                            {( item.variant.map(s => <React.Fragment>
                                <Box>
                                    <Typography variant={"body1"}>
                                        Chr. Coordinate: {s.chr_coordinate}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        cDNA: {s.cDNA}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        Protein: {s.protein}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        Gene: {s.gene}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        Transcript: {s.transcript}
                                    </Typography>
                                </Box>

                            </React.Fragment>

                            ))
                            }
                            </div>
                        </div>
                        </Box>
                        <Box p={2}>
                            <Typography variant={"body1"}>
                             GPAP Genomics Query : <a href={item.link_to_gpap} target={"_blank"}>{item.link_to_gpap}</a>
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>


        })

    }


    return <Container>
        <Box p={3}>
            <Typography variant={"h6"}>
                Solutions to Playground Cases
            </Typography>
            <Typography variant={"body1"}>
              The following are the solutions to the playground cases. You can find the causative mutations in the list.
            </Typography>
            <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item={8}>


                    <div >
                        <Box p={1}>
                            {renderSolutions()}
                        </Box>

                    </div>

                </Grid>

            </Grid>

        </Box>



    </Container>




}


export default Cases;