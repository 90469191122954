


// process variant data to generate the chromose graph;

function processVariantData(obj){

    return new Array(23).fill().map((e, i) => {
        let variant_by_chrom = [];
        if (obj.variants !== undefined) {
            let n = i + 1;

            variant_by_chrom = obj.variants.filter(variant => variant.chrom === n.toString() || variant.chrom === n )
        }
        return {d: i+1, key: 'chrom', value: variant_by_chrom.length}
    });

}


export {processVariantData}