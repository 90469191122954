import React from "react";
import {Typography} from "@mui/material";
import MappedText from '../../../create-report/shared-components/mapText.js';
import Grid from "@mui/material/Grid";
import DataField from "../../../create-report/shared-components/Datafield.js";


function GeneralInfoSection({   dirSelected, allDataApis, analysisType }){

    return   <>
        {/*<Typography variant="h5" style={{ fontWeight: "700", marginBottom: '20px' }}>
            <MappedText text="General Info"/>
        </Typography>*/}

        <Grid container spacing={2} style={{ marginBottom: '30px' }}>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Issuing centre" value={dirSelected.issuing_center || "NA"} />
              
            </Grid>
            <Grid item  xs={12} sm={6} md={6}>
                <DataField title="Requesting centre" value={dirSelected.hospital_name || "NA"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Referrer" value={dirSelected.clinical_referrer || "NA"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Reporting date" value={dirSelected.creation_date || "NA"} /> 
            </Grid>
        </Grid>

        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
            <MappedText text={"Patient Info"}/>
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Name" value={allDataApis?.participants?.[0].first_name || "NA"} /> 
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Surname" value={allDataApis?.participants?.[0].last_name || "NA"} /> 
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Patient ID" value={allDataApis?.participants?.[0].report_id || "NA"} /> 
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Sex" value={allDataApis?.participants?.[0].sex || "NA"} /> 
            </Grid>
        </Grid>

        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
            <MappedText text={"Clinical Info"}/>
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
        {analysisType !=="germline" && (
                    <>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Sample Type" value={allDataApis?.samples?.sample_type || "NA"}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Biopsy Location" value={allDataApis?.samples?.sample_tissue_of_origin || "NA"}/>
            </Grid>
            </>
          )
            }
        </Grid>
          
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>

            <DataField title="Reason for referral" value={allDataApis?.participants?.[0]?.clinical_referral || "NA"}/>

            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>

            <DataField title="Study performed" value={ dirSelected.study_performed || "NA"}/>

            
            </Grid> 
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>

            <DataField title="Library strategy" value={allDataApis?.experiments?.[0].library_strategy || "NA"}/>

             
            </Grid>
        </Grid>

    </>

}


export default GeneralInfoSection;