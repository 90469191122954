import tableSlice from "./slices/tables/table.slice";
import { combineReducers } from "redux";
import { authorization } from "./authorization";
import { queryListReducer, variants} from "../scenes/dashboard/reducers/variants_reducer";
import { selectedVariant } from "../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/reducer";
import { reducer as formReducer } from 'redux-form';
import {
    currentFilterSetReducer,
    currentQueryReducer,
    queryReducer, sa_queryReducer, select_cohort
} from "./query_reducer";
import {geneListReducer} from "../scenes/dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/gene_reducer";
import {patientsReducer} from "../scenes/study-page/create-study/analysis-page/components/clinical-info/patients_reducer";
import {inheritance} from "../scenes/study-page/create-study/analysis-page/reducers/inheritance_reducer";
import {sampleReducer} from "../scenes/study-page/create-study/analysis-page/reducers/sample_reducer";
import {study_bucket, study_settings} from "../scenes/dashboard/reducers/study_reducer";
import {sidebar_settings} from "../components/SideNavigation/sidebar_reducer";
import {roh_reducer} from "../scenes/dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/RadioGroupForm/roh_reducer";
import {positionReducer} from "../scenes/dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/position_reducer";
import {
    setSelectedTableRows
} from "../scenes/dashboard/components/genomics-dashboard/components/results/components/variant-table/table_row_reducer";


const allReducer = combineReducers({

    'generaltable': tableSlice,
    'authorization': authorization,
    'variants': variants,
    'form': formReducer,
    "roh_value": roh_reducer,
    'patients': patientsReducer,
    'query': queryReducer,
    'currentQuery': currentQueryReducer,
    'currentFilterSet': currentFilterSetReducer,
    'sa_query': sa_queryReducer,
    'geneList': geneListReducer,
    'queryContainer': queryListReducer,
    'selected_variant': selectedVariant,
    "selected_table_rows": setSelectedTableRows,
    'selected_cohort': select_cohort,
    "inheritance_key": inheritance,
    "sample_list": sampleReducer,
    "studyBucket": study_bucket,
    "studySettings": study_settings,
    "sidebarSettings": sidebar_settings,
    "position_uploads": positionReducer
});


export default allReducer;