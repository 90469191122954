import {getGenesEnsembl} from "../../../../../../../../../../../../services/api/get";

import React, {Component} from "react";
import * as Actions from "../../../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {mapGeneOperatorToProps} from "../../functions/mapStateToProps";
import Box from "@material-ui/core/Box";
import GeneralAutoComplete from "../GeneralAutoComplete";
import Typography from "@material-ui/core/Typography";
import {handleResponse} from "../async-selection/handlers";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";




class SingleGeneSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_genes: [],
            results: [],
            default:[]
        };

        this.passData = this.passData.bind(this);
        this.getResults = this.getResults.bind(this);
        this.passSelected = this.passSelected.bind(this);

    }


    componentDidMount() {

        if(this.props.genes!== undefined){

            this.setState({
                default:this.props.genes.map(s => {return {value: s, label: s}})
            })


        }

    }


    passData(obj){

        let { actions, operator } = this.props;

        if(obj.length!== 0 && obj.length > this.state.selected_genes.length){
            let genes = obj.map(function(geneItem){return geneItem.label});
            if(operator=== "intersection"){
                actions.intersect_genelist(genes, "genes");
            }
            else{
                actions.add_genes_to_specific_genelist(genes, "genes");
            }
            this.setState({selected_genes: genes, default: obj})
        }
        else if(obj.length < this.state.selected_genes.length){
            // remove genes

            let genes = obj.map(function(geneItem){return geneItem.label});
            let to_remove = this.state.selected_genes.filter(s => !genes.includes(s));
            actions.remove_genes_from_list(to_remove, "genes");
            this.setState({selected_genes: genes, default: obj})


        }
        else{
            actions.remove_genes_from_list(this.state.selected_genes, "genes");
            this.setState({selected_genes: [], default: []})
        }

    }


    getResults(val){

        const self = this;
        
        if(val !== ""){
            getGenesEnsembl(val)
                .then(r => r.json())
                .then(function(json){

                let resp = handleResponse("genes", json);
                self.setState({results: resp})
            })
        }

    }


    passSelected(values){

        if(values!== undefined){
            this.passData(values);
        }


    }


    render() {

        return <Box m={2}>
            <Typography variant={"body1"}><MappedText text={"Gene Search"}/></Typography>
            <GeneralAutoComplete
                results = {this.state.results}
                onInputChange = {this.getResults}
                title=<MappedText text={"search for a gene name"}/>
                passSelected = {this.passSelected}
                default_tags = {this.state.default}
            />
        </Box>


    }
}




const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapGeneOperatorToProps,mapDispatchToProps)(SingleGeneSearch);



