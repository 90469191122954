import React from 'react';
import {NavLink} from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";


function HomeInstand(){

        return(
               <>
                   <Paper>        
                       <Grid
                           container
                           spacing={2}
                           direction="column"
                           justifyContent="flex-start"
                           alignItems="center">
                           <Grid item>
                               <Box p={2}>
                                   <Typography variant={"h4"} align={"center"}> Welcome to the Instand reporting module</Typography>
                                   <Typography variant={"h6"} align={"center"}> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
                               </Box>
                           </Grid>
                          
                           <Grid item>
                               <Box p={1}>
                                   <Button variant="contained" color="primary"><NavLink exact to="/dir_list" style={{textDecoration:"none", color:"white"}}> Access Instand</NavLink></Button>
                               </Box>
                           </Grid>


                           <Grid item lg={7}>
                                   <Box p={2}>
                                       <Typography variant={"h6"}> Help us improve, leave your feedback</Typography>
                                       <Box p={1}>
                                           <Typography variant={"body1"}>
                                               You can let us know about your experience with the Instand. You can fill in  <a href={" https://forms.gle/dAUJxUcWhrs8Cz9z5"}>this simple form</a>, it will take not more than 5 minutes.
                                               This will help us to improve this new application and facilitate your reports in the future. Thanks!
                                               You can always contact our team at <a href="mailto:help@rd-connect.eu">here</a>.
                                               <br/>
                                           </Typography>

                                       </Box>
                                   </Box>
                           </Grid>
                          

                       </Grid>
        
                   </Paper>
               </>
        )

}



export default HomeInstand;