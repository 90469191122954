import React from "react";

import { Grid, Paper } from "@mui/material";

export default function InstandGeneralLayout(props){

    return(
    <div className="general-layout" style={{ backgroundColor: '#F2F2F2' , width: '100%', height: '100%', display: "flex",justifyContent: "center",}}>
      
        
      <Paper elevation={0}  style={{ width: '100%', height: '100%', backgroundColor: 'white', margin:'50px', borderRadius:'4px', padding:'30px' }}>
        <Grid  >
          <Grid item xs={12} >
          {props.component}
          </Grid>
        </Grid>
      </Paper> 
    </div>
    )
}