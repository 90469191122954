import React, {useEffect, useState} from "react";


import External_Databases from "../bottom-panels/components/External_Databases";


import "./bottom-tab-views.css";

import {connect} from "react-redux";


function DatabaseDataRequests(props) {


    const [variants, setVariants] = useState([]);
    const [ext_data, setObject] = useState({
        biosoftware: false,
        wiki: false,
        pathways: false,
        quickGo: false,
        hmtdb: false,
        edgar: false,
        diseaseCard: false,
        ebi_annotation: false,
        ebi_quickGo: false,
        hpscreg: false
    });



    const setup_parameters = () => {

        const L = props.selected_variant.length;
        let variant = props.selected_variant[L - 1];

        let {gene_name} = variant;

        // required info

        let obj = {
            biosoftware: false,
            wiki: gene_name,
            pathways: gene_name,
            quickGo: gene_name,
            diseaseCard: gene_name,
            ebi_annotation: false,
            hpscreg : gene_name,
        };

        setObject(obj);
        setVariants(props.selected_variant)

    };

    useEffect(function(){

            // get info...
            if( props.selected_variant.length>0 && variants.length!== props.selected_variant.length ){

                setup_parameters();

            }
            else if(Array.isArray(props.selected_variant) && Array.isArray(variants) ){
                if(props.selected_variant.length!== 0 && variants.length!== 0 ){
                    setup_parameters();
                }

            }
            else if(props.selected_variant.length === 0 && variants.length!== props.selected_variant.length){
                setVariants([])
            }


    }, [props.selected_variant]);



    return (
        <div className={'bottom_tabviews'}>
            <External_Databases
                data = {ext_data}
            />
        </div>
        );


}




function mapStateToProps (state) {
    if(state.variants!==undefined)
    {
        return {
            token: state.authorization.token,
            selected_variant: state.selected_variant,
            patients: state.patients}
    }
    else{
        return {snv: []}
    }
}



export default connect(mapStateToProps,null)(DatabaseDataRequests);




