


import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField/TextField";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../../../reducers/study_actions";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QueryChips from "./QueryChips";


function QueryNameEdit(props){

    const [edit, setEditing] = useState(false);
    const [queryName, setQueryName] = useState(props.queryName);
    const [savedName, setSavedName] = useState(props.queryName);

    const {variants_n} = props;


    useEffect(() => {
        // side effect here on change of any of props.x or stateY

        // you saved and you were editing
        if(props.queryName!== savedName && edit){
            setQueryName(queryName);
            setEditing(false);
        }
        // you load a new query
        else if(props.queryName!== savedName && !edit){
            setQueryName(props.queryName);
            setSavedName(props.queryName);
        }
       /* else if(props.queryName === savedName && !edit){
            setEditing(false);
        }*/

    }, [props.queryName, queryName, savedName]);


    const saveName = () =>{

        const {studyActions, studySettings} = props;
        setSavedName(queryName);

        studyActions.change_query_name(
            {
                query_id: studySettings.current_query,
                analysis_id: studySettings.current_analysis,
                study_id: studySettings.id,
                name: queryName
            }
        )
        setEditing(false);
    };


    const onChange =(evt) => {
        setQueryName(evt.target.value);
    };

    const fixed = () => {

        return <div align={"center"} className={"queryBox"}>
           {/* <IconButton style={{opacity:"0", padding: "8px"}}><CheckCircleIcon/></IconButton>*/}
            <strong>Query: </strong> {queryName}
            <IconButton style={{padding: "8px"}} onClick={() => setEditing(true)}><EditIcon/></IconButton>
        </div>

    };


    const editing = () => {

        return <React.Fragment>
            <TextField
                size={"small"}
                InputProps={{
                    style: {
                        color:"#4575B4",
                        fontSize: "15px"
                    },
                }}
                onKeyUp={(event) => {
                    if (event.key=== 'Enter')
                        saveName()
                }}
                value ={queryName}
                onChange={onChange}
                id="queryName" label={"Query Name"}/>
            <IconButton onClick={() => saveName()}><CheckCircleIcon/></IconButton>

        </React.Fragment>

    };


    return  <React.Fragment>
                {(edit) ? editing() : fixed()}
                   <QueryChips queryNumbers={variants_n}/>

    </React.Fragment>


}


function mapStateToProps (state) {

    if(state.authorization!==undefined)
    {
        return { studySettings: state.studySettings, studyBucket: state.studyBucket}
    }

}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(QueryNameEdit);