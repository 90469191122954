const civicGeneData= [
    {"civic_number": 1,"gene_name": "ALK"},
    {"civic_number": 2,"gene_name": "AKT1"},
    {"civic_number": 3,"gene_name": "ARAF"},
    {"civic_number": 4,"gene_name": "ABL1"},
    {"civic_number": 5,"gene_name": "BRAF"},
    {"civic_number": 6,"gene_name": "BRCA1"},
    {"civic_number": 7,"gene_name": "BRCA2"},
    {"civic_number": 8,"gene_name": "CCND1"},
    {"civic_number": 9,"gene_name": "CCND2"},
    {"civic_number": 10,"gene_name": "CCND3"},
    {"civic_number": 11,"gene_name": "CCNE1"},
    {"civic_number": 12,"gene_name": "CDK6"},
    {"civic_number": 13,"gene_name": "CDK4"},
    {"civic_number": 14,"gene_name": "CDKN2A"},
    {"civic_number": 15,"gene_name": "CEBPA"},
    {"civic_number": 17,"gene_name": "PRKACA"},
    {"civic_number": 18,"gene_name": "DNMT3A"},
    {"civic_number": 19,"gene_name": "EGFR"},
    {"civic_number": 20,"gene_name": "ERBB2"},
    {"civic_number": 21,"gene_name": "ESR1"},
    {"civic_number": 22,"gene_name": "FGFR2"},
    {"civic_number": 23,"gene_name": "FGFR3"},
    {"civic_number": 24,"gene_name": "FLT3"},
    {"civic_number": 25,"gene_name": "GATA2"},
    {"civic_number": 26,"gene_name": "IDH1"},
    {"civic_number": 27,"gene_name": "IDH2"},
    {"civic_number": 28,"gene_name": "JAK2"},
    {"civic_number": 29,"gene_name": "KIT"},
    {"civic_number": 30,"gene_name": "KRAS"},
    {"civic_number": 31,"gene_name": "MAP2K1"},
    {"civic_number": 32,"gene_name": "MEF2D"},
    {"civic_number": 33,"gene_name": "CSF1R"},
    {"civic_number": 34,"gene_name": "MGMT"},
    {"civic_number": 35,"gene_name": "NPM1"},
    {"civic_number": 36,"gene_name": "NRAS"},
    {"civic_number": 37,"gene_name": "PIK3CA"},
    {"civic_number": 38,"gene_name": "PDGFRA"},
    {"civic_number": 39,"gene_name": "PML"},
    {"civic_number": 41,"gene_name": "PTEN"},
    {"civic_number": 42,"gene_name": "RET"},
    {"civic_number": 43,"gene_name": "RUNX1"},
    {"civic_number": 44,"gene_name": "SF3B1"},
    {"civic_number": 45,"gene_name": "TP53"},
    {"civic_number": 46,"gene_name": "TSC1"},
    {"civic_number": 47,"gene_name": "TSC2"},
    {"civic_number": 48,"gene_name": "U2AF1"},
    {"civic_number": 49,"gene_name": "WT1"},
    {"civic_number": 50,"gene_name": "NOTCH1"},
    {"civic_number": 51,"gene_name": "DDR2"},
    {"civic_number": 52,"gene_name": "MET"},
    {"civic_number": 53,"gene_name": "FLI1"},
    {"civic_number": 54,"gene_name": "EWSR1"},
    {"civic_number": 55,"gene_name": "TET2"},
    {"civic_number": 56,"gene_name": "FOXP1"},
    {"civic_number": 57,"gene_name": "REL"},
    {"civic_number": 58,"gene_name": "VHL"},
    {"civic_number": 59,"gene_name": "BCL2"},
    {"civic_number": 60,"gene_name": "IGF2"},
    {"civic_number": 61,"gene_name": "AURKA"},
    {"civic_number": 62,"gene_name": "PBRM1"},
    {"civic_number": 63,"gene_name": "EZH2"},
    {"civic_number": 64,"gene_name": "KMT2D"},
    {"civic_number": 65,"gene_name": "BTK"},
    {"civic_number": 66,"gene_name": "APC"},
    {"civic_number": 67,"gene_name": "AR"},
    {"civic_number": 68,"gene_name": "ASXL1"},
    {"civic_number": 69,"gene_name": "ATM"},
    {"civic_number": 70,"gene_name": "BAP1"},
    {"civic_number": 71,"gene_name": "ERG"},
    {"civic_number": 72,"gene_name": "FOXL2"},
    {"civic_number": 73,"gene_name": "IKZF1"},
    {"civic_number": 74,"gene_name": "NCOA3"},
    {"civic_number": 75,"gene_name": "PAX8"},
    {"civic_number": 76,"gene_name": "PGR"},
    {"civic_number": 77,"gene_name": "SMAD4"},
    {"civic_number": 78,"gene_name": "SMARCA4"},
    {"civic_number": 79,"gene_name": "TERT"},
    {"civic_number": 80,"gene_name": "TTF1"},
    {"civic_number": 101,"gene_name": "ABL2"},
    {"civic_number": 128,"gene_name": "ACTA1"},
    {"civic_number": 150,"gene_name": "ACTL6A"},
    {"civic_number": 154,"gene_name": "ACVR1"},
    {"civic_number": 199,"gene_name": "PARP1"},
    {"civic_number": 221,"gene_name": "AEBP1"},
    {"civic_number": 254,"gene_name": "AKT2"},
    {"civic_number": 259,"gene_name": "ALCAM"},
    {"civic_number": 353,"gene_name": "BIRC3"},
    {"civic_number": 355,"gene_name": "BIRC5"},
    {"civic_number": 389,"gene_name": "AREG"},
    {"civic_number": 399,"gene_name": "RHOA"},
    {"civic_number": 436,"gene_name": "ASCL1"},
    {"civic_number": 446,"gene_name": "ASNS"},
    {"civic_number": 450,"gene_name": "ASS1"},
    {"civic_number": 524,"gene_name": "ATR"},
    {"civic_number": 525,"gene_name": "ATRX"},
    {"civic_number": 533,"gene_name": "AXL"},
    {"civic_number": 537,"gene_name": "B2M"},
    {"civic_number": 549,"gene_name": "BARD1"},
    {"civic_number": 550,"gene_name": "BAX"},
    {"civic_number": 566,"gene_name": "BCL6"},
    {"civic_number": 570,"gene_name": "TNFRSF17"},
    {"civic_number": 588,"gene_name": "CEACAM1"},
    {"civic_number": 593,"gene_name": "PRDM1"},
    {"civic_number": 594,"gene_name": "BLK"},
    {"civic_number": 634,"gene_name": "KLF5"},
    {"civic_number": 732,"gene_name": "CALR"},
    {"civic_number": 761,"gene_name": "CASP8"},
    {"civic_number": 774,"gene_name": "CBFA2T3"},
    {"civic_number": 776,"gene_name": "CBFB"},
    {"civic_number": 778,"gene_name": "CBL"},
    {"civic_number": 779,"gene_name": "CBLB"},
    {"civic_number": 826,"gene_name": "CD19"},
    {"civic_number": 827,"gene_name": "MS4A1"},
    {"civic_number": 855,"gene_name": "CD44"},
    {"civic_number": 868,"gene_name": "CD79B"},
    {"civic_number": 888,"gene_name": "CDH1"},
    {"civic_number": 906,"gene_name": "CDK2"},
    {"civic_number": 908,"gene_name": "CDK5"},
    {"civic_number": 912,"gene_name": "CDK9"},
    {"civic_number": 913,"gene_name": "CDKN1A"},
    {"civic_number": 914,"gene_name": "CDKN1B"},
    {"civic_number": 916,"gene_name": "CDKN2B"},
    {"civic_number": 927,"gene_name": "CDX2"},
    {"civic_number": 981,"gene_name": "CHEK1"},
    {"civic_number": 1142,"gene_name": "MAP3K8"},
    {"civic_number": 1193,"gene_name": "CREBBP"},
    {"civic_number": 1204,"gene_name": "CRKL"},
    {"civic_number": 1234,"gene_name": "CSF1"},
    {"civic_number": 1239,"gene_name": "CSF3R"},
    {"civic_number": 1278,"gene_name": "CTAG1B"},
    {"civic_number": 1283,"gene_name": "CCN2"},
    {"civic_number": 1285,"gene_name": "CTLA4"},
    {"civic_number": 1290,"gene_name": "CTNNB1"},
    {"civic_number": 1342,"gene_name": "CYP2D6"},
    {"civic_number": 1390,"gene_name": "DBI"},
    {"civic_number": 1396,"gene_name": "DCC"},
    {"civic_number": 1411,"gene_name": "GADD45A"},
    {"civic_number": 1413,"gene_name": "DDIT3"},
    {"civic_number": 1428,"gene_name": "DEFA1"},
    {"civic_number": 1463,"gene_name": "NQO1"},
    {"civic_number": 1510,"gene_name": "DNMT1"},
    {"civic_number": 1526,"gene_name": "DPYD"},
    {"civic_number": 1536,"gene_name": "DRD5"},
    {"civic_number": 1567,"gene_name": "DUSP6"},
    {"civic_number": 1627,"gene_name": "EFNA2"},
    {"civic_number": 1634,"gene_name": "EGF"},
    {"civic_number": 1646,"gene_name": "EIF1AX"},
    {"civic_number": 1655,"gene_name": "EIF4EBP1"},
    {"civic_number": 1701,"gene_name": "SLC29A1"},
    {"civic_number": 1704,"gene_name": "EP300"},
    {"civic_number": 1705,"gene_name": "EPAS1"},
    {"civic_number": 1719,"gene_name": "EPHB2"},
    {"civic_number": 1721,"gene_name": "EPHB4"},
    {"civic_number": 1728,"gene_name": "EPOR"},
    {"civic_number": 1733,"gene_name": "ERBB3"},
    {"civic_number": 1734,"gene_name": "ERBB4"},
    {"civic_number": 1735,"gene_name": "ERCC1"},
    {"civic_number": 1736,"gene_name": "ERCC2"},
    {"civic_number": 1737,"gene_name": "EREG"},
    {"civic_number": 1741,"gene_name": "ERCC5"},
    {"civic_number": 1753,"gene_name": "ESR2"},
    {"civic_number": 1763,"gene_name": "ETS2"},
    {"civic_number": 1764,"gene_name": "ETV1"},
    {"civic_number": 1767,"gene_name": "ETV4"},
    {"civic_number": 1768,"gene_name": "ETV5"},
    {"civic_number": 1769,"gene_name": "ETV6"},
    {"civic_number": 1771,"gene_name": "MECOM"},
    {"civic_number": 1810,"gene_name": "FANCA"},
    {"civic_number": 1811,"gene_name": "FANCC"},
    {"civic_number": 1818,"gene_name": "PTK2B"},
    {"civic_number": 1842,"gene_name": "FCGR2A"},
    {"civic_number": 1843,"gene_name": "FCGR2B"},
    {"civic_number": 1844,"gene_name": "FCGR3A"},
    {"civic_number": 1872,"gene_name": "FGF2"},
    {"civic_number": 1873,"gene_name": "FGF3"},
    {"civic_number": 1883,"gene_name": "FGF13"},
    {"civic_number": 1885,"gene_name": "FGFR1"},
    {"civic_number": 1887,"gene_name": "FGFR4"},
    {"civic_number": 1911,"gene_name": "FOXF1"},
    {"civic_number": 1920,"gene_name": "FOXC2"},
    {"civic_number": 1925,"gene_name": "FOXO1"},
    {"civic_number": 1926,"gene_name": "FOXO3"},
    {"civic_number": 1937,"gene_name": "FLT3LG"},
    {"civic_number": 1938,"gene_name": "FLT4"},
    {"civic_number": 1947,"gene_name": "FN1"},
    {"civic_number": 1950,"gene_name": "FNTB"},
    {"civic_number": 1955,"gene_name": "FOS"},
    {"civic_number": 2073,"gene_name": "MTOR"},
    {"civic_number": 2186,"gene_name": "GAS6"},
    {"civic_number": 2188,"gene_name": "GATA1"},
    {"civic_number": 2189,"gene_name": "GATA3"},
    {"civic_number": 2239,"gene_name": "B4GALT1"},
    {"civic_number": 2279,"gene_name": "GLI1"},
    {"civic_number": 2308,"gene_name": "GNA11"},
    {"civic_number": 2317,"gene_name": "GNAQ"},
    {"civic_number": 2319,"gene_name": "GNAS"},
    {"civic_number": 2438,"gene_name": "ARHGAP35"},
    {"civic_number": 2473,"gene_name": "GSTP1"},
    {"civic_number": 2478,"gene_name": "MSH6"},
    {"civic_number": 2537,"gene_name": "H3-3A"},
    {"civic_number": 2538,"gene_name": "H3-3B"},
    {"civic_number": 2556,"gene_name": "HBB"},
    {"civic_number": 2578,"gene_name": "HDAC2"},
    {"civic_number": 2591,"gene_name": "HGF"},
    {"civic_number": 2593,"gene_name": "NRG1"},
    {"civic_number": 2596,"gene_name": "HIF1A"},
    {"civic_number": 2608,"gene_name": "HLA-C"},
    {"civic_number": 2622,"gene_name": "HLA-DRA"},
    {"civic_number": 2657,"gene_name": "HMOX1"},
    {"civic_number": 2715,"gene_name": "HOXC10"},
    {"civic_number": 2723,"gene_name": "HOXD8"},
    {"civic_number": 2747,"gene_name": "HRAS"},
    {"civic_number": 2778,"gene_name": "HSPA5"},
    {"civic_number": 2784,"gene_name": "HSPB1"},
    {"civic_number": 2822,"gene_name": "TNC"},
    {"civic_number": 2899,"gene_name": "IGF1R"},
    {"civic_number": 2962,"gene_name": "IL2RB"},
    {"civic_number": 2970,"gene_name": "IL6"},
    {"civic_number": 2975,"gene_name": "IL7R"},
    {"civic_number": 3017,"gene_name": "CXCL10"},
    {"civic_number": 3090,"gene_name": "JAK1"},
    {"civic_number": 3091,"gene_name": "JAK3"},
    {"civic_number": 3094,"gene_name": "JUN"},
    {"civic_number": 3153,"gene_name": "KDR"},
    {"civic_number": 3215,"gene_name": "KRT18"},
    {"civic_number": 3236,"gene_name": "LAG3"},
    {"civic_number": 3255,"gene_name": "STMN1"},
    {"civic_number": 3274,"gene_name": "LEPR"},
    {"civic_number": 3359,"gene_name": "LYN"},
    {"civic_number": 3362,"gene_name": "TACSTD2"},
    {"civic_number": 3364,"gene_name": "EPCAM"},
    {"civic_number": 3375,"gene_name": "SMAD2"},
    {"civic_number": 3376,"gene_name": "SMAD3"},
    {"civic_number": 3465,"gene_name": "MDM2"},
    {"civic_number": 3466,"gene_name": "MDM4"},
    {"civic_number": 3485,"gene_name": "MEN1"},
    {"civic_number": 3527,"gene_name": "MITF"},
    {"civic_number": 3529,"gene_name": "MKI67"},
    {"civic_number": 3532,"gene_name": "MLH1"},
    {"civic_number": 3537,"gene_name": "KMT2A"},
    {"civic_number": 3549,"gene_name": "MMP2"},
    {"civic_number": 3553,"gene_name": "MMP9"},
    {"civic_number": 3564,"gene_name": "MN1"},
    {"civic_number": 3577,"gene_name": "MPL"},
    {"civic_number": 3586,"gene_name": "MRE11"},
    {"civic_number": 3628,"gene_name": "MSH2"},
    {"civic_number": 3629,"gene_name": "MSH3"},
    {"civic_number": 3659,"gene_name": "MTAP"},
    {"civic_number": 3672,"gene_name": "MTHFR"},
    {"civic_number": 3730,"gene_name": "MYB"},
    {"civic_number": 3737,"gene_name": "MYC"},
    {"civic_number": 3738,"gene_name": "MYCL"},
    {"civic_number": 3741,"gene_name": "MYCN"},
    {"civic_number": 3742,"gene_name": "MYD88"},
    {"civic_number": 3777,"gene_name": "MYOD1"},
    {"civic_number": 3801,"gene_name": "NBN"},
    {"civic_number": 3852,"gene_name": "NEDD9"},
    {"civic_number": 3867,"gene_name": "NF1"},
    {"civic_number": 3870,"gene_name": "NF2"},
    {"civic_number": 3878,"gene_name": "NFE2L2"},
    {"civic_number": 3936,"gene_name": "NOTCH3"},
    {"civic_number": 3976,"gene_name": "NT5E"},
    {"civic_number": 3983,"gene_name": "NTRK1"},
    {"civic_number": 3984,"gene_name": "NTRK2"},
    {"civic_number": 3985,"gene_name": "NTRK3"},
    {"civic_number": 3995,"gene_name": "NUP98"},
    {"civic_number": 4103,"gene_name": "PRKN"},
    {"civic_number": 4111,"gene_name": "PAX5"},
    {"civic_number": 4137,"gene_name": "PCNA"},
    {"civic_number": 4173,"gene_name": "PDGFB"},
    {"civic_number": 4176,"gene_name": "PDGFRB"},
    {"civic_number": 4244,"gene_name": "ABCB1"},
    {"civic_number": 4285,"gene_name": "PIK3CB"},
    {"civic_number": 4286,"gene_name": "PIM1"},
    {"civic_number": 4289,"gene_name": "PIK3R1"},
    {"civic_number": 4290,"gene_name": "PIK3R2"},
    {"civic_number": 4327,"gene_name": "PLCG2"},
    {"civic_number": 4337,"gene_name": "PLK1"},
    {"civic_number": 4371,"gene_name": "PMS2"},
    {"civic_number": 4384,"gene_name": "POLD1"},
    {"civic_number": 4386,"gene_name": "POLE"},
    {"civic_number": 4419,"gene_name": "POU5F1"},
    {"civic_number": 4463,"gene_name": "PPP2R1A"},
    {"civic_number": 4465,"gene_name": "PPP2R2A"},
    {"civic_number": 4503,"gene_name": "PRKAA2"},
    {"civic_number": 4511,"gene_name": "PRKAR1A"},
    {"civic_number": 4517,"gene_name": "PRKCB"},
    {"civic_number": 4532,"gene_name": "MAPK1"},
    {"civic_number": 4542,"gene_name": "MAP2K2"},
    {"civic_number": 4546,"gene_name": "MAP2K7"},
    {"civic_number": 4566,"gene_name": "PRPS1"},
    {"civic_number": 4616,"gene_name": "PSMB8"},
    {"civic_number": 4630,"gene_name": "PSMD4"},
    {"civic_number": 4645,"gene_name": "PTCH1"},
    {"civic_number": 4659,"gene_name": "PTGS2"},
    {"civic_number": 4682,"gene_name": "PTPN6"},
    {"civic_number": 4685,"gene_name": "PTPN11"},
    {"civic_number": 4686,"gene_name": "PTPN12"},
    {"civic_number": 4690,"gene_name": "PTPRB"},
    {"civic_number": 4692,"gene_name": "PTPRD"},
    {"civic_number": 4695,"gene_name": "PTPRF"},
    {"civic_number": 4753,"gene_name": "RAC1"},
    {"civic_number": 4760,"gene_name": "RAD23B"},
    {"civic_number": 4762,"gene_name": "RAD51C"},
    {"civic_number": 4763,"gene_name": "RAD51B"},
    {"civic_number": 4765,"gene_name": "RAD51D"},
    {"civic_number": 4767,"gene_name": "RAF1"},
    {"civic_number": 4791,"gene_name": "RASA1"},
    {"civic_number": 4793,"gene_name": "RASGRF1"},
    {"civic_number": 4795,"gene_name": "RB1"},
    {"civic_number": 4868,"gene_name": "RHEB"},
    {"civic_number": 4875,"gene_name": "RIT1"},
    {"civic_number": 4941,"gene_name": "ROS1"},
    {"civic_number": 5013,"gene_name": "RPS6"},
    {"civic_number": 5051,"gene_name": "RRM1"},
    {"civic_number": 5052,"gene_name": "RRM2"},
    {"civic_number": 5112,"gene_name": "ATXN7"},
    {"civic_number": 5128,"gene_name": "SCN8A"},
    {"civic_number": 5167,"gene_name": "CX3CL1"},
    {"civic_number": 5176,"gene_name": "SDHA"},
    {"civic_number": 5177,"gene_name": "SDHB"},
    {"civic_number": 5210,"gene_name": "SRSF2"},
    {"civic_number": 5225,"gene_name": "SGK1"},
    {"civic_number": 5354,"gene_name": "SMARCA2"},
    {"civic_number": 5356,"gene_name": "SMARCB1"},
    {"civic_number": 5365,"gene_name": "SMO"},
    {"civic_number": 5409,"gene_name": "SOS1"},
    {"civic_number": 5418,"gene_name": "SOX10"},
    {"civic_number": 5499,"gene_name": "SSTR5"},
    {"civic_number": 5500,"gene_name": "SSX1"},
    {"civic_number": 5501,"gene_name": "SSX2"},
    {"civic_number": 5503,"gene_name": "SSX4"},
    {"civic_number": 5514,"gene_name": "STAT1"},
    {"civic_number": 5516,"gene_name": "STAT3"},
    {"civic_number": 5519,"gene_name": "STAT5B"},
    {"civic_number": 5520,"gene_name": "STAT6"},
    {"civic_number": 5525,"gene_name": "SULT1E1"},
    {"civic_number": 5534,"gene_name": "STK11"},
    {"civic_number": 5572,"gene_name": "SYK"},
    {"civic_number": 5581,"gene_name": "TBXT"},
    {"civic_number": 5627,"gene_name": "TBX2"},
    {"civic_number": 5649,"gene_name": "ZEB1"},
    {"civic_number": 5654,"gene_name": "TCF19"},
    {"civic_number": 5717,"gene_name": "TEK"},
    {"civic_number": 5723,"gene_name": "TF"},
    {"civic_number": 5731,"gene_name": "NR2F2"},
    {"civic_number": 5734,"gene_name": "TFE3"},
    {"civic_number": 5737,"gene_name": "TFF3"},
    {"civic_number": 5742,"gene_name": "TGFA"},
    {"civic_number": 5752,"gene_name": "TGFBR3"},
    {"civic_number": 5761,"gene_name": "THBS2"},
    {"civic_number": 5777,"gene_name": "TIMP1"},
    {"civic_number": 5813,"gene_name": "TMPRSS2"},
    {"civic_number": 5845,"gene_name": "TOP1"},
    {"civic_number": 5848,"gene_name": "TOP2A"},
    {"civic_number": 5852,"gene_name": "TP53BP1"},
    {"civic_number": 5870,"gene_name": "TPT1"},
    {"civic_number": 5876,"gene_name": "HSP90B1"},
    {"civic_number": 5939,"gene_name": "TSPYL1"},
    {"civic_number": 5970,"gene_name": "TYK2"},
    {"civic_number": 5971,"gene_name": "TYMS"},
    {"civic_number": 5972,"gene_name": "TYR"},
    {"civic_number": 6023,"gene_name": "UGT1A"},
    {"civic_number": 6054,"gene_name": "KDM6A"},
    {"civic_number": 6071,"gene_name": "VEGFA"},
    {"civic_number": 6073,"gene_name": "VEGFC"},
    {"civic_number": 6104,"gene_name": "WEE1"},
    {"civic_number": 6119,"gene_name": "WNT11"},
    {"civic_number": 6144,"gene_name": "XRCC1"},
    {"civic_number": 6329,"gene_name": "CXCR4"},
    {"civic_number": 6358,"gene_name": "DEK"},
    {"civic_number": 6380,"gene_name": "TFEB"},
    {"civic_number": 6394,"gene_name": "AIMP2"},
    {"civic_number": 6455,"gene_name": "HMGA2"},
    {"civic_number": 6523,"gene_name": "LZTR1"},
    {"civic_number": 6534,"gene_name": "ZRSR2"},
    {"civic_number": 6538,"gene_name": "KDM5C"},
    {"civic_number": 6559,"gene_name": "ARID1A"},
    {"civic_number": 6572,"gene_name": "AXIN2"},
    {"civic_number": 6614,"gene_name": "H3C2"},
    {"civic_number": 6652,"gene_name": "SPOP"},
    {"civic_number": 6676,"gene_name": "RAD54L"},
    {"civic_number": 6772,"gene_name": "LGR5"},
    {"civic_number": 6862,"gene_name": "IRS2"},
    {"civic_number": 6906,"gene_name": "ABCC3"},
    {"civic_number": 7009,"gene_name": "CFLAR"},
    {"civic_number": 7014,"gene_name": "PROM1"},
    {"civic_number": 7024,"gene_name": "ALDH1A2"},
    {"civic_number": 7041,"gene_name": "SPHK1"},
    {"civic_number": 7044,"gene_name": "FUBP1"},
    {"civic_number": 7084,"gene_name": "MBD4"},
    {"civic_number": 7222,"gene_name": "CCNE2"},
    {"civic_number": 7282,"gene_name": "AURKB"},
    {"civic_number": 7297,"gene_name": "PTTG1"},
    {"civic_number": 7356,"gene_name": "KLF4"},
    {"civic_number": 7451,"gene_name": "ABCG2"},
    {"civic_number": 7502,"gene_name": "KIF23"},
    {"civic_number": 7608,"gene_name": "SNCAIP"},
    {"civic_number": 7623,"gene_name": "SH3PXD2A"},
    {"civic_number": 7704,"gene_name": "HDAC9"},
    {"civic_number": 7780,"gene_name": "CUL7"},
    {"civic_number": 7909,"gene_name": "FGF19"},
    {"civic_number": 7936,"gene_name": "AKT3"},
    {"civic_number": 7948,"gene_name": "HDAC6"},
    {"civic_number": 7953,"gene_name": "BCL2L11"},
    {"civic_number": 7954,"gene_name": "SH2B3"},
    {"civic_number": 8032,"gene_name": "RAD50"},
    {"civic_number": 8160,"gene_name": "SPRY2"},
    {"civic_number": 8197,"gene_name": "MAEA"},
    {"civic_number": 8262,"gene_name": "TUBB3"},
    {"civic_number": 8292,"gene_name": "YAP1"},
    {"civic_number": 8331,"gene_name": "MERTK"},
    {"civic_number": 8351,"gene_name": "HOXB13"},
    {"civic_number": 8368,"gene_name": "NCOA2"},
    {"civic_number": 8407,"gene_name": "AGR2"},
    {"civic_number": 8450,"gene_name": "SLCO1B1"},
    {"civic_number": 8508,"gene_name": "CTCF"},
    {"civic_number": 8524,"gene_name": "DLL3"},
    {"civic_number": 8552,"gene_name": "STAG3"},
    {"civic_number": 8553,"gene_name": "STAG2"},
    {"civic_number": 8614,"gene_name": "HSPH1"},
    {"civic_number": 8787,"gene_name": "TLK2"},
    {"civic_number": 8878,"gene_name": "PTPRT"},
    {"civic_number": 8908,"gene_name": "PTP4A3"},
    {"civic_number": 8950,"gene_name": "CHEK2"},
    {"civic_number": 9054,"gene_name": "RRAS2"},
    {"civic_number": 9121,"gene_name": "ZHX2"},
    {"civic_number": 9171,"gene_name": "DKK1"},
    {"civic_number": 9189,"gene_name": "NT5C2"},
    {"civic_number": 9326,"gene_name": "CIC"},
    {"civic_number": 9533,"gene_name": "DICER1"},
    {"civic_number": 9538,"gene_name": "SIRT1"},
    {"civic_number": 9588,"gene_name": "BRD4"},
    {"civic_number": 9705,"gene_name": "CBLC"},
    {"civic_number": 9722,"gene_name": "PPP1R15A"},
    {"civic_number": 9935,"gene_name": "POT1"},
    {"civic_number": 9952,"gene_name": "WWTR1"},
    {"civic_number": 10024,"gene_name": "SETBP1"},
    {"civic_number": 10573,"gene_name": "PDCD4"},
    {"civic_number": 11261,"gene_name": "MAGEH1"},
    {"civic_number": 11314,"gene_name": "DROSHA"},
    {"civic_number": 11322,"gene_name": "TBK1"},
    {"civic_number": 11335,"gene_name": "CD274"},
    {"civic_number": 11466,"gene_name": "TLX3"},
    {"civic_number": 11492,"gene_name": "CTAG2"},
    {"civic_number": 11587,"gene_name": "FOXP3"},
    {"civic_number": 11761,"gene_name": "CRBN"},
    {"civic_number": 11932,"gene_name": "DDX41"},
    {"civic_number": 12074,"gene_name": "SUFU"},
    {"civic_number": 12112,"gene_name": "CDK12"},
    {"civic_number": 12121,"gene_name": "RSF1"},
    {"civic_number": 12146,"gene_name": "LRP1B"},
    {"civic_number": 12271,"gene_name": "ERRFI1"},
    {"civic_number": 12367,"gene_name": "ROBO4"},
    {"civic_number": 12422,"gene_name": "UGT1A1"},
    {"civic_number": 12555,"gene_name": "BCOR"},
    {"civic_number": 12754,"gene_name": "FANCL"},
    {"civic_number": 12881,"gene_name": "NUDT15"},
    {"civic_number": 12903,"gene_name": "FBXW7"},
    {"civic_number": 13005,"gene_name": "DDX43"},
    {"civic_number": 13185,"gene_name": "WDR12"},
    {"civic_number": 13276,"gene_name": "PBK"},
    {"civic_number": 13465,"gene_name": "POLE4"},
    {"civic_number": 13648,"gene_name": "CYSLTR2"},
    {"civic_number": 13948,"gene_name": "CIP2A"},
    {"civic_number": 14089,"gene_name": "KMT2C"},
    {"civic_number": 14160,"gene_name": "BACH2"},
    {"civic_number": 14195,"gene_name": "PAPPA2"},
    {"civic_number": 14284,"gene_name": "PBLD"},
    {"civic_number": 14303,"gene_name": "CRLF2"},
    {"civic_number": 14483,"gene_name": "RBM15"},
    {"civic_number": 14629,"gene_name": "ZFTA"},
    {"civic_number": 14842,"gene_name": "BIRC7"},
    {"civic_number": 14923,"gene_name": "GALNT14"},
    {"civic_number": 15013,"gene_name": "PALB2"},
    {"civic_number": 15344,"gene_name": "PREX2"},
    {"civic_number": 15403,"gene_name": "PDCD1LG2"},
    {"civic_number": 15676,"gene_name": "NECTIN4"},
    {"civic_number": 15955,"gene_name": "BRIP1"},
    {"civic_number": 16180,"gene_name": "CARD11"},
    {"civic_number": 16394,"gene_name": "HAVCR2"},
    {"civic_number": 16656,"gene_name": "ABCC10"},
    {"civic_number": 16802,"gene_name": "IL33"},
    {"civic_number": 16872,"gene_name": "SLFN11"},
    {"civic_number": 16939,"gene_name": "RCSD1"},
    {"civic_number": 17016,"gene_name": "NAPRT"},
    {"civic_number": 17939,"gene_name": "H3C14"},
    {"civic_number": 18353,"gene_name": "VPS37A"},
    {"civic_number": 18364,"gene_name": "PXDNL"},
    {"civic_number": 18410,"gene_name": "BEND2"},
    {"civic_number": 18420,"gene_name": "AMER1"},
    {"civic_number": 19449,"gene_name": "DGKH"},
    {"civic_number": 19738,"gene_name": "ZNF384"},
    {"civic_number": 19838,"gene_name": "ARID2"},
    {"civic_number": 19959,"gene_name": "FLCN"},
    {"civic_number": 20480,"gene_name": "RICTOR"},
    {"civic_number": 21380,"gene_name": "RABL3"},
    {"civic_number": 22231,"gene_name": "ATXN1L"},
    {"civic_number": 24374,"gene_name": "EML6"},
    {"civic_number": 24929,"gene_name": "MIR21"},
    {"civic_number": 24938,"gene_name": "MIR218-1"},
    {"civic_number": 26599,"gene_name": "ECSCR"},
    {"civic_number": 32083,"gene_name": "KLLN"},
    {"civic_number": 34321,"gene_name": "DUX4"},
    {"civic_number": 41522,"gene_name": "PRNCR1"},
    {"civic_number": 56400,"gene_name": "COX2"}
];

const getCivicNumber = (geneName) => {

    const geneData = civicGeneData.find(g => g.gene_name === geneName);
    return geneData ? geneData.civic_number : null;
}
export default getCivicNumber;

