import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import MappedText from '../../../../../create-report/shared-components/mapText.js';
import DataField from '../../../../../create-report/shared-components/Datafield.js';
import { sectionConfigSomatic } from "../config/config_somatic.js";
import { renderTextField } from "../../annexes_components.js";

function stringifyValue(value) {
    
    return value !== null && value !== undefined ? value.toString() : 'NA';
}

function AnnexesSomaticSection({ annexesData, clinicalReportName, setClinicalReportName, ...additionalData  }) {
    const sectionConfig = sectionConfigSomatic(annexesData, clinicalReportName, setClinicalReportName, additionalData);

    return (
        <>
            {sectionConfig.map((section, index) => (
                <Grid container spacing={2} key={index} style={{ marginBottom: '20px' }}>
                    {section.title && (
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '20px', fontWeight: '700', marginBottom: '20px' }}>
                                <MappedText text={section.title} />
                            </Typography>
                        </Grid>
                    )}
                    {section.subTitle && (
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '16px', fontWeight: '700', marginBottom: '10px' }}>
                                <MappedText text={section.subTitle} />
                            </Typography>
                        </Grid>
                    )}
                    {section.paragraph && (
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '12px', fontWeight: '500', marginBottom: '10px' }}>
                                <MappedText text={section.paragraph} />
                            </Typography>
                        </Grid>
                    )}
                    {section.render && (
                        <Grid item xs={12}>
                            {section.render()}
                        </Grid>
                    )}
                    {section.fields && (
                        <Grid item xs={12} container spacing={2}>
                            {section.fields.map((field, fieldIndex) => (
                                <Grid item xs={section.columns === 1 ? 12 : 6} key={fieldIndex}>
                                    {field.component === 'TextField' ? (
                                        renderTextField(field.title, field.value, (e) => field.setValue(e.target.value))
                                    ) : (
                                        <DataField title={field.title} value={stringifyValue(field.value)} />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            ))}
        </>
    );
}

export default AnnexesSomaticSection;
