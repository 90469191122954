
import React from "react";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import FlagIcon from "@material-ui/icons/Flag";
import FlaggedBadge from "../../FlaggedBadge";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const useOptionFilter = (columns, type, states, setExpandedRows, data) => {
  const dataRef = React.useRef(states?.mainData);
  dataRef.current = states?.mainData;
  return (hooks) => {
    hooks.visibleColumns.push((columns) => {
      let newColumns = [...columns];

      if (type === "toggle") {
        newColumns = [
          {
            id: "selection",
            disableFilters: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...newColumns,
        ];
      }

      if (type === "expanding") {
        newColumns = [
          {
            id: "expansion",
            disableFilters: true,
            Header: () => null, 
            Cell: ({ row }) => (
              <div>
                <span
                  {...row.getToggleRowExpandedProps()}
                  
                  onClick={(e) => {
                    e.stopPropagation();
                    const newData = dataRef.current[row.original.originalIndex];
                    setExpandedRows((prevState) => ({
                      ...prevState,
                      [row.id]: {
                        expanded: true,
                        data: [newData], 
                      },
                    }));
                    states.setRowIdSelected(row.id);
                    states.setIdExpanded({
                      id: row.id,
                      expanded: true,
                    });
                    row.toggleRowExpanded();
                  }}
                >
 
              { row.isExpanded ?
                  <div>
                      <IconButton aria-label="expandMore" color="black">
                          <ExpandMore/>
                      </IconButton>
                      {(row.isFlagged) ?
                          <FlaggedBadge badgeContent={1} ><FlagIcon style={{ color: "#3182bd" }}/></FlaggedBadge>
                          : null}
                  </div>
                      :
                  <div>
                      <IconButton aria-label="keyboardArrowRight" color="black">
                          <KeyboardArrowRight/>
                      </IconButton>
                      {(row.isFlagged) ?
                          <FlaggedBadge badgeContent={1}><FlagIcon style={{color: "#3182bd"}}/></FlaggedBadge>
                          : null}
                  </div>
            }
                </span>
              </div>
            ),
          },
          ...newColumns,
        ];
      }

      return newColumns;
    });
  };
};

export default useOptionFilter;