

import React, {useEffect, useState} from "react";

import {get_ebi_quickGo_Annotation, get_quickGo} from "../../../../../../../../../services/api/variant_external_calls";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import "./cards.css";
import {CircularProgress} from "@material-ui/core";

function QuickGO(props){

    const [results, setGoResults] = useState([]);
    const [gos, setGos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(function(){

        if(props.input){
            setLoading(true);
            get_quickGo(props.input)
                .then(r => r.json())
                .then(json =>
                {
                    setLoading(false);
                    setGoResults(json);
                }
                )
        }


    }, [props.input]);

    useEffect(function(){

        let array = results.results;

        if(array!== undefined && array.length!==0){
            array.forEach(function(result){

                get_ebi_quickGo_Annotation(result.id, props.token)
                    .then(r => r.json())
                    .then(function(json){

                        let copy_gos = [...gos];
                        let term = (json.results!== undefined && json.results.length!==0) ? json.results[0] : false;
                        if(term){
                            copy_gos.push({goID: result.id, name: json.results.name, id: json.results[0].id});
                            setGos(copy_gos);
                        }

                    })


            });
        }


    }, [results]);


    const renderData = () => {


        let array = results.results;

        if(array!== undefined && array.length!==0){
            return array.map(function(item){
                let items = gos.find(s => s.goID === item.id);

                let links = [];
                if(items!==undefined){
                    links = items.map( term => <div style={{paddingTop:"10px"}}><Link href={"https://www.ebi.ac.uk/QuickGO/term/" + term.id}>{term.name}</Link></div>)
                }
                else{
                    links = <Typography variant={"subtitle1"}>ebi.ac.uk could not be queried correctly. </Typography>
                }
                return <Box p={2}>
                    <Typography variant={"subtitle2"}>Query for {props.input}  </Typography>
                    <Divider/>
                    <Typography variant={"subtitle2"}>UniProtKB id:{item.id}</Typography>
                    <Divider/>
                    {links}
                </Box>


            })

        }
        else{
            return <Box p={2}>
                <Typography color={"red"}>Query for {props.input} didn't return annotations</Typography>
            </Box>
        }



    };




    return <Grid item lg={12}>
        <div className={"my_paper_box"}>
                <Box p={1}>
               <Typography variant={"body1"}>GO <GPAP_InfoIcon style={{float: "right"}} title={"Gene Ontology and GO Annotations"}/></Typography>
                    <Divider/>
                    <div className={"boxResults"}>
                        {(loading)
                            ? <Box p={2}><CircularProgress/></Box>
                            : renderData()
                        }
                    </div>
                </Box>
            </div>
        </Grid>
}

export default QuickGO;