import React from 'react';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import MappedText from '../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText';



const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        /*color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,*/
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));


function CustomTab(props) {
    const { classes, label, ...restProps } = props;

    return  <AntTab {...restProps} 
    label={<MappedText text={label}/>}
    />
  
}

export default CustomTab;