




/*
 * action types
 */

export const UPDATE_QUERY_NAME = 'UPDATE_QUERY_NAME';



/*
 * action creators
 */
export function updateQueryName(index, queryName) {
    return {
        type: UPDATE_QUERY_NAME,
        index: index,
        queryName: queryName }
}
