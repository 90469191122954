import { AlignmentType, HeadingLevel, Paragraph } from "docx";
import { drawBasicTable } from "../../write-sections/write_utils/drawTables_examples.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";

const headers = [
    "Gene",
    "Transcript cDNA",
    "GT",
    "GQ",
    "DP",
    "REF count",
    "ALT count",
    "AAF",
];

const dataKeys = [
    "gene",
    "transcript_cDNA",
    "gt",
    "gq",
    "dp",
    "ref_count",
    "alt_count",
    "aaf",
];

function WriteVariantMetricsSection(sectionData) {
    let rows = sectionData && Array.isArray(sectionData) ? sectionData : [];
    
    return [
        new Paragraph({
            text: renderToString("Variant metrics:"),
            heading: HeadingLevel.HEADING_2
        }),
        new Paragraph({
            text: "",
        }),
        drawBasicTable(rows, headers, dataKeys, renderToString("No data available")),
      
        new Paragraph({
            text: "",
        }),
    ];
}

export default WriteVariantMetricsSection;