
import React from 'react';
import Autocomplete, {createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import _ from 'lodash';
import {
    experiments_view_new,
    getParticipantBySample,
    getParticipants_Aggregations
} from "../../../../../../services/api/get";
import {connect} from "react-redux";
import {AlertInfo} from "./SelectionInfo";



class SearchID extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            results: [],
            options_text: "Start your search..."
        };
        this.onTagsChange = this.onTagsChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getField = this.getField.bind(this);

        this.REQUEST_N = 30000;

    }


    onTagsChange = (event, values) => {
        this.setState({
            tags: values
        }, () => {
            // This will output an array of objects
            // given by Autocomplete options property.
            if(this.props.passSelected !== undefined ){

                if(values!== undefined && values!== null){
                    this.props.passSelected(values);
                }
                else{
                    this.props.passSelected(undefined);
                }

            }
        });
    };

    onInputChange(event,value){


        let field = "report_id";
        if(this.props.field !== undefined)
        {
            field = this.props.field;
        }
        //Request all data
        let total_rows = this.REQUEST_N;

        if (value !== "" ) {

            if(value.length>-1){

            if (value.length < 2 && (this.props.sampleType=== "report_id" || this.props.sampleType=== "id" || this.props.sampleType === "famid")) {
                this.setState({ options_text: "Please keep typing, at least 2 characters required...", error_msg: false});
            }
            else{
                 this.setState({ options_text: "Searching...", error_msg: false });
            }

            if(value.length > 1 && (this.props.sampleType === "report_id" || this.props.sampleType === "id" || this.props.sampleType === "famid")){
                    //Object to post

                    let postObject = {
                        page:  1,
                        pageSize: total_rows,
                        filtered: [{id: field, value:[value]}],
                        fields: [field, "sex"]
                    };


                    getParticipantBySample( config.apis_configuration.api_phenostore, this.props.token, postObject).then(data => data.json())
                        .then((data) => {

                            this.setState({
                                results: data.rows
                            });

                            if (this.state.results.length === 0)
                                this.setState({ options_text: "No options" });


                        });
                }
                else if(  this.props.sampleType === "case" || this.props.sampleType === "LocalExperimentID"){
                    //Object to post

                    let field = (this.props.sampleType === "case") ? "ExperimentID" : "LocalExperimentID"

                    let postObject = {
                        page:  1,
                        pageSize: total_rows,
                        filtered: [{id: field, value: [value]}],
                        fields: ["ExperimentID", "Participant_ID", "library_strategy", field]
                    };

                    experiments_view_new( config.apis_configuration.api_data_management, this.props.token, postObject)
                        .then(function(data){
                                if(data.ok){
                                    return data.json()
                                }
                                else{
                                    return false;
                                }
                            })
                        .then((data) => {

                            if(data){
                                this.setState({
                                    results:data.items
                                });

                                if (this.state.results.length === 0)
                                {
                                    this.setState({ options_text: "No options" });
                                }
                            }
                            else{
                                this.setState({ options_text: "Connection Error" });
                            }
                        });
                }
            }

            else if (this.props.sampleType === "case" || this.props.sampleType === "LocalExperimentID"){
                this.setState({results:[], options_text: "Keep typing..."});

            }else {
            this.setState({results:[], options_text: "Start your search..."})
            }
        }
    }
    


    getField = () => {

        if(this.props.sampleType === "case"){
            return "ExperimentID";
        }
        if(this.props.sampleType === "LocalExperimentID"){
            return "LocalExperimentID";
        }
        else if (this.props.sampleType === "id"){
            return "id";
        }
        else if(this.props.sampleType === "famid"){
            return "famid";
        }
        else{
            return "report_id";
        }
    };

    filterOptions = (options, state) => {

        const _filterOptions = createFilterOptions();
        let results = _filterOptions(options, state);
        let field = this.getField();
        if(this.state.results!== undefined ){

            if(this.state.results.length > 10){
                results = results.slice(0,10);
                let obj = {};
                obj[field] = "+ " + (this.state.results.length - 10) + " matches.";
                obj["Participant_ID"] = "";
                obj["library_strategy"] = "";

                if (!results.includes(obj)) {
                    results.push(obj);
                }
            }

        }

        return results;
    };

    render() {

        let field = this.getField();
        let options_display = [];

        // process results;
        this.state.results.forEach(function(s){
            if (options_display.find(opt => opt[field] === s[field]) === undefined){
                options_display.push({...s})
            }
        })

        return (
            <React.Fragment>
                <Autocomplete
                    filterOptions = { this.filterOptions }
                    options={options_display}
                    noOptionsText={this.state.options_text}
                    autoHighlight={true}
                    getOptionLabel={option => (option[field]!==undefined) ?  option[field] : ""}
                    onChange={this.onTagsChange}
                    renderOption={(option, inputValue ) => {
                        if(option[field]!== undefined){
                            if(option[field].includes("matches")){
                                return (
                                    <span {...option} style={{ fontWeight: "bold" }}>
                                {option[field]}
                              </span>)
                            }
                            else{
                                return (
                                    <span {...option} >
                                        {option[field]}
                              </span>)
                            }
                        }
                    }}
                    onInputChange={_.debounce(this.onInputChange, 1000)} //** on every input change hitting my api**
                    getOptionDisabled={option => {
                        if(option[field]){
                            return option[field].includes("matches")
                        }
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={this.props.title}
                            margin="normal"
                            fullWidth
                        />
                    )}
                />
                <Typography variant={"subtitle2"}> Only experiments that have been processed and that are ready for analysis are visible. </Typography>
                { (this.state.error_msg) ? <AlertInfo title={this.state.error_msg} severity={"error"}/> : null }
                {this.state.tags && field in this.state.tags &&
                <div>
                    <Typography variant={"h4"} style={{fontSize:"15px"}} > You have selected: <span
                        style={{fontWeight: "bold"}}> {this.state.tags[field]} </span> </Typography>
                    <div style={{marginTop:"3%"}}>
                    </div>
                </div>
                }
            </React.Fragment>
        );
    }
}


function mapPatients (state) {
    if(state.authorization!== undefined)
    {
        return {token: state.authorization.token}
    }
    else{
        return {token: ""}
    }

}




export default connect(mapPatients,null)(SearchID);
