

import {UPDATE_ROH_VALUE} from "./roh_actions";


const roh_init = {
    roh_value: []
};


export const roh_reducer = (state= roh_init, roh_action) => {


    if (roh_action.type === UPDATE_ROH_VALUE) {
        return roh_action.roh_value;
    } else {
        return state
    }

   /* switch (action.type) {
        case UPDATE_ROH_VALUE:
            console.log("setting roh value - reducer")
            return action.roh_value;
        default:
            console.log("setting default roh value - reducer ")
            // TODO: this is called at any refresh of the state..
            if(state!== undefined && Array.isArray(state) && state.length!== 0 && state.roh_value.length!==0){
                return
            }
            else{
                return [];
            }





    }*/



};