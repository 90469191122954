

import {diff} from "deep-object-diff";


function query_difference(query_1, query_2){

    const list_of_filters = diff(query_1, query_2 );
    delete list_of_filters["samples_germline"];

    return list_of_filters

}


function getCurrentQuery(studies, studySettings){

    if( studySettings.current_analysis!== null){
        const analyses = studies
            .find(study => study.localId === studySettings.id).analyses;

        const queries = analyses.find(analysis => analysis.localId === studySettings.current_analysis).queries;

        let currentQuery = queries.find(q => q.localId === studySettings.current_query);

        return currentQuery;
    }
    else{
        return false;
    }



}

export {query_difference, getCurrentQuery}