

import React, {Fragment, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ViewListIcon from '@material-ui/icons/ViewList';
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import Link from "@material-ui/core/Link";
import {connect} from "react-redux";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';
import { getLabelChrom } from '../../../../../side-panels/filter-panel/components/filter-sections/position-section/Position_Section';




function IGVLinks(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const headerConf = [
        { key: "Experiment ID", text: "Experiment ID"},
        { key: "IGV_position", text: "IGV Position"},
        { key: "IGV_region", text: "IGV Region"},
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const renderSamples = () => {


        let origin = window.location.origin;


        let selectedDataRow = props.selected_table_rows.find(s => s.data_type === props.dataType);

        if (selectedDataRow !== undefined) {
            let variantObj = selectedDataRow.data_row;
            if (variantObj.length > 0) {

                let variant = variantObj[0];
                if (props.samples !== undefined && props.samples.length !== 0 && Array.isArray(props.samples)) {
                    return props.samples.map(function (s) {
                        if (variant !== undefined) {

                            let chrom, pos, start, end;

                            if (props.dataType === "CNV") {
                                chrom = variant.SV_chrom === 23 ? "MT" : variant.SV_chrom;
                                start = parseInt(variant.SV_start);
                                end = parseInt(variant.SV_end);

                                let start_pos_res_1 = start - 20;
                                let start_pos_sum_1 = start + 20;
                                let start_positions = start_pos_res_1 + "-" + start_pos_sum_1;

                                let end_pos_res_1 = end - 20;
                                let end_pos_sum_1 = end + 20;
                                let end_positions = end_pos_res_1 + "-" + end_pos_sum_1;

                                let start_pos_res_2 = start - 1000;
                                let start_pos_sum_2 = start + 1000;
                                let start_region = start_pos_res_2 + "-" + start_pos_sum_2;

                                let end_pos_res_2 = end - 1000;
                                let end_pos_sum_2 = end + 1000;
                                let end_region = end_pos_res_2 + "-" + end_pos_sum_2;

                                let sampleOrExperiment = s.sample || s;

                                return <Fragment key={sampleOrExperiment}>
                                    <Grid item lg={4}>
                                        <Link target={"_blank"}
                                            href={origin + "/datamanagement/#/experimentinfo/" + sampleOrExperiment}>{sampleOrExperiment}</Link>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Grid container direction="column">
                                            <Link target={"_blank"}
                                                href={`${origin}/igvgpap/#/browser/?file=${sampleOrExperiment}&chrom=${chrom}&locus=${start_positions}`}>Start +- 20bp </Link>
                                            <Link target={"_blank"}
                                                href={`${origin}/igvgpap/#/browser/?file=${sampleOrExperiment}&chrom=${chrom}&locus=${end_positions}`}>End +- 20bp </Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Grid container direction="column">
                                            <Link target={"_blank"}
                                                href={`${origin}/igvgpap/#/browser/?file=${sampleOrExperiment}&chrom=${chrom}&locus=${start_region}`}>Start +- 1 kbp</Link>
                                            <Link target={"_blank"}
                                                href={`${origin}/igvgpap/#/browser/?file=${sampleOrExperiment}&chrom=${chrom}&locus=${end_region}`}>End +- 1 kbp</Link>
                                        </Grid>
                                    </Grid>

                                </Fragment>
                            } else {
                                chrom = variant.chrom === 23 ? "MT" : variant.chrom;
                                start = parseInt(variant.pos);

                                let res_1 = start - 20;
                                let sum_1 = start + 20;
                                let positions = res_1 + "-" + sum_1;

                                let res_2 = start - 1000;
                                let sum_2 = start + 1000;
                                let region = res_2 + "-" + sum_2;

                                let sampleOrExperiment = s.sample || s;

                                return <Fragment key={sampleOrExperiment}>
                                    <Grid item lg={4}>
                                        <Link target={"_blank"}
                                              href={origin + "/datamanagement/#/experimentinfo/" + sampleOrExperiment}>{sampleOrExperiment}</Link>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Link target={"_blank"}
                                              href={`${origin}/igvgpap/#/browser/?file=${sampleOrExperiment}&chrom=${chrom}&locus=${positions}`}>+- 20bp </Link>
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Link target={"_blank"}
                                              href={`${origin}/igvgpap/#/browser/?file=${sampleOrExperiment}&chrom=${chrom}&locus=${region}`}>+- 1 kbp</Link>
                                    </Grid>
                                </Fragment>
                            }
                        } else {
                            return null;
                        }
                    })
                } else {
                    return <div>Samples Information not available</div>
                }
            }
        }
    };

    let buttonColor = (open) ? "orange" : "";

    return (
        <div style={{display:"inline-block", margin: ".5%"}}>
            <Button  aria-describedby={id} variant="outlined" size="small" onClick={handleClick}
                     style={{ marginLeft: ".5%", width: "143px", background: buttonColor}} startIcon={<ViewListIcon/>}>
                <MappedText text={"IGV Browser"}/>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} style={{width: "400px" }}>
                    <Grid container direction={"row"}>
                        <Grid item lg={12}>
                            <div>
                            <img
                                style={{    float: "right", maxWidth: "30px"}}
                                src={"https://avatars.githubusercontent.com/u/11527111?s=200&v=4"}/>
                            </div>
                        </Grid>
                            {headerConf.map(d => { return <Grid item lg={4}>
                                <GPAP_Tooltip title = {d.text} placement= "top" >
                                    <Typography variant={"subtitle1"} style={{fontWeight: "bold"}}> {d.text} </Typography>
                                </GPAP_Tooltip>
                            </Grid>})}

                        {renderSamples()}
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
}

function mapStateToProps (state) {


    if(state.selected_table_rows!==undefined)
    {
        return {
            selected_table_rows: state.selected_table_rows
        }
    }
    else{
        return {selected_table_rows: []}
    }

}

export default connect(mapStateToProps)(IGVLinks);