




// this gives back the list of all predefined lists (metadata only)
export function get_all_lists(token,url_prefix){
    return fetch((url_prefix + "/get_all_lists"),{
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
            'Authorization': token}
    })
}

// save a new list;
export function save_new_list(token,url_prefix, data){
    return fetch((url_prefix + "/save_list"),{
        method: 'POST',
        headers:{
            'Content-Type': 'application/json',
            'Authorization': token} ,
        body: JSON.stringify(data)
    })
}

// this gives back the list of all predefined lists (metadata only)
export function get_list_data(token,url_prefix, data){
    return fetch((url_prefix + "/get_list"),{
        method: 'POST',
        headers:{
            'Content-Type': 'application/json',
            'Authorization': token
        } ,
        body: JSON.stringify(data)
    })
}

// this gives back the list of all predefined lists (metadata only)
export function delete_list(token,url_prefix, data){
    return fetch((url_prefix + "/delete_list"),{
        method: 'DELETE',
        headers:{
            'Content-Type': 'application/json',
            'Authorization': token} ,
        body: JSON.stringify(data)
    })
}

