import axios from "axios";

export const fileDownload = async (url, token) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob', 
      headers: {
        Authorization: `${token}`,
      },
    });


    const contentType = response.headers['content-type'];
    let extension;

    switch (contentType) {
      case 'application/pdf':
        extension = 'pdf';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        extension = 'docx';
        break;
      case 'application/msword':
        extension = 'doc';
        break;
      default:
        extension = '';
    }


    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const decodedFileName = decodeURIComponent(fileName); 


    const finalFileName = extension && !decodedFileName.includes('.') ?
                          `${decodedFileName}.${extension}` : decodedFileName;

    const urlDownload = window.URL.createObjectURL(new Blob([response.data], { type: contentType || 'application/octet-stream' }));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', finalFileName);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(urlDownload);
    link.remove();
  } catch (error) {
    console.error('Error:', error);
  }
};