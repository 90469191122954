

import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import "./query_top_bar.css";
import Typography from "@material-ui/core/Typography";
import {GPAP_Button, GPAP_Button_light} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import {AlertInfo} from "../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import ClearIcon from "@material-ui/icons/Clear";
import Collapse from '@material-ui/core/Collapse';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {bindActionCreators} from "redux";
import {getCurrentAnalysis} from "../../../../results/components/variant-table/getAnnotations";
import {checkIfNameExists, getText, isStringOnlyEmptySpaces} from "./func";
import SaveFilterSet from "./SaveFilterSet";
import FilterSet_SavePanel from "./FilterSet_SavePanel";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import * as FilterSetActions from "../../../../../../../../../actions/index.js"

function useRefHook() {
    const ref = useRef(null);

    useEffect(() => {
        // Code to be executed when the component using the hook mounts
        // or when the ref value changes

        // You can perform any desired operations on the ref here
    }, [ref]);

    return ref;
}


function QueryTopBar(props){

    const [running, setRunning] = useState(false);


    const runQuery = () => {


        props.queryRunner();
        setRunning(true);
        setTimeout(function(){  setRunning(false) }, 1000);

    }




    const resetQuery = () =>{

        props.resetQuery()
        props.filterSet_Actions.reset_filter_set();
    }


    const getAlertMessage = () => {

        let { disable, requireGenes } = props;

        let message= (requireGenes)
            ? "You can't run a query till genes are not selected for a search across/cohort study"
            : "Experiments not configured correctly" ;

        return (disable)
            ? <AlertInfo severity="warning" message={message} title={"No genes selected"}/>
            : null;

    }



    const getChetCheck = () => {

        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);

        // inheritance_mode id === 3 is C-het;
        return (analysis.analysis.inheritance_mode_id === 3 && !props.formTouched)

    }



    return (
        <React.Fragment>
            <div className={"bar"}>
                <Box p={0}>
                    <Typography variant={"h5"} style={{marginLeft: "8px"}}>
                       <MappedText text={"Filter Panel"}/>
                    </Typography>
                </Box>
                <Box p={1}  style={{borderBottom: "1px solid #bdbdbd"}}>
                    {getAlertMessage()}
                    <GPAP_Button size={"sm"}
                                 variant={"run"}
                                 disabled={props.disable || getChetCheck()}
                                 onClick={function (e) {runQuery()}}>
                        <MappedText text={"Run Query"}/> </GPAP_Button>
                    <GPAP_Button_light
                        size={"sm"}
                        variant={"gpap_button"}
                        onClick={function (e) {resetQuery()}}
                        startIcon={<ClearIcon/>}> <MappedText text={"Reset Filters"}/>
                    </GPAP_Button_light>
                    <FilterSet_SavePanel/>
                </Box>
                {(getChetCheck()
                    ? <AlertInfo severity="warning"
                                 message={"To run a C-Het analysis you must add at least one filter."}
                                 title={"No filter selected"}/>
                    : null)}
            </div>

        </React.Fragment>
    )


}


export function map_enable_query (state) {
    if(state.sample_list!==undefined) {

        let validTypes = ["family_trio", "somatic", "instand"];
        let requireGenes = !validTypes.includes(state.studySettings.analysisType); // case search across all or cohort;

        let bool = state.sample_list.samples.length === 0 || ( requireGenes && state.geneList.geneList.length === 0  );
        let touched = false;
        Object.keys(state.form).forEach(function(formEl){
            let VALUES = state.form[formEl].values;
            if(VALUES!== undefined){
                Object.keys(VALUES).forEach(function(formElKey){

                    let val = VALUES[formElKey];
                    if(Array.isArray(val) && val.length !== 0){
                        touched = true
                    }
                    if (val !== null && !Array.isArray(val) ) {
                        touched = true;
                    }
                })

            }})

        return {
            disable: bool,
            requireGenes: requireGenes,
            token: state.authorization.token,
            formTouched:touched,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket
        }
    }
    else{
        return {disable: true}
    }
}
const mapDispatchToProps = (dispatch) => ({
    filterSet_Actions: bindActionCreators(FilterSetActions, dispatch)
});


export default connect(map_enable_query, mapDispatchToProps)(QueryTopBar);