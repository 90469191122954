
const genomeCoverageData =
    [
        {
            "mean_coverage": "NA",
            "median_coverage": "NA",
            "c10": "NA",
            "c15": "NA",
            "c20": "NA",
        }
    ]
    export default genomeCoverageData;