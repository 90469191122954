



// returns the samples that satisfy the conditions

export function filterConditions(samples, conditions){

    return samples.filter(function (item) {
        for (let key in conditions) {

            let bool = item[key] === undefined || item[key] !== conditions[key];
            if (bool)
                return false;
        }
        return true;
    });
}