





export const parse_date = (d) => {

    return d.getDate()  +
        "/" +  (parseInt(d.getMonth())+ 1) +
        "/" +  d.getFullYear();
};

export const X_CHROM_OBJ = {
    chrom: "24",
    pos: -1,
    end_pos: -1
}