import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, BorderStyle } from "docx";
import { createSpacer } from "../write_utils/documentHelpers";
import { renderToString } from "../../../create-report/shared-components/renderToString";
export default function WriteGeneralInfo (data, dirSelected) {

    return [new Table({
        width: { size: 100, type: WidthType.PERCENTAGE },
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Issuing center:"),
                                    bold: true
                                }),
                                new TextRun(dirSelected ? dirSelected.issuing_center  : 'NA')
                            ]
                        })],
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Requesting center:"),
                                    bold: true
                                }),
                                new TextRun(dirSelected ? dirSelected.requesting_centre : 'NA')
                            ]
                        }), new Paragraph({ text: "" })],
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.NONE },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    }),
                ],

            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Reporting date:"),
                                        bold: true
                                    }),
                                    new TextRun(dirSelected ? dirSelected.creation_date : 'NA')
                                ]
                            }), new Paragraph({ text: "" })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Referrer: "),
                                        bold: true
                                    }),
                                    new TextRun(dirSelected ? dirSelected.clinical_referrer : 'NA')
                                ]
                            })],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.NONE },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Identifier: "),
                                        bold: true
                                    }),
                                    new TextRun(dirSelected ? dirSelected.dir_id : 'NA')
                                ]
                            }),
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: ""
                            })],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.NONE },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    }),
                ],

            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Name: "),
                                        bold: true
                                    }),
                                    new TextRun(dirSelected ? dirSelected.first_name : 'NA')
                                ],
                            }),],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Age: "),
                                        bold: true,
                                    }),
                                    new TextRun(dirSelected ? dirSelected.age : 'NA')
                                ]
                            })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.NONE },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    })],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Surname: "),
                                        bold: true
                                    }),
                                    new TextRun(dirSelected ? dirSelected.last_name : 'NA')
                                ]
                            }), new Paragraph({ text: "" })],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Sex: "),
                                        bold: true
                                    }),
                                    new TextRun(dirSelected ? dirSelected.sex : 'NA')
                                ]
                            })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.NONE },
                            left: { style: BorderStyle.NONE },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    })]
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Sample type:"),
                                    bold: true
                                }),
                                new TextRun(dirSelected ? dirSelected.sample_tissue_of_origin_status : 'NA')
                            ]
                        })],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Biopsy location:"),
                                    bold: true
                                }),
                                new TextRun(dirSelected ? dirSelected.sample_tissue_of_origin : 'NA')
                            ]
                        })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE },
                            bottom: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            left: { style: BorderStyle.NONE },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    })],
            }),
        ],
    }),
        createSpacer(50, 50),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("REASON FOR REFERRAL:"),
                    bold: true
                }),
                new TextRun(dirSelected ? dirSelected.clinical_referral : 'NA')
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("STUDY PERFORMED:"),
                    bold: true
                }),
                new TextRun(dirSelected ? dirSelected.study_performed: 'NA')
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("TEST PERFORMED:"),
                    bold: true
                }),
                new TextRun(dirSelected ? dirSelected.library_strategy: 'NA')
            ]
        })];
}