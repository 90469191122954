
import React, { Component } from 'react'


export default class Bars extends Component {
    constructor(props) {
        super(props);

        this.colors={
            selected: "green",
            hovered: "yellow",
            default: "orange"
        }
    }


    getFillColor(val){
        if(val === this.props.selectedData.d){return this.colors.selected}
        else{return this.colors.default}

    }

    render() {
        const { scales, margins, data, initData, svgDimensions } = this.props;
        const { xScale, yScale } = scales;
        const { height } = svgDimensions;

        const bars = (
            data.map((datum,index) =>
                <rect
                    style={{cursor:"auto"}}
                    key={index}
                    x={xScale(datum.d)}
                    y={yScale(datum.value)}
                    height={height - margins.bottom - scales.yScale(datum.value)}
                    width={xScale.bandwidth()}
                    fill={this.getFillColor(datum.d)}
                    onMouseOver={() => this.props.onMouseOverCallback(datum)}
                    onMouseOut={() => this.props.onMouseOutCallback(null)}
                    /*onClick={() => this.props.onMouseClickCallback(datum)}*/
                />
            )
        );
        const bars_all = (
            initData.map((datum,index) =>
                <rect
                    style={{cursor:"auto"}}
                    key={index}
                    x={xScale(datum.d)}
                    y={yScale(datum.value)}
                    height={height - margins.bottom - scales.yScale(datum.value)}
                    width={xScale.bandwidth()}
                    fill={"#f0f0f0"}
                    onMouseOver={() => this.props.onMouseOverCallback(datum)}
                    onMouseOut={() => this.props.onMouseOutCallback(null)}
                    /*onClick={() => this.props.onMouseClickCallback(datum)}*/
                />
            )
        );

        return (
            <g>
                {bars_all}
                {bars}
            </g>
        )
    }
}