
import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Variant_Section from "../filter-sections/variant-section/Variant_Section";
import SNV_Section from "../filter-sections/snv-effect-prediction/SNV_Section";
import Population_Section from "../filter-sections/population-section/Population_Section";
import Position_Section from "../filter-sections/position-section/Position_Section";

import IconLeftExpansionPanelSummary
    from "../../../../results/components/variant-table/gpap-table/ParticipantTable/LeftIconExpansion";
import {Accordion, AccordionDetails} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import useFilterStyles from "./filtersStyles";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


// keep only certain keys;
function keep_only_keys(myObj, keys){

    if(myObj!== undefined && myObj){
        let copy_obj = {...myObj};

        for (let k in copy_obj) {
            if (keys.indexOf(k) < 0) {
                delete copy_obj[k];
            }
        }

        return { ... copy_obj}
    }
    else{
        return myObj
    }

}

function parsePopFields(myObj, keys){

    if(myObj!== undefined && myObj && !Array.isArray(myObj)){

        return {
            gnomad_af: myObj["population"].gnomad_af,
            gp1_af: myObj["population"].gp1_af,
            freqInt: myObj["freqInt"]
        }
    }
    else{
        return myObj
    }



}

function getView(query_section_name, default_filters, queryKeys,somatic) {


    switch (query_section_name) {

        case 'variants':
            return <Variant_Section
                default_filters = {keep_only_keys(default_filters, queryKeys)}  // can remove the useless key with function above.
                minimized={false}/>;
        case 'snv':
            let filters = keep_only_keys(default_filters, queryKeys);
            return <SNV_Section
                default_filters = {filters}
                somatic = {somatic}

            />;
        case 'population':
            return <React.Fragment>
                <Population_Section
                    default_filters = {parsePopFields(default_filters, queryKeys)}
                />
            </React.Fragment>;
        case 'position':
            return <Position_Section/>;}

}


export default function FilterSections(props){

    const styles= useFilterStyles()
    const sections = props.sections;
    const [expanded, setExpanded] = useState({key: "none"});

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getTitle = (title, active, description) => {

        if(active){
            return <React.Fragment>
                    <Typography variant={"body1"}
                                component={"div"}
                                className={styles.header}>
                        <MappedText text={title} />
                    </Typography>
                <Box p={0} style={{float: "right"}}>
                    <GPAP_InfoIcon title=<MappedText text={description}/> color={"white"}/>
                </Box>
            </React.Fragment>

        }
        else{
           return <Typography variant={"body1"}
                              component={"div"}
                              className={styles.header}>
                <MappedText text={title} />
               <Box p={0} style={{float: "right"}}>
                   <GPAP_InfoIcon title=<MappedText text={description}/> color={"white"}/>
               </Box>
            </Typography>
        }

    }


    return sections.map(function(sectionName){
        let title = sectionName.label;
        return (<Accordion expanded={expanded.key === sectionName.key}
                           onChange={handleChange(sectionName)}
                           style={{marginBottom: "5px"}}>
            <IconLeftExpansionPanelSummary
                className={styles.accordion_header}
                expandIcon={<ExpandMoreIcon className={styles.icon_style}/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                {getTitle(title, sectionName.active, sectionName.description)}
            </IconLeftExpansionPanelSummary>
            <AccordionDetails>
                <div className={"myPanel"}>
                    {getView(sectionName.key, props.default_filters, sectionName.queryKeys,props.somatic)}
                </div>
            </AccordionDetails>
        </Accordion>)



})
}