import React from 'react';
import { Typography } from '@mui/material';
import MappedText from '../../study-page/create-study/analysis-page/components/clinical-info/mapText';

const stringifyIfNeeded = (value) => {

    return value !== null && value !== undefined ? value.toString() : 'NA';
}

const DataField = ({ title, value, helperText }) => {

  //console.log(`DataField value `, value);

  const stringValue = stringifyIfNeeded(value);
//console.log(`DataField string: `, stringValue); 

  const capitalizedValue = stringValue.charAt(0).toUpperCase() + stringValue.slice(1);

  return (
    <>
      <Typography sx={{ color: '#626262', fontWeight: '400', fontSize: '14px' }}>
        <MappedText text={title}/>
      </Typography>
      <Typography sx={{ color: '#202020', fontWeight: '400', fontSize: '16px' }}>
        <MappedText text={capitalizedValue} />
      </Typography>
      {helperText && (
        <Typography sx={{ color: '#757575', fontWeight: '400', fontSize: '12px', marginTop: '4px' }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default DataField;
