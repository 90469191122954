

export function mergeGeneListWithFilters(query, list_found, isOperatorApplied) {

    if (list_found) {
        // If a list is found, merge it with the existing gene filters
        const filter_json = { ...query.filter_set.filters }; // Create a copy of the existing filter set
        let full_list_genes = [];

        if (isOperatorApplied) {
            // check operation type
            const geneListOperator = query.extra.find(s => s.hasOwnProperty("gene_list_operator"))?.gene_list_operator;

            if (geneListOperator === "intersection") {
                // the current genes are the ones added in other ways...
                full_list_genes = filter_json.gene;
            } else {
                // add the ones from predefined gene list;
                full_list_genes = filter_json.gene.concat(list_found.data); // Combine the existing genes with the found list
            }
        }
        
        //When switching to a new Analysis the "isOperatorApplied" is false (only for the first query) and so the predefined gene list is not added to the query
        //Need to handle it here for now
        else {
            full_list_genes = filter_json.gene.length > 0 ? filter_json.gene : filter_json.gene.concat(list_found.data);
        }

        filter_json.gene = [...full_list_genes]; // Update the gene filter with the combined list
        query.filter_set.filters = { ...filter_json }; // Apply the updated filter set to the query
    }

    return query;
}

// Usage:
// query = mergeGeneListWithFilters(query, list_found, isOperatorApplied);


