


import  React from 'react'
import "./query-filters.css";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {default_query} from "../../../../../../../../services/api/query_sample";
import Box from "@material-ui/core/Box";
import FieldRendering from "./FieldRendering";
import {getCurrentQuery, query_difference} from "./query_difference";
import MappedText from '../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';

// this is Showing the Filters applied;

function QueryFilters(props){

    // check_diff
    let {studies, studySettings} = props;

    // get currentQuery only when loaded;

    let currentQuery = (props.whichQuery === "new") ? props.query : getCurrentQuery(studies, studySettings);

    // get extra field


    const whichFilterSetApplied = () => {

        // if a filter set is applied in configured in the store, we show the name. Below, all applied criteria are shown.
        if( props.currentFilterSet && Object.keys(props.currentFilterSet).length >0 ){
            return <div>
                <div className={"field_title"} style={{ fontWeight: "bold"}} ><MappedText text={"Used filter-set"}/> </div>
                <div className={"filter_value"}> {props.currentFilterSet.filter_set_name} </div>
            </div>

        }
        else{
            return null;
        }


    }

    const getFilterList = () => {

        if(currentQuery!== undefined)
        {

            let list_of_filters;
            let query_1 = default_query;
            if(props.whichQuery === "new"){
                list_of_filters = query_difference(query_1,props.query);

            }
            else{
                let query_2 = (currentQuery.filters!== undefined ) ? JSON.parse(currentQuery.filters) : currentQuery.filter_set.filters;
                list_of_filters = query_difference(query_1,query_2);

            }
            

            // some mapping

            // remove size
            delete list_of_filters["size"]
            if("svn" in list_of_filters){
                delete Object.assign(list_of_filters, {["snv"]: list_of_filters["svn"] })["svn"];
            }


            //For Instand predefined gene list to appear in the Applied Filters
            if (studySettings.analysisType === "instand"){
               if (props.applied_genes.applied_lists && props.applied_genes.applied_lists.length > 0)
                 currentQuery.extra = [props.applied_genes.applied_lists[props.applied_genes.applied_lists.length - 1]]
            }

            let filters = Object.keys(list_of_filters).map(d => FieldRendering(list_of_filters, d, currentQuery.extra  )).filter(s => s!== null)

            if(filters.length>0){
                return( <Box m={1}>{filters}</Box>)
            }
            else
            {
                return  <div>
                    <Typography variant={"subtitle2"}>None</Typography>
                </div>
            }

        }
        else
        {
            return  <div>
                <Typography variant={"subtitle2"}>None</Typography>
            </div>
        }

    }


    if(currentQuery!== undefined && currentQuery){
        let variant_type = ("extra" in currentQuery) ? currentQuery.extra.find(s => s["variant_type"] !== undefined) : undefined;
        return <Box p={2}>
            <Typography variant={"h6"} style={{color: "#4575B4", fontWeight: "bold"}} ><MappedText text="Applied Filters"/></Typography>
            <Box>
                {whichFilterSetApplied()}
            </Box>
            <div>
                {(variant_type!== undefined && variant_type.variant_type!== "GPAP")
                    ? <React.Fragment>
                        <div className={"field_title"} style={{ fontWeight: "bold"}} ><MappedText text="Tag variants"/> </div>
                        <div className={"filter_value"}> {variant_type.variant_type} </div>
                    </React.Fragment>
                    : null
                }

                {getFilterList()}
            </div>
        </Box>
    }
    else{
     return <Box p={2}>
            <Typography variant={"h6"} style={{color: "#4575B4", fontWeight: "bold"}} ><MappedText text="Applied Filters"/></Typography>
            <div>
             <Typography variant={"h6"}>
                 <MappedText text="There is no query selected. Or applied filters are not visible."/>
             </Typography>
            </div>
        </Box>
    }

}




//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.query !== undefined)
        {
            return { query: {... state.query},
                token: state.authorization.token,
                studies: state.studyBucket.studies,
                studySettings: state.studySettings,
                applied_genes: state.geneList,
                currentFilterSet: state.currentFilterSet
            }
        }

    }
}




export default connect(mapStateToProps,null)(QueryFilters);