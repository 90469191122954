import React, { useState, useRef } from "react";
import { Box, Button, Dialog, Typography, TextField, LinearProgress, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTable, toggleRefresh } from "../../../../../../../../reducers/slices/tables/table.slice";
import axios from "axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import MappedText from "../../../mapText";

const FileUpload = () => {
  const dispatch = useDispatch();
  const { isOpenUpload } = useSelector((state) => state.generaltable);
  const token = useSelector((state) => state.authorization.token);
  const fileInputRef = useRef();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [clinicalReportName, setClinicalReportName] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
      if (!validTypes.includes(newFile.type)) {
        setErrorMessage('Unsupported file type. Supported types are .doc, .docx, .pdf, and .txt');
        return;
      }
      const maxSize = 10 * 1024 * 1024;
      if (newFile.size > maxSize) {
        setErrorMessage('File must be less than 10MB.');
        return;
      }
      setFile(newFile);
      setErrorMessage(''); 
    }
  };

  const handleNameChange = (event) => {
    setClinicalReportName(event.target.value);
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("dir_id", id);
      formData.append("origin", "uploaded_by_user");
      formData.append("group_owner", "admin");
      formData.append("clinical_report_name", clinicalReportName);

      const config = {
        headers: {
          'Authorization': token,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      };

      try {
        const response = await axios.post(
          `${window.config.apis_configuration.api_nextgpap_server}/clinical_report`,
          formData,
          config
        );

        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        dispatch(setTable({ option: "isUploadFileTable", value: true }));
        setUploadSuccess(true);
        setUploadProgress(0); 
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      }
    }
  };

  const handleCloseModal = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setErrorMessage('');
    dispatch(setTable({ option: "isOpenUpload", value: false }));
  };

  const handleCloseSuccessDialog = () => {
    setUploadSuccess(false);
    dispatch(toggleRefresh());
    dispatch(setTable({ option: "isOpenUpload", value: false }));
  };

  return (
    <>
      <Dialog
        open={isOpenUpload}
        onClose={handleCloseModal}
      >
        <Box p="15px" display="flex" flexDirection="column" gap="15px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom>
              <MappedText text="Upload Clinical Report" />
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="10px">
            <TextField
              fullWidth
              label={<MappedText text="Report name" />}
              variant="outlined"
              value={clinicalReportName}
              onChange={handleNameChange}
            />
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                component="label"
                startIcon={<AttachFileIcon />}
              >
                <MappedText text="Select File" />
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </Button>
              {file && (
                <Typography style={{ marginLeft: 8 }}>
                  {file.name}
                </Typography>
              )}
            </Box>
            <Typography variant="caption">
              <MappedText text="Supported files: .doc, .docx, .pdf, .txt" />
            </Typography>
            {errorMessage && (
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
            {uploadProgress > 0 && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" color="textSecondary">{`${uploadProgress}%`}</Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="10px">
            <Button
              variant="outlined"
              onClick={handleCloseModal}
            >
              <MappedText text="Cancel" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              startIcon={<CloudUploadIcon />}
              disabled={!file || !clinicalReportName}
            >
              <MappedText text="Upload" />
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={uploadSuccess} onClose={handleCloseSuccessDialog}>
        <Box p="15px" textAlign="center">
          <Typography variant="h6" gutterBottom>
            <MappedText text="File uploaded successfully" />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseSuccessDialog}
          >
            <MappedText text="OK" />
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default FileUpload;