

const TRANSCRIPT_URL_hg19 = "http://feb2014.archive.ensembl.org/Homo_sapiens/Transcript/Summary?r=";
const TRANSCRIPT_URL_hg38 = "http://www.ensembl.org/Homo_sapiens/Transcript/Summary?r="

const getTranscriptLink = (transcriptRow, row) => {

    let url = (window.config.genome_ref === "hg38") ? TRANSCRIPT_URL_hg38 : TRANSCRIPT_URL_hg19
    return url + row.chrom + ":" + row.pos + "-" + row.pos + ";t=" + transcriptRow.transcript_id

}

export default getTranscriptLink;