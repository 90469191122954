




// turn the list of genes into a string;

export function string_geneConcatenation(gene_array){

    // sort

    let gene_array_copy = [...gene_array];

    gene_array_copy.sort()
    let concat_string = '';
    if(gene_array_copy!== undefined){
        if(gene_array_copy.length>1){
            let lastElem = " and " +gene_array_copy.pop();
            return concat_string+gene_array_copy.join(", ")+lastElem;
        }
        else if(gene_array_copy.length ===1){
            return gene_array_copy[0];
        }
        else{
            return concat_string;
        }
    }
}