import React from "react";
import MappedText from "../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";

const gene_field = "gene_name";

// ························· 37 ·························

const by_default = {
    id: 'root',
    name: <MappedText text='Frequent Links'/>,
    children: [
        {
            "id": '1',
            'name': 'dbSNP',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/snp/',
            'input': ['dbSNP'],
            'description': 'dbSNP Reference SNP (rs or RefSNP) number is a locus accession for a variant type assigned by dbSNP',
            'url_appendix': '',
            "variable_category": "rs"
        },
        {
            "id": "2",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom','pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '?dataset=gnomad_r2_1',
            "variable_category": "pos"
        },
        {
            "id": "3",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            'description': '',
            "variable_category": "clinvar_id"

        },
        {
            "id": "4",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg19/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "5",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "6",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg19&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "pos"
        },
        {
            id: "7",
            'name': 'OMIM',
            'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            'input': ['gene_name'],
            'url_appendix': '',
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "8",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },


    ],
    description: <MappedText text="Links curated by the GPAP team"/>
};
const disease_info = {
    id: "root",
    "name": <MappedText text="Disease Information"/>,
    children:[
        {
            id: "1",
            'name': 'OMIM',
            'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            'input': ['gene_name'],
            'url_appendix': '',
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "2",
            'name': 'ClinVar',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '%5Bgene%5D',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "3",
            'name': 'DisGeNET',
            'url_prefix' : 'http://disgenet.org/search/1/',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "4",
            'name': 'Edgar',
            'url_prefix' : ' https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },

    ],
    description: <MappedText text="A list of links releated to disease annotations"/>
};
const variant_info = {
    id: "root",
    "name": <MappedText text="Variant Information"/>,
    children:[
        {
            "id": '0',
            'name': 'dbSNP',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/snp/',
            'input': ['dbSNP'],
            'description': 'dbSNP Reference SNP (rs or RefSNP) number is a locus accession for a variant type assigned by dbSNP',
            'url_appendix': '',
            "variable_category": "rs"
        },
        {
            id: "1",
            'name': 'Ensembl',
            'url_prefix' : 'http://feb2014.archive.ensembl.org/Homo_sapiens/Location/View?r=',
            'input': ['chrom','pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            id: "2",
            'name': 'NCBI',
            'url_prefix' : "http://www.ncbi.nlm.nih.gov/variation/view/?assm=GCF_000001405.25&chr=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            id: "3",
            'name': 'DGVa',
            'url_prefix' : "http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg19/?name=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            id: "4",
            'name': 'GWASCentral',
            'url_prefix' : "http://www.gwascentral.org/generegion?q=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "5",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            'description': '',
            "variable_category": "clinvar_id"
        },
        {
            "id": "6",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom','pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '?dataset=gnomad_r2_1',
            "variable_category": "pos"
        },
        {
            "id": "7",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg19&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "pos"
        },
        {
            "id": "8",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "9",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg19/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "10",
            'name': 'SpliceAI Lookup',
            'url_prefix' : "https://spliceailookup.broadinstitute.org/#variant=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '&hg=37&distance=50&mask=0&precomputed=0',
            "variable_category": "pos"
        },
        {
            "id": "11",
            'name': 'Trap Score',
            'url_prefix' : " http://trap-score.org/Search?query=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "12",
            'name': 'Regulation Spotter',
            'url_prefix' : "https://www.regulationspotter.org/RS/AnalyseVariant91.cgi?var=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "13",
            'name': 'InterVar',
            'url_prefix' : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': "&build=hg19",
            "variable_category": "pos"
        },
        {
            "id": "14",
            'name': 'LOVD',
            'url_prefix' : "https://databases.lovd.nl/shared/variants#order=VariantOnGenome%2FDNA%2CASC&search_VariantOnGenome/DNA=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': "&page_size=100&page=1",
            "variable_category": "codon_change"
        }

    ],
    description: <MappedText text="A list of links releated to variant annotations"/>
};
const actionability = {
    id: "root",
    name: <MappedText text="Actionability"/>,
    children: [ {
        id:  "1",
        'name': 'Treatabolome DB',
        'url_prefix' : 'https://treatabolome.org/#/gene/',
        'url_appendix': '',
        'input': ["gene"],
        'description': '',
        "variable_category": "gene_name"
    },
        {
            id:  "2",
            'name': 'IEMbase',
            'url_prefix' : 'http://www.iembase.com/search/results?page=1&queryText=',
            'url_appendix': '',
            'input': ["gene"],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id:  "3",
            'name': 'ClinGen Knowledge Repo',
            'url_prefix' : 'https://actionability.clinicalgenome.org/ac/',
            'url_appendix': '',
            'input': [],
            'description': '',
            "variable_category": ""
        }
    ],
    description: <MappedText text="Actionability links to identify possible treatments and interventions for a specific gene"/>
};

const gene_info_37 = {
    id: "root",
    name: <MappedText text="Gene Information"/>,
    children: [
        {
            id: "1",
            name: 'Ensembl',
            url_prefix: 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=',
            input: [gene_field],
            description: 'Genome browser providing comprehensive gene annotations.',
            url_appendix: '&db=core',
            variable_category: gene_field
        },
        {
            id: "2",
            name: 'PubMed',
            url_prefix: 'http://www.ncbi.nlm.nih.gov/pubmed/?term=',
            url_appendix: '+gene',
            input: [gene_field],
            description: 'Database for exploring scientific literature related to genes.',
            variable_category: gene_field
        },
        {
            id: "3",
            name: 'Entrez',
            url_prefix: 'http://www.ncbi.nlm.nih.gov/gene/?term=%28',
            url_appendix: '%5BGene+Name%5D%29+AND+9606%5BTaxonomy+ID%5D',
            input: [gene_field],
            description: 'Database providing comprehensive information about genes.',
            variable_category: gene_field
        },
        {
            id: "4",
            name: 'GeneCards',
            url_prefix: 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database offering detailed information about genes and their associated diseases.',
            variable_category: gene_field
        },
        {
            id: "5",
            name: 'COSMIC',
            url_prefix: 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about genes implicated in cancer.',
            variable_category: gene_field
        },
        {
            id: "6",
            name: 'GTEx',
            url_prefix: 'http://www.gtexportal.org/home/gene/',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing gene expression data across different tissues.',
            variable_category: gene_field
        },
        {
            id: "7",
            name: 'gnomAD',
            url_prefix: 'http://gnomad.broadinstitute.org/gene/',
            url_appendix: '?dataset=gnomad_r2_1',
            input: [gene_field],
            description: 'Database providing allele frequencies for genes.',
            variable_category: gene_field
        },
        {
            id: "8",
            name: 'GWAS Central',
            url_prefix: 'http://www.gwascentral.org/generegion?q=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database containing genomic regions associated with traits from GWAS studies.',
            variable_category: gene_field
        },
        {
            id: "9",
            name: 'ATLAS',
            url_prefix: 'http://www.proteinatlas.org/search/',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about protein expression in different tissues.',
            variable_category: gene_field
        },
        {
            id: "10",
            name: 'eDGAR',
            url_prefix: 'https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about gene-disease associations.',
            variable_category: gene_field
        },
        {
            id: "11",
            name: 'ClinGen',
            url_prefix: 'https://search.clinicalgenome.org/kb/genes?page=1&size=50&search=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing clinical annotations for genes.',
            variable_category: gene_field
        },
        {
            id: "12",
            name: 'HGMD',
            url_prefix: 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database for exploring genetic mutations associated with inherited diseases.',
            variable_category: gene_field
        },
        {
            id: "13",
            name: 'GeneReviews',
            url_prefix: 'https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing comprehensive reviews of genes and genetic disorders.',
            variable_category: gene_field
        },
        {
            id: "14",
            name: 'LOVD',
            url_prefix: "https://databases.lovd.nl/shared/transcripts/",
            input: [gene_field, "transcript_id"],
            description: 'Database providing information about genetic variants and associated phenotypes.',
            url_appendix: "",
            variable_category: gene_field
        },
        {
            "id": "15",
            'name': 'genomeRNAi',
            'url_prefix' : "http://www.genomernai.org/v17/genedetails/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        },
        {
            "id": "16",
            'name': 'DisGeNet',
            'url_prefix' : "https://www.disgenet.org/browser/1/1/0/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        }
    ],
    description: <MappedText text="A list of links related to gene annotations"/>
};
const gene_info_38 = {
    id: "root",
    "name": <MappedText text="Gene Information"/>,
    children:[
        {
            id: "1",
            'name': 'Ensembl',
            'url_prefix' : 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=',
            'input': [gene_field],
            'description': '',
            'url_appendix': '&db=core',
            "variable_category": gene_field
        },
        {
            id: "2",
            'name': 'PubMed',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/pubmed/?term=',
            'url_appendix': '+gene',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "3",
            'name': 'Entrez',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/gene/?term=%28',
            'url_appendix': '%5BGene+Name%5D%29+AND+9606%5BTaxonomy+ID%5D',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "4",
            'name': 'GeneCards',
            'url_prefix' : 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "5",
            'name': 'COSMIC',
            'url_prefix' : 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "6",
            'name': 'GTEx',
            'url_prefix' : 'http://www.gtexportal.org/home/gene/',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "7",
            'name': 'gnomAD',
            'url_prefix' : 'http://gnomad.broadinstitute.org/region/',
            'url_appendix': '?dataset=gnomad_r3',
            'input': ["SV_start"],
            'description': '',
            "variable_category": "SV_start"
        },
        {
            id: "8",
            'name': 'GWAS Central',
            'url_prefix' : 'http://www.gwascentral.org/generegion?q=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "9",
            'name': 'ATLAS',
            'url_prefix' : 'http://www.proteinatlas.org/search/',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "10",
            'name': 'eDGAR',
            'url_prefix' : 'https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "11",
            'name': 'ClinGen',
            'url_prefix' : 'https://search.clinicalgenome.org/kb/genes?page=1&size=50&search=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "12",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "13",
            'name': 'GeneReviews',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "14",
            'name': 'LOVD',
            'url_prefix' : "https://databases.lovd.nl/shared/transcripts/",
            'input': [gene_field, "transcript_id"],
            'description': '',
            'url_appendix': "",
            "variable_category": gene_field
        },
        {
            "id": "15",
            'name': 'genomeRNAi',
            'url_prefix' : "http://www.genomernai.org/v17/genedetails/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        },
        {
            "id": "16",
            'name': 'DisGeNet',
            'url_prefix' : "https://www.disgenet.org/browser/1/1/0/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        }
    ],
    description: <MappedText text="A list of links releated to gene annotations"/>
};

const gene_info = {
    id: "root",
    "name": <MappedText text="Gene Information"/>,
    children:[
        {
            id: "1",
            'name': 'Ensembl',
            'url_prefix' : 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=',
            'input': ['gene_name'],
            'description': '',
            'url_appendix': '&db=core',
            "variable_category": "gene_name"
        },
        {
            id: "2",
            'name': 'PubMed',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/pubmed/?term=',
            'url_appendix': '+gene',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "3",
            'name': 'Entrez',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/gene/?term=%28',
            'url_appendix': '%5BGene+Name%5D%29+AND+9606%5BTaxonomy+ID%5D',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "4",
            'name': 'GeneCards',
            'url_prefix' : 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "5",
            'name': 'COSMIC',
            'url_prefix' : 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "6",
            'name': 'GTEx',
            'url_prefix' : 'http://www.gtexportal.org/home/gene/',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "7",
            'name': 'gnomAD',
            'url_prefix' : 'http://gnomad.broadinstitute.org/gene/',
            'url_appendix': '?dataset=gnomad_r2_1',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "8",
            'name': 'GWAS Central',
            'url_prefix' : 'http://www.gwascentral.org/generegion?q=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "9",
            'name': 'ATLAS',
            'url_prefix' : 'http://www.proteinatlas.org/search/',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "10",
            'name': 'eDGAR',
            'url_prefix' : 'https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            id: "11",
            'name': 'ClinGen',
            'url_prefix' : 'https://search.clinicalgenome.org/kb/genes?page=1&size=50&search=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "12",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "13",
            'name': 'GeneReviews',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "14",
            'name': 'LOVD',
            'url_prefix' : "https://databases.lovd.nl/shared/transcripts/",
            'input': ["gene_name", "transcript_id"],
            'description': '',
            'url_appendix': "",
            "variable_category": "gene_name"
        },
        {
            "id": "15",
            'name': 'genomeRNAi',
            'url_prefix' : "http://www.genomernai.org/v17/genedetails/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        },
        {
            "id": "16",
            'name': 'DisGeNet',
            'url_prefix' : "https://www.disgenet.org/browser/1/1/0/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        }
    ],
    description: <MappedText text="A list of links releated to gene annotations"/>
};
const pathwayInfo = {
    id: "root",
    "name": <MappedText text="Pathway Information"/>,
    children: [ {
        id:  "1",
        'name': 'WikiPathways',
        'url_prefix' : 'http://www.wikipathways.org//index.php?query=',
        'url_appendix': '&species=Homo+sapiens&title=Special%3ASearchPathways&doSearch=1',
        'input': ['gene_name'],
        'description': '',
        "variable_category": "gene_name"
    }],
    description: <MappedText text="A list of links releated to pathways annotations"/>
};
const data_discovery_37 = {
    id: "root",
    name: <MappedText text="Data Discovery"/>,
    children: [ {
        id:  "1",
        'name': 'GA4GH beacon',
        'url_prefix' : 'https://beacon-network.org//#/search?chrom=',
        'url_appendix': "&rs=GRCh37",
        'input': ['chrom', "pos", "ref", "alt"],
        'description': '',
        "variable_category": "pos"
    },
        {
            id:  "2",
            'name': 'PubCaseFinder',
            'url_prefix' : 'https://pubcasefinder.dbcls.jp/search_disease/phenotype:',
            'url_appendix': '',
            'input': ['features', "gene"],
            'description': '',
            "variable_category": "gene_name"
        }
    ],
    description:<MappedText text="A list of links that may allow new discoveries"/> 
};


// ························· 38 ·························

const by_default_38 = {
    id: 'root',
    name: <MappedText text='Frequent Links'/>,
    children: [
        {
            "id": '1',
            'name': 'dbSNP',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/snp/',
            'input': ['dbSNP'],
            'description': 'dbSNP Reference SNP (rs or RefSNP) number is a locus accession for a variant type assigned by dbSNP',
            'url_appendix': '',
            "variable_category": "rs"
        },
        {
            "id": "2",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom','pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '?dataset=gnomad_r3',
            "variable_category": "pos"
        },
        {
            "id": "3",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            'description': '',
            "variable_category": "clinvar_id"

        },
        {
            "id": "4",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg38/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "5",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "6",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "pos"
        },
        {
            id: "7",
            'name': 'OMIM',
            'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            'input': ['gene_name'],
            'url_appendix': '',
            'description': '',
            "variable_category": "gene_name"
        },
        {
            "id": "8",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '-hg38',
            "variable_category": "pos"
        },


    ],
    description: <MappedText text="Links curated by the GPAP team"/>
};

const variant_info_38 = {
    id: "root",
    "name": <MappedText text="Variant Information"/>,
    children:[
        {
            "id": '0',
            'name': 'dbSNP',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/snp/',
            'input': ['dbSNP'],
            'description': 'dbSNP Reference SNP (rs or RefSNP) number is a locus accession for a variant type assigned by dbSNP',
            'url_appendix': '',
            "variable_category": "rs"
        },
        {
            id: "1",
            'name': 'Ensembl',
            'url_prefix' : 'http://www.ensembl.org/Homo_sapiens/Location/View?r=',
            'input': ['chrom','pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            id: "2",
            'name': 'NCBI',
            'url_prefix' : "https://www.ncbi.nlm.nih.gov/variation/view/?assm=GCF_000001405.40&chr=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            id: "3",
            'name': 'DGVa',
            'url_prefix' : "http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            id: "4",
            'name': 'GWASCentral',
            'url_prefix' : "http://www.gwascentral.org/generegion?q=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "5",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            'description': '',
            "variable_category": "clinvar_id"
        },
        {
            "id": "6",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom','pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '?dataset=gnomad_r3',
            "variable_category": "pos"
        },
        {
            "id": "7",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "pos"
        },
        {
            "id": "8",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '-hg38',
            "variable_category": "pos"
        },
        {
            "id": "9",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg38/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id": "10",
            'name': 'SpliceAI Lookup',
            'url_prefix' : "https://spliceailookup.broadinstitute.org/#variant=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '&hg=38&distance=50&mask=0&precomputed=0',
            "variable_category": "pos"
        },
        {
            "id": "11",
            'name': 'Trap Score',
            'url_prefix' : " http://trap-score.org/Search?query=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        /*   {
               "id": "12",
               'name': 'Regulation Spotter',
               'url_prefix' : "https://www.regulationspotter.org/RS/AnalyseVariant91.cgi?var=",
               'input': ['chrom', 'pos', 'ref', "alt"],
               'description': '',
               'url_appendix': '',
               "variable_category": "pos"
           },*/
        {
            "id": "13",
            'name': 'InterVar',
            'url_prefix' : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '&build=hg38',
            "variable_category": "pos"
        },
        {
            "id": "14",
            'name': 'LOVD',
            'url_prefix' : "https://databases.lovd.nl/shared/variants#order=VariantOnGenome%2FDNA%2CASC&search_VariantOnGenome/DNA/hg38=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': "&page_size=100&page=1",
            "variable_category": "codon_change"
        }

    ],
    description: <MappedText text="A list of links releated to variant annotations"/>
};
const data_discovery_38 = {
    id: "root",
    name: "Data Discovery",
    children: [ {
        id:  "1",
        'name': 'GA4GH beacon',
        'url_prefix' : 'https://beacon-network.org//#/search?chrom=',
        'url_appendix': '&rs=GRCh38',
        'input': ['chrom', "pos", "ref", "alt"],
        'description': '',
        "variable_category": "pos"
    },
        {
            id:  "2",
            'name': 'PubCaseFinder',
            'url_prefix' : 'https://pubcasefinder.dbcls.jp/search_disease/phenotype:',
            'url_appendix': '',
            'input': ['features', "gene"],
            'description': '',
            "variable_category": "gene_name"
        }
    ],
    description: <MappedText text="A list of links that may allow new discoveries"/>
};


//············ Both ·························

const ACMG_37 = {
    id: "root",
    name: <MappedText text=" ACMG guidelines"/>,
    children: [
        {
            "id":  "1",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg19/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id":  "2",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id":  "1",
            'name': 'InterVar',
            'url_prefix' : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        }]

}

const ACMG_38 = {
    id: "root",
    name: <MappedText text=" ACMG guidelines"/>,
    children: [
        {
            "id":  "1",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg38/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id":  "2",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '-hg38',
            "variable_category": "pos"
        },
        {
            "id":  "1",
            'name': 'InterVar',
            'url_prefix' : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        }]

}

const pharmaco = {
    id: "root",
    name: <MappedText text="Pharmacogenomics "/>,
    children: [
        {
            "id":  "1",
            'name': 'PharmGKB ',
            'url_prefix' : "https://www.pharmgkb.org/search?query=",
            'input': ["gene"],
            'description': '',
            'url_appendix': '',
            "variable_category": "gene_name"
        },
        {
            "id":  "2",
            'name': 'PharmVar',
            'url_prefix' : " https://www.pharmvar.org/gene/",
            'input': ["gene"],
            'description': '',
            'url_appendix': '',
            "variable_category": "gene_name"
        }]

}


const configuration_tree = {

    "by default": (window.config.genome_ref === "hg38") ? by_default_38 : by_default,
    "Disease Information": disease_info,
    "Variant Information": (window.config.genome_ref === "hg38") ? variant_info_38 : variant_info  ,
    "Gene Information":(window.config.genome_ref === "hg38") ? gene_info_38 : gene_info_37 ,
    "Pathway Information": pathwayInfo,
    "Data Discovery": (window.config.genome_ref === "hg38") ? data_discovery_38 : data_discovery_37 ,
    "Actionability": actionability,
    "ACMG": (window.config.genome_ref === "hg38") ? ACMG_38 : ACMG_37,
    "Pharmacogenomics": pharmaco
};




const external_resources_config  = [
    {key: 'pos', values :[
        {
            'label': 'Ensembl',
            'url_prefix' : 'http://feb2014.archive.ensembl.org/Homo_sapiens/Location/View?r=',
            'input': ['pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'gnomAD',
            'link' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'UCSC',
            'link' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg19&position=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'NCBI',
            'link' : "http://www.ncbi.nlm.nih.gov/variation/view/?assm=GCF_000001405.25&chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'DGVa',
            'link' : "http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg19/?name=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'GWAS Central',
            'link' : "http://www.gwascentral.org/generegion?q=chr",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'GA4GH',
            'link' : "https://beacon-network.org//#/search?chrom=",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },
        {
            'label': 'VarSome',
            'link' : "https://varsome.com/variant/hg19/",
            'input': ['chrom', 'pos_start', 'pos_end'],
            'description': '',
            'url_appendix': '',
        },


    ]},
    {key: 'dbsSNP', values: [
            {
                'label': 'dbSNP',
                'url_prefix' : 'http://www.ncbi.nlm.nih.gov/SNP/snp_ref.cgi?rs=rs',
                'input': ['dbSNP'],
                'description': '',
                'url_appendix': '',
            }
        ]},
    {key: 'gene_name', values: [
            {
                'label': 'OMIM',
                'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
                'input': ['gene_name'],
                'url_appendix': '',
                'description': ''
            },
            {
                'label': 'Genetics Home Reference',
                'url_prefix': "https://ghr.nlm.nih.gov/search?query=",
                'input': ['gene_name'],
                'url_appendix': '',
                'description': ''
            },
            {
                'label': 'NCBI Gene Reviews',
                'url_prefix': " https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=",
                'input': ['gene_name'],
                'url_appendix': '',
                'description': ''
            },

            {
                'label': 'Ensembl',
                'url_prefix' : 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g="',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'PubCaseFinder',
                'url_prefix' : 'https://pubcasefinder.dbcls.jp/search_disease/phenotype:/gene:HGNC:',
                'url_appendix': '/page:1%2C1/size:10%2C10',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'PubMed',
                'url_prefix' : 'http://www.ncbi.nlm.nih.gov/pubmed/?term=BTN2A1+gene',
                'url_appendix': '+gene',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'HGMD',
                'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'Entrez',
                'url_prefix' : 'http://www.ncbi.nlm.nih.gov/gene/?term=%28"BTN2A1',
                'url_appendix': '%5BGene+Name%5D%29+AND+9606%5BTaxonomy+ID%5D',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'GeneCards',
                'url_prefix' : 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'COSMIC',
                'url_prefix' : 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'ClinVar',
                'url_prefix' : 'http://www.ncbi.nlm.nih.gov/clinvar/?term=',
                'url_appendix': '%5Bgene%5D',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'ExAC',
                'url_prefix' : 'http://exac.broadinstitute.org/awesome?query=',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'GTEx',
                'url_prefix' : 'http://www.gtexportal.org/home/gene/',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'gnomAD',
                'url_prefix' : 'http://gnomad.broadinstitute.org/gene/',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'GWAS Central',
                'url_prefix' : 'http://www.gwascentral.org/generegion?q=',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'ATLAS',
                'url_prefix' : 'http://www.proteinatlas.org/search/',
                'url_appendix': '',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'WikiPathways',
                'url_prefix' : 'http://www.wikipathways.org//index.php?query=',
                'url_appendix': '&species=Homo+sapiens&title=Special%3ASearchPathways&doSearch=1',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'Open PHACTS',
                'url_prefix' : 'https://explorer.openphacts.org/search?query=',
                'url_appendix': '%20Homo%20sapiens',
                'input': ['gene_name'],
                'description': ''
            },
            {
                'label': 'DisGeNET',
                'url_prefix' : 'http://disgenet.org/search/1/',
                'url_appendix': '/',
                'input': ['gene_name'],
                'description': ''
            },

        ]},
];



export {configuration_tree, external_resources_config} ;