import React from "react";
import {GeneBox, TitleAndDescription} from "./elements";
import ShowGenotypeForm from "../analysis-page/components/GenotypeBuilder/components/GenotypeForm/showGenotypeForm";
import Paper from "@material-ui/core/Paper";
import useSectionStyles from "./styles";
import StartFilters
    from "../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/start-filters/StartFilters";
import CohortSelect from "../cohort-selection/CohortSelect";
import {AlertInfo} from "../analysis-page/components/samples-section/SelectionInfo";


function Inheritance(props) {

    const classes = useSectionStyles()

    return <Paper className={classes.paper_class}>
        <TitleAndDescription title={"Select Inheritance Mode"} description={"Edit Inheritance"}/>
        <div>
            <ShowGenotypeForm
                inheritance_key={"custom"}
                search_across={true}
                analysis_type={props.analysisType}
            />
        </div>
    </Paper>


}


function GeneSelection(props) {
    const classes = useSectionStyles()
    return <Paper className={classes.paper_class}>
      {/*  <TitleAndDescription title={"Select Genes"} description={"Search interesting genes from different databases"}/>*/}

        {(props.genes.length !== 0) ? <div style={{
            float: "left",
            width: "100%"
        }}><AlertInfo title={"Info"} severity={"info"} message={"There are " + props.genes.length + " genes selected"}/>
        </div> : null}
        <GeneBox/>
    </Paper>
}

function FilterSection(props) {
    const classes = useSectionStyles()
    return <Paper className={classes.paper_class}>
        <TitleAndDescription title={"Create a Filter Set"}
                             description={"These filters may considerably reduce the number of results of the query"}/>
        <StartFilters gene_filters={props.geneTab}/>
    </Paper>
}


function CohortSelectSection(props) {
    const classes = useSectionStyles()
    return <Paper className={classes.paper_class}>
        <CohortSelect
        cohort_id_from_url={props.cohort_id_from_url}
        assignAnalysis={props.assignAnalysis}
        getSelectedCohort={props.getSelectedCohort}
    />
    </Paper>

}


export {Inheritance, GeneSelection, FilterSection, CohortSelectSection}
