import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf.mjs';


const setupPDFWorker = async () => {
    if (!GlobalWorkerOptions.workerSrc) {
        const worker = await import('pdfjs-dist/legacy/build/pdf.worker.mjs');
        const workerUrl = URL.createObjectURL(new Blob([worker.default]));
        GlobalWorkerOptions.workerSrc = workerUrl;
    }
};

function PDFViewer({ base64String }) {
    const canvasRef = useRef();
    const [isWorkerReady, setWorkerReady] = useState(false);

    useEffect(() => {
        setupPDFWorker().then(() => setWorkerReady(true));
    }, []);

    useEffect(() => {
        if (isWorkerReady) {
            const loadingTask = getDocument({ data: atob(base64String) });
            loadingTask.promise.then(pdf => {
                console.log('PDF loaded');

                pdf.getPage(1).then(page => {
                    const viewport = page.getViewport({ scale: 1.5 });
                    const canvas = canvasRef.current;
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: canvas.getContext('2d'),
                        viewport: viewport
                    };
                    page.render(renderContext).promise.then(() => {
                        console.log('Page rendered');
                    });
                });
            }).catch(reason => console.error('Error loading PDF: ', reason));
        }
    }, [base64String, isWorkerReady]);

    return <canvas ref={canvasRef}></canvas>;
}

export default PDFViewer;
