
import React, {Fragment, useState} from "react";
import {Grid, Skeleton, Typography} from "@mui/material";
import Genomics from "../../dashboard/components/genomics-dashboard/genomics/Genomics";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";



function GeneticFindings(props) {


    const renderView = () => {
        return <Fragment>
            <Grid item lg={12}>
                <Genomics instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>
            </Grid>
        </Fragment>
    }

    return <Grid container direction={"row"}>
        <Grid item lg={12}>
        <Typography variant={"h5"} style={{  fontWeight: "700", marginTop: "20px"}}><MappedText text={"Genetic Findings"}/></Typography>
        </Grid>
        {renderView()}

    </Grid>


}

export default GeneticFindings;
