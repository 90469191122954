




import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
      /*  minWidth: 650,*/
    },
});



export default function FiltersTable(props) {

    const classes = useStyles();
    let data = [];

    if(Array.isArray(props.filters)){
        data= props.filters.map(filter => {

            let my_string = filter.val;
            let val= filter.val;
            let info= "--"
            if(typeof val === 'boolean') my_string = JSON.stringify(val);
            if(Array.isArray(val)){
                my_string= val.toString();
            }
            else if(typeof val === "object"){
                // ontology
                let keys = Object.keys(val);

                keys.forEach(function(d){
                    if(val[d].length!== 0 ){
                        my_string = d.toUpperCase();
                        let list = val[d].map(item => item.name);
                        info = list.toString();
                    }
                })
            }


            return {
                category:filter.category,
                filter_key : filter.key,
                val : my_string,
                value :filter.value,
                info : info
            }
        });
    }



    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Filter Key</TableCell>
                        <TableCell align="center">Value</TableCell>
                       {/* <TableCell align="center">Count</TableCell>
                        <TableCell align="center">Info</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell align="center">{row.filter_key}</TableCell>
                            <TableCell align="center">{row.val}</TableCell>
                         {/*   <TableCell align="center">{row.value}</TableCell>
                            <TableCell align="center">{row.info}</TableCell>*/}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}