import './pedigree_lib/pedigreejs.js';
import './pedigree_lib/pedigreejs.min.css';
import $ from 'jquery';

/*var parent_width = $('#ped').parent().width();*/
/*var margin = ($(window).width()-parent_width > 10 ? 100 : 30);*/
/*var svg_width = (parent_width > 750 ? (parent_width*8/12 - margin) : parent_width- margin);*/

const dataset =
    [ {"famid":"familia","name": "m21", "sex": "M", "top_level": true},
{"famid":"familia","name": "f21", "sex": "F", "top_level": true},
{"famid":"familia","name": "ch1", "sex": "F", "mother": "f21", "father": "m21", "breast_cancer":  true, "proband": true}];


let opts = {
    'targetDiv': 'ped',
     consanguinity_pairs:[],
    'btn_target': 'cancer_history',
    'store_type': 'array',
    'height': 600,
    'width': 1000,
    'symbol_size': 50,
    'font_size': '0.9em',
    'font_family': 'sans-serif',
    'font_weight': 400,
    'edit': true,
    'background': 'white',
    'node_background': '#fff',
    'DEBUG': (pedigree_util.urlParam('debug') !== null)
};

$('#opts').append(JSON.stringify(opts, null, 4));


var local_dataset = pedcache.current(opts);

if (local_dataset !== undefined && local_dataset !== null) {
                opts.dataset = local_dataset;
}

else {
           opts.dataset = dataset;
        }

opts= ptree.build(opts);

export {dataset,opts};
