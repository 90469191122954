import {Paragraph, TextRun, HeadingLevel, Numbering, AlignmentType} from 'docx';
import { renderToString } from '../../../create-report/shared-components/renderToString';


const WriteTechnicalLimitations = (title, technicalLimitations, experimentType)=>{

  const limitationsText = technicalLimitations[experimentType];
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: renderToString("TECHNICAL LIMITATIONS"),
            size: 24,
            color: '#000000',
            bold: true,
          }),
        ],
        
      }),
      new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: limitationsText,
            size: 24,
            font: 'calibri',

          }),
        ],
      }),
    ];
  };

export default WriteTechnicalLimitations;