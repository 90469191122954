



import React, {Component} from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./entry_page.css";
import * as StudyActions from "../dashboard/reducers/study_actions";
import Grid from "@material-ui/core/Grid";
import {get_filter_set, get_study_analyses} from "../../services/api/manage_study";
import {Redirect} from "react-router-dom";
import {StudyType} from "./StudyType";
import StudyView from "./StudyView";
import Box from "@material-ui/core/Box";
import * as Actions from "./create-study/analysis-page/reducers/sample_actions";
import * as General_Actions from "./../../actions";
import PermissionsBox from "./permission-box/PermissionsBox";
import Divider from "@material-ui/core/Divider";


class StudyPage extends Component {


    constructor(props){
        super(props);

        // Personalize View;
        this.state = {
            dimensions: null,
            use_case: 'entry-page',
            studyID: null,
            study_load: false
        };

        this.selectView = this.selectView.bind(this);
        this.onLoad = this.onLoad.bind(this);

    }

    componentDidMount() {

        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight,
            },
        });
    }

    selectView(viewMode){
        this.setState({use_case: viewMode, studyID: localID});
    }



    onLoad(study){
        //load study ....

        let token = this.props.token;
        const self = this;
        const cohort_server = window.config.apis_configuration.api_nextgpap_server;

        this.props.actions.resetSamples();
        this.props.general_actions.reset_selected_cohort();

        let data = {
            study_id:study.id
        };

        //study.samples = undefined;

        if(study.samples!== undefined){


            study.samples.forEach(function(sample){
                if(sample.sample_id!== "ALL_SAMPLES"){
                    self.props.actions.setSamples(sample.sample_id, sample.pheno_id, sample.sample_type, sample.affectedStatus);
                }
                else{
                    self.props.actions.setSearchAcrossSample(sample);
                }

            });
        }

        get_study_analyses(cohort_server, token, data)
            .then(r => r.json())
            .then(function(d){
                d.analyses.forEach(function(analysis){
                    analysis.queries.forEach(function(Q){
                        get_filter_set(cohort_server, token,{"filter_set_id": Q.filter_set_id})
                            .then(r=> r.json())
                            .then(function(d){
                                console.log(d.data);
                                Q.filter_set = d.data;
                            })
                    })
                });

                const studyLoad = { study: study, analyses: d.analyses.filter(analysis => analysis.queries.length!==0)};
                self.setState({
                    study_load: studyLoad,
                });

                self.props.studyActions.setStudySettings({study_id_db: study.id, study_status: true});

            })



    }


    renderContent() {

        return (<Box className="main-content"
                     style={{ margin: "1%"}}>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item lg={11}>
                        <Box p={1}>
                            <StudyType />
                        </Box>
                    </Grid>
                    <Grid item lg={11}>
                        <Box p={0}>
                            <StudyView loadStudy={this.onLoad}/>
                        </Box>
                    </Grid>
                    <Grid item lg={12}>
                        <Divider/>
                    </Grid>


                </Grid>
               </Box>)

    }


    render() {
        const { dimensions } = this.state;

        if(this.state.study_load){
            return <Redirect to={{pathname: "study_overview", state: { studyData: this.state.study_load} }}/>;
        }
        else if(this.state.studyType === "family_trio"){
            return <Redirect to={{pathname: "new_study", state: { studyData: this.state.study_load} }}/>;

        }
        else{
            return (
                <div className="myinnerContainer" ref={el => (this.container = el)}>
                    {dimensions && this.renderContent()}
                </div> )
        }





    }
}




function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    actions: bindActionCreators(Actions, dispatch),
    general_actions: bindActionCreators(General_Actions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(StudyPage);