import MappedText from "../../shared-components/mapText";
import React from "react";


function getReferences(analysisType){

    let references = [];
    if ( typeof analysisType !=="string"){
        return references;

    }else{

        if (analysisType.includes("somatic")) {

            references = [
                 <MappedText text="Horak P, Griffith M, Danos AM, et al. Standards for the classification of pathogenicity of somatic variants in cancer (oncogenicity): Joint recommendations of Clinical Genome Resource (ClinGen), Cancer Genomics Consortium (CGC), and Variant Interpretation for Cancer Consortium (VICC) [published correction appears in Genet Med. 2022 Sep;24(9):1991]. Genet Med. 2022;24(5):986-998"/>,
                 <MappedText text="Li MM, Datto M, Duncavage EJ, et al. Standards and Guidelines for the Interpretation and Reporting of Sequence Variants in Cancer: A Joint Consensus Recommendation of the Association for Molecular Pathology, American Society of Clinical Oncology, and College of American Pathologists. J Mol Diagn. 2017;19(1):4-23."/>
            ];
        } else if (analysisType === "germline") {
            references = [
                <MappedText text="Richards S, Aziz N, Bale S, et al. ACMG Laboratory Quality Assurance Committee. Standards and guidelines for the interpretation of sequence variants:a joint consensus recommendation of the American College of Medical Genetics and Genomics and the Association for Molecular Pathology. Genet Med. 2015 May;17(5):405-24"/>,
                <MappedText text="Riggs ER, Andersen EF, Cherry AM, et al. Technical standards for the interpretation and reporting of constitutional copy-number variants: a joint consensus recommendation of the American College of Medical Genetics and Genomics (ACMG) and the Clinical Genome Resource (ClinGen). Genet Med. 2020 Feb;22(2):245-257. "/>
            ];
        } else if (analysisType === "somatic_fusion_analysis") {
            references = [
                <MappedText text="Horak P, Griffith M, Danos AM, et al. Standards for the classification of pathogenicity of somatic variants in cancer (oncogenicity): Joint recommendations of Clinical Genome Resource (ClinGen), Cancer Genomics Consortium (CGC), and Variant Interpretation for Cancer Consortium (VICC) [published correction appears in Genet Med. 2022 Sep;24(9):1991]. Genet Med. 2022;24(5):986-998 "/>,
                <MappedText text="Li MM, Datto M, Duncavage EJ, et al. Standards and Guidelines for the Interpretation and Reporting of Sequence Variants in Cancer: A Joint Consensus Recommendation of the Association for Molecular Pathology, American Society of Clinical Oncology, and College of American Pathologists. J Mol Diagn. 2017;19(1):4-23."/>
            ];
        }

        return references
    }
}

export default getReferences;