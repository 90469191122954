import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {GPAP_Button} from "../../gpap-lib/components/Button_Collection";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";

import {mme_send_mail} from "../../services/api/get";

const useStyles = makeStyles({
  table: {
    minWidth: 450,
  },
});


function MMEResultsTable(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [Msg, setMsg] = useState(null);

  const [patient, setPatient] = React.useState({});

  //Props data
  let rows = props.queryResults
  let mailInfo = props.mailInfo

  function handleErrors(response) {
    if (!response.ok) {
        setMsg("An error occured")
        throw Error(response.statusText);
    }
    return response;
  }

  const handleClickOpen = (entry) => {
    setPatient(entry);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = ()  => {
    setOpen(false);
    
    let mail_data = {"participant_query":mailInfo.participant, "gene_query": mailInfo.gene, "hpo_query": mailInfo.hpo, "sample_query":mailInfo.sample,"target": mailInfo.target ,"result": patient }
    
    mme_send_mail(config.api_endpoint, props.token, mail_data)
    .then(handleErrors)
    .then(resp => {
      if(resp){
        setMsg("Mail was sent")
    }
    })
  };


  return (
     <div>

    {Msg &&
    <Typography variant="h4" style={{padding:"0px 250px"}}>
      {Msg}
    </Typography> 
    }   
    
    <Typography variant="h6" style={{}}>
    Matches found: {rows.length}               
    </Typography>

    <TableContainer style={{ maxHeight: 650 }} component={Paper}>

      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Contact</TableCell>
            <TableCell align="right">Patient</TableCell>
            <TableCell align="right">Score</TableCell>
            <TableCell align="right">Submitter</TableCell>
            <TableCell align="right">Phenotype</TableCell>
            <TableCell align="right">Genes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row"> <GPAP_Button onClick={() => handleClickOpen(row)}> Contact </GPAP_Button>  </TableCell>
              <TableCell align="right">{row.patient.id}</TableCell>
              <TableCell align="right">{row.score.patient.toFixed(2)}</TableCell>
              <TableCell align="right">{row.patient.contact.name}</TableCell>
              <TableCell align="right">{row.patient.features && row.patient.features.map((item) => ( <div key={item.id}>{item.label}</div> ))} </TableCell>
              <TableCell align="right">{row.patient.genomicFeatures && row.patient.genomicFeatures.map((item) => ( <div key={item.gene.id}>{item.gene.id}</div> ))} </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
     </TableContainer>


     <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Initiate contact?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          An email will be sent to yourself and the submitter of the matched patient record in order to initiate the contact. The email will contain the matched gene and HPO terms and the patient ID from RD-Connect and the other platform. A copy of this email will also be sent to platform@rd-connect.eu to keep track of the matches initiated through MatchMaker Exchange.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>

     </div>
  );
}

function mapStateToProps (state) {
  if(state.authorization!==undefined)
  {
      return {token: state.authorization.token}
  }
}


export default connect(mapStateToProps)(MMEResultsTable);
