

import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

import FindReplaceIcon from '@material-ui/icons/FindReplace';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PropTypes from "prop-types";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GPAP_Tooltip from "../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";

import ContactDialog from "../ContactDialog";
import {GPAP_Button} from "../../../../../../../../../../../gpap-lib/components/Button_Collection";
import Box from "@material-ui/core/Box";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        {
            color: "#3182bd",
            backgroundColor: "#deebf7"
        },
        title: {
        flex: '1 1 100%',
    },
}));

const CohortTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const [ContactFlag, setContactFlag] = React.useState(false);

    const { selected, numSelected } = props;

    
    React.useEffect(() => {

        setContactFlag(false)


    }, [selected]);

    const handleClickOpen = () => {

        setContactFlag(true)
      };
    
   

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {numSelected > 0 ? (
                    <React.Fragment>
                            <Box p={1}>
                                <GPAP_Tooltip title="Contact Owner">
                                <GPAP_Button onClick={handleClickOpen} aria-label="contact" startIcon={ <ContactMailIcon />}>
                                    Contact
                                </GPAP_Button>
                            </GPAP_Tooltip>
                        </Box>
                            <Box p={1}>
                                <GPAP_Tooltip title="Show the variants of the selected participant">
                                    <GPAP_Button onClick={props.loadVariants}
                                                 aria-label="contact"
                                                 startIcon={ <FindReplaceIcon />}>
                                        see Participant Variants
                                    </GPAP_Button>
                                </GPAP_Tooltip>
                            </Box>
                    </React.Fragment>
            ) : (
                <Typography className={classes.title} variant="body1" id="tableTitle" component="div">
                    Select a participant to query for associated variants. The query includes the selected gene list
                </Typography>
            )}

            {numSelected > 0 ? (
                <React.Fragment>

                 {ContactFlag &&
                 <ContactDialog  contactFlag = {ContactFlag} selected={selected}/> 
                 }

                </React.Fragment>
                )
                : null}

        </Toolbar>
    );
};

CohortTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};



export default CohortTableToolbar;

