

import React from "react";
import {checkIfNameExists, getText} from "../../../../../side-panels/filter-panel/components/query-top-bar/func";
import MappedText
    from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



const getHelperText = (currentStudy, studyDetails, list_of_studies) => {

    let actionType = (currentStudy && currentStudy.id !== undefined)
        ? "update"
        : "save";

    if (currentStudy.studyName !== studyDetails["studyName"]) {
        return (checkIfNameExists(studyDetails["studyName"], list_of_studies, actionType))
            ? getText(studyDetails["studyName"],
                "study",
                actionType)
            : <MappedText text="Study title. Max 50 chars"/>;
    } else {
        return <MappedText text="Study title. Max 50 chars"/>;
    }

}

export default getHelperText;