import React from "react";
import {Grid, Typography} from "@mui/material";
import Box from "@material-ui/core/Box";
import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import Treatments from "../../treatments/Treatments";



function ClinicalEvidence(props){


    return  <>
        <Grid item lg={12}>
            <Box p={1}>
                <Typography variant={"h5"}><MappedText text={"Clinical Evidence"} /></Typography>
            </Box>
        </Grid>
        <Grid item lg={12}>
            <Treatments/>
        </Grid>
    </>



}

export default ClinicalEvidence;