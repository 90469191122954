        import React, {useState, useEffect} from 'react';
        import Box from "@mui/material/Box";
        import Grid from "@mui/material/Grid";
        import { Button, Stack, TextField, Typography, Paper } from "@mui/material";
        // import jsonData from "../config/dir.json";
        import AccordionBasic from "../../create-report/shared-components/general-table/components/accordion/AccordionBasic.jsx"
        //import { Link as RouterLink } from 'react-router-dom';
        import { useDispatch, useSelector } from 'react-redux';
        //import { loadingAllInfo } from '../../../../reducers/slices/tables/table.thunk';  
        //import {createClinicalReport} from "../../../../reducers/slices/tables/table.thunk";
        import { createDoc } from '../../create-doc/write-document/writeReport.js';
        import useNavigate from "../../../../../general-table/hooks/useNavigate";
        import axios from "axios";
        import * as docx from "docx-preview";
        import DataField from "../../create-report/shared-components/Datafield.js";
        import MappedText from "../../create-report/shared-components/mapText.js";
        import ClinicalEvidenceSection from "./clinical-evidence/ClinicalEvidenceSection.js";
        import annexesData from "../config/annexes.json";
        import Interpretation from "./interpretation/Interpretation.js";
        import GeneralInfoSection from "./general-info-section/GeneralInfoSection.js";
        import JSON_DIR_SAMPLE from "../config/dir.json";
        import generalData from "../config/generalData.json";
        import genomeCoverageData from "./annexes/germline/data-fake/genomeCoverageData.js";
        import coverageGenesData from './annexes/germline/data-fake/coverageGenesData.js';
        import genotypePhenotypeData from './annexes/germline/data-fake/genotypePhenotypeData.js';
        import pharmacogenomicsData from './annexes/germline/data-fake/pharmacogenomicsData.js';
        import variantMetricsData from './annexes/germline/data-fake/variantMetricsData.js';
        import AnnexesSomaticSection from "./annexes/somatic/components/AnnexesSomaticSection.js";
        import AnnexesGermlineSection from "./annexes/germline/components/AnnexesGermlineSection.js";
        import GeneticFindingsSection from "./genetic_findings/GeneticFindingsSection.js";
        import somaticCoverageGenesData from "./annexes/somatic/data-fake/somaticCoverageGenesData.js";
        import exomeCoverageData from "./annexes/somatic/data-fake/exomeCoverageData.js";
        import getReferences from "./references/referenceConfig.js";
        import Disclaimer from './disclaimer/Disclaimer.js';
        import getDisclaimer from './disclaimer/disclaimerConfig.js';
        import TechnicalLimitations from './technical_limitations/TechnicalLimitations.js';
        import getTechnicalLimitations from './technical_limitations/technicalLimitationsConfig.js';
        import References from "./references/References.js";
        import {useParams} from "react-router-dom";
        import {loadingAllInfo} from "../../../../../reducers/slices/tables/table.thunk";
     
        
        function NewClinicalReport () {
   
            const [docUrl, setDocUrl] = useState(null);

            //const analysisType = "germline";
            //const allDataApis = {}
            //const isLoadingApis = false;

            const dispatch = useDispatch();
            const { allDataApis, isLoadingApis } = useSelector((state) => state.generaltable);
            const [selectedStatus, setSelectedStatus] = useState('');
            const { id } = useParams();
            //const id = "test";
            const token = useSelector(state => state.authorization.token);
            const [clinicalReportName, setClinicalReportName] = useState('');

            //TODO, we need to re-enable these ones;
            const [dirSelected, setDirSelected] = useState({ dir_id: id });
            const analysisType = useSelector(state => state.generaltable.allDataApis?.dirs?.analysis);
            const urlBase = window.config.apis_configuration.api_nextgpap_server
          
            const navigate = useNavigate();

            const [geneticFindingsData, setGeneticFindingsData] = useState(null);
            const [interpretationData, setInterpretationData] = useState({rows: [], conclusions: ""});
            const [clinicalEvidenceData, setClinicalEvidenceData] = useState(null);
          



            useEffect(() => {
                dispatch(loadingAllInfo(id, "POST", token));
            }, [dispatch, id, token]);

            useEffect(() => {
                if (allDataApis?.dir) {
                    const dirFind = allDataApis.dir.find(d => d.dir_id === id);
                    setDirSelected(dirFind);
                }
            }, [allDataApis?.dir, id]);

            useEffect(() => {
                if (allDataApis?.dirs?.status) {
                    setSelectedStatus(allDataApis?.dirs?.status);
                }
            }, [allDataApis?.dirs?.status]);

                useEffect(() => {

                    dispatch(loadingAllInfo(id, "POST", token));
                }, [dispatch, id, token]);

                useEffect(() => {
                    if (allDataApis?.dir) {
                        const dirFind = allDataApis.dir.find(d => d.dir_id === id);
                        setDirSelected(dirFind);
                    }
                }, [allDataApis?.dir, id]);


      
            const createDocument = async () => {
             

                        const clinicalReportName = document.querySelector('[name="clinicalReportName"]').value;

                        const dataForDoc = {
                            ...generalData.generalData,
                            clinicalEvidenceData: clinicalEvidenceData,
                            geneticFindingsData: geneticFindingsData,
                            interpretationData:  interpretationData,
                            genomeCoverageData: genomeCoverageData,
                            exomeCoverageData: exomeCoverageData,
                            coverageGenesData: coverageGenesData,
                            variantMetricsData: variantMetricsData,
                            genotypePhenotypeData: genotypePhenotypeData,
                            references: getReferences(analysisType),
                            disclaimer: getDisclaimer(analysisType),
                            technicalLimitations: getTechnicalLimitations(analysisType),
                        };

                   
                        let experimentType = allDataApis?.experiments[0]?.library_strategy;
                        const doc = await createDoc(dataForDoc, analysisType, experimentType, allDataApis);
                        
                        
                  
                        if(doc instanceof Blob) {
                        
                            return doc;
                        } else {
                         
                            const blob = new Blob([doc], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                            return blob;
                        }
                    };

                    const onPreviewClick = async () => {
                        try {
                            const doc = await createDocument();
                            docx.renderAsync(doc, document.getElementById("preview_container"));
                        } catch (error) {
                            console.error('Error en la previsualización:', error);
                        }
                    };
                
              
                    const uploadDocument = async (blob) => {
                        const formData = new FormData();
          
                        const fileExtension = analysisType === 'PDF' ? 'pdf' : 'docx';
                        formData.append("file", blob, `${clinicalReportName || 'report'}.${fileExtension}`);
                        formData.append("dir_id", dirSelected.dir_id);
                        formData.append("origin", "created_in_system");
                        formData.append("group_owner", "admin");
                        formData.append("clinical_report_name", clinicalReportName);
                        try {
                            const config = {
                                headers: {
                                  'Authorization': token,
                                }
                              };
                    
                              const response = await axios.post(
                                `${window.config.apis_configuration.api_nextgpap_server}/clinical_report`,
                                formData,
                                config 
                              );
                    
                            console.log('Document uploaded:', response.data);
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    };
                    
                
                    
                    
             
                  


                    const downloadDocument = (doc, filename) => {
                        const url = URL.createObjectURL(doc);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    };
                    const onSaveClick = async () => {
                        try {
                            const doc = await createDocument();
 
                            const fileExtension = analysisType === 'PDF' ? 'pdf' : 'docx';
                            const fileName = `${clinicalReportName || 'report'}.${fileExtension}`;
                    
                            await downloadDocument(doc, fileName);
                            await uploadDocument(doc); 
                            navigate(`/dir_overview/${id}`);
                        } catch (error) {
                            console.error('Error en onSaveClick:', error);
                        }
                    };
                    



            const handleChange = (event) => {
                    setSelectedStatus(event.target.value);
                };


            const handleSectionUpdate = (sectionData, data_type) => {

                    //let selectedRows = rows.map(s => s.original);
                    //console.log(selectedRows);

                    if(data_type === "Clinical_Evidence"){
                        const changesDetected = JSON.stringify(sectionData) !== JSON.stringify(clinicalEvidenceData);
                        if(changesDetected){
                            setClinicalEvidenceData(sectionData);
                        }
                    }

                    else if (data_type === "Genetic_Findings") {

                        if (JSON.stringify(sectionData) !== JSON.stringify(geneticFindingsData))
                        {
                            setGeneticFindingsData(sectionData);
                        }

                        if (interpretationData) {
                            setInterpretationData(sectionData);
                        }
                    }
                    else if(data_type === "Interpretation"){

                        const changesDetected = JSON.stringify(sectionData) !== JSON.stringify(interpretationData);
                        if(changesDetected){
                            setInterpretationData(sectionData);
                        }

                    }

                }

                const groups = [
                    {
                        title: <MappedText text="General Info" />,
                        content: (
                            <GeneralInfoSection
                                dirSelected={dirSelected}
                                allDataApis={allDataApis}
                                analysisType={analysisType}
                            />
                        ),
                    },
                    {
                        title: <MappedText text="Genetic Findings Summary" />,
                        content: (
                            <>
                                <GeneticFindingsSection
                                    handleSectionUpdate={handleSectionUpdate}
                                    url = {urlBase} token={token} report_id={allDataApis?.participants?.[0].report_id}
                                />

                            </>
                        ),
                    },
                    {
                        title: <MappedText text= "Interpretation"/>,
                        content: (
                            <>
                                <Interpretation
                                    variants={geneticFindingsData}
                                    handleSectionUpdate={handleSectionUpdate}
                                    variant_type="SNV"
                                    analysisType = {analysisType}
                                />
                            </>

                        ),
                    },
                    {
                        title: <MappedText text= "Clinical evidence and management"/>,
                        content: (
                            <ClinicalEvidenceSection
                                handleSectionUpdate={handleSectionUpdate}
                            />
                        ),
                    },
                    {
                        title: <MappedText text="Disclaimer" />,
                        content: (
                            <Disclaimer analysisType={analysisType}/>
                        ),
                    },
                    {
                        title: <MappedText text="Technical limitations"/>,
                        content: (
                            <TechnicalLimitations analysisType={analysisType} experimentType = "WGS"/>
                        ),
                    },
                    {
                        title: <MappedText text= "References"/>,
                        content: (
                            <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <References analysisType={analysisType}/>
                                </Grid>
                            </Grid>
                        ),
                    },
                    {
                        title: <MappedText text= "Annexes" />,
                        content: (
                            <>
                                <Grid container spacing={2} sx={{ marginBottom: 4}}>
                                    <Grid item xs={12} sm={12} md={12}>
                                {analysisType !== "germline"
                                    ?<>
                                       
                                           <AnnexesSomaticSection
                                                        annexesData={annexesData}
                                                        clinicalReportName={clinicalReportName}
                                                        setClinicalReportName={setClinicalReportName}
                                                        exomeCoverageData={exomeCoverageData}
                                                        somaticCoverageGenesData={somaticCoverageGenesData}
                                                    />
                                    </>
                                            : (<>
                                                    {/* Germline */}
                                                    <AnnexesGermlineSection
                                                        annexesData={annexesData}
                                                        clinicalReportName={clinicalReportName}
                                                        setClinicalReportName={setClinicalReportName}
                                                        genomeCoverageData={genomeCoverageData}
                                                        coverageGenesData={coverageGenesData}
                                                        variantMetricsData={variantMetricsData}
                                                        genotypePhenotypeData={genotypePhenotypeData}
                                                        pharmacogenomicsData={pharmacogenomicsData}
                                                    />

                                                </>
                                            )}
                                    </Grid>
                                </Grid>
                            </>

                        )
    }
        ];
            return (
                    <React.Fragment>
                        <Box sx={{ flexGrow: 1, p: 2 }}>
                        {/* <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '30px' }}>
                                <Link component={RouterLink} to="/dir_list" underline="hover" color="inherit">
                                    Interpretations Requests
                                </Link>
                                <Link component={RouterLink} to={`/dir_overview/${id}`} underline="hover" color="inherit">
                                    {id}
                                </Link>
                                <Typography color="text.primary">New Clinical Report</Typography>
                            </Breadcrumbs>*/}
                            {analysisType !== "germline" && (
                                <>
                                    <Typography variant={"h4"}>
                                        <MappedText text={"New Clinical Report"} />
                                    </Typography>
                                    <Typography style={{ fontSize: "16px", fontWeight: "400", marginBottom: '30px', marginTop: '30px' }}>
                                        <MappedText text={"Welcome to the Somatic Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report."} />
                                    </Typography>
                                </>
                            )}
                           
                            {analysisType === "germline" && (
                                <>
                                    <Typography variant={"h4"}>
                                        <MappedText text={"New Clinical Report"} />
                                    </Typography>
                                    <Typography style={{ fontSize: "16px", fontWeight: "400", marginBottom: '30px', marginTop: '30px' }}>
                                        <MappedText text={"Welcome to the Germline Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report."} />
                                    </Typography>
                                </>
                            )}
                            <TextField
                                id="outlined-basic"
                                label=<MappedText text="Report Name" />
                                name="clinicalReportName"
                                variant="outlined"
                                value={clinicalReportName}
                                onChange={(e) => setClinicalReportName(e.target.value)}
                            />
                         
                            <Paper
                                elevation={3}
                                sx={{   
                                    position: "fixed",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    height: "50px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    zIndex: 1000
                                }}
                                >
                                <Button
                                    type="button"
                                    onClick={onPreviewClick} 
                                    variant="contained"
                                    sx={{ m: 1 }}
                                >
                                    <MappedText text="Preview" />
                                </Button>
                                <Button
                                    type="button"
                                    onClick={onSaveClick} 
                                    variant="contained"
                                    sx={{ m: 1 }}
                                >
                                    <MappedText text="Save"/>
                                </Button>
                                </Paper>
                        
                        </Box>
                        <>


                            <Box
                                sx={{
                                    position: "relative",
                                    height: "calc(100vh - 50px)",
                                    overflow: "auto",
                                }}
                            >
                                <Grid container>
                                    <Grid item lg={6}>
                                        <AccordionBasic groups={groups} />
                                    </Grid>
                                    <Grid item lg={6}>
                                        <div id="preview_container"></div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>


                    </React.Fragment>
                );
            }

            export default NewClinicalReport;