import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, {useState} from "react";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../reducers/study_actions";
import {connect} from "react-redux";
import {getStudy} from "./components/variant-table/getAnnotations";
import inheritances_keys from "../side-panels/study-store/components/list-material-ui/inheritance_list";
import withStyles from "@material-ui/core/styles/withStyles";
import * as Actions from "../../../../../../actions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {deleteAnalysis} from "../../../../../../services/api/manage_study";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from "../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


const CustomTab = withStyles({
    root: {
        maxWidth: "400px"
    }
})(Tab);

const CustomQueryTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        minHeight: "25px"
    },
    indicator: {
        top:"0px",
        backgroundColor: '#31a354',
        height: "4px"
    },
})(Tabs);

function AnalysesTabs(props){

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleSnackBar = () => {

        setOpen(false);
    }


    const renderAnalysesTab = (text1, text2, analysisIndex, disabled, tooltip) => {

        return <CustomTab
            key={<MappedText text={text1}/>}
            component={"div"}
            disabled={disabled}
            label={
            <React.Fragment>
              <GPAP_Tooltip title={tooltip} style={{ whiteSpace: 'pre-line' }} >
              <div style={{float: "left"}}>
                <MappedText text={text1}/>
                    {(!disabled) ? <div style={{float: "right"}}>
                            <IconButton style={{padding: "0px"}}size="small" onClick={() => { handleDelete(analysisIndex) }}>
                                <CloseIcon />
                            </IconButton> </div>
                        : null
                    }

                </div></GPAP_Tooltip>
                <span style={{ fontSize: "smaller" }}>
                    <div>
                        {text2}
                    </div>

                </span>
            </React.Fragment>
        } />

    };

    const getAnalysisIndex = () => {

        let study = getStudy(props.studySettings, props.studyBucket);

        if(study!== undefined && study.study.analyses!== 0){

            // current analysis

            let analysis_id = props.studySettings.current_analysis;

            return study.study.analyses.map(function (e) {
                return e.localId;
            }).indexOf(analysis_id);
        }
        else{
            return 0
        }



    };

    const renderTabs = () => {

        let study = getStudy(props.studySettings, props.studyBucket);

        if(study!== undefined && study.study.analyses!== undefined && study.study.analyses.length!== 0 ){
            // get query
            const inheritance_list = inheritances_keys;

            return study.study.analyses.map(a => {

                let name = inheritance_list.find(s => s.key === a.inheritance_mode_id)

                let tooltip= "";
                let lines = [];
                a.samples.forEach(function(sample){

                    if(Array.isArray(sample.gt_settings) || Array.isArray(sample.gt) ){

                        let val_1 = ( Array.isArray(sample.gt_settings) && sample.gt_settings.length>0 && sample.gt_settings[0]!== null) ? sample.gt_settings[0].setting : "NA";
                        let val_2 = ( Array.isArray(sample.gt) && sample.gt.length>0 ) ? sample.gt[0].setting : "NA";

                        let setting = (sample.gt_settings !== undefined) ?  val_1 : val_2;
                        let line = sample.sample_id + " " +  setting.toString();
                        lines.push(line);
                    }

                })

                tooltip = lines.join("\n");
                // extract settings;

                let name_analysis = (name!== undefined) ? name.label : "undefined";
                // number of queries
                let n_queries = a.queries.length;
                let term = (n_queries>1) ? " queries" : " query";
                let text2 = n_queries + term;
                let sample_l = a.samples.length;
                let term_2 = (sample_l>1) ? sample_l + " exp." : sample_l + " exp";

                let text_f = (props.studySettings.analysisType === "family_trio")
                    ? text2 + ", " + term_2
                    : text2;
                
                name_analysis = name_analysis.replace("Inheritance", "");

                return renderAnalysesTab(name_analysis, text_f, a.localId, false , tooltip)
            })
        }
        else{
            return renderAnalysesTab("No analyses in this study", "", 1, true)
        }

    };

    const handleChange = (event, newValue) => {


        //
        let study = getStudy(props.studySettings, props.studyBucket);

        if(study!== undefined ){

            let analyses_selected = study.study.analyses[newValue];

            // analyses id
            if(analyses_selected!== undefined){
                let analysis_id = analyses_selected.localId;
                let query_id = (analyses_selected.queries.length!==0) ? analyses_selected.queries[0].localId : null;

                props.studyActions.setStudySettings({current_query: query_id, current_analysis: analysis_id});
                props.switchAnalysis(analysis_id, query_id);
            }
        }
    };

    const handleDelete = (analysisIndex) => {

        let study = getStudy(props.studySettings, props.studyBucket);

        let n_analysis = study.study.analyses.length;

        if(study.study.analyses.length!== 0){
            let analysisTab = study.study.analyses.find(analysis => analysis.localId === analysisIndex);

        // id is the database field for the id
            if("id" in analysisTab){
                if (window.confirm('This action will delete the analysisTab from the DB and its queries permanently?'))
                {

                    let body = {analysis_id: analysisTab.id };
                    deleteAnalysis( config.apis_configuration.api_nextgpap_server, props.token, body )
                        .then(r => {
                             return r.json()
                        })
                        .then(json => {

                            if(json.message=== "Success"){
                                props.studyActions.delete_analysis(analysisIndex, study.study.localId);
                                setOpen(true);
                                setMessage("Analysis Deleted Correctly");
                            }
                            else{
                                setMessage("Analysis Could not be deleted correctly");
                                setOpen(true);
                            }
                        })

                    if(n_analysis === 1){
                        props.resetAll();
                    }

                }
            }
            else{
                if (window.confirm('This action will delete the analysis from this session permanently?')) {
                    props.studyActions.delete_analysis(analysisIndex,  study.study.localId);
                    setOpen(true);
                    setMessage("Analysis Deleted Correctly");

                    if(n_analysis === 1){
                        props.resetAll();
                    }
                }
            }

        }



    }



    return  <div style={{float: "left", width: "100%"}} >
        <Snackbar
            anchorOrigin={{ vertical:"top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleSnackBar}
            message={""}
        >
            <Alert onClose={handleSnackBar} severity="error" >
                {message}
            </Alert>
        </Snackbar>
        {(!props.studySettings.analysisType !== "instand")
            ? <CustomQueryTabs
                id={"AnalysesTabs"}
                value={getAnalysisIndex()}
                onChange={handleChange }
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {renderTabs()}
            </CustomQueryTabs>
            : null
        }
    </div>



}


function mapStateToProps (state) {
    if(state.studyBucket!==undefined)
    {
        return {
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            token: state.authorization.token,}
    }
    else{
        return {snv: []}
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(AnalysesTabs);