

import {convertInchesToTwip, Paragraph, TextRun} from "docx";
import { renderToString } from "../../create-report/shared-components/renderToString";


const table_margins = {
    top: convertInchesToTwip(0.20),
    bottom: convertInchesToTwip(0.20),
    left: convertInchesToTwip(0.20),
};


function getKeyValField(key,val){

    let value = (val!== "") ? val : "NA";

    return new Paragraph({
        children: [new TextRun({text: key,  bold: true, font: "Calibri", size: 22*2}), new TextRun({text: value, font: "Calibri", size: 22})],
    })
}

function bodyText(text){

    return new TextRun({
        text: text,
        font: "Calibri",
        size: 22
    })

}



const TEXT_FOR_NEGATIVE_VARIANTS = "No pathogenic or likely pathogenic single nucleotide variant(s), small indel(s), copy number variant(s) or structural variant(s) have been detected. This result does not exclude a possible genetic origin as the cause of the disorder";


const TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE = "No Clinical Evidence was reported for this DIR";



export {table_margins, getKeyValField,  bodyText, TEXT_FOR_NEGATIVE_VARIANTS, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE}