import React from "react";
import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


export const CLINICAL_DATA_HEADER = [
    {
        header: "Clinical reports",
        columns: [
            { nameTable: <MappedText text={"Report ID"} />, dataSearch: "clinical_report_id", tooltip: "" },
            { nameTable: <MappedText text={"Report Name"}/> , dataSearch: "clinical_report_name", tooltip: "" },
            { filterType: { type: "date" }, filter: "dateFilter", nameTable: <MappedText text={"Creation Date"}/> , dataSearch: "creation_date", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: <MappedText text={"Origin"}/> , dataSearch: "origin", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: <MappedText text={"Creator"}/> , dataSearch: "creator", tooltip: "" },
        ],
    },
];
