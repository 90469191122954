import {connect} from "react-redux";
import React, {Component} from "react";

import "./variant-info.css";

import predictors_config from "./predictors_config";
import Box from "@material-ui/core/Box";
import SamplesPopOver from "../../../results/components/variant-table/gpap-table/components/SamplesPopOver";
import PopulationPopOver from "../../../results/components/variant-table/gpap-table/components/PopulationPopOver";
import PredictorsPopOver from "../../../results/components/variant-table/gpap-table/components/PredictorsPopOver";

import IGVLinks from "../../../results/components/variant-table/gpap-table/components/IGVLinks";
import CandidatePopOver from "../../../results/components/variant-table/gpap-table/components/CandidatePopOver";
import {getAnalysisAnnotations} from "../../../results/components/variant-table/getAnnotations";
import {variantFinder} from "../../../results/components/variant-table/gpap-table/variant_finder";
import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import Label_Menu from "../../../results/components/toolbar/label-menu/Label_Menu";
import TagDialog from "../../../results/components/toolbar/tag_dialog/TagDialog";
import {getLabelChrom} from "../../../side-panels/filter-panel/components/filter-sections/position-section/Position_Section";

// a Window for Variant Details;

class Variant_Info extends Component {

    constructor(props){
        super(props);

        this.extractPredictors = this.extractPredictors.bind(this);
        this.getTaggedVariants = this.getTaggedVariants.bind(this);
    }

    getTaggedVariants(){

        return []



    }


    extractPredictors(){
        let {selected_variant} = this.props;

        return predictors_config.map(function(predictor)
        {
            let output = selected_variant[predictor.predictor];
            if(output!== undefined && output.length>0)
            {
                if(selected_variant[predictor.predictor]){
                    output = predictor.outputs.find(out => out.key === output).label;
                    return <div className={'prediction'}>
                        <div className={'pred_name'}>{predictor.predictor}:</div>
                        <div className={'pred_score'}>{output} (score = {selected_variant[predictor.score]})</div>
                    </div>
                }
                else{
                    return <div className={'prediction'}>
                        <div className={'pred_name'}>{predictor.predictor}:</div>
                        <div className={'pred_score'}>NA</div>
                    </div>
                }
            }
            else{
                return <div className={'prediction'}>
                    <div className={'pred_name'}>{predictor.predictor}:</div>
                    <div className={'pred_score'}>NA</div>
                </div>
            }
        })
    }


    getIGVLinks(last_variant){ 

        if(window.config.extra.igv){
            const allSamples = [
                ...(Array.isArray(last_variant.samples_somatic) ? last_variant.samples_somatic : []),
                ...(Array.isArray(last_variant.samples_germline) ? last_variant.samples_germline : [])
              ];
                return <IGVLinks samples = { allSamples } dataType={this.props.dataType} />}
        else{
            return null;
        }


    }

   



    render(){

        let {selected_variant} = this.props;

        if(selected_variant!== undefined && selected_variant.length!==0){
            let last_variant = selected_variant[selected_variant.length-1];

            let annotations = getAnalysisAnnotations(this.props.studySettings, this.props.studyBucket);
            let tagged_variants = this.props.tagged_variants;

            let variant_found = variantFinder(last_variant, annotations, tagged_variants, "SNV_Germline");

            return <Box p={2}>
                {/*<div className={'ref'}>Gene Name: <div style={{ fontStyle : "italic", display: "inline-block"}}>{last_variant.gene_name}</div></div>*/}
                        <div className={'ref'}>Chr: {getLabelChrom(last_variant.chrom)}</div>
                        <div className={'ref'}>Position: {last_variant.pos}</div>
                        <GPAP_Tooltip title={last_variant.ref}>
                            <div className={'ref'} style={{maxWidth:"200px"}}>Ref: {last_variant.ref}</div>
                        </GPAP_Tooltip>
                        <GPAP_Tooltip title={last_variant.alt}>
                        <div className={'ref'}>Alt: {last_variant.alt}</div>
                        </GPAP_Tooltip>
                <div style={{float: "left", width: "100%"}}>
                    <Label_Menu
                        disable = {Array.isArray(variant_found) && variant_found.length !== 0 }
                        annotate_variants={this.props.annotate_variants}/>
                    { (this.props.studySettings.analysisType === "family_trio" || this.props.studySettings.analysisType === "instand")
                        ? <TagDialog
                            tagged_variants = { this.props.tagged_variants }
                            annotations = { this.props.annotations }
                            annotate_variants={this.props.annotate_variants}
                            getAllTaggedVariants = {this.props.getAllTaggedVariants}
                            dataType = {this.props.dataType}
                        />
                        : null
                    }
                    <SamplesPopOver 
                        germline_samples = { last_variant.samples_germline } 
                        somatic_samples = {last_variant.samples_somatic}
                        data_type= {this.props.dataType}/>
                    <PopulationPopOver
                        dataType = {this.props.dataType}
                        samples = { last_variant.samples_germline }/>
                    <PredictorsPopOver 
                        dataType = {this.props.dataType}
                        samples = { last_variant.samples_germline }/>
                    <CandidatePopOver
                        tagged_variants = { variant_found }
                        samples = { last_variant.samples_germline }/>
                    {this.getIGVLinks(last_variant)}
                </div>
                    </Box>
        }
        else{
            return <div>No Variant Selected</div>
        }

    }


}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {studyBucket: state.studyBucket, studySettings: state.studySettings}
    }
    else{
        return {studyBucket: null, studySettings: null}
    }
}

export default connect(mapStateToProps, null)(Variant_Info);