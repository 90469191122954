import Typography from "@mui/material/Typography";
import React from "react";
import MappedText from "../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function ReportTypography({ selected, text }) {
    return (
        <Typography
            variant="body1"
            color={selected ? "inherit" : "textSecondary"}
        >
            <MappedText text={text}/>
        </Typography>
    );
}

export default ReportTypography;