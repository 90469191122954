




export function getChr(chr){

    let chrom = chr;
    switch (chr) {
        case '24':
            chrom = "X";
            break;
        case '25':
            chrom = "Y";
            break;
        case '23':
            chrom = "MT";
            break;
        default:
            chrom = chr;
    }

    return chrom;
}