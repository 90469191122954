import React ,{useEffect, useState}from "react";
import { Grid, Box, Typography } from "@mui/material";
import { GENETIC_FINDINGS_CNV } from "./config/genetic_findings_cnv.js";
import { GENETIC_FINDINGS_SNV } from "./config/genetic_findings_snv.js";
import GeneralTable from "../../../create-report/shared-components/general-table/GeneralTable.js";
import MappedText from "../../shared-components/mapText.js";



export default function GeneticFindingsReport({data, handleUpdate, report_id, variant_type}) {
    const [sectionData, setData] = useState(data)
    let columns = variant_type === 'CNV' ? GENETIC_FINDINGS_CNV : GENETIC_FINDINGS_SNV;

    const handleSelectedRows = (rows) => {

            let rowsData = rows.map(s => s.original);
            let obj= {rows: rowsData}
            handleUpdate(obj, variant_type);


    };

    useEffect(() => {
        setData(data);
    }, [data]);

    
    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <GeneralTable data={ sectionData } columns={columns} showCheckbox={true} handleSelectedRows={handleSelectedRows}/>
                {variant_type === 'CNV' && (
                        <Typography variant='caption' display={"flex"}>
                            <MappedText text="*Based upon reference genome version GRCh38" />
                        </Typography>
                    )}
                <Typography variant='caption'><MappedText text="**Based upon ClinGen, CGC and VICC recommendations (Horak et al 2022)"/></Typography>
                </Box>
            </Box>
        </Grid>
    );
}
