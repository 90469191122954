
import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CNAG_LOGO from "../../../resources/CNAG_LOGO.png";
import MailIcon from '@mui/icons-material/Mail';
import { makeStyles } from "@mui/styles";
import MappedText from "../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";



function ContactGPAP(){


    let mailTo = "mailto:" + window.config.help_email;

    return <Box style={{margin:'3.5rem', width: "75%" }}>
        <Typography variant="h3" style={{margin:'30px 0', }}><MappedText text="Get in touch"/></Typography>
        <Grid container spacing={2}>
            <Grid item lg={6}>
                <Box display="flex" flexDirection='column'>
                    <Typography variant="body1"><MappedText text="Thank you for using our product."/></Typography>
                    <Typography variant="body1" style={{marginBottom:'30px', }}><MappedText text="Feel free to reach out if you have any questions or comments."/></Typography>
                    <Box >
                        <a href={mailTo} style={{ textDecoration: 'none', color: '#3e699e', display:'inline-flex', marginBottom:'30px' }}><MailIcon style={{width:'25px', height:'25px' }}/>
                            <Typography variant="body1"> {window.config.help_email}</Typography>
                        </a>
                    </Box>
                    <Box>
                        <img src={CNAG_LOGO} alt="GPAP" style={{marginBottom:'30px', }}/></Box>
                    <Typography variant="body1" style={{ fontWeight: '700'}}>Centre Nacional d’Anàlisi Genòmica</Typography>
                    <Typography variant="body1" style={{marginBottom:'30px', }}><MappedText text="Data Platforms & Tools Development Team"/></Typography>
                    <Typography variant="body1"><MappedText text="Barcelona, Spain"/></Typography>
                </Box>
            </Grid>

        </Grid>
    </Box>

}

export default ContactGPAP;