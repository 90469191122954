import React from "react";
import MappedText from "../../../../shared-components/mapText";

export const GENOTYPE_PHENOTYPE= [
    {
        Header: <MappedText text='Gene'/>,
        accessor: "gene",
    },
    {
        Header: <MappedText text='Gene MIM Number'/>,
        accessor: "gene_mim_number",
    },
    {
        Header: <MappedText text='Phenotype'/>,
        accessor: "phenotype",
    },
    {
        Header: <MappedText text='Phenotype MIM Number'/>,
        accessor: "phenotype_mim_number",
    },
    {
        Header: <MappedText text='Inheritance'/>,
        accessor: "inheritance",
    },
    
];