
import React from 'react'
import NavigationBar from "./NavigationBar";


function GPAP_Header(props){
    return (
        <div className={"navBar"}>
            <NavigationBar/>
            {props.children}

        </div>
    )
}


export default GPAP_Header;