import React from "react";
import MappedText from "../../../../shared-components/mapText";

export const EXOME_COVERAGE = [
    {
        Header: <MappedText text='Mean Coverage'/>,
        accessor: "mean_coverage",
        filter: '',
    },
    {
        Header: <MappedText text='Duplication Rate '/>,
        accessor: "duplication_rate",
        filter: '',
    },
    {
        Header: <MappedText text='Uniformity'/>,
        accessor: "uniformity",
        filter: '',
    },
    {
        Header: 'C10',
        accessor: "c10",
        filter: '',
    },
    {
        Header: 'C15',
        accessor: "c15",
        filter: '',
    }, 
];