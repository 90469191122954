import React from "react";
import {DATA_HEADER} from "./data_header";
import CustomTable from "../../../general-table/components/tables/CustomTable";
import { useDispatch } from "react-redux";
import {
    selectedVariant,
    setSelectedTableRows
} from "../../dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";

function Pharmacogenomics_Table(props){

   const dispatch = useDispatch();


  /* const handleSelectedPharmacogenomics= (variant) => {



      dispatch(selectedVariant(
          [{
              variant : variant,
              type: "Pharmacogenomics_Germline"
          }]))
    }*/


    const handleSelectedPharmacogenomics= (selectedRows) => {

        let data = {
            data_row : [selectedRows],
            data_type: "Pharmacogenomics_Germline",

        };

        dispatch(setSelectedTableRows(data))

    }



    return <CustomTable
        url={props.url}
        method= "POST"
        dataSend = {{
            "fields": [],
            "filtered": [
              {
                "id": "ExperimentID",
                "value": [
                  //"AZ8559"
                  "germline_demo"
                ]
              }
            ],
            "page": 1,
            "pageSize": 1000,
            "sorted": []
          }}  
        token={props.token}
        participant={props.participant}
        locationData="items"
        classRow= "Pharmacogenomics"
        dataType = {"Pharmacogenomics_Germline"}
        setSelected = {(pharmacogenomics) => handleSelectedPharmacogenomics(pharmacogenomics)}
        customFeatures= {[
            { SwitchFS: false },  
            { toggle: false },
            { expanding: false },
            { showHeader: false },
        ]}
       
        dataHeader={DATA_HEADER}
       
 
    />


}

export default Pharmacogenomics_Table
