import {getCohortsDB, getUser_Cohorts} from "../../../../services/api/get";
import React, {Fragment, useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../actions";
import * as StudyActions from "../../../dashboard/reducers/study_actions";
import * as SampleActions from "../analysis-page/reducers/sample_actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {GPAP_Button, GPAP_Button_light} from "../../../../gpap-lib/components/Button_Collection";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {AlertInfo} from "../analysis-page/components/samples-section/SelectionInfo";
import Divider from "@material-ui/core/Divider";
import GPAP_Tooltip from "../../../../gpap-lib/components/GPAP_Tooltip";
import {Card, CardActions, CardMedia, CircularProgress, Paper} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import COHORTAPP_LOGO from "./cohortapp-icon.svg";
import {RefreshOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {Alert, AlertTitle} from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";


function CohortSelect(props){


    const [cohorts, setCohorts] = useState(false);
    const [selected, setCohortSelected] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [created, setCreated] = useState(false);
    const [loading, setLoading]  = useState(false);
    const [URL_CohortID, setURLCohortID]  = useState(false);
    let {api_phenostore, api_cohortapp} = config.apis_configuration;


    useEffect(function(){

        let mounted = true;

        if(!cohorts){
            setLoading(true);
            collectCohorts();

            return function cleanup() {
                mounted = false
            }
        }



    });

    const collectCohorts = () => {

        let mounted = true;

        getUser_Cohorts(api_cohortapp, props.token)
            .then((response) =>
                response.json())
            .then((json) =>  {
                if(mounted){
                    setCohorts(json.data);
                    if(props.cohort_id_from_url){

                        let find= json.data.find(s => s.id === props.cohort_id_from_url);
                        if(find!== undefined){
                            setURLCohortID(props.cohort_id_from_url);
                            let obj = {value: find.cohortName, label: find.cohortName};
                            setCohortSelected(obj);
                        }
                    }

                    setTimeout( () => setLoading(false),1000);
                }
            });
    }


    const loadCohorts = () => {

        setLoading(true);
        setCohortSelected(false);
        setCohorts([]);
        collectCohorts();


    }

    const handleSelectCohort = (value) => {

        setCohortSelected(value);

    };


    const renderCohortView = () => {

        let options = [];
        if(Array.isArray(cohorts)){
           options =  cohorts.map(s => {
                let name = s.cohortName + " (" + s.data.length + " experiments )";
                return {value: s.cohortName, label:  name }});
            // cohorts list;


        }
        return <Fragment>
            <div style={{    width: "100%",display: "block", height: "30px"}}>
                <div style={{float: "left", width: "100%"}}>
                    <IconButton style={{float: "right"}} onClick={() => loadCohorts()}>
                        <RefreshOutlined/>
                    </IconButton>
                </div>
            </div>
            <Box p={1}>
                <Typography variant={"h6"} >Cohorts ready for analysis</Typography>
                {
                    (loading)
                        ? <Box style={{textAlign: "center"}}><CircularProgress/></Box>
                        : <Select options={options}
                                  isSearchable={true}
                                  onChange = {(value) => handleSelectCohort(value)}/>

                }
        </Box>
        </Fragment>



    };

    const createStudyCohort = () =>{

        if(selected){

            let cohort = cohorts.find(s => s.cohortName === selected.value);
            props.assignAnalysis(cohort)
            setCreated(true);
            setInitialized(true);
            // add cohort to redux;

            props.actions.set_selected_cohort(cohort.id);
        }

    };

    const renderCohortCard = () => {

        return <Fragment>
            <Divider/>
            <Box p={1}>
                <Typography gutterBottom variant="h6" component="div">
                    Create a cohort to analyse
                </Typography>
            </Box>

            <Card elevation={2}>
                <CardMedia
                    style={{background: "#4575B4"}}
                    component="img"
                    height="140"
                    image= {COHORTAPP_LOGO}
                    alt="cohort_app"
                />
                <CardContent>
                </CardContent>
                <CardActions>
                    <GPAP_Tooltip title={"Click here if you still need to create a cohort to analyze"}>
                        <GPAP_Button
                            target={"_blank"}
                            href={window.location.origin + "/cohortapp/#/dashboard"}
                        >Go to CohortApp
                        </GPAP_Button>
                    </GPAP_Tooltip>
                </CardActions>
            </Card>
        </Fragment>

    }


    const isStudyCreated = () => {

        return  (!created)
            ?   <Grid item lg={7}>
                <Box p={1} textAlign={"center"}>
                    <GPAP_Button onClick={createStudyCohort}
                                 disabled={!selected}>Create Cohort Study</GPAP_Button>
                </Box></Grid>
            : <Grid item lg={7}>
                <Box p={0}>
                    <Typography variant={"subtitle2"} >Cohort Selected</Typography>
                </Box>
            </Grid>

    }



    return <Grid container
                      direction="row"
                        justifyContent="center"
                      alignItems="center">
        <Grid item lg={7}>
            <Box>
               <Typography variant={"h5"} align={"center"}>Select a cohort
                   <GPAP_Tooltip title={"To analyze a cohort, you need first to create a cohort in the GPAP CohortApp. Save the cohort and come back to " +
               "this page to find the created cohort in the list below."}><InfoIcon color={"primary"}/></GPAP_Tooltip>
               </Typography>
            </Box>
        </Grid>
            <Grid item lg={7}>
                        {
                            (selected) ? <Grid item lg={12}>
                                <Box p={2}>
                                    <Alert severity={"success"}>
                                        <AlertTitle>Cohort Selected</AlertTitle>
                                        Cohort selected:  {selected.value}
                                    </Alert>
                                </Box></Grid>
                                : null
                        }

                        <Divider/>
                    </Grid>
                    <Grid item lg={7}>
                        <Box p={2}>
                            {renderCohortView()}
                        </Box>
                    </Grid>

                    {isStudyCreated()}
                    <Grid item lg={7}>
                        <Box p={2}>
                            {renderCohortCard()}
                        </Box>
                    </Grid>

                    </Grid>


}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
            return {
                token:state.authorization.token,
                studySettings: state.studySettings,
                selected_cohort: state.selected_cohort
            }
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    sampleActions: bindActionCreators(SampleActions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(CohortSelect);