

import React from "react";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";



class ErrorBoundaryGPAP extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null, open:false };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
            open: true
        })
        // You can also log error messages to an error reporting service here
    }

    handleClose = () =>{
        this.setState({open:false})
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <React.Fragment>
                    <Snackbar
                        anchorOrigin={{ vertical:"top", horizontal: "center" }}
                        open={this.state.open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        message="Error Caught"
                    >
                       <Alert onClose={this.handleClose} severity="error" >
                            Application Error
                        </Alert>
                    </Snackbar>
                    {this.props.children}
                </React.Fragment>
            );
        }
        else if(this.props.children!== undefined){
            // Normally, just render children
            return this.props.children;
        }
        else{
            return null;
        }

    }
}

export default ErrorBoundaryGPAP;