import React, {Fragment} from "react";
import Box from "@material-ui/core/Box";
import Gene_Section_View
    from "../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/gene-section/Gene_SectionView";
import {AlertInfo} from "../analysis-page/components/samples-section/SelectionInfo";
import Typography from "@material-ui/core/Typography";
import useSectionStyles from "./styles";




const GeneBox = () => {

    return  <Fragment>
        <Box p={2}>
            <Gene_Section_View/>
        </Box>
    </Fragment>

};

const WarningMessage = () => {


    return  <AlertInfo severity={"warning"}  title={"Warning"}
                       message={"You must select at least one gene when using the “search across all/cohort” functionality." +
                       "\n"}
    />

}


const TitleAndDescription = (props) => {

    const classes = useSectionStyles()

    let {title, description} = props;

    const titleEl =  <div style={{
        float: "left",
        width: "100%"}}>
        <Typography className={classes.title} variant="h6" id="tableTitle">
            {title}
        </Typography>
    </div>

    const descr_El =  <div>
        <Typography variant={"subtitle1"}>{description}</Typography>
    </div>

    return <Fragment>
        <div style={{
            float: "left",
            width: "100%"}}>
            {titleEl}
            {descr_El}
        </div>
    </Fragment>


}


export {WarningMessage, GeneBox, TitleAndDescription}