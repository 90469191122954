

import React from "react";
import CNV_DATA_HEADER_FULL from "../cnv_config/data_header_full";
import {additional_info_1, additional_info_2} from "../cnv_config/additional_info";
import CNV_DATA_HEADER_SPLIT from "../cnv_config/data_header_split";
import CustomTableForCNVs from "../NewReactTable/CustomTableForCNVs";




function CNV_Table(props){


    return <CustomTableForCNVs
        url={props.url}
        method = {props.method}
        token={props.token}
        locationData="items"
        //experiment NA12878
        //experiment={["NA12878"]}
        experiment={props.experiment}
        genes={props.genes}
        getTotal = {props.getCNVNumber}
        setSelected = {props.setSelectedCNV}
        configFilter={[
            {
                full: {
                    dataSearch: "Annotation_mode",
                    keyFilter: "full",
                },
            },
            {
                split: {
                    dataSearch: "Annotation_mode",
                    keyFilter: "split",
                },
            },
        ]}
        dataHeader={CNV_DATA_HEADER_FULL}
        compactColumns = {[
            {
                referenceTable: "ACMG Classific.",
                nameColumn: "AnnotSV reported pathogenic",
                tooltip: "Phenotype of the pathogenic genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (TS3).",
                dataSearch: "SV_type",
                position: "right",
                rowSelect: [
                    {
                        replaceWith: "P_gain_phen",
                        dataSearch: "P_gain_phen",
                        value: "DUP",
                    },
                    {
                        replaceWith: "P_loss_phen",
                        dataSearch: "P_loss_phen",
                        value: "DEL",
                    },
                    {
                        replaceWith: "P_ins_phen",
                        dataSearch: "P_ins_phen",
                        value: "INS",
                    },
                ],
            },
            {
                referenceTable: "Internal Freq.",
                nameColumn: "AnnotSV population AFMax",
                tooltip: "Maximum allele frequency of the reported benign genomic regions completely overlapped with the SV. Source is displayed in the tooltip: gnomAD, ClinVar (CLN), ClinGen (TS40), DGV (dgv, nsv or esv), DDD, 1000 genomes (1000g), Ira M. Hall’s lab (IMH), Children’s Mercy Research Institute (CMRI). This filter applies values equal to or less than the specified threshold.",
                dataSearch: "SV_type",
                position: "left",
                rowSelect: [
                    {
                        replaceWith: "B_gain_AFmax",
                        dataSearch: "B_gain_AFmax",
                        value: "DUP",
                    },
                    {
                        replaceWith: "B_loss_AFmax",
                        dataSearch: "B_loss_AFmax",
                        value: "DEL",
                    },
                    {
                        replaceWith: "B_ins_AFmax",
                        dataSearch: "B_ins_AFmax",
                        value: "INS",
                    },
                ],
            },
        ]}
        compactColumnsSplit= {[
            {
                referenceTable: "OMIM",
                nameColumn: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                position: "right",
                rowSelect: [
                    {
                        replaceWith: "P_gain_phen",
                        dataSearch: "P_gain_phen",
                        value: "DUP",
                    },
                    {
                        replaceWith: "P_loss_phen",
                        dataSearch: "P_loss_phen",
                        value: "DEL",
                    },
                    {
                        replaceWith: "P_ins_phen",
                        dataSearch: "P_ins_phen",
                        value: "INS",
                    },
                ],
            },
            {
                referenceTable: "Internal Freq.",
                nameColumn: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                position: "left",
                rowSelect: [
                    {
                        replaceWith: "B_gain_AFmax",
                        dataSearch: "B_gain_AFmax",
                        value: "DUP",
                    },
                    {
                        replaceWith: "B_loss_AFmax",
                        dataSearch: "B_loss_AFmax",
                        value: "DEL",
                    },
                    {
                        replaceWith: "B_ins_AFmax",
                        dataSearch: "B_ins_AFmax",
                        value: "INS",
                    },
                ],
            },
        ]}
        tooltipCompact={ [
            {
                referenceTable: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "P_loss_source",
                        dataSearch: "P_loss_source",
                        value: "DEL",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "P_gain_source",
                        dataSearch: "P_gain_source",
                        value: "DUP",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "P_ins_source",
                        dataSearch: "P_ins_source",
                        value: "INS",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "B_loss_source",
                        dataSearch: "B_loss_source",
                        value: "DEL",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "B_gain_source",
                        dataSearch: "B_gain_source",
                        value: "DUP",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "B_ins_source",
                        dataSearch: "B_ins_source",
                        value: "INS",
                    },
                ],
            },
        ]}
        tooltipCompactSplit = {[
            {
                referenceTable: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "P_loss_source",
                        dataSearch: "P_loss_source",
                        value: "DEL",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "P_gain_source",
                        dataSearch: "P_gain_source",
                        value: "DUP",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV reported pathogenic",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "P_ins_source",
                        dataSearch: "P_ins_source",
                        value: "INS",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "B_loss_source",
                        dataSearch: "B_loss_source",
                        value: "DEL",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "B_gain_source",
                        dataSearch: "B_gain_source",
                        value: "DUP",
                    },
                ],
            },
            {
                referenceTable: "AnnotSV population AFMax",
                dataSearch: "SV_type",
                rowSelect: [
                    {
                        replaceWith: "B_ins_source",
                        dataSearch: "B_ins_source",
                        value: "INS",
                    },
                ],
            },

        ]}
        chipFull ={[
            {
                referenceColumn: "ACMG Classific.",
                cells: [
                    {
                        data: "Likely pathogenic",
                        color: "#FD7645",
                        textColor: "white",
                    },
                    {
                        data: "Benign",
                        color: "#31A354",
                        textColor: "white",
                    },
                    {
                        data: "Uncertain significance",
                        color: "grey",
                        textColor: "white",
                    },
                    {
                        data: "Pathogenic",
                        color: "#DD4434",
                        textColor: "white",
                    },
                ],
            },
        ]}
        nestedInfo={additional_info_1}
        split={CNV_DATA_HEADER_SPLIT}
        nestedInfoB={additional_info_2}
        customFeatures = {[
            { switchFS: true },
        ]}
        buttons={[
            {
                nameButton: "Population",
                type: "popover",
                template: "Population",
                data: ["B_gain_AFmax", "B_loss_AFmax", "B_ins_AFmax", "B_inv_AFmax"],
            },
        ]}
        getAllTaggedVariants = {props.getAllTaggedVariants}
        all_tagged_variants = {props.all_tagged_variants}

    />


}

export default CNV_Table