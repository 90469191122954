
import React from "react";
import {getStudy} from "../components/results/components/variant-table/getAnnotations";
import {assignSettings} from "../../../../study-page/create-study/search-across-all/utils";


function getSamplesFromBucket(studySettings,studyBucket, analysis_id, samples_in_query){

    // get analysis samples;
    let study = getStudy(studySettings,studyBucket);
    let current_analysis = study.study.analyses.find(s => s.localId === analysis_id);
    let samples_to_analyze = current_analysis.samples;
    return assignSettings(samples_in_query, samples_to_analyze);

}




export {getSamplesFromBucket}