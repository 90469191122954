



export const variant_section_classes = ['variantclasses', 'v_type','v_tagged','clinvarclasses','genotypefeatures','Mane','variantconsequences'];

export const snv_effects = ['mutationtaster', 'siftpred', 'polyphen2hvarpred', 'cadd_phred','revel','spliceai_max_ds','intervarclasses','OCScore','onco_classifier'];

export const population = ['freqInt', 'gnomad_af', 'gp1_af', 'gnomad_filter'];

export const population_es7 = ['freqInt', 'gnomad_af', 'gnomad_filter'];

export const position_roh = ['chrom', "start", "end", "roh"];

