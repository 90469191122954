import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Grid, Box, IconButton, Menu, MenuItem, Divider, ListItemIcon, } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MappedText from "../../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";
import LanguageSelector from "../../header/LanguageSelector";
import GPAP_Logo from '../../../../resources/GPAP-Cancer.png'
import CustomTab from "../../../general-table/pages/CustomTab";
import {connect} from "react-redux";
import auth from '../../../services/session/Auth'
import { useLocation, useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles'; 


const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTab-textColorPrimary': {
       //color: '#3e699e', 
    },
    '& .MuiTabs-indicator': {
      height: 6, 
      //backgroundColor: '#3e699e',
      color: '#3e699e'
      },
    },
}));


let configOptions = [
    //{hash: "#/instand_dashboard", value: 0},
    {hash: "#/dir_list", value: 0},
    {hash: "#/dir_overview/", value: 0},
    {hash: "#/gpap_doc", value: 1},
    {hash: "#/contact", value: 2},


]

function getInitVal(){

    let hash = configOptions.find(s => window.location.hash.includes(s.hash));
    if(hash){
        return hash.value;
    }
    else{ return -1}

}

function InstandHeader({authorization}){

    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(getInitVal());
    const classes = useStyles();


    const handleChange = (newValue) => {
      setValue(newValue);
    };

    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
      if(authorization){
      auth.user.keycloak.logout()
        .then(() => {
          console.log("Logout successful");
        })
        .catch((e) => {
          console.error("Logout failed", e);
        });
      }
    };
    
    return <AppBar position="static" color="white" >
    <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <Link to="/#">
            <img src={GPAP_Logo} alt="GPAP" component={Link} to="/#" style={{width: "250px"}}/>
          </Link>    
        </Typography> 

        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary" 
            textColor="primary"
            className={classes.tabs}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#3e699e',
                //color:'#3e699e'
              },
              '& .MuiTab-label': {
                color: '#3e699e',
              },
              width:'450px'
            }}>

            {/* <CustomTab label="Dashboard" component={Link} to="/instand_dashboard"  className={classes.tab}
            sx={{fontSize: '16px', fontWeight:'400', color:'black', width:'100px'}}/> */}
            <CustomTab label="Interpretation Requests" component={Link} to="/dir_list" className={classes.tab} 
            sx={{ fontSize: '16px', color:'black', fontWeight:'400',width:'210px'}}/>
            <CustomTab label="Docs" component={Link} to="/instand_doc" target="_blank" className={classes.tab}
            sx={{fontSize: '16px', color:'black', fontWeight:'400',width:'100px'}}/>
            <CustomTab label="Contact" component={Link} to="/contact" className={classes.tab} 
            sx={{fontSize: '16px', color:'black', marginRight:'30px', fontWeight:'400',width:'100px'}}/>
          </Tabs>
        </Box>

        <LanguageSelector/>

        <IconButton color="#3e699e" marginRight= '3px' disableRipple 
        sx={{ textDecoration: 'none', color:"#3e699e", margin:'0 20px', backgroundColor: 'rgba(62, 105, 158, 0.1)', width: '32px', height: '32px', borderRadius:'4px'}}>
          <NotificationsIcon color="#3e699e"/>
        </IconButton>
        <IconButton  disableRipple 
        sx={{ backgroundColor: 'rgba(62, 105, 158, 0.1)', color:"#3e699e", width: '78px', height: '39px', borderRadius:'50px' }}
        onClick={handleClick}>

          <Avatar 
          sx={{ width: '30px', height: '30px', fontSize:'16px', marginRight: '10px',bgcolor:"#3e699e" }}>  
            {authorization?.preferredName ? authorization.preferredName.charAt(0).toUpperCase() : ''}

            </Avatar>
          <SettingsIcon sx={{ width: '20px', height: '20px'}}/>
        </IconButton>
        
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        >
          <MenuItem onClick={handleClose}>{authorization?.preferredName}</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose} component={Link} to="/instand_settings">
            <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
            <MappedText text={"Settings"}/>
          </MenuItem>
          <Divider/>
          <MenuItem onClick={() => handleLogout(authorization)}>
            <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
            <MappedText text={"Logout"}/>
          </MenuItem>
      </Menu>
    </Toolbar>
  </AppBar>
}

function mapStateToProps (state) {
  if(state.authorization!==undefined)
  {
      return {authorization: state.authorization}
  }
}



export default connect(mapStateToProps,[])(InstandHeader);