import React, {Component} from "react";


import * as Actions from "../../../actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
    getGenes_fromHPO, getHPO_fromDisgeNet, standardHPOSearch
} from "../../../../../../../../../../../../services/api/get";

import "./hpo-search.css";
import HPO_QueryBuilder from "./HPO_QueryBuilder";


export function intersection() {
    let result = [];
    let lists;

    if(arguments.length === 1) {
        lists = arguments[0];
    } else {
        lists = arguments;
    }

    for(let i = 0; i < lists.length; i++) {
        let currentList = lists[i];
        for(let y = 0; y < currentList.length; y++) {
            let currentValue = currentList[y];
            if(result.indexOf(currentValue) === -1) {
                if(lists.filter(function(obj) { return obj.indexOf(currentValue) === -1 }).length === 0) {
                    result.push(currentValue);
                }
            }
        }
    }
    return result;
}




class HPO_Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fetched_Genes: [],
            geneList: [],
            diseaseList: [],
            extracted_hpo: [],
            umls_array: [],
            array_list: [],
            loading: false,
            operation: "union",
            list_genes: []
        };

        this.passData = this.passData.bind(this);
        this.add_genes_to_store = this.add_genes_to_store.bind(this);
        this.apply_HPO_Ontology = this.apply_HPO_Ontology.bind(this);
        this.applyDisGenet = this.applyDisGenet.bind(this);
        this.reset = this.reset.bind(this);
        this.resetHPOs = this.resetHPOs.bind(this);
        this.extract_patients_HPOs = this.extract_patients_HPOs.bind(this);
        this.removeHPO = this.removeHPO.bind(this);
        this.make_intersection = this.make_intersection.bind(this);
        this.LIST_TYPE= "HPO"
    }



    componentDidMount() {

        let{genes} = this.props;

        if(genes.hasOwnProperty("applied_lists")){

            let hpo_list = genes.applied_lists.find(s => s.type === "HPO");

            if(hpo_list!== undefined){

                let pheno_hpos = hpo_list.list.find(item => item.from === "phenostore").list;
                let searched = hpo_list.list.find(item => item.from === "search").list;

                if(searched!== undefined && pheno_hpos!== undefined){
                    this.setState({
                        extracted_hpo: pheno_hpos.concat(searched)
                    })
                }


            }
            else{
                this.extract_patients_HPOs()
            }

        }

    }


    // function called when I confirm;
    add_genes_to_store(genes) {


        let {actions, operator} = this.props;

        let obj_1 = {from: "phenostore", list: this.state.extracted_hpo};
        let obj_2 = {from: "search", list: this.state.data};

        let save_obj = {
            "id": "hpo_list",
            "list": [obj_1,obj_2],
            "type": this.LIST_TYPE,
            "n_genes": genes.length
        };

        actions.add_applied_list(save_obj);

        if (operator === "intersection") {
            //actions.add_genes_to_specific_genelist(genes,"symptoms");
            actions.intersect_genelist(genes, "symptoms");
        } else {
            actions.add_genes_to_specific_genelist(genes,"symptoms");
        }

    }

    resetHPOs() {

        this.setState({
            extracted_hpo: []
        })


        }

    reset(){

        // remove list of genes..
        this.props.actions.remove_genes_from_list([...new Set(this.state.geneList)], "symptoms" );
        this.props.actions.remove_list_types("hpo_list");

        this.setState({
            data: [],
            fetched_Genes: [],
            geneList: [],
            list_genes:[],
            diseaseList: [],
            extracted_hpo: []
        })


    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        return nextProps.patients.length !== this.props.patients.length
            || nextState.geneList.length !== this.state.geneList.length
            || nextState.extracted_hpo.length !== this.state.extracted_hpo.length
            || nextState.data.length !== this.state.data.length
            || nextState.umls_array.length !== this.state.umls_array.length
            || nextState.loading!== this.state.loading;

    }


    apply_HPO_Ontology() {

        let {data} = this.state;
        let self = this;
        let all_hpo = this.state.extracted_hpo.concat(data);

        // reset;


        // get data;
        let list_genes = [];
        let geneList = [];


        all_hpo.forEach(function(hpoTerm){

            getGenes_fromHPO(hpoTerm.refCode).then(function (response) {return response.json()}).then(json => {
                let { genes } = json;
                if (genes !== undefined) {
                    let extract_genes = genes.map(g => g.name);
                    list_genes.push(extract_genes);

                    // union or intersection
                    self.setState(state => {
                        geneList = geneList.concat(extract_genes);
                        return {
                            geneList,
                            fetched_Genes: genes,
                            list_genes: list_genes
                        };
                    });
                }
            }
            )

        })

            /*  get_Diseases_from_HPO(hpoTerm.refCode)
                  .then(function(response){ return response.json()
                  })
                  .then(function(json)
                  {
                      let { diseases } = json;

                      if(diseases!== undefined){

                          // TODO: check for duplicates;
                          self.setState(state => {
                              const diseaseList = state.diseaseList.concat(diseases);
                              return {
                                  diseaseList,
                                  fetched_Diseases: diseases
                              };
                          });
                      }
                  })*/

    }


    make_intersection  = (operation_type) => {

        let myList = [];
        let { list_genes, geneList, tot_genes } = this.state;
        const all_genes = (tot_genes!== undefined) ? [...tot_genes] : [...geneList];
        if (operation_type === "union") {
            myList = [...all_genes]
        } else {
            myList = intersection(list_genes);
        }

        this.setState({
            geneList: myList,
            tot_genes: all_genes,
            operation: operation_type
        })


    };


    async applyDisGenet() {

        let { api_endpoint } =  window.config;
        const self = this;

        let { token } = this.props;

        let {umls_array} = this.state;

        let string_umls = umls_array.toString();

        this.setState({loading:true})

        getHPO_fromDisgeNet(api_endpoint, string_umls, token)
            .then(resp => {
                if(resp.ok){
                    return resp.json()
                }
                else{
                    return {}
                }
            })
            .then(json => {
                this.setState({loading:false});
                if(json.hasOwnProperty("data")){
                    self.setState(state => {
                        const geneList = state.geneList.concat(json.data);
                        return {
                            geneList,
                            //tot_genes: [...geneList],
                            fetched_Genes: json.data
                        };
                    });
                }
            })
    }

    extract_patients_HPOs(){
        let { patients } = this.props;
        const self = this;

        if(patients.length!==0){
            let pat_features = patients.map(p => p.features).filter(f => f!== undefined);
            let extracted_hpo = [];
            pat_features.forEach(function(d){
                if(Array.isArray(d)){
                    d.forEach(function(hpo){
                        if(hpo.observed){
                            extracted_hpo= extracted_hpo.concat(hpo)
                        }

                    })
                }
            }) ;

            let umls_array = [...this.state.umls_array];

            extracted_hpo.forEach(function(hpo_item){

                if(hpo_item!== undefined){
                    standardHPOSearch(hpo_item.id)
                        .then(resp => resp.json())
                        .then(json => {
                            
                            if (json.terms.length > 0) {
                                let UMLS_array = json.terms[0].xrefs
                                    .filter(x => x.includes("UMLS"))
                                    .map(x => x.replace("UMLS:", ""));

                                umls_array = [...new Set(umls_array.concat(UMLS_array))];
                            }

                            self.setState({umls_array})

                        })
                }

            });


            this.setState({
                extracted_hpo: extracted_hpo
                    .filter(h => h!== undefined)
                    .map(function(hpo){

                        let label = (hpo.label!== undefined) ? hpo.label : hpo.name ;

                        return {label: label, refCode: hpo.id, value: hpo.id + ", " + label }}),
            })
        }

    };


    async passData(obj) {

        let {actions} = this.props;
        let self = this;

        let last_hpo = obj[obj.length - 1];
        let list = await standardHPOSearch(last_hpo.refCode).then(resp => resp.json());

        //let UMLS_array = list.details.xrefs.filter(x => x.includes("UMLS")).map(x => x.replace("UMLS:", ""));
        let UMLS_array = list.terms[0].xrefs.filter(x => x.includes("UMLS")).map(x => x.replace("UMLS:", ""));


        let umls_array = [...this.state.umls_array];

        umls_array = [...new Set(umls_array.concat(UMLS_array))];

        this.setState({data: obj, umls_array: umls_array})

    }

    removeHPO(hpo)
    {
        let array = this.state.extracted_hpo.filter(function( obj ) {
            return obj.value !== hpo;
        });

        this.setState({extracted_hpo: array})

    }


    render() {


        let patientsBool = (this.props.patients.length === 0);
        let hpo_bool = (this.state.extracted_hpo.length === 0 && this.state.data.length === 0);

        return (
            <React.Fragment>
                <HPO_QueryBuilder
                        hpo_bool={hpo_bool}
                        patientBool={patientsBool}
                        apply_HPO_Ontology = {this.apply_HPO_Ontology}
                        applyDisGenet = {this.applyDisGenet}
                        extracted_hpo={this.state.extracted_hpo}
                        searched_hpos={ this.state.data }
                        passData={this.passData}
                        add_genes_to_store = {this.add_genes_to_store}
                        geneList = {this.state.geneList}
                        diseaseList = {this.state.diseaseList}
                        reset={this.reset}
                        umls_array={ this.state.umls_array }
                        extract_patients_HPOs={this.extract_patients_HPOs}
                        removeHPO = {this.removeHPO}
                        make_intersection = {this.make_intersection}
                        loading={this.state.loading}
                        resetHPOs = {this.resetHPOs}
                />

            </React.Fragment>

        )
    }

}


export function mapGene_HPOs_ToProps (state) {
    if(state.form!==undefined)
    {
        let form = state.form;
        if(form.gene_section!== undefined && form.gene_section.values!== undefined ){
            let patients = [];
            // get patients data from the store (phenostore entries)
            if(state.patients.patients!== undefined && state.patients.patients.length!==0){patients = state.patients.patients}
            return {
                genes: state.geneList,
                operator: state.geneList.operator,
                patients: patients,
                token: state.authorization.token
            }
        }
        else{
            let patients = [];
            if(state.patients.patients!== undefined && state.patients.patients.length!==0){
                patients = state.patients.patients
            }
            return {
                genes: state.geneList,
                token: state.authorization.token,
                patients: patients
            }
        }
    }
    else{
        return {
            token: state.authorization.token,
            genes: state.geneList,
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapGene_HPOs_ToProps, mapDispatchToProps)(HPO_Search);
