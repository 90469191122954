import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from '@material-ui/icons/Share';
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";

import {connect} from "react-redux";
import CopyUrl from "./CopyURL";
import {getCurrentAnalysis} from "../../variant-table/getAnnotations";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";


function ShareDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [study_id, setStudyId] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    useEffect(function(){

        let { study_id_db } = props;

        if( study_id_db!== undefined){
            setStudyId(study_id_db)
        }

    });


    const renderContent = () => {


        if(study_id && !props.unsaved){

            const URL = window.location.origin + window.location.pathname + "#/study/" + study_id;

            return <DialogContent style={{width: "500px"}}>

                        <DialogContentText id="alert-dialog-description">
                          <Box p={1}>
                              You can share the study with your collaborators with this link.
                          </Box>
                        </DialogContentText>
                        <CopyUrl
                            url = {URL}
                        />
            </DialogContent>
        }
        else{
            return <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   Please, save the study to be able to share it.
                </DialogContentText>
            </DialogContent>
        }
    };

    return (
        <React.Fragment>
            <GPAP_Tooltip title="Share the study with collaborators">
                <IconButton id="share_btn" aria-label="Share" onClick={handleClickOpen}>
                    <ShareIcon />
                </IconButton>
            </GPAP_Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Share Study"}</DialogTitle>
                {renderContent()}
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {

        let analysis = getCurrentAnalysis(state.studySettings, state.studyBucket);

        let unsaved_bool= true

        if(window.location.href.includes("home")){
            unsaved_bool = false;
        }
        else{
            if( analysis.analysis ){
                unsaved_bool = analysis.analysis.queries.filter(query => query.query_id === undefined).length > 0;
            }
        }

        return {
            token: state.authorization.token,
            studySettings: state.studySettings,
            unsaved: unsaved_bool
        }
    }
}




export default connect(mapStateToProps, null)(ShareDialog);