


export function mapGeneOperatorToProps (state) {
    if(state.form!==undefined)
    {
        let form = state.form;
        if(form.gene_section!== undefined && form.gene_section.values!== undefined){
            return {
                operator: state.geneList.operator,
                token: state.authorization.token,
                genes: state.geneList.genes,
                applied_lists: state.geneList.applied_lists

            }
        }
        else{
            return {token: state.authorization.token, genes:[]}
        }
    }
    else{
        return {token: state.authorization.token}
    }
}