

import React from "react";
import FilterSections from "./FilterSections";
import Filters_Accordions from "./config_accordions";



function All_Filters_Accordion(props){


    let accordionPanels = Filters_Accordions;

    // check which sections are active;

    if(props.pointers!== undefined){
        // pointers
        let keys = Object.keys(props.pointers);
        accordionPanels.forEach(function(s){
            keys.forEach(my_key => {
                if(s.queryKeys.includes(my_key) || s.key === my_key){
                    s.active = true;
                }
            })
        })
    }



    return <FilterSections
        sections={accordionPanels}
        default_filters = { props.default_filters }
        somatic = {props.somatic}

    />;

}


export default All_Filters_Accordion