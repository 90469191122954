

import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import GPAP_Tooltip from "../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import Link from "@material-ui/core/Link";


const cells= [
    { id: 'famid', numeric: false, disablePadding: false, label: 'Fam.ID' },
    { id: 'ExperimentID', numeric: false, disablePadding: false, label: 'Experiment ID' },
    { id: 'LocalExperimentID', numeric: false, disablePadding: false, label: 'Local Exp. ID' },
    { id: 'n_variants', numeric: true, disablePadding: false, label: 'Variants' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project' },
    { id: 'affectedStatus', numeric: false, disablePadding: false, label: 'Affected Status' },
    { id: 'consanguinity', numeric: false, disablePadding: false, label: 'Consanguinity' },
    { id: 'sex', numeric: false, disablePadding: false, label: 'Sex' },
    { id: 'solved', numeric: false, disablePadding: false, label: 'Solved Status' },
    { id: 'features', numeric: false, disablePadding: false, label: 'Obs. HPOs' },
    { id: 'diagnosis', numeric: true, disablePadding: false, label: 'Diagnosis' }
]


const fillBlueSpan = { "color": "white","background": "#3182bd","borderRadius": "5px","padding": "5px"};
const outlineBlueSpan = { "color": "#3182bd","background": "white", "borderRadius": "5px","padding": "5px","border": "1px solid #3182bd"};


function getCells(row){

    return cells.map(function(x){

        if (x.id === "diagnosis") {
            if(row[x.id]!== undefined && Array.isArray(row[x.id])){
                if (row[x.id].length !== 0) {
                    let diagnosis_string = row[x.id].map(diagnosis => diagnosis.ordo.name).join(", ");
                    return <TableCell style={{fontSize: "13px"}} >{diagnosis_string}</TableCell>;
                } else {
                    return <TableCell style={{fontSize: "13px"}} >NA</TableCell>;
                }
            }
            else{
                return <TableCell style={{fontSize: "13px"}} align="left">NA</TableCell>;
            }

        }
        if (x.id === "features") {
            if(row[x.id]!== undefined && Array.isArray(row[x.id])){
                if (row[x.id].length !== 0) {
                    let hpo_string = row[x.id].filter(f => f.observed === true).map(term => term.name).join(", ");
                    return <TableCell padding="none"
                                     >
                        <GPAP_Tooltip title={hpo_string}>
                            <div style={{fontSize: "13px", maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} >
                                {hpo_string}
                            </div>
                        </GPAP_Tooltip>
                    </TableCell>;
                } else {
                    return <TableCell padding="none" style={{fontSize: "13px"}} >NA</TableCell>;
                }
            }
            else{
                return <TableCell padding="none" style={{fontSize: "13px"}} align="left">NA</TableCell>;
            }

        }else if (x.id === "projects" && row["project"] !== undefined) {
            return <TableCell style={{fontSize: "13px"}} >{row["project"]}</TableCell>;
        } else if (row[x.id] === "") {
            return <TableCell style={{fontSize: "13px"}} >NA</TableCell>;
        }
        else if(x.id === "famid"){
            return  <TableCell style={{fontSize: "13px"}} >
                <Link color="primary" target="_blank" href={origin + "/phenostore/#/family/" + row[x.id]}>{row[x.id]}</Link>
            </TableCell>
        }
        else if(x.id === "ExperimentID"){
            return <TableCell style={{fontSize: "13px"}} >
                <Link color="primary" target="_blank" href={origin + "/datamanagement/#/experimentinfo/" + row[x.id]}>{row[x.id]}</Link>
            </TableCell>
        }
        else if (row[x.id] !== undefined ) {
            if(x.id=== "affectedStatus"){

                let affected = row[x.id] === "Affected";
                let style = (affected)? fillBlueSpan : outlineBlueSpan

                return <TableCell padding="none" style={{fontSize: "13px"}}>
                    <span style={style}>
                        {row[x.id]}
                    </span>
                </TableCell>
            }
            else{
                return <TableCell padding="none"
                                  align={"center"}
                                  style={{fontSize: "13px"}}>
                    {row[x.id]}
                </TableCell>
            }

        }  else {
            return <TableCell padding="none"
                              style={{fontSize: "13px", color: "lightgray"}}
                              align={"center"}>NA</TableCell>
        }

    } )

}


export default getCells;