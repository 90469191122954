import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import{ Box, Button} from "@material-ui/core";
import {GPAP_Button} from "../../../../../../../../../../../gpap-lib/components/Button_Collection";
import BackupIcon from "@material-ui/icons/Backup";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

function List_Upload(props){


    const classes = useStyles();

    let { operator, actions } = props;
    const [genes, setGenes] = useState([]);


    const handleFile = (input) => {

        const reader = new FileReader();
        reader.onload = function(){
            let text = reader.result;
            //let node = document.getElementById('output');
            //node.innerText = text;
            text = reader.result.replace(/(\r\n|\n|\r)/gm,"");
            let geneList = text.split(",");
            let final = geneList.filter(s => s !== "");
            setGenes(final);
            props.getGenes(final);
        };
        reader.readAsText(input.target.files[0]);

    };

    const clear = () => {

        setGenes([]);

    };

    return (
        <Box>
            {(genes.length === 0)
                ? <React.Fragment>
                      <div>
                            <Typography variant={"body1"}>
                                Here, you can upload a comma separated list.
                            </Typography>
                        </div>
                      <div className={classes.root}>
                            <input
                                accept="text/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleFile}
                            />
                            <label htmlFor="contained-button-file">
                                <Button  variant="contained" color="#EAEAEA" disableElevation component="span" startIcon={<BackupIcon/>}>
                                    Upload File
                                </Button>
                            </label>
                        </div>
                </React.Fragment>
                : <Typography variant={"subtitle1"}> <DoneIcon color={"primary"}/> Uploaded {genes.length} genes <IconButton onClick={clear}><ClearIcon/></IconButton></Typography>
            }
        </Box>
    );
}


export default List_Upload;