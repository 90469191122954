import {
    DELETE_SAMPLE,
    RESET_GENOTYPES,
    RESET_SAMPLES,
    SET_GENOTYPE,
    SET_GQ_PARAMETER,
    SET_SA_SAMPLES,
    SET_SAMPLES,
    UPDATE_GENOTYPE,
    SET_COHORT_SAMPLE, REMOVE_GENOTYPE, SET_AFFECTED_STATUS,
    SET_SOMATIC_SAMPLES
} from "./sample_actions";
import {calculateSettings} from "../components/GenotypeBuilder/helpers/calculateSettings";


const initialState = {
    samples:[],
    somatic_samples: []
};



export const sampleReducer = (state =initialState, action) => {


    switch (action.type) {
        case SET_SAMPLES:
            let current_samples = [...state.samples];
            let bool = current_samples.length !==0 && current_samples.find(s => s.sample_id === action.sample_id) === undefined;
          
            if(current_samples.length === 0 || bool){
                return {
                    ...state,
                    samples: current_samples.concat([{
                        sample_id: action.sample_id,
                        pheno_id: action.pheno_id,
                        sample_type: action.sample_type,
                        gt_settings: (action.sample_id === "ALL_SAMPLES") ? action.gt : [] ,
                        affectedStatus: action.affectedStatus, 
                        "experiment_type": action.experiment_type
                    }])
                };
            }
            else{
                return state;
            }
        case SET_SOMATIC_SAMPLES:
            let s_samples = [...state.somatic_samples];
            let bool_add =  s_samples.find(s => s.sample_id === action.sample_id) === undefined;
            if(s_samples.length === 0 || bool_add){
                return {
                    ...state,
                    somatic_samples: s_samples.concat([{
                        sample_id: action.sample_id,
                        pheno_id: action.pheno_id,
                        sample_type: action.sample_type,
                        gt_settings: action.gt ,
                        affectedStatus: action.affectedStatus,
                        "experiment_type": action.experiment_type,
                        experiment_somatic: action.experiment_somatic

                    }])
                };
            }
            else{
                return state;
            }
        case SET_COHORT_SAMPLE:
            let samples = [...state.samples];
            let bool_samples = samples.length !==0 && samples.find(s => s.sample_id === action.sample_id) === undefined;
            if(samples.length === 0 || bool_samples){
                return {
                    ...state,
                    samples: samples.concat([{
                        sample_id: action.sample_id,
                        pheno_id: action.pheno_id,
                        sample_type: action.sample_type,
                        gt_settings: action.gt ,
                        affectedStatus: action.affectedStatus,  //TODO: connect the sidebars
                        "experiment_type": action.experiment_type
                    }])
                };
            }
            else{
                return state;
            }
        case SET_SA_SAMPLES:
            return {
                ...state,
                samples: [...state.samples,{
                    sample_id: action.sample.sample_id,
                    pheno_id: "",
                    sample_type: "other",
                    gt_settings: action.sample.gt,
                    affectedStatus: action.sample.index,  //TODO: connect the sidebars
                    /*"dp":10,
                    "gq":30,
                    "ad_low":0.2,
                    "ad_high":0.8*/

                }]
            };
        case RESET_SAMPLES:
            return initialState;
        case DELETE_SAMPLE:
            let current_state = Object.assign({}, state);
            current_state.samples = current_state.samples.filter(s => s.sample_id!== action.sample_id);
            return current_state;
        case RESET_GENOTYPES:
            let new_state = Object.assign({}, state);
            new_state.samples.forEach(function(s){ s["gt_settings"]=[]});
            return new_state;
        case SET_GENOTYPE:
            //sample_id
            let state_new = Object.assign({}, state);
            if(action.sample_id === "ALL_SAMPLES"){

                let found = state_new.samples.find(s => s.sample_id === action.sample_id);
                let gt = {...action.gt};
                gt.setting = [...new Set(gt.setting)];
                gt.settings_id = action.settings_id;

                let custom_exists = found["gt_settings"].find(s => s.inheritance_key === "custom") !== undefined;

                // avoid adding multiple inheritances;
                if(!custom_exists){
                    found["gt_settings"] = found["gt_settings"].concat(gt);
                    found["index"] = true;
                }
            }
            else{

               // check exact sample to avoid picking a wrong sample if patients have more than 1.
                let sample = state_new.samples.find(s => s.sample_id === action.sample_id);
                let gt = {...action.gt};
                gt.setting = [...new Set(gt.setting)]
                let ad_low_high = calculateSettings(sample,gt.setting);

                // TODO: Assign here a gt_settings id..
                gt.settings_id = action.settings_id

                // I need to compare inheritance key and label (e.g. case AR and homozygous/chet
                let inheritance_exists = sample["gt_settings"].find(s => s.inheritance_key === gt.inheritance_key && s.label === gt.label);

                // TODO: use settings id

                //let inheritance_exists = sample["gt_settings"].find(s => s.settings_id === gt.settings_id );

                if(inheritance_exists === undefined){
                    gt.ad_low=ad_low_high[0];
                    gt.ad_high=ad_low_high[1];
                    sample["gt_settings"] = sample["gt_settings"].concat(gt);
                    sample["index"] = action.index;
                }
            }

            return state_new;
        case UPDATE_GENOTYPE:
            return {...state,
                samples: state.samples.map(function(sample){
                    if(sample.sample_id === action.sample_id){
                        // here I should set up the gt settings...

                        let sample_new = Object.assign({}, sample);

                        //get gt and tune alternate allele settings;

                        let ad_low_high = calculateSettings(sample_new, action.gt)

                        // TODO: use settings id

                        sample_new["gt_settings"]
                            .filter(sett => sett.inheritance_key === action.inheritance_key && sett.label === action.inheritance_type).forEach(inheritance =>{
                                if(inheritance!== undefined){
                                    inheritance.ad_low=ad_low_high[0];
                                    inheritance.ad_high=ad_low_high[1];
                                    inheritance.setting = action.gt;
                                }
                            })

                        return sample_new

                    }
                    else{
                        return sample;
                    }
                })
            };
        case REMOVE_GENOTYPE:
            return {...state,
                samples: state.samples.map(function(sample){
                    // load sample;
                    sample.gt_settings = sample.gt_settings.filter(gt => gt.inheritance_key !== action.inheritance_key && gt.label !== gt.inheritance_type);
                    return sample;
                    }
                )}
        case SET_GQ_PARAMETER:
                // updated samples
                let updated_samples = state.samples.map(function(sample){
                    if(sample.sample_id === action.sample_id){
                        let sample_edit = Object.assign({}, sample);
                        // take right settings
                        sample_edit.gt_settings.forEach(function(el) {
                                if (el.inheritance_key === action.inheritance_key && el.label === action.inheritance_type) {
                                    if (action.gq_key === "ad") {
                                        el["ad_low"] = action.value[0] / 100;
                                        el["ad_high"] = action.value[1] / 100;
                                    } else {
                                        el[action.gq_key] = action.value;
                                    }

                                    // if cohort case
                                    if(action.analysisType!== undefined && action.analysisType === "cohort"){
                                        state.samples.forEach(function(sample_edit){

                                            if(sample_edit.sample_id !== action.sample_id){
                                                sample_edit.gt_settings.forEach(function(item) {
                                                    if (item.inheritance_key === action.inheritance_key && item.label === action.inheritance_type) {
                                                        item["ad_low"] = el["ad_low"]
                                                        item["ad_high"] = el["ad_high"]
                                                        item["dp"] = el["dp"]
                                                        item["gq"] = el["gq"]
                                                    }

                                                })
                                            }
                                        })
                                    }
                                }
                            }
                        )

                        return sample_edit
                    }
                    else{
                        return sample;
                    }
                })

                // if cohort case, update all of them...


            return {
                ...state, samples: updated_samples
            };
        case SET_AFFECTED_STATUS:
            let state_affected = Object.assign({}, state);
            state_affected.samples.forEach(function(sample){
                if(sample.sample_id === action.sample_id){
                    sample.affectedStatus = action.affectedStatus
                }
            })
            return state;
        default:
            return state


    }
};