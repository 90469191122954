

import React from "react";
import GPAP_Tooltip from "../../gpap-lib/components/GPAP_Tooltip";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import {parse_date} from "../../scenes/study-page/helpers";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ShareDialog
    from "../../scenes/dashboard/components/genomics-dashboard/components/results/components/toolbar/share/ShareDialog";
import DeleteDialog from "../../scenes/study-page/study-overview/deleteDialog";
import InfoIcon from "@material-ui/icons/Info";


const getIcon = (owner) => {

    if(owner){
        return <GPAP_Tooltip title={"created by you"}>
            <PersonIcon style={{ color: "#4575B4" }}/>
        </GPAP_Tooltip>
    }
    else{
        return <GPAP_Tooltip title={"shared with you"}>
            <GroupIcon style={{ color: "#4575B4" }}/>
        </GPAP_Tooltip>
    }


}


const getPermissionText = (value) => {

    switch (value){
        case "user":
            return "Visible only to you";
        case "public":
            return "Visible to all users";
        case "group":
            return "Visible only to you and your collaborators"
    }





}


export const study_columns = [
    {
        Header: 'Study Name',
        accessor: "studyName",
        filter: 'fuzzyText',
        Cell: function({ value, row }){
            return <div>
                <div style={{padding: "5px", width: "20px", float: "left", display: "inline-block"}}>
                    {getIcon(row.original.owner)}
                </div>
                <div style={{padding: "5px", float: "left", display: "inline-block"}}>

                    <a href={"#/study/" + row.original.id}
                       style={{
                         fontSize: "14px",
                         fontWeight: "500",
                         color: "rgb(69, 117, 180)"
                     }}> {value}</a>
                </div>
            </div>


        }
    },
    {
        Header: 'Description',
        accessor: "description",
        filter: 'fuzzyText',
    },
    {
        Header: 'Created on',
        accessor: "creationDate",
        filter: 'fuzzyText',

        Cell: function({ value }){

            let data = new Date(value);

            return <div > {parse_date(data)}</div>
        }

    },
    {
        Header: 'Created by',
        accessor: "creator",
        filter: 'fuzzyText',

        Cell: function({ value }){

            return <div > {value}</div>
        }

    },
    {
        Header: 'Permission',
        accessor: "permission",
        filter: 'fuzzyText',
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: e =>  <div
            style={{color: "#3182bd", fontWeight: "500", borderRadius: "5px",fontSize: "14px", padding: "2px", display: "inline-block" }}
        > {getPermissionText(e.value)} </div>
    },
    {
        Header: 'Actions',
        accessor: "id",
        filter: 'fuzzyText',
        Cell: function({ value, row }){
            return <div>
                <Grid container direction={"row"}>
                    <Grid item>
                        <Box p={0}>
                            <ShareDialog study_id_db={value}/>
                        </Box>
                    </Grid>
                    { (row.original.owner) ?   <Grid item>
                        <Box p={0}>
                            <DeleteDialog
                                onlyIcon={true}
                                study_id = {value}
                                go_to_study = {() => {
                                    window.location.reload()
                                    //window.location.href= window.location.origin + "/nextgpap/#/home"
                                }}
                            />
                        </Box>
                    </Grid> : null }
                </Grid>
            </div>


        }
    },
]