import React from "react";
import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

export const PATIENT_DATA_HEADER = [
    {
        header: "Patient reports",
        columns: [
            { nameTable: <MappedText text={"Report ID"}/> , dataSearch: "report_id", tooltip: "" },
            { nameTable: <MappedText text={"Associated Clinical Report ID"}/>, dataSearch: "associated_clinical_report_id", tooltip: "" },
            { filterType: { type: "date" }, filter: "dateFilter", nameTable: <MappedText text={"Creation Date"}/>, dataSearch: "deadline", tooltip: "" },
            { nameTable: <MappedText text={"Assignee(s)"}/>, dataSearch: "assignee", tooltip: "" },
        ],
    },
];
