



export const minDepth_props = {
    defaultValue:10,
    marks:[
        {
            value: 0,
            label: '0',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 30,
            label: '30',
        },
        {
            value: 40,
            label: '40',
        },
        {
            value: 50,
            label: '50',
        },
        {
            value: 60,
            label: '60',
        },
        {
            value: 70,
            label: '70',
        },
        {
            value: 80,
            label: '80',
        },
        {
            value: 90,
            label: '90',
        },
        {
            value: 100,
            label: '100',
        },

    ],
    pushable:true,
    allowCross:false,
    min:0,
    max:100,
    step:10,
};

export const gQ_Range_props = {
    defaultValue:10,
    marks: [
        {
            value: 0,
            label: '0',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 30,
            label: '30',
        },
        {
            value: 40,
            label: '40',
        },
        {
            value: 50,
            label: '50',
        },
        {
            value: 60,
            label: '60',
        },
        {
            value: 70,
            label: '70',
        },
        {
            value: 80,
            label: '80',
        },
        {
            value: 90,
            label: '90',
        },
        {
            value: 100,
            label: '100',
        },

    ],
    pushable:true,
    allowCross:false,
    min:0,
    max:100,
    step:10,
};

export const Alternate = {
    defaultValue:[20,80],
    marks:[
        {
            value: 0,
            label: '0',
        },
        {
            value: 10,
            label: '0.1',
        },
        {
            value: 20,
            label: '0.2',
        },
        {
            value: 30,
            label: '0.3',
        },
        {
            value: 40,
            label: '0.4',
        },
        {
            value: 50,
            label: '0.5',
        },
        {
            value: 60,
            label: '0.6',
        },
        {
            value: 70,
            label: '0.7',
        },
        {
            value: 80,
            label: '0.8',
        },
        {
            value: 90,
            label: '0.9',
        },
        {
            value: 100,
            label: '1',
        },

    ],
    pushable:true,
    allowCross:false,
    min:0,
    max:100,
    step:10,
};

