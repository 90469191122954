

import labelIcons from "./labelIcons";
import React from "react";


function getGPAPLabelIcon(labelKey) {

    const IconComponent = labelIcons[labelKey];

    if (IconComponent) {
        return <IconComponent style={{ color: "#3182bd" }} />;
    } else {
        return null; // Handle cases where labelKey doesn't exist in the lib object
    }
}

export default getGPAPLabelIcon