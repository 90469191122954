


import React, {useEffect, useState} from "react";
import {get_all_studies} from "../../services/api/manage_study";
import Grid from "@material-ui/core/Grid";
import {bindActionCreators} from "redux";
import * as StudyActions from "../dashboard/reducers/study_actions";
import {connect} from "react-redux";
import "./study-view.css";
import GeneralTable from "../../gpap-lib/components/general-table/GeneralTable";
import {study_columns} from "../../config-files/table-config/study_columns";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";



function StudyView(props){

    const { t, i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [studies, setStudies] = useState(undefined);


    useEffect(function(){

        if(!loaded){
            refreshStudies()
        }

    },[]);

    const refreshStudies = () => {

        get_all_studies( config.apis_configuration.api_nextgpap_server, props.token)
            .then(resp => resp.json())
            .then(json => {
                if(json.message === "Success"){
                    setLoaded(true);
                    setStudies(json);
                }
        })

    };


    if(studies !== undefined && studies.hasOwnProperty("data")){
        studies.data.sort(function(a, b) {
            let a_date = a.creationDate
            let b_date= b.creationDate
            return new Date(b_date) - new Date(a_date) })
    }


    return  <div className={"studySaved"}>
    <Grid container direction={"row"} alignItems={"center"}>
        <Grid item lg={12}>
            {
                (studies!== undefined) ?
                    <React.Fragment>
                        <div style={{width: '100%', padding:"1%"}}>
                            <Typography variant={"h4"}> Available studies </Typography>
                        </div>
                        <GeneralTable data={ studies.data }
                                      columns={study_columns}/>
                    </React.Fragment>
                     : null
            }


         {/*   <StudyListView
                studies={studies}
                loadStudy={props.loadStudy}
                refreshStudies = {refreshStudies}
            />*/}
        </Grid>
    </Grid>
    </div>

}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings
        }
    }
}
const mapDispatchToProps = (dispatch) => ({

    studyActions: bindActionCreators(StudyActions, dispatch)

});


export default connect(mapStateToProps, mapDispatchToProps)(StudyView);
