


import * as COLLECTIONS from "../../../../../../../config-files/filter_collections";


export function variant_type_parser(variant_type,query_to_run){

    let query_key = 'variant_types';
    if(variant_type!== undefined)
    {
        let values_array = variant_type[query_key];
        COLLECTIONS.variant_section_classes.forEach(function(itemClass){
            let item = values_array.find(obj => obj.key === itemClass);
            if(item && item.values!==undefined)
            {
                let updated_query = value_assigner(itemClass,item, query_to_run);
                query_to_run = {...updated_query}

            }
        });
    }

    return {...query_to_run};

}


function process_values(queryValues, values_obj){

    if(Object.keys(values_obj).length !== 0){
        Object.keys(queryValues).forEach(function(query_key){
            queryValues[query_key] = !!values_obj[query_key];
        });
    }
    else{
        Object.keys(queryValues).forEach(function(query_key){
            queryValues[query_key] = false;
        });
    }


    return queryValues

}


function value_assigner(itemClass,item, query){

    const values_obj = item.values;
    let query_to_run = {... query};

    switch (itemClass) {
        case 'variantclasses':
            let b = query_to_run[itemClass];
            query_to_run[itemClass] = process_values(b,values_obj);
            return query_to_run;
        case 'variantconsequences':
            //if (Object.values(values_obj).includes(true)){
                query_to_run[itemClass] = process_values(query_to_run[itemClass],values_obj);
            //}
            return query_to_run;
        case 'v_type':
            query_to_run['svn'] = (values_obj.hasOwnProperty('snv')) ?  values_obj.snv: false;
            query_to_run['indel'] = (values_obj.hasOwnProperty('indel')) ?  values_obj.indel: false;
            return query_to_run;
        case 'v_tagged':
            return query_to_run;
        case 'clinvarclasses':
            query_to_run[itemClass] = process_values(query_to_run[itemClass],values_obj);
            return query_to_run;
        case 'genotypefeatures':
            query_to_run[itemClass] = process_values(query_to_run[itemClass],values_obj);
            return query_to_run;
        case 'onco_filter':
            query_to_run[itemClass] = process_values(query_to_run[itemClass],values_obj);
            return query_to_run;
        case 'Mane':
            if (values_obj.hasOwnProperty('Mane')) {query_to_run[itemClass] =  values_obj.Mane;}
            return query_to_run;
        default:
            return query_to_run;
    }

}



export function snv_effects_parser(snv_effects,query_to_run){
    let query_key = 'snv_effects';
    if(snv_effects!== undefined)
    {
        let values_array = snv_effects[query_key];
        COLLECTIONS.snv_effects.forEach(function(itemClass){
            let item = values_array.find(obj => obj.key === itemClass);


            if(item!== undefined && (item.key === "cadd_phred" )){
                query_to_run[itemClass] = (item.value!== null && !isNaN(parseInt(item.value))) ? parseInt(item.value) : item.value;
            } //another else if for parsing a float
            else if (item!== undefined && (item.key === "revel" || item.key ==="spliceai_max_ds")){
                query_to_run[itemClass] = (item.value!== null && !isNaN(parseInt(item.value))) ? parseFloat(item.value) : item.value;
            }
            
            else if(item!== undefined && item.key === "OCScore"){
                query_to_run[itemClass] = (item.value!== null && !isNaN(parseFloat(item.value))) ? parseFloat(item.value) : item.value;
            }
            else if(item && item.values!==undefined)
            {
                let values_obj = item.values;
                let b = query_to_run[itemClass];
                query_to_run[itemClass] = process_values(b,values_obj);
            }
        });

    }
}


function getPopVal(values_array, key){

    let pop_item = values_array.find(item => item.key === key);

    let alt_value = (pop_item!== undefined && pop_item.value !== null && !isNaN(pop_item.value)) ? parseFloat(pop_item.value) : null;

    return (pop_item === undefined)
        ? null
        : alt_value

}

// TODO: very hard coded; find a way to generalize this;
export function population_parser(populationForm, query_to_run){
    let query_key = 'population';
    if(populationForm!== undefined)
    {
        let values_array = populationForm[query_key];
        query_to_run.population['gnomad_af'] = getPopVal(values_array,'gnomad_af');
        query_to_run.population['gp1_af'] = getPopVal(values_array,'gp1_af');
        query_to_run['freqInt'] = getPopVal(values_array,'freqInt');

        let gnomad = values_array.find(d => d.key === 'gnomad_filter');
        if(gnomad!== undefined)
        {
            let values_obj = gnomad.values;
            let b = query_to_run['gnomad_filter'];
            query_to_run['gnomad_filter'] = process_values(b,values_obj);
        }


    }
}


export function position_parser(positionForm, query_to_run){
    if(positionForm!== undefined){
        let values_array = positionForm['position_roh'];
        query_to_run.chrom = [];
        let chrom_value = values_array.find(item => item.key === 'chrom');

        if(chrom_value!== undefined && !Array.isArray(chrom_value)){
            // see if you have start or end
            let start  = values_array.find(item => item.key === 'start');
            let end  = values_array.find(item => item.key === 'end');
            let pos_start = (start!== undefined) ? parseInt(start.value) : -1;
            let pos_end = (end!== undefined) ? parseInt(end.value) : -1;
            // check if array should be empty;
            if(!Array.isArray(chrom_value.value)){
                query_to_run.chrom.push({"chrom": chrom_value.value, "pos": pos_start, "end_pos": pos_end });
            }
            
        }

    }

}


// may do some more complicated stuff;
export function genes_parser(genes, query_to_run){
    if(genes === undefined){
        query_to_run.gene = [];
    }
    else{
        query_to_run.gene = [...genes];
    }

}
