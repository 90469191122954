import React from "react";
import {PATIENT_DATA_HEADER} from "./config/patient_data_header.js";
import CustomTable from "../../../general-table/components/tables/CustomTable";
import { useSelector } from "react-redux";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import jsonData from "./json/patient.json";

export default function PatientReportTable () {
    const token = useSelector(state => state.authorization.token);


    const url = window.config.apis_configuration.api_nextgpap_server
    return (
        <Grid container direction={"row"}>
            <Grid item lg={12}>
                <CustomTable
                     url= {window.config.apis_configuration.api_nextgpap_server}
                     urlBase = {url}
                     urlUpload= {`${window.config.apis_configuration.api_nextgpap_server}/api/patient_report`}
                     json={jsonData}
                    token={token}
                    locationData=""
                    dataHeader= {PATIENT_DATA_HEADER}
                    //idRelation={"dir_id"}
                    classRow= "Report_Patient"
                    customFeatures= {[
                    
                        { toggle: false },
                        { expanding: false },
                        { showHeader: false },
                        {
                            actions : {
                                edit: true,
                                upload: true,
                                download: true,
                            },
                        },

                    ]}
                />
            </Grid>
        </Grid>
    );
};