
import React from "react";
import Typography from "@material-ui/core/Typography";
import SampleSummary from "./components/SampleSummary";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    analysis_toolbar: {
        margin: "1%",
        width: "100%"
    },
    analysis_box:{
        padding: "10px",
        width: "100%",
        height: "100%",
    }
}));


function AnalysisSummary(){

    const classes = useStyles();

    return <div className={"extra_view"}>
        <div className={classes.analysis_box}>
            <Typography variant="h6" align={"left"} style={{margin: "5x"}}>
                Participants
            </Typography>
            <Typography variant="subtitle2" align={"left"} style={{margin: "5x"}}>
                Currently included in the analysis
            </Typography>
            <SampleSummary/>
        </div>

    </div>;



}


export default AnalysisSummary;