



export const cnv_positionFormatters = {
    "Ensembl": (chrom, start, end) => `${chrom}:${start}-${end}`,
    "NCBI": (chrom, start, end) => `chr${chrom}:${start}-${end}`,
    "DGVa": (chrom, start, end) => `chr${chrom}:${start}..${end}`,
    "GWASCentral": (chrom, start, end) => `chr${chrom}:${start}..${end}`,
    "ClinVar": (chrom, start, end) => `chr${chrom}:${start}-${end}`,
    "gnomAD": (chrom, start, end) => `${chrom}:${start}-${end}`,
    "UCSC": (chrom, start, end) => `chr${chrom}:${start}-${end}`,
    "Franklin": (chrom, start, end) => `Chr${chrom}:${start}-${end}`,
    "VarSome": (chrom, start, end) => `chr${chrom}:${start}-${end}:${sv_type}`,
    "gnomAD Rare": (chrom, start, end )=> `${chrom}:${start}-${end}`,

    // Add more node names and their corresponding position formatters here
};