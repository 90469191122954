import React, {Component, useEffect, useRef, useState} from "react";


import MyCheckbox from "./MyCheckbox";
import * as Sample_Actions from "../../../../../reducers/sample_actions"
import {bindActionCreators} from "redux";

import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";


class CheckBoxesComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            open: false,
            custom_inher_gt: false
        };

        this.values = [{key: "REF/REF", val: "0/0"}, {key: "REF/ALT", val: "0/1"}, {key: "ALT/ALT", val: "1/1"}];
        this.updateSelected = this.updateSelected.bind(this);

    }


    componentDidMount() {

       const sample = this.props.samples.find(sample => sample.sample_id === this.props.sample_id);
       
       //Check of GT for custom inheritance
       const hasValidGtSettings = 
         this.props.inheritance_key === "custom" && 
         sample &&
         sample?.gt_settings?.length > 0 && 
         sample.gt_settings.some(setting => setting.inheritance_key === "custom" && setting.setting?.length > 0);

        this.setState({
            //selected: this.props.setting,
            selected: hasValidGtSettings ? sample.gt_settings.find(setting => setting.inheritance_key === "custom").setting : this.props.setting,
            readOnly: !(this.props.editable),
            custom_inher_gt: hasValidGtSettings ? true : false
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //let props = this.props;

        let inheritance_key = this.props.inheritance_key;

        if (inheritance_key !== prevProps.inheritance_key) {
            this.setState({
                selected: this.props.setting,
                readOnly: !(this.props.editable)
            });

        }


    }

    updateSelected = (val) => {

        let props = this.props;
        let samples_actions = props.samples_actions;
        if(!this.state.readOnly){
            let selected = [...this.state.selected];
            if (val.val && !selected.includes(val.key)) {
                selected.push(val.key);
                this.setState({
                    selected: selected
                });

                samples_actions.updateGenotype(props.sample_id,selected, props.inheritance_key, props.inheritance_type)
            }
            else if(!val.val && selected.includes(val.key))
            {
                // TODO: to check again this and remove it;
                const index = selected.indexOf(val.key);
                if (index > -1) {
                    selected.splice(index, 1);
                    this.setState({
                        selected: selected
                    });


                    samples_actions.updateGenotype(props.sample_id,selected, props.inheritance_key, props.inheritance_type)
                }
            }
        }


    };


    // setting

    render() {

        let self = this;
        let { selected,readOnly,custom_inher_gt } = this.state;
        
        let cols = this.values.map(function (val, i) {
            let bool = (selected!== undefined) ? selected.includes(val.val) : false;

            return <Grid item lg={1}>
                <MyCheckbox key= {self.props.sample_id + "_" + i}
                            updateSample={self.updateSelected}
                            checked={bool} cbkey={val.key}
                            val={val.val}
                            readOnly={readOnly}
                            custom_inher_gt = {custom_inher_gt}

                />
            </Grid>

        });
        return <React.Fragment>{cols}</React.Fragment>

    };

}


function mapStateToProps (state) {
    if(state.inheritance_key.length!== 0 )
    {
        return {
            //inheritance_key: state.inheritance_key,
            studySettings: state.studySettings, studyBucket: state.studyBucket}
    }
    else{
        return {inheritance_key: []}
    }
}


const mapDispatchToProps = (dispatch) => ({
    samples_actions: bindActionCreators(Sample_Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(CheckBoxesComponent);