

import React from "react";
import {StudyType} from "../StudyType";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";


function CreateStudy(){

    return <Grid container direction={"row"}>
        <Grid item lg={12}>
            <Box p={2}>
                <Typography variant={"h5"}>Create a new Study</Typography>
            </Box>
        </Grid>
        <Grid item lg={12}>
            <Box p={2}>
                <StudyType/>
            </Box>

        </Grid>
    </Grid>



}


export default CreateStudy;