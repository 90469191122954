



import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MuiTreeItem from '@material-ui/lab/TreeItem';
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
});

const TreeItem = withStyles({
    root: {
      /*  "&.Mui-selected > .MuiTreeItem-content": {
            color: "#e6550d",
            fontWeight: "bold"
        },*/
        "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
            fontWeight: "bold",
            color: "#e6550d",
        },
    }
})(MuiTreeItem);

export default function SampleTreeView(props) {
    const classes = useStyles();

    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [items, setItems] = useState([]);
    const MAX_AGGREGATION_SIZE = 100;

    useEffect(function(d){

        if(props.data!== undefined && props.data.length!== 0 && items.length === 0 ){

            let mydata = props.data;
            let counter = 0;
            let data_items = [];
            mydata.map(function(d, i) {
                counter += 1;
                data_items.push({
                    "id": counter.toString(),
                    "type": "sample",
                    "label":d.experiment });
                d.genes.map((gene, index) => {
                counter += 1;
                    data_items.push({"id": counter.toString(), type: "gene", label: gene.gene }); })
            });

            setItems(data_items)

        }




    }, []);

    // when clicking an experiment
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        let mydata = items.filter(s => nodeIds.includes(s.id)).filter(i => i.type === "sample");

        let sample_array = mydata.filter(function (s) {
            return nodeIds.includes(s.id);
        }).map(g=> g.label);


        let last_sample = (sample_array.length!== 0 ) ? [sample_array[0]] : sample_array;
        // genes
        let genes = [];
        if(last_sample.length>0){
            genes = props.data.find(s => s.experiment === last_sample[0]).genes.map(g => g.gene);
        }


        props.passSamples(last_sample, genes);


    };

    // when clicking a GENE
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);

        let genes = items.filter(s => nodeIds.includes(s.id)).filter(i => i.type === "gene");
        let gene_array = genes.filter(function (s) {
            return s.id === nodeIds;
        }).map(g=> g.label);

        props.passGenes(gene_array);

    };

    const renderTree = () => {
        let counter = 0;

        return props.data.map(function(d, i) {
            counter += 1;
            let exp_label = d.experiment + " (" + d.genes.length + ")";

            return <TreeItem
                nodeId={counter.toString()}
                label= {exp_label}>
                {d.genes.map((gene, index) => {
                    counter += 1;
                    let label= gene.gene + " (" + gene.value + ")";
                    return  <TreeItem
                        nodeId={counter.toString()}
                        label = {label}/>
                })}
            </TreeItem>

        });


    };


    return (
        <Box p={1}>
            {(props.data.length < MAX_AGGREGATION_SIZE)
                ? <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >  {renderTree()}
                </TreeView>
                : <div style={{float: "left"}}>There are too many experiments to aggregate. Narrow down your query to inspect this feature (max 100 experiments)</div>
            }


        </Box>
    );
}
