import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../../../actions";
import * as GeneActions from "../filter-sections/actions";
import {connect} from "react-redux";
import {date_generator} from "../../../../../../../../func/helpers";
import {get_all_filter_sets, postFilterSet} from "../../../../../../../../../services/api/manage_study";
import {default_filters} from "../../../../../../../../../config-files/configFilters";
import SaveFilterSet from "./SaveFilterSet";



function FilterSet_SavePanel(props){


    const [listNames, setNames] = useState([]);
    const [message, setMessage] = useState(false);
    const [filter_set_name, setFilterSetName] = useState(""); // not used for now;
    const [initialized, setInitialized] = useState(false);

    useEffect(function(){

        if(!initialized){

            getAllFilterSetNames();
            setInitialized(true);

        }

    })


    const getAllFilterSetNames = () => {

        get_all_filter_sets(window.config.apis_configuration.api_nextgpap_server, props.token)
            .then(r => r.json())
            .then(function(json) {
                let list_names = json.data.filter(s => s.filter_set_name !== "").map(p => p.filter_set_name);

                let defaults = default_filters.map(s => s.filter_set_name);
                let tot = defaults.concat(list_names);
                setNames(tot);


            })


    }

    const saveFilterSet = (filterInfo) => {

        // save filter set;
        //
        let filters_to_save = JSON.parse(JSON.stringify(props.query));

        delete filters_to_save["samples_germline"];

        let filter_set_obj = {
            "filter_set_name": filterInfo["name"],
            "filters": filters_to_save,
            "creationDate": date_generator(),
            "description": filterInfo["description"]
        }

        // api call;

        postFilterSet(window.config.apis_configuration.api_nextgpap_server, props.token, filter_set_obj)
            .then(function(resp){
                if(resp.ok){
                    return resp.json();
                }
                else{
                    return {"error": resp.text()}
                }
            })
            .then(function(json){
                setMessage(true);
                setTimeout(() => { setMessage(false)}, 1000);

                // update list of filter sets;

                getAllFilterSetNames();

            })

    }


    return <SaveFilterSet
        message={message}
        saveFilterSet = {saveFilterSet}
        listNames = {listNames}/>



}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if( state.form !== undefined && Object.keys(state.form).length!== 0 && state.query!== undefined)
        {
            return {
                token: state.authorization.token,
                currentQuery: state.currentQuery,
                studySettings: state.studySettings,
                query: state.query,
            };
        }
        else{
            return {
                token: state.authorization.token,
                currentQuery: state.currentQuery,
                query: state.query,
                studies: state.studyBucket.studies,
                studySettings: state.studySettings,
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    gene_actions: bindActionCreators(GeneActions, dispatch),
});

export default connect(mapStateToProps,mapDispatchToProps)(FilterSet_SavePanel)