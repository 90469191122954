

const inheritances_keys= [
    {key: 0, label: "custom", "parent": "custom"},
    {key: 1, label: "Autosomal Dominant", "parent": "ad", "value": "AD default" },
    {key: 2, label: "Autosomal Recessive Homozygous", "parent": "ar", "value": "homozygosity"},
    {key: 3, label: "Autosomal Recessive Compound Heteroz.", "parent": "ar", "value": "compound heterozygosity"},
    {key: 4, label: "AR Consanguineous", "parent": "ar_consanguineous", "value": "homozygous"},
    {key: 5, label: "X-Linked Dominant - Index Case Male", "parent": "x-linked", "value": "index_case"},
    {key: 6, label: "X-Linked Dominant - Index Case Female", "parent": "x-linked","value": "index_case"},
    {key: 7, label: "X-Linked Recessive - Index Case Male", "parent": "x-linked", "value": "x-linked R index case male or unknown"},
    {key: 8, label: "De Novo", "parent": "de_novo", "value": "de novo variants - default trio analysis"},
    {key: 9, label: "Somatic", "parent": "somatic", "value": "somatic analysis" },

];



export default inheritances_keys;