import MappedText from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import React from "react";

const CNV_DATA_HEADER_FULL = [
    {
        header: <MappedText text={"Gene Info"} />,
        columns: [
            { nameTable: "Gene", dataSearch: "Gene_name" ,  filterType:  "none"},
        ],
    },
    {
        header: <MappedText text={"Variant Information"} />,
        columns: [
            { filterType: { type: "select", option: "default" }, nameTable: "Chrom", dataSearch: "SV_chrom", tooltip: "" },
            { nameTable: "Start", dataSearch: "SV_start", tooltip: "" },
            { nameTable: "End", dataSearch: "SV_end", tooltip: "" },
            { nameTable: "Length", dataSearch: "SV_length",  type: "number", tooltip: "Negative values displayed for the deletions" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "SV Type", dataSearch: "SV_type", tooltip: "" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Copy Number", dataSearch: "Copy_number", tooltip: "" },
            { filterType: { type: "select", option: "default" }, nameTable: "CytoBand", dataSearch: "CytoBand", tooltip: "" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Quality", dataSearch: "QC_tagg", tooltip: "" },
            { nameTable: "Translocation_ID", dataSearch: "Translocation_ID", tooltip: "" },

        ],
    },
    {
        header: <MappedText text={"Clinical Association"} />,
        columns: [
            { filterType: { type: "select", option: "multiple" },
                filter: "multiSelect",
                nameTable: "ACMG Classific.",
                dataSearch: "ACMG_class", tooltip: "According to https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7313390/" },
                {   nameTable: "ClinVar", dataSearch: "clinvar_clnsig", tooltip: "Minimum 70% overlap with ClinVar CNVs is considered (see guidelines)", filterType:  "none" },

      /*      {
                nameTable: "AnnotSV Reported pathogenic",
                dataSearch: "P_gain_phen",
                tooltip: "Phenotype of the pathogenic genomic regions completely overlapped with the SV. Source is displayed in the tooltip: dbVarNR (dbVar), ClinVar (CLN), ClinGen (TS3).",
                accessor: (row) => {
                    if (row.P_gain_phen) {
                        return row.P_gain_phen + ", source: " + row.P_gain_source;
                    } else if (row.P_loss_phen) {
                        return row.P_loss_phen + ", source: " + row.P_loss_source;
                    } else if (row.P_ins_phen) {
                        return row.P_ins_phen + ", source: " + row.P_ins_source;
                    }
                    return <div className={"score_NA"}>NA</div>; // Return an empty string if none of the fields exist
                }
            },*/

            /* { nameTable: "P_gain_phen", dataSearch: "P_gain_phen" },
           { nameTable: "P_loss_phen", dataSearch: "P_loss_phen" },
           { nameTable: "P_ins_phen", dataSearch: "P_ins_phen" },*/
        ],
    },
    {
        header: <MappedText text={"Population Frequency"} />,
        columns: [
            /* { nameTable: "B_gain_AFmax", dataSearch: "B_gain_AFmax" },
             { nameTable: "B_loss_AFmax", dataSearch: "B_loss_AFmax" },
             { nameTable: "B_ins_AFmax", dataSearch: "B_ins_AFmax" },*/
     /*       {
                nameTable: "Albert AnnotSV populaton AFMax ",
                dataSearch: "B_gain_AFmax",
                tooltip: "Maximum allele frequency of the reported benign genomic regions completely overlapped with the SV. Source is displayed in the tooltip: gnomAD, ClinVar (CLN), ClinGen (TS40), DGV (dgv, nsv or esv), DDD, 1000 genomes (1000g), Ira M. Hall’s lab (IMH), Children’s Mercy Research Institute (CMRI).",
            /!*    accessor: (row) => {
                    if (row.B_gain_AFmax) {
                        return row.B_gain_AFmax + ", (source: " + row.B_gain_source + ")";
                    } else if (row.B_loss_AFmax) {
                        return row.B_loss_AFmax + ", (source: " + row.B_loss_source +")";
                    } else if (row.B_ins_AFmax) {
                        return row.B_ins_AFmax + ", (source: " + row.B_ins_source + ")";
                    }
                    return <div className={"score_NA"}>NA</div>; // Return an empty string if none of the fields exist
                }*!/
            },*/
            { nameTable: "Internal Freq.", dataSearch: "Internal_Freq", filter: "numeric", filterType: { type: "numeric_population"}, tooltip:"Internal frequency of each event in our in-house database. Two events are considered the same if they are the same type and have at least 70% reciprocal overlap. This filter applies values equal to or less than the specified threshold." },
            { nameTable: "Internal Freq. Count", dataSearch: "Internal_Count", filter: "numeric", filterType: { type: "numeric_population"}, tooltip:"Number of times a CNV / SV has been detected in our in-house database. Two events are considered the same if they are the same type and have at least 70% reciprocal overlap. This filter applies values equal to or less than the specified threshold."},
            { nameTable: "Exact match", dataSearch: "Internal_Count_exact", filter: "numeric", filterType: { type: "numeric_population"}, tooltip: "Number of identical events. This filter applies values equal to or less than the specified threshold."}
        ],
    },
    {
        header: <MappedText text={"Additional Information"} />,
        columns: [
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Tool", dataSearch: "Tool" },
            { filterType: { type: "select", option: "multiple" }, filter: "multiSelect", nameTable: "Experiment ID", dataSearch: "Experiment_ID" },
        ],
    },
];


export default CNV_DATA_HEADER_FULL;
