import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DefaultSelect({ setFilter, options }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };

  React.useEffect(() => {
    setFilter(personName);
  }, [personName]);

  const sortedOptions = [...options].sort((a, b) => {
    const getLabelValue = (label) => {
      if (Array.isArray(label) && label.length > 0) {
        return label[0];
      } else if (typeof label === 'string') {
        return label;
      }
      return '';
    };
  
    const labelA = getLabelValue(a.label);
    const labelB = getLabelValue(b.label);
  
    const numA = Number(labelA);
    const numB = Number(labelB);
  
    if (isNaN(numA) && isNaN(numB)) {
      return labelA.localeCompare(labelB);
    } else if (isNaN(numA)) {
      return 1;
    } else if (isNaN(numB)) {
      return -1;
    } else {
      return numA - numB;
    }
  });
  

  return (
    <div>
     <FormControl sx={{ m: 1, width: "100%", marginTop: "-1px" }}>
      <InputLabel id="standard-label">
       
        </InputLabel>
        <Select
          size="small"
          multiple
          displayEmpty
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <>Select</>;
            }
            return selected.join(', ');
          }}
          MenuProps={MenuProps}
        >
        {sortedOptions.map((name) => (
          <MenuItem
            key={Array.isArray(name.label) ? name.label.join('-') : name.label}
            value={Array.isArray(name.label) ? name.label.join('-') : name.label}
            style={getStyles(Array.isArray(name.label) ? name.label.join('-') : name.label, personName, theme)}
          >
            {Array.isArray(name.label) ? name.label.join(', ') : name.label}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    </div>
  );
}
