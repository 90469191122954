

import {SET_SELECTED_TABLE_ROWS, RESET_SELECTED_TABLE_ROWS} from "./actions"



// TODO: review this with;
const selected_table_rowInit = [
    {data_type: "SNV_Germline", data_row: []},
    {data_type: "SNV_Somatic", data_row: []},
    {data_type: "CNV", data_row: []},
    {data_type: "Treatment_Somatic", data_row: []},
    {data_type: "Pharmacogenomics_Germline", data_row: []},
]

export const setSelectedTableRows = (state= selected_table_rowInit, action) => {

    switch (action.type) {
        case SET_SELECTED_TABLE_ROWS:

            let data = action.data;

            const objectIndex = state.findIndex(item => item.data_type === data.data_type);

            if(objectIndex!== -1){

                state.forEach(s => s.data_row = []);

                return [
                    ...state.slice(0, objectIndex), // Copy elements before the object
                    {
                        ...state[objectIndex], // Copy existing object index
                        data_type: data.data_type,
                        data_row: data.data_row, // Update data_row with new data
                    },
                    ...state.slice(objectIndex + 1) // Copy elements after the object
                ];
            }
            else{
                return state;
            }
        case RESET_SELECTED_TABLE_ROWS:
            return selected_table_rowInit;
        default:
            return state
    }
};
