import React, {useState} from "react";
import {getHPO_terms} from "../../../../../../../../../../../../services/api/get";
import {DataList_View} from "../DataList_View";
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";
import {
    GPAP_Button,
    GPAP_Button_light
} from "../../../../../../../../../../../../gpap-lib/components/Button_Collection";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GPAP_Tooltip from "../../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import IconButton from "@material-ui/core/IconButton";
import GeneralAutoComplete from "../GeneralAutoComplete";
import {handleResponse} from "../async-selection/handlers";
import {Alert} from "@material-ui/lab";
import {CircularProgress} from "@material-ui/core";
import {Disgenet_Tooltip, HPO_Tooltip, TextSection} from "./tooltips_conf";
import {AlertInfo} from "../../../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import {DeleteForeverOutlined} from "@material-ui/icons";
import {connect} from "react-redux";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

function formatHPOs (extracted_hpo, removeHPO) {

    return extracted_hpo.map(function(d, index){

        let background = ( index % 2 === 0 ) ? "#f0f0f0" : "white";

        return <div key={index} className={"fake_hpo_label"} style={{background: background}}>
            <div className={"hpo_text"}>{d.value}</div>
            <IconButton
                style={{float: "right", padding: "0px"}}
                onClick={()=> removeHPO(d.value)}
                tabIndex="-1" aria-label="Remove"><DeleteIcon/> </IconButton></div>
    });
}




function HPO_QueryBuilder(props){

    const [step, setStep] = useState("step1");
    const [value, setOperation] = useState("union");
    const [method, setMethod] = useState("");
    const [added_to_query, setAddToQuery] = useState(false);
    const [results, setResults] = useState([]);
    const [selected_values, setSelected] = useState([]);

    let {patientsBool, requestFailed, hpo_bool, extracted_hpo, geneList, searched_hpos} = props;

    const next_button = () => {

        let allow = (extracted_hpo.length!== 0 || searched_hpos.length!==0);

        return <Grid item>
            <Typography variant={"subtitle2"}><MappedText text={"In the next step, you can extract genes from HPOs and add them to your queries"}/> </Typography>
            <GPAP_Button disabled={!allow} onClick={()=> setStep("step2")} style={{float:"right"}} endIcon={<ArrowForwardIcon/>}><MappedText text={"Next"}/></GPAP_Button></Grid>

    };

    const back_button = () => {

        return <Grid item>
            <Box p={1}>
            <GPAP_Button_light onClick={()=> setStep("step1")} style={{float:"left"}} startIcon={<ArrowBackIcon/>}><MappedText text={"Back"}/></GPAP_Button_light>
            </Box>
        </Grid>


    };

    const getButtonOperation = () =>{


        if(value ==="union"){
           return  <Box p={0}>
               <GPAP_Tooltip title={<MappedText text={"Intersect the genes extracted from the HPOs "}/>}><GPAP_Button
                color="secondary" size="small"
                onClick={() => {
                    setOperation("intersection");
                    props.make_intersection("intersection")
                }}>
               <MappedText text={"Apply Intersection of Genes"}/>
            </GPAP_Button>
            </GPAP_Tooltip>
           </Box>
        }
        else{
            return  <Box p={0}>
                    <GPAP_Tooltip title={<MappedText text={"Make union of extracted genes (default)"}/>}><GPAP_Button
                    color="secondary" size="small"
                    onClick={() => {
                        setOperation("union");
                        props.make_intersection("union")
                    }}
                    >
                    <MappedText text={"Apply Union of Genes"}/>
                </GPAP_Button>
            </GPAP_Tooltip>
            </Box>
        }
    };


    const init = () => {
        // reinitialize values;
        setStep("step1")
        setAddToQuery(false);
        setMethod("")

    }

    // box of selected genes;
    const geneViewBox = () => {

        if(geneList.length!== 0  ){
            return <React.Fragment>
                {(!added_to_query)
                    ? <div><Alert severity={"warning"}><MappedText text="You didn't include the genes in your query yet." /> </Alert></div>
                    : <div><Alert severity={"success"}><MappedText text="You included the genes in your query. You made an " />{value} <MappedText text=" between the HPO genes" /></Alert></div>
                }
                <Grid item lg={12}>
                    <Box m={0}>
                        <GPAP_Button color="secondary" size="small" onClick={() => {
                            init();
                            props.reset()
                        }} startIcon={<CancelIcon/>}>
                            <MappedText text="Reset HPO gene list"/>
                        </GPAP_Button>{' '}
                        <GPAP_Button
                            size="small"
                            style={{marginRight: "1%"}}
                            onClick={() => {
                                setAddToQuery(true);
                                let uniques = [...new Set(geneList)];
                                props.add_genes_to_store(uniques);
                            }}
                            startIcon={<CheckCircleIcon/>}>
                            <MappedText text="Add Genes to Query"/>
                        </GPAP_Button>
                    </Box>
                </Grid>
                    <Grid item lg={12}>
                    <Paper elevation={0} style={{float: "left", width: "100%"}}>
                        <DataList_View
                            dataList={[...new Set(geneList)]}
                            color={"#f7fbff"}
                            title={"Genes"}
                            label={"Current Selected Genes "}/></Paper>
                </Grid>

            </React.Fragment>
        }
        else if (geneList.length === 0 && value === "intersection"){
            return <AlertInfo severity={"warning"}
                              message={<MappedText text={"No genes shared across all HPOs"}/> }
                              title={<MappedText text={"No Genes"}/>}/>
        }
        else {
            return null;
        }


    };


    // display if any the list of selected genes
    const showGeneList = () => {

        return  geneViewBox()

    };

    const getResults = (s) => {

        if(s!== ""){
            getHPO_terms(config.apis_configuration.api_phenostore, props.token, s)
                .then(r => r.json())
                .then(json => {
                    const resp = handleResponse("HPO", json);
                    setResults(resp);
                })
        }
    };


    const getInputValues = (obj) => {

        // STORE INPUT VALUES;
        setSelected(obj);
        // PASS DATA TO PARENT

        props.passData(obj);


    };

    const cleanHPOs = () => {
        props.resetHPOs();
    }


    const step1 = () => {

        let text = TextSection(props.patientBool);


        return <React.Fragment>
            {
                (extracted_hpo.length!==0)
                        ?  <Box>
                        <GPAP_Tooltip title={<MappedText text={"Clear current HPO selection"}/>}>
                            <IconButton onClick={()=> cleanHPOs()}><DeleteForeverOutlined/></IconButton>
                        </GPAP_Tooltip>
                        <Typography variant={"subtitle1"}>
                            <MappedText text="HPOs collected from analysed index case"/> ({extracted_hpo.length})
                        </Typography>
                    </Box>
                        :  <Typography variant={"subtitle1"}>
                        {text}
                        </Typography>
            }
            <div className={"field_container"}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <div className={'section'}>
                            {(extracted_hpo.length ===0 && !props.patientBool)
                                ? <GPAP_Button
                                    style={{margin: "0px"}}
                                    variant={"outlined"}
                                    size="small"
                                    onClick={props.extract_patients_HPOs}
                                    disabled={patientsBool}><MappedText text="Get HPOs from Participants"/></GPAP_Button>
                                :
                                <div>{formatHPOs(extracted_hpo, props.removeHPO)}</div>
                            }
                        </div>
                    </Grid>
                    <Grid item>
                        <Box m={0}>
                            <GeneralAutoComplete
                                results = {results}
                                onInputChange = {getResults}
                                title=<MappedText text={"search for HPO terms"}/>
                                passSelected = {getInputValues}
                                default_tags = {selected_values}
                            />
                            <div>{requestFailed}</div>
                        </Box>
                    </Grid>
                    {next_button()}
                </Grid>
            </div>
        </React.Fragment>



    };

   const getStyle = (term) => {
       return (method === term) ? {background: "orange"} : null;
    }


    const step2 = () => {


       let tot_genes = extracted_hpo.length + searched_hpos.length;

       return  (!hpo_bool)
            ? <React.Fragment>
               <Grid container
                     direction={"row"}>
                   <Grid item lg={12}> {back_button()}</Grid>
                <Grid item lg={12}>
                    <Box m={1}>
                        <Typography variant={"subtitle2"}><MappedText text="You have now"/> {tot_genes} <MappedText text="HPO terms selected"/></Typography>
                    </Box>
                        <Divider/>
                </Grid>
                   <Grid item lg={12}><Box p={1}>
                       <Typography variant={"h6"}><MappedText text="Select Resource:"/></Typography>
                   </Box>
                   </Grid>
                <Grid item lg={12}>
                        <div>
                            <GPAP_Tooltip
                                interactive
                                title={ HPO_Tooltip() }>
                                <GPAP_Button
                                    style={getStyle("HPO")}
                                    onClick={() => {
                                        setMethod("HPO")
                                        props.apply_HPO_Ontology()
                                    }}
                                    disabled={hpo_bool}>
                                    <MappedText text="HPO Ontology"/>
                                </GPAP_Button>
                            </GPAP_Tooltip>
                            <GPAP_Tooltip
                                interactive
                                title={  Disgenet_Tooltip() }>
                                <GPAP_Button
                                    style={getStyle("DISGENET")}
                                    size = {"medium"}
                                    disabled={props.umls_array.length === 0}

                                    onClick={() => {
                                        setMethod("DISGENET")
                                        props.applyDisGenet()
                                    }}>
                                    DisgeNET
                                </GPAP_Button></GPAP_Tooltip>{' '}
                        </div>
                    { (props.loading) ? <CircularProgress/> : null}
                </Grid>
                   <Grid item lg={12}>
                       <Typography variant={"h6"}><MappedText text="Select Method:"/></Typography>
                       <Typography variant={"subtitle2"}>{value} <MappedText text="of genes in this section is applied "/></Typography>
                       {getButtonOperation()}
                   </Grid>
                   {showGeneList()}
               </Grid>
            </React.Fragment>
            : null



    };


    const renderStep = () => {

        switch (step) {
            case "step1":
                return step1();
            case "step2":
                return step2();
            default:
                return step1();

        }

    };



    return <React.Fragment>
        {renderStep()}
    </React.Fragment>





}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            }
    }
}


export default connect(mapStateToProps)(HPO_QueryBuilder);