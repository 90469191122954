


export const tourSteps = [
    {
        selector: '#study_toolbar',
        content: 'In this toolbar, you can find the main tools to work on the study. ',
    },
    {
        selector: '#newAnalysis',
        content: 'You can create a new analysis in the study',
    },
    {
        selector: '#save_update',
        content: 'Save or update your study once you created new analyses and queries. After that, you can share the study with other users',
    },{
        selector: '#list_work',
        content: 'Review your work and your analyses till now',
    },
    {
        selector: "#see_participants",
        content: "See information on the participant currently analyzed"
    },
    {
        selector: '#share_btn',
        content: 'Share the study with other users. Be sure you saved that your study is not saved as "Private"',
    },
    {
        selector: '#save_status',
        content: 'Save the status of your study',
    },
    {
        selector: '.queryBox',
        content: 'Edit the name of a query',
    },
    {
        selector: ".leftSidePanel",
        content: 'All external resources go in this section (e.g. links to other databases)',
    },
    {
        selector: ".variant_table",
        content: 'Most of your work takes place in this area of the dashboard',
    },
    {
        selector: "#AnalysesTabs",
        content: 'You can have multiple analyses and jump from one to the other by clicking on the different tabs',
    },
    {
        selector: "#QueryTabs",
        content: 'Same for the queries',
    },
    {
        selector: ".toolbar_results",
        content: "The main functionalities to work on the results of your query can be found here"
    },
    {
        selector: "#new_query",
        content: "Create a new Query with variant filters and genes"
    },
    {
        selector: "#charts_variants",
        content: "See a visual summary of the current results"
    },
    {
        selector: "#plugins",
        content: "Use different plugins to prioritize variants"
    },
    {
        selector: "#exportVariants",
        content: "Export up to "+  (window.config.max_sortable_svn_rows) ? window.config.max_sortable_svn_rows : 1000 + "variants in CSV format"
    },
    {
        selector: "#review_filters",
        content: "This is easy ;). Click here to review the filters applied in your query"
    },
    {
        selector: "#gpap_table",
        content: "Query results are shown here. Click on a variant to access to label and tag functionalities and more information"
    },
    {
        selector: "#random",
        content: "If you have any question, please contact us at help@rd-connect.eu"
    }


    // ...
];