import React, {useState} from 'react';

import { Link } from 'react-router-dom';
import {grey} from "@material-ui/core/colors";
import {Menu, MenuItem} from "@material-ui/core";
import {OrangeTextButton} from "../../../gpap-lib/components/Button_Collection";
import Button from "@material-ui/core/Button";


const menuItemSx = {
    color: grey[900], 
    backgroundColor: grey[50],
    '&:hover': {
      color: 'primary.main',
      backgroundColor: grey[200],
    },
} 

export default function NavigationMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {

        setAnchorEl(null);

    };

    return (
        <React.Fragment>
            {
                (props.orange) ?
                    <OrangeTextButton //classes={{a: {color: "orange"}}}
                                      onClick={handleClick} >{props.title}</OrangeTextButton>
                :
                    <Button aria-controls="data_submission"  aria-haspopup="true" onClick={handleClick} sx={{ color: 'text.primary' }}>{props.title}</Button>
            }
            <Menu
                id="data_submission"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            { 
                props.items.map((item, index) => {
                    return <Link to={item.to} style={{ textDecoration: 'none' }} key={index}>
                               <MenuItem
                                   key={index}
                                   onClick={() => handleClose()} sx={menuItemSx}> {item.label} </MenuItem>
                           </Link>
                })  
            }
            </Menu>
        </React.Fragment>
    );
}