import React, {Component} from 'react'

import * as d3 from "d3";

export default class Bars extends Component {
    constructor(props) {
        super(props);

        this.colors={
            selected: "green",
            hovered: "yellow",
            default: "orange"
        };

        this.getWidth = this.getWidth.bind(this);
    }


    getFillColor(val){

        if(val === this.props.selectedData.d){return this.colors.selected}
        else{return this.colors.default}

    }

    getWidth(val){
        const { scales, margins } = this.props;
        const { xScale } = scales;

        let scaledVal = xScale(val);
        return scaledVal - margins.left;

    }

    render() {
        const { scales, margins, data, svgDimensions } = this.props;
        const { yScale } = scales;


        let self = this;
        let bar_h = d3.min([20,yScale.bandwidth()]);


        const bars = (
            data.map((datum, index) =>
                <rect
                    style={{cursor:"auto"}}
                    key={"bar_" + index}
                    x={margins.left}
                    y={yScale(datum.d) + (yScale.bandwidth() - bar_h)/2}
                    height={bar_h}
                    width={self.getWidth(datum.value)}
                    fill={this.getFillColor(datum.d)}
                    onMouseOver={() => this.props.onMouseOverCallback(datum)}
                    onMouseOut={() => this.props.onMouseOutCallback(null)}
                />
            )
        );


        return (
            <g>
                {bars}
            </g>
        )
    }
}