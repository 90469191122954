
import React from "react";
import { TextField } from "@mui/material";
import OptionSelect from "../selects/OptionSelect";

function DefaultColumnFilter({
    column: {
      filterValue,
      preFilteredRows,
      setFilter,
      width,
      id,
      filterType,
      data,
    },
  }) {
    const count = preFilteredRows.length;
    if (filterType && filterType.type === 'none') {
      return null;
    };
    if (filterType.type === "select") {
      const options = preFilteredRows.reduce((options, row) => {
        options.add(row.values[id]); 
        return options;
      }, new Set());
  
      const optionsForAutoComplete = Array.from(options).map((option) => ({
        label: option,
      }));
  
      return (
        <>
          <OptionSelect
            optionType={filterType.option}
            options={optionsForAutoComplete}
            setFilter={setFilter}
          />
        </>
      );
    }
  
    return (
      <TextField
        size="small"
        id="standard-basic"
        placeholder={(filterType.type == "numeric_population" || id == "AnnotSV population AFMax") ? `Equal or less` : `Search...`}
        variant="outlined"
        onChange={(e) => {
          setFilter(e.target.value || undefined); 
        }}
      />
    );
  }

export default DefaultColumnFilter;