
/* CONFIGURATION FILE DEFAULT FILTERS  */



const filter_set_low = {
    "filter_set_name": "Low Stringency",
    "filters": {
        "cadd_phred": null,
        "chrom": [],
        "clinvarclasses": {
            "A": false,
            "C": false,
            "D": false,
            "L": false,
            "P": false,
            "U": false
        },
        "cnv_germline": false,
        "cnv_somatic": false,
        "compound_in": false,
        "cosmic": false,
        "freqInt": null,
        "from": 0,
        "fromCNV": 0,
        "gene": [],
        "genotypefeatures": {
            "RNA": false,
            "coding": false,
            "other": false
        },
        "gnomad_filter": {
            "nonpass": false,
            "pass": false
        },
        "indel": true,
        "mutationtaster": {
            "A": false,
            "D": false,
            "P": false
        },
        "nprograms": 0,
        "onco_filter": {
            "K": false,
            "P1": false,
            "P2": false,
            "PP": false
        },
        "onco_classifier":{
            "O": false,
            "LO": false,
            "VUS": false,
            "B": false ,
            "LB":false  
    },
        "intervarclasses": {
            "B": false,
            "LB": false,
            "VUS": false,
            "P": false,
            "LP": false
        },
        "polyphen2hvarpred": {
            "B": false,
            "D": false,
            "P": false
        },
        "population": {
            "exac": null,
            "gnomad_af" : 0.05,
            "gp1_af" :  null
        },
        "programs_filter": {
            "caveman": false,
            "lancet": false,
            "muse": false,
            "mutect": false,
            "strelka": false
        },
        "qc_filter": {
            "dp_alt_tumor": 3,
            "dp_control": 10,
            "dp_ref_tumor": 10,
            "dp_tumor": 10,
            "vaf_tumor_high": 0.8,
            "vaf_tumor_low": 0.05
        },
        "samples_germline": [],
        "samples_somatic": [],
        "siftpred": {
            "D": false,
            "T": false
        },
        "size": 0,
        "svn": true,
        "variantclasses": {high:true,moderate:true,low:true,modifier:true}
    },
    "creationDate": "May 18, 2019, 2:39 PM GMT+1",
    "description": "Low Stringency Filter",
    "id": -1
};

const filter_set_medium = {
    "filter_set_name": "Medium Stringency",
    "filters": {
        "cadd_phred": 10,
        "chrom": [],
        "clinvarclasses": {
            "A": false,
            "C": false,
            "D": false,
            "L": false,
            "P": false,
            "U": false
        },
        "cnv_germline": false,
        "cnv_somatic": false,
        "compound_in": false,
        "cosmic": false,
        "freqInt": null,
        "from": 0,
        "fromCNV": 0,
        "gene": [],
        "genotypefeatures": {
            "RNA": false,
            "coding": false,
            "other": false
        },
        "gnomad_filter": {
            "nonpass": false,
            "pass": false
        },
        "indel": true,
        "mutationtaster": { A : true, D : true, P : false },
        "intervarclasses": {
            "B": false,
            "LB": false,
            "VUS": false,
            "P": false,
            "LP": false
        },
        "nprograms": 0,
        "onco_filter": {
            "K": false,
            "P1": false,
            "P2": false,
            "PP": false
        },
        "onco_classifier":{
            "O": false,
            "LO": false,
            "VUS": false,
            "B": false ,
            "LB":false  
        },
        "polyphen2hvarpred": { D : true, P : true, B : false },
        "population": {
            "exac": null,
            "gnomad_af" : 0.05,
            "gp1_af" :  null
        },
        "programs_filter": {
            "caveman": false,
            "lancet": false,
            "muse": false,
            "mutect": false,
            "strelka": false
        },
        "qc_filter": {
            "dp_alt_tumor": 3,
            "dp_control": 10,
            "dp_ref_tumor": 10,
            "dp_tumor": 10,
            "vaf_tumor_high": 0.8,
            "vaf_tumor_low": 0.05
        },
        "samples_germline": [],
        "samples_somatic": [],
        "siftpred": { D : true, T : false },
        "size": 0,
        "svn": true,
        "variantclasses": {high:true, moderate:true, low:true, modifier:false}
    },
    "creationDate": "May 18, 2019, 2:39 PM GMT+1",
    "description": "Medium Stringency Filter",
    "id": -1
};

const filter_set_high = {
    "filter_set_name": "High Stringency",
    "filters":{
        "cadd_phred": 20,
        "chrom": [],
        "clinvarclasses": {
            "A": false,
            "C": false,
            "D": false,
            "L": false,
            "P": false,
            "U": false
        },
        "cnv_germline": false,
        "cnv_somatic": false,
        "compound_in": false,
        "cosmic": false,
        "freqInt": null,
        "from": 0,
        "fromCNV": 0,
        "gene": [],
        "genotypefeatures": {
            "RNA": false,
            "coding": false,
            "other": false
        },
        "gnomad_filter": {
            "nonpass": false,
            "pass": false
        },
        "indel": true,
        "mutationtaster": { A : true, D : true, P : false },
        "nprograms": 0,
        "onco_filter": {
            "K": false,
            "P1": false,
            "P2": false,
            "PP": false
        },
        "onco_classifier":{
            "O": false,
            "LO": false,
            "VUS": false,
            "B": false ,
            "LB":false  },
        "intervarclasses": {
            "B": false,
            "LB": false,
            "VUS": false,
            "P": false,
            "LP": false
        },
        "polyphen2hvarpred": { D : true, P : true, B : false },
        "population": {
            "exac": null,
            "gnomad_af" : 0.01,
            "gp1_af" :  null
        },
        "programs_filter": {
            "caveman": false,
            "lancet": false,
            "muse": false,
            "mutect": false,
            "strelka": false
        },
        "qc_filter": {
            "dp_alt_tumor": 3,
            "dp_control": 10,
            "dp_ref_tumor": 10,
            "dp_tumor": 10,
            "vaf_tumor_high": 0.8,
            "vaf_tumor_low": 0.05
        },
        "samples_germline": [],
        "samples_somatic": [],
        "siftpred": { D : true, T : false },
        "size": 0,
        "svn": true,
        "variantclasses": {high:true, moderate:true, low:false, modifier:false}
    },
    "creationDate": "May 18, 2019, 2:39 PM GMT+1",
    "description": "High Stringency Filter",
    "id": -1
};

const CNAG_diagnotics = {
    "filter_set_name": "CNAG Diagnostics",
    "filters":{
        "cadd_phred": null,
        "chrom": [],
        "clinvarclasses": {
            "A": false,
            "C": false,
            "D": false,
            "L": false,
            "P": false,
            "U": false
        },
        "cnv_germline": false,
        "cnv_somatic": false,
        "compound_in": false,
        "cosmic": false,
        "freqInt": 0.02,
        "from": 0,
        "fromCNV": 0,
        "gene": [],
        "genotypefeatures": {
            "RNA": false,
            "coding": false,
            "other": false
        },
        "gnomad_filter": {
            "nonpass": false,
            "pass": false
        },
        "indel": false,
        "mutationtaster": { A : false, D : false, P : false },
        "intervarclasses": {
            "B": false,
            "LB": false,
            "VUS": false,
            "P": false,
            "LP": false
        },
        "nprograms": 0,
        "onco_filter": {
            "K": false,
            "P1": false,
            "P2": false,
            "PP": false
        },
        "onco_classifier":{
            "O": false,
            "LO": false,
            "VUS": false,
            "B": false ,
            "LB":false  
        },
        "polyphen2hvarpred": { D : false, P : false, B : false },
        "population": {
            "exac": null,
            "gnomad_af" : 0.01,
            "gp1_af" :null
        },
        "programs_filter": {
            "caveman": false,
            "lancet": false,
            "muse": false,
            "mutect": false,
            "strelka": false
        },
        "qc_filter": {
            "dp_alt_tumor": 3,
            "dp_control": 10,
            "dp_ref_tumor": 10,
            "dp_tumor": 10,
            "vaf_tumor_high": 0.8,
            "vaf_tumor_low": 0.05
        },
        "samples_germline": [],
        "samples_somatic": [],
        "siftpred": { D : false, T : false },
        "size": 0,
        "svn": true,
        "variantclasses": {high:true, moderate:true, low:true, modifier:false}
    },
    "creationDate": "November 23, 2022, 10:30 AM GMT+1",
    "description": "CNAG Diagnostics Filters",
    "id": -1
};

const default_filters = [CNAG_diagnotics, filter_set_high, filter_set_medium, filter_set_low];



export {default_filters};
