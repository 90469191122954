

import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogAnalysisContent from "./DialogAnalysisContent";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions";
import * as SidebarActions from "../../../../../../../../../components/SideNavigation/sidebar_actions";
import {connect} from "react-redux";
import {DialogTitle} from "../../dialog_components";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';



const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function AddAnalysisDialog(props) {


    const [open, setOpen] = React.useState(props.open);

    useEffect(function(){

        if(props.open!== open){
            setOpen(props.open)
        }


    }, [props.open])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (type) => {
        setOpen(false);
        props.setClose(false);
    };


    return (
        <React.Fragment>
            <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <MappedText text="Create New Analysis"/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogAnalysisContent
                        handleClose = {handleClose} queryRunner = {props.queryRunner} instandGeneticFindingsTab={props.instandGeneticFindingsTab}
                    />
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});



export default connect(null, mapDispatchToProps)(AddAnalysisDialog);