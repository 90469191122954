export const getValueByPath = (obj, path) => {
  let current = obj;
  let properties = path.split(".");

  for (let i = 0; i < properties.length; i++) {
    if (!current[properties[i]]) {
      return undefined;
    } else {
      current = current[properties[i]];
    }
  }
  return current;
};

export const setValueByPath = (obj, path, value) => {
  const keys = path.split(".");
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
  lastObj[lastKey] = value;
};
