import { createSlice } from "@reduxjs/toolkit";

export const tableSlice = createSlice({
  name: "table",
  initialState: {
    allState: {},
    isLabel: false,
    rowIcons: [],
  },
  reducers: {
    setTable: (state, action) => {
      const { option, value } = action.payload;
      state[option] = value;
      return state;
    },
  },
});

export const { setTable } = tableSlice.actions;

export default tableSlice.reducer;