
import React from "react";


export const app_config = [
    {
        title: "Phenotypic Module",
        description: "PhenoStore",
        description2: "",
        link: "/phenostore",
        playground: "phenostore",
        visibility: true,
        body: "Module to submit and manage phenotypic data",
        category: "submission",
        doc_link: "phenotypic_data.html",
        image: "phenostore",
        home_link: false
    },
    {
        title: "Data Submission",
        description: "Experiment & Files",
        description2: "",
        link: "/datamanagement",
        image: "datamanagement",
        playground: "datamanagement",
        visibility: true,
        body: "Module to submit and manage experiments",
        category: "submission",
        doc_link: "experiment_data.html",
        home_link: false
    },
    {
        title: "Genomic Analysis",
        description: "Analyse genome-phenome data",
        description2: "",
        link: "#/genomics_home",
        image: "genomics",
        playground: "nextgpap",
        visibility: true,
        body: "Carry out genomics analysis for singletons, families, and cohorts",
        category: "analysis",
        doc_link: "",
        home_link: true
    },
    {
        title: "Cohort Creation",
        description: <div>Create <i>in-silico cohorts</i></div>,
        description2: "",
        link: "/cohortapp",
        image: "cohortapp",
        playground: "cohortapp",
        visibility: true,
        body: "Interactive Construction of Cohorts and Exploration of Phenotypic Data",
        category: "analysis",
        doc_link: "cohortapp.html",
        home_link: false
    },
   /* {
        title: "IGV GPAP Browser",
        description: "IGV GPAP Browser",
        description2: "",
        link: "/igvgpap",
        playground: "/igv",
        image: "igv",
        visibility: true,
        body: "Module to visualize and explore read alignements from EGA",
        doc_link: "igvgpap/igv.html",
        category: "analysis",
        home_link: false
    },*/
];




export const app_genomics_config = [
    {
        title: "Previous Genomic Analysis Interface",
        description: "The application used in several EU projects to solve unsolved cases. Allows quickly analysis of cases or family trios",
        description2: "",
        link: "/genomics/",
        image: "genomics_old",
        playground: "#genomics_playground",
        visibility: true,
        body: "Perform quick analysis of singletons, families, and cohorts",
        category: "analysis",
        doc_link: "https://www.youtube.com/@rd-connect4603",
        home_link: false
    },
    /*{
        title: "Genomic Analysis - Beta",
        description: "Beta version of the Genomics Analysis Module. It comes with a new look and user experience, with a more in-depth integration with the other GPAP Modules.",
        description2: "",
        link: "nextgpap",
        image: "genomics",
        playground: "#genomics_playground",
        visibility: true,
        body: "Beta version of the Genomics Analysis Module. It comes with a new look and user experience, with a more in-depth integration with the other GPAP Modules.",
        category: "analysis",
        doc_link: "genomics/genomics.html",
        home_link: false
    },*/
   /* {
        title: "IGV GPAP Browser",
        description: "Visualize and inspect genomic alignments of bam and cram files of RD-Connect GPAP experiments",
        description2: "",
        link: "/igvgpap",
        playground: "/igv",
        image: "igv",
        visibility: window.config.extra.igv,
        body: "Module to visualize and explore read alignements from EGA",
        doc_link: "igvgpap/igv.html",
        category: "analysis",
        home_link: false
    }*/
]