


import React, {useRef, useState} from "react";
import Box from "@material-ui/core/Box";
import {GPAP_Button} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {Input, InputLabel} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


export default function CopyUrl(props) {

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);


    function copyToClipboard(e) {


        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('URL copied to your clipboard!');
    }

    return (
        <Box m={1}>
            {
                /* Logical shortcut for only displaying the
                   button if the copy command exists */
                document.queryCommandSupported('copy') &&
                <div>
                    <Grid container direction={"column"} spacing={1}>
                        <Grid item lg={12}>
                            {  <form>
                        <textarea
                            readOnly
                            ref={textAreaRef}
                            value={props.url}
                            style={{
                                width: "100%",
                                resize: "none",
                                marginTop: "20px",
                                border: "none",
                                fontSize: "16px",
                            }}
                        />
                            </form>}
                        </Grid>
                       <Grid item>
                            <GPAP_Button startIcon={<FileCopyIcon/>}
                                         onClick={copyToClipboard}>Copy Study URL</GPAP_Button>
                        </Grid>
                        <Grid item>
                            <div style={{
                                float: "left",
                                padding: "5px"
                            }}>{copySuccess}</div>
                        </Grid>
                    </Grid>
                </div>
            }

        </Box>
    );
}