

/* SEARCH ACROSS ALL: STEP BY STEP STUDY CREATION - CONFIGURATION  */


// Step titles
const sectionsForm = ["Select genotype", "Select genes", "Apply variant filters"];

const sectionsCohortForm = ["Select cohort", "Select genotype", "Select genes", "Apply variant filters"];


export {sectionsCohortForm, sectionsForm};