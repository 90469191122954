export const linkJoin = (data, config) => {
    if (data.length === 0) {
        let defaultValue = {};
        if (config.length > 0) {
            const firstConfig = config[0];
            defaultValue[firstConfig.dataSearch] = "https://www.google.com";
            defaultValue.links = [{
                link: "https://www.google.com",
                icon: firstConfig.icon,
            }];
        }
        return [defaultValue];
    }
    
    if (config.length === 0) return data;

    return data.map((item) => {
        let links = [];
        config.forEach((configItem) => {
            const dataSearch = configItem.dataSearch;
            const icon = configItem.icon;
            let linkValue = Array.isArray(item[dataSearch]) ? item[dataSearch][0] : item[dataSearch];

            if (linkValue != null) {
                links.push({
                    link: linkValue,
                    icon: icon,
                });
            }
        });
        return {
            ...item,
            links: links.length > 0 ? links : [{ link: "No Data", icon: undefined }] 
        };
        });
        };
  
