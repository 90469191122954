
import  React from "react";
import { Grid, Box, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { VARIANT_METRICS } from "../config/variant_metrics.js";


export default function VariantMetrics({data, report_id}) {
        //const token = useSelector(state => state.authorization.token);
    //     const [sectionData, setData] = useState(data)
    
    // useEffect(() => {
    //     if(report_id!== undefined && sectionData.length === data.length){
    //         setData(data)}
    // }, [report_id]);

    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <GeneralTable data={ data } columns={ VARIANT_METRICS} />
                </Box>
            </Box>
        </Grid>
    );
}
