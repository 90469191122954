

import React from "react";
import {Chip} from "@mui/material";

export const customOptionLabel = ({ value, label, canonical }) => (
    <div>
        {/* You can customize the label here */}
        <span>{label}</span>
        <span style={{ color: 'red', fontSize: "15px", marginLeft: '5px' }}>{(canonical) ? <Chip size="small" color="primary" label="Canonical" /> : ""}</span>
    </div>
);

