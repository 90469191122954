

export const ADD_BED_RANGES = 'ADD_BED_RANGES';
export const ADD_COORDINATES_RANGES = 'ADD_COORDINATES_RANGES';
export const RESET = "RESET";


export function add_bed_ranges(ranges, key) {
    return {
        type: ADD_BED_RANGES,
        ranges: ranges,
        key: key
    }
}

export function add_coordinate_ranges(ranges, key) {
    return {
        type: ADD_COORDINATES_RANGES,
        ranges: ranges,
        key: key
    }
}


export function reset_ranges(key){
    return {
        type: RESET,
        key: key
    }
}

