import React from "react";
import MappedText from "../../../shared-components/mapText";

export const GENETIC_FINDINGS_SNV = [
        {
            Header: <MappedText text='Gene'/>,
            accessor: "geneName",
            filter: 'fuzzyText',
        },
        {
            Header: <MappedText text='Transcript'/>,
            accessor: "transcript",
            filter: 'fuzzyText',
        },
        {
            Header: 'cDNA',
            accessor: "cDNA",
            filter: 'fuzzyText',
        },
        {
            Header: <MappedText text='Protein'/>,
            accessor: "protein",
            filter: 'fuzzyText',
        },
        {
            Header: <MappedText text='Clinical significance**'/>,
            accessor: "significance",
            filter: 'fuzzyText',
        },
        {
            Header: <MappedText text='Zygosity'/>,
            accessor: "zygosity",
            filter: 'fuzzyText',
        },
        {
            Header: <MappedText text='Origin'/>,
            accessor: "origin",
            filter: 'fuzzyText',
        },
];
