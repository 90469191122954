




export function calculateSettings(sample_new, genotype) {
    // sample settings;

    let ad_low, ad_high;


    if (genotype.length > 1) {
        let bool =  genotype.every(elem => ["0/1","1/1"].indexOf(elem) > -1);
        //search for homozygous
        if(bool){
            ad_low = 0.2;
            ad_high = 1;
        }
        else{
            ad_low = 0.2;
            ad_high = 0.8
        }}
    else if (genotype.length === 0) {ad_low = 0.2; ad_high = 0.8}
    else {
        let gt = genotype[0];
        if (gt === "0/0") { ad_low = 0; ad_high = 0}
        else if (gt === "0/1") {ad_low = 0.2;ad_high = 0.8}
        else if (gt === "1/1") {ad_low = 0.8;ad_high = 1}
    }
    return [ad_low, ad_high];
}