
import React, {useEffect, useState} from "react";

import Grid from "@material-ui/core/Grid";
import QueryGenerator from "../../../../../query-generator/QueryGenerator";
import StartFiltersAccordion from "./StartFiltersAccordion";
import FilterSelector from "../../filter-selector/FilterSelector";
import {default_filters} from "../../../../../../../../../../config-files/configFilters";

import {connect} from "react-redux";
import {get_all_variants} from "../../../../../../../../../../services/api/variants_api";
import GeneFilters from "./GeneFilters";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from '@material-ui/core/Collapse';

import Divider from "@material-ui/core/Divider";
import {GPAP_Button_light} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import DeleteIcon from "@material-ui/icons/Delete";
import {CircularProgress} from "@material-ui/core";
import {bindActionCreators} from "redux";
import * as GeneActions from "../actions";
import ViewAppliedFilters
 from "../../../../../results/components/view-applied-filters/ViewAppliedFilters";


function StartFilters(props){

    const [openVariant, setOpenVariant] = useState(true);
    const [openGenes, setOpenGenes] = useState(true);


    const [taggedVariants, setTaggedVariants] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [reset, resetForm] = useState(false);
    const [state, setState] = useState(    {
        filters: [],
        filter_set_name: -1,
        loaded: false
    });
    const [openDialogFilters, setOpenDialogFilters] = useState(false);
    const [filter_sets, setFilterSets] = useState([]);
    const [selectedFilterSet, setSelectedFilterSet]= useState(null);
    const [title, setTitle]= useState(null);


    useEffect(function(){

        // collect tagged variants only once.
        // TODO: in this case I should probably use - useMemo - check it.
        if(!initialized){
            get_all_variants(config.apis_configuration.api_phenostore, props.token)
                .then(r => r.json())
                .then(json => setTaggedVariants(json.data));
            setInitialized(true);
        }

    })


    const resetAllFilters = () => {

        setState({
            filters: [],
            filter_set_name: -1,
            loaded: false
        })

        resetForm(true);
        props.geneListActions.resetGenes();
        setTimeout(() => { resetForm(false)}, 1000);


    }

    const resetFilterChoice = () => {


        setState({
            filters: false,
            filter_set_name: "",
            loaded: false
        })

    }


    const setNewFilterSet = (filter_set) => {


        setState({
            filters: filter_set.filters,
            filter_set_name: filter_set.filter_set_name,
            loaded: true
        })

        // now update the queryView // that should also update the query

    };

    const renderFilterSelector = () => {

        return <React.Fragment>
            <FilterSelector
                filters = {default_filters}
                setFilterSet = {setNewFilterSet}
                reset = {resetFilterChoice}
            />
            <Divider/>
        </React.Fragment>


    }


    const renderVariantFilters = () => {

        return <React.Fragment>
            <div style={{ float: "left", width: "100%", margin: "2%" }}>
                <Box p={2}>
                    <div className={"title_section"} onClick={() => setOpenVariant(!openVariant) }>
                        <div style={{float: "left"}}>
                            <IconButton
                                style={{padding: "0px"}}
                                onClick={() => setOpenVariant(!openVariant)  } >
                                {openVariant
                                    ? <ExpandLess />
                                    : <ExpandMore style={{order: -1,
                                        transform: "rotate(-90deg)"}}/>}
                            </IconButton> </div>
                        <span > Filter by variants</span>
                    </div>

                </Box >
                <Collapse in={openVariant}>
                    <StartFiltersAccordion
                        default_filters = {state.filters}
                        gene_filters={props.gene_filters}
                    />
                </Collapse>
            </div>
        </React.Fragment>

    }



    const renderGeneFilters = () => {

        return ((props.analysisType === "family_trio") || (props.analysisType === "instand"))
            ? <div style={{ float: "left", width: "100%", margin: "2%" }}>
                <Box p={2}>
                    <div className={"title_section"} onClick={() => setOpenGenes(!openGenes) }>
                        <div style={{float: "left"}}>
                            <IconButton
                                style={{padding: "0px"}}
                                onClick={() => setOpenGenes(!openGenes)  } >
                                {openGenes ? <ExpandLess /> : <ExpandMore style={{order: -1,
                                    transform: "rotate(-90deg)"}}/>}
                            </IconButton> </div>
                        <span >    Filter by genes </span>
                    </div>
                </Box >
                    <Collapse in={openGenes}>
                        <GeneFilters/>
                    </Collapse>
                </div>
            : null

    }

    const renderForm = () => {

        if(reset){
            return <div >
                <Grid container
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}>
                    <Grid item lg={"12"}>
                        <CircularProgress/>
                    </Grid>
                </Grid>

            </div>
        }
        else{
            return <React.Fragment>
                <Grid container
                      direction={"row"}
                      spacing={2}>
                    <Grid item lg={12}>
                        {renderFilterSelector()}
                    </Grid>
                    <Grid item lg={6}>
                        {renderVariantFilters()}
                    </Grid>
                    <Grid item lg={6}>
                        {renderGeneFilters()}
                    </Grid>

                </Grid>
            </React.Fragment>
        }
    }


    return  <div className={"sample_section"}>
            <QueryGenerator
                all_tagged_variants = {taggedVariants}
            />
            <Grid container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}>
                <div>
                    <GPAP_Button_light
                    onClick={resetAllFilters}
                    startIcon={<DeleteIcon/>}>Reset Query</GPAP_Button_light>
                    <ViewAppliedFilters whichQuery="new"/>
                </div>
                {renderForm()}
            </Grid>
    </div>

}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
      return {
          token:state.authorization.token,
          analysisType : state.studySettings.analysisType,
          gene_tot: (state.geneList.geneList) ? state.geneList.geneList.length : 0
      }
    }
        else{
            return {token: ""}
        }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    geneListActions: bindActionCreators(GeneActions, dispatch),
});



export default connect(mapStateToProps,mapDispatchToProps)(StartFilters);

