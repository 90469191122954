


import {ADD_BED_RANGES, ADD_COORDINATES_RANGES, RESET} from "./position_actions";



const ranges_init = {
    bed_ranges: [],
    coordinate_ranges: []
};


export const positionReducer = (state= ranges_init, action) => {


    switch (action.type) {
        // intersect or join
        case ADD_BED_RANGES:
            let bed_ranges = [...state.bed_ranges];
            bed_ranges = bed_ranges.concat(action.ranges);
            let update_state = Object.assign({}, state);
            update_state.bed_ranges = bed_ranges;
            return update_state;

        case ADD_COORDINATES_RANGES:
            let coordinate_ranges = [...state.coordinate_ranges];
            coordinate_ranges = coordinate_ranges.concat(action.ranges);
            let state_copy = Object.assign({}, state);
            state_copy.coordinate_ranges = coordinate_ranges;
            return state_copy;
        case RESET:

            let myKey = "bed_ranges";
            let options = ["bed_ranges", "coordinate_ranges"];
            if(action.key === undefined){
                // delete all
                let options = ["bed_ranges", "coordinate_ranges"];
                let state_copy_2 = {...state};
                options.forEach(s => state_copy_2[s] = []);
                return state_copy_2;
            }
            else{
                myKey = (action.key === "BED") ? options[0] : options[1];
            }

            let state_copy_2 = {...state};
            state_copy_2[myKey] = [];
            return state_copy_2;

        default:
            return state;

    }
};




