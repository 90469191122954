



import React, {Component} from 'react';

import Genomics  from "./components/genomics-dashboard/genomics/Genomics"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import "./view_manager.css";
import * as SidebarActions from "../../components/SideNavigation/sidebar_actions";


function ErrorFallback({ error }) {
    return (
        <div role="alert">
            <p>Oops, there was an error:</p>
            <p style={{ color: "red" }}>{error.message}</p>
        </div>
    );
}


class Dashboard extends Component {


    constructor(props) {
        super(props);
        this.state = {
            dimensions: null,
            sidebarSelection: 'home'
        };

        this.removeSidebar = this.removeSidebar.bind(this);
    }

    componentDidMount() {


        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
                height: this.container.offsetHeight,
            },
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let sidebarSettings = this.props.sidebarSettings;

        if(prevState.sidebarSelection!== sidebarSettings.page)
        {
            this.setState({sidebarSelection: sidebarSettings.page})
        }

    }



    removeSidebar(){

        let { sidebarActions } = this.props;
        sidebarActions.setView({page: "home"});
    }


    renderContent() {

        let self = this;

        return <Genomics
            loadStudy={(this.props.loadStudy!== undefined) ? this.props.loadStudy : false }
            sidebarSelection={self.state.sidebarSelection}/>

    }


    render() {
        const { dimensions } = this.state;

        return (
            <div className="managerContainer" ref={el => (this.container = el)}>
                {dimensions && this.renderContent()}
            </div>

        )

    }
}




function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token, sidebarSettings: state.sidebarSettings}
    }
}
const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

