import {ADD_TO_QUERY_LIST } from "../../../actions";
import {UPDATE_QUERY_NAME} from "../components/genomics-dashboard/components/variant-tabs/actions";


const initialState = {
    variants:[]
};

export const variants = (state =initialState, action) => {

    if (action.type === 'variants') {
        return action.variants;
    } else {
        return state
    }
};


const queryList_initialState = {
    queryList:[]
};


export const queryListReducer = (state= queryList_initialState, action) => {

    switch (action.type) {
        case ADD_TO_QUERY_LIST:
            return Object.assign({}, state, {
                queryList: [...state.queryList, action.new_query]
            });
        case UPDATE_QUERY_NAME:
            return Object.assign({}, state, {
                queryList: state.queryList.map((query, index) => {
                    if (index === action.index) {
                        return Object.assign({}, query, {
                            queryName: action.queryName
                        })
                    }
                    return query
                })
            });
        default:
            return state
    }
};


