


import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import StudyCreator from "./create-study/StudyCreator";
import {useTranslation} from "react-i18next";



export function StudyType(){

    const { t, i18n } = useTranslation();

    return  <Grid
        container
        direction="row"
        spacing={2}
        style={{
            background: "white",
            padding: "1%",
            borderRadius: "5px"
        }} >
        <Grid item lg={12}>
            <Grid
                container
                direction="column"
                spacing={2}>
                <Grid item lg={10}>
                    <div style={{width: '100%'}}>
                        <Typography variant={"h4"}> {t("Select type of analysis")} </Typography>
                    </div>
                </Grid>
                <Grid item lg={10}>
                    <Grid container direction={"row"} spacing={1}>
                        <StudyCreator
                            default={true}
                            disableStudyCreation={false}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </Grid>



}