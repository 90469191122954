import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordionBasic({ groups }) {
  return (
    <>
      {groups.map((group, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontSize: '24px', fontWeight: '700'}}>{group.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {group.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
