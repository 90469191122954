export const deutsch = {
    "Contact": "Kontakt",
    "Dashboard": "Dashboard",
    "Recent Work": "Jüngste Arbeit",
    "Select type of analysis": "Wählen Sie den Typ der Analyse",
    "Cohort Analysis": "Kohortenanalyse",
    "Case Analysis": "Fallanalyse",
    "Analyse a singleton or a family": "Analysieren Sie einen Einzelnen oder eine Familie",
    "Clinical Report": "Klinischer Bericht",
    "Search Across All Experiments": "Suche über alle Experimente",
    "Patient Matchmaking": "Patientenvermittlung",
    "Sample site of origin": "Herkunftsort der Probe",
    "General Info": "Allgemeine Informationen",
    "Medium": "Medium",
    "Request ID": "Anforderungs-ID",
    "Comments": "Kommentare",
    "Priority": "Priorität",
    "Deadline": "Frist",
    "Analysis Type": "Analysetyp",
    "Assignee(s)": "Beauftragte(r)",
    "Creator": "Ersteller",
    "Creation date": "Erstellungsdatum",
    "Hospital name": "Name des Krankenhauses",
    "Clinical referrer": "Klinischer Überweiser",
    "Clinical referrer contact info": "Kontaktinformationen des klinischen Überweisers",
    "Patient ID": "Patienten-ID",
    "Sex": "Geschlecht",
    "Clinical referral Investigated": "Klinische Überweisung untersucht",
    "Observed Symptoms": "Beobachtete Symptome",
    "Diagnostic date": "Diagnosedatum",
    "Clinical diagnosis": "Klinische Diagnose",
    "Case number pathology": "Fallnummer Pathologie",
    "Treatment history and response": "Behandlungsgeschichte und -antwort",
    "Cancer type": "Krebsart",
    "Sample ID": "Proben-ID",
    "Sample site of origin status": "Status des Herkunftsortes der Probe",
    "Sample fixation": "Probenfixierung",
    "Purity": "Reinheit",
    "Experiment ID": "Experiment-ID",
    "Library strategy": "Bibliotheksstrategie",
    "Kit": "Kit",
    "Administrative Info": "Verwaltungsinformationen",
    "Patient Info": "Patienteninformationen",
    "Clinical Info": "Klinische Informationen",
    "Tumor Sample & Experiment Info": "Tumorprobe & Experimentinformationen",
    "Sample Info": "Probeninformationen",
    "Experiment Info": "Experimentinformationen",
    "Control Sample & Experiment Info": "Kontrollprobe & Experimentinformationen",
    "Germline Sample & Experiment Info": "Keimbahnprobe & Experimentinformationen",
    "Relevant Observations": "Relevante Beobachtungen",
    "to do": "zu tun",
    "in progress": "in Bearbeitung",
    "Cancer with Metastasis": "Krebs mit Metastasen",
    "Low Amount of DNA": "Geringe Menge DNA",
    "High number of duplicates": "Hohe Anzahl von Duplikaten",
    "Request Overview": "Anforderungsübersicht",
    "Germline Genetic Findings": "Keimbahn-Genetische Befunde",
    "Reports": "Berichte",
    "Tumor Genetic Overview": "Genetische Übersicht des Tumors",
    "Somatic Genetic Findings & Treatment Options": "Somatische Genetische Befunde & Behandlungsoptionen",
    "Interpretations Requests": "Interpretationsanfragen",
    "Request": "Anfrage",
    "Interpretation Requests": "Interpretationsanfragen",
    "Contact": "Kontakt",
    "Docs": "Dokumente",

    //tumor genetic overview
    "Tumor characteristics": "Tumoreigenschaften",
    "Tumor purity": "Tumorreinheit",
    "Average ploidy":"Durchschnittliche Ploidie",
    "Microsatellite status":"Mikrosatellitenstatus",
    "Tumor mutational load":"Tumor-Mutationslast",
    "Tumor mutational burden":"Tumor-Mutationslast",
    "Homologous recombination deficiency (HRD) status": "Homologe Rekombinationsdefizienz (HRD) Status",
    "Bin-level log2 coverages and segmentation calls":"Log2-Abdeckungen und Segmentierungsauswahl auf Bin-Ebene",
    "Copy number per chromosome":"Kopienzahl pro Chromosom",
    "Tumor Sample ID":"Tumormuster-ID",
    "Mutational signatures":"Mutationsmuster",
    //reports
    "Clinical Reports":"Klinische Berichte",
    "Create report":"Bericht erstellen",
    "Create a new report for this DIR":"Einen neuen Bericht für dieses DIR erstellen",
    "Patient reports":"Patientenberichte",
    "Report ID":"Bericht-ID",
    "Creation Date":"Erstellungsdatum",
    "Assignee(s)":"Beauftragte(r)",
    "Associated Clinical Report ID":"Zugehörige klinische Bericht-ID",

    //genetic findings
    
    "Variant information": "Informationen zur Variante",
    "Clinical association": "Klinische Assoziation",
    "Population": "Bevölkerung",
    "Predictors": "Prädiktoren",
    "Population frequency": "Häufigkeit in der Bevölkerung",
    "Somatic Related": "Somatisch Verwandt",
    "Treatments": "Behandlungen",
    "Gene name": "Genname",
    "Variant": "Variante",
    "Level of evidence": "Evidenzniveau",
    "Match": "Übereinstimmung",
    "Cancer type": "Krebsart",
    "Therapy": "Therapie",
    "Therapy status": "Therapiestatus",
    "Drug status": "Medikamentenstatus",
    "Review full list of variants": "Vollständige Liste der Varianten überprüfen",
    "See tag in the": "Siehe Tag in der",
    "Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant": "Klicken Sie hier, wenn Sie eine Zusammenfassung der in PhenoStore gespeicherten Daten für den markierten Teilnehmer sehen möchten",
    "Go to Participant": "Zum Teilnehmer gehen",
    "summary": "Zusammenfassung",
    "You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "Sie haben die Variante auch aus PhenoStore gelöscht. Wenn dies ein Versehen war, bitte erneut markieren.",
    "Is this the Causative Variant?": "Ist dies die kausale Variante?",
    "Interpretation": "Interpretation",
    "Clinical Significance": "Klinische Bedeutung",
    "Zygosity": "Zygotie",
    "Origin": "Ursprung",
    "Inheritance": "Vererbung",
    "Trancript": "Transkript",
    "Type of variant": "Variantentyp",
    "Genes": "Gene",
    "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Wenn Sie eine Variante markieren, ist das Tag für alle Benutzer der Plattform sichtbar, unabhängig von der Sperrfrist",
    "Causative Variant (confirmed as the cause of the primary disorder)": "Kausale Variante (bestätigt als Ursache der primären Störung)",
    "Submit": "Einreichen",
    "Cancel": "Abbrechen",
    "Close": "Schließen",
    "Required": "Erforderlich",
    "Previous Tags": "Vorherige Tags",
    "Eperiment ID": "Experiment-ID",
    "Your Tag": "Ihr Tag",
    "Date": "Datum",
    "IGV Browser": "IGV-Browser",
    "Genotypes": "Genotypen",
    "Label": "Label",
    "The variant is tagged and can't be labelled": "Die Variante ist markiert und kann nicht beschriftet werden",
    "Once selected, click again to unapply": "Einmal ausgewählt, klicken Sie erneut, um die Auswahl aufzuheben",
    "Label variant in this study only. Label will be private and different than the tag.": "Markieren Sie die Variante nur in dieser Studie. Das Label wird privat sein und sich vom Tag unterscheiden.",
    "Tag": "Tag",
    "Tag variants in the GPAP database": "Varianten in der GPAP-Datenbank markieren",
    "Columns": "Spalten",
    "Tag SNV Variant": "SNV-Variante markieren",
    "Update/Delete Tag": "Tag aktualisieren/löschen",
    "Tag for SNV Somatic": "Tag für SNV Somatisch",
    "CNV variant already Tagged": "CNV-Variante bereits markiert",
    "Tag CNV Variant": "CNV-Variante markieren",
    "You have tagged this variant for this experiment. You can edit or delete the tag below.": "Sie haben diese Variante für dieses Experiment markiert. Sie können das Tag unten bearbeiten oder löschen.",
    "This variant has been tagged for another/other experiment(s). You can still create a new tag for the same variant. We prefilled the options below with the last tag that was reported.": "Diese Variante wurde für ein anderes/andere Experiment(e) markiert. Sie können immer noch ein neues Tag für dieselbe Variante erstellen. Die Optionen unten wurden mit dem zuletzt gemeldeten Tag vorausgefüllt.",
    "Variant not tagged yet": "Variante noch nicht markiert",
    "Gene Info": "Geninformationen",
    "Clinical Association": "Klinische Assoziation",
    "Population Frequency": "Häufigkeit in der Bevölkerung",
    "Additional Information": "Zusätzliche Information",
    "Variant Information": "Varianteninformationen",
    "Delete your tag": "Ihr Tag löschen",
    "Complete view": "Vollständige Ansicht",
    "Split by genes": "Nach Genen aufteilen",
    "SNV & InDELs - Germline": "SNV & InDELs - Keimbahn",
    "SNV & InDELs - Somatic": "SNV & InDELs - Somatisch",
    "Variants associated to experiments": "Mit Experimenten assoziierte Varianten",
    "Variants": "Varianten",
    "Edit Filters": "Filter bearbeiten",
    "Apply your own filters": "Eigene Filter anwenden",
    "Variants distributions - (based on max 3000 entries)": "Verteilung der Varianten - (basierend auf maximal 3000 Einträgen)",
    "List of Integrated Plugins": "Liste integrierter Plugins",
    "Export Variants (max.1000)": "Varianten exportieren (max.1000)",
    "View Applied Filters": "Angewandte Filter anzeigen",
    "Save": "Speichern",
    "New Analysis": "Neue Analyse",
    "Back": "Zurück",
    "Filter Panel": "Filterpanel",
    "Reset Filters": "Filter zurücksetzen",
    "Run Query": "Abfrage ausführen",
    "Save Filter-Set": "Filter-Set speichern",
    "Clearing filters": "Filter löschen",
    "Use a predefined filter set": "Ein vordefiniertes Filter-Set verwenden",
    "GPAP Predefined Filters are curated by the GPAP team": "Die vordefinierten GPAP-Filter werden vom GPAP-Team kuratiert",
    "Select a predefined filter set": "Ein vordefiniertes Filter-Set auswählen",
    "Reset the applied filter-set": "Das angewendete Filter-Set zurücksetzen",
    "Filter Set deleted": "Filter-Set gelöscht",
    "Reset": "Zurücksetzen",
    "Delete": "Löschen",
    "Select a simulated mode of inheritance configuration": "Eine simulierte Konfiguration des Erbgangs auswählen",
    "Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis\n": "Bitte beachten Sie, dass die vordefinierten Erbgänge den gemeldeten Betroffenheitsstatus der Familienmitglieder berücksichtigen, was in einigen Fällen unrealistische Konfigurationen erzwingen kann. In solchen Fällen verwenden Sie bitte die benutzerdefinierte Konfiguration, um Ihre Hypothese zu formulieren\n",
    "or configure a custom mode of inheritance": "oder konfigurieren Sie einen benutzerdefinierten Erbgang",
    "Create/Adjust Filters": "Filter erstellen/anpassen",
    "Filter by variants": "Nach Varianten filtern",
    "Gene Filters": "Genfilter",
    "Filter by genes": "Nach Genen filtern",
    "Variant Type": "Variantentyp",
    "SNV Effect Predictions": "SNV-Effektvorhersagen",
    "Position Specific and Runs Of Homozygosity": "Positionsbezogene und Läufe von Homozygotie",
    "In this panel you have to select functional annotations criteria": "In diesem Panel müssen Sie Kriterien für funktionale Annotationen auswählen",
    "The population filters return true if the value is lower than filter value or the field value is 0 or n.a; multiple populations filters result are treated with AND logical operator": "Die Populationsfilter geben wahr zurück, wenn der Wert niedriger als der Filterwert ist oder der Feldwert 0 oder n.a ist; das Ergebnis mehrerer Populationsfilter wird mit dem logischen UND-Operator behandelt",
    "The single effect predictor outcome will be handled with OR logical operator": "Das Ergebnis des einzelnen Effektvorhersagers wird mit dem logischen ODER-Operator behandelt",
    "Select specific chromosomes, upload coordinates and BED files": "Bestimmte Chromosomen auswählen, Koordinaten und BED-Dateien hochladen",
    "ClinVar Classification": "ClinVar-Klassifikation",
    "Protein Impact": "Proteinauswirkung",
    "Clear Filters": "Filter löschen",
    "Transcript Biotype": "Transkript-Biotyp",
    "Tagged Variants": "Markierte Varianten",
    "Pathogenic": "Pathogen",
    "Likely pathogenic": "Wahrscheinlich pathogen",
    "Likely benign": "Wahrscheinlich benigne",
    "Benign": "Benigne",
    "Uncertain significance": "Unklare Bedeutung",
    "Drug response": "Medikamentenreaktion",
    "Association": "Assoziation",
    "Risk factor": "Risikofaktor",
    "Protective": "Schützend",
    "Other": "Andere",
    "Heterozygous": "Heterozygot",
    "Homozygous": "Homozygot",
    "Hemizygous": "Hemizygot",
    "Autosomal dominant": "Autosomal-dominant",
    "Autosomal recessive": "Autosomal-rezessiv",
    "X-linked": "X-gebunden",
    "X-linked recessive": "X-gebunden rezessiv",
    "X-linked dominant": "X-gebunden dominant",
    "Y-linked inheritance": "Y-gebundene Vererbung",
    "Digenic inheritance": "Digenische Vererbung",
    "Mitochondrial inheritance": "Mitochondriale Vererbung",
    "Oligogenic inheritance": "Oligogenische Vererbung",
    "Autosomal dominant with paternal imprinting": "Autosomal-dominant mit väterlichem Imprinting",
    "Autosomal dominant with maternal imprinting": "Autosomal-dominant mit mütterlichem Imprinting",
    "Unknown": "Unbekannt",
    "De novo": "De novo",
    "Primary disorder": "Primäre Störung",
    "Secondary findings": "Sekundäre Befunde",
    "Polygenic risk score": "Polygenischer Risikoscore",
    "Duplication": "Duplikation",
    "Deletion": "Deletion",
    "Inversion": "Inversion",
    "Short tandem repeat": "Kurze Tandemwiederholung",
    "Translocation": "Translokation",
    "germline": "Keimbahn",
    "somatic": "Somatisch",
    "de novo": "De novo",
    "maternal": "Mütterlich",
    "paternal": "Väterlich",
    "inherited": "Vererbt",
    "unknown": "Unbekannt",
    "High": "Hoch",
    "Moderate": "Moderat",
    "Low": "Niedrig",
    "Modifier": "Modifikator",
    "Selected samples": "Ausgewählte Proben",
    "Any Sample": "Beliebige Probe",
    "Variant of uncertain significance": "Variante unklarer Signifikanz",
    "Conflicting interpretations": "Widersprüchliche Interpretationen",
    "Drug response": "Medikamentenreaktion",
    "Any": "Beliebig",
    "Other": "Andere",
    "Coding": "Codierung",
    "SnpEff reports putative variant impact in order to make it easier quickly to categorize and prioritize variants. As defined by SnpEFF - see ": "SnpEff-Berichte vermuten Auswirkungen von Varianten, um diese schnell zu kategorisieren und zu priorisieren. Wie von SnpEFF definiert - siehe ",
    "Effect prediction details": "Details zur Effektvorhersage",
    "section for a detailed explanation": "Abschnitt für eine detaillierte Erklärung",
    "When applied,it will return variants that have been tagged only in the GPAP database": "Wenn angewendet, werden nur in der GPAP-Datenbank getaggte Varianten zurückgegeben",
    "ClinVar aggregates information about genomic variation and its relationship to human health": "ClinVar sammelt Informationen über genomische Variationen und deren Beziehung zur menschlichen Gesundheit",
    "Browse Genomics England virtual gene panels related to human disorders ": "Durchsuchen Sie virtuelle Genpanels von Genomics England, die mit menschlichen Störungen in Verbindung stehen ",
    "Genomics England PanelApp": "PanelApp von Genomics England",
    "A number between 0-1": "Eine Zahl zwischen 0-1",
    "INTERNAL FREQUENCY": "INTERNE FREQUENZ",
    "Annotated as disease causing": "Als krankheitsverursachend annotiert",
    "Disease causing": "Krankheitsverursachend",
    "Polymorphism": "Polymorphismus",
    "Neutral": "Neutral",
    "Damaging": "Schädlich",
    "Tolerated": "Toleriert",
    "Probably damaging": "Wahrscheinlich schädlich",
    "Possibly damaging": "Möglicherweise schädlich",
    "Benign": "Benigne",
    "Mutation Taster": "Mutations-Tester",
    "Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)": "Phred-skalierte CADD-Punktzahl, die das Niveau der Schädlichkeit anzeigt, z.B. 20 (vorhergesagt in den obersten 1% der schädlichsten)",
    "Upload BED file": "BED-Datei hochladen",
    "Upload Coordinate file": "Koordinatendatei hochladen",
    "Start Position": "Startposition",
    "End Position": "Endposition",
    "Select a chromosome. Positions are considered ONLY if a chromosome is selected": "Wählen Sie ein Chromosom. Positionen werden NUR berücksichtigt, wenn ein Chromosom ausgewählt ist",
    "Info": "Informationen",
    "The X chromosome has been automatically selected to match the selected inheritance": "Das X-Chromosom wurde automatisch ausgewählt, um der ausgewählten Vererbung zu entsprechen",
    "Selected Positions": "Ausgewählte Positionen",
    "Chromosome required": "Chromosom erforderlich",
    "Chromosome": "Chromosom",
    "When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample": "Wenn angewendet, werden nur Varianten zurückgegeben, die in einem Lauf von Homozygotie fallen, der mindestens die Länge des zuerst betroffenen Musters hat",
    "Minimum run of homozygosity length": "Mindestlänge eines Homozygotie-Laufs",
    "Not Specified": "Nicht spezifiziert",
    "Upload": "Hochladen",
    "Predefined lists": "Vordefinierte Listen",
    "Select a Predefined Gene List prepared from ERNs or the GPAP team": "Wählen Sie eine vordefinierte Genliste aus, die von ERNs oder dem GPAP-Team erstellt wurde",
    "Gene search and upload": "Gen-Suche und -Upload",
    "Enter multiple gene symbols using HGNC nomenclature or Upload your own": "Geben Sie mehrere Gen-Symbole unter Verwendung der HGNC-Nomenklatur ein oder laden Sie Ihre eigenen hoch",
    "WARNING: This platform uses annotations from Ensembl": "WARNUNG: Diese Plattform verwendet Annotationen von Ensembl",
    "Diseases": "Krankheiten",
    "Select one or more OMIM terms or a PanelApp to query the associated genes": "Wählen Sie einen oder mehrere OMIM-Begriffe oder ein PanelApp aus, um die zugehörigen Gene abzufragen",
    "Symptoms": "Symptome",
    "Collect the HPOs from the index case or search more HPOs to query the associated genes": "Sammeln Sie die HPOs vom Indexfall oder suchen Sie weitere HPOs, um die zugehörigen Gene abzufragen",
    "Pathways": "Pfade",
    "Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database": "Pfade werden von REACTOME abgerufen und Assoziationen mit Genen aus der Comparative Toxicogenomics-Datenbank",
    "Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v75 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": "Jedes Gen-Symbol, das für die Filterung verwendet wird und nicht mit dem Symbol in Ensembl v75 übereinstimmt, kann übersehen oder falsch zugeordnet werden. Dies gilt auch für externe Webdienste. Wir arbeiten derzeit daran, dieses Problem zu mildern.",
    "Genes not validated": "Gene nicht validiert",

    "Validating gene symbols..": "Validierung von Gen-Symbolen..",
    "Gene Validation": "Gen-Validierung",
    "Current selected genes by applying ": "Aktuell ausgewählte Gene durch Anwenden ",
    " of gene lists": " von Genlisten",
    "No genes selected": "Keine Gene ausgewählt",
    "All gene symbols were validated using Ensembl": "Alle Gen-Symbole wurden mit Ensembl validiert",
    " gene symbols could not be validated using ": " Gen-Symbole konnten nicht validiert werden mit ",
    "warning": "Warnung",
    "success": "Erfolg",
    "Clear gene list": "Genliste löschen",
    "Union": "Vereinigung",
    "Intersection": "Schnittmenge",
    "Operator": "Operator",
    "Filters have changed. Please, reset the filters and select the filter-set again": "Die Filter haben sich geändert. Bitte setzen Sie die Filter zurück und wählen Sie das Filter-Set erneut aus",
    "Filters applied and added to the current selection. If you want to change the filters or only apply this filter set, click on \"RESET\" first.": "Filter angewendet und zur aktuellen Auswahl hinzugefügt. Wenn Sie die Filter ändern oder nur dieses Filter-Set anwenden möchten, klicken Sie zuerst auf \"RESET\".",
    "Click here if you want to delete the Filter-Set from the database. This is an irreversible action. ": "Klicken Sie hier, wenn Sie das Filter-Set aus der Datenbank löschen möchten. Dies ist eine irreversible Aktion.",
    "Apply": "Anwenden",
    "Validating gene symbols..": "Validierung von Gen-Symbolen in Bearbeitung..",
    "Genetic Findings": "Genetische Befunde",
    "CADD Prediction": "CADD-Vorhersage",
    "Select a predefined gene list": "Wählen Sie eine vordefinierte Genliste",
    "WARNING: This platform uses annotations from Ensembl ": "WARNUNG: Diese Plattform verwendet Annotationen von Ensembl",
    ". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v75 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": ". Jedes für die Filterung verwendete Gen-Symbol, das nicht mit dem in Ensembl v75 verwendeten Symbol übereinstimmt, könnte übersehen oder falsch zugewiesen werden. Dies gilt auch für externe Webdienste. Wir arbeiten derzeit daran, dieses Problem zu mildern.",
    "Gene Search": "Gen-Suche",
    "search for a gene name": "Suche nach einem Gen-Namen",
    "Upload comma separated list of HGNC identifiers": "Hochladen einer durch Kommas getrennten Liste von HGNC-Identifikatoren",
    "(The file is not stored in GPAP)": "(Die Datei wird nicht in GPAP gespeichert)",
    "Upload": "Hochladen",
    "Delete OMIM associated genes": "Lösche mit OMIM assoziierte Gene",
    "Enter multiple OMIM ids or names of disorders": "Geben Sie mehrere OMIM-IDs oder Namen von Störungen ein",
    "Search for OMIM terms": "Suche nach OMIM-Begriffen",
    "Request Error": "Anfragefehler",
    "Error": "Fehler",
    "The type of panel has changed. Click + Add Panel App Genes button": "Der Typ des Panels hat sich geändert. Klicken Sie auf + Genes der Panel-App hinzufügen",
    "Delete PanelApp genes from query": "PanelApp-Gene aus der Abfrage löschen",
    "PanelApp genes added to the query": "PanelApp-Gene zur Abfrage hinzugefügt",
    "Add Panel App Genes": "Panel-App-Gene hinzufügen",
    "Genomics England PanelApp": "PanelApp von Genomics England",
    "Whole panel": "Gesamtes Panel",
    "Green List": "Grüne Liste",
    "Green and Amber lists": "Grüne und Bernsteinfarbene Listen",
    "Type of Panel": "Art des Panels",
    "Loading genes...": "Gene werden geladen...",
    "Panel ": "Panel ",
    "includes a total of ": "umfasst insgesamt ",
    " entities. There are ": " Entitäten. Es gibt ",
    " distinct genes in the ": " unterschiedliche Gene in der ",
    " green list": " grünen Liste",
    "Read more of this panel in PanelApp": "Lesen Sie mehr über dieses Panel in PanelApp",
    "search for OMIM terms": "suche nach OMIM-Begriffen",
    "Select Method:": "Methode auswählen:",
    "of genes in this section is applied ": "von Genen in diesem Abschnitt wird angewendet ",
    "HPO Ontology": "HPO-Ontologie",
    "Select Resource:": "Ressource auswählen:",
    "You have now": "Sie haben jetzt",
    "HPO terms selected": "HPO-Begriffe ausgewählt",
    "search for HPO terms": "suche nach HPO-Begriffen",
    "Get HPOs from Participants": "HPOs von Teilnehmern erhalten",
    "Clear current HPO selection": "Aktuelle HPO-Auswahl löschen",
    "HPOs collected from analysed index case": "HPOs aus analysiertem Indexfall gesammelt",
    "No genes shared across all HPOs": "Keine Gene, die über alle HPOs geteilt werden",
    "No Genes": "Keine Gene",
    "Current Selected Genes ": "Aktuell ausgewählte Gene ",
    "Add Genes to Query": "Gene zur Abfrage hinzufügen",
    "Reset HPO gene list": "HPO-Genliste zurücksetzen",
    " between the HPO genes": " zwischen den HPO-Genen",
    "You didn't include the genes in your query yet.": "Sie haben die Gene noch nicht in Ihre Abfrage aufgenommen.",
    "You included the genes in your query. You made an ": "Sie haben die Gene in Ihre Abfrage aufgenommen. Sie haben eine ",
    "Apply Union of Genes": "Vereinigung von Genen anwenden",
    "Make union of extracted genes (default)": "Vereinigung extrahierter Gene erstellen (Standard)",
    "Apply Intersection of Genes": "Schnittmenge von Genen anwenden",
    "Intersect the genes extracted from the HPOs ": "Schneiden Sie die Gene, die aus den HPOs extrahiert wurden ",
    "Back": "Zurück",
    "Next": "Weiter",

    "In the next step, you can extract genes from HPOs and add them to your queries": "Im nächsten Schritt können Sie Gene aus HPOs extrahieren und sie Ihren Abfragen hinzufügen",
    "Genes associated to": "Mit Genen assoziiert",
    "No genes": "Keine Gene",
    "Search Pathways": "Pfade suchen",
    "Request Error": "Anforderungsfehler",
    "Search for Pathways": "Nach Pfaden suchen",
    "Delete Pathway associated genes": "Mit Pfaden assoziierte Gene löschen",
    "Create New Analysis": "Neue Analyse erstellen",
    "Select a simulated mode of inheritance configuration:": "Wählen Sie eine simulierte Vererbungskonfiguration:",
    "Autosomal recessive (both homozygous and compound heterozygous)": "Autosomal-rezessiv (sowohl homozygot als auch zusammengesetzte Heterozygotie)",
    "compound heterozygosity": "zusammengesetzte Heterozygotie",
    "Autosomal dominant": "Autosomal-dominant",
    "Autosomal dominant - de novo (parents required)": "Autosomal-dominant - de novo (Eltern erforderlich)",
    "X-Linked Recessive ": "X-gebunden rezessiv",
    "X-Linked Dominant ": "X-gebunden dominant",
    "X-Linked Dominant - Index Case Female": "X-gebunden dominant - Indexfall weiblich",
    "Custom Inheritance ": "Benutzerdefinierte Vererbung",
    "Somatic": "Somatisch",
    "You can now create a query that will be applied to the genotype(s). Select your filters and run the new query.": "Sie können nun eine Abfrage erstellen, die auf den/die Genotyp(en) angewendet wird. Wählen Sie Ihre Filter und führen Sie die neue Abfrage aus.",
    "Run Query": "Abfrage ausführen",
    "You have created ": "Sie haben erstellt ",
    " analyses.": " Analysen.",
    "Apply to current Samples": "Auf aktuelle Proben anwenden",
    "or start from new Samples:": "oder mit neuen Proben beginnen:",
    "Configuration details": "Konfigurationsdetails",
    "Affected": "Betroffen",
    "You need at least one experiment": "Sie benötigen mindestens ein Experiment",
    "You need at least one experiment to create an analysis. If you deleted an experiment by mistake, click on restore experiment": "Sie benötigen mindestens ein Experiment, um eine Analyse zu erstellen. Wenn Sie versehentlich ein Experiment gelöscht haben, klicken Sie auf Experiment wiederherstellen",
    "Ok, keep this experiment": "Ok, dieses Experiment behalten",
    "set Genotype Settings": "Genotyp-Einstellungen festlegen",
    "Quality Settings": "Qualitätseinstellungen",
    "No genotype configured. The sample will not be included in the analysis": "Kein Genotyp konfiguriert. Die Probe wird nicht in die Analyse einbezogen",
    "Genotype Settings": "Genotyp-Einstellungen",
    "GPAP Experiment ID": "GPAP-Experiment-ID",
    "Case": "Fall",
    "Affected": "Betroffen",
    "X-Linked Recessive - Index Case Male": "X-gebunden rezessiv - Indexfall männlich",
    "X-Linked Dominant - Index Case Male": "X-gebunden dominant - Indexfall männlich",
    "X-Linked Dominant - Index Case Female": "X-gebunden dominant - Indexfall weiblich",
    "Please, give a name to your study to save it correctly. ": "Bitte geben Sie Ihrer Studie einen Namen, um sie korrekt zu speichern.",
    "Analysis could not be updated successfully. Trying to save queries.": "Die Analyse konnte nicht erfolgreich aktualisiert werden. Versuche, Abfragen zu speichern.",
    "The study could not be saved": "Die Studie konnte nicht gespeichert werden",
    "Study title. Max 50 chars": "Studientitel. Maximal 50 Zeichen",
    "Save changes": "Änderungen speichern",
    "Cancel": "Abbrechen",
    "Share with your groups": "Mit Ihren Gruppen teilen",
    "Please select study visibility": "Bitte wählen Sie die Sichtbarkeit der Studie",
    "Select": "Auswählen",
    "Private": "Privat",
    "Public": "Öffentlich",
    "Study description. Max 50 chars": "Studienbeschreibung. Maximal 50 Zeichen",

    "Fill in some information to save your work.": "Geben Sie einige Informationen ein, um Ihre Arbeit zu speichern.",
    "Update Study Information": "Studieninformationen aktualisieren",
    "Save Study": "Studie speichern",
    "Study Saved Successfully": "Studie erfolgreich gespeichert",
    "Close": "Schließen",
    "One or more queries could not be saved": "Eine oder mehrere Abfragen konnten nicht gespeichert werden",
    "Something went wrong and one or more queries could not be saved correctly. You will not be able to load the study correctly. Please, contact us if the problem persists.": "Etwas ist schiefgegangen und eine oder mehrere Abfragen konnten nicht korrekt gespeichert werden. Sie werden die Studie nicht korrekt laden können. Bitte kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
    "Analyses without queries will not be saved": "Analysen ohne Abfragen werden nicht gespeichert",
    "You are not the creator of the study": "Sie sind nicht der Ersteller der Studie",
    "Save current Study with Analyses and queries": "Aktuelle Studie mit Analysen und Abfragen speichern",
    "Save": "Speichern",
    "You have unsaved queries": "Sie haben nicht gespeicherte Abfragen",
    "New Instand DIR Study": "Neue sofortige DIR-Studie",
    "No description": "Keine Beschreibung",
    "Please select study visibility": "Bitte wählen Sie die Sichtbarkeit der Studie",
    "The number includes SNVs and CNVs (if any)": "Die Zahl beinhaltet SNVs und CNVs (falls vorhanden)",

    "The filters selected for SNV-InDels do not automatically apply to CNVs. You can further filter the CNVs directly": "Die für SNV-InDels ausgewählten Filter gelten nicht automatisch für CNVs. Sie können die CNVs direkt weiter filtern",
    "Used filter-set": "Verwendetes Filter-Set",
    "Applied Filters": "Angewandte Filter",
    "Tag variants": "Varianten taggen",
    "There is no query selected. Or applied filters are not visible.": "Es ist keine Abfrage ausgewählt. Oder angewandte Filter sind nicht sichtbar.",
    "Variant Effect": "Varianteneffekt",
    "Genotype Features": "Genotypmerkmale",
    "Mutation Taster": "Mutationstester",
    "ClinVar Classes": "ClinVar-Klassen",
    "Onco Filter": "Onko-Filter",
    "PolyPhen-2 Pred.": "PolyPhen-2-Vorhersage",
    "GnomAD Filter": "GnomAD-Filter",
    "Programs Filter": "Programmfilter",
    "Lists applied:": "Angewandte Listen:",
    "Include CNVs": "CNVs einschließen",
    "genes": "Gene",
    "Positions": "Positionen",
    "Filter can't be displayed": "Filter kann nicht angezeigt werden",
    "population": "Bevölkerung",
    "No SNV/Indel selected": "Kein SNV/Indel ausgewählt",
    "SNV/Indel variant selected. Click to navigate to resource": "SNV/Indel-Variante ausgewählt. Klicken, um zur Ressource zu navigieren",
    "External Links": "Externe Links",
    "Links curated by the GPAP team": "Vom GPAP-Team kuratierte Links",
    "Frequent Links": "Häufige Links",
    "Disease Information": "Krankheitsinformationen",
    "A list of links releated to disease annotations": "Eine Liste von Links zu Krankheitsannotationen",
    "Variant Information": "Varianteninformationen",

    "Actionability links to identify possible treatments and interventions for a specific gene": "Umsetzbarkeitslinks zur Identifizierung möglicher Behandlungen und Interventionen für ein bestimmtes Gen",
    "Gene Information": "Geninformationen",
    "A list of links releated to gene annotations": "Eine Liste von Links zu Genannotationen",
    "Pathway Information": "Pfadinformationen",
    "A list of links releated to pathways annotations": "Eine Liste von Links zu Pfadannotationen",
    "Data Discovery": "Datenerkundung",
    "A list of links that may allow new discoveries": "Eine Liste von Links, die neue Entdeckungen ermöglichen können",
    "ACMG guidelines": "ACMG-Richtlinien",
    "Pharmacogenomics": "Pharmakogenomik",
    "Actionability": "Umsetzbarkeit",
    "We are working to integrate more resources in this section": "Wir arbeiten daran, mehr Ressourcen in diesen Bereich zu integrieren",
    "Pathways": "Pfade",
    "Pathways Reactome": "Reactome-Pfade",
    "Reactome is a free, open-source, curated and peer-reviewed pathway database": "Reactome ist eine kostenlose, Open-Source-, kuratierte und von Experten begutachtete Pfaddatenbank",
    "No Associations": "Keine Assoziationen",
    "HmtDB - Human Mitochondrial Database": "HmtDB - Menschliche Mitochondrien-Datenbank",
    "External Databases": "Externe Datenbanken",
    "Human Pluripotent Stem Cell Registry": "Register für pluripotente menschliche Stammzellen",
    "Variants distributions": "Variantenverteilungen",
    "Effect Impact": "Auswirkung des Effekts",
    "GnomAD Population": "GnomAD-Bevölkerung",
    "Query": "Abfrage",
    "This query did not return any variants":"aaaa",
    //interpretation requests
    "Interpretation Requests": "Interpretationsanfragen",
    "Case ID": "Fall-ID",
    "Patient ID": "Patienten-ID",
    "Analysis Type": "Analysetyp",
    "Priority": "Priorität",
    "Status": "Status",
    "Creation Date": "Erstellungsdatum",
    "Assignee(s)": "Zugewiesene(r)",
    "Experiment": "Experiment",
    "Clinical referrer": "Klinischer Überweiser",
    "Clinical contact": "Klinischer Kontakt",
    "Hospital Name": "Name des Krankenhauses",
    "Deadline": "Frist",
    "Actions": "Aktionen",
    "Download": "Herunterladen",
    "Upload": "Hochladen",
    "Edit": "Bearbeiten",
    "Link": "Link",

    //contact
    "Get in touch": "Nehmen Sie Kontakt auf",
    "Thank you for using our product.": "Danke, dass Sie unser Produkt nutzen.",
    "Feel free to reach out if you have any questions or comments.": "Zögern Sie nicht, uns zu kontaktieren, wenn Sie Fragen oder Kommentare haben.",
    "Data Platforms & Tools Development Team": "Entwicklungsteam für Datenplattformen und -werkzeuge",
    "Barcelona, Spain": "Barcelona, Spanien",

    //Pharmacogenomics
    "Gene": "Gen",
    "Gene Evidence": "Genetische Evidenz",
    "Genotype": "Genotyp",
    "Phenotype": "Phänotyp",
    "Haplotype1": "Haplotyp1",
    "Haplotype2": "Haplotyp2",
    "Haplotype Evidence": "Haplotyp-Evidenz",
    "Variant Data": "Variantendaten",
    //Settings
    "Filter Set Manager": "Filter-Set-Manager",
    "Create and Save a new Filter Set": "Erstellen und Speichern eines neuen Filter-Sets",
    "Save Filter-Set": "Filter-Set speichern",
    "Filter Name": "Filtername",
    "Filter Description": "Filterbeschreibung",
    "Save Filter": "Filter speichern",
    "Filters saved in the database. You can find the filter-set in the section below.": "Filter im Datenbank gespeichert. Sie können das Filter-Set im unten stehenden Abschnitt finden.",
    "Filter Set Saved Correctly": "Filter-Set korrekt gespeichert",
    "No leading and trailing spaces are allowed": "Führende und nachfolgende Leerzeichen sind nicht erlaubt",
    "Name already exists.": "Name existiert bereits.",
    "filter_description": "filter_beschreibung",
    "Available Filter Sets": "Verfügbare Filter-Sets",
    "Applied filters:": "Angewandte Filter:",
    "No filters found": "Keine Filter gefunden",
    "View filters": "Filter anzeigen",
    "User created": "Benutzer erstellt",
    "GPAP default": "GPAP Standard",
    "No description": "Keine Beschreibung",
    "Settings": "Einstellungen",
    "Filter Sets": "Filter-Sets",
    "Analysis Settings": "Analyseeinstellungen",
    "Analysis Parameters": "Analyseparameter",
    "Standard Pipelines": "Standard-Pipelines",
    "Search:": "Suche:",
    "No results": "Keine Ergebnisse",
    "Export in .txt": "Export in .txt",
    "This list contains": "Diese Liste enthält",
    "Search:": "Suche:",
    "ACTIVE": "AKTIV",
    "Not Active": "Nicht aktiv",
    "Predefined Gene Lists": "Vordefinierte Genlisten",
    "Title:": "Titel:",
    "Description:": "Beschreibung:",
    "Source:": "Quelle:",
    "Permission:": "Berechtigung:",
    "Creator:": "Ersteller:",
    "Version:": "Version:",
    "Size:": "Größe:",
    "Creation Date:": "Erstellungsdatum:",
    "Status:": "Status:",
    "Predefined Lists in GPAP": "Vordefinierte Listen in GPAP",
    "Visible List": "Sichtbare Liste",
        
    "Disabled List. Users won't see this predefined gene list": "Deaktivierte Liste. Benutzer werden diese vordefinierte Genliste nicht sehen",
    "You are deleting this predefined list from the GPAP instance database. If you delete it, GPAP users won't be able to use this list anymore.": "Sie löschen diese vordefinierte Liste aus der GPAP-Instanzdatenbank. Wenn Sie sie löschen, können GPAP-Benutzer diese Liste nicht mehr verwenden.",
    "Yes, delete it": "Ja, löschen",
    "Are you sure to delete the Predefined Gene List?": "Sind Sie sicher, dass Sie die vordefinierte Genliste löschen möchten?",
    "List of genes": "Liste der Gene",
    "Predefined Gene List Info": "Infos zur vordefinierten Genliste",
    "Public": "Öffentlich",
    "Share with your groups": "Mit Ihren Gruppen teilen",
    "Private": "Privat",
    "Please select study visibility": "Bitte wählen Sie die Studiensichtbarkeit",
    "Visibility": "Sichtbarkeit",
    "Select": "Auswählen",
    "Gene List": "Genliste",
    "Variant Positions": "Variantenpositionen",
    "Notes": "Notizen",
    "List Source": "Listenquelle",
    "Description": "Beschreibung",
    "List Title": "Listen-Titel",
    "List Information": "Listeninformationen",
    "Upload Successful": "Hochladen erfolgreich",
    "The GPAP gene file was successfully uploaded and it's ready to be used.": "Die GPAP-Gen-Datei wurde erfolgreich hochgeladen und ist bereit zur Verwendung.",
    "Save List": "Liste speichern",
    "Create a new List": "Eine neue Liste erstellen",
    "List Visibility": "Listen-Sichtbarkeit",
    "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Wenn aktiv, wird die Liste für Analysen nutzbar sein. Wenn diese Liste denselben Namen wie eine vorhandene hat, wird die vorherige für Benutzer inaktiv.",
    "Predefined Gene Lists": "Vordefinierte Genlisten",
    "Upload a List": "Eine Liste hochladen",
    "View Lists": "Listen anzeigen",
    "File type": "Dateityp",
    "Please select file type": "Bitte Dateityp auswählen",
    "GPAP Gene File": "GPAP-Gen-Datei",
    ".list File": ".list-Datei",
    "Comma Separated List": "Komma-getrennte Liste",
    "You don't have the rights to do edits here": "Sie haben nicht das Recht, hier Änderungen vorzunehmen",
    "Gene list was validated": "Genliste wurde validiert",
    "Upload Gene list": "Genliste hochladen",
    "Some genes could not be validated. Please check them and re-upload the file:": "Einige Gene konnten nicht validiert werden. Bitte überprüfen Sie diese und laden Sie die Datei erneut hoch:",
    "Please check the text": "Bitte überprüfen Sie den Text",
    "Genes are not in the correct format, please check the text": "Gene sind nicht im richtigen Format, bitte überprüfen Sie den Text",
    "Fields should not be empty, please check the text": "Felder sollten nicht leer sein, bitte überprüfen Sie den Text",
    "Gene list is empty. Please check the file.": "Genliste ist leer. Bitte überprüfen Sie die Datei.",
    "Cancel Gene List Upload": "Hochladen der Genliste abbrechen",
    "Upload a GPAP gene file in txt format": "Eine GPAP-Gen-Datei im txt-Format hochladen",
    "Be sure that the file is in the correct format and all required fields are present": "Stellen Sie sicher, dass die Datei im korrekten Format ist und alle erforderlichen Felder vorhanden sind",
    "Select file": "Datei auswählen",
    "Don't know the correct format?": "Kennen Sie das richtige Format nicht?",
    "Download our template file and fill it in with your data": "Laden Sie unsere Vorlagendatei herunter und füllen Sie sie mit Ihren Daten aus",
    "Download sample file": "Beispieldatei herunterladen",
    "Validating genes..": "Gene validieren...",
    "Upload a .list file": "Eine .list-Datei hochladen",
    "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "Die .list-Datei ist das Legacy-Dateiformat, das verwendet wird, um vordefinierte Genlisten im GPAP zu speichern. Dieser Upload ist für CNAG-Benutzer gedacht. Für weitere Informationen kontaktieren Sie Steve Laurie.",
    "The file must contain a JSON array of Predefined Gene List Objects.": "Die Datei muss ein JSON-Array von vordefinierten Genlisten-Objekten enthalten.",
    "upload .list file": "Datei .list hochladen",
    "lists parsed correctly and ready to be saved.": "Listen korrekt analysiert und bereit zum Speichern.",
    "Save Lists": "Listen speichern",
    "View DIR list": "DIR-Liste anzeigen",
    "Create a new DIR": "Eine neue DIR erstellen",
    "Priority": "Priorität",
    //report
    "Welcome to the Somatic Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.":"aaaaa",
    "Report Name": "aaaaa",
    "New Clinical Report": "aaaaa",
    "Welcome to the Germline Clinical Report creation screen. In here you’ll be able to create and export a molecular genetic report document with the information you’ve reviewed and processed for this interpretation request. Read, review and complete each section and, when you’re done, click on the Create button to export your report.": "aaaaa",
    "Save": "aaaaa",
    "Preview": "aaaaa",
    "Technical limitations": "aaaaa",
    "References": "aaaaa",
    "Disclaimer": "aaaaa",
    "Clinical evidence and management": "aaaaa",
    "Interpretation": "aaaaa",
    "Genetic Findings Summary": "aaaaa",
    "General Info": "aaaaa",
    "These are the interpretation comments that were written in each variant tag.": "aaaaa",
    "The original tag comment will not be modified here. Your modifications will only be visible in the exported report.": "aaaaa",
    "Requesting centre":"aaaaa",
    "Issuing centre":"aaaaa",
    "Referrer":"aaaaa",
    "Reporting date":"aaaaa",
    "Patient Info":"aaaaa",
    "Name":"aaaaa",
    "Surname":"aaaaa",
    "Patient ID":"aaaaa",
    "Sex":"aaaaa",
    "Clinical Info":"aaaaa",
    "Sample Type":"aaaaa",
    "Biopsy Location":"aaaaa",
    "Reason for referral":"aaaaa",
    "Study performed":"aaaaa",
    "Library strategy":"aaaaa",
    "These are the variants that were tagged in the":"aaaaa",
    "Genetic Findings":"aaaaa",
    " tab of this Interpretation Request. Check all the variants that you want to display in the final report.":"aaaaa",
    "*Based upon reference genome version GRCh38":"aaaaa",
    "**Based upon ClinGen, CGC and VICC recommendations (Horak et al 2022)":"aaaaa",
    "Conclusion":"aaaaa",
    "The following annexes complete the report, which must be evaluated in its entirety":"aaaaa",
    "These are the treatment options that were tagged in the":"aaaaa",
    "Clinical Evidence":"aaaaa",
    " tab of this Interpretation Request.":"aaaaa",
    "Check all the treatments that you want to display in the final report.":"aaaaa",
    "The":"aaaaa",
    " Clinical Significance":"aaaaa",
    "field shows the comments that were added when tagging an evidence.":"aaaaa",
    "The original tag comment ":"aaaaa",
    "will not":"aaaaa",
    " be modified here. Your modifications will only be visible in the exported report.":"aaaaa",
    "Conclusion":"aaaaa",
    "Clinical Significance":"aaaaa",
    "These are the interpretation comments that were written in each evidence tag.":"aaaaa",
    "The original tag comment ":"aaaaa",
    "will not":"aaaaa",
    " be modified here. Your modifications will only be visible in the exported report.":"aaaaa",
    "Genomic breakpoints detected":"aaaaa",
    "Clinically relevant gene":"aaaaa",
    "Origin":"aaaaa",
    "Clinical significance**":"aaaaa",
    "Zygosity":"aaaaa",
    "Protein":"aaaaa",
    "Gene":"aaaaa",
    "Transcript":"aaaaa",
    "Genomic Position:":"aaaaa",
    "Comment":"aaaaa",
    //annexes
    "The following annexes complete the report, which must be evaluated in its entirety":"aaaaa",
    "Annex 1: Clinical record":"aaaaa",
    "Cancer Type":"aaaaa",
    "Family History":"aaaaa",
    "Treatment":"aaaaa",
    "Annex 2: Tumor genomic characteristics":"aaaaa",
    "Annex 2: Methodology":"aaaaa",
    "Test method":"aaaaa",
    "Type of variants assessed":"aaaaa",
    "Sample type":"aaaaa",
    "Sample received":"aaaaa",
    "Date of sample collection":"aaaaa",
    "Sample processing":"aaaaa",
    "Centre":"aaaaa",
    "Sequencing platform":"aaaaa",
    "Fragment (size and type)":"aaaaa",
    "Bioinformatic analysis":"aaaaa",
    "Human reference genome":"aaaaa",
    "Aligner":"aaaaa",
    "Mark duplicates":"aaaaa",
    "Base recalibration":"aaaaa",
    "Smart variants caller":"aaaaa",
    "Analysis pipeline":"aaaaa",
    "Annex 3 : Quality control metrics":"aaaaa",
    "Genome coverage metrics":"aaaaa",
    "C10/C15/C20: percentage of bases covered by at least 10,15 and 20 reads, respectively.":"aaaaa",
    "List of diagnostically targeted genes":"aaaaa",
    "Coverage of the genes of interest (RefSeq coding sections)":"aaaaa",
    "These genes are the one that do NOT fit the coverage threshold (90%).":"aaaaa",
    "Annex 4 : Variant metrics and genotype-phenotype associations":"aaaaa",
    "Variant metrics":"aaaaa",
    "GT: genotype; GQ: genotype quality; DP: depth of coverage (total reads); REF count: reads supporting reference allele; ALT count: reads supporting alternative allele; AAF: alternative allele frequency":"aaaaa",
    "Genotype-phenotype associations":"aaaaa",
    "Pharmacogenomics":"aaaaa",
    "These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report.":"aaaaa",
    "Pharmacogenomics interpretation":"aaaaa",
    "These are the interpretation comments that were written in each haplotype tag. The original tag comment will not be modified here. Your modifications will only be visible in the exported report.":"aaaaa",
    "Purity":"aaaaa",
    "Average ploidy":"aaaaa",
    "Annex 3: Other biomarkers":"aaaaa",
    "Microsatellite status":"aaaaa",
    "Tumor mutation load":"aaaaa",
    "Tumor mutational burden":"aaaaa",
    "Homologous recombination deficiency":"aaaaa",
    "Mutational profiles":"aaaaa",
    "Annex 4: Methodology":"aaaaa",
    "Test method":"aaaaa",
    "Type of variants assessed":"aaaaa",
    "Sample received":"aaaaa",
    "Sample type":"aaaaa",
    "Sample condition":"aaaaa",
    "Site of tumor":"aaaaa",
    "Treatment given before sample was taken":"aaaaa",
    "Date of sample collection":"aaaaa",
    "DNA purity":"aaaaa",
    "DNA integrity":"aaaaa",
    "Sample processing":"aaaaa",
    "Centre":"aaaaa",
    "Sequencing platform":"aaaaa",
    "Fragment (size and type)":"aaaaa",
    "Bioinformatic analysis":"aaaaa",
    "Human reference genome":"aaaaa",
    "Aligner":"aaaaa",
    "Mark duplicates":"aaaaa",
    "Base recalibration":"aaaaa",
    "Smart variants caller":"aaaaa",
    "Analysis pipeline":"aaaaa",
    "Annex 5: Quality control metrics":"aaaaa",
    "Exome coverage metrics":"aaaaa",
    "C10/C15: percentage of bases covered by at least 10,15 and 20 reads,respectively.":"aaaaa",
    "List of diagnostically targeted genes":"aaaaa",
    "Coverage of the genes of interest (RefSeq coding sections)":"aaaaa",
    "These genes are the one that do NOT fit the coverage threshold (90%).":"aaaaa",
    "Gene symbol":"aaaaa",
    "Transcript":"aaaaa",
    "Mean Coverage":"aaaaa",
    "Duplication Rate ":"aaaaa",
    "Uniformity":"aaaaa",
    "Gene Symbol":"aaaaa",
    "Median Coverage":"aaaaa",
    "Transcript (RefSeq)":"",
    "Mean coverage":"aaaaa",
    "Transcript cDNA":"aaaaa",
    "Gene MIM Number":"aaaaa",
    "Phenotype":"aaaaa",
    "Phenotype MIM Number":"aaaaa",
    "Inheritance":"aaaaa",
    "Annexes":"aaaaa",
    "Upload Report":"aaaaa",
    "Create report":"aaaaa",
    //docx report
    "MOLECULAR GENETIC REPORT":"aaaa",
    "REASON FOR REFERRAL:":"aaaaa",
    "STUDY PERFORMED:":"aaaaa",
    "TEST PERFORMED:":"aaaaa",
    "Sample type:":"aaaaa",
    "Biopsy location:":"aaaaa",
    "Sex: ":"aaaaa",
    "Surname: ":"aaaaa",
    "Age: ":"aaaaa",
    "Name: ":"aaaaa",
    "Identifier: ":"aaaaa",
    "Referrer: ":"aaaaa",
    "Reporting date:":"aaaaa",
    "Requesting center:":"aaaaa",
    "Issuing center:":"aaaaa",
    "GENETIC FINDINGS":"aaaaa",
    "*Based upon reference genome version GRCh38":"aaaaa",
    "**Based upon ACMG recommendations (Richards et al. 2015, Riggs et al. 2020 and https://clinicalgenome.org/) ":"aaaaa",
    "Genomic breakpoints detected":"aaaaa",
    "Clinically relevant gene":"aaaaa",
    "Origin":"aaaaa",
    "Clinical significance**":"aaaaa",
    "Zygosity":"aaaaa",
    "Protein":"aaaaa",
    "Gene":"aaaaa",
    "Transcript":"aaaaa",
    "cDNA":"aaaaa",
    "INTERPRETATION":"aaaaa",
    "CONCLUSION":"aaaaa",
    "Not available":"aaaaa",
    "RECOMENDATION":"aaaaa",
    "references":"aaaaa",
    "Page ":"aaaaa",
    " of ":"aaaaa",
    "CLINICAL EVIDENCE AND MANAGEMENT":"aaaaa",
    "Only information relevant to the specific type of cancer under investigation is reported.":"aaaaa",
    "Not Provided":"aaaaa",
    "No pathogenic or likely pathogenic single nucleotide variant(s), small indel(s), copy number variant(s) or structural variant(s) have been detected. This result does not exclude a possible genetic origin as the cause of the disorder":"aaaaa",
    "No Clinical Evidence was reported for this DIR":"aaaaa",
    "DISCLAIMER":"aaaaa",
    "TECHNICAL LIMITATIONS":"aaaaa",
    "REFERENCES":"aaaaa",
    //annexes word document
    "Test method":"aaaaa",
    "Type of variants assessed":"aaaaa",
    "Sample received":"aaaaa",
    "Sample type":"aaaaa",
    "Date of sample collection":"aaaaa",
    "Sample processing:":"aaaaa",
    "Center":"aaaaa",
    "Sequencing platform":"aaaaa",
    "Fragment (size and type)":"aaaaa",
    "Bioinformatics analysis:":"aaaaa",
    "Human reference genome":"aaaaa",
    "Aligner":"aaaaa",
    "Mark duplicates":"aaaaa",
    "Base recalibration":"aaaaa",
    "Smart variants caller":"aaaaa",
    "Analysis pipeline":"aaaaa",
    "ANNEX III: QUALITY CONTROL METRICS":"aaaaa",
    "ANNEXES":"aaaaa",
    "The following annexes complete the report, which must be evaluated in its entirety.":"aaaaa",
    "ANNEX I: CLINICAL RECORD":"aaaaa",
    "ANNEX II: METHODOLOGY":"aaaaa",
    "ANNEX III: QUALITY CONTROL METRICS":"aaaaa",
    "ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS":"aaaaa",
    "ANNEX V: PHARMACOGENOMICS RESULTS":"aaaaa",
    "ANNEX I: CLINICAL RECORD":"aaaaa",
    "Clinical symptoms and physical findings":"aaaaa",
    "ANNEX II: METHODOLOGY":"aaaaa",
    "Family history":"aaaaa",
    "Clinical symptoms and physical findings":"aaaaa",
    "ANNEX II: TUMOUR GENOMICS CHARACTERISTICS":"aaaaa",
    "ANNEX III: OTHER BIOMARKERS":"aaaaa",
    "ANNEX IV: METHODOLOGY":"aaaaa",
    "ANNEX V: QUALITY CONTROL METRICS":"aaaaa",
    "Tumour mutation load":"aaaaa",
    "Tumour mutational burden":"aaaaa",
    "Homologous Recombination Deficiency":"aaaaa",
    "Mutational profiles":"aaaaa",
    "Molecular tissue of origin prediction":"aaaaa",
    "Tumor specific viral insertions":"aaaaa",
    "Site of tumour":"aaaaa",
    "Sample information:":"aaaaa",
    "No data available":"aaaaa",
    "C10 / C15: percentage of bases covered by at least 10, 15 and 20 reads, respectively.":"aaaaa",
    "Mean coverage":"aaaaa",
    "Median coverage":"aaaaa",
    "Duplication rate":"aaaaa",
    "Uniformity":"aaaaa",
    "Drug":"aaaaa",
    "Level of evidence":"aaaaa",
    "Clinical significance":"aaaaa",
    "Coverage of the genes of interest (RefSeq coding regions):":"aaaaa",
    "*for microRNAs coverage is computed using Ensembl and based on the complete genomic region":"aaaaa",
    "GT: genotype; GQ: genotype quality; DP: depth of coverage (total reads); REF count: reads supporting reference allele; ALT count: reads supporting alternative allele; AAF: alternative allele frequency":"aaaaa",
    "Gene_MIM":"aaaaa",
    "Phenotype_MIM":"aaaaa",
    "Variant metrics:":"aaaaa",
    "Genotype-phenotype associations:":"aaaaa",
    "Create Request":"aaaaa",
    //upload file dialog
    "Upload Clinical Report":"aaaaa",
    "Report name":"aaaaa",
    "Select File":"aaaaa",
    "Supported files: .pdf, .doc, .docx, .ppt, .pptx, .xls, .csv, .jpg, .png":"aaaaa",
    "Cancel":"aaaaa",
    "Upload":"aaaaa",
    "File uploaded successfully":"aaaaa",
    "OK":"aaaaa",
    //references text
    "Horak P, Griffith M, Danos AM, et al. Standards for the classification of pathogenicity of somatic variants in cancer (oncogenicity): Joint recommendations of Clinical Genome Resource (ClinGen), Cancer Genomics Consortium (CGC), and Variant Interpretation for Cancer Consortium (VICC) [published correction appears in Genet Med. 2022 Sep;24(9):1991]. Genet Med. 2022;24(5):986-998":"aaaaa",
    "Li MM, Datto M, Duncavage EJ, et al. Standards and Guidelines for the Interpretation and Reporting of Sequence Variants in Cancer: A Joint Consensus Recommendation of the Association for Molecular Pathology, American Society of Clinical Oncology, and College of American Pathologists. J Mol Diagn. 2017;19(1):4-23.":"aaaaa",
    "Richards S, Aziz N, Bale S, et al. ACMG Laboratory Quality Assurance Committee. Standards and guidelines for the interpretation of sequence variants:a joint consensus recommendation of the American College of Medical Genetics and Genomics and the Association for Molecular Pathology. Genet Med. 2015 May;17(5):405-24":"aaaaa",
    "Riggs ER, Andersen EF, Cherry AM, et al. Technical standards for the interpretation and reporting of constitutional copy-number variants: a joint consensus recommendation of the American College of Medical Genetics and Genomics (ACMG) and the Clinical Genome Resource (ClinGen). Genet Med. 2020 Feb;22(2):245-257. ":"aaaaa",
    //TL TEXT
    "WES":"aaaaa",
    "panels":"aaaaa",
    "WGS":"aaaaa",
    "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.":"aaaaa",
    "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.":"aaaaa",
    "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of variant detection protocols using whole genome sequencing is of 99.79% and 99.94% respectively for single nucleotide variants,  99.38% and 99.04% for small insertions / deletions (indels) of up to 10 nucleotides and of 99% and 42% for copy numbers variants with a minimum resolution of 5kb. Other types of genetic variants such as repeat expansions, large structural rearrangements or variations in regions of high homology (pseudogenes and homologues) may not be detected. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.":"aaaaa",
    "The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis. ":"aaaaa",
    "The internal assessment of specificity and sensitivity of variant detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69% respectively for single nucleotide variants and of 70.62% and 89.99% for small insertions / deletions (indels) of up to 10 nucleotides. Other types of genetic variants such as repeat expansions, copy number variants, structural variants or variations in regions of high homology (pseudogenes and homologues) may not be detected. This approach neither captures deep intronic variants nor intergenic regions nor variants in promoter or regulatory regions of the gene. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis.":"aaaaa",
    "The internal assessment of specificity and sensitivity of variant detection protocols using whole genome sequencing is of 99.79% and 99.94% respectively for single nucleotide variants,  99.38% and 99.04% for small insertions / deletions (indels) of up to 10 nucleotides and of 99% and 42% for copy numbers variants with a minimum resolution of 5kb. Other types of genetic variants such as repeat expansions, large structural rearrangements or variations in regions of high homology (pseudogenes and homologues) may not be detected. Consecutive variants forming haplotypes are not collapsed to multiple nucleotide variants (MNVs) and are reported as individual events. Variants have not been validated by an independent analysis. ":"aaaaa",
    "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of fusion detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69%. Variants have not been validated by an independent analysis.":"aaaaa",
    "Variant detection in samples with lower tumour content is less sensitive. In case of a low tumour purity (below 20%) likelihood of failing to detect potential variants increases. The internal assessment of specificity and sensitivity of fusion detection protocols using the KAPA HyperExome capture kit from Roche® is of 99.54% and 99.69%. Variants have not been validated by an independent analysis.":"aaaaa",
    //disclaimer text
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022, Gene Fusion Specification —":"aaaaa",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.":"aaaaa",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)":"aaaaa",
    "Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).":"aaaaa",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS)":"aaaaa",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.":"aaaaa",
    "":"aaaaa",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.":"aaaaa",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)":"aaaaa",
    " Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).":"aaaaa",
    "Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene.":"aaaaa",
    " genomic variants":"aaaaa",
    "The displayed number is the total sum of events in the FULL and SPLIT results.":"aaaaa",
    "":"aaaaa",
    "":"aaaaa",
    "":"aaaaa",

}