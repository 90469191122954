

import StarIcon from "@material-ui/icons/Star";
import {WatchLater} from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";


const labelIcons = {
    "Candidate": StarIcon,
    "Follow-Up": WatchLater,
    "Discarded": CancelIcon,
    "Important": PriorityHighIcon
};

export default labelIcons