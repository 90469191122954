

function handleEnsemblJSON(json){
    
    // Previous Ensembl endpoint parsing
    /*let array = [];

    if (json.error === "" && json.result.spellcheck.suggestions.length > 0) {

        let parent = json.result.spellcheck.suggestions[1].suggestion
            .map( (x) => x.split("__") )
            .filter( (x) => x[2].toLowerCase() === "gene" )

        for(let i = 0; i < parent.length;i++){
            let gene_obj = {};
            gene_obj.name = parent[i][1].toUpperCase()
            gene_obj.id = parent[i][3]

            if (array.filter((x) => x.name === gene_obj.name).length === 0)
                array.push(gene_obj)
        }
    }
    return array;*/


    // New Ensenble endpoint parsing
    let array = []

    if (json) {
      //hg38
      if (window.config.genome_ref && window.config.genome_ref === "hg38") {
        for (const key in json) {
          if (json.hasOwnProperty(key)) {
          const gene_obj = {
            id: json[key].g,
            name: json[key].label
          };
          array.push(gene_obj);
          }
        }
      } 
      //hg19
      else  {
        for (const item of json) {
          if (item.length >= 3) {
            const [name, id, core] = item;

            // Check if a gene with the same key already exists in array
            const existingGene = array.find(gene => gene.name === name);

            if (!existingGene) {
              const gene_obj = {
                id: id,
                name: name
              };
              array.push(gene_obj);
            }
          }
        }
      }
    }

  return array;

}

// handle the json responses;

function handleResponse(itemType, json) {


    if(itemType === "genes"){
        // handle Ensembl response
        return handleEnsemblJSON(json).map(s => {
            return {value: s.name, label: s.name}
        });

    }
    else if(itemType === "HPO"){
        if(json.hits!== undefined && json.hits.hits!== undefined){
            return json.hits.hits.map(function(d){
                return {label: d._source.name + "(" + d._source.id + ") " , value: d._source.name, refCode: d._source.id}
            });
        }
        else{
            return [];
        }
    }
    else if(itemType === "OMIM"){
        return json.results
            .filter(item => item.id.includes("OMIM"))
            .map(function(d){
          return {label: d.name, value: d.name, refCode: d.id}
        });
    }
    else if(itemType === "pathways" ){

        if(json.hasOwnProperty("entries")){
            return json.entries.map(function(d){
                return {label: d.name, value: d.name, refCode: d.dbRef, gene: d.stId}
            })
        }
        else{
            return null;
        }


    }
    else{
        return null;
    }

}






export {handleResponse}