

import {SELECTED_VARIANT} from "./actions"


const variant_initialState = {
    variants: []
};

export const selectedVariant = (state= variant_initialState.variants, action) => {

    switch (action.type) {

        case SELECTED_VARIANT:
            let list_selected_variants = [...state];
            // check for uniqueID:
            if(action.variant.length === 0 ){
                list_selected_variants = [];
            }
            else {
                list_selected_variants =  [... action.variant];
            }
            return list_selected_variants;
        default:
            return state
    }
};
