import React, {Fragment, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function LanguageSelector() {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {

        console.log(lng);
        i18n.changeLanguage(lng);
        handleCloseMenu();
    };

    return (
        <Fragment>
            <Button
                //variant="outlined"
                onClick={handleOpenMenu}
                sx={{  
                textDecoration: 'none', 
                color:"#3e699e", 
                backgroundColor: 'rgba(62, 105, 158, 0.1)', 
                padding:'10px',  
                width: '35px', 
                height: '35px', 
                borderRadius:'5px'}}
            >
                {i18n.language}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}>
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => changeLanguage('ca')}>Catalan</MenuItem>
                <MenuItem onClick={() => changeLanguage('es')}>Español</MenuItem>
                <MenuItem onClick={() => changeLanguage('it')}>Italiano</MenuItem>
                <MenuItem onClick={() => changeLanguage('de')}>Deutsch</MenuItem>

                {/* Add menu items for other languages as needed */}
            </Menu>
        </Fragment>
    );
}

export default LanguageSelector;
