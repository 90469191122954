

export const test_espanol = {
    "Genomic Analysis": "Análisis genómico",
    "Dashboard": "Tablero",
    "Edit": "Modificar",
    "Annotations": "Anotaciones",
    "{props.text}": "{props.texto}",
    "{children}": "{niños}",
    "{props.input.name}": "{props.entrada.nombre}",
    "Alfa - ALamut Functional Annotations": "Alfa - Anotaciones funcionales de ALamut",
    "EBI Annotations": "Anotaciones EBI",
    "eDGAR - Disease-Gene associations": "eDGAR - Asociaciones enfermedad-gen",
    "We are working to integrate more resources in this section": "Estamos trabajando para integrar más recursos en esta sección.",
    "Pathways": "Caminos",
    "Other Resources": "Otros recursos",
    "Biosoftware - RD-Connect": "Biosoftware - RD-Connect",
    "{message}": "{mensaje}",
    "Request Error for selected position": "Error de solicitud para la posición seleccionada",
    "Pathogenicity: {getField(data, \"pathogenicity\")}": "Patogenicidad: {getField(data, \"patogenicidad\")}",
    "Haplogroups: {getField(data, \"haplogroups\")}": "Haplogrupos: {getField(datos, \"haplogrupos\")}",
    "CrossRef:": "Referencia cruzada:",
    "Genomes1K_hetero: {getField(data, \"mitomap_associated_disease\")}": "Genomas1K_hetero: {getField(datos, \"mitomap_associated_disease\")}",
    "Genomes1K_hetero: {getField(data, \"pubmed_ids\")}": "Genomas1K_hetero: {getField(datos, \"pubmed_ids\")}",
    "Genomes1K_hetero: {getField(data[\"Plasmy\"], \"genomes1K_hetero\")}": "Genomas1K_hetero: {getField(datos[\"Plasmy\"], \"genomas1K_hetero\")}",
    "Genomes1K_homo: {getField(data[\"Plasmy\"], \"genomes1K_homo\")}": "Genomas1K_homo: {getField(datos[\"Plasmy\"], \"genomas1K_homo\")}",
    "Mitomap_hetero: {getField(data[\"Plasmy\"], \"mitomap_hetero\")}": "Mitomap_hetero: {getField(datos[\"Plasmy\"], \"mitomap_hetero\")}",
    "Mitomap_homo: {getField(data[\"Plasmy\"], \"mitomap_homo\")}": "Mitomap_homo: {getField(datos[\"Plasmy\"], \"mitomap_homo\")}",
    "Predictions:": "Predicciones:",
    "Mut. Pred.: {getField(data[\"Predict\"], \"mutPred_pred\")}": "Mut. Pred.: {getField(data[\"Predict\"], \"mutPred_pred\")}",
    "Polyphen2_humDiv: {getField(data[\"Predict\"], \"polyphen2_humDiv_pred\")}": "Polyphen2_humDiv: {getField(data[\"Predict\"], \"polyphen2_humDiv_pred\")}",
    "Polyphen2 HumVar: {getField(data[\"Predict\"], \"polyphen2_humVar_pred\")}": "Polyphen2 HumVar: {getField(data[\"Predict\"], \"polyphen2_humVar_pred\")}",
    "SNP GO: {getField(data[\"Predict\"], \"snp_go_pred\")}": "SNP GO: {getField(data[\"Predict\"], \"snp_go_pred\")}",
    "No information available": "no hay información disponible",
    "HmtDB - Human Mitochondrial Database": "HmtDB - Base de datos mitocondrial humana",
    "{text}": "{texto}",
    "hPSCreg": "hPSCreg",
    "Query for {props.input} didn't return any associations": "La consulta de {props.input} no arrojó ninguna asociación",
    "No variant selected": "Ninguna variante seleccionada",
    "Pathways Reactome {(data) ? \"(\" + data.numHits + \")\": \"\"}": "Vías Reactome {(datos)? \"(\" + datos.numHits + \")\": \"\"}",
    "ebi.ac.uk could not be queried correctly.": "No se pudo consultar ebi.ac.uk correctamente.",
    "Query for {props.input}": "Consulta para {props.input}",
    "UniProtKB id:{item.id}": "Identificación de UniProtKB: {item.id}",
    "Query for {props.input} didn't return annotations": "La consulta de {props.input} no devolvió anotaciones",
    "GO": "IR",
    "Wikipathways": "Wikivías",
    "Only Variants from Chromosome 1 are shown.": "Sólo se muestran las variantes del cromosoma 1.",
    "External Databases": "Bases de datos externas",
    "External Links": "Enlaces externos",
    "No SNV/Indel selected": "No se seleccionó ningún SNV/Indel",
    "SNV/Indel variant selected. Click to navigate to resource": "Variante SNV/Indel seleccionada. Haga clic para navegar al recurso",
    "{labelText}": "{etiquetaTexto}",
    "{labelInfo}": "{etiquetaInfo}",
    "Experiment {experiment} has already {previous.length} analyses status.": "El experimento {experimento} ya tiene el estado de análisis {previous.length}.",
    "No Analyses reported for sample {experiment}": "No se informaron análisis para la muestra {experimento}",
    "{opt.label}": "{opt.etiqueta}",
    "There are no previous analyses": "No hay análisis previos",
    "HPO{hpo_selected.length} HPOs selected for {item.n_genes} genes": "HPO{hpo_selected.length} HPO seleccionados para genes {item.n_genes}",
    "{item.type}{getVal(item.label) + getVal(\" genes\", item.n_genes)  }": "{item.type}{getVal(item.label) + getVal(\" genes\", item.n_genes) }",
    "{item.type}{getVal(item.label) + getVal(\" genes\", item.n_genes) + getVal(\", v\", item.version) }": "{item.type}{getVal(item.label) + getVal(\" genes\", item.n_genes) + getVal(\", v\", item.version) }",
    "{g}": "{gramo}",
    "None": "Ninguno",
    "Applied Filters": "Filtros aplicados",
    "There is no query selected. Or applied filters are not visible.": "No hay ninguna consulta seleccionada. O los filtros aplicados no son visibles.",
    "The variant is tagged and can't be labelled": "La variante está etiquetada y no se puede etiquetar.",
    "Once selected, click again to\n              unapply": "Una vez seleccionado, haga clic nuevamente para\n              cancelar la aplicación",
    "Annotate with VarSome": "Anotar con VarSome",
    "Exomiser & Genomiser": "Exomisador y genomizador",
    "# analysis: {currentStudy.analyses.length}": "# análisis: {currentStudy.analyses.length}",
    "{variant_type } variant {status} correctly": "{variant_type } variante {status} correctamente",
    "Please complete the participant diagnosis in GPAP PhenoStore": "Por favor complete el diagnóstico del participante en GPAP PhenoStore",
    "You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "También eliminaste la variante de PhenoStore. Si lo hiciste por error, etiqueta nuevamente.",
    "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Cuando ETIQUETAS una variante, la etiqueta será visible para todos los usuarios de la plataforma independientemente del período de embargo.",
    "{(variant_flagged)\n                                        ?{getTagVisibility()}:Variant not tagged yet}": "{(variante_marcada)\n                                        ?{getTagVisibility()}:Variante aún no etiquetada}",
    "{text} {infoIconElement}": "{texto} {infoIconElement}",
    "Load variants by clicking on a gene or experiment on the left, or a participant in the table above.": "Cargue variantes haciendo clic en un gen o experimento a la izquierda, o en un participante en la tabla de arriba.",
    "{props.participants.length} Participants Selected : {props.participants[0]}": "{props.participants.length} Participantes seleccionados: {props.participants[0]}",
    "{(type === \"search_across\") ? \"Variants associated to experiments\"  : \"Variants\"}": "{(tipo === \"search_across\") ? \"Variantes asociadas a experimentos\": \"Variantes\"}",
    "The Exomiser plugin is able to prioritise variants from whole-exome (Exomiser) or whole-genome sequencing data (Genomiser) based on clinical information.": "El complemento Exomiser puede priorizar variantes de datos de secuenciación del exoma completo (Exomiser) o del genoma completo (Genomiser) en función de la información clínica.",
    "***For GPAP performance reasons, Exomiser can only be run with 1000 variants or less.": "***Por motivos de rendimiento de GPAP, Exomiser solo se puede ejecutar con 1000 variantes o menos.",
    "HPO term(s)": "Término(s) de HPO",
    "Inheritance model": "modelo de herencia",
    "Choose Prioritiser": "Elija priorizador",
    "{errorMsg}": "{mensaje de error}",
    "Exomiser results": "Resultados del exomisor",
    "{d.text}": "{d.texto}",
    "{pop_config[field]}": "{pop_config[campo]}",
    "Allele population frequencies": "Frecuencias de población de alelos",
    "{config_pred[field]}": "{config_pred[campo]}",
    "Pathogeniticy predictions": "Predicciones de patogenia",
    "{d.key}": "{d.clave}",
    "Annotation Result": "Resultado de la anotación",
    "Transcripts": "Transcripciones",
    "Gene ID: {gene_id}": "ID del gen: {gene_id}",
    "dbSNP: {rs}": "dbSNP: {rs}",
    "Toggle All": "Alternar todo",
    "{column.id.charAt(0).toUpperCase() + column.id.slice(1)}": "{columna.id.charAt(0).toUpperCase() + columna.id.slice(1)}",
    "{renderView}": "{renderVer}",
    "Complete view": "Vista completa",
    "Split by genes": "Dividido por genes",
    "Aggregation View": "Vista de agregación",
    "{Msg}": "{mensaje}",
    "Loading Participant Data ...": "Cargando datos del participante...",
    "{s}": "{s}",
    "Experiments ({ props.experiments.length})": "Experimentos ({ props.experiments.length})",
    "Select a participant to query for associated variants. The query includes the selected gene list": "Seleccione un participante para consultar variantes asociadas. La consulta incluye la lista de genes seleccionados.",
    "Clearing filters": "Limpiando filtros",
    "{title.toString().toUpperCase()}": "{título.toString().toUpperCase()}",
    "Predefined lists ({props.by_predefined_list})": "Listas predefinidas ({props.by_predefinido_list})",
    "Gene search and upload ({props.by_gene_list})": "Búsqueda y carga de genes ({props.by_gene_list})",
    "Diseases ({props.by_disease})": "Enfermedades ({props.by_disease})",
    "Symptoms ({props.by_symptoms})": "Síntomas ({props.by_symptoms})",
    "Pathways ({props.by_pathways})": "Caminos ({props.by_pathways})",
    "Upload comma separated list of HGNC identifiers": "Cargar una lista separada por comas de identificadores HGNC",
    "(The file is not stored in GPAP)": "(El archivo no se almacena en GPAP)",
    "{label} (n={dataList.length})": "{etiqueta} (n={listadatos.longitud})",
    "Genes not validated ({this.state.genes_not_validated.length}):": "Genes no validados ({this.state.genes_not_validated.length}):",
    "Genes validated ({this.state.genes_validated.length}):": "Genes validados ({this.state.genes_validated.length}):",
    "Validating gene symbols..": "Validando símbolos genéticos.",
    "In the next step, you can extract genes from HPOs and add them to your queries": "En el siguiente paso, puede extraer genes de HPO y agregarlos a sus consultas.",
    "HPOs collected from analysed index case ({extracted_hpo.length})": "HPO recopilados del caso índice analizado ({extracted_hpo.length})",
    "You have now {tot_genes} HPO terms selected": "Ahora tienes {tot_genes} términos de HPO seleccionados",
    "Select Resource:": "Seleccionar recurso:",
    "Select Method:": "Seleccionar método:",
    "{value} of genes in this section is applied": "Se aplica {valor} de genes en esta sección",
    "Search for OMIM terms": "Buscar términos OMIM",
    "Genes associated to\n                        {this.state.refCodeList.map((omim_id, index) => ({omim_id.refCode} \n                        ))}\n                        : {(this.state.geneList.length!== 0 ) ? this.state.geneList.toString() : \"None\"}": "Genes asociados a\n                        {this.state.refCodeList.map((omim_id, index) => ({omim_id.refCode}\n                        ))}\n                        : {(this.state.geneList.length!== 0 )? this.state.geneList.toString(): \"Ninguno\"}",
    "Loading genes...": "Cargando genes...",
    "Genes associated to {gene_links}:": "Genes asociados a {gene_links}:",
    "Search for Pathways": "Buscar caminos",
    "Gene Search": "Búsqueda de genes",
    "Here, you can upload a comma separated list.": "Aquí puede cargar una lista separada por comas.",
    "Uploaded {genes.length} genes": "Genes {genes.length} cargados",
    "Selected Positions": "Posiciones seleccionadas",
    "Info": "Información",
    "Upload BED file": "Subir archivo BED",
    "Upload Coordinate file": "Subir archivo de coordenadas",
    "Upload BED File": "Subir archivo BED",
    "Upload Coordinate File": "Cargar archivo de coordenadas",
    "Uploaded {list.length} range from {fileType} File": "Rango {list.length} cargado desde el archivo {fileType}",
    "Minimum run of homozygosity length": "Longitud mínima de homocigosidad",
    "Filter Panel": "Panel de filtro",
    "Current work": "Trabajo actual",
    "Active studies in this session": "Estudios activos en esta sesión.",
    "Genotype Settings": "Configuración de genotipo",
    "You have created {props.studySettings.analysis_ids.length} analyses.": "Ha creado análisis {props.studySettings.analysis_ids.length}.",
    "or start from new Samples:": "o empezar desde nuevas Muestras:",
    "Cohorts ({(props.cohorts) ? props.cohorts.length : 0})": "Cohortes ({(props.cohorts)? props.cohorts.length: 0})",
    "{\n                    props.samples.map(function(d){\n                        return{d.sample_id}})\n                }": "{\n                    props.samples.map(función(d){\n                        devolver{d.sample_id}})\n                }",
    "Variants distributions": "Distribuciones de variantes",
    "You haven't loaded any study yet": "Aún no has cargado ningún estudio",
    "Settings": "Ajustes",
    "Filter Set Manager": "Administrador de conjuntos de filtros",
    "Create and Save a new Filter Set": "Crear y guardar un nuevo conjunto de filtros",
    "Available Filter Sets {(filter_sets.length !== 0 ? \"(\" + filter_sets.length + \")\" : \"\")}": "Conjuntos de filtros disponibles {(filter_sets.length !== 0 ? \"(\" + filter_sets.length + \")\" : \"\")}",
    "Applied filters:": "Filtros aplicados:",
    "No filters found": "No se encontraron filtros",
    "Search:": "Buscar:",
    "This list contains {genes.length} genes": "Esta lista contiene genes {genes.length}",
    "List Information": "Información de la lista",
    "List Visibility": "Visibilidad de la lista",
    "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Cuando esté activa, la lista se podrá utilizar para análisis. Si esta lista tiene el mismo nombre que una existente, la anterior quedará inactiva para los usuarios.",
    "Predefined Gene Lists": "Listas de genes predefinidas",
    "You don't have the rights to do edits here ;)": "No tienes los derechos para hacer ediciones aquí;)",
    "Title:{getField(viewList.title)}": "Título:{getField(viewList.title)}",
    "Description:{getField(viewList.description)}": "Descripción:{getField(viewList.description)}",
    "Source:{getField(viewList.source)}": "Fuente:{getField(viewList.source)}",
    "Permission:{getField(viewList.permission)}": "Permiso:{getField(viewList.permission)}",
    "Creator:{getField(viewList.creator)}": "Creador:{getField(viewList.creator)}",
    "Version:{getField(viewList.version)}": "Versión:{getField(viewList.version)}",
    "Size:{getField(viewList.size)}": "Tamaño:{getField(viewList.size)}",
    "Creation Date:{getField(viewList.creationDate)}": "Fecha de creación:{getField(viewList.creationDate)}",
    "Status:{getActive(viewList.active)}": "Estado:{getActive(viewList.active)}",
    "Predefined Lists in GPAP  - ( {predefined_lists.length} )": "Listas predefinidas en GPAP - ({predefinido_lists.length})",
    "Upload a GPAP gene file in txt format": "Cargue un archivo genético GPAP en formato txt",
    "Be sure that the file is in the correct format and all required fields are present": "Asegúrese de que el archivo tenga el formato correcto y que todos los campos obligatorios estén presentes",
    "Don't know the correct format?": "¿No sabes el formato correcto?",
    "Download our template file and fill it in with your data": "Descarga nuestro archivo plantilla y rellénalo con tus datos",
    "Validating genes..": "Validando genes..",
    "Upload a .list file": "Cargar un archivo .list",
    "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "El archivo .list es el formato de archivo heredado que se utiliza para almacenar listas de genes predefinidos en el GPAP. Esta carga está destinada a usuarios de CNAG. Para obtener más información, comuníquese con Steve Laurie.",
    "The file must contain a JSON array of Predefined Gene List Objects.": "El archivo debe contener una matriz JSON de objetos de lista de genes predefinidos.",
    "{title}": "{título}",
    "{description}": "{descripción}",
    "Welcome to the new version of the Genomic Analysis module": "Bienvenidos a la nueva versión del módulo de Análisis Genómico",
    "Analyse integrated genome-phenome data from individual cases, families and cohorts": "Analizar datos integrados de genoma y fenómeno de casos individuales, familias y cohortes.",
    "Help us improve, leave your feedback": "Ayúdanos a mejorar, deja tus comentarios",
    "You can let us know about your experience with the Next-GPAP. You can fill inthis simple form, it will take not more than 5 minutes.\n                                               This will help us to improve this new application and facilitate your analyses in the future. Thanks!\n                                               You can always contact our team athelp@rd-connect.eu.": "Puede contarnos su experiencia con el Next-GPAP. Puedes rellenar este sencillo formulario, no te llevará más de 5 minutos.\n                                               Esto nos ayudará a mejorar esta nueva aplicación y facilitar sus análisis en el futuro. ¡Gracias!\n                                               Siempre puede ponerse en contacto con nuestro equipo en help@rd-connect.eu.",
    "If you want to use the previous version of the RD-Connect GPAP Genomic Analysis, access the module below": "Si desea utilizar la versión anterior del Análisis Genómico RD-Connect GPAP, acceda al módulo siguiente",
    "{item.experiment_id}  - PhenoStore ID:{item.participant_id}": "{item.experiment_id} - ID de PhenoStore:{item.participant_id}",
    "{(item.disease !== \"\") ? \"Diagnosis: \" + item.disease : \"No Diagnosis Reported\"}": "{(item.enfermedad! == \"\") ? \"Diagnóstico: \" + item.disease: \"No se informó ningún diagnóstico\"}",
    "{(item.inheritance !== \"\") ? \"Inheritance: \" + item.inheritance : \"NA\"}": "{(item.herencia! == \"\") ? \"Herencia: \" + elemento.herencia: \"NA\"}",
    "Causative Variants": "Variantes causales",
    "Chr. Coordinate: {s.chr_coordinate}": "Chr. Coordenada: {s.chr_coordinate}",
    "cDNA: {s.cDNA}": "ADNc: {s.cDNA}",
    "Protein: {s.protein}": "Proteína: {s.protein}",
    "Gene: {s.gene}": "Gen: {s.gen}",
    "Transcript: {s.transcript}": "Transcripción: {s.transcripción}",
    "GPAP Genomics Query :{item.link_to_gpap}": "Consulta de genómica GPAP:{item.link_to_gpap}",
    "Solutions to Playground Cases": "Soluciones para casos de parques infantiles",
    "The following are the solutions to the playground cases. You can find the causative mutations in the list.": "Las siguientes son las soluciones a los casos de juegos infantiles. Puede encontrar las mutaciones causantes en la lista.",
    "Patient Matchmaking": "Emparejamiento de pacientes",
    "MatchMaker Exchange facilitates the matching of cases with similar phenotypic and genotypic profiles": "MatchMaker Exchange facilita la comparación de casos con perfiles fenotípicos y genotípicos similares",
    "Collecting patients with MatchMaker consent": "Recolectar pacientes con el consentimiento de MatchMaker",
    "There are{options.length}patients with Matchmaker consent in\n                                    the database": "Hay{options.length}pacientes con el consentimiento de Matchmaker en\n                                    la base de datos",
    "Target endpoint": "Punto final de destino",
    "Request info": "Solicitar información",
    "Organisation Requesting Match": "Organización que solicita la coincidencia",
    "RD-Connect": "Conexión RD",
    "Date": "Fecha",
    "{cur_date}": "{fecha_actual}",
    "Global age of onset": "Edad global de aparición",
    "{onset_dict[participantData.onset]}": "{onset_dict[participanteData.onset]}",
    "Mode of inheritance": "Modo de herencia",
    "Unknown": "Desconocido",
    "{inheritance_dict[item]}": "{herencia_dict[elemento]}",
    "Candidate gene(s)": "Gen(es) candidato(s)",
    "Matches found: {rows.length}": "Coincidencias encontradas: {rows.length}",
    "GPAP Studies ( {(props.studies !== undefined) ? props.studies.data.length : 0} )": "Estudios GPAP ( {(props.studies !== indefinido) ? props.studies.data.length : 0} )",
    "Select type of analysis": "{t(\"Seleccione tipo de análisis\")}",
    "Available studies": "Estudios disponibles",
    "Create a new Study": "Crear un nuevo estudio",
    "Adding a new analysis to current study:{getStudy(studySettings, studyBucket).study.studyName}": "Agregar un nuevo análisis al estudio actual:{getStudy(studySettings, StudyBucket).study.studyName}",
    "New Study": "Nuevo estudio",
    "Hi! We are going to create the first analysis of a new GPAP Study": "¡Hola! Vamos a crear el primer análisis de un nuevo Estudio GPAP",
    "A study is a collection of analyses (experiments(s) and inheritance type).\n                                You can add the name and a short description for this study. Define the visibility level, and you are ready to go!": "Un estudio es una colección de análisis (experimentos y tipo de herencia).\n                                Puede agregar el nombre y una breve descripción de este estudio. ¡Defina el nivel de visibilidad y estará listo para comenzar!",
    "The study will NOT BEsaved automatically. You can save the study in the genomics dashboard once performed the first analysis.": "El estudio NO se guardará automáticamente. Puede guardar el estudio en el panel de genómica una vez realizado el primer análisis.",
    "Participants": "Participantes",
    "Currently included in the analysis": "Actualmente incluido en el análisis.",
    "Can't get info": "No puedo obtener información",
    "Pedigree of Index Case": "Árbol genealógico del caso índice",
    "{cohort.cohort.data.length} Experiments from selected cohort": "{cohort.cohort.data.length} Experimentos de la cohorte seleccionada",
    "Select Samples": "Seleccionar muestras",
    "Thank you!": "¡Gracias!",
    "You will get an email with further instructions": "Recibirás un correo electrónico con más instrucciones.",
    "Participants included in the study": "Participantes incluidos en el estudio.",
    "Pedigree": "Genealogía",
    "Additional Information": "información adicional",
    "Sample Information": "Información de muestra",
    "3 Members Selected": "3 miembros seleccionados",
    "Tagged variants: {variants.length}": "Variantes etiquetadas: {variantes.longitud}",
    "Current Genotype Settings:": "Configuración actual del genotipo:",
    "Genotype information can not be displayed.": "No se puede mostrar la información del genotipo.",
    "Local ID:{getValOrNa(participant.id)}": "ID local:{getValOrNa(participante.id)}",
    "Configuration details": "Detalles de configuración",
    "{inheritance_label}": "{etiqueta_herencia}",
    "{capitalize(configuration.label)}": "{capitalizar(configuración.etiqueta)}",
    "No genotype configured. The sample will not be included in the analysis": "Ningún genotipo configurado. La muestra no será incluida en el análisis.",
    "{(sample_case.id === \"ALL_SAMPLES\") ? \"All Experiments\" : sample_case.id}": "{(sample_case.id === \"TODAS_MUESTRAS\") ? \"Todos los experimentos\": sample_case.id}",
    "{props.label}": "{props.etiqueta}",
    "{mode.explanation}": "{modo.explicación}",
    "Select a simulated mode of inheritance configuration:": "Seleccione un modo simulado de configuración de herencia:",
    "or configure a custom mode of inheritance": "o configurar un modo personalizado de herencia",
    "Only experiments that have been processed and that are ready for analysis are visible.": "Sólo son visibles los experimentos que han sido procesados ​​y que están listos para el análisis.",
    "You have selected:{this.state.tags[field]}": "Has seleccionado:{this.state.tags[field]}",
    "No Experiments available.": "No hay experimentos disponibles.",
    "No Experiments selected yet.": "Aún no se han seleccionado experimentos.",
    "Selected Experiments": "Experimentos seleccionados",
    "{section_titles[0]}": "{sección_títulos[0]}",
    "{section_titles[1]}": "{sección_títulos[1]}",
    "Cohorts ready for analysis": "Cohortes listas para el análisis",
    "Create a cohort to analyse": "Crear una cohorte para analizar",
    "Cohort Selected": "Cohorte seleccionada",
    "Select a cohort": "Seleccione una cohorte",
    "Loading List Experiments": "Cargando experimentos de lista",
    "Error": "Error",
    "Search Across All": "Buscar en todo",
    "Study Created": "Estudio creado",
    "Cohort Analysis": "Análisis de cohorte",
    "Studies": "Estudios",
    "{config_labels[keyItem]}": "{config_labels[elementoclave]}",
    "{data[keyItem]}": "{datos[elementoclave]}",
    "GPAP Experiments Overview": "Descripción general de los experimentos GPAP",
    "Search Across All Study": "Buscar en todos los estudios",
    "{(study!== undefined) ? el.label : \"NA\"}": "{(¡estudiar!== indefinido)? el.label: \"NA\"}",
    "You created the study on {date_formatted}": "Creaste el estudio el {date_formatted}",
    "The study was created on {date_formatted}": "El estudio fue creado el {date_formatted}",
    "Study Overview": "Descripción general del estudio",
    "{(study!== undefined) ? study.studyName : \"NA\"}": "{(¡estudiar!== indefinido)? estudio.nombre del estudio: \"NA\"}",
    "Study Loaded, you will find it in your Study List": "Estudio cargado, lo encontrarás en tu Lista de estudios",
    "Analyses": "Análisis",
    "No analyses in this study": "No hay análisis en este estudio.",
    "The study was not found": "El estudio no fue encontrado.",
    "Study not found": "Estudio no encontrado",
    "The study that you are trying to load is\n                                not visible or does not exist. Please, ask your collaborator to save the study with\n                                either a public or group permission, or to verify the link. Don't hesitate to contact us at {window.config.help_email} if\n                                you have any question. You can also consult ouruser guide.": "El estudio que estás intentando cargar es\n                                no es visible o no existe. Por favor, solicita a tu colaborador que guarde el estudio con\n                                ya sea un permiso público o de grupo, o para verificar el enlace. No dude en contactarnos en {window.config.help_email} si\n                                tienes alguna pregunta. También puedes consultar nuestra guía de usuario.",
    "Study not saved correctly": "El estudio no se guardó correctamente",
    "The study that you are trying to load did not save correctly.\n                                Don't hesitate to contact us at {window.config.help_email} if\n                                you have any question. You can also consult ouruser guide.": "El estudio que está intentando cargar no se guardó correctamente.\n                                No dude en contactarnos en {window.config.help_email} si\n                                tienes alguna pregunta. También puedes consultar nuestra guía de usuario.",
    "Something went wrong!": "¡Algo salió mal!",
    "{error.message}": "{mensaje de error}"
}