

import React, {useEffect, useState} from 'react';
import {initialize, reduxForm} from 'redux-form';
import "../../../css/query-view.css";
import { connect } from 'react-redux'

import {SelectSingleArea, TextArea} from "../../../../../../../../../../gpap-lib/components/shared-components/renderer";

import {
    GPAP_Button,
    GPAP_Button_light,
    GPAP_Button_Orange
} from "../../../../../../../../../../gpap-lib/components/Button_Collection";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import RadioButtonROH from "./RadioGroupForm/RadioButtonROH";
import DoneIcon from '@material-ui/icons/Done';
import UploadFile from "./UploadFile";
import Chromosome_Selected from "./Chromosome_Selected";
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import Typography from "@material-ui/core/Typography";
import {AlertInfo} from "../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import {bindActionCreators} from "redux";
import * as Actions from "../actions";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';



export function getLabelChrom(i){

    let label = i;
    switch (i){
        case 23:
            label= "MT"
            break;
        case 24:
            label= "X"
            break;
        case 25:
            label= "Y"
            break;
        case "24":
            label= "X"
            break;
        case "23":
            label= "MT"
            break;
        case "25":
            label= "Y"
            break;
    }

    return label;

}


let Position_Section = props => {

    const { handleSubmit, pristine, reset, submitting } = props;

    const [initialized, setInitialized] = useState(false);


    useEffect(function(){

        if(!initialized && props.inheritance_key.includes("x")){

        /*    let x_query = {
                "chrom": [{value: 22, label: "X"}],
                "start": null,
                "end": null,
                "roh": null
            }
            props.dispatch(initialize(
                'position_roh',
                x_query
            ));*/

            setInitialized(true)
        }
        else{
            setInitialized(true);
        }
    })



    const options = new Array(25).fill().map((e,i) => {
        let label = getLabelChrom(i);
        return {value: i, label: label}
    }).filter(s => s.value !== 0 )


    const resetSection = () =>{

        let reset_obj = {
            "chrom": [],
            "start": null,
            "end": null,
            "roh": null
        }

        props.dispatch(initialize(
            'position_roh',
            reset_obj
        ));



    };

    const getCorrectAlert = () => {


        if(props.inheritance_key.includes("x")){

            return <div>
                <AlertInfo message= <MappedText text={"The X chromosome has been automatically selected to match the selected inheritance"}/> title={"Info"} severity={"info"}/>
            </div>


        }
        else{
            return <div>
                <div
                    style={{background: "aliceblue", width:"100%", padding: "1%"}}>
                    <Typography variant={"body1"} style={{fontWeight: "500"}}><MappedText text={"Info"}/></Typography>
                    <MappedText text={"Select a chromosome. Positions are considered ONLY if a chromosome is selected"}/>
                </div>
            </div>
        }
    }

    const SaveBtn = () => {

        return (Array.isArray(props.query.chrom) && props.query.chrom.length!==0)
            ?  <GPAP_Button size="sm" variant={"secondary"} disabled={submitting} startIcon={<DoneIcon/>}>
                <MappedText text={"Save"}/>
            </GPAP_Button>
            :  <GPAP_Button size="sm" variant={"secondary"} disabled={submitting}>
                 <MappedText text={"Save"}/>
            </GPAP_Button>



    }



    return (<div className={"form-_section"}>
            <div className={'bar'}>
                {getCorrectAlert()}
               {/* {SaveBtn()}*/}
                <GPAP_Button_light size="sm" variant={"secondary"} disabled={pristine || submitting} onClick={() => {
                    reset();
                    resetSection();
                }}>
                    <MappedText text={"Clear Filters"}/>
                </GPAP_Button_light>
            </div>
           <Box m={2} style={{float: "left", width: "100%"}}>
               <form onSubmit={handleSubmit}>
                <div className={"form_container"}>
                    <SelectSingleArea collection_name={'chrom'} header = <MappedText text={'Chromosome'}/> fields = {options}/>
                    <TextArea size="30%" name={'start'} collection_name={'start'} header = <MappedText text={'Start Position'}/> subheader=<MappedText text={"Chromosome required"}/>   defaultValue={"start"} />
                    <TextArea size="30%"  name={'end'} collection_name={'end'} header = <MappedText text={'End Position'}/> subheader=<MappedText text={"Chromosome required"}/> defaultValue={"end"} />
                </div>
            </form>
           </Box>
            <div style={{float: "left", width: "100%"}}><Divider/></div>
        <Box m={2} style={{float: "left", width: "100%"}}>
            <Box p={0} style={{float: "left", width: "100%"}}>
                <GPAP_Tooltip title={"Tab delimited file e.g:\n" +
                "1 13868 14652\n" +
                "3 39100 41808\n" +
                "\n" +
                "UCSC BED format definition"}>
                    <div>
                        <Typography>
                            <MappedText text={"Upload BED file"}/>
                        </Typography>
                    </div>
                </GPAP_Tooltip>
                <UploadFile
                    fileType={"BED"}
                />
            </Box>
            <Box p={0} style={{float: "left", width: "100%"}}>
                <GPAP_Tooltip title={"Tab delimited file e.g:\n" +
                "#Chr Position\n" +
                "8 169432\n" +
                "\n" +
                "UCSC BED format definition"}>
                    <div>
                        <Typography>
                        <MappedText text={"Upload Coordinate file"}/>
                        </Typography>
                    </div>
                </GPAP_Tooltip>
                <UploadFile
                    fileType={"coordinate"}
                />
            </Box>
        </Box>
            <div style={{float: "left", width: "100%"}}><Divider/></div>
            <RadioButtonROH/>
            <Box>
                <Chromosome_Selected/>
            </Box>

        </div>
    )
};


export function map_genes (state) {
    if(state.inheritance_key!==undefined)
    {
        return {
            inheritance_key: state.inheritance_key,
            query: state.query
        }
    }
    else{
        return {}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});

Position_Section = connect(
    map_genes,
    mapDispatchToProps
)(Position_Section);









export default reduxForm({
    form: 'position_roh', // a unique identifier for this form,
    destroyOnUnmount: true,
    enableReinitialize : true,
    keepDirtyOnReinitialize: true,
    initialValues: {
        "chrom": [],
        "start": null,
        "end": null,
        "roh": null
    }
})(Position_Section)




//Position_Section = connect(() => ({}))(Position_Section);