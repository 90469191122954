import React from "react";
import { Grid, Typography } from "@mui/material";
import Treatments_Table from "./Treatments_config";
import { useSelector } from 'react-redux';

function Treatments() {
  const token = useSelector(state => state.authorization.token);
  const baseUrl = window.config.apis_configuration.api_nextgpap_server_elastic;
  const url = `${baseUrl}/api/treatments`;

  const participant = useSelector(state => state.generaltable.allDataApis.participants[0]);

  return (
    <Grid container direction={"row"}>
      
      <Grid item lg={12}>
        <Treatments_Table
          url = {url}
          token={token}
          participant={participant}
        />
      </Grid>
    </Grid>
  );
}

export default Treatments;