
import React from "react";
import QueryItem from "./query-item/QueryItem";



export function QueryList(props){


    return props.analysis.queries.map(function (query, index) {
        return <QueryItem query={ query }
                          key={index}
                          readOnly = { props.readOnly }
                          analysisType = {props.analysis.analysis_type}
                          analysis_id = {props.analysis.localId}
                          queryDelete = { props.queryDelete }
                          queryViewer = { props.queryViewer }/>

})
}