



import {SET_INHERITANCE} from "./inheritance_actions";


const initialState = {
    inheritance_key: []
};

export const inheritance = (state =initialState.inheritance_key, action) => {

    if (action.type === SET_INHERITANCE) {
        return action.inheritance_key;
    } else {
        return state
    }
};
