

/* This is a the Sample Section */


import React, {useState} from 'react';
import "../../../../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/css/query-view.css";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SelectionContainer from "./selection-container/SelectionContainer";
import Checkbox from "@material-ui/core/Checkbox";
import {bindActionCreators} from "redux";
import * as Actions from "../../reducers/sample_actions";
import {connect} from "react-redux";
import WarningBox from "../../../WarningBox";
import MySamples from "./sample-list-selected/MySamples";





let Sample_Section = props => {


    const [sampleType, setSampleType] = useState("LocalExperimentID");
    const [familyMembers, setFetchFamily] = useState(true);
    let { samples, actions } = props;

    //actions.resetSamples();

    let options = [];
    let participants_opts = [];
    if(samples!== undefined){
        let sorted = samples.map(s => s.ExperimentID).sort();
        let participants = samples.map(s=> s.Participant_ID).sort();
        options = sorted.map(sample_ID => (
            {
                "value": sample_ID,
                "label": sample_ID,
                "pheno_id": samples.find(s => s.ExperimentID === sample_ID).Participant_ID,
                "sample_id": sample_ID
            }
            ));

        participants_opts =  participants.filter(p => p!== "").map(pheno_ID => (
            {
                "value": samples.find(s => s.Participant_ID === pheno_ID).ExperimentID,
                "label": pheno_ID,
                "pheno_id": pheno_ID,
                "sample_id": samples.find(s => s.Participant_ID === pheno_ID).ExperimentID
            }))
    }


    const showCurrentPatientList = () => {

        
        if(props.patients.length!==0){
            let verb = (props.patients.length === 1) ? " There is" : " There are";
            let text = verb + " already " + props.patients.length + " patients in the study";
            return <WarningBox
                text={text}
            />

        }


    };


    const getFormControlLabel = (field, label, disable) => {


        return  <FormControlLabel checked={sampleType === field}
                                  disabled={disable}
                                  onChange={e => setSampleType(e.target.value)} value= {field} control={<Radio color="primary" />} label={label} />

    }

    return (
        <div className={"sample_section"}>
            {showCurrentPatientList()}
            <Container>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Select experiments by a specific ID: </FormLabel>
                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                        <Box p={1} m={1} style={{border: "2px solid #f0f0f0", borderRadius: "5px"}}>
                            {getFormControlLabel("LocalExperimentID", "Local Experiment ID", false)}
                            {getFormControlLabel("case", "GPAP Experiment ID", false)}
                        </Box>
                        <Box p={1} m={1} style={{border: "2px solid #f0f0f0", borderRadius: "5px"}}>
                            {getFormControlLabel("id", "Local Participant ID", false)}
                            {getFormControlLabel("report_id", "PhenoStore ID", false)}
                            {getFormControlLabel("famid", "Family ID", false)}
                        </Box>

                    </RadioGroup>
                    <FormControlLabel
                        style={{float:"right"}}
                        control={
                            <Checkbox
                                disabled = {(sampleType === "famid")}
                                checked={familyMembers}
                                name="checkedB"
                                onChange = {(e) => setFetchFamily(!familyMembers)}
                                color="primary"
                            />
                        }
                        label="Automatically add family members"
                    />
                </FormControl>
                <Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                 <SelectionContainer sampleType={sampleType} experiments = {options} participants = {participants_opts} fetchFamily={familyMembers}/>
                            </Grid>
                        </Grid>
                </Box>
                <Box>
                    <MySamples/>
                </Box>
            </Container>
        </div>
    );


};


function mapPatients (state) {
    if(state.patients!== undefined)
    {
        return {patients: state.patients.patients}
    }
    else{
        return {patients: []}
    }

}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});




export default connect(mapPatients,mapDispatchToProps)(Sample_Section);


