
import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import auth from './Auth';
import * as Actions from "../../actions/index";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";



class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keycloak: null,
            authenticated: false
        }
    }



    componentDidMount() {

        let {actions} = this.props;
        let config =window.config;
        //const keycloak = Keycloak(config.config_keycloak);

           const keycloak = Keycloak(
                {
                    realm: config.config_keycloak.realm,
                    url: config.config_keycloak["url"],
                    clientId: config.config_keycloak.resource,
                }
            );


        if (this.props.location.state !== undefined){
            location.href=location.href +"?redirect="+ this.props.location.state.from.pathname;
            //return <Redirect to={this.props.location.state.from.pathname}/>
        }

        keycloak.init({onLoad: 'login-required', "checkLoginIframe" : false, promiseType: 'native'})
            .then(authenticated => {
                this.setState({ keycloak: keycloak, authenticated: authenticated });
                let appState = {
                    authenticated: authenticated,
                    keycloak: keycloak
                };
                auth.setToken(appState);
                let projects =  keycloak.tokenParsed.hasOwnProperty("group_projects") ? keycloak.tokenParsed["group_projects"].map(x => x.replace("/","")) : [];
                let groups = keycloak.tokenParsed["group"].map(x => x.replace("/",""));

                let authorization_variables = {
                    token: keycloak.token,
                    projects: projects ,
                    groups: groups,
                    preferredName: keycloak.tokenParsed.preferred_username,
                    authenticated: true
                };
                actions.addAuthorization(authorization_variables);
                this.props.history.push(window.location.href.split("=")[1])
            })
    }

    render() {
       if (this.state.keycloak) {
            if (this.state.authenticated) {
                return (<div>protected page</div>)
            } else return (<div>Unable to authenticate!</div>)
        }
        return (
            <div><CircularProgress /></div>
        );
    }
}


function mapStateToProps (state) {
    return {
        authorization: state.authorization
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



//export default Login;

export default connect(mapStateToProps,mapDispatchToProps)(Login);