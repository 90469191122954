




function createVariantRow(obj, hit, annotations, analysisType, samples, geneName){

    let obj_copy = {...obj};

    // search for Mane Select Canonical Transcript;
    //let canonical = hit.fields.effs.find(s => s.mane_select !== undefined || s.mane_plus_clinical!== undefined);
    
    // filter by geneName too
    let canonical = hit.fields.effs.find(s => (s.mane_select !== undefined || s.mane_plus_clinical !== undefined) && s.gene_name === geneName);


    let myCanonical;
    if(canonical!== undefined){
        // take the canonical;
        myCanonical = canonical;
    }
    else{
        // otherwise, any;

        if(hit.fields.effs.length>0)
        {
            myCanonical = hit.fields.effs.find(s => s.gene_name === geneName);
            //myCanonical = hit.fields.effs[0]
        }
        else{
            myCanonical = undefined;
        }

    }

    if(myCanonical !== undefined){
        //obj_copy.gene_name = myCanonical.gene_name;
        obj_copy.gene_name = geneName;
        obj_copy.effect_impact = myCanonical.effect_impact;
        // transcripts, decide what to pick;
        obj_copy.consequence = myCanonical.effect;

        obj_copy.transcript_biotype =myCanonical.transcript_biotype;

        // take the canonical;
        obj_copy.codon_change = myCanonical.codon_change;
        obj_copy.amino_acid_change = myCanonical.amino_acid_change;
    }
    else{
        const NA_val = "NA";
        obj_copy.gene_name = NA_val;
        obj_copy.effect_impact = NA_val;
        // transcripts, decide what to pick;
        obj_copy.consequence = NA_val;
        obj_copy.transcript_biotype = NA_val;
        // take the canonical;
        obj_copy.codon_change = NA_val;
        obj_copy.amino_acid_change = NA_val;
    }


    obj_copy.number = hit.fields.effs.length;
    // take only transcripts for the geneName;
    obj_copy.transcripts_all = hit.fields.effs.filter(s => s.gene_name === geneName);
    // put all transcripts under the same gene;


    // copy the samples;
    obj_copy.samples_germline = hit.fields.samples_germline ?? {};
    obj_copy.samples_somatic = hit.fields.samples_somatic ?? {};

    if(analysisType !== "cohort" && analysisType !== "search_across"){

        obj_copy.gt = "NA";
        let index_case = samples ? samples.filter(s => s.index === true) : [];

        if(index_case.length>0){
            // get index case genotype;
            let sampleMatch = (hit.fields.samples_germline || []).find(s=> s.sample === index_case[0].sample_id);
            if(sampleMatch){
                obj_copy.gt = sampleMatch.gt;
            }
        }
        else{
            // show all;
            let all_gt = (hit.fields.samples_germline || []).map(sample => sample.gt);
            // keep distinct
            obj_copy.gt = [...new Set(all_gt)].toString()

        }
    }
    else{
        let all_gt = (hit.fields.samples_germline || []).map(sample => sample.gt);
        // keep distinct
        obj_copy.gt = [...new Set(all_gt)].toString()

    }

    obj_copy.n_exp = (hit.fields.samples_germline !== undefined) ? hit.fields.samples_germline.length : {};

    // get annotations if collected...

    let annotation_id = "chr" + obj_copy.chrom + ":" + obj_copy.pos + ":" + obj_copy.ref + ":" + obj_copy.alt;
    let find = annotations.find(s => s.original_variant === annotation_id);

    //acmg
    if(annotations.length!==0){
        if(find!== undefined){
            obj_copy.acmg_verdict = find.acmg_annotation.verdict.ACMG_rules.verdict;
            obj_copy.acmg_classifications = find.acmg_annotation.verdict.classifications;
        }
        else{
            obj_copy.acmg_verdict = "";
            obj_copy.acmg_classifications = [];
        }
    }

    return {
        source: obj_copy,
        effects: hit.fields.effs.filter(s => s.gene_name === geneName)
        //effects: hit.fields.effs
    }

}


export default createVariantRow;