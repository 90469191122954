


import React, {Component} from 'react'
import "./extra.css";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../actions";
import {connect} from "react-redux";
import FolderIcon from '@material-ui/icons/Folder';
import "./treeview.css";
import {StateMapper} from "../../query-generator/functions/StateMapper";
import Typography from "@material-ui/core/Typography";
import {save_analysis, save_study, saveQuery} from "../../../../../../../services/api/manage_study";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import 'regenerator-runtime/runtime'
import {formatQuery, formatStudy} from "./helpers";
import List_Queries from "./components/list-material-ui/List_Queries";
import {getStudy} from "../../results/components/variant-table/getAnnotations";
import Box from "@material-ui/core/Box";



// This is the Component that Shows the current Study and other loaded study with analysis, queries and so on...

class StudyStore extends Component {

    constructor(props){
        super(props);
        this.state = {
            sample_cases: [],
            open: false
        };

        this.getQuerySummaries = this.getQuerySummaries.bind(this);
        this.createTree  = this.createTree.bind(this);
        this.queryView = this.queryView.bind(this);
        this.getFolderIconLabel = this.getFolderIconLabel.bind(this);
        this.saveStudy = this.saveStudy.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }



    handleOpen(){
        this.setState({open: !this.state.open})
    }


    getFolderIconLabel(text){
        return <React.Fragment>
            <div><FolderIcon/> {text}</div>
        </React.Fragment>
    }


    //TODO: create a collapsible tree;
    createTree(){


        let self = this;
        let { studyBucket } = self.props;
        const studies = studyBucket.studies;
        return <div style={{float:"left", width:"100%"}}>
            <List_Queries
                queryDelete = { this.props.queryDelete }
                queryViewer= { this.props.queryViewer}
                studies={studies}/>
        </div>

    }


    queryView(query){
        this.props.queryViewer(query);
    }

    saveStudy (){

        let api_next_gpap =  window.config.apis_configuration.api_nextgpap_server;
        let {token, studyBucket, studySettings} = this.props;
        let self = this;
        let study_to_save = getStudy(studySettings, studyBucket);


        const STUDY = study_to_save.study;
        const formatted_study = formatStudy(STUDY);

        save_study(api_next_gpap, token, formatted_study)
            .then(resp => resp.json())
            .then(function(data){
                // Save Study
                let { id } = data;

                STUDY.analyses.forEach(function(analysis_post){
                    analysis_post["study_id"] = id;
                    const resp = save_analysis(api_next_gpap, token, analysis_post)
                        .then(resp => resp.json())
                        .then(function(data){
                            let analysis_id = data.id;
                            // check if things went fine...then save queries.
                            let queries = analysis_post.queries;
                            queries.forEach(function(x) {
                                    const queryPost = formatQuery(x, analysis_id);
                                    saveQuery(api_next_gpap, token, queryPost)
                                        .then(resp => resp.json())
                                        .then(function (data) {
                                            console.log(data)
                                        })
                                }
                            );

                            self.handleOpen();
                        });

                });

            })

    }


    getQuerySummaries() {

        let { queryList } = this.props;

        return queryList.map(function (query, index) {

            let queryName = (query.name) ? query.name : 'Query-' + index ;
            return {
                name: queryName,
                samples: query.query_to_run.samples_germline.length,
                variant_n: query.variants,
                genes_n: query.genes.length,
                effect_impact: query.effect_impact,
                query_to_run: query.query_to_run,
                collapsed: false
            }


        })
    }





    render() {

        let self =this;

        return (
            <div className={"extra_view"}>
                <Box p={3}>
                    <Typography variant="h6" align={"left"}>
                        Current work
                    </Typography>
                    <Typography variant="subtitle2" align={"left"}>
                        Active studies in this session
                    </Typography>
                </Box>
                {self.createTree()}
                <Dialog onClose={this.handleOpen} aria-labelledby="simple-dialog-title" open={this.state.open}>
                    <DialogTitle id="simple-dialog-title">Study Saved Successfully</DialogTitle>
                </Dialog>
            </div>
        )
    }
}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.queryContainer !== undefined)
        {
            return {
                queryList: state.queryContainer.queryList,
                token: state.authorization.token,
                studyBucket: state.studyBucket,
                studySettings: state.studySettings,
                result: StateMapper(state, state.inheritance_key, state.studySettings.analysis_ids)}
        }

    }
}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(StudyStore);

