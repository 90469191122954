

import {DateTime} from "luxon";


function date_generator(){

    let date = DateTime.local();
    return date.toLocaleString(DateTime.DATETIME_FULL);
}

export {date_generator}