import {AlignmentType, HeadingLevel, Paragraph, TextRun} from "docx";
import {drawBasicTable} from "../write_utils/drawTables_examples.js";
import { drawClinicalEvidenceTable } from "../write_utils/drawClinicalEvidenceTable.js";
import {bodyText, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE} from "../../config/template_config.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";

const headers = [
    "Gene",
    "Drug",
    "Level of evidence",
    "Clinical significance",
];

const dataKeys = [
    "gene",
    "therapy",
    "evidence_level",
    "interpretation",
];


function WriteClinicalEvidenceSection(sectionData){


    // check if we have the data that we need;


    let rows = [];
    let conclusion = "";

    if(sectionData !== null && sectionData.rows){

        rows = sectionData.rows;
        conclusion= sectionData.conclusion;

    }

    // render the section;

    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("CLINICAL EVIDENCE AND MANAGEMENT"),
                    size: 24,
                    color: '#000000',
                    bold: true,
                    heading: HeadingLevel.HEADING_2,
                    underline: true
                }),
            ]
        }),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("Only information relevant to the specific type of cancer under investigation is reported."),
                    size: 24,
                    color: '#000000',
                    heading: HeadingLevel.HEADING_3,
                }),
            ]
        }),
        new Paragraph({
            text: "",
        }),
        drawClinicalEvidenceTable(rows, headers, dataKeys, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [bodyText(conclusion)],
            alignment: AlignmentType.LEFT,
            spacing: {after: 10, before: 10}
        })
    ];





}


export default WriteClinicalEvidenceSection;