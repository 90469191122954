export const italiano = {
    "Dashboard": "Dashboard",
    "Recent Work": "Lavoro recente",
    "Select type of analysis": "Seleziona il tipo di analisi",
    "Cohort Analysis": "Analisi di coorte",
    "Case Analysis": "Analisi del caso",
    "Analyse a singleton or a family": "Analizza un singolo individuo o una famiglia",
    "Clinical Report": "Referto clinico",
    "Search Across All Experiments": "Ricerca tra tutti gli esperimenti",
    "Patient Matchmaking": "Abbinamento dei pazienti",
    "Sample site of origin" : "Sito di origine del sample",
    "General Info": "Informazioni generali",
    "Medium": "Media",
    "Request ID": "ID della richiesta",
    "Comments": "Commenti",
    "Priority": "Priorità",
    "Deadline": "Scadenza",
    "Analysis Type": "Tipo di analisi",
    "Assignee(s)": "Assegnatario(i)",
    "Creator": "Creatore",
    "Creation date": "Data di creazione",
    "Hospital name": "Nome dell'ospedale",
    "Clinical referrer": "Referente clinico",
    "Clinical referrer contact info": "Informazioni di contatto del referente clinico",
    "Patient ID": "ID del paziente",
    "Sex": "Sesso",
    "Clinical referral Investigated": "Richiesta clinica indagata",
    "Observed Symptoms": "Sintomi osservati",
    "Diagnostic date": "Data di diagnosi",
    "Clinical diagnosis": "Diagnosi clinica",
    "Case number pathology": "Patologia del numero de caso",
    "Treatment history and response": "Storia e risposte del trattamento",
    "Cancer type": "Tipo di cancro",
    "Sample ID": "ID del sample",
    "Sample site of origin status": "Stato del sito di origine del sample",
    "Sample fixation": "Fissazione del sample",
    "Purity": "Purezza",
    "Experiment ID": "ID dell'esperimento",
    "Library strategy": "Strategia della libreria",
    "Kit": "Kit",
    "Administrative Info": "Informazioni amministrative",
    "Patient Info": "Informazioni sul paziente",
    "Clinical Info": "Informazioni cliniche",
    "Tumor Sample & Experiment Info": "Informazioni sul sample di tumore e sull'esperimento",
    "Sample Info": "Informazioni sul sample",
    "Experiment Info": "Informazioni sull'esperimento",
    "Control Sample & Experiment Info": "Informazioni sul sample di controllo e sull'esperimento",
    "Germline Sample & Experiment Info": "Informazioni sul sample germinale e sull'esperimento",
    "Relevant Observations": "Osservazioni rilevanti",
    "to do":"per fare",
    "in progress": "in corso",
    "Cancer with Metastasis":  "Cancro con metastasi",
    "Low Amount of DNA":   "Quantità bassa di DNA",
    "High number of duplicates": "Numero elevato di duplicati",
    "Request Overview":   "Panoramica della richiesta",
    "Germline Genetic Findings":   "Ricerche genetiche germinali",
    "Reports": "Referti",
    "Tumor Genetic Overview":  "Panoramica genetica del tumore",
    "Somatic Genetic Findings & Treatment Options": "Ricerche genetiche somatiche e opzioni di trattamento",
    "Interpretations Requests": "Richieste di interpretazione",
    "Request": "Richiesta",
    "Interpretation Requests": "Richieste di interpretazione",
    "Contact": "Contatto",
    "Docs": "Docs",
    //tumor genetic overview
    "Tumor characteristics": "Caratteristiche del tumore",
    "Tumor purity": "Purezza del tumore",
    "Average ploidy": "Ploidia media",
    "Microsatellite status": "Stato dei microsatelliti",
    "Tumor mutational load": "Carico mutazionale del tumore",
    "Tumor mutational burden": "Onere mutazionale del tumore",
    "Homologous recombination deficiency (HRD) status": "Stato della deficienza di ricombinazione omologa (HRD)",
    "Bin-level log2 coverages and segmentation calls": "Coperture log2 a livello di bin e chiamate di segmentazione",
    "Copy number per chromosome": "Numero di copie per cromosoma",
    "Tumor Sample ID": "ID campione di tumore",
    "Mutational signatures": "Firme mutazionali",
    //reports
    "Clinical Reports": "Relazioni cliniche",
    "Create report": "Crea una relazione",
    "Create a new report for this DIR": "Crea una nuova relazione per questo DIR",
    "Patient reports": "Relazioni del paziente",
    "Report ID": "ID relazione",
    "Assignee(s)": "Assegnatario/i",
    "Associated Clinical Report ID": "ID relazione clinica associata",
    //genetic findings
    
    "Variant information": "Informazioni sulla variante",
    "Clinical association": "Associazione clinica",
    "Population": "Popolazione",
    "Predictors": "Predittori",
    "Clinical association": "Associazione clinica",
    "Population frequency": "Frequenza in popolazione",
    "Somatic Related": "Correlato somatico",
    "Treatments": "Trattamenti",
    "Gene name": "Nome del gene",
    "Variant": "Variante",
    "Level of evidence": "Livello di evidenza",
    "Match": "Corrispondenza",
    "Cancer type": "Tipo di cancro",
    "Therapy": "Terapia",
    "Therapy status": "Stato della terapia",
    "Drug status": "Stato del farmaco",
    "Review full list of variants": "Rivedere l'elenco completo delle varianti",
    "See tag in the": "Vedi etichetta in",
    "Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant": "Clicca qui se vuoi vedere un riassunto dei dati memorizzati in PhenoStore per il partecipante etichettato",
    "Go to Participant": "Vai al Partecipante",
    "summary": "sommario",
    "You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "Hai anche eliminato la variante da PhenoStore. Se l'hai fatto per errore, per favore etichetta di nuovo.",
    "Is this the Causative Variant?": "È questa la Variante Causativa?",
    "Interpretation": "Interpretazione",
    "Clinical Significance": "Significato Clinico",
    "Zygosity": "Zigosità",
    "Origin": "Origine",
    "Inheritance": "Ereditarietà",
    "Trancript": "Trascritto",
    "Type of variant": "Tipo di variante",
    "Genes": "Geni",
    "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Quando etichetti una variante, l'etichetta sarà visibile a tutti gli utenti della piattaforma indipendentemente dal periodo di embargo",
    "Causative Variant (confirmed as the cause of the primary disorder)": "Variante Causativa (confermata come causa del disturbo primario)",
    "Submit": "Invia",
    "Cancel": "Annulla",
    "Close": "Chiudi",
    "Required": "Richiesto",
    "Previous Tags": "Etichette Precedenti",
    "Eperiment ID": "ID dell'Esperimento",
    "Your Tag": "La Tua Etichetta",
    "Date": "Data",
    "IGV Browser": "Browser IGV",
    "Genotypes": "Genotipi",
    "Predictors": "Predittori",
    "Label": "Etichetta",
    "The variant is tagged and can't be labelled": "La variante è etichettata e non può essere etichettata",
    "Once selected, click again to unapply": "Una volta selezionato, clicca di nuovo per deselezionare",
    "Label variant in this study only. Label will be private and different than the tag.": "Etichetta la variante solo in questo studio. L'etichetta sarà privata e diversa dall'etichetta.",
    "Tag": "Tag",
    "Tag variants in the GPAP database": "Etichetta varianti nel database GPAP",
    "Columns": "Colonne",
    "Tag SNV Variant": "Etichetta Variante SNV",
    "Update/Delete Tag": "Aggiorna/Elimina Etichetta",
    "Tag for SNV Somatic": "Etichetta per SNV Somatica",
    "CNV variant already Tagged": "Variante CNV già Etichettata",
    "Tag CNV Variant": "Etichetta Variante CNV",
    "You have tagged this variant for this experiment. You can edit or delete the tag below.": "Hai etichettato questa variante per questo esperimento. Puoi modificare o eliminare l'etichetta qui sotto.",
    "This variant has been tagged for another/other experiment(s). You can still create a new tag for the same variant. We prefilled the options below with the last tag that was reported.": "Questa variante è stata etichettata per un altro/esperimenti. Puoi ancora creare una nuova etichetta per la stessa variante. Abbiamo precompilato le opzioni qui sotto con l'ultima etichetta che è stata segnalata.",
    "Variant not tagged yet": "Variante non ancora etichettata",
    "Gene Info": "Informazioni sul Gene",
    "Clinical Association": "Associazione Clinica",
    "Population Frequency": "Frequenza nella Popolazione",
    "Additional Information": "Informazioni Aggiuntive",
    "Variant Information": "Informazioni sulla Variante",
    "Delete your tag": "Elimina la tua etichetta",
    "Complete view": "Vista Completa",
    "Split by genes": "Diviso per geni",
    "SNV & InDELs - Germline": "SNV & InDELs - Germline",
    "SNV & InDELs - Somatic": "SNV & InDELs - Somatica",
    "Variants associated to experiments": "Varianti associate agli esperimenti",
    "Variants": "Varianti",
    "Edit Filters": "Modifica Filtri",
    "Apply your own filters": "Applica i tuoi filtri",
    "Variants distributions - (based on max 3000 entries)": "Distribuzione delle varianti - (basato su un massimo di 3000 voci)",
    "List of Integrated Plugins": "Lista di Plugin Integrati",
    "Export Variants (max.1000)": "Esporta Varianti (max.1000)",
    "View Applied Filters": "Visualizza Filtri Applicati",
    "Save": "Salva",
    "New Analysis": "Nuova Analisi",
    "Back": "Indietro",
    "Filter Panel": "Pannello dei Filtri",
    "Reset Filters": "Reimposta Filtri",
    "Run Query": "Esegui Query",
    "Save Filter-Set": "Salva Set di Filtri",
    "Clearing filters": "Pulizia dei Filtri",
    "Use a predefined filter set": "Usa un set di filtri predefinito",
    "GPAP Predefined Filters are curated by the GPAP team": "I Filtri Predefiniti GPAP sono curati dal team GPAP",
    "Select a predefined filter set": "Seleziona un set di filtri predefinito",
    "Reset the applied filter-set": "Reimposta il set di filtri applicato",
    "Filter Set deleted": "Set di Filtri Eliminato",
    "Reset": "Reimposta",
    "Delete": "Elimina",
    "Select a simulated mode of inheritance configuration": "Seleziona una configurazione simulata di modalità di ereditarietà",
    "Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis\n": "Si prega di tenere presente che le ereditarietà predefinite tengono conto dello stato di salute segnalato dei membri della famiglia, il che in alcuni casi può forzare configurazioni non realistiche. In tali casi, si prega di utilizzare la configurazione personalizzata per formulare la vostra ipotesi\n",
    "or configure a custom mode of inheritance": "o configura una modalità di ereditarietà personalizzata",
    "Create/Adjust Filters": "Crea/Regola Filtri",
    "Filter by variants": "Filtra per varianti",
    "Gene Filters": "Filtri Genici",
    "Filter by genes": "Filtra per geni",
    "Variant Type": "Tipo di Variante",
    "SNV Effect Predictions": "Predizioni Effetto SNV",
    "Position Specific and Runs Of Homozygosity": "Specifico di Posizione e Corsa di Omozigosi",
    "In this panel you have to select functional annotations criteria": "In questo pannello devi selezionare i criteri di annotazioni funzionali",
    "The population filters return true if the value is lower than filter value or the field value is 0 or n.a; multiple populations filters result are treated with AND logical operator": "I filtri della popolazione restituiscono vero se il valore è inferiore al valore del filtro o se il valore del campo è 0 o n.a; i risultati di filtri di popolazioni multiple sono trattati con l'operatore logico AND",
    "The single effect predictor outcome will be handled with OR logical operator": "Il risultato del singolo predittore di effetto sarà gestito con l'operatore logico OR",
    "Select specific chromosomes, upload coordinates and BED files": "Seleziona cromosomi specifici, carica coordinate e file BED",
    'ClinVar Classification': "Classificazione ClinVar",
    'Protein Impact': "Impatto Proteico",
    "Clear Filters": "Cancellare Filtri",
    'Transcript Biotype': "Biotype del Trascritto",
    'Tagged Variants': "Varianti Etichettate",
    "Pathogenic": "Patogenico",
    "Likely pathogenic": "Probabilmente Patogenico",
    "Likely benign": "Probabilmente Benigno",
    "Benign": "Benigno",
    "Uncertain significance": "Significato Incerto",
    "Drug response": "Risposta al Farmaco",
    "Association": "Associazione",
    "Risk factor": "Fattore di Rischio",
    "Protective": "Protettivo",
    "Other": "Altro",
    "Heterozygous": "Eteroziogote",
    "Homozygous": "Omoziogote",
    "Hemizygous": "Emizigote",
    "Autosomal dominant": "Dominante Autosomico",
    "Autosomal recessive": "Recessivo Autosomico",
    "X-linked": "Collegato all'X",
    "X-linked recessive": "Recessivo Collegato all'X",
    "X-linked dominant": "Dominante Collegato all'X",
    "Y-linked inheritance": "Ereditarietà Legata al Cromosoma Y",
    "Digenic inheritance": "Ereditarietà Digenica",
    "Mitochondrial inheritance": "Ereditarietà Mitocondriale",
    "Oligogenic inheritance": "Ereditarietà Oligogenica",
    "Autosomal dominant with paternal imprinting": "Dominante Autosomico con Imprinting Paterno",
    "Autosomal dominant with maternal imprinting": "Dominante Autosomico con Imprinting Materno",
    "Unknown": "Sconosciuto",
    "De novo": "De Novo",
    "Primary disorder": "Disordine Primario",
    "Secondary findings": "Risultati Secondari",
    "Polygenic risk score": "Punteggio di Rischio Poligenico",
    "Duplication": "Duplicazione",
    "Deletion": "Delezione",
    "Inversion": "Inversione",
    "Short tandem repeat": "Ripetizione Tandem Corta",
    "Translocation": "Translocazione",
    "germline": "Linea Germinale",
    "somatic": "Somato",
    "de novo": "De Novo",
    "maternal": "Materno",
    "paternal": "Paterno",
    "inherited": "Ereditato",
    "unknown": "Sconosciuto",
    "High": "Alto",
    "Moderate": "Moderato",
    "Low": "Basso",
    "Modifier": "Modificatore",
    "Selected samples": "Campioni Selezionati",
    "Any Sample": "Qualsiasi Campione",
    "Variant of uncertain significance": "Variante di Significato Incerto",
    "Conflicting interpretations": "Interpretazioni Conflittuali",
    "Drug response": "Risposta al Farmaco",
    "Any": "Qualsiasi",
    "Other": "Altro",
    "Coding": "Codifica",
    "SnpEff reports putative variant impact in order to make it easier quickly to categorize and prioritize variants. As defined by SnpEFF - see ": "I report di SnpEff riportano l'impatto presunto della variante per facilitare la categorizzazione e la priorità delle varianti. Come definito da SnpEFF - vedi ",
    "Effect prediction details": "Dettagli della Predizione dell'Effetto",
    "section for a detailed explanation": "sezione per una spiegazione dettagliata",
    "When applied,it will return variants that have been tagged only in the GPAP database": "Quando applicato, restituirà varianti che sono state etichettate solo nel database GPAP",
    "ClinVar aggregates information about genomic variation and its relationship to human health": "ClinVar aggrega informazioni sulla variazione genomica e la sua relazione con la salute umana",
    "Browse Genomics England virtual gene panels related to human disorders ": "Sfoglia i pannelli di geni virtuali di Genomics England relativi ai disturbi umani ",
    "Genomics England PanelApp": "PanelApp di Genomics England",
    "A number between 0-1": "Un numero tra 0-1",
    "INTERNAL FREQUENCY": "FREQUENZA INTERNA",
    "Annotated as disease causing": "Annotato come causa di malattia",
    "Disease causing": "Causa di Malattia",
    "Polymorphism": "Polimorfismo",
    "Neutral": "Neutro",
    "Damaging": "Dannoso",
    "Tolerated": "Tollerato",
    "Probably damaging": "Probabilmente Dannoso",
    "Possibly damaging": "Possibilmente Dannoso",
    "Benign": "Benigno",
    "Mutation Taster": "Test della Mutazione",
    "Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)": "Punteggio CADD su scala Phred, che indica il livello di nocività, ad es. 20 (previsto nel 1% più nocivo)",
    "Upload BED file": "Carica file BED",
    "Upload Coordinate file": "Carica file delle Coordinate",
    'Start Position': "Posizione di Inizio",
    'End Position': "Posizione di Fine",
    "Select a chromosome. Positions are considered ONLY if a chromosome is selected": "Seleziona un cromosoma. Le posizioni sono considerate SOLO se viene selezionato un cromosoma",
    "Info": "Informazioni",
    "The X chromosome has been automatically selected to match the selected inheritance": "Il cromosoma X è stato selezionato automaticamente per corrispondere all'eredità selezionata",
    "Selected Positions": "Posizioni Selezionate",
    "Chromosome required": "Cromosoma richiesto",
    "Chromosome": "Cromosoma",
    "When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample": "Quando applicato, restituirà solo le varianti che cadono all'interno di una corsa di omozigosi di almeno la lunghezza selezionata nel primo campione colpito",
    "Minimum run of homozygosity length": "Lunghezza minima della corsa di omozigosi",
    "Not Specified": "Non Specificato",
    "Upload": "Carica",
    "Predefined lists": "Liste Predefinite",
    "Select a Predefined Gene List prepared from ERNs or the GPAP team": "Seleziona una Lista di Geni Predefinita preparata dagli ERNs o dal team GPAP",
    "Gene search and upload": "Ricerca e Caricamento dei Geni",
    "Enter multiple gene symbols using HGNC nomenclature or Upload your own": "Inserisci più simboli genici utilizzando la nomenclatura HGNC o Carica i tuoi",
    "WARNING: This platform uses annotations from Ensembl": "ATTENZIONE: Questa piattaforma utilizza annotazioni di Ensembl",
    "Diseases": "Malattie",
    "Select one or more OMIM terms or a PanelApp to query the associated genes": "Seleziona uno o più termini OMIM o un PanelApp per interrogare i geni associati",
    "Symptoms": "Sintomi",
    "Collect the HPOs from the index case or search more HPOs to query the associated genes": "Raccogli gli HPO dal caso indice o cerca altri HPO per interrogare i geni associati",
    "Pathways": "Percorsi",
    "Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database": "I percorsi sono recuperati da REACTOME e le associazioni con i geni dalla banca dati Comparative Toxicogenomics",
    "Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v75 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": "Qualsiasi Simbolo Genico utilizzato per il filtraggio che non corrisponde al simbolo utilizzato in Ensembl v75 potrebbe essere perso o assegnato erroneamente. Ciò si applica anche ai servizi web esterni. Stiamo attualmente lavorando per mitigare questo problema.",
    "Genes not validated": "Geni non validati",
    "Validating gene symbols..": "Validazione dei simboli genici..",
    "Gene Validation": "Validazione dei Geni",
    "Current selected genes by applying ": "Geni selezionati attuali applicando ",
    " of gene lists": " delle liste di geni",
    "No genes selected": "Nessun gene selezionato",
    "All gene symbols were validated using Ensembl": "Tutti i simboli genici sono stati validati usando Ensembl",
    " gene symbols could not be validated using ": " i simboli dei geni non hanno potuto essere validati usando ",
    "warning": "avviso",
    "success": "successo",
    "Clear gene list": "Cancella lista dei geni",
    "Union": "Unione",
    "Intersection": "Intersezione",
    "Operator": "Operatore",
    "Filters have changed. Please, reset the filters and select the filter-set again": "I filtri sono cambiati. Si prega di reimpostare i filtri e selezionare nuovamente il set di filtri",
    "Filters applied and added to the current selection. If you want to change the filters or only apply this filter set, click on \"RESET\" first.": "Filtri applicati e aggiunti alla selezione corrente. Se vuoi cambiare i filtri o applicare solo questo set di filtri, clicca prima su \"RESET\".",
    "Click here if you want to delete the Filter-Set from the database. This is an irreversible action. ": "Clicca qui se vuoi eliminare il Set di Filtri dal database. Questa è un'azione irreversibile.",
    "Apply": "Applica",
    "Validating gene symbols..": "Validazione dei simboli genici in corso..",
    "Genetic Findings": "Risultati Genetici",
    "CADD Prediction": "Predizione CADD",
    "Select a predefined gene list": "Seleziona una lista di geni predefinita",
    "WARNING: This platform uses annotations from Ensembl ": "ATTENZIONE: questa piattaforma utilizza annotazioni di Ensembl",
    ". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v75 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": "Qualsiasi Simbolo Genico utilizzato per il filtraggio che non corrisponde al simbolo utilizzato in Ensembl v75 pot per il filtraggio che non corris correttamente. Ciò si applica anche ai servizi web esterni . Stiamo attualmente lavorando per mitigare questo problema.",
    "Gene Search": "Ricerca dei Geni",
    "search for a gene name": "cerca un nome di gene",
    "Upload comma separated list of HGNC identifiers": "Carica lista separata da virgole di identificatori HGNC",
    "(The file is not stored in GPAP)": "(Il file non è memorizzato in GPAP)",
    "Upload": "Carica",
    "Delete OMIM associated genes": "Elimina i geni associati all'OMIM",
    "Enter multiple OMIM ids or names of disorders": "Inserisci più ID OMIM o nomi di disturbi",
    "Search for OMIM terms": "Cerca termini OMIM",
    "Request Error": "Errore di Richiesta",
    "Error": "Errore",
    "The type of panel has changed. Click + Add Panel App Genes button": "Il tipo di pannello è cambiato. Clicca sul pulsante + Aggiungi Geni dell'App Panel",
    "Delete PanelApp genes from query": "Elimina geni PanelApp dalla query",
    "PanelApp genes added to the query": "Geni PanelApp aggiunti alla query",
    "Add Panel App Genes": "Aggiungi Geni dell'App Panel",
    "Genomics England PanelApp": "PanelApp Genomics England",
    "Whole panel": "Pannello completo",
    "Green List": "Lista Verde",
    "Green and Amber lists": "Liste Verde e Ambra",
    "Type of Panel": "Tipo di Pannello",
    "Loading genes...": "Caricamento geni...",
    "Panel ": "Pannello ",
    "includes a total of ": "include un totale di ",
    " entities. There are ": " entità. Ci sono ",
    " distinct genes in the ": " geni distinti nel ",
    " green list": " lista verde",
    "Read more of this panel in PanelApp": "Leggi di più su questo pannello in PanelApp",
    "search for OMIM terms": "cerca termini OMIM",
    "Select Method:": "Seleziona Metodo:",
    "of genes in this section is applied ": "di geni in questa sezione è applicato ",
    "HPO Ontology": "Ontologia HPO",
    "Select Resource:": "Seleziona Risorsa:",
    "You have now": "Ora hai",
    "HPO terms selected": "termini HPO selezionati",
    "search for HPO terms": "cerca termini HPO",
    "Get HPOs from Participants": "Ottieni HPO dai Partecipanti",
    "Clear current HPO selection": "Cancella selezione HPO corrente",
    "HPOs collected from analysed index case": "HPO raccolti dal caso indice analizzato",
    "No genes shared across all HPOs": "Nessun gene condiviso tra tutti gli HPO",
    "No Genes": "Nessun Gene",
    "Current Selected Genes ": "Geni Selezionati Attuali ",
    "Add Genes to Query": "Aggiungi Geni alla Query",
    "Reset HPO gene list": "Reimposta lista dei geni HPO",
    " between the HPO genes": " tra i geni HPO",
    "You didn't include the genes in your query yet.": "Non hai ancora incluso i geni nella tua query.",
    "You included the genes in your query. You made an ": "Hai incluso i geni nella",
    "Apply Union of Genes": "Applica Unione dei Geni",
    "Make union of extracted genes (default)": "Crea unione dei geni estratti (predefinito)",
    "Apply Intersection of Genes": "Applica Intersezione dei Geni",
    "Intersect the genes extracted from the HPOs ": "Interseca i geni estratti dagli HPO",
    "Back": "Indietro",
    "Next": "Prossima",
    "In the next step, you can extract genes from HPOs and add them to your queries": "Nel prossimo passo, puoi estrarre i geni dagli HPO e aggiungerli alle tue query",
    "Genes associated to": "Geni associati a",
    "No genes": "Nessun gene",
    "Search Pathways": "Cerca Percorsi",
    "Request Error": "Errore di Richiesta",
    "Search for Pathways": "Cerca Percorsi",
    "Delete Pathway associated genes": "Elimina i geni associati ai Percorsi",
    "Create New Analysis": "Crea Nuova Analisi",
    "Select a simulated mode of inheritance configuration:": "Seleziona una configurazione simulata del modo di ereditarietà:",
    "Autosomal recessive (both homozygous and compound heterozygous)": "Recessivo autosomico (sia omozigote che eterozigote composto)",
    "compound heterozygosity": "eterozigosi composta",
    "Autosomal dominant": "Dominante autosomico",
    "Autosomal dominant - de novo (parents required)": "Dominante autosomico - de novo (genitori richiesti)",
    "X-Linked Recessive ": "Recessivo legato all'X",
    "X-Linked Dominant ": "Dominante legato all'X",
    "X-Linked Dominant - Index Case Female": "Dominante legato all'X - Caso indice femminile",
    "Custom Inheritance ": "Ereditarietà personalizzata",
    "Somatic": "Somatizzato",
    "You can now create a query that will be applied to the genotype(s). Select your filters and run the new query.": "Ora puoi creare una query che verrà applicata ai genotipi. Seleziona i tuoi filtri ed esegui la nuova query.",
    "Run Query": "Esegui la Query",
    "You have created ": "Hai creato ",
    " analyses.": " analisi.",
    "Apply to current Samples": "Applica ai campioni attuali",
    "or start from new Samples:": "o inizia da nuovi campioni:",
    "Configuration details": "Dettagli della configurazione",
    "Affected": "Affetto",
    "You need at least one experiment": "Hai bisogno di almeno un esperimento",
    "You need at least one experiment to create an analysis. If you deleted an experiment by mistake, click on restore experiment": "Hai bisogno di almeno un esperimento per creare un'analisi. Se hai cancellato un esperimento per errore, clicca su ripristina esperimento",
    "Ok, keep this experiment": "Ok, mantieni questo esperimento",
    "set Genotype Settings": "imposta Impostazioni Genotipo",
    "Quality Settings": "Impostazioni di Qualità",
    "No genotype configured. The sample will not be included in the analysis": "Nessun genotipo configurato. Il campione non sarà incluso nell'analisi",
    "Genotype Settings": "Impostazioni Genotipo",
    "GPAP Experiment ID": "ID Esperimento GPAP",
    "Case": "Caso",
    "Affected": "Affetto",
    "X-Linked Recessive - Index Case Male": "Recessivo legato all'X - Caso indice maschile",
    "X-Linked Dominant - Index Case Male": "Dominante legato all'X - Caso indice maschile",
    "X-Linked Dominant - Index Case Female": "Dominante legato all'X - Caso indice femminile",
    "Please, give a name to your study to save it correctly. ": "Per favore, dai un nome al tuo studio per salvarlo correttamente.",
    "Analysis could not be updated successfully. Trying to save queries.": "L'analisi non ha potuto essere aggiornata con successo. Tentativo di salvare le query.",
    "The study could not be saved": "Lo studio non ha potuto essere salvato",
    "Study title. Max 50 chars": "Titolo dello studio. Massimo 50 caratteri",
    "Save changes": "Salva modifiche",
    "Cancel": "Annulla",
    "Share with your groups": "Condividi con i tuoi gruppi",
    "Please select study visibility": "Per favore, seleziona la visibilità dello studio",
    "Select": "Seleziona",
    "Private": "Privato",
    "Public": "Pubblico",
    "Study description. Max 50 chars": "Descrizione dello studio. Massimo 50 caratteri",
    "Fill in some information to save your work.": "Inserisci alcune informazioni per salvare il tuo lavoro.",
    "Update Study Information": "Aggiorna Informazioni Studio",
    "Save Study": "Salva Studio",
    "Study Saved Successfully": "Studio Salvato con Successo",
    "Close": "Chiudi",
    "One or more queries could not be saved": "Una o più query non hanno potuto essere salvate",
    "Something went wrong and one or more queries could not be saved correctly. You will not be able to load the study correctly.Please, contact us at if the problem persists.": "Qualcosa è andato storto e una o più query non hanno potuto essere salvate correttamente. Non sarai in grado di caricare lo studio correttamente. Per favore, contattaci se il problema persiste.",
    "Analyses without queries will not be saved": "Le analisi senza query non verranno salvate",
    "You are not the creator of the study": "Non sei il creatore dello studio",
    "Save current Study with Analyses and queries": "Salva Studio Corrente con Analisi e query",
    "Save": "Salva",
    "You have unsaved queries": "Hai query non salvate",
    "New Instand DIR Study": "Nuovo Studio DIR Istantaneo",
    "No description": "Nessuna descrizione",
    "Please select study visibility": "Per favore, seleziona la visibilità dello studio",
    "The number includes SNVs and CNVs (if any)": "Il numero include SNV e CNV (se presenti)",

    "The filters selected for SNV-InDels do not automatically apply to CNVs. You can further filter the CNVs directly": "I filtri selezionati per SNV-InDels non si applicano automaticamente ai CNV. Puoi filtrare ulteriormente i CNV direttamente",
    "Used filter-set": "Set di filtri utilizzati",
    "Applied Filters": "Filtri Applicati",
    "Tag variants": "Tag Variabili",
    "There is no query selected. Or applied filters are not visible.": "Non è selezionata alcuna query. O i filtri applicati non sono visibili.",
    "Variant Effect": "Effetto Variante",
    "Genotype Features": "Caratteristiche Genotipo",
    "Mutation Taster": "Assaggiatore di Mutazione",
    "ClinVar Classes": "Classi ClinVar",
    "Onco Filter": "Filtro Onco",
    "PolyPhen-2 Pred.": "Pred. PolyPhen-2",
    "GnomAD Filter": "Filtro GnomAD",
    "Programs Filter": "Filtro Programmi",
    "Lists applied:": "Liste applicate:",
    "Include CNVs": "Includi CNV",
    "genes": "geni",
    "Positions": "Posizioni",
    "Filter can't be displayed": "Il filtro non può essere visualizzato",
    "population": "popolazione",
    "No SNV/Indel selected": "Nessun SNV/Indel selezionato",
    "SNV/Indel variant selected. Click to navigate to resource": "Variante SNV/Indel selezionata. Clicca per navigare alla risorsa",
    "External Links": "Collegamenti Esterni",
    "Links curated by the GPAP team": "Collegamenti curati dal team GPAP",
    "Frequent Links": "Collegamenti Frequenti",
    "Disease Information": "Informazioni sulla Malattia",
    "A list of links releated to disease annotations": "Una lista di collegamenti relativi alle annotazioni di malattie",
    "Variant Information": "Informazioni sulla Variante",
    "A list of links releated to variant annotations": "Una lista di collegamenti relativi alle annotazioni delle varianti",

    "Actionability links to identify possible treatments and interventions for a specific gene": "Collegamenti di attuabilità per identificare possibili trattamenti e interventi per un gene specifico",
    "Gene Information": "Informazioni sul Gene",
    "A list of links releated to gene annotations": "Una lista di collegamenti relativi alle annotazioni dei geni",
    "Pathway Information": "Informazioni sui Percorsi",
    "A list of links releated to pathways annotations": "Una lista di collegamenti relativi alle annotazioni dei percorsi",
    "Data Discovery": "Scoperta dei Dati",
    "A list of links that may allow new discoveries": "Una lista di collegamenti che possono permettere nuove scoperte",
    "ACMG guidelines": "Linee guida ACMG",
    "Pharmacogenomics": "Farmacogenomica",
    "Actionability": "Attuabilità",
    "We are working to integrate more resources in this section": "Stiamo lavorando per integrare più risorse in questa sezione",
    "Pathways": "Percorsi",
    "Pathways Reactome": "Percorsi Reactome",
    "Reactome is a free, open-source, curated and peer-reviewed pathway database": "Reactome è un database di percorsi gratuito, open source, curato e revisionato da esperti",
    "No Associations": "Nessuna Associazione",
    "HmtDB - Human Mitochondrial Database": "HmtDB - Database Mitocondriale Umano",
    "External Databases": "Database Esterni",
    "Human Pluripotent Stem Cell Registry": "Registro delle Cellule Staminali Pluripotenti Umane",
    "Variants distributions": "Distribuzioni delle Varianti",
    "Effect Impact": "Impatto dell'Effetto",
    "GnomAD Population": "Popolazione GnomAD",
    "Query": " Query",
    //interpretation requests
    "Interpretation Requests": "Richieste di Interpretazione",
    "Case ID": "ID del Caso",
    "Patient ID": "ID del Paziente",
    "Analysis Type": "Tipo di Analisi",
    "Priority": "Priorità",
    "Status": "Stato",
    "Creation Date": "Data di Creazione",
    "Assignee(s)": "Assegnatario(i)",
    "Experiment": "Esperimento",
    "Clinical referrer": "Referente Clinico",
    "Clinical contact": "Contatto Clinico",
    "Hospital Name": "Nome dell'Ospedale",
    "Deadline": "Scadenza",
    "Actions": "Azioni",
    "Download": "Scarica",
    "Upload": "Carica",
    "Edit": "Modifica",
    "Link": "Collegamento",

    //contact
    "Get in touch": "Mettiti in contatto",
    "Thank you for using our product.": "Grazie per aver utilizzato il nostro prodotto.",
    "Feel free to reach out if you have any questions or comments.": "Non esitare a contattarci se hai domande o commenti.",
    "Data Platforms & Tools Development Team": "Team di Sviluppo Piattaforme e Strumenti di Dati",
    "Barcelona, Spain": "Barcellona, Spagna",

    //Pharmacogenomics
    "Gene": "Gene",
    "Gene Evidence": "Evidenza Genica",
    "Genotype": "Genotipo",
    "Phenotype": "Fenotipo",
    "Haplotype1": "Applotipo1",
    "Haplotype2": "Applotipo2",
    "Haplotype Evidence": "Evidenza Applotipica",
    "Variant Data": "Dati delle Varianti",
    //Settings
    "Filter Set Manager": "Gestore Set di Filtri",
    "Create and Save a new Filter Set": "Crea e Salva un nuovo Set di Filtri",
    "Save Filter-Set": "Salva Set di Filtri",
    "Filter Name": "Nome del Filtro",
    "Filter Description": "Descrizione del Filtro",
    "Save Filter": "Salva Filtro",
    "Filters saved in the database. You can find the filter-set in the section below.": "Filtri salvati nel database. Puoi trovare il set di filtri nella sezione sottostante.",
    "Filter Set Saved Correctly": "Set di Filtri Salvato Correttamente",
    "No leading and trailing spaces are allowed": "Non sono consentiti spazi iniziali e finali",
    "Name already exists.": "Il nome esiste già.",
    "filter_description": "descrizione_filtro",
    "Available Filter Sets": "Set di Filtri Disponibili",
    "Applied filters:": "Filtri applicati:",
    "No filters found": "Nessun filtro trovato",
    "View filters": "Visualizza filtri",
    "User created": "Utente creato",
    "GPAP default": "Predefinito GPAP",
    "No description": "Nessuna descrizione",
    "Settings": "Impostazioni",
    "Filter Sets": "Set di Filtri",
    "Analysis Settings": "Impostazioni di Analisi",
    "Analysis Parameters": "Parametri di Analisi",
    "Standard Pipelines": "Pipeline Standard",
    "Search:": "Cerca:",
    "No results": "Nessun risultato",
    "Export in .txt": "Esporta in .txt",
    "This list contains": "Questa lista contiene",
    "Search:": "Cerca:",
    "ACTIVE": "ATTIVO",
    "Not Active": "Non Attivo",
    "Predefined Gene Lists": "Liste di Geni Predefinite",
    "Title:": "Titolo:",
    "Description:": "Descrizione:",
    "Source:": "Fonte:",
    "Permission:": "Permesso:",
    "Creator:": "Creatore:",
    "Version:": "Versione:",
    "Size:": "Dimensione:",
    "Creation Date:": "Data di Creazione:",
    "Status:": "Stato:",
    "Predefined Lists in GPAP": "Liste Predefinite in GPAP",
    "Visible List": "Lista Visibile",
    
    "Disabled List. Users won't see this predefined gene list": "Lista Disattivata. Gli utenti non vedranno questa lista di geni predefinita",
    "You are deleting this predefined list from the GPAP instance database. If you delete it, GPAP users won't be able to use this list anymore.": "Stai eliminando questa lista predefinita dal database dell'istanza GPAP. Se la elimini, gli utenti GPAP non potranno più utilizzare questa lista.",
    "Yes, delete it": "Sì, elimina",
    "Are you sure to delete the Predefined Gene List?": "Sei sicuro di voler eliminare la Lista di Geni Predefinita?",
    "List of genes": "Elenco di geni",
    "Predefined Gene List Info": "Informazioni sulla Lista di Geni Predefinita",
    "Public": "Pubblico",
    "Share with your groups": "Condividi con i tuoi gruppi",
    "Private": "Privato",
    "Please select study visibility": "Si prega di selezionare la visibilità dello studio",
    "Visibility": "Visibilità",
    "Select": "Seleziona",
    "Gene List": "Lista di Geni",
    "Variant Positions": "Posizioni Varianti",
    "Notes": "Note",
    "List Source": "Fonte della Lista",
    "Description": "Descrizione",
    "List Title": "Titolo della Lista",
    "List Information": "Informazioni della Lista",
    "Upload Successful": "Caricamento Riuscito",
    "The GPAP gene file was successfully uploaded and it's ready to be used.": "Il file dei geni GPAP è stato caricato con successo ed è pronto all'uso.",
    "Save List": "Salva Lista",
    "Create a new List": "Crea una nuova Lista",
    "List Visibility": "Visibilità della Lista",
    "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Quando attiva, la lista sarà utilizzabile per le analisi. Se questa lista ha lo stesso nome di una esistente, la precedente diventerà inattiva per gli utenti.",
    "Predefined Gene Lists": "Liste di Geni Predefinite",
    "Upload a List": "Carica una Lista",
    "View Lists": "Visualizza Liste",
    "File type": "Tipo di File",
    "Please select file type": "Si prega di selezionare il tipo di file",
    "GPAP Gene File": "File dei Geni GPAP",
    ".list File": ".list file",
    "Comma Separated List": "Lista Comma Separata",
    "You don't have the rights to do edits here": "Non hai l'autorizzazione per modificare questa sottostante.", 
    "Gene list was validated": "La lista di geni è stata validata",
    "Upload Gene list": "Carica lista di geni",
    "Some genes could not be validated. Please check them and re-upload the file:": "Alcuni geni non hanno potuto essere validati. Per favore, controllali e ricarica il file:",
    "Please check the text": "Si prega di verificare il testo",
    "Genes are not in the correct format, please check the text": "I geni non sono nel formato corretto, si prega di verificare il testo",
    "Fields should not be empty, please check the text": "I campi non dovrebbero essere vuoti, si prega di verificare il testo",
    "Gene list is empty. Please check the file.": "La lista di geni è vuota. Per favore, controlla il file.",
    "Cancel Gene List Upload": "Annulla il caricamento della lista di geni",
    "Upload a GPAP gene file in txt format": "Carica un file di geni GPAP in formato txt",
    "Be sure that the file is in the correct format and all required fields are present": "Assicurati che il file sia nel formato corretto e che tutti i campi richiesti siano presenti",
    "Select file": "Seleziona file",
    "Don't know the correct format?": "Non conosci il formato corretto?",
    "Download our template file and fill it in with your data": "Scarica il nostro file modello e compilalo con i tuoi dati",
    "Download sample file": "Scarica file di esempio",
    "Validating genes..": "Validazione dei geni in corso...",
    "Upload a .list file": "Carica un file .list",
    "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "Il file .list è il formato file legacy utilizzato per memorizzare liste di geni predefiniti nel GPAP. Questo caricamento è destinato agli utenti CNAG. Per maggiori informazioni, contattare Steve Laurie.",
    "The file must contain a JSON array of Predefined Gene List Objects.": "Il file deve contenere un array JSON di Oggetti Lista di Geni Predefiniti.",
    "upload .list file": "carica file .list",
    "lists parsed correctly and ready to be saved.": "liste analizzate correttamente e pronte per essere salvate.",
    "Save Lists": "Salva Liste",
    "View DIR list": "Visualizza DIR list",
    "Create a new DIR": "Crea una nuova DIR",
    "Priority": "Priorità",




}