export const getChromObj = (gene_array, patientData) => {


    const special_dict = {"MT":"23", "X": "24", "Y": "25"};
    let positions = [];
    let variants = [];
    gene_array.forEach(function (gene) {

        if(gene.variants!== undefined){
            gene.variants.forEach(function (variant) {

                let geneObj = {};

                geneObj["Family ID"] = patientData["famid"];
                geneObj["Local ID"] = patientData["id"];
                geneObj["PhenoStore ID"] = patientData["report_id"];

                geneObj["gene"] = gene.gene["name"];
                geneObj["status"] = (gene.gene["status"] !== undefined) ? gene.gene["status"] : "NA";

                if (variant["chromosome"] !== undefined) {

                    let chrom = (special_dict[variant["chromosome"]] === undefined) ? variant["chromosome"] : special_dict[variant["chromosome"]];
                    positions.push({
                        chrom: chrom,
                        pos: variant["start_pos"],
                        end_pos: variant["start_pos"]
                    });

                    ["timestamp","sample", "chromosome", "start_pos", "zygosity", "transcript", "cDNA", "protein", "significance", "inheritance", "comments","user","origin"].forEach(function (d) {

                        geneObj[d] = (variant[d]!== undefined) ? variant[d] : null;


                    });

                    variants.push(geneObj);

                }
            })
        }

    });

    return {positions: positions, flagged_variants: variants };
};


export const splitSNVandCNV = (data) => {

    if(data!== undefined){
        return {
            snv_data: data.filter(s => s.associated_genes === undefined),
            cnv_data: data.filter(s => s.associated_genes !== undefined) 
        }
    }
    else{
        return {
            snv_data: [],
            cnv_data: [],
           
        }
    }
    

}