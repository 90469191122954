

import React, {useEffect, useState} from "react";
import StudyOverview from "./StudyOverview";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

// ACTIONS
import * as Sample_Actions from "../../../scenes/study-page/create-study/analysis-page/reducers/sample_actions";
import * as Inheritance_Actions from "../../../scenes/study-page/create-study/analysis-page/reducers/inheritance_actions";
import * as StudyActions from "../../dashboard/reducers/study_actions";
import * as Actions from "../create-study/analysis-page/reducers/sample_actions";
import {parseSamples} from "./functions";




function StudyParser(props){

    const [samplesLoaded, setSamplesLoaded] = useState(false);
    const [load, setLoadStudy] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] =useState(false);

    let state = (props.location.state!== undefined) ? props.location.state : undefined;
    let samples_array = [];
    let study = undefined;
    let analyses = undefined;

    // from location state or from URL when loading a study from there.... this requires calling a study/1 API;
    if( state !== undefined){
        //studyData =  state.studyData;
        study = state.studyData.study;
        analyses = state.studyData.analyses;
        if(analyses!== undefined){
            samples_array = parseSamples(analyses)
        }

    }


    useEffect(function(){

        // set samples...

        if(study!== undefined && !samplesLoaded){

            setSamplesLoaded(true);
            let studyBucket = props.studyBucket;

            //let found = studyBucket.studies.find(s => s.studyName === study.studyName);
            // remove study from bucket...

            props.studyActions.removeStudy(study.studyName);

            /*let loaded = (found) !== undefined;
            setAlreadyLoaded(loaded)*/

        }

    }, []);



    return <StudyOverview
                study={study}
                analyses={analyses}
                samples_array = { samples_array }
                samplesLoaded = { samplesLoaded }
                alreadyLoaded={alreadyLoaded}
    />



}





function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            sampleList: state.sample_list,
            patients: state.patients.patients
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    /*  actions: bindActionCreators(Actions, dispatch),*/
    studyActions: bindActionCreators(StudyActions, dispatch),
    actions: bindActionCreators(Actions, dispatch),
    inheritance_actions: bindActionCreators(Inheritance_Actions, dispatch),
    samples_actions: bindActionCreators(Sample_Actions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(StudyParser);