import React, {Component} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions";
import {connect} from "react-redux";
import "./geneList.css";
import {DataList_View} from "../DataList_View";
import {AlertInfo} from "../../../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import Typography from "@material-ui/core/Typography";
import {Divider, LinearProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {string_geneConcatenation} from "../../functions/stringConcatenation";
import {h37_endpoint, h38_endpoint} from "../../../../../../../../../../../../services/api/ensembl";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


class GeneList extends Component{

    constructor(props)
    {
        super(props)

        this.state={
                genes_validated: false,
                loading_validation: false,
                genes_not_validated: []
            }

       this.getValidationInfo = this.getValidationInfo.bind(this);

    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        if(prevProps.geneList.length!== this.props.geneList.length){

            // validate genes;

            this.validateGenes(this.props.geneList);
        }
    }

    async validateGenes(array) {

        const chunkSize = 1000;
        let chunks = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            // do whatever
            chunks.push({chunk: chunk, validated: false, not_validated_genes: []});
        }

        this.setState({loading_validation: true})
        for (let item of chunks) {

            let url=h37_endpoint;
            if(window.config.genome_ref === "hg38"){
                url = h38_endpoint
            }

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({symbols: item.chunk})
            })
            let json = await response.json();

            if (Object.keys(json).length === item.chunk.length) {

                item.validated = true;

                //this.setState({genes_validated: true, genes_not_validated: []})
            }
          /*  else if (Object.keys(json).length < item.chunk.length) {

                let validated_genes = Object.keys(json);
                // not included genes;
                let difference = item.chunk.filter(x => !validated_genes.includes(x));
                item.validated = false;
                item.not_validated_genes = difference
                this.setState({genes_validated: false, genes_not_validated: difference})
            } */
            else {
                let validated_genes = Object.keys(json);
                item.not_validated_genes = item.chunk.filter(x => !validated_genes.includes(x))

            }
        }


        let genes_not_validated = [];

        let not_all_validated = chunks.map(s => s.validated).some(s => !s)


        chunks.forEach(s => genes_not_validated = genes_not_validated.concat(s.not_validated_genes))

        this.setState({
            genes_validated: !not_all_validated ,
            genes_not_validated: genes_not_validated,
            loading_validation:false
        })
    }


    getValidationInfo = () => {

        let validation_text = "NA";

        if(Array.isArray(this.state.genes_not_validated)){
            validation_text = (this.state.genes_not_validated.length !== 0 )
                ?  string_geneConcatenation(this.state.genes_not_validated)
                : "None"
        }

        let no_validation_text = "NA";

        if(Array.isArray(this.state.genes_validated)){
            validation_text = (this.state.genes_validated.length !== 0 )
                ? string_geneConcatenation(this.state.genes_validated)
                : "None"
        }

        return <Box>
            <Typography variant={"subtitle1"}> <MappedText text={"Genes not validated"}/> ({this.state.genes_not_validated.length}):</Typography>
            <div className={"geneListBox"}>{ validation_text}
            </div>
            {/*<Divider/>
            <Typography variant={"subtitle1"}>Genes validated ({this.state.genes_validated.length}):</Typography>
            <div className={"geneListBox"}>{ no_validation_text }</div>*/}
        </Box>


    }

    render() {

        let { geneList, operator } = this.props;
        const self = this;
        let text = (operator && geneList.length >0) ? (
            <span>
                <MappedText text="Current selected genes by applying " />
                {operator}
                <MappedText text=" of gene lists" />
            </span>
         ) : (<MappedText text={"No genes selected"} />);


        let severity = (this.state.genes_validated) ? "success" : "warning";

        let version = (window.config.genome_ref === "hg38") ? "v109 for GRCh38" : "v75 for GRCh37"
        let validation = (this.state.genes_validated) ?( 
            <span>
                <MappedText text="All gene symbols were validated using Ensembl" />
                {version}
            </span>
        ) : ( 
            <span>
            {this.state.genes_not_validated.length}<MappedText text={" gene symbols could not be validated using "}/> {version}
            </span>)


        let el= (this.props.geneList.length!== 0 ) ?  <div style={{float: "left", width: "97%", margin: "1%"}}>
            {
                (this.state.loading_validation)
                    ? <div>
                        <Typography variant={"subtitle2"}><MappedText text={"Validating gene symbols.."}/></Typography>
                        <LinearProgress/>
                    </div>

                    : <AlertInfo severity={severity}
                                message={ validation }
                                title=<MappedText text={"Gene Validation"}/>/>
            }      </div> : null;


        return <React.Fragment>
            {el}
            <DataList_View
                dataList = {geneList}
                title={"Genes"}
                label={text}
            />
            <Box>
                {self.getValidationInfo()}
            </Box>
        </React.Fragment>
    }


}



function mapStateToProps (state) {
    if (state.geneList !== undefined) {
        return {geneList: state.geneList.geneList, operator: state.geneList.operator}
    } else {
        return {geneList: [], operator: ""}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(GeneList);


