


import React from "react";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";



function ROHs({rohs, n_rohs}){


    if(rohs){
        if(n_rohs !== 0 )
        {
            return  <GPAP_Tooltip title={"Request for ROHs successful"}>
                    <div>
                        <div style={{float: "left", marginTop: "5px"}}>
                            <CheckCircleOutlineIcon color={"primary"} />
                        </div>
                        <div style={{float: "left", padding: "5px"}}>
                            Runs of Homozygosity calculated: {n_rohs}
                        </div>
                    </div>
                </GPAP_Tooltip>
        }
        else{
            return <GPAP_Tooltip title={"No ROHs were calculated"}>
                <div>
                    <div style={{float: "left", marginTop: "5px"}}>
                        <CancelIcon color={"error"}/>
                    </div>
                    <div style={{float: "left", padding: "5px"}}>
                    No runs of homozygosity were calculated for these experiments </div>
                </div>
            </GPAP_Tooltip>
        }
    }
    else{
        return  <GPAP_Tooltip title={"Failed to collect ROHs"}>
            <div>
                <div style={{float: "left", marginTop: "5px"}}>
                    <CancelIcon color={"error"}/>
                </div>
                <div style={{float: "left", padding: "5px"}}>
                    Error in collecting runs of homozygosities </div>
            </div>
        </GPAP_Tooltip>
    }

}

export default ROHs;