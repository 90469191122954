const onset_dict = {
    "Unknown": "Unknown",
    "HP:0003577": "Congenital",
    "HP:0030674": "Antenatal",
    "HP:0011460": "Embryonal",
    "HP:0011461": "Fetal",
    "HP:0003623": "Neonatal",
    "HP:0003593": "Infantile",
    "HP:0011463": "Childhood",
    "HP:0003621": "Juvenile",
    "HP:0003581": "Adult",
    "HP:0003584": "Late",
    "HP:0003596": "Middle age",
    "HP:0011462": "Young adult"
}
const inheritance_dict = {
    "HP:0000006": "Autosomal dominant",
    "HP:0001444": "Autosomal dominant somatic cell mutation",
    "HP:0001470": "Sex-limited autosomal dominant",
    "HP:0000007": "Autosomal recessive",
    "HP:0003745": "Sporadic",
    "HP:0001417": "X-linked",
    "HP:0001419": "X-linked recessive",
    "HP:0001423": "X-linked dominant",
    "HP:0001450": "Y-linked",
    "HP:0001427": "Mitochondrial",
    "HP:0001426": "Multifactorial"
}

export {inheritance_dict,onset_dict}

