import {makeStyles} from "@material-ui/core";


const useSectionStyles = makeStyles({
    paper_class: {
        float: "left",
        padding: "2%",
        width: "100%"
    },
    title: {
        flex: '1 1 100%',
        textTransform: "uppercase",
        color: "rgb(69, 117, 180)"
    },
});

export default useSectionStyles;