



//REDUCER

const initialState = {
    authorization: []
};


export const authorization = (state =initialState, action) => {
    switch (action.type) {
        case 'authorization':
            return action.authorization;
        default:
            return state
    }
};