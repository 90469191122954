
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {FixedSizeList} from "react-window";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {get_list_data} from "../../../services/api/lists_api";
import {connect} from "react-redux";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function GenesDialog(props) {

    const [genes, setGeneList] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(function(){

        // call to collect gene list;

        if(Object.keys(props.list).length>0 && "id" in props.list){
            get_list_data(props.token, window.config.apis_configuration.api_nextgpap_server, {"list_id": [props.list.id]})
                .then(r => r.json())
                .then(function(json){
                    if("data" in json && json["data"].length === 1 ){
                        let genes = json["data"]
                        if(genes.length>0){
                            setGeneList(genes[0]["data"]);
                        }
                    }
                })
        }
    }, [props.list])

    const getFilteredGenes = () => {
        let copy_genes = [...genes];
        return copy_genes.filter((gene) =>
            gene.toLowerCase().includes(searchValue.toLowerCase())
        );
    }

    const exportList = () => {
            const textData = genes.join('\n');

            const blob = new Blob([textData], { type: 'text/plain' });

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = (props.list)
                ? props.list.title + '.txt'
                : 'gene_list.txt';

            document.body.appendChild(link);
            link.click();

            URL.revokeObjectURL(url);
            document.body.removeChild(link);
    };


    return <Dialog
        open={true}
        onClose={props.closeDialog}
        aria-labelledby="genes-dialog-title"
        aria-describedby="genes-dialog-description"

    >
        <DialogTitle><MappedText text="List of genes"/></DialogTitle>
        <DialogContent style={{width:'300px', height:'500px'}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" style={{ marginRight: '8px' }}>
                    <MappedText text="Search:"/>
                </Typography>
            </div>
            <TextField
                variant="outlined"
                type="search"
                id="search"
                size="small"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}

            />
            {getFilteredGenes().length === 0 ? (
                <DialogContentText><MappedText text="No results"/></DialogContentText>
            ) : (
                <>
                    <FixedSizeList height={350} width="100%" itemSize={40} itemCount={getFilteredGenes().length} >
                        {({ index, style }) => (
                            <div  style={{...style, marginTop: '12px' }}>{getFilteredGenes()[index]}</div>
                        )}
                    </FixedSizeList>
                    <Typography variant="caption" style={{ fontSize:'14px',color:'#8E8E8E', marginTop: '12px', display:"flex" }}>
                        <MappedText text="This list contains"/> {genes.length} <MappedText text="genes"/> 
                    </Typography>
                    <Button onClick={exportList} variant="contained" color="#EAEAEA" disableElevation style={{ marginTop: '12px', display:"flex"}} >
                        <span><MappedText text="Export in .txt"/></span>
                    </Button>
                </>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.closeDialog} color="primary" autoFocus >
            <MappedText text="Close"/>
            </Button>
        </DialogActions>
    </Dialog>
}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,

        }
    }
}



export default connect(mapStateToProps,null)(GenesDialog);