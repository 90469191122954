



import React, { Component } from "react";



import * as Actions from "../../../actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    getPathways,
    getPathwaysGenes
} from "../../../../../../../../../../../../services/api/get";
import {mapGeneOperatorToProps} from "../../functions/mapStateToProps";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';
import GPAP_Tooltip from "../../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {string_geneConcatenation} from "../../functions/stringConcatenation";
import GeneralAutoComplete from "../GeneralAutoComplete";
import {handleResponse} from "../async-selection/handlers";
import {AlertInfo} from "../../../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import {CircularProgress} from "@material-ui/core";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



class PathwaySearch extends Component{

    constructor(props){
        super(props);
        this.state = {
            data:undefined,
            geneList: [],
            refGene: [],
            current_search: [],
            results: [],
            selected: [],   // previously selected items;
            error:false,
            reset: false
        };

        this.passData = this.passData.bind(this);
        this.add_applied_list = this.add_applied_list.bind(this);
        this.clearList = this.clearList.bind(this);
        this.getResults = this.getResults.bind(this);
        this.showResults = this.showResults.bind(this);
        this.renderAutoComplete = this.renderAutoComplete.bind(this);

        this.items = [];
    }

    componentDidMount() {


        if(this.props.applied_lists!== undefined){
            let myPathwayList = this.props.applied_lists.find(s => s.type === "Pathway");

            if(this.state.refGene.length === 0
                && this.state.geneList.length === 0
                && myPathwayList !== undefined){
                // load list
                this.setState({
                    refGene: myPathwayList.pathway_terms,
                    geneList: myPathwayList.genes
                })
                this.add_applied_list(myPathwayList.pathway_terms, myPathwayList.genes)
            }
        }

    }

    clearList(){

        let { actions } = this.props;
        let genes = this.state.geneList;

        actions.remove_genes_from_list(genes, "pathways");

        this.setState(
            {
                geneList: [],
                refGene: [],
                reset: true,
                currentSearch: []
            }
        )

        actions.reset_list_types("Pathway");
    }


    add_applied_list(terms, genes)
    {
        let { actions } = this.props;

        // add applied list to store

        let list ={};
        list["type"] = "Pathway";
        list["label"] = "Pathway-Associated";
        list["id"] = "Pathway";
        list["n_genes"] = genes.length;
        list["genes"] = genes;
        list["pathway_terms"] = terms;

        actions.add_applied_list(list);

    }


    passData(obj, reason){

        let { actions,operator, token } = this.props;
        let self = this;

        let { api_endpoint } = window.config;

        let genes = obj.map(d => d.gene);

        // collect other Genes


        if(genes.length!==0){

            let currentGeneList = [...this.state.geneList];
            self.setState({loading: true});

            if(reason === "remove-option"){
                // find removed gene
                let currentGenes = this.state.currentSearch.map(i => i.gene);

                const removed = currentGenes.filter(value => !genes.includes(value));
                if (removed.length >0){
                    let found = this.state.currentSearch.find(s => s.gene === removed[0]);
                    let genes_to_remove = found.list;
                    actions.remove_genes_from_list(genes_to_remove, "pathways");
                }


            }

            // get associated genes from api...
            self.items = [];
            genes.forEach(function(gene, index){

                getPathwaysGenes(api_endpoint, gene, token)
                    .then(resp =>
                    {
                        if(resp.ok){
                            return  resp.json();
                        }else{
                            self.setState({error: true, loading: false})
                        }
                    })
                    .then(json => {
                        if(json!== undefined){

                            let geneList_i = json
                                .map(item => item.GeneSymbol)
                                .filter(s => s!== undefined);

                            if(index === genes.length -1){
                                self.setState({
                                    loading: false})
                            }

                            if(operator=== "intersection"){
                                actions.intersect_genelist(geneList_i, "pathways");
                            }
                            else{
                                actions.add_genes_to_specific_genelist(geneList_i, "pathways");
                            }

                            let item_array = [{gene: gene, list: geneList_i}]

                            let tot_genes = [...new Set(currentGeneList.concat([...geneList_i]))];

                         /*   let list ={};
                            list["type"] = "Pathway";
                            list["label"] = "Pathway-Associated";
                            list["id"] = "Pathway";
                            list["n_genes"] = tot_genes.length;
                            list["genes"] = tot_genes;
                            list["pathway_terms"] = ;*/
                            self.add_applied_list(self.state.refGene.concat(gene), tot_genes)

                            //actions.add_applied_list(list);

                            self.setState({
                                refGene: self.state.refGene.concat(gene),
                                geneList: tot_genes,
                                currentSearch: self.items.concat( item_array )
                            });
                        }
                        else{
                            if(index === genes.length -1) {
                                self.setState({error: true, loading: false})
                            }
                        }
                    })

            })

        }

        if(obj.length === 0){
            self.clearList();
        }

    };

    getResults(s){

        const self = this;

        getPathways(s)
            .then(r => {
                if(r.ok){
                    return r.json()
                }
                else{
                    return false;
                }
            })
            .then(json => {
                if(json){
                    const resp = handleResponse("pathways", json);
                    self.setState({
                        results: resp,
                        error: false,
                        requestFailed:false
                    })
                }
                else{
                    self.setState({
                        results: [],
                        error: true,
                        requestFailed:true
                })
                }

            })
    }

    showResults(){

        const self = this;
        let string_genes = string_geneConcatenation(this.state.geneList);

        let gene_links = (Array.isArray(this.state.refGene))
            ? this.state.refGene.map((i, index) => <React.Fragment>
                <Link target={"_blank"} rel="noreferrer" href={"https://reactome.org/PathwayBrowser/#/" + i}> {i} </Link> { (index !== this.state.refGene.length) ? "," : null }
            </React.Fragment>)
            : null;


        if(gene_links !== null && Array.isArray(gene_links) && gene_links.length> 0 ){
            return <Box>
                <Typography> <MappedText text={"Genes associated to"}/> {gene_links}:
                </Typography>
                <div className={"geneList"}>
                    {(self.state.geneList.length !== 0 ) ? string_genes : <MappedText text={"No genes"}/>}
                </div>
            </Box>
        }
        else{ return null}


    }

    renderAutoComplete = () => {

        if(this.state.reset){

            setTimeout( () => this.setState({reset:false}), 500);
            return <CircularProgress/>

        }
        else{
            return    <GeneralAutoComplete
                //noOptionsText={"Start your search..."}
                results = {this.state.results}
                onInputChange = {this.getResults}
                title= <MappedText text={"Search Pathways"}/>
                passSelected = {this.passData}
                default_tags = {this.state.selected}
            />
        }



    }



    render(){

        const self = this;



        return (
            <Box p={2}>
                {
                    (self.state.geneList.length!==0)
                        ? <GPAP_Tooltip title=<MappedText text={"Delete Pathway associated genes"}/>>
                            <IconButton onClick={this.clearList}>
                                <DeleteSweepOutlinedIcon/>
                            </IconButton>
                        </GPAP_Tooltip>
                        : null
                }
                <Typography variant={"body1"}><MappedText text={"Search for Pathways"}/></Typography>
                {self.renderAutoComplete()}

                <div>{this.state.requestFailed}</div>
                {(this.state.loading)
                    ? <CircularProgress color="secondary" />
                    : null
                }
                <Box p={1}>
                    {
                        (this.state.refGene !== undefined && !this.state.error)
                            ? this.showResults()
                            : (this.state.error)
                                ?  <AlertInfo message=<MappedText text={ "Request Error" }/> severity={"error"}  title=<MappedText text={"Error"}/>/>
                                :  null
                    }
                </Box>
            </Box>

        )
    }

}





const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapGeneOperatorToProps,mapDispatchToProps)(PathwaySearch);