


export function getAnalysisAnnotations(studySettings, studyBucket){

    const study = studyBucket.studies.find(s => s.localId === studySettings.id);

    if(study!== undefined){

        // get all the labelled ones;
        let analysis = study.analyses.find(s=> s.localId === studySettings.current_analysis);

        let all_labelled= [];

        let global_labels = {"Important" : [], "Follow-Up": [], "Discarded":[], "Candidate": []}

        // make labels global so that are visible for all analyses run in the study.
        study.analyses.forEach(d => {
            if(Object.keys(d.labelled).length > 0 ){
                Object.keys(d.labelled).forEach(s => {
                    global_labels[s] = global_labels[s].concat(d.labelled[s])
                })
            }

        });

        if(analysis !== undefined){
            return {
                labelled: global_labels,
                tagged: (study.tagged_variants!== undefined) ? study.tagged_variants : []
            }
        }
        else{
            return {
                labelled: [],
                tagged: []
            }
        }
    }
    else{
        alert("Error: study not created");
    }
}


export function getStudy(studySettings, studyBucket){
    const study = studyBucket.studies.find(s => s.localId === studySettings.id);

    return {study:study}
}

export function getCurrentAnalysis(studySettings, studyBucket){
    const study = studyBucket.studies.find(s => s.localId === studySettings.id);

    if(study!== undefined && studySettings.current_analysis!== null){
        const analysis = study.analyses.find(s => s.localId === studySettings.current_analysis);

        /*if ((analysis.analysis_type == "instand") && (analysis.queries.length==2)){
            if (analysis.queries[0].outputs[0].n_variants === -1){
               analysis.queries.splice(0,1)
               analysis.queries[0].name = "query_1"}
        }*/

        if(analysis!== undefined){
            return {analysis:analysis}
        }
        else{
            return {analysis:false}
        }

    }
    else{
        return {analysis:false}
    }




}