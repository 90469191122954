import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListIcon from '@material-ui/icons/List';
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../../../../actions";
import * as StudyActions from "../../../../../../../../reducers/study_actions";
import {connect} from "react-redux";
import Badge from "@material-ui/core/Badge";
import LabelIcon from "@material-ui/core/SvgIcon/SvgIcon";
import DoneIcon from '@material-ui/icons/Done';
import SaveIcon from '@material-ui/icons/Save';
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import WarningIcon from '@material-ui/icons/Warning';
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import orange from "@material-ui/core/colors/orange";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
    queryText:{
        fontSize:"13px"
    },
    queryText_selected:{
        fontWeight: "bold",
        fontSize:"13px"
    },
    queryText_secondary:{
        fontSize:"11px"
    },
    customBadge: {
        backgroundColor: "#3182bd",
        color: "white"
    }
}));


function QueryItem(props){

    const classes = useStyles();

    const {current_query, analysisType, key} = props;
    const n_variants = ( props.query.outputs!== undefined && props.query.outputs.length!== 0 )
        ? props.query.outputs[props.query.outputs.length-1].n_variants
        : "NA";
    const query_item = props.query;

    const queryName = (query_item!== undefined && query_item.name!== undefined) ?  query_item.name : "query_" + query_item.query_id;


    const getColor = () => {

        return (query_item.localId === current_query) ? "orange": "gray";

    };

    const getSelected = () => {
        return (query_item.localId === current_query) ? classes.queryText_selected: classes.queryText;
    };


    const getSavedIcon = () => {
        let saved = props.query.query_id!== undefined;

        if(saved){
            return <GPAP_Tooltip title={"Query Saved to DB"} ><span><SaveIcon style={{ color: green[500] }}/></span></GPAP_Tooltip>
        }
        else{
            return <GPAP_Tooltip title={"Query not saved yet"} ><WarningIcon style={{ color: orange[500] }}/></GPAP_Tooltip>
        }

    };

    const getDeleteTool = () => {

        if(props.readOnly){
            return null
        }
        else if(props.readOnly === undefined){
            return <IconButton edge="end" aria-label="run"><DeleteIcon onClick={() => props.queryDelete(query_item)}/></IconButton>
        }
        else{
            return <IconButton edge="end" aria-label="run"><DeleteIcon onClick={() => props.queryDelete(query_item)}/></IconButton>
        }

    }

    const renderActions = () => {


       return <ListItemSecondaryAction>
                {getSavedIcon()}
                {(!props.readOnly)
                    ? <IconButton
                        edge="end"
                        aria-label="run"
                        onClick={() => props.queryViewer(query_item, analysisType)}>
                        <PlayArrowIcon style={{color: getColor()}}/>
                </IconButton>
                    : null
                }
               {getDeleteTool()}
            </ListItemSecondaryAction>


    }




    return <ListItem
        key={key}
        button className={classes.nested}>
        <ListItemIcon>
            <span>
                <Badge
                    overlap="rectangular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    badgeContent={n_variants}
                    max={999}
                    classes={{badge:classes.customBadge}}
                ><GPAP_Tooltip title={"Number of variants"}><ListIcon/></GPAP_Tooltip></Badge>
            </span>
        </ListItemIcon>
        <ListItemText
            classes={{
                primary: getSelected() ,
                secondary: classes.queryText_secondary
            }}
            primary={queryName}
            secondary={(query_item.filter_set.filter_set_name!== "")
                ? query_item.filter_set.filter_set_name
                : "Custom filters"
            }/>
        {renderActions()}

    </ListItem>



}



function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return { "current_query": state.studySettings.current_query}
    }
}





export default connect(mapStateToProps,null)(QueryItem);

