import {
    ADD_SAMPLES,
    ANNOTATE_VARIANTS,
    CHANGE_QUERY_NAME,
    CHANGE_STUDY_NAME,
    CREATE_ANALYSIS,
    CREATE_QUERY,
    CREATE_STUDY,
    DELETE_ANALYSIS,
    DELETE_QUERY,
    UPDATE_OUTPUT,
    UPDATE_ANALYSIS_SAMPLES,
    SET_STUDY_TYPE, SET_TAGGED_VARIANTS,
    ADD_QUERY_ID,
    ADD_STUDY_ID, ADD_ANALYSIS_ID, RESET_STUDY_SETTINGS, REMOVE_STUDY,RESET_BUCKET
} from "./study_actions";




const initialState = {
    studies: []
};

const initial_settings = {
    id: null,
    analysisType: "",
    analysis_ids: [],
    current_analysis: null,
    current_query: null
};


export const study_settings = ( state = initial_settings, action) => {

    const settings = {...state};

    switch (action.type) {

        case SET_STUDY_TYPE:
            return {...settings, ... action.settings};  // merge properties or overwrites
        case RESET_STUDY_SETTINGS:
            return initial_settings;  // merge properties or overwrites
        default:
            return state;
    }
};





export const study_bucket = (state =initialState, action) => {

    const bucket = state;
    switch (action.type) {

        case CREATE_QUERY:
            let bucket_copy1 = {...bucket};
            const currentStudy = bucket_copy1.studies.find(s => s.localId === action.study_id);
            const analysis = currentStudy.analyses.find(s=> s.localId === action.analysis_id);
            if(analysis!== undefined){
                analysis.queries.push(action.query);
                return bucket_copy1;
            }
            else{
                return bucket_copy1;
            }

        case CREATE_ANALYSIS:
            let bucket_copy2 = {...bucket};
            let study = bucket_copy2.studies.find(s => s.localId === action.analysis.studyLocalId);
            if(study!== undefined){
                study.analyses.push(action.analysis);
                return bucket_copy2;
            }
            else{
                alert("Study not created or found");
                return bucket_copy2;
            }
        case CREATE_STUDY:
            bucket.studies.push(action.study);
            return bucket;
        case RESET_BUCKET:
            let bucket_to_reset = {...bucket};
            bucket_to_reset.studies = [];
            return bucket_to_reset;
        case REMOVE_STUDY:
            let bucket_rm = {...bucket};
            bucket_rm.studies = bucket_rm.studies.filter(s => s.studyName !== action.studyName);
            return  bucket_rm;
        case ADD_SAMPLES:
            let bucket_copy_samples = {...bucket};
            let my_study = bucket_copy_samples.studies.find(s => s.localId === action.studyInfo.studyID);
            my_study.samples = action.studyInfo.samples;
            return bucket_copy_samples;
        case UPDATE_ANALYSIS_SAMPLES:
            let bucket_copy_samples_2 = {...bucket};
            let my_study_2 = bucket_copy_samples_2.studies.find(s => s.localId === action.study_id);
            let analysis_2 = my_study_2.analyses.find(s=> s.localId === action.analysis_id);
            analysis_2.samples= action.samples;
            return bucket_copy_samples_2;
        case CHANGE_STUDY_NAME:
            let bucket_copy3 = {...bucket};
            let current_study = bucket_copy3.studies.find(s => s.localId === action.studyInfo.studyID);

            // get keys to change
            let keys = Object.keys(action.studyInfo).filter(s => s !== "studyID");

            // update values;
            keys.forEach(k=> {
                current_study[k] =  action.studyInfo[k];
            })

            return bucket_copy3;

        case DELETE_ANALYSIS:

            let bucket_z = {...bucket};
            const study_z = bucket_z.studies.find(s => s.localId === action.study_id);
            study_z.analyses = study_z.analyses.filter(s=> s.localId !== action.analysis_id);
            return bucket_z;

        case DELETE_QUERY:
            let bucket_d = {...bucket};
            const study_d = bucket_d.studies.find(s => s.localId === action.study_id);
            const analysis_d = study_d.analyses.find(s=> s.localId === action.query.analysis_id);
            analysis_d.queries = analysis_d.queries.filter(q => q.localId !== action.query.localId);
            return bucket_d;
        case CHANGE_QUERY_NAME:
            let bucket_c = {...bucket};
            const study_c = bucket_c.studies.find(s => s.localId === action.study_id);
            const analysis_c = study_c.analyses.find(s=> s.localId === action.analysis_id);
            let query= analysis_c.queries.find(s => s.localId === action.query_id);
            query.name = action.name;
            return bucket_c;
        case ADD_STUDY_ID:
            let bucket_YY = {...bucket};
            // looking at study by taking the database ID. We assume the study has been saved correctly and we add the query_id in the database.
            let study_YY = bucket_YY.studies.find(s => s.localId === action.localId);
            // add study id, just in case
            study_YY.id = action.study_id;
            return bucket_YY;
        case ADD_ANALYSIS_ID:
            let bucket_aa = {...bucket};
            // looking at study by taking the database ID. We assume the study has been saved correctly and we add the query_id in the database.
            let study_aa = bucket_aa.studies.find(s => s.id === action.study_id);
            // add study id, just in case
            let analysis_aa = study_aa.analyses.find(s=> s.localId === action.localId);
            analysis_aa.id = action.analysis_id
            return bucket_aa;

        case ADD_QUERY_ID:
            let bucket_xx = {...bucket};
            // looking at study by taking the database ID. We assume the study has been saved correctly and we add the query_id in the database.
            let study_xx = bucket_xx.studies.find(s => s.id === action.study_id);
            // add study id, just in case
            //study_xx.id = action.study_id;
            let analysis_xx = study_xx.analyses.find(s=> s.localId === action.analysis_id);
            if(analysis_xx!== undefined){
                let query_xx= analysis_xx.queries.find(s => s.localId === action.localId);
                query_xx.query_id = action.query_id;
                return bucket_xx;
            }
            else{
                //alert("Error in saving the study");
                return bucket_xx;
            }

        case UPDATE_OUTPUT:
            let bucket_f = {...bucket};
            const study_f = bucket_f.studies.find(s => s.localId === action.study_id);
            const analysis_f = study_f.analyses.find(s=> s.localId === action.analysis_id);
            if(analysis_f!== undefined){
                let query_f= analysis_f.queries.find(s => s.localId === action.query_id);
                if(query_f!== undefined){
                    if(Array.isArray(query_f.outputs) && query_f.outputs.length>0){
                        // which one should we edit? we assume now we have only one output...later we will have more..
                        if(query_f.outputs[0].n_variants === -1  ){
                            query_f.outputs[0].n_variants = action.n_variants;
                        }

                    }
                }
            }
            return bucket_f;
        case SET_TAGGED_VARIANTS:
            let bucket_e = {...bucket};
            const study_e = bucket_e.studies.find(s => s.localId === action.study_id);
            if(study_e!== undefined){
                study_e.samples_variants = [];
                study_e.samples_variants =study_e.samples_variants.concat(action.variants);
            }

            return bucket_e;
        case ANNOTATE_VARIANTS:
            let bucket_to_annotate = {...bucket};
            // get the analysis;
            const study_to_annotate = bucket_to_annotate.studies.find(s => s.localId === action.study_id);
            let analysis_to_annotate = study_to_annotate.analyses.find(s=> s.localId === action.analysis_id);
            // add labelled or tagged;
            if(action.annotation_type === "LABEL"){

                // update this;
                let added_already = false;
                if(Object.keys(analysis_to_annotate.labelled).includes(action.label)){

                    let all_keys  = Object.keys(analysis_to_annotate.labelled);
                    // remove from other labels (e.g If variant in Follow-Up, it will be removed
                    all_keys.forEach(function(label_term){
                        if(label_term!==action.label){
                            let variants =action.variants.map(v => v.uniqueName);
                            analysis_to_annotate.labelled[label_term] = analysis_to_annotate.labelled[label_term].filter(s=> !variants.includes(s.uniqueName) )
                        }
                        else{

                            // add the variant in the selected category

                            let variants_selected =action.variants.map(v => v.uniqueName);
                            let variants_by_tag = analysis_to_annotate.labelled[label_term];
                            added_already = variants_by_tag.filter(variant => variants_selected.includes(variant.uniqueName)).length !==0;
                            if(added_already){
                                // remove the variant from the selected category
                                analysis_to_annotate.labelled[label_term] = variants_by_tag.filter(s=> !variants_selected.includes(s.uniqueName) )
                            }
                            else{
                                // add the variant in the category
                                let current_list_uniques = analysis_to_annotate.labelled[action.label].map(s => s.uniqueName);
                                let variants_by_tag = analysis_to_annotate.labelled[action.label];
                                analysis_to_annotate.labelled[action.label] = variants_by_tag.concat(action.variants.filter(s => !current_list_uniques.includes(s.uniqueName)));
                            }
                        }

                    });

                }

                else{
                    // new collection;
                    analysis_to_annotate.labelled[action.label] = action.variants;
                    //analysis_to_annotate.labelled = analysis_to_annotate.labelled[action.extra].concat(action.variants);
                }

            } // add tagged;
            else if(action.annotation_type === "TAGGED"){

                if(action.operation_type === "undefined" || action.operation_type === "add")
                {
                    study_to_annotate.tagged_variants = study_to_annotate.tagged_variants.concat(action.variants);
                }
                else if(action.operation_type === "delete")
                {
                    let variant_to_delete = action.variants[0];
                    study_to_annotate.tagged_variants = study_to_annotate.tagged_variants.filter( function(variant){
                       return variant["chrom"] !== variant_to_delete["chrom"] && variant["pos"] !== variant_to_delete["pos"] && variant["chrom"] !== variant_to_delete["gene_name"]
                    })

                }

            }
            return bucket_to_annotate;



        default:
            return state;
    }

};