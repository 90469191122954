

// example of CNV Object

/*
example= {
    "SV_chrom": "17",
    "SV_start": 44204001,
    "SV_end": 44359999,
    "SV_length": 155998,
    "SV_type": "DUP",
    "CytoBand": "q21.31",
    "Gene_name": [
    "ARL17B",
    "KANSL1",
    "KANSL1-AS1"
],
    "ACMG_class": "Benign",
    "Tool": "CONTROLFREEC",
    "Experiment_ID": "Case1M",
    "Gene_count": 3,
    "B_gain_source": "CMRI:0_pbsv.SPLIT.DUP.751;DDD:49604;DDD:49608;DDD:49609;DDD:49611;dgv127e55;dgv128e55;dgv130e55;dgv3181n100;dgv939e212;esv2422002;esv2762433;nsv516807",
    "B_gain_AFmax": "0.5406",
    "B_loss_AFmax": "0.2994",
    "HI": "3.0",
    "GnomAD_pLI": "0.99972",
    "AnnotSV_ranking_criteria": "1A (cf Gene_count, RE_gene, +0.00);2C (cf po_B_gain_allG_source, -1.00);2B (cf po_P_gain_source, TS, OMIM_morbid+0.00);2J (KANSL1, +0.00);2L (ARL17B, +0.00);3A (3 genes, +0.00);5F (+0.00)",
    "RE_gene": [
    "NSF (morbid/RE=EA_enhancer)"
],
    "P_snvindel_phen": "Inborn_genetic_diseases;Intellectual_disability;Koolen-de_Vries_syndrome;Neurodevelopmental_delay",
    "LOEUF_bin": "0.0",
    "P_snvindel_nb": "17.0",
    "AnnotSV_ranking_score": -1,
    "B_loss_source": "DDD:49609;DDD:49611;dgv940e212;esv2762433",
    "Annotation_mode": "full",
    "QC_tagg": "High Qual",
    "AnnotSV population AFMax": "0.5406",
    "originalIndex": 3
}*/


// ························· 38 ·························

import MappedText from "../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";
import React from "react";



const gene_field = "Gene_name";


const by_default_38 = {
    id: 'root',
    name: <MappedText text='Frequent Links'/>,
    children: [
        {
            "id": "2",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom','SV_start', 'SV_end'],
            "description": "Database providing population allele frequencies for structural variants.",
            'url_appendix': '?dataset=gnomad_sv_r4',
            "variable_category": "SV_start"
        },
        {
            "id": "3",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            "description": "Database containing clinically relevant genetic variants and their associations with diseases.",
            "variable_category": "SV_start"

        },
        {
            "id": "4",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg38/",
            'input': ['chrom', 'SV_start', 'ref', "alt"],
            "description": "Platform offering comprehensive variant annotation and interpretation.",
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            "id": "5",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            "description": "Resource for exploring genetic mutations associated with inherited diseases.",
            "variable_category": gene_field
        },
        {
            "id": "6",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'SV_start', 'SV_end'],
            "description": "Genome browser for visualizing genomic data and annotations.",
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "SV_start"
        },
        {
            id: "7",
            'name': 'OMIM',
            'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            'input': ['gene_name'],
            'url_appendix': '',
            "description": "Database for exploring genetic disorders and their associated genes.",
            "variable_category": gene_field
        },
        {
            "id": "8",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/roh/",
            'input': ['chrom', 'SV_start', 'ref', "alt"],
            "description": "Clinical database offering information on genetic variants and their significance.",
            'url_appendix': '',
            "variable_category": "SV_start"
        },


    ],
    description: <MappedText text="Links curated by the GPAP team"/>
};

const by_default_37 = {
    id: 'root',
    name: <MappedText text='Frequent Links'/>,
    children: [
        {
            "id": "2",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom',"SV_start", "SV_end"],
            "description": "Database providing population allele frequencies for structural variants.",
            'url_appendix': '?dataset=gnomad_sv_r2_1',
            "variable_category": "SV_start"
        },
        {
            "id": "3",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            "description": "Database containing clinically relevant genetic variants and their associations with diseases.",
            "variable_category": "SV_start"

        },
        {
            "id": "4",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg19/",
            'input': ['chrom', 'SV_start', 'ref', "alt"],
            "description": "Platform offering comprehensive variant annotation and interpretation.",
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            "id": "5",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': ['gene_name'],
            "description": "Resource for exploring genetic mutations associated with inherited diseases.",
            "variable_category": gene_field
        },
        {
            "id": "6",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'SV_start', 'SV_end'],
            "description": "Genome browser for visualizing genomic data and annotations.",
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "SV_start"
        },
        {
            id: "7",
            'name': 'OMIM',
            'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            'input': ['gene_name'],
            'url_appendix': '',
            "description": "Database for exploring genetic disorders and their associated genes.",
            "variable_category": gene_field
        },
        {
            "id": "8",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/roh/",
            'input': ['chrom', 'SV_start', 'ref', "alt"],
            "description": "Clinical database offering information on genetic variants and their significance.",
            'url_appendix': '-hg38',
            "variable_category": "SV_start"
        },


    ],
    description: <MappedText text="Links curated by the GPAP team"/>
};


const variant_info_38 = {
    id: "root",
    name: <MappedText text="Variant Information"/>,
    children: [
        {
            id: "0",
            name: 'Ensembl',
            url_prefix: 'http://www.ensembl.org/Homo_sapiens/Location/View?r=',
            input: ['chrom','SV_start', 'SV_end'],
            description: 'Genome browser providing comprehensive variant annotations.',
            url_appendix: '',
            variable_category: "SV_start"
        },
        {
            id: "1",
            name: 'NCBI',
            url_prefix: "https://www.ncbi.nlm.nih.gov/variation/view/?assm=GCF_000001405.40&chr=",
            input: ['chrom', 'SV_start', 'SV_end'],
            description: 'Database for exploring genetic variations and their annotations.',
            url_appendix: '',
            variable_category: "SV_start"
        },
        {
            id: "2",
            name: 'DGVa',
            url_prefix: "http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr",
            input: ['chrom', "SV_start", "SV_end"],
            description: 'Database of genomic structural variants for the human population.',
            url_appendix: '',
            variable_category: "SV_start"
        },
        {
            id: "3",
            name: 'GWASCentral',
            url_prefix: "http://www.gwascentral.org/generegion?q=chr",
            input: ['chrom', 'SV_start', 'SV_end'],
            description: 'Database containing genomic regions associated with traits from GWAS studies.',
            url_appendix: '',
            variable_category: "SV_start"
        },
        {
            id: "4",
            name: 'ClinVar',
            url_prefix: 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            url_appendix: '',
            input: ['clinvar_id'],
            description: 'Database containing clinically relevant genetic variants and their associations with diseases.',
            variable_category: "SV_start"
        },
        {
            id: "5",
            name: 'gnomAD',
            url_prefix: 'https://gnomad.broadinstitute.org/region/',
            input: ['chrom', 'SV_start', 'SV_end'],
            description: 'Database providing population allele frequencies for structural variants.',
            url_appendix: '?dataset=gnomad_sv_r4',
            variable_category: "SV_start"
        },
        {
            id: "6",
            name: "gnomAD Rare",
            url_prefix: 'https://gnomad.broadinstitute.org/region/',
            input: ['chrom', 'SV_start', 'SV_end'],
            description: 'Database offering allele frequencies for rare structural variants.',
            url_appendix: '?dataset=gnomad_cnv_r4',
            variable_category: "SV_start"
        },
        {
            id: "7",
            name: 'UCSC',
            url_prefix: "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            input: ['chrom', 'SV_start', 'SV_end'],
            description: 'Genome browser for visualizing genomic data and annotations.',
            url_appendix: "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            variable_category: "SV_start"
        },
        {
            id: "8",
            name: 'Franklin',
            url_prefix: "https://franklin.genoox.com/clinical-db/variant/roh/",
            input: ['chrom', 'SV_start', 'ref', "alt"],
            description: 'Clinical database offering information on genetic variants and their significance.',
            url_appendix: '',
            variable_category: "SV_start"
        },
        {
            id: "9",
            name: 'VarSome',
            url_prefix: "https://varsome.com/variant/hg38/",
            input: ['chrom', 'SV_start', 'ref', "alt"],
            description: 'Platform offering comprehensive variant annotation and interpretation.',
            url_appendix: '',
            variable_category: "SV_start"
        }
    ],
    description: <MappedText text="A list of links related to variant annotations"/>
};



const variant_info_37 = {
    id: "root",
    "name": <MappedText text="Variant Information"/>,
    children:[
        {
            id: "0",
            'name': 'Ensembl',
            'url_prefix' : 'http://www.ensembl.org/Homo_sapiens/Location/View?r=',
            'input': ['chrom','SV_start', 'SV_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            id: "1",
            'name': 'NCBI',
            'url_prefix' : "https://www.ncbi.nlm.nih.gov/variation/view/?assm=GCF_000001405.40&chr=",
            'input': ['chrom', 'SV_start', 'SV_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            id: "2",
            'name': 'DGVa',
            'url_prefix' : "http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr",
            'input': ['chrom', 'SV_start', 'SV_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            id: "3",
            'name': 'GWASCentral',
            'url_prefix' : "http://www.gwascentral.org/generegion?q=chr",
            'input': ['chrom', 'SV_start', 'SV_end'],
            'description': '',
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            "id": "4",
            'name': 'ClinVar',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '',
            'input': ['clinvar_id'],
            'description': '',
            "variable_category": "SV_start"
        },
        {
            "id": "5",
            'name': 'gnomAD',
            'url_prefix' : 'https://gnomad.broadinstitute.org/region/',
            'input': ['chrom', 'SV_start', 'SV_end'],
            'description': '',
            'url_appendix': '?dataset=gnomad_sv_r4',
            "variable_category": "SV_start"
        },
        {
            "id": "6",
            'name': 'UCSC',
            'url_prefix' : "http://genome-euro.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&lastVirtModeExtraState=&virtModeType=default&virtMode=0&nonVirtPosition=&position=",
            'input': ['chrom', 'SV_start', 'SV_end'],
            'description': '',
            'url_appendix': "&hgsid=279155592_46qebeEONPC9a28Uow1XNTe6dQuJ",
            "variable_category": "SV_start"
        },
        {
            "id": "7",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/roh/",
            'input': ['chrom', 'SV_start', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "SV_start"
        },
        {
            "id": "8",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg38/",
            'input': ['chrom', 'SV_start', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "SV_start"
        },
    ],
    description: <MappedText text="A list of links releated to variant annotations"/>
};


const data_discovery_38 = {
    id: "root",
    name: "Data Discovery",
    children: [
        {
            id:  "1",
            'name': 'PubCaseFinder',
            'url_prefix' : 'https://pubcasefinder.dbcls.jp/search_disease/phenotype::/gene:HGNC:',
            'url_appendix': '/page:1%2C1/size:10%2C10',
            'input': ['features', "gene"],
            'description': '',
            "variable_category": gene_field
        }
    ],
    description: <MappedText text="A list of links that may allow new discoveries"/>
};
const disease_info = {
    id: "root",
    "name": <MappedText text="Disease Information"/>,
    children:[
        {
            id: "1",
            'name': 'OMIM',
            'url_prefix' : 'https://omim.org/search/?index=entry&start=1&limit=10&sort=score+desc%2C+prefix_sort+desc&search=',
            'input': [gene_field],
            'url_appendix': '',
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "2",
            'name': 'ClinVar',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/clinvar/?term=',
            'url_appendix': '%5Bgene%5D',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "3",
            'name': 'DisGeNET',
            'url_prefix' : 'http://disgenet.org/search/1/',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "4",
            'name': 'Edgar',
            'url_prefix' : ' https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },

    ],
    description: <MappedText text="A list of links releated to disease annotations"/>
};

//············ Both ·························

const ACMG_37 = {
    id: "root",
    name: <MappedText text=" ACMG guidelines"/>,
    children: [
        {
            "id":  "1",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg19/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id":  "2",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id":  "1",
            'name': 'InterVar',
            'url_prefix' : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        }]

}

const ACMG_38 = {
    id: "root",
    name: <MappedText text=" ACMG guidelines"/>,
    children: [
        {
            "id":  "1",
            'name': 'VarSome',
            'url_prefix' : "https://varsome.com/variant/hg38/",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        },
        {
            "id":  "2",
            'name': 'Franklin',
            'url_prefix' : "https://franklin.genoox.com/clinical-db/variant/snp/chr",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '-hg38',
            "variable_category": "pos"
        },
        {
            "id":  "1",
            'name': 'Wintervar',
            'url_prefix' : "http://wintervar.wglab.org/results.pos.php?queryType=position&chr=",
            'input': ['chrom', 'pos', 'ref', "alt"],
            'description': '',
            'url_appendix': '',
            "variable_category": "pos"
        }]

}

const pharmaco = {
    id: "root",
    name: <MappedText text="Pharmacogenomics "/>,
    children: [
        {
            "id":  "1",
            'name': 'PharmGKB ',
            'url_prefix' : "https://www.pharmgkb.org/search?query=",
            'input': [gene_field],
            'description': '',
            'url_appendix': '',
            "variable_category": gene_field
        },
        {
            "id":  "2",
            'name': 'PharmVar',
            'url_prefix' : " https://www.pharmvar.org/gene/",
            'input': [gene_field],
            'description': '',
            'url_appendix': '',
            "variable_category": gene_field
        }]

}


const gene_info_37 = {
    id: "root",
    name: <MappedText text="Gene Information"/>,
    children: [
        {
            id: "1",
            name: 'Ensembl',
            url_prefix: 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=',
            input: [gene_field],
            description: 'Genome browser providing comprehensive gene annotations.',
            url_appendix: '&db=core',
            variable_category: gene_field
        },
        {
            id: "2",
            name: 'PubMed',
            url_prefix: 'http://www.ncbi.nlm.nih.gov/pubmed/?term=',
            url_appendix: '+gene',
            input: [gene_field],
            description: 'Database for exploring scientific literature related to genes.',
            variable_category: gene_field
        },
        {
            id: "3",
            name: 'Entrez',
            url_prefix: 'http://www.ncbi.nlm.nih.gov/gene/?term=%28',
            url_appendix: '%5BGene+Name%5D%29+AND+9606%5BTaxonomy+ID%5D',
            input: [gene_field],
            description: 'Database providing comprehensive information about genes.',
            variable_category: gene_field
        },
        {
            id: "4",
            name: 'GeneCards',
            url_prefix: 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database offering detailed information about genes and their associated diseases.',
            variable_category: gene_field
        },
        {
            id: "5",
            name: 'COSMIC',
            url_prefix: 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about genes implicated in cancer.',
            variable_category: gene_field
        },
        {
            id: "6",
            name: 'GTEx',
            url_prefix: 'http://www.gtexportal.org/home/gene/',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing gene expression data across different tissues.',
            variable_category: gene_field
        },
        {
            id: "7",
            name: 'gnomAD',
            url_prefix: 'http://gnomad.broadinstitute.org/gene/',
            url_appendix: '?dataset=gnomad_sv_r2_1',
            input: [gene_field],
            description: 'Database providing allele frequencies for genes.',
            variable_category: gene_field
        },
        {
            id: "8",
            name: 'GWAS Central',
            url_prefix: 'http://www.gwascentral.org/generegion?q=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database containing genomic regions associated with traits from GWAS studies.',
            variable_category: gene_field
        },
        {
            id: "9",
            name: 'ATLAS',
            url_prefix: 'http://www.proteinatlas.org/search/',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about protein expression in different tissues.',
            variable_category: gene_field
        },
        {
            id: "10",
            name: 'eDGAR',
            url_prefix: 'https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing information about gene-disease associations.',
            variable_category: gene_field
        },
        {
            id: "11",
            name: 'ClinGen',
            url_prefix: 'https://search.clinicalgenome.org/kb/genes?page=1&size=50&search=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing clinical annotations for genes.',
            variable_category: gene_field
        },
        {
            id: "12",
            name: 'HGMD',
            url_prefix: 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database for exploring genetic mutations associated with inherited diseases.',
            variable_category: gene_field
        },
        {
            id: "13",
            name: 'GeneReviews',
            url_prefix: 'https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=',
            url_appendix: '',
            input: [gene_field],
            description: 'Database providing comprehensive reviews of genes and genetic disorders.',
            variable_category: gene_field
        },
        {
            id: "14",
            name: 'LOVD',
            url_prefix: "https://databases.lovd.nl/shared/transcripts/",
            input: [gene_field, "transcript_id"],
            description: 'Database providing information about genetic variants and associated phenotypes.',
            url_appendix: "",
            variable_category: gene_field
        },
        {
            "id": "15",
            'name': 'genomeRNAi',
            'url_prefix' : "http://www.genomernai.org/v17/genedetails/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        },
        {
            "id": "16",
            'name': 'DisGeNet',
            'url_prefix' : "https://www.disgenet.org/browser/1/1/0/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        }
    ],
    description: <MappedText text="A list of links related to gene annotations"/>
};
const gene_info_38 = {
    id: "root",
    "name": <MappedText text="Gene Information"/>,
    children:[
        {
            id: "1",
            'name': 'Ensembl',
            'url_prefix' : 'http://feb2014.archive.ensembl.org/Homo_sapiens/Gene/Summary?g=',
            'input': [gene_field],
            'description': '',
            'url_appendix': '&db=core',
            "variable_category": gene_field
        },
        {
            id: "2",
            'name': 'PubMed',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/pubmed/?term=',
            'url_appendix': '+gene',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "3",
            'name': 'Entrez',
            'url_prefix' : 'http://www.ncbi.nlm.nih.gov/gene/?term=%28',
            'url_appendix': '%5BGene+Name%5D%29+AND+9606%5BTaxonomy+ID%5D',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "4",
            'name': 'GeneCards',
            'url_prefix' : 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "5",
            'name': 'COSMIC',
            'url_prefix' : 'http://cancer.sanger.ac.uk/cosmic/gene/overview?ln=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "6",
            'name': 'GTEx',
            'url_prefix' : 'http://www.gtexportal.org/home/gene/',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "7",
            'name': 'gnomAD',
            'url_prefix' : 'http://gnomad.broadinstitute.org/region/',
            'url_appendix': '?dataset=gnomad_sv_r4',
            'input': ["SV_start"],
            'description': '',
            "variable_category": "SV_start"
        },
        {
            id: "8",
            'name': 'GWAS Central',
            'url_prefix' : 'http://www.gwascentral.org/generegion?q=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "9",
            'name': 'ATLAS',
            'url_prefix' : 'http://www.proteinatlas.org/search/',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "10",
            'name': 'eDGAR',
            'url_prefix' : 'https://edgar.biocomp.unibo.it/cgi-bin/gene_disease_db/gene.py?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            id: "11",
            'name': 'ClinGen',
            'url_prefix' : 'https://search.clinicalgenome.org/kb/genes?page=1&size=50&search=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "12",
            'name': 'HGMD',
            'url_prefix' : 'http://www.hgmd.cf.ac.uk/ac/gene.php?gene=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "13",
            'name': 'GeneReviews',
            'url_prefix' : 'https://www.ncbi.nlm.nih.gov/books/NBK1116/?term=',
            'url_appendix': '',
            'input': [gene_field],
            'description': '',
            "variable_category": gene_field
        },
        {
            "id": "14",
            'name': 'LOVD',
            'url_prefix' : "https://databases.lovd.nl/shared/transcripts/",
            'input': [gene_field, "transcript_id"],
            'description': '',
            'url_appendix': "",
            "variable_category": gene_field
        },
        {
            "id": "15",
            'name': 'genomeRNAi',
            'url_prefix' : "http://www.genomernai.org/v17/genedetails/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        },
        {
            "id": "16",
            'name': 'DisGeNet',
            'url_prefix' : "https://www.disgenet.org/browser/1/1/0/",
            'input': ["gene_name"],
            'description': '',
            'url_appendix': "",
            "variable_category": "geneId"
        }
    ],
    description: <MappedText text="A list of links releated to gene annotations"/>
};


const pathwayInfo = {
    id: "root",
    "name": <MappedText text="Pathway Information"/>,
    children: [ {
        id:  "1",
        'name': 'WikiPathways',
        'url_prefix' : 'http://www.wikipathways.org//index.php?query=',
        'url_appendix': '&species=Homo+sapiens&title=Special%3ASearchPathways&doSearch=1',
        'input': [gene_field],
        'description': '',
        "variable_category": gene_field
    }],
    description: <MappedText text="A list of links releated to pathways annotations"/>
};
const actionability = {
    id: "root",
    name: <MappedText text="Actionability"/>,
    children: [ {
        id:  "1",
        'name': 'Treatabolome DB',
        'url_prefix' : 'https://treatabolome.cnag.crg.eu/#/gene/',
        'url_appendix': '',
        'input': ["gene"],
        'description': '',
        "variable_category": gene_field
    },
        {
            id:  "2",
            'name': 'IEMbase',
            'url_prefix' : 'http://www.iembase.com/search/results?page=1&queryText=',
            'url_appendix': '',
            'input': ["gene"],
            'description': '',
            "variable_category": gene_field
        },
        {
            id:  "3",
            'name': 'ClinGen Knowledge Repo',
            'url_prefix' : ' https://actionability.clinicalgenome.org/ac/',
            'url_appendix': '',
            'input': ["gene"],
            'description': '',
            "variable_category": ""
        }
    ],
    description: <MappedText text="Actionability links to identify possible treatments and interventions for a specific gene"/>
};

const configuration_tree_CNVs = {

    "by default": (window.config.genome_ref === "hg38") ? by_default_38 : by_default_37,
    "Disease Information": disease_info,
    "Variant Information": (window.config.genome_ref === "hg38") ? variant_info_38 : variant_info_37  ,
    "Gene Information":(window.config.genome_ref === "hg38") ? gene_info_38 : gene_info_37 ,
    "Pathway Information": pathwayInfo,
    "Data Discovery": data_discovery_38,
    "Actionability": actionability,
    "ACMG": (window.config.genome_ref === "hg38") ? ACMG_38 : ACMG_37,
    "Pharmacogenomics": pharmaco
};


export default configuration_tree_CNVs