
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {GPAP_Button} from "../../../../../gpap-lib/components/Button_Collection";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Divider from "@material-ui/core/Divider";
import {StudyType} from "../../../../study-page/StudyType";



function NoView(){


    return <Box p={2}>
        <Grid container
              direction={"row"}
              justifyContent="center"
              alignItems="center">
            <Grid item lg={12}>
                <Box p={2}>
                    <Typography variant={"h4"}>You haven't loaded any study yet </Typography>
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Box p={2}>
                    <Link
                        to={"home"}
                        component={GPAP_Button}
                        startIcon={<ArrowBackIcon/>} >
                        Back to My GPAP
                    </Link>
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Divider/>
                <StudyType/>
            </Grid>
        </Grid>
    </Box>;



}


export default NoView;