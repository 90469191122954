

import React, {Fragment} from "react";
import {GPAP_Button} from "../../../../../../gpap-lib/components/Button_Collection";
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";



function CreateReportBtn(props){

    let {participant_id, tooltipText, title, label, disable} = props;


    return <Fragment>
        <GPAP_Tooltip title={tooltipText}>
            <GPAP_Button
                disable={disable}
                label={label}
                target={"_blank"}
                href= {window.location.origin + "/report/#/create_report/" + participant_id}
                color="primary">{title}
            </GPAP_Button>
        </GPAP_Tooltip>
    </Fragment>


}

export default CreateReportBtn;