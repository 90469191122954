import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText"
import React from "react";




export const tag_dict =
    {
        "sample_tag": <MappedText text={"You have tagged this variant for this experiment. You can edit or delete the tag below."}/>,
        "extra_tag": <MappedText text={"This variant has been tagged for another/other experiment(s). You can still create a new tag for the same variant. We prefilled the options below with the last tag that was reported."}/>,
    }