import React from "react";
import {string_geneConcatenation} from "../functions/stringConcatenation";
import Typography from "@material-ui/core/Typography";
import "../gene-section.css";




export function DataList_View(props){

    let { dataList } = props;

    let title = props.title;
    let label = props.label;



    let data_string = string_geneConcatenation(dataList);
    if(dataList === undefined || dataList.length=== 0)
    {
        return <div className={"geneListBox"}>No Genes to query have been selected yet</div>
    }
    else{
        return (
            <div className={"geneListBox"}
                 style={{background: props.color}}>
                {(title!== undefined)
                    ? <Typography variant={"body1"}>{label} (n={dataList.length})</Typography>
                    : null
                }
            <div className={"selectedItems"}>
                {data_string}
            </div>
        </div>)
    }
}