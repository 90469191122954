
import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from 'react-select';
import IconButton from "@material-ui/core/IconButton";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions";
import {connect} from "react-redux";
import {get_analysis_status, post_analysis_status} from "../../../../../../../../../services/api/get";
import green from "@material-ui/core/colors/green";
import blueGrey from "@material-ui/core/colors/blueGrey";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TableAnalyses from "./TableAnalyses";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import {Alert} from "@material-ui/lab";
import {GPAP_Button} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import GPAP_Dialog_Title from "../../../../../../../../../gpap-lib/components/GPAP_Dialog_Title";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


//Set the styles
const useStyles = makeStyles(() => ({
    paper: { minWidth: "800px" },
}));

const options = [
    { value: 'not_analyzed', label: 'Not Analyzed' },
    { value: 'solved', label: 'Solved' },
    { value: 'unsolved', label: 'Unsolved' },
    { value: 'ongoing', label: 'Ongoing Analysis' },
    { value: 'under_segregation', label: 'Under segregation/ functional studies' },
    { value: 'heterozygous_ar', label: 'Heterozygous variant found in AR disorder' },
    { value: 'unaffected', label: 'Unaffected Relative' },
    { value: 'other', label: 'Other' }
];

function AnalysisCompleteDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState("not_analyzed");
    const [experiment, setExperiment] = React.useState(false);
    const [samples_options, setSamplesOptions] = useState([]);
    const [saved, setSaved] = useState(false);
    const [previous, setPreviousAnalyses] = useState([]);
    const [comments, setComment] = useState("");


    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateData = () => {

        getSampleOptions();
        loadData();

    }

    const loadData = () => {

        if(experiment !== "ALL_SAMPLES"){
            get_analysis_status(config.api_endpoint, props.token, experiment)
                .then(r => {
                    if (!r.ok) {
                        // Handle the error if the HTTP request was not successful (e.g., non-200 status code)
                        throw new Error(`HTTP request failed with status: ${r.status}`);
                    }
                    return r.json(); // Parse the response JSON if the request was successful
                })
                .then(json => {
                    // Process the JSON data here
                    setPreviousAnalyses(json);
                })
                .catch(error => {
                    // Handle any errors that occurred during the HTTP request or JSON parsing
                    console.error("An error occurred:", error);
                    // You can perform error handling here, such as showing an error message to the user
                });

        }

    }

    const handleSave = () => {

        let label = options.find(opt => opt.value === value);

        let data = {"analysed":true,"comment": label.label + " - " + comments};

        post_analysis_status(config.api_endpoint, props.token, experiment, data)
            .then(r => r)
            .then(json => {
                setSaved(true);
                handleClose();
                updateData();
            })


    };

    useEffect(function(){

        let experiment_options = getSampleOptions();
        setSamplesOptions(experiment_options);


    }, [props.samples]);

    useEffect(function(){

        if(experiment && experiment!== "ALL_SAMPLES"){
            get_analysis_status(config.api_endpoint, props.token, experiment)
                .then(r => r.json())
                .then(json => {
                    setPreviousAnalyses(json);
                })
        }

    }, [experiment]);


    const getSampleOptions = () => {

        let {samples} = props;

        let options_samples = samples.map(s => { return {value: s.sample_id, label: s.sample_id}});

        if(options_samples.length!== 0 ){
            setExperiment(options_samples[0].value);
        }

        return options_samples;


    };

    const handleOnChange = (evt) => {

        setValue(evt.target.value)

    };

    // add comments;
    const handleTextChange = (e) => {
        if(e.target.value!== "Leave a comment"){
            setComment(e.target.value)
        }
    };

    const handleExperimentValue = (opt) => {

        setExperiment(opt.value)

    };

    const getSolved = () => {

        let solved= false;
        if(previous.length!== 0){
            // find solved;

            let found = previous.find(a => a.comment.includes("Solved"));
            solved = (found!== undefined) ? found : false;

        }

        if(solved){
            return <Box p={2}>
                <Alert severity="success">This case has been solved by {solved.username} on {solved.date} </Alert>
            </Box>
        }
        else{
            return <Box p={2}>
                <Alert severity="info">The Analysis has not been solved yet</Alert>
            </Box>
        }
    }


    const renderView = () => {

        let samples = props.samples.filter(s => { return s.sample_id !== "ALL_SAMPLES"});

        let color =(saved) ?  green[500]: blueGrey[500];

        let update_text = (saved) ? "[status updated] " : "";

        let title= (previous.length!== 0)
            ? update_text + previous[0].comment + " (" + previous[0].date + ")"
            : "Set Analysis Status";

        if(samples.length!==0){
            return <React.Fragment>
                <GPAP_Tooltip title={title}>
                    <IconButton id="save_status" aria-label= <MappedText text={"Save"} /> onClick={handleClickOpen} >
                        <AssignmentTurnedInIcon style={{ color:  color }}/>
                    </IconButton>
                </GPAP_Tooltip>
                <Dialog open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        classes={{ paper: classes.paper}}>
                    <GPAP_Dialog_Title id="form-dialog-title" onClose={handleClose}>Set Analysis Status</GPAP_Dialog_Title>
                    <Box p={2}>{(previous.length!==0)
                        ? <Typography variant={"subtitle2"}>Experiment {experiment} has already {previous.length} analyses status.</Typography>
                        : <Typography variant={"subtitle2"}>No Analyses reported for sample {experiment} </Typography>}
                    </Box>
                    <Box>
                        {getSolved()}
                    </Box>
                    <DialogContent>
                        <DialogContentText>
                            Previous analyses
                        </DialogContentText>
                        <TableAnalyses data={previous}/>
                        <DialogContentText>
                            Confirm the Analysis Status
                        </DialogContentText>
                        <Box p={2}>
                        <FormLabel component="legend">Select Experiment:</FormLabel>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={(samples_options.length!== 0 ) ? samples_options[0] : {value: "select", label: "select"}}
                            isClearable={true}
                            isSearchable={true}
                            name="Experiment ID"
                            options={samples_options}
                            onChange = {handleExperimentValue}
                        />
                        </Box>
                        <Box p={2}>
                        <FormControl component="fieldset">

                            <FormLabel component="legend">Select Analysis Status:</FormLabel>
                            <RadioGroup aria-label="status" name="status" value={value} onChange={handleOnChange}>
                                {options.map(opt => <FormControlLabel
                                    key={opt.value}
                                    value={opt.value}
                                    control={<Radio />}
                                    label={<Typography variant={"subtitle1"}>{opt.label}</Typography>}
                                />)
                                }
                            </RadioGroup>
                        </FormControl>
                        </Box>
                        <Box p={2}>
                                <FormLabel >Write a comment:</FormLabel>
                                <TextField
                                    style={{width: "100%"}}
                                    id="standard-multiline-static"
                                    label="Comments"
                                    multiline
                                    rows={4}
                                    onChange = {handleTextChange}
                                />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <GPAP_Button onClick={handleSave} color="primary">
                            <MappedText text={"Save"} />
                        </GPAP_Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        }
        else{
            return null;
        }

    };



    // render
    return renderView()
}


function mapStateToProps (state) {

    if(state.authorization!==undefined)
    {

        return {
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            selected_variant: state.selected_variant,
            samples: state.sample_list.samples,
            token: state.authorization.token,
            username: state.authorization.preferredName,
            patients: state.patients}


    }

}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(AnalysisCompleteDialog);