
export const CREATE_ANALYSIS = "CREATE_ANALYSIS";
export const DELETE_ANALYSIS = "DELETE_ANALYSIS";
export const CREATE_STUDY = "CREATE_STUDY";
export const CHANGE_STUDY_NAME = "CHANGE_STUDY_NAME";
export const RENAME_STUDY = "RENAME_STUDY";
export const REMOVE_STUDY = "REMOVE_STUDY";
export const ADD_SAMPLES = "ADD_SAMPLES";
export const CREATE_QUERY = "CREATE_QUERY";
export const DELETE_QUERY = "DELETE_QUERY";
export const SET_STUDY_TYPE = "SET_STUDY_TYPE";
export const CHANGE_QUERY_NAME = "CHANGE_QUERY_NAME";
export const ANNOTATE_VARIANTS = "ANNOTATE_VARIANTS";
export const SET_TAGGED_VARIANTS = "SET_TAGGED_VARIANTS";
export const UPDATE_OUTPUT = "UPDATE_OUTPUT";
export const ADD_ANALYSIS_ID = "ADD_ANALYSIS_ID";
export const ADD_QUERY_ID = "ADD_QUERY_ID";
export const ADD_STUDY_ID = "ADD_STUDY_ID";
export const RESET_STUDY_SETTINGS= "RESET_STUDY_SETTINGS";
export const UPDATE_ANALYSIS_SAMPLES = "UPDATE_ANALYSIS_SAMPLES";
// CREATE ANALYSIS

export const RESET_BUCKET = "RESET_BUCKET";

export function setStudySettings(settings){
    return {
        type: SET_STUDY_TYPE,
        settings: settings
    }
}

export function resetStudySettings(){
    return {
        type: RESET_STUDY_SETTINGS
    }
}

export function create_study(study){
    return {
        type: CREATE_STUDY,
        study: study
    }
}

export function change_study_name(studyInfo){
    return {
        type: CHANGE_STUDY_NAME,
        studyInfo: studyInfo
    }
}

export function addSamples(studyInfo){
    return {
        type: ADD_SAMPLES,
        studyInfo: studyInfo
    }
}

export function rename_study(analysis){
    return {
        type: RENAME_STUDY,
        analysis: analysis
    }
}

export function removeStudy(studyName){
    return {
        type: REMOVE_STUDY,
        studyName: studyName
    }
}

export function create_analysis(analysis){
    return {
        type: CREATE_ANALYSIS,
        analysis: analysis
    }
}

export function update_analysis_samples(samples, analysis_localID, study_id){
    return {
        type: UPDATE_ANALYSIS_SAMPLES,
        analysis_id: analysis_localID,
        study_id: study_id,
        samples: samples
    }
}

export function delete_analysis(analysis_localID, study_id){
    return {
        type: DELETE_ANALYSIS,
        analysis_id: analysis_localID,
        study_id: study_id
    }
}

export function create_query(data){
    return {
        type: CREATE_QUERY,
        query: data.query,
        analysis_id: data.analysis_id,
        study_id: data.study_id
    }
}

export function update_query_output(data){

    return {
        type: UPDATE_OUTPUT,
        query_id: data.query_id,
        analysis_id: data.analysis_id,
        study_id: data.study_id,
        n_variants: data.n_variants
    }
}


export function change_query_name(data){
    return {
        type: CHANGE_QUERY_NAME,
        name: data.name,
        query_id: data.query_id,
        study_id: data.study_id,
        analysis_id: data.analysis_id
    }
}

export function add_study_id_db(data){
    return {
        type: ADD_STUDY_ID,
        localId: data.localId,
        study_id: data.study_id,
    }

}

export function add_query_id_db(data){
        return {
            type: ADD_QUERY_ID,
            query_id: data.query_id,
            localId: data.localId,
            study_id: data.study_id,
            analysis_id: data.analysis_id
    }

}

export function add_analysis_id_db(data){
    return {
        type: ADD_ANALYSIS_ID,
        localId: data.localId,
        study_id: data.study_id,
        analysis_id: data.analysis_id
    }

}


export function delete_query(query, study_id){
    return {
        type: DELETE_QUERY,
        query: query,
        study_id: study_id
    }
}


export function annotate_variants(data){
    return {
        type: ANNOTATE_VARIANTS,
        annotation_type: data.type,
        label: data.label,
        variants: data.variants,
        query_id: data.query_id,
        study_id: data.study_id,
        analysis_id: data.analysis_id,
        operation_type: data.operation_type
    }
}

export function set_samples_tagged_variants(data){
    return {
        type: SET_TAGGED_VARIANTS,
        annotation_type: data.type,
        variants: data.variants,
        query_id: data.query_id,
        study_id: data.study_id
    }
}

export function reset_bucket(){
    return {
        type: RESET_BUCKET
    }
}


