import React, {Component} from "react";

import {bindActionCreators} from "redux";
import "./genotype-form.css"
import * as Actions from "../../../clinical-info/patient_actions";
import * as Sample_Actions from "../../../../reducers/sample_actions";
import {connect} from "react-redux";
import GenotypeRow from "./GenotypeRow";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@material-ui/core";


export function createConfiguration(setting, inheritance_key, configuration){

    let gt_settings = [];
    Object.keys(setting.gt).forEach(d => gt_settings = gt_settings.concat(setting.gt[d]));
    return {
        inheritance_key: inheritance_key,
        setting: gt_settings,
        label: configuration.label
    }


}


export function map_sa_samples(patients, samples_all){

    // get only the participant ids of current samples.

    return samples_all.map(sample => {
        return {
            "case": "index",
            "id": sample.sample_id,
            "sex": "",
            "report_id": "",
            "affected": true,
            dp: sample.dp, gq: sample.gq, ad_low: sample.ad_low, ad_high: sample.ad_high
        };

    });

}



class SearchAcross_GenotypeForm_ extends Component {

    constructor(props){
        super(props);
        this.state = {
            render:true,
            open: false
        };

        this.renderCheckboxes = this.renderCheckboxes.bind(this);
        this.renderHeaders = this.renderHeaders.bind(this);
        this.setOpen = this.setOpen.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.inheritance_key !== this.props.inheritance_key){
            this.setState({render:true})

        }
        else{
            this.setState({render:false})
        }

    }

    setOpen(){
        this.setState({open: !this.state.open})
    }


    // TODO: find a better solution that this;
    shouldComponentUpdate(nextProps, nextState, nextContext) {

        return (nextProps.inheritance_key !== this.props.inheritance_key || nextState.open !== this.state.open || this.props.samples !== nextProps.samples);
    }

   /// create data to render the checkboxes;
    renderCheckboxes(genotypes, configuration){
        let props = this.props;
        let samples_all = props.samples;

        let patients = props.patients;

        // map affected status from patients information...
        let samples = map_sa_samples(patients, samples_all);

        // assign the gt configuration to the samples..

        return samples.map(function (sample_case) {

            let gt = [];
            let gt_sample_configuration;
            gt_sample_configuration = createConfiguration({gt: []}, "custom", configuration);
            // add parameters;
            gt_sample_configuration["dp"] = 10;
            gt_sample_configuration["gq"] = 30;
            gt_sample_configuration["ad_low"] = 0.2;
            gt_sample_configuration["ad_high"] = 0.8;


            return {
                gt: gt,
                gt_parameters: gt_sample_configuration,
                sample_case: sample_case,
                editable: props.editable,
                configuration_label: configuration.label
            }

        });

    }


    renderCheckboxRow(row_data){

        // return all rows;
        return row_data.filter(sample => sample.sample_case.id === "ALL_SAMPLES").map(function(row){
            let {gt, sample_case, editable, gt_parameters} = row;
            return <GenotypeRow  gt_parameters={gt_parameters}
                                 gt={gt}
                                 sample_case = { sample_case}
                                 editable = {editable}/>
        })
    }

    renderHeaders(){

        let conf_labels = [{key: "ID", size: 2},{key: "Case", size:1},{key: "Affected", size: 1},{key: "", size: 2},{key: "", size: 2},{key: "", size:2},{key: "", size: 2}];

        return conf_labels.map(function (label) {
            return <Grid item lg={label.size} key={label.key}>
              <div style={{fontWeight: "500", textTransform: "uppercase"}}>{label.key}</div>
            </Grid>

        });
    }


    render() {

        let self = this;
        let props = this.props;

        let config = props.config;

        // if cohort analysis, show a fake ALL_SAMPLES Genotype row

            let gt_select_box = config.map(function (configuration) {

                let genotypes = configuration.genotypes;
                let cbx = [];
                if (genotypes.length !== 0) {
                    cbx = self.renderCheckboxes(genotypes, configuration, props.editable);
                }

                return (
                    <div style={{marginTop: "2%"}}>
                        <Grid container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={3}>
                                    {self.renderHeaders()}
                            <Grid item lg={12}>
                                <Divider/>
                            </Grid>
                                    {self.renderCheckboxRow(cbx)}
                        </Grid>
                    </div>
                )
            });

            return <div id="genotype_builder">
                {gt_select_box}
            </div>;



    }


}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {samples: state.sample_list.samples, token: state.authorization.token}
    }
    else{
        return {samples:[], token: state.authorization.token}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    samples_actions: bindActionCreators(Sample_Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(SearchAcross_GenotypeForm_);