

import React, {Fragment, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../dashboard/reducers/study_actions";
import * as Sample_Actions from "../create-study/analysis-page/reducers/sample_actions";
import * as InheritanceActions from "../create-study/analysis-page/reducers/inheritance_actions";
import * as QueryActions from "../../../actions/index";
import * as GeneActions from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions.js";
import * as Patient_Actions from "./analysis-page/components/clinical-info/patient_actions";

import * as GeneralActions from "../../../actions/index";

import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import { useHistory } from 'react-router-dom'
import GPAP_CardButton from "../../../gpap-lib/components/GPAP_CardButton";
import {query_sample} from "../../../services/api/query_sample";
import {date_generator} from "../../func/helpers";
import * as PositionActions
    from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/position_actions";

import {useTranslation} from "react-i18next";



function StudyCreator(props){


    const { t, i18n } = useTranslation();


    const [redirect, setRedirect] = useState(false);
    const [view, setView] = useState(null);
    const history = useHistory();
    const colors = ['#4292c6','#2171b5','#08519c','#08306b', "#034e7b", "#BE3E82"];
    
    const matchMaker = () => {
        history.push(`/matchmaker`);
      };


    const resetQuery = () => {

        let { queryActions, geneActions, positionActions } = props;

        queryActions.updateQuery(query_sample);
        geneActions.resetGenes();
        positionActions.reset_ranges();

    };


    const createStudy = (viewMode)=>{

        let { studyActions }  = props;

        if(props.disableStudyCreation){

            let settings = {analysisType: viewMode, study_status: false};
            studyActions.setStudySettings(settings);
            setRedirect(true);
            setView(viewMode);

            // add an analysis instead;
        }
        else{

            props.sampleActions.resetSamples();
            props.patientActions.reset_patients();
            props.generalActions.reset_filter_set();
            props.queryActions.reset_selected_cohort();
            studyActions.resetStudySettings();


            // create the study;
            let localID = Math.floor(Math.random()*200);
            let date = date_generator();
            let sampleStudy = {
                "localId": localID,
                "studyName": "New GPAP Study",
                "description": "No description",
                "creationDate": date,
                "samples": [],
                "analyses": [],
                "tagged_variants": [],
                "info":[]
            };
            studyActions.create_study(sampleStudy);
            let settings = {analysisType: viewMode, id: localID};
            studyActions.setStudySettings(settings);

            // also reset geneList;
            resetQuery()
            setRedirect(true);
            setView(viewMode);

        }

    };


    const createTypeStudy = (type) => {

        switch (type) {
            case "family_trio":
                createStudy("family_trio");
                props.inheritanceActions.setInheritance([]);
                break;
            case "mme_view":
                createStudy("mme_view");
                break;
            case "search_across":
                createStudy("search_across");
                props.inheritanceActions.setInheritance("custom");
                break;
            case "cohort":
                createStudy("cohort");
                props.inheritanceActions.setInheritance("custom");
                break;
            case "clinical_report":
                setRedirect(true);
                setView("clinical_report");
                break;
            case "instand":
                //createStudy("instand");
                setRedirect(true);
                setView("instand");
        }



    };


    const renderView = () => {


        if(redirect && view === "family_trio"){
            return <Redirect to={{
                pathname: "new_study",
                state:{
                    key:"family"
                }
            }}/>;
        }
        else if (redirect && view === "mme_view"){
            return <Redirect
                to={{
                    pathname: "matchmaker",
                    state:{
                        key:"mme"
                    }
                }}/>;
        }
        else if (redirect && view === "cohort"){
            return <Redirect to={{
                pathname: "new_study",
                state:{
                    key:"cohort"
                }

            }}/>;
        }
        else if (redirect && view === "search_across"){
            return <Redirect to={{
                pathname: "new_study",
                state:{
                    key:"search_across"
                }
            }}/>;
        }
        else if (redirect && view === "clinical_report"){
            window.location.href = window.location.origin + "/report/"
        }
        else if (redirect && view === "instand"){
            return <Redirect to={{
                pathname: "dir_list",
                state:{
                    key:"instand"
                }
            }}/>;
        }
        else{
            if(props.default){
                let size = 2;
                // TODO: create an array of object and map;
                return <Fragment>
                    <Grid item lg={size}>
                        <GPAP_CardButton
                            text={t("Case Analysis")}
                            description={t("Analyse a singleton or a family")}
                            type={"family_trio"}
                            gpap_action={createTypeStudy}
                            color={colors[0]}
                        />
                    </Grid>
                    <Grid item lg={size}>
                        <GPAP_CardButton
                            text={t("Cohort Analysis")}
                            description={"Load and analyse a cohort"}
                            type={"cohort"}
                            gpap_action={createTypeStudy}
                            color={colors[1]}
                        />
                    </Grid>
                    <Grid item lg={size}>
                        <GPAP_CardButton
                            disable={false}
                            text={t("Search Across All Experiments")}
                            description={"Currently not available."}
                            type={"search_across"}
                            gpap_action={createTypeStudy}
                            color={colors[2]}
                        />
                    </Grid>
                       <Grid item lg={size}>
                        <GPAP_CardButton
                            text={t("Patient Matchmaking")}
                            description={"Use Matchmaker Exchange (MME) to find similar patients in other databases"}
                            type={"mme_view"}
                            gpap_action={createTypeStudy}
                            color={colors[3]}
                        />
                    </Grid>
                       <Grid item lg={size}>
                        <GPAP_CardButton
                            text={t("Clinical Report")}
                            description={"Create and export a draft of a clinical report"}
                            type={"clinical_report"}
                            gpap_action={createTypeStudy}
                            color={colors[4]}
                        />
                    </Grid>
                    {
                        (window.config.instand) ? 
                          <Grid item lg={size}>
                            <GPAP_CardButton
                                text={"INSTAND TEST"}
                                description={"HEY"}
                                type={"instand"}
                                gpap_action={createTypeStudy}
                                color={colors[5]}
                            />
                        </Grid>
                            : null
                    }

            </Fragment>
            }
            else{
                let analysis_type = "";
                let editItem = null;
                if(props.samples.length!==0){
                    analysis_type = props.studySettings.analysisType;
                    if(analysis_type!== undefined){
                        let stringAnalysis = analysis_type.replace("_", " ").toUpperCase();
                        editItem =  <Grid item lg={3}>
                            <div style={{width: '100%', textAlign: "left"}}>
                                <GPAP_Button
                                    style={{width: '300px'}}
                                    variant="contained"
                                    color="primary" onClick={() => {createStudy(analysis_type)}}>Edit {stringAnalysis}  </GPAP_Button>
                            </div>
                        </Grid>
                    }
                    else{
                        editItem = null;
                    }

                }

                return <Fragment>
                    {editItem}
                </Fragment> }
        }




    };


    return renderView();





}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {token: state.authorization.token, samples: state.sample_list.samples, studySettings: state.studySettings}
    }
}
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch),
    sampleActions: bindActionCreators(Sample_Actions, dispatch),
    patientActions: bindActionCreators(Patient_Actions, dispatch),
    inheritanceActions: bindActionCreators(InheritanceActions, dispatch),
    queryActions: bindActionCreators(QueryActions, dispatch),
    geneActions: bindActionCreators(GeneActions,dispatch),
    positionActions: bindActionCreators(PositionActions, dispatch),
    generalActions: bindActionCreators(GeneralActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(StudyCreator);