import * as d3 from "d3";


function getPercentage(val, max_score){

    return (parseFloat(val)/max_score*100).toFixed(0)
}

function gradient(value1, max_score, gradientArray){

    let scale = d3.scaleLinear()
        .domain([0, max_score])
        .range(gradientArray);

    return scale(value1);


  /*  return getPercentage(value1, max_score)> 66 ? gradientArray[2]
        : getPercentage(value1, max_score) > 33 && getPercentage(value1, max_score) <66 ? gradientArray[0]
            : gradientArray[1]*/

}

function gradientCADD(value1, max_score, gradientArray){

    return getPercentage(value1, max_score)> 0 ? gradientArray[2]
        : getPercentage(value1, max_score) > 25 && getPercentage(value1, max_score) <30 ? gradientArray[1]
            : gradientArray[0]

}


export {getPercentage, gradient, gradientCADD}