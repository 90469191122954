


import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GPAP_Tooltip from "../../../../../../gpap-lib/components/GPAP_Tooltip";

const useStyles = makeStyles({
    table: {
        minWidth: 0,
    },
});

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
function dynamicSort(property) {
    let sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
    }
}


export default function GeneAggTable(props) {
    const classes = useStyles();
    const [row_selected, setSelected] = useState(-1);

    useEffect(function(){
        if(props.data.hasOwnProperty("total")){
            if(props.totalVariants!== props.data.total){
                //
                //setSelected(-1);
            }
        }


    },[props.totalVariants])

    const handleClick = (event, row_name) => {

        const sel_index = row_selected !== row_name;

        let newSelected = -1;
        if (sel_index) {
            newSelected = row_name;
            props.runSearchAcrossQuery( row_name )
        }
        else{
            newSelected = -1;
        }

        setSelected(newSelected);

    };

    const isSelected = (name) => row_selected === name;


    // sort rows

    // Sort the MyData array with the custom function
// that sorts alphabetically by the name key
    props.rows.sort(dynamicSort("gene"));




    return (<TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Gene</TableCell>
                        <GPAP_Tooltip title={"All variants found..."}>
                            <TableCell align="left">
                            # Pos.</TableCell>
                        </GPAP_Tooltip>
                        <GPAP_Tooltip title={"All Experiments found..."}>
                            <TableCell align="left"># Exp.</TableCell>
                        </GPAP_Tooltip>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, index) => {
                        const isItemSelected = isSelected(row.gene);
                        return (  <TableRow key={row.gene}
                                      hover
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      selected={isItemSelected}
                                      onClick={(event) => handleClick(event, row.gene)}>
                                <TableCell align="left" component="th" scope="row">{row.gene}</TableCell>
                                <TableCell align="left">{row.n_var}</TableCell>
                                <TableCell align="left">{row.n_exp}</TableCell>
                            </TableRow> )}
                    )}
                </TableBody>
            </Table>
        </TableContainer>);
}