

import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import PatientCard from "./PatientCard/PatientCard";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TabPanel from "../../../../../../gpap-lib/components/GPAP_TabPanel";
import PersonIcon from "@material-ui/icons/Person";


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

function PatientTabs(props){

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let { patients, phenostore_participants } = props;


    const createTabs = (patients) => {

        let uniqueObjArray = [
            ...new Map(patients.map((item) => [item["pheno_id"], item])).values(),
        ];

        return uniqueObjArray
            .filter(s => Object.keys(s).length>0)
            .map(function (p, index) {

                let icon = (p.index === "Yes") ? <PersonIcon/> : <PersonOutlineIcon/>
                return <Tab key={index} label={p.pheno_id} icon={icon}/>;
        })
    };

    const createTabPanels = (patients, phenostore_participants) => {

        //TODO: not optimal solution but works

        let uniqueObjArray = [
            ...new Map(patients.map((item) => [item["pheno_id"], item])).values(),
        ];

        return uniqueObjArray
            .filter(s => Object.keys(s).length>0)
            .map(function (p, index) {
                return  <TabPanel value={value} index={index} key={index}>
                        <PatientCard phenostore_participants = { phenostore_participants }
                                     sampleID={ p.pheno_id }
                                     experiment_id = {p.sample_id}
                        />
                    </TabPanel>

            });
    };


    return(
        <Box>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto">
                    {createTabs(patients)}
                </Tabs>
                {createTabPanels(patients, phenostore_participants)}
            </Paper>

        </Box>
    )



}



export default PatientTabs;