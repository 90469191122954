import {AlignmentType, Paragraph, Table, TableCell, TableRow, TextRun, WidthType} from "docx";
import {bodyText, table_margins} from "../../config/template_config";
import { renderToString } from "../../../create-report/shared-components/renderToString";



function writeHeaders(headerNames) {
    return [
        new TableRow({
            tableHeader: true,
            children: headerNames.map(text => {
                const localizedText = renderToString(text);
                return new TableCell({
                    children: [new Paragraph({ children: [new TextRun({ text: localizedText, bold: true })] })],
                    width: {
                        size: 4505,
                        type: WidthType.DXA,
                    },
                    margins: table_margins,
                });
            })
        })
    ];
}

function writeRows(data, dataKeys) {
    return data.map(function (element) {
        let cells = dataKeys.map(key => {

            // TODO: check with real data...that the keys are correct...
            let value = (element[key] !== undefined) ? element[key]  :"NA";

            return new TableCell({
                children: [new Paragraph(value)],
            });
        });

        return new TableRow({
            tableHeader: true,
            children: cells,
        });
    });
}


function drawBasicTable(data, headerNames, dataKeys, no_data_text) {

        if (data.length !== 0) {
            const headers = writeHeaders(headerNames);
            const dataRows = writeRows(data, dataKeys);
            const rows = headers.concat(dataRows);

            return new Table({
                columnWidths: [1000, 500], // Adjust column widths as needed
                rows: rows,
                alignment: AlignmentType.LEFT,
            });
        } else {
            return new Paragraph({
                children: [bodyText(no_data_text)],
                alignment: AlignmentType.LEFT,
                spacing: { after: 10, before: 10 }
            });
        }
}


export { drawBasicTable }