


import React, {useEffect, useState} from "react";
import {get_alfa} from "../../../../../../../../../services/api/variant_external_calls";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



function AlfaDB(props){

    const [data, setData] = useState(false);


    useEffect(async function () {

        const L = props.selected_variant.length;
        let variant = props.selected_variant[L - 1];

        let {chrom, alt, ref, pos} = variant;

        //ALFA (make a separate component?)
        let alfa = await get_alfa(chrom, pos, pos, ref, alt).then(r => r.json());

        setData(alfa);

    }, [props.selected_variant])


    return  <div className={"my_paper_box"}>
        <Box p={1} >
            <Typography variant={"subtitle1"}>Alfa - ALamut Functional Annotations</Typography>
            <div>  {(data && data.success === true) ? <Link target="_blank" href={(data.url_link)}>Alfa Annotation</Link>: <MappedText text="No Associations"/>} </div>
        </Box>
    </div>





}

function mapStateToProps (state) {
    if(state.selected_variant!==undefined)
    {
        return {
            token: state.authorization.token,
            selected_variant: state.selected_variant,
            patients: state.patients}
    }
    else{
        return {snv: []}
    }
}

export default connect(mapStateToProps,null)(AlfaDB);