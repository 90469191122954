const pharmacogenomicsData =
    [
        {
            "gene": "NA",
            "genotype": "NA",
            "phenotype": "NA",
            "haplotype1": "NA",
            "haplotype2": "NA",
            "variant_data": "NA"
        },
        {
            "gene": "NA",
            "genotype": "NA",
            "phenotype": "NA",
            "haplotype1": "NA",
            "haplotype2": "NA",
            "variant_data": "NA"
        }
    ]
    export default pharmacogenomicsData;