
import React from "react";
import {Grid, Skeleton, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import Box from "@mui/material/Box";
import CreateReportBtn from "../../study-page/create-study/analysis-page/analysis-summary/components/CreateReportBtn";
import {useSelector} from "react-redux";
import ClinicalReportTable from "./ClinicalReportTable";
import PatientReportTable from "./PatientReportTable";
import AddIcon from '@material-ui/icons/Add';
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";



function Reports(props) {


    const participants = useSelector(state => state.generaltable.allDataApis.participants);

    let participant_id = (Array.isArray(participants) && participants.length>0) ? participants[0].report_id : undefined;

    let disable = participant_id === undefined;



    return <div style={{width: "100%", height: "500px"}}>
        <Grid container direction={"row"}>
        
            <Grid item lg={12}>
                <Box  display="flex" alignItems="center" justifyContent="flex-start" >

                
                    <Typography variant={"h5"}  style={{ marginRight: "300px" , fontWeight: "700" }}><MappedText text={"Clinical Reports"}/></Typography>
                    {/* <CreateReportBtn
                        disable={disable}
                        participant_id={participant_id}
                        label={"Create Report version"}
                        title={<><AddIcon style={{ color: 'white', marginRight: '8px' }} /><MappedText text={"Create report"}/></>}
                        tooltipText= <MappedText text={"Create a new report for this DIR"} />
                    /> */}

                </Box>   
            </Grid>
                <ClinicalReportTable />
            </Grid>
            <Grid item lg={12}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" pt={4}>
                    <Typography variant={"h5"} style={{ marginRight: "455px", fontWeight: "700" }}><MappedText text={"Patient reports"}/></Typography>
                    <CreateReportBtn
                        disable={disable}
                        participant_id={participant_id}
                        label={"Create Report version"}
                        title={<><AddIcon style={{ color: 'white', marginRight: '8px' }} /><MappedText text={"Create report"}/></>}
                        tooltipText=<MappedText text={"Create a new report for this DIR"} />
                    />
                </Box>
                <PatientReportTable />
            </Grid>
        
    </div>


}


export default Reports;