



import React from "react";
import RenderPatientCard from "./renderPatientCard";
import {LinearProgress} from "@material-ui/core";



const PatientCard = (props) => {

    let phenostore_id = "report_id";

    let {sampleID, phenostore_participants} = props;

    // move index participant in front;

    let pid_case = phenostore_participants.find(s => s[phenostore_id] === sampleID);
        if (pid_case!== undefined) {
            return <RenderPatientCard participant={pid_case} experiment_id = {props.experiment_id}/>
        }
    return  <div>
                <LinearProgress/>
            </div>
};



export default PatientCard;