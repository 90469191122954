

/* FAMILY ANALYSIS: STEP BY STEP STUDY CREATION - CONFIGURATION  */


const step_form_labels = [
    "Select experiments",
    "Review case information",
    "Select inheritance mode",
    "Apply filters"];

const section_titles = [
    "Select experiments to analyse",
    "Review case information",
    "Select inheritance mode",
    "Apply filters"
]



export {step_form_labels, section_titles}