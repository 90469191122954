import React, {useEffect, useState} from 'react';
import {Field, initialize, reduxForm} from 'redux-form';
import "../../../css/query-view.css";

import { connect } from 'react-redux'
import {SelectArea, TextArea, TextFieldForm} from "../../../../../../../../../../gpap-lib/components/shared-components/renderer";

import "./population-section.css";
import {GPAP_Button_light} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import Grid from "@material-ui/core/Grid";
import {CircularProgress} from "@material-ui/core";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


const useMount = (callback) => {
    React.useEffect(callback, [])
}

let Population_Section = props => {

    const { handleSubmit, pristine, reset, submitting, minimized } = props;


    const [resetForm, setResetForm] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const resetVals = () => {

        let resetVals =  {
            'gp1_af': null,
            'gnomad_af': null,
            'freqInt': null
        };
        props.dispatch(initialize(
            'population',
            resetVals,
        ));

        setResetForm(true);
        setInitialized(true);
    };

    useMount(() => {

    })

    const [init_obj_statefull, setinit_obj] = useState({});

    useEffect(function(){


        if(props.default_filters!== undefined){
            if(props.default_filters){
                let obj = {...props.default_filters};

                let keys = Object.keys(obj);
                let init_obj = {};
                keys.forEach(function(key){
                    init_obj[key] = obj[key];
                });
                if ( JSON.stringify(init_obj_statefull) !== JSON.stringify(init_obj)){
                    console.log("dispatch action");
                  
                  setinit_obj(init_obj);
                if(Object.keys(init_obj).length>0){
                    props.dispatch(initialize(
                        'population',
                        init_obj,
                    ));
                }}
            }
            else{
                //reset
                resetVals();
            }
        }

    },[props.default_filters]);



    const renderForm = () => {

        if(resetForm){

            setTimeout(() => { setResetForm(false)}, 1000);
            return <div style={{width: "600px", height: "500px"}}>
                <CircularProgress/>
            </div>

        }
        else{
            return  <Grid container alignItems={"center"} direction={"column"}>
                <Grid item lg={12}>
                    <div className="box-body">
                        <Field name={'gnomad_af'}
                               helperText = <MappedText text={"A number between 0-1"}/>
                               label= {"GNOMAD AF"} 
                               component={TextFieldForm}
                               defaultValue={"gnomad_af"}/>
                    </div>
                </Grid>
                {

                    (false)
                        ? <Grid item lg={12}>
                        <div className="box-body">
                            <Field
                                helperText = {"A number between 0-1"}
                                name={'gp1_af'} label= {"1000Genomes AF"}
                                component={TextFieldForm}
                                defaultValue={"1000Genomes AF"}/>
                        </div>
                    </Grid>
                        : null
                }
                <Grid item lg={12}>
                    <div className="box-body">
                        <Field
                            helperText = <MappedText text={"A number between 0-1"}/>
                            name={'freqInt'}
                            label= <MappedText text={"INTERNAL FREQUENCY"}/>
                            component={TextFieldForm}
                            defaultValue={"freqInt"}/>
                    </div>
                </Grid>
            </Grid>
        }

    }


    return (
        <div className={"form_section"}>
            <div className={'bar'}>
                <GPAP_Button_light size="sm" disabled={pristine || submitting} onClick={reset}>
                    <MappedText text={"Clear Filters"}/>
                </GPAP_Button_light>
                </div>
            <form onSubmit={handleSubmit}>
                <div className={"form_container"}>
                    {renderForm()}
                </div>
            </form>
        </div>
    )
};



export default reduxForm({
    form: 'population', // a unique identifier for this form,
    destroyOnUnmount: true,
    keepDirtyOnReinitialize: false,
    updateUnregisteredFields: true,
    initialValues: {
        'gp1_af': null,
        'gnomad_af': null,
        'freqInt': null
    }
})(Population_Section)


// TODO: check how to initialize the form;
Population_Section = connect(() => ({
}))(Population_Section);
