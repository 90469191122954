


export function post_variant(urlprefix, token, data){
    return fetch(urlprefix + "/participant/post_variants",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}


export function update_variants(urlprefix, token, data, string){
    return fetch(urlprefix + "/participant/tag_variants/" + string,
        {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}


export function delete_variants(urlprefix, token, data, string){
    return fetch(urlprefix + "/participant/tag_variants/" + string,
        {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}

    )
}

export function get_sample_variants(urlprefix, token, string){
    return fetch(urlprefix + "/participant/tag_variants/" + string,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}


export function get_all_variants(urlprefix, token){
    return fetch(urlprefix + "/get_all_variants",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}