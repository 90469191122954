export const checkCustomGenotype = (samples, inheritance, setCustomNoGQ) => {
    setCustomNoGQ(false);
  
    if (inheritance.includes("custom")) {
      const hasInvalid = samples.some(sample => {
        // Check if gt_settings is an empty array
        if (sample.gt_settings.length === 0) {
          return true;
        }
  
        // Check if there is a custom setting with an empty array
        const customSetting = sample.gt_settings.find(
          setting => setting.inheritance_key === "custom"
        );
  
        return !customSetting || (Array.isArray(customSetting.setting) && customSetting.setting.length === 0);
      });
  
      if (hasInvalid) {
        setCustomNoGQ(true);
      }
    }
  };
