import React, {useMemo, useState, useCallback} from "react";
import { filters } from "./utils/filters";
import DefaultColumnFilter from "./Filters/DefaultColumnFilter";
import {
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import useOptionFilter from "./hooks/useOptionFilter";
import {
  Button,
  IconButton,
  Pagination,
  Select,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
} from "@mui/material";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { addTooltipRow } from "./utils/addTooltipRow";
import SimpleTable from "./SimpleTable";
import styled from "@emotion/styled";
import ButtonGroupCustom from "./buttons/ButtonGroupCustom";
import { ArrowDownward, ArrowUpward, Info } from "@mui/icons-material";
import TagDialog from "../../../toolbar/tag_dialog/TagDialog";
import {variantFinder} from "../variant_finder";


import BootstrapTooltip from "../../../../../../../../../../gpap-lib/components/BootstrapTooltip";

import SamplesPopOver from "../components/SamplesPopOver";
import {useSelector} from "react-redux";
import { CNVPreviousTags } from "./buttons/CNVPreviousTags";
import CandidatePopOver from "../components/CandidatePopOver";
import IGVLinks from "../components/IGVLinks";



function TableComponentForVariants({
  columns,
  data,
  type,
  nestedColumns,
  buttons,
  states,
  tooltipCompact,
  renderFeatures,
  chipFull,
  
}) {

  const [expandedRows, setExpandedRows] = useState({});

  const DATA_TYPE = "CNV";
  const filterTypes = useMemo(filters, []);

  // get from REDUX

  // TODO:return selected_row;

  const selected_table_row = useSelector(state => state.selected_table_rows.find(s => s.data_type === DATA_TYPE));

  // the selected_variant object;
 const selected_variant = (selected_table_row !== undefined) ? selected_table_row.data_row : [];
 const samples = selected_variant[0]?.samples_somatic || [selected_variant[0]?.Experiment_ID] || [];

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const sortNum = React.useCallback((rowA, rowB, columnId) => {
    const valA = rowA.values[columnId];
    const valB = rowB.values[columnId];
    const numA = parseFloat(valA);
    const numB = parseFloat(valB);
  
   
    if (!isNaN(numA) && !isNaN(numB)) {
     
      return numA > numB ? 1 : numA < numB ? -1 : 0;
    } else {
    
      return ('' + valA).localeCompare('' + valB, undefined, {numeric: true});
    }
  }, []);


  const chromosomeSort = useCallback((rowA, rowB, columnId) => {

    const getOrder = (chrom) => {
      if (chrom === 'X') return 24;
      if (chrom === 'Y') return 25;
      return parseInt(chrom, 10); 
    };
  
    const valA = getOrder(rowA.values[columnId]);
    const valB = getOrder(rowB.values[columnId]);
  
    return valA > valB ? 1 : (valA < valB ? -1 : 0);
  }, []);

  const formatNumber = useCallback((number, decimals) => {
    if (number !== null && number !== undefined) {
      const strNumber = number.toString();
      const decimalPointIndex = strNumber.indexOf('.');
      
      if (decimalPointIndex !== -1) {
      
        const decimalPartLength = strNumber.length - decimalPointIndex - 1;
        if (decimalPartLength > decimals) {
         
          return number.toFixed(decimals);
        }
        return number.toString();
      }
      return number.toString();
    }
    return "";
  }, []);
  const geneSort = useCallback((rowA, rowB, columnId) => {
    const extractFirstGene = (geneValue) => {
      if (!geneValue) return ''; 
      const geneString = String(geneValue); 
      const genes = geneString.split(/,|\s/).filter(Boolean); 
      return genes[0].toUpperCase(); 
    };
  
    const valA = extractFirstGene(rowA.values[columnId]);
    const valB = extractFirstGene(rowB.values[columnId]);
  
    return valA.localeCompare(valB); 
  }, []);
  const numColumns = useMemo(() => {
    return columns.map(group => {
      const newColumns = group.columns.map(column => {
        if (column.accessor === "Internal_Freq") {
          return {
            ...column,
            sortType: sortNum,
            Cell: ({ value }) => (
              <BootstrapTooltip title={value.toString()}>
              <span style={{ 
                textDecoration: 'underline', 
                textDecorationStyle: 'dotted', 
                textDecorationColor: '#BDBDBD',
                textUnderlineOffset: '3px' 
              }}>{formatNumber(value, 6)}</span>
              </BootstrapTooltip>
            )
          };
        } else if (column.accessor === "Gene_name") {
          return {
            ...column,
            sortType: geneSort,
          };
        } else if (column.accessor === "SV_chrom") {
          return {
            ...column,
            sortType: chromosomeSort,
          };
        } else {
          const isNumeric = data.every(item => 
            item[column.accessor] !== undefined && 
            !isNaN(parseFloat(item[column.accessor])) && 
            isFinite(item[column.accessor])
          );
          if (isNumeric) {
            return {
              ...column,
              sortType: sortNum,
              Cell: ({ cell }) => {
                const { content, truncated } = addTooltipRow(cell, tooltipCompact, data);
                return (
                  <BootstrapTooltip title={truncated ? content : ""}>
                    <span>{cell.value}</span>
                  </BootstrapTooltip>
                );
              }
            };
          } else {
            return column;
          }
        }
      });
  
      return {
        ...group,
        columns: newColumns,
      };
    });
  }, [columns, sortNum, chromosomeSort, geneSort, data]);
  

  const getAdditionalInfo = async (row) => {
    return !row.isExpanded;

  };
  
  const isFlagged = (row) => {
    // chrom ,  start_pos, gene

    let annotations = undefined;
    let tagged_variants = states.tagged_variants;

    let variant_found_cnv = variantFinder(row.original, annotations, tagged_variants, "CNV");
    if (variant_found_cnv !== undefined && variant_found_cnv.length !== 0) {
        return variant_found_cnv;
    }

    let variant_found_somatic = variantFinder(row.original, annotations, tagged_variants, "SNV_Somatic");
    if (variant_found_somatic !== undefined && variant_found_somatic.length !== 0) {
        return variant_found_somatic;
    }

    return false;

  };

  const getSamples = () => {


  /*  if(Array.isArray(selected_variant) && selected_variant.length>0){

      let variant = selected_variant[0];
      // somatic samples
      return variant["variant"].samples_somatic;

    }
    else{
      return []
    }*/

    if(selected_table_row){

      if(Array.isArray(selected_table_row.data_row) && selected_table_row.data_row.length>0){

        let selected_row = selected_table_row.data_row;
        // somatic samples
        return selected_row.samples_somatic;

      }
      else{
        return []
      }


    }


  }


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize, selectedRowIds },
    resetResizing,
  } = useTable(
    {
      columns: numColumns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: false,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useBlockLayout,
    useResizeColumns,
    useOptionFilter(columns, type, states, setExpandedRows, data)
  );

  const firstPageRows = page;


  return (
    <>
    <Stack direction="row" spacing={2}>
      <TagDialog
          annotate_variants = {(label, array, type) => console.log(type)}  // edit. See how to add tagged variants to the store
          tagged_variants={ states.tagged_variants}
          getAllTaggedVariants = {states.getAllTaggedVariants}
          dataType = {DATA_TYPE}
      />
      <CNVPreviousTags  tagged_variants={states.tagged_variants} />
      <IGVLinks samples={samples} dataType={DATA_TYPE} />
      {/* <SamplesPopOver samples = { getSamples() } data_type={"somatic"}/>  */}
      <ButtonGroupCustom
        allColumns={allColumns}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        resetResizing={resetResizing}
        buttons={buttons}
        states={states}
        renderFeatures={renderFeatures}
      />
        </Stack>

      <br />

      <TableContainer>
      <MaUTable {...getTableProps()}>
<TableHead>
{headerGroups.map((headerGroup, headerGroupIndex) => (
<React.Fragment key={headerGroup.id}>
  <TableRow 
    sx={{
    backgroundColor: headerGroupIndex === 0 ? '#3E69A9' : '#EAEAEA'}} 
    {...headerGroup.getHeaderGroupProps()}
  >
    {headerGroup.headers.map((column, columnIndex) => (
      <TableCell {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))} sx={{ borderRight: '1px solid #C4C4C4', '&:last-child': { borderRight: '0' }, position: 'sticky', top: 0, zIndex: 1  }}>
        <div style={{ color: headerGroupIndex === 0 ? 'white' : 'inherit' }}>
        {column.tooltip ? (
                     <BootstrapTooltip title={column.tooltip} forceUnderline={true}>
                     {column.render("Header")}
                   </BootstrapTooltip>
                   
                    ) : (
                column.render("Header")
              )}
          <span>
            {column.isSorted ? (
              column.isSortedDesc ? (
                <IconButton>
                  <ArrowUpward />
                </IconButton>
              ) : (
                <IconButton>
                  <ArrowDownward />
                </IconButton>
              )
            ) : (
              ""
            )}
          </span>
        </div>
     
      </TableCell>
    ))}
  </TableRow>
  {headerGroup.headers.some((column) => column.canFilter) ? (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column) => (
       <TableCell {...column.getHeaderProps()} sx={{ 
          display: 'flex!important',  
          alignItems: 'center!important',  
          justifyContent: 'left!important', 
          position: 'sticky', top: 0, zIndex: 1,
         }}    >
          {column.canFilter && column.filterType && column.filterType.type !== 'none' ? column.render('Filter') : null}
        </TableCell>
      ))}
    </TableRow>
  ) : null}
</React.Fragment>
))}
<TableRow style={{ display: "none" }}>
<TableCell
  colSpan={visibleColumns.length}
  style={{textAlign: "left",}}
></TableCell>
</TableRow>
</TableHead>

          <TableBodyCustom
            rowId={states.idExpanded.id}
            {...getTableBodyProps()}
          >
            
            {firstPageRows.map((row, i) => {

              row.isFlagged = isFlagged(row);
              prepareRow(row);
              return (
                <>
                  <TableRowCustom
                    className={`row${row.id}`}
                    {...row.getRowProps()}
                    rowId={states.idExpanded.id}
                    states={states}
                    expanded={states}
                    onClick={() => {

                      states.setSelected(row.original);

                      if (states.idExpanded.id !== null) {
                        const currentExpandedRow = firstPageRows.find(
                            (r) => r.id === states.idExpanded.id
                        );
                        if (currentExpandedRow && !states.idExpanded.selected) {
                          currentExpandedRow.toggleRowExpanded(false);
                          states.setRowIdSelected(null);
                          states.setIdExpanded({ id: null, expanded: false });
                        }
                      }

                      getAdditionalInfo(row).then((res) => {
                        if (res) {

                          row.toggleRowExpanded(true);
                          states.setIdExpanded({ id: row.id, expanded: true, selected: true });
                          states.setRowIdSelected(row.id);

                        }
                      });
                    }}
                    onMouseEnter={() => {
                      document.querySelector(
                        `.row${row.id}`
                      ).style.backgroundColor = "#EAEAEA";
                      document.querySelector(`.row${row.id}`).style.cursor =
                        "pointer";
                    }}
                    onMouseLeave={() => {
                      document.querySelector(
                        `.row${row.id}`
                      ).style.backgroundColor = "#fff";
                    }}
                  >
                    {row.cells.map((cell) => { 
                      const { truncated, content } = addTooltipRow(
                        cell,
                        tooltipCompact,
                        data
                      );

                      return (
                          <TableCell {...cell.getCellProps()}>

                         <BootstrapTooltip 
                            title={truncated ? `${cell.value}` : ""} 
                            isTruncated={truncated}
                          >
                          <div style={truncated ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' } : {}}>

                              {(() => {
                                const matchedData = chipFull
                                  ?.flatMap((item) => item.cells)
                                  .find((data) => data.data === cell.value);
                                if (matchedData) {
                                  return (
                                    <Chip
                                        size="small"
                                        label={content}
                                        sx={{
                                        backgroundColor: matchedData.color,
                                        color: matchedData.textColor,
                                      }}
                                    />
                                  );
                                }
                                if (typeof cell.column.Header === "function") {
                                  return content;
                                }
                           
                                return (
                                  <div>
                                    {cell.value ? (
                                      content
                                    ) : (
                                      <>N/A</>
                                    )}
                                  </div>
                                );
                                
                              })()}
                            </div>
                          </BootstrapTooltip>
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>

                  {row.isExpanded && expandedRows[row.id]?.expanded ? (
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "#F9F9F9" }} colSpan={visibleColumns.length}>
                        {expandedRows[row.id]?.data ? ( 
                          <SimpleTable
                            columns={nestedColumns}
                            data={expandedRows[row.id].data} 
                            states={states}
                          />
                        ) : (

                          <div>Loading...</div>
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBodyCustom>
        </MaUTable>
      </TableContainer>

      <br />

      <div style={{ display: "flex", alignItems: "center", height: "50px", justifyContent: "space-between"}}>

      <TablePagination
      component="div"
      rowsPerPageOptions={[10, 25]}
      count={ pageCount}
      rowsPerPage={pageSize}
      page={pageIndex}
      onPageChange={(event, newPage) => gotoPage(newPage)}
      onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
      style={{ '& .MuiTablePagination-actions': { display: 'none' } }}
      />

      <Pagination
      count={pageCount}
      page={pageIndex + 1}
      onChange={(event, value) => gotoPage(value - 1)}
      showFirstButton
      showLastButton
      />

</div>
</>
);
}


export default TableComponentForVariants;

const TableRowCustom = styled(TableRow)`
height: 50px;
  & > td {
    display: flex!important;
    align-items: center;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      //height: 40px;
      vertical-align: middle;
    }
  }
`;


const TableBodyCustom = styled(TableBody)`
  ${(props) => {
    return (
      props.rowId &&
      `.row${props.rowId} > td {
    background-color: #C6DBEF !important;
  }`
    );
  }}
`;