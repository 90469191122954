import React, {Component, Fragment} from "react";
// import children
import "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/css/variant_table.css"
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../dashboard/reducers/study_actions";

import External_Links from "../../../dashboard/components/genomics-dashboard/components/variant-tabs/ExternalResources";
import SNV_Table from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/SNV_Table"
import {getAnalysisAnnotations, getStudy} from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/getAnnotations";
import {prepareVariantsTable} from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/variant_finder";
import QueryTabs from "../../../dashboard/components/genomics-dashboard/components/results/QueryTabs";
import Box from "@material-ui/core/Box";
import {Accordion, AccordionDetails, CircularProgress, Snackbar} from "@material-ui/core";
import {STATIC_VARIABLES} from "../../../../config-files/const_variables";
import {getCurrentQuery} from "../../../dashboard/components/genomics-dashboard/components/results/components/query-applied-filters/query_difference";
import AccordionInfo from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/component/Pagination";
import * as GPAP_General_Actions from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/actions";
import ToolBar_New from "../../../dashboard/components/genomics-dashboard/components/results/components/toolbar/ToolBar_New";
import CNV_Table from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/cnv_table/CNV_Table";
import {Grid, Skeleton, Typography} from "@mui/material";
import {GPAP_Button} from "../../../../gpap-lib/components/Button_Collection";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import SaveDialog
    from "../../../dashboard/components/genomics-dashboard/components/results/components/toolbar/save_dialog/SaveDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SomaticTable
    from "../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/gpap-table/somatic_table/SomaticTable";

import AddAnalysisButton from "../../../dashboard/components/genomics-dashboard/components/results/components/general-query-view/AddAnalysisButton";
import AnalysesTabs from "../../../dashboard/components/genomics-dashboard/components/results/AnalysesTabs";
import Treatments from "../../treatments/Treatments";
import MappedText from "../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import {Alert} from "@material-ui/lab";


class MainView_ManualFiltering extends Component{

    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            selectedRows: [],
            viewMode: true, // this is because in the first version there were two ways of showing the data. gene view (true) or transcript view (false)
            tableType: true,
            selectedParticipants: [],
            selectedExperiments: [],
            from:0,
            cnv_number: 0,
            somaticNumber:0,
            edit: false,
            snackMessage: false
    };

        // config

        //this.cols_items = cols_items;
        this.getTableDataContent = this.getTableDataContent.bind(this);

        this.getSNVTable = this.getSNVTable.bind(this);
        this.renderTablesLayout = this.renderTablesLayout.bind(this);
        this.annotate_variants = this.annotate_variants.bind(this);
        this.collectVariants = this.collectVariants.bind(this);
        this.getCNVTable = this.getCNVTable.bind(this);
        this.getCNVNumber = this.getCNVNumber.bind(this);
        this.setSelectedCNV = this.setSelectedCNV.bind(this);
        this.getTreatmentTable = this.getTreatmentTable.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.getSomaticTable = this.getSomaticTable.bind(this);
        this.getSomaticNumber = this.getSomaticNumber.bind(this);
        this.handleSnackClose = this.handleSnackClose.bind(this);
    }


   collectVariants(){
       let {studySettings, studyBucket} = this.props;
       let study = getStudy(studySettings, studyBucket);

       if(study!== undefined && study.study!== undefined){
           this.setState({
               tagged_variants: study.study.tagged_variants
           })
       }

   }

   componentDidMount() {

       this.collectVariants()

   }



    getTableDataContent(){

        let { variants } =this.props;
        const data = prepareVariantsTable(variants, this.state.viewMode);
        return {
            data: data,
            variants: data
        }

    }

    annotate_variants = (type, extra, operation_type) => {

        let { study_actions, studySettings, selected_variant} = this.props;
        if(selected_variant.length>0 && selected_variant[0].variant!== undefined){
            study_actions.annotate_variants(
                {
                    query_id: studySettings.current_query,
                    analysis_id: studySettings.current_analysis,
                    study_id: studySettings.id,
                    variants: selected_variant[0].variant,
                    label: extra,
                    type: type,
                    operation_type: operation_type
                }
            );
        }

        this.collectVariants()

    };





    getCNVNumber = (tot) =>{

        this.setState({cnvNumber: tot});
        this.props.getCNVTotal(tot);

        this.setState({snackMessage: (tot === -1)})
    }

    getSomaticNumber = (tot) =>{

        console.log(tot);
        this.setState({somaticNumber: tot});
        //this.props.getCNVTotal(tot);
    }


    setSelectedCNV = (cnv_row) => {


        let {gpap_general_actions} = this.props;

        console.log("Set selected CNV");

        if(cnv_row){

           /* gpap_general_actions.selectedVariant([{
                variant : cnv_row,
                type: "CNV"
            }]);*/


            gpap_general_actions.setSelectedTableRows({
                data_row : [cnv_row],
                data_type: "CNV",
            })
        }

    }

    getTreatmentTable() {


        return <Box p={1} style={{float: "left", width: "100%"}}>
            <Accordion defaultExpanded={false}>
                <AccordionInfo title={"Treatments"}
                               total={this.state.cnvNumber}
                               appendixTitle = {""}
                />
                <AccordionDetails>
                    <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>



    }


    handleSnackClose = () => {

        this.setState({snackMessage:false})
    }

    getCNVTable(){


        const self = this;

        let url = window.config.apis_configuration.api_nextgpap_server_elastic + "/api/cnvs";

        return <Fragment>
            <Snackbar  open={this.state.snackMessage}
                       //autoHideDuration={5000}
                       onClose={this.handleSnackClose}>
                <Alert onClose={this.handleSnackClose} severity="danger">
                    CNV could not be loaded
                </Alert>
            </Snackbar>
            <CNV_Table
            url={url}
            method ={"POST"}
            token={this.props.token}
            locationData="items"
            experiment={this.props.experiment}
            genes={this.props.genes_state}
            getCNVNumber = {this.getCNVNumber}
            setSelectedCNV = {this.setSelectedCNV}
            // how can we make this more generic?
            getAllTaggedVariants = {self.props.getAllTaggedVariants}
            all_tagged_variants = {self.props.all_tagged_variants}

        />

        </Fragment>
    }

    getSomaticTable(){

        const self = this;

        return <SomaticTable
                         url={window.config.api_endpoint + "/variants"}
                         method ={"POST"}
                         token={this.props.token}
                         experiment={this.props.experiment}
                         getTotalNumber = {this.getSomaticNumber}
                         getAllTaggedVariants = {self.props.getAllTaggedVariants}
                         all_tagged_variants = {self.props.all_tagged_variants}

        />


    }


    getSNVTable(){

        let obj = this.getTableDataContent();

        let self = this;
        let { total, summary } = this.props;

        let query_type = (self.props.instandGeneticFindingsTab === "somatic") ? "somatic" : "default";

        let type = (summary) ? "search_across" : query_type;

        return    <SNV_Table
            annotate_variants = {self.annotate_variants}
            getAllTaggedVariants = {self.props.getAllTaggedVariants}
            all_tagged_variants = {self.props.all_tagged_variants}
            passAnnotations={ self.props.passAnnotations}
            loading={self.props.loading}
            queryRunner={self.props.queryRunner}
            fetchData={ self.props.fetchData}
            total={(self.state.selectedParticipants.length=== 0) ? total : obj.data.length}
            type={type}
            tagged_variants = {self.state.tagged_variants}
            data = {obj.data}
            participants = {this.state.selectedParticipants}
            selectedExperiments = {self.state.selectedExperiments}
            pageCount={Math.ceil(total/100)}
            pid_array = {(self.props.participants.participants!== undefined) ? self.props.participants.participants.map(s => s.report_id) : []}
        />


    }
    
    renderTablesLayout(){

        const self = this;

        const TreatmentTable =  this.getTreatmentTable();
        const SNV_Table = this.getSNVTable()
        const CNV_Table = (self.props.cnv_data) ? this.getCNVTable() : null;
        
        //Use the SNV table also for the somatic
        //const SomaticTable = this.getSomaticTable();
        const SomaticTable = this.getSNVTable();

        let accordionEl = <Box p={1} style={{float: "left", width: "100%"}}>
            <Accordion defaultExpanded={false}>
                <AccordionInfo title={"Germline, SNV and InDels "} total={self.props.total + " genomic variants"} tooltipTitle="Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene."/>
                <AccordionDetails>
                    {SNV_Table}
                </AccordionDetails>
            </Accordion>
        </Box>


        // if we are in family trio, we show the SNV Table as an accordion. In cohort analysis we don't.
        let SNV_Table_Element = (self.props.summary || self.props.cnv_data)
            ? accordionEl
            : SNV_Table
                    
        let appendixTitleCNV = "- no filters applied"
                             if (this.props.genes_state && this.props.genes_state.length > 0)
                                { appendixTitleCNV="- Genes filter applied";}

        return <Fragment>

              {/* In case we want to separate them in the Edit mode (but need to address the right queries in each view)*/}
               {self.props.instandGeneticFindingsTab === "germline" && (
               <>
                {SNV_Table_Element}
               </>
             )} 

             <Box p={1} style={{float: "left", width: "100%"}}>
                {self.props.instandGeneticFindingsTab === "somatic" && (
                    <>
                    <Accordion defaultExpanded={false}>
                        <AccordionInfo title={"Somatic, SNV and InDels "}
                                       total={ self.props.total + " genomic variants"}
                                       //appendixTitle = {"- no filters applied"}
                                       tooltipTitle=<MappedText text="Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene." />
                        />
                        <AccordionDetails>
                            <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                                {SomaticTable}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    </>
                )}
            </Box>

            
             {/* SNVs and Somatic table together in the view 
             
             {SNV_Table_Element}

            <Box p={1} style={{float: "left", width: "100%"}}>
                    
                    <Accordion defaultExpanded={false}>
                        <AccordionInfo title={"Somatic, SNV and InDels "}
                                       //total={ this.state.somaticNumber }
                                       total={ self.props.total }
                                       appendixTitle = {"- no filters applied"}
                        />
                        <AccordionDetails>
                            <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                                {SomaticTable}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
            </Box>*/}


            <Box p={1} style={{float: "left", width: "100%"}}>
                <Accordion defaultExpanded={false}>
                    
                    <AccordionInfo title={"CNVs and SVs"}
                                   total={this.state.cnvNumber}
                                   appendixTitle = {appendixTitleCNV}
                                   tooltipTitle=<MappedText text="The displayed number is the total sum of events in the FULL and SPLIT results."/>
                    />
                    <AccordionDetails>
                        <Box style={{ overflow: "auto", overflowY: "hidden", maxHeight: "100%" }}>
                            {this.getCNVTable()}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Fragment>


    }

    handleEdit(editBool) {

        this.setState({edit: editBool})

    }

    render(){

        let self  = this;

        let obj = this.getTableDataContent();
        let annotations = getAnalysisAnnotations(self.props.studySettings, self.props.studyBucket);

        if(self.state.edit){
            if(self.props.variants!== undefined)
            {
                return ( <Grid container direction={"row"}>
                        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <GPAP_Button onClick={() => self.handleEdit(!self.state.edit)} startIcon={<ArrowBackIcon />}><MappedText text={"Back"}/></GPAP_Button>

                        </Grid>
                        <Grid item lg={12}>
                            <SaveDialog list_of_studies = {[ ]}/>
                            <AddAnalysisButton queryRunner={self.props.queryRunner} instandGeneticFindingsTab={self.props.instandGeneticFindingsTab}/>
                        </Grid>

                        {/* Move query tabs under the AnalysesTabs
                        <Grid item lg={12}>
                            <QueryTabs
                                switchQuery = {self.props.switchQuery}
                            />
                        </Grid>
                        */}
                        
                        <Grid item lg={12}>
                            <ToolBar_New
                                data = {obj.data}
                                annotations = {annotations}
                                annotate_variants = {self.annotate_variants}
                                tagged_variants = {self.props.all_tagged_variants}
                                getAllTaggedVariants = {self.props.getAllTaggedVariants}
                                passAnnotations={ self.props.passAnnotations}
                            />
                        </Grid>
                       <Grid item lg={10}>
                           <div className="variant_table_instand">
                    
                           {/* This doesn't seem necessary for the Manual Filtering Option*/}
                           <AnalysesTabs
                            switchAnalysis = {self.props.switchAnalysis}
                            resetAll = {self.props.resetAll}
                           />

                           <QueryTabs
                            switchQuery = {self.props.switchQuery}
                           />

                           {self.renderTablesLayout()}
                           </div>
                       </Grid>
                        <Grid item lg={2}>
                            <External_Links
                                classStyle={""}
                                summary = {self.props.summary}
                                sample_agg = {self.props.sample_agg}
                                queryRunner={self.props.queryRunner}
                                runSearchAcrossQuery = {() => {console.log("not needed here")}}
                                participants = {this.props.participants}
                                passExperiments = {self.selectedExperiments}
                                passGenes = {() => {console.log("not needed here")} }
                                totalVariants={self.props.total}
                            />
                        </Grid>
                    </Grid>
                   )
            }
            else if(self.props.requestStatus === STATIC_VARIABLES.UNAVAILABLE_SERVER){
                return <div className={'central-panel'}>
                    <div className={"error_msg"}>
                        <CircularProgress />
                        The GPAP Genomics server is currently unavailable. We apologize for the the inconvenience. Please contact us at <a href="mailto:help@rd-connect.eu">help@rd-connect.eu</a> for more information.
                    </div>
                </div>
            }
            else if(self.props.variants  === undefined ){
                return <div className={'central-panel'}>
                    <div className={"error_msg"}>
                        <CircularProgress />
                        No variants...yet
                    </div>
                </div>
            }
            else{
                return <div className={'central-panel'}>
                    <div className={"error_msg"}>Ooops, the query returned an error;</div>
                </div>
            }
        }
        else{
            return <Fragment>
                <Grid item lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <GPAP_Button onClick={() => self.handleEdit(!self.state.edit)} startIcon={<FilterListIcon/>}><MappedText text={"Review full list of variants"} /></GPAP_Button>
                </Grid>
                 
                  {/* 
                <Grid item lg={12}>
                    <Box p={1}>
                        <Typography variant={"h5"}>SNV & InDELs - Somatic ({self.state.somaticNumber} )</Typography>
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Box p={1}>
                        {this.getSomaticTable()}
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Box p={1}>
                        <Typography variant={"h5"}>SNV & InDELs - Germline ({this.props.total})</Typography>
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Box p={1}>
                        {this.getSNVTable()}
                    </Box>
                </Grid>
                */}

                <Grid item lg={12}>
                   <Box p={1}>
                   {self.props.instandGeneticFindingsTab === "somatic" && (
                    <>
                     <Typography variant={"h6"}>
                     <MappedText text={"SNVs and InDels - Somatic"} /> ({this.props.total + " genomic variants"})
                      {/* ({self.state.somaticNumber} ) */}
                     </Typography>
                     {this.getSNVTable()}
                     
                     {/* {this.getSomaticTable()} */}
                    </>
                   )}
                 
                  {self.props.instandGeneticFindingsTab === "germline" && (
                   <>
                    <Typography variant={"h6"}>
                    <MappedText text={"SNVs and InDels - Germline"} /> ({this.props.total + " genomic variants"})
                   </Typography>
                   {this.getSNVTable()}
                   
                   </>
                  )}
                 </Box>
                </Grid>

                <Grid item lg={12}>
                    <Box p={1}>
                    <Typography variant={"h6"}>
                    <MappedText text={"CNVs and SVs"} /> ({(this.state.cnvNumber || 0) + " genomic variants"})
                    </Typography>
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Box p={1}>
                    {this.getCNVTable()}
                   
                    </Box>
                </Grid>

              {/*  {self.props.instandGeneticFindingsTab === "somatic" && (
                <>
                <Grid item lg={12}>
                    <Box p={1}>   
                    <Typography variant={"h5"}><MappedText text={"Treatments"} /></Typography>
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Treatments/>
                </Grid>
                </>
                )}*/}
            </Fragment>
        }

    }
}


function mapStateToProps (state) {
    if(state.variants!==undefined ){

        return {
            token: state.authorization.token,
            selected_variant: state.selected_variant,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            patients: state.patients,
            genes_state :( state.currentFilterSet.filters!==undefined ) ? state.currentFilterSet.filters.gene : (state.query.gene) ? state.query.gene: null,

            // TODO: we need to get all the experiments in the analysis
            experiment: state.sample_list.samples.map(s => s.sample_id)
        }
    }
    else{
        return {snv: []}
    }
}

const mapDispatchToProps = (dispatch) => ({
    study_actions: bindActionCreators(StudyActions, dispatch),
    gpap_general_actions: bindActionCreators(GPAP_General_Actions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(MainView_ManualFiltering);
