import React, {Fragment, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {GPAP_Button_light} from "../../../gpap-lib/components/Button_Collection";
import {delete_study} from "../../../services/api/manage_study";
import {connect} from "react-redux";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import GPAP_Tooltip from "../../../gpap-lib/components/GPAP_Tooltip";

function DeleteDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [message, setMessage] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSuccess(false);
        setMessage(false);
        setOpen(false);
    };

    const handleDelete = () => {

        const nextgpap_server = window.config.apis_configuration.api_nextgpap_server;
        let data = {
            study_id: props.study_id

        };

        delete_study(nextgpap_server, props.token, data)
            .then(resp => {

                if(resp.ok){
                    setSuccess(true);
                    return resp.json()
                }
                else{
                    setSuccess(false);
                    return resp.json()
                }


            }
              )
            .then(json => {
                setMessage(json.info);
                props.go_to_study();
            })



    };


    const firstDialog = () => {

        return <Fragment>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    All analysis and queries will also be delete from our database
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No, I don't want
                </Button>
                <Button onClick={handleDelete} color="primary" autoFocus>
                    Yes, I want to delete it
                </Button>
            </DialogActions>
        </Fragment>


    };

    const secondDialog = () => {

        return <Fragment>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                   Close
                </Button>
            </DialogActions>
        </Fragment>


    };


    const getButton = () => {

        if(props.onlyIcon){

            return <GPAP_Tooltip title={"Delete the study"}><IconButton onClick={handleClickOpen}>
                <DeleteForeverIcon/>
            </IconButton>
            </GPAP_Tooltip>

        }
        else{
            return  <GPAP_Button_light onClick={handleClickOpen} startIcon={<DeleteForeverIcon/>}>
                Delete
            </GPAP_Button_light>
        }



    }

    return (
        <Fragment>
            {getButton()}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{(!message) ? "Are you sure to delete this study?" : "Response"}</DialogTitle>
                {(!message)
                    ? firstDialog()
                    : secondDialog()}
            </Dialog>
        </Fragment>
    );
}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {   token: state.authorization.token}
    }
}





export default connect(mapStateToProps,null)(DeleteDialog);