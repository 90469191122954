export const InstandTheme = {
    palette: {
        mode: 'light',
        primary: {
        main: '#3e699e',
        dark: '#314c80',
        light: '#669cd5',
        },
        secondary: {
        main: '#f17645',
        light: '#f2a387',
        dark: '#d8521c',
        },
        error: {
        main: '#bf3b2e',
        dark: '#a22d1d',
        light: '#db5b4f',
        },
        warning: {
        main: '#f2b72a',
        light: '#f1de30',
        dark: '#eb7c13',
        },
        info: {
        main: '#3fa7f4',
        dark: '#3088d0',
        light: '#69c0f6',
        },
        success: {
        main: '#4cae50',
        dark: '#2d7d33',
        light: '#80c683',
        },
    },
    };