



import React, {Component, useState} from "react";
import {connect} from "react-redux";
import Inheritance_Mode from "./Inheritance_Mode";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import cardStyles from "../card-style";



const Inheritance_Mode_renderer = (props) => {

    const classes = cardStyles();
    const  w  = props.full_size;

    let width = (w) ? "100%" : "50%";

    if(props.render){
        return (
            <Fade in={true} timeout={300}>
                <Card className={classes.root} variant="outlined">
                    <CardContent style={{width: width}}>
                        <Inheritance_Mode instandGeneticFindingsTab={props.instandGeneticFindingsTab}/>
                    </CardContent>
                </Card>
            </Fade>
           )

    }
    else{
        return null
    }


};



function samples_added (state) {
    if(state.sample_list.samples.length!==0)
    {
        return {
            render:true
        }
    }
    else{ return {render:false}}

}

export default connect(samples_added,null)(Inheritance_Mode_renderer);







