import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next'; // Adjust the path to your i18n configuration
import MappedText from './mapText'; // Adjust the path to your MappedText component

export const renderToString = (text) => {
  return ReactDOMServer.renderToString(
    <I18nextProvider i18n={i18next}>
      <MappedText text={text} />
    </I18nextProvider>
  );
};