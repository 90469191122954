import React from "react";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";



export const DATA_HEADER = [
  
    {
        header: <MappedText text={"Treatments"} />,
        columns: [

            { nameTable: <MappedText text={"Gene name"} />, dataSearch: "gene_name", tooltip: "" },
            { nameTable: <MappedText text={"Variant"} />, dataSearch: "hgvs_p", tooltip: "" },
            {  filterType: { type: "select", option: "default" },nameTable: <MappedText text={"Level of evidence"} />, dataSearch: "Evidence_level", tooltip: "" },
            {  filterType: { type: "select", option: "default" },nameTable: <MappedText text={"Match"} />, dataSearch: "match_type", tooltip: "" },
            {  nameTable: <MappedText text={"Cancer type"} />, dataSearch: "Cancer_Type", tooltip: "" },
            {  nameTable: <MappedText text={"Therapy"} />, dataSearch: "Therapies", tooltip: "" },
            {  filterType: { type: "select", option: "default" },nameTable: <MappedText text={"Therapy status"} />, dataSearch: "Therapy_status", tooltip: "" },
            // {  filterType: { type: "select", option: "default" },nameTable: <MappedText text={"Drug status "} />, dataSearch: "Drug_status", tooltip: "" },
            // {   filterType: "text", nameTable: "Evidence", dataSearch: "links" },
            //    {  filterType: { type: "none" }, nameTable: "Evidence CGI", dataSearch: "cgi_evidence_source" },
             {  filterType: { type: "none" }, nameTable: <MappedText text={"Evidence CIViC "} />, dataSearch: "Evidence_Source_link" },    
        ],
    },  
    
];
