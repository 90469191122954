    import React from "react";
    import MappedText from "../../../../shared-components/mapText";

    export const SOMATIC_GENOME_COVERAGE = [
        {
            Header: <MappedText text='Gene symbol'/>,
            accessor: "gene_symbol",
            filter: '',
        },
        {
            Header: <MappedText text='Transcript'/>,
            accessor: "transcript",
            filter: '',
        },
        {
            Header: <MappedText text='Mean coverage'/>,
            accessor: "mean_coverage",
            filter: '',
        },
        {
            Header: 'C1',
            accessor: "c1",
            filter: '',
        },
        {
            Header: 'C10',
            accessor: "c10",
            filter: '',
        },
        {
            Header: 'C15',
            accessor: "c15",
            filter: '',
        }, 
        {
            Header: 'C20',
            accessor: "c20",
            filter: '',
        },

    ];