


import React from 'react';
import Main from "./src/components/PageHeader/components/Main/Main";
import {initReactI18next} from "react-i18next";
import i18n from 'i18next';
import {translation_dic} from "./translations/translation-dic";
import { HashRouter } from 'react-router-dom';


// Initialize the library. In production, we would do this in a
// separate file and import it into the root index.js file.
// (We only need to initialize i18next once).
i18n.use(initReactI18next)
    .init({
        // Add translations. We're adding English (USA) and
        // Arabic (Egypt) here. In a production app, these
        // translations would likely be in separate files.
        resources: translation_dic,
        // Set the default language to English.
        lng: "en",
        // Disable i18next's default escaping, which prevents XSS
        // attacks. React already takes care of this.
        interpolation: {
            escapeValue: false,
        },
    });


function App() {

    return (
    <HashRouter>
        <Main/>
    </HashRouter>
    );
}



export default App;