import { Document, Packer, Paragraph, Table, TableRow, TableCell, WidthType, VerticalAlign, ShadingType, BorderStyle, Header, AlignmentType, TextRun, Footer, PageNumber, NumberFormat } from "docx";
import { createSubTitle, createBoldUnderlinedParagraph, createTitle, createSpacer, createKeyValueParagraph, createCenteredTitle, createTwoColumnRow, createSingleRow } from "../write-sections/write_utils/documentHelpers.js";
import WriteClinicalEvidenceSection from "../write-sections/clinical-evidence/WriteClinicalEvidenceSection.js";
import WriteReferences from "../write-sections/references/WriteReferences.js";
import AnnexesSomatic from "../annexes-sections/somatic/AnnexesSomatic.js"
import WriteGeneticFindingsSection from "../write-sections/genetic-findings/WriteGeneticFindingsSection.js";
import WriteInterpretationData from "../write-sections/interpretation/WriteInterpretationSection.js";
import WriteExomeCoverageSection from "../annexes-sections/somatic/TableExomeCoverage.js";
import WriteGenomeCoverageSection from "../annexes-sections/germline/TableGenomeCoverage.js";
import AnnexesGermline from "../annexes-sections/germline/AnnexesGermline.js";
import WriteAssociationsSection from "../annexes-sections/germline/TableAssociations.js";
import WriteVariantMetricsSection from "../annexes-sections/germline/TableVariantMetrics.js";
import WriteGenesInterestSection from "../annexes-sections/germline/TableGenesInterest.js";
import WriteDisclaimer from "../write-sections/disclaimer/WriteDisclaimer.js";
import WriteTechnicalLimitations from "../../reports/templates/write-sections/WriteTechnicalLimitations";
import WriteGeneralInfo from "../write-sections/general-info/WriteGeneralInfo.js";
import { renderToString } from "../../create-report/shared-components/renderToString.js";


export const createDoc = async (data, analysisType, experimentType, allDataApis) => {

  const header = new Header({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: renderToString("MOLECULAR GENETIC REPORT"),
            color: "#808080",
            size: 11 * 2,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: data.reportName,
            color: "#808080",
            size: 12 * 2,
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    ],
  });

  const footer = new Footer({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            children: [renderToString("Page "), PageNumber.CURRENT],
          }),
          new TextRun({
            children: [renderToString(" of "), PageNumber.TOTAL_PAGES],
          }),
        ],
        alignment: AlignmentType.LEFT,
      }),
    ],
  });



  if (analysisType !== 'germline') {
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
            pageNumbers: {
              start: 1,
              formatType: NumberFormat.DECIMAL,
            },
          },
          headers: {
            default: header,
          },

          children: [
            createSpacer(50, 50),
            new Paragraph({
              children: [new TextRun({
                text: renderToString("MOLECULAR GENETIC REPORT"),
                bold: true,
                color: "#3e699e",
                size: 20 * 2,
              })],
              alignment: AlignmentType.CENTER,
            }),
            ...WriteGeneralInfo(data),
            createSpacer(50, 50),
            ...WriteGeneticFindingsSection(data["geneticFindingsData"]),
            ...WriteInterpretationData(data["interpretationData"], analysisType),
          ],
          footers: {
            default: footer,
          },
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: [
            createSpacer(50, 50),
            ...WriteClinicalEvidenceSection(data["clinicalEvidenceData"]),
            createSpacer(50, 50),
            ...WriteDisclaimer("Disclaimer", data["disclaimer"]),
            createSpacer(50, 50),
            ...WriteTechnicalLimitations("Technical Limitations", data["technicalLimitations"], experimentType),
            createSpacer(50, 50),
            ...WriteReferences("References", data["references"]),
          ]
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: AnnexesSomatic(allDataApis),
        },

        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: [
            ...WriteExomeCoverageSection(data["exomeCoverageData"]),
            createSpacer(50, 50),
            createKeyValueParagraph("List of diagnostically targeted genes", data.targeted_genes),
          ],


        },
      ],


    });

    return await Packer.toBlob(doc);
  } else if (analysisType === 'germline') {
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          children: [

            createSpacer(50, 50),
            new Paragraph({
              children: [new TextRun({
                text: renderToString("MOLECULAR GENETIC REPORT"),
                bold: true,
                color: "#3e699e",
                size: 20 * 2,
              })],
              alignment: AlignmentType.CENTER,
            }),
            ...WriteGeneralInfo(data),
            createSpacer(50, 50),
            ...WriteGeneticFindingsSection(data["geneticFindingsData"]),
            ...WriteInterpretationData(data["interpretationData"], analysisType),
          
              ],
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          children: [
            ...WriteClinicalEvidenceSection(data["clinicalEvidenceData"]),
            ...WriteDisclaimer("Disclaimer", data["disclaimer"]),
            ...WriteTechnicalLimitations("Technical Limitations", data["technicalLimitations"], experimentType),
            ...WriteReferences("References", data["references"]),

          ]
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
          },
          headers: {
            default: header,
          },
          children: AnnexesGermline(allDataApis),
        },
        {
          properties: {
            page: {
              margin: {
                top: 1117,
                right: 787,
                bottom: 1117,
                left: 787,
              }
            },
            pageNumbers: {
              start: 1,
              formatType: NumberFormat.CARDINAL_TEXT,
            },

          },
          headers: {
            default: header,
          },
          children: [
            ...WriteGenomeCoverageSection(data["genomeCoverageData"]),
            createSpacer(50, 50),
            createKeyValueParagraph("List of diagnostically targeted genes", data.targeted_genes),
            createSpacer(50, 50),
            ...WriteGenesInterestSection(data["coverageGenesData"]),
            createSubTitle("*for microRNAs coverage is computed using Ensembl and based on the complete genomic region"),
            createSpacer(50, 50),
            createBoldUnderlinedParagraph("ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS"),
            ...WriteVariantMetricsSection(data["variantMetricsData"]),
            createSubTitle("GT: genotype; GQ: genotype quality; DP: depth of coverage (total reads); REF count: reads supporting reference allele; ALT count: reads supporting alternative allele; AAF: alternative allele frequency"),
            createSpacer(50, 50),
            ...WriteAssociationsSection(data["genotypePhenotypeData"]),
            createSpacer(50, 50),
            createBoldUnderlinedParagraph("ANNEX V: PHARMACOGENOMICS RESULTS"),
          ],
        },
      ],
    });

    return await Packer.toBlob(doc);
  }


};