


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import QueryFilters from "../query-applied-filters/QueryFilters";
import MappedText from '../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function ViewAppliedFilters(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <Button
                id={"review_filters"}
                color = {( anchorEl!== null ? "primary" : "default" )}
                aria-describedby={id} onClick={handleClick}>
                <MappedText text={"View Applied Filters"}/>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <Box p={0} style={{maxHeight:"400px", width: "400px", overflowY: "auto", padding: "1%"}}>
                    <Box p={0}>
                       <QueryFilters whichQuery={props.whichQuery}/>
                    </Box>
                </Box>

            </Popover>
        </React.Fragment>
    );
}