



import React, {Fragment, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import {save_new_list} from "../../../services/api/lists_api";
import Box from "@material-ui/core/Box";
import {Divider, LinearProgress} from "@material-ui/core";
import GPAP_Tooltip from "../../../gpap-lib/components/GPAP_Tooltip";
import {h37_endpoint, h38_endpoint} from "../../../services/api/ensembl";
import Alert from '@material-ui/lab/Alert';
import { DESCRIPTION, NAME_FIELD } from "./ConfigValidation";
import {downloadURLFile} from "./download_utils";
import { GPAP_Button_Gray } from "../../../gpap-lib/components/Button_Collection";
import {Grid} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";


export function UploadJsonFile(props ) {
    const [files, setFiles] = useState("");
    const [nLists, setNumberLists] = useState(0);


    const [listUploaded, setListUploaded] = useState(false);

    const [validated, setValidated] = useState(false);
    const [validating, setValidating] = useState(false);
    const [genesNotValidated, setGenesNotValidated] = useState([]);
    const [error, setError] = useState(false);
    const [errorInfo, setErrorInfo]= useState(false);



    const getString = (s, index) => {

        if(s.length>0 && index <= s.length){
            return s[index]
        }
        else{
            return "NA";
        }


    }

    const handleGPAPTxtFile = e => {
        setError(false);
        setErrorInfo(false);
        setListUploaded(false);
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            const text = (e.target.result);
            //
            let lines = text.split("\n");
            lines = lines.filter(line => line.trim() !== "");
            lines = lines.map(s => s.split(":"));

            //genes
            let genes = lines.filter(s => !getString(s,0).includes("#"))
                .map(s => getString(s,0).replace("\r", ""))

            // fields

            let fields = lines.filter(s => getString(s,0).includes("#"))
                .map(s => {return {"key": getString(s,0).replace("#", ""), "value": getString(s,1).replace("\r", "")}})
                
            
            const title = fields.find((field) => field.key === NAME_FIELD);
            const source = fields.find((field) => field.key === "source");
            const description = fields.find((field) => field.key === DESCRIPTION);
    
            if (!title || title.length < 3) {
                setErrorInfo(true);
                
            }
    
            if (!source || source.length < 3) {
                setErrorInfo(true);
               
            }
    
            if (!description || description.length < 3) {
                setErrorInfo(true);
                
            }


            let list = {};

            fields.forEach(function(s){
                let value= s.value.replace(/\\/, "\\\\");
                try {
                    list[s.key] = JSON.parse(value);
                } catch(e) {
                    list[s.key] = value
                }
            })


            list["genes"]= genes;
            setListUploaded(list);
            validateGenes(genes);

        }


    }

    const validateGenes = async (array) => {

        const chunkSize = 1000;
        let chunks = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            // do whatever
            chunks.push({chunk: chunk, validated: false, not_validated_genes: []});
        }

       if(chunks.length>0){
           setValidating(true);
           for (let item of chunks) {
               let url=h37_endpoint;
               if(window.config.genome_ref === "hg38"){
                   url = h38_endpoint
               }

               let response = await fetch(url, {
                   method: 'POST',
                   headers: {
                       'Accept': 'application/json',
                       "Content-Type": "application/json"
                   },
                   body: JSON.stringify({symbols: item.chunk})
               })
               let json = await response.json();

               if (Object.keys(json).length === item.chunk.length) {
                   // in this case, all good. No error.
                   item.validated = true;
                   setValidating(false);
                   setValidated(true);

               }
               else if(Object.keys(json).length ===0){
                   // No genes uploaded or validated
                   setValidated(false);
                   setValidating(false);
                   setError(true);
               }
               else {
                   // Some genes could not be validated...throw error.
                   let validated_genes = Object.keys(json);
                   let difference = item.chunk.filter(x => !validated_genes.includes(x));
                   //if difference.length==0 means that there are duplicated genes
                   if (difference.length === 0) {

                    
                    difference=["check duplicate genes name"];
                   }
                   item.not_validated_genes = difference;
                   setValidating(false);
                   setValidated(false);
                   //setError(true);
                   // TODO: we could use this hook below because if the array is not of length 0, it means something did not go well...
                   setGenesNotValidated(difference);
               }
           }
       }


        let genes_not_validated = [];
        chunks.forEach(s => genes_not_validated = genes_not_validated.concat(s.not_validated_genes))

    }



    const uploadList = () => {

        SaveList(listUploaded, props.token);

    }






    const getValidated = () => {

        if(listUploaded && validated && !errorInfo){
            return <Box pt={1}>
                <Alert severity="success" style={{transitionDuration:"2s"}}><MappedText text="Gene list was validated"/></Alert>
                <Box  pt={1}>
                        <GPAP_Button
                            variant="contained" color="#EAEAEA" disableElevation
                            disabled={errorInfo || error}
                            onClick={() => props.handleSaveList(listUploaded)}><MappedText text="Upload Gene list"/></GPAP_Button>
                    </Box>
            </Box>
        }
        else if(listUploaded && genesNotValidated.length>0){
            return <Box pt={1}>
                <Alert severity="error">
                    <Box>
                    <MappedText text="Some genes could not be validated. Please check them and re-upload the file:"/> <br/>
                    {genesNotValidated.toString()}
                </Box>
                </Alert>
            </Box>
        }



    }



    const downloadTxtFile = () => {

        const TEMPLATE_URL = window.config.static_files + "/docs/templates/gene_list_template.txt";
        downloadURLFile(TEMPLATE_URL, "gene_list_template.txt");


    }

    const handleErrors = () =>{

        let text = "";
        let showAlert = false;

        // error => error genes not validated
        // errorInfo = > title, description...wrong...


        if(error && errorInfo){
            text = <MappedText text="Please check the text"/>;
            showAlert = true;
        }
        else if(error && listUploaded){
            text = <MappedText text="Genes are not in the correct format, please check the text"/>;
            showAlert = true;
        }
        else if(errorInfo){
            text = <MappedText text="Fields should not be empty, please check the text"/>;
            return <Box pt={2}>
                <Alert severity="error">{text}</Alert>
            </Box>
        }
        else if(listUploaded && Array.isArray(listUploaded["genes"]) && listUploaded["genes"].length === 0){
            text = <MappedText text="Gene list is empty. Please check the file."/>;
            return <Box pt={2}>
                <Alert severity="error">{text}</Alert>
            </Box>
        }

        if (showAlert) {
            return <Box pt={2}>
                    <Alert severity="error">{text}</Alert>
                    <GPAP_Tooltip
                        title=<MappedText text={"Cancel Gene List Upload"}/>>
                    <GPAP_Button onClick={() => {
                        document.getElementById("txtFile").value = "";
                        setGenesNotValidated([]);
                        setListUploaded(false);
                    }} startIcon={ <CancelIcon/>}>
                       <MappedText text="Cancel Gene List Upload"/>
                    </GPAP_Button>
                    </GPAP_Tooltip>
                </Box>
        }
        else{
            return null;
        }
        


    }


    return (
        <Box>


            <Grid container spacing={5}>
                <Grid item md={3} style={{margin:'50px, 50px 0 0'}}>
                    <Box pt={2}>
                        <Typography variant={"body1"} style={{ marginBottom: '12px' }}><MappedText text="Upload a GPAP gene file in txt format"/></Typography>
                        <Typography variant={"body2"} style={{ height: '40px' }}> <MappedText text="Be sure that the file is in the correct format and all required fields are present"/></Typography>
                        <GPAP_Button_Gray  component='label' style={{ marginTop: '10px' }}>
                        <MappedText text="Select file"/>
                            <input hidden id="txtFile" type="file" onChange={handleGPAPTxtFile} />
                        </GPAP_Button_Gray>
                        {handleErrors()}
                    </Box>

                </Grid>
            
                <Grid item md={4}>
                    <Box pt={2}>
                        <Typography variant={"body1"} style={{ marginBottom: '12px' }}><MappedText text="Don't know the correct format?"/></Typography>
                        <Typography variant={"body2"} style={{ height: '40px', width:'270px'}}><MappedText text="Download our template file and fill it in with your data"/></Typography>
                        <GPAP_Button_Gray  onClick={downloadTxtFile} style={{ marginTop: '10px' }}><MappedText text="Download sample file"/></GPAP_Button_Gray>
                    </Box>
                </Grid>
                    <Grid item md={6}>
                        {(listUploaded)
                            ? <Box>
                                {(validating && !errorInfo)
                                    ? <div style={{width: "100%", float: "left"}}>
                                    <Typography variant={"subtitle2"}><MappedText text="Validating genes.."/></Typography>
                                    <LinearProgress style={{width:'80rem'}} color = "primary" />
                                </div>
                                    : <Box>
                                        {getValidated()}
                                    </Box> }
                            </Box>
                            :  null
                        }

            </Grid>
            

            </Grid>

        </Box>
    );
}