import CandidatePopOver from "../../components/CandidatePopOver";
import { useSelector } from "react-redux";
import React from "react";
import { getAnalysisAnnotations } from "../../../getAnnotations";
import { variantFinder } from "../../variant_finder";



export function CNVPreviousTags(props){

    const { tagged_variants} = props;

    const dataType = "CNV";

    const { studyBucket, studySettings, selected_table_rows } = useSelector(state => ({
        studyBucket: state.studyBucket,
        studySettings: state.studySettings,
        all_tagged_variants: state.all_tagged_variants,
        getAllTaggedVariants: state.getAllTaggedVariants,
        selected_variant: state.selected_variant,
        selected_table_rows: state.selected_table_rows
    }));

   let cnv_data =  selected_table_rows.find(s => s.data_type === dataType);

   let last_variant = (cnv_data !== undefined)
       ? cnv_data.data_row[0]
       : [];


   let annotations = getAnalysisAnnotations(studySettings, studyBucket);

   let variant_found = (last_variant !== undefined )
            ? variantFinder(last_variant, annotations, tagged_variants)
            : []

   let samples = (last_variant !== undefined ) ? last_variant.samples_germline : []
        
   return (
            <CandidatePopOver
                tagged_variants={variant_found}
                samples={samples}
            />
        );

}