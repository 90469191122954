import React from "react";
import MappedText from "../../../../shared-components/mapText";

export const COVERAGE_GENES = [
    {
        Header: <MappedText text='Gene Symbol'/>,
        accessor: "gene_symbol", 
    },
    {
        Header: <MappedText text='Transcript'/>,
        accessor: "transcript",
    },
    {
        Header: <MappedText text='Mean Coverage'/>,
        accessor: "mean_coverage", 
    },
    {
        Header: <MappedText text='Median Coverage'/>,
        accessor: "median_coverage", 
    },
    {
        Header: 'C10',
        accessor: "c10", 
    },
    {
        Header: 'C15',
        accessor: "c15", 
    }, 
    {
        Header: 'C20',
        accessor: "c20", 
    },
];

export default COVERAGE_GENES;
