




//Config from window
import React, {Component} from "react";

import {dataset} from "./PedigreeConfig";

import "./pedigree-viewer-only.css";


import {connect} from "react-redux";
import {family_get} from "../../../../../../../services/api/get";


const config=window.config;
const {opts} = require('./PedigreeConfig');





class PedigreeViewerOnly extends Component {

    constructor(props){
        super(props);
        this.state =
            {
                ped_opts: [],
                containerWidth: null,
                rendered: false,


            };

        this.getPedigree = this.getPedigree.bind(this);
        this.buildTree = this.buildTree.bind(this);
        this.fitParentContainer = this.fitParentContainer.bind(this);
        this.renderPedigree = this.renderPedigree.bind(this);
        this.exportPNG = this.exportPNG.bind(this);
    }


    componentDidMount(){

        this.fitParentContainer();
        window.addEventListener('resize', this.fitParentContainer);
        this.getPedigree();

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.index_case_id!== prevProps.index_case_id && ! this.state.rendered){
            const myNode = document.getElementsByClassName("ped_viewer ");
            myNode.innerHTML = '';
            this.getPedigree();
            if(this.props.index_case_id!== undefined && this.props.index_case_id!== null && this.props.index_case_id){
                this.setState({rendered: true})
            }

        }


    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.fitParentContainer)
    }

    fitParentContainer() {
        const { containerWidth, containerHeight } = this.state;
        const currentContainerWidth = this.pedigreeContainer.getBoundingClientRect().width;
        const currentContainerHeight= this.pedigreeContainer.getBoundingClientRect().height;

        const shouldResize = containerWidth !== currentContainerWidth && containerHeight !== currentContainerHeight;


        if (shouldResize && this.props.open) {

            //this.buildTree(currentContainerWidth,currentContainerHeight );
            this.setState({
                containerWidth: currentContainerWidth,
                containerHeight: currentContainerHeight,
            })
        }
    }


    renderPedigree(){

        const { containerWidth, containerHeight } = this.state;
        this.buildTree(containerWidth,containerHeight);

    }


    buildTree(w,h){

        let opts = this.state.ped_opts;
        opts.width=w;
        opts.heigth = h;
        let options= ptree.build(opts);

    }




    getPedigree = () => {

        //Get PED file

        if(this.props.index_case_id!== undefined && this.props.index_case_id!== null){

            if(this.props.index_case_id.family!== undefined || this.props.index_case_id.famid){
                let fam = "";
                if(this.props.index_case_id.family!== undefined){
                    let family =this.props.index_case_id.family;
                    fam = family.split(".")[1];
                }
                else{
                    fam = this.props.index_case_id.famid;
                }

                family_get(fam, this.props.token,config.apis_configuration.api_phenostore)
                    .then(data => data.json())
                    .then((data) => {
                        if (!("message" in data)) {

                            let screen_w =   window.screen.width;
                            //let screen_h =   window.screen.height;
                            if(this.props.preview){
                                opts.symbol_size  = 10;
                                opts.height= 300;
                                opts.width= 500;
                                opts.font_size= "0.6em";

                            }
                            else if(screen_w < 1300 ){
                                opts.symbol_size = 10;
                                opts.height= 400;
                                opts.width= 500;

                            }
                            else{
                                opts.symbol_size  = 10;
                                opts.width= 500;
                                opts.height= 400;
                                opts.font_size= "0.9em";
                            }

                            opts.preview= this.props.preview;

                            let family_members = data;

                            if (family_members !== undefined && family_members !== null) {
                                opts.dataset = family_members;
                            } else {
                                opts.dataset = dataset;
                            }


                            let members_array = [];

                            opts.dataset.map(x => members_array.push(x.display_name));

                            members_array = members_array.filter(function( obj ) { return obj !== undefined });


                            ptree.build(opts);
                            this.setState({ped_opts: opts})


                        }

                    })
            }




        }


    };

    exportPNG  ()  {

       /* let props = this.props;
        let childNodes = document.getElementById("ped_viewer").childNodes;
        if (childNodes.length !== 0) {
            let svg = childNodes[0];
            let width = $("#ped_viewer").width() ;
            let height = svg.height.baseVal.value;
            let svgString = getSVGString(svg);
            svgString2Image(svgString, 2 * width, 2 * height, 'png', save); // passes Blob and filesize String to the callback

            function save(dataBlob, filesize) {
                let name = props.family + "_" + "pedigree.png";
                FileSaver.saveAs(dataBlob, name); // FileSaver.js function
            }
        }*/
    };


    render() {

        opts.targetDiv = "ped_viewer";


        let classNamePed = (this.props.preview) ? "ped_viewer_small" : "ped_viewer";

        return<React.Fragment>
            <div style={{float:"left", width:"98%", overflow: "auto" }}>
                {(this.state.rendered)
                    ? null
                    : <div>No Pedigree to display</div>
                }
                <div
                    ref={(el) => {this.pedigreeContainer = el }}
                    className={classNamePed}
                    id={"ped_viewer"}>

                </div>
            </div>
        </React.Fragment>

    }

}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if( state.authorization)
    {
        return { token: state.authorization.token }
    }
}


export default connect(mapStateToProps,null)(PedigreeViewerOnly);