import createVariantRow from "./components/createVariantRow";


export function getTableData(variants, annotations, analysisType, samples){

    let data = [];

    if(variants!== undefined){
        //self.getData(snv.hits._source)
        variants.hits.hits.forEach(function(hit){

            let obj = hit._source;

            // how many genes in effs;

            let gene_names = [...new Set(hit.fields.effs.map(s => s.gene_name))];

            // filter out undefined;

            gene_names = gene_names.filter(s => s !== undefined);

            gene_names.forEach(function(geneName){

                let row=  createVariantRow(obj, hit, annotations, analysisType, samples, geneName);
                data.push(row);

            })


        });
    }

    return data;
}