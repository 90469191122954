    import React from "react";
    import MappedText from "../../../../shared-components/mapText";

    export const GENOME_COVERAGE = [
        {
            Header: <MappedText text='Mean Coverage'/>,
            accessor: "mean_coverage",
            filter: '',
        },
        {
            Header: <MappedText text='Median Coverage'/>,
            accessor: "median_coverage",
            filter: '',
        },
        {
            Header: 'C10',
            accessor: "c10",
            filter: '',
        },
        {
            Header: 'C15',
            accessor: "c15",
            filter: '',
        }, 
        {
            Header: 'C20',
            accessor: "c20",
            filter: '',
        },

    ];