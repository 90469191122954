


import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React, {useState, useEffect} from "react";
import {get_wikipathways} from "../../../../../../../../../services/api/variant_external_calls";
import Divider from "@material-ui/core/Divider";
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import "./cards.css";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

function Wikipathways(props){

    const [wikis, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    //let {data} = props;

    useEffect(function(){

        if(props.input!== false){

            get_wikipathways(props.input)
                .then(r => {
                    setLoading(true);
                    return r.json()
                })
                .then(function(json){
                    setData(json);
                    setLoading(false);
                });
        }
        else{
            setData([]);
        }


    }, [props.input]);


    const renderWikiLinks = () => {

        return  <div className={"boxResults"}> {(wikis && wikis.result !== undefined)
            ? wikis.results.map(s => <div style={{paddingTop: "10px"}}><Link href={s.url}>{s.name}</Link></div>)
            : "No Associations"} </div>
    }


    return  <Grid item lg={12}>
        <div className={"my_paper_box"}>
            <Box p={1} >
                <Typography variant={"body1"}>Wikipathways <GPAP_InfoIcon style={{float: "right"}} title={"WikiPathways is a database of biological pathways maintained by and for the scientific community"}/></Typography>
                <Divider/>
                {
                    (loading)
                        ? <CircularProgress color="secondary"/>
                        : renderWikiLinks()

                }
                        </Box>
        </div>
    </Grid>

}


export default Wikipathways;