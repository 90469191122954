import React from "react";
import MappedText from "../../../../shared-components/mapText";

export const PHARMACOGENOMICS = [
    {
        Header: <MappedText text= 'Gene'/>,
        accessor: 'gene',
    },
    {
        Header: <MappedText text='Genotype'/>,
        accessor: 'genotype',
    },
    {
        Header: <MappedText text='Phenotype'/>,
        accessor: 'phenotype',
    },
    {
        Header: <MappedText text='Haplotype1'/>,
        accessor: 'haplotype1',
    },
    {
        Header: <MappedText text='Haplotype2'/>,
        accessor: 'haplotype2',
    },
    {
        Header: <MappedText text='Variant Data'/>,
        accessor: 'variant_data',
    },
];

export default PHARMACOGENOMICS;
