import Chart from "../../../../../../../../gpap-lib/components/bar-chart/Chart";
import React,{useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../../actions";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse/Collapse";
import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import {GPAP_Button_light} from "../../../../../../../../gpap-lib/components/Button_Collection";
import {BarChartOutlined} from "@material-ui/icons";




function ChromosomeChart(props){

    const [checked, setChecked] = useState(false);


    const handleChange = () => {

        setChecked(!checked);

    };


    const renderChromosomeChart = () => {

        if(props.total>1000){
            return  <div className="Chart"> <Typography>Only Variants from Chromosome 1 are shown.</Typography></div>
        }
        else if(props.data.length === 0){
            return null;
        }
        else{
            return <Chart initData = {props.data} data = {props.data} chartTitle = {""} selectedData={props.selectedData}/>
        }

    };




    return <div style={{textAlign:"left", marginLeft: "10px"}}>
        <GPAP_Tooltip title="# variants grouped by chromosome" >
            <GPAP_Button_light
            startIcon={   <BarChartOutlined/>}
            onClick={ handleChange } >Chromosome Summary</GPAP_Button_light>
        </GPAP_Tooltip>
        <div>
            <Collapse in={checked}>
                {renderChromosomeChart()}
            </Collapse>
        </div>
    </div>

}


function mapStateToProps (state) {
    if(state.selected_variant!==undefined) {

        return {selectedData: {d: parseInt(state.selected_variant.chrom)}}

    }
        else{
            return {selectedData:{d: undefined}}

        }

}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapStateToProps,mapDispatchToProps)(ChromosomeChart);




