
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import GeneticFindingsReport from "./GeneticFindingsReport.js";
import {splitSNVandCNV} from "../../../create-report/sections/genetic_findings/utils.js";
import MappedText from "../../shared-components/mapText.js";
import { useSelector } from "react-redux";
import { participant_get } from "../../../../../../services/api/apis.js";


export default function GeneticFindingsSection({report_id, data, handleSectionUpdate}){

    const token = useSelector(state => state.authorization.token); 
    const urlBase = window.config.apis_configuration.api_phenostore;

    const [tableData, setTableData] = useState({ snv_data: [], cnv_data: [] });
    const [dataSNV, setGeneticFindingsDataSNV] = useState([]);
    const [dataCNV, setGeneticFindingsDataCNV] = useState([]);




    useEffect(() => {
        const fetchData = async () => {
            if (!report_id) return;

            try {
                const response = await participant_get(report_id, token, urlBase);
                const json = await response.json();

                const allVariants = [];

                // SNV
                if (json.genes && Array.isArray(json.genes)) {
                    json.genes.forEach(gene => {
                        if (gene.variants && Array.isArray(gene.variants)) {
                            gene.variants.forEach(variant => {
                                variant.geneName = gene.gene.name;
                                allVariants.push(variant);
                            });
                        }
                    });
                }

                // CNV
                if (json.regions && Array.isArray(json.regions)) {
                    json.regions.forEach(region => {
                        if (region.variants && Array.isArray(region.variants)) {
                            region.variants.forEach(variant => {
                                variant.region = region.region;
                                allVariants.push(variant);
                            });
                        }
                    });
                }

                const initData = splitSNVandCNV(allVariants);
                setTableData(initData);
                setGeneticFindingsDataSNV(initData.snv_data);
                setGeneticFindingsDataCNV(initData.cnv_data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [report_id, token, urlBase]);
    
        const handleUpdate = (dataSelected, variantType) => {
            let updateData = [];
            if (variantType === "SNV") {
                setGeneticFindingsDataSNV(dataSelected.rows);
                updateData = [...dataSelected.rows, ...dataCNV];
            } else {
                setGeneticFindingsDataCNV(dataSelected.rows);
                updateData = [...dataSNV, ...dataSelected.rows];
            }
    
            let sectionData = { rows: updateData, conclusions: "", recommendations: "" };
            handleSectionUpdate(sectionData, "Genetic_Findings");
        };


    return    <>
        <Typography variant="body1">
        <MappedText text="These are the variants that were tagged in the"/>{" "}
        <strong><MappedText text="Genetic Findings"/></strong><MappedText text=" tab of this Interpretation Request. Check all the variants that you want to display in the final report."/>
    </Typography>
    <Typography variant="h6">SNVs & INDELs</Typography>
    <GeneticFindingsReport
        handleUpdate={handleUpdate}
        data={tableData.snv_data}
        variant_type="SNV"
    />
    <Typography variant="h6">CNVs</Typography>
    <GeneticFindingsReport
        handleUpdate={handleUpdate}
        data={tableData.cnv_data}
        variant_type="CNV"
    />
        </>

}