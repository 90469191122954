
import React from "react";
import { useSelector } from "react-redux";
import {Grid, Typography, Box} from "@mui/material";
import DataField from "../dir_overview/Datafield";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import PDFViewer from "../../../components/instand-components/pdf";


function Biomarkers(props) {
   
    const {allDataApis} = useSelector((state) => state.generaltable);
    const binLevel = allDataApis?.tumor?.bin_level_log2_coverages_and_segmentation_calls;
    const copyNumber = allDataApis?.tumor?.copy_number_per_chromosome;
    
    const mutationalSignatures = allDataApis?.tumor?.mutational_signatures;
    
    return <Grid container direction={"row"}>
        <Grid item xs={12}>

            <Typography variant={"h5"}  mb={2} style={{  fontWeight: "700" }}><MappedText text={"Tumor characteristics"}/></Typography>
            
            <Grid container spacing={1}  mb={4}>
                <Grid item lg={3} md={4} xs={6} >
             
                    <DataField mb={2} title="Tumor purity" value={allDataApis?.samples?.percentage_of_tumoral_cells } />
                    
                </Grid>
                
                <Grid  item lg={3} md={4} xs={6}>
                    <DataField title="Average ploidy" value={allDataApis?.tumor?.average_ploidy}  />
                </Grid>
               
            </Grid>

            <Typography variant={"h5"}  mb={2} style={{  fontWeight: "700" }}>Biomarkers of genetic instability</Typography>

            <Grid item sx={12} mb={4}>
                <DataField title="Microsatellite status" value={allDataApis?.tumor?.microsatellite_status || "NA"} helperText={"Predicted by MSIsensor"}/>
            </Grid>

            <Grid container spacing={2} mb={4}>
                <Grid  item lg={3} md={4} xs={6} >
                    <DataField title="Tumor mutational burden" value={allDataApis?.tumor?.tumor_mutational_burden || "NA"} helperText={"Computed by pyTMB"}/>
                </Grid>
            </Grid>

            <Grid item xs={12} mb={4}>
                <DataField title="Homologous recombination deficiency (HRD) status" value={allDataApis?.tumor?.homologous_recombination_deficiency_status || "NA"}helperText={"Computed by Classifier of Homologous Recombination Deficiency (CHORD)"} />
            </Grid>

            <Grid item xs={12}>
            <Typography variant={"h5"}  mt={2} style={{  fontWeight: "700" }}><MappedText text={"Bin-level log2 coverages and segmentation calls"}/></Typography>
            </Grid>

            <Grid item xs={12}>
            <Typography variant={"caption"}><MappedText text={"Source: CNVkit"}/></Typography>
            </Grid>

                <Grid item xs={12}>
                    {binLevel && <img src={`data:image/png;base64,${binLevel}`} alt="Bin-level Log2 Coverages" style={{width: "40%"}}/>}
                </Grid>

            <Grid item xs={12}>
            <Typography variant={"h5"}  mt={3} style={{  fontWeight: "700" }}><MappedText text={"Copy number per chromosome"}/></Typography>
            </Grid>

            <Grid item xs={12}>
            <Typography variant={"caption"}  mb={2}><MappedText text={"Source: CNVkit"}/></Typography>
            </Grid>
           

            <Grid item xs={12}>
            <Typography variant={"body2"} mt={3}><span style={{ fontWeight: "bold" }}><MappedText text={"Tumor Sample ID:"}/></span> S134576</Typography>
            </Grid>
           
                <Grid item xs={12}>

                <Grid item xs={12}>
                {copyNumber && <PDFViewer base64String={copyNumber} />}

                </Grid>

                </Grid>

            <Grid item xs={12}>
            <Typography variant={"h5"}  mt={3} mb={2} style={{  fontWeight: "700" }}><MappedText text={"Mutational signatures"}/></Typography>
            </Grid>

            <Grid item xs={12}>
            <Typography variant={"caption"}><MappedText text={"Source: SigProfilerAssignment"}/></Typography>
            </Grid>

            <Grid item xs={12}>
                
               
                    <Grid item xs={12}>
                {mutationalSignatures && <PDFViewer base64String={mutationalSignatures} />}

                </Grid>
                </Grid>
        </Grid> 
    </Grid>
}

export default Biomarkers;