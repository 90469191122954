import React from "react";
import GPAP_Header from "../../header/GPAP_Header";
import InstandHeader from "./InstandHeader";



export default function Headings(props){
   

        if(props.project==="instand"){
        return  <InstandHeader />

        }else{
            return  <GPAP_Header/>
        }
}


