

import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import auth from "../../../services/session/Auth";
import { Container, Grid } from '@mui/material';
import { Box } from '@material-ui/core';
import InstandGeneralLayout from '../../instand-components/InstandGeneralLayout';
import Headings from '../../instand-components/header/Headings';
import { flexbox } from '@material-ui/system';


function AuthenticatedRoute({component: Component,enabled, project, ...rest}) {

    return (
        <Route
            {...rest}
            render={ (props) => {

              if(auth.user.authenticated === true || !enabled){

                    // render Page Layout and component;
                  return  <Box style={{width: '100%', height: '100%'}}>
                            
                            
                              <Headings project={ project }/>
                              {(project === "instand")
                              
                              ? <InstandGeneralLayout component= {<Component username = {auth.user.username} group={auth.user.group} {...props} {...rest} />} />
                              : <Component username = {auth.user.username} group={auth.user.group} {...props} {...rest} />
                              
                              }
                            
                          </Box>
              }else{
                return <Redirect
                      to={{
                          pathname: "/login/",
                          state: {from: props.location}
                      }}
                  />
              }

            }
            } />
    )
}



export default AuthenticatedRoute;