import React, {Fragment, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as Actions from "../../../../reducers/sample_actions";
import GPAP_InfoIcon from "../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import Box from "@material-ui/core/Box";
import {GPAP_Button} from "../../../../../../../../gpap-lib/components/Button_Collection";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
    },
    margin: {
        height: theme.spacing(3),
    },
}));



function valuetext(value) {
    return value;
}





const GQForm = (props) => {

    let config = props.config;

    let init_value = (Array.isArray(props.config.defaultValue) ? [...props.config.defaultValue] : props.config.defaultValue );

    let {inheritance_key, sample_id, queryKey, inheritance_type} = props;
    const [value, setValue] = useState();
    const [edit, setEdit] = useState(false);

    const handleChange = (e, val) => {

        let analysisType = props.studySettings.analysisType;
        props.actions.setGQ_parameter(sample_id, queryKey, val, inheritance_key, inheritance_type, analysisType)

        setValue(val);
    };


    function valueLabelFormat(text){
            if(props.name==="Alternate"){
                return parseInt(text)/100;
            }
            else{
                return text;
            }
    }

    const GPAP_Slider = () => {

        return <Slider
            onChange={ handleChange}
            defaultValue={config.defaultValue}
            getAriaValueText={valuetext}
            valueLabelFormat={valueLabelFormat}
            aria-labelledby= {config.label}
            step={config.step}
            valueLabelDisplay="auto"
            marks={config.marks}
            value={value}
        />
    }

    const getSlider = () => {

        if(!Array.isArray(config.defaultValue)){
           return GPAP_Slider();
        }
        else if(edit){
            return GPAP_Slider();
        }
        else{

            let val = (config.defaultValue) ? config.defaultValue.map( s=> s/100).toString() : null;
            return <Fragment>
                <div>
                    <IconButton onClick={() => setEdit(true)}>
                        <EditIcon/>
                    </IconButton>
                    AD Low, AD High: {val}</div>
            </Fragment>
        }



    }



    return (
        <Fragment>
            <Typography id="discrete-slider-custom" gutterBottom>
                {props.label} <Box m={1} style={{float: "right"}}><GPAP_InfoIcon title={props.tooltip} /></Box>
            </Typography>
            {
                getSlider()
            }

        </Fragment>)


};

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization)
    {
        return {
            studySettings: state.studySettings,
            token: state.authorization.token
        }
    }
    else{
        return {
            studySettings: "",
            token: state.authorization.token
        }
    }
}



// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(GQForm);




