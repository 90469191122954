

import React, {useEffect, useState} from 'react';
import 'regenerator-runtime/runtime'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CohortTableToolbar from "./TableToolbar";
import CohortTableHead from "./TableHead";
import ParticipantRow from "./ParticipantRow";
import {GPAP_Button} from "../../../../../../../../../../../gpap-lib/components/Button_Collection";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AggregationView from "../AggregationView";
import Switch from "@material-ui/core/Switch";
import {FormControlLabel, FormGroup} from "@material-ui/core";
import Box from "@material-ui/core/Box";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontSize: "1em"
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        fontSize: ".9em"
    },
    tableCell:{
        fontSize: ".9em",
        color: "blue"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0, position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function MUITable(props) {

    const default_rows_pp = 5;
    const classes = useStyles();
    const participant_id = "report_id";
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('report_id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0); // this is the loaded data page;
    const [realPageNumber, setRealPageNumber] = useState(0);

    let row_size = (props.rows!== undefined) ? props.rows.length/default_rows_pp : 0;
    let row_length= (props.rows!== undefined) ? props.rows.length : 0;
    const [totalPagesLoaded, setTotalPagesLoaded] = useState(row_size);
    const [totalPages, setTotalPages] = useState(Math.ceil(props.total_participants/default_rows_pp));
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(default_rows_pp);
    const [totalRows, setTotalRows] = useState(props.total_participants);
    const [totalObj, setTotalForQuery] = useState({query: 0, total: 0});


    const [state, setState] = useState({
        showCharts: false
    });


    useEffect(() => {
        setPage(0);
        let rows = (props.rows === undefined) ? []: props.rows;
        let row_size = (props.rows!== undefined) ? props.rows.length/default_rows_pp : 0;
        setRows(rows);

        setTotalPagesLoaded(row_size);
        setRealPageNumber(row_size*(props.table_page-1)+1);
        setTotalRows(props.total_participants);
        setTotalPages(Math.ceil(props.total_participants/default_rows_pp))

        if(props.total!==0 && props.total!== undefined){
                if(props.query_id!== totalObj.query_id){
                    // change the total for the query_id (case cohortapp or search across all
                    setTotalForQuery({
                        query_id: props.query_id,
                        total: props.total
                    });
                }

        }



    }, [props.rows]); // Only re-run the effect if count changes


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };



    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n[participant_id]);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            //newSelected = newSelected.concat(selected, name);
            newSelected.push(name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleChangePage = (event, newPage) => {
        if(newPage >= totalPagesLoaded){
           props.loadMore(true)  // true -> forward
        }
        else if(newPage<=0 && props.table_page!==1){
            props.loadMore(false)// false -> go back
        }
        else{
            setPage(newPage);
            (newPage>page) ? setRealPageNumber(realPageNumber+ (newPage-page)) : setRealPageNumber(realPageNumber-(newPage-page))
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTotalRows(props.total_participants);
        setTotalPages(Math.ceil(props.total_participants/event.target.value));

        setTotalPagesLoaded(props.rows / (parseInt(event.target.value, 10)));
        setPage(0);
    };


    const resetToSearchAll = () => {

        setSelected([]);
        props.selectParticipants("ALL_SAMPLES");

    }

    /*const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };*/

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, row_length - page * rowsPerPage);

    let BACK_DISABLED = (realPageNumber === 1 );

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const getText = () => ({ from, to, count }) => {

        return `(page ${realPageNumber} of total ${totalPages}). 
        Displaying participants ${from}-${to} of total ${props.total_participants}`;

        // , (loaded pages ${totalPagesLoaded})
    }


    const showTable = () => {

        return <React.Fragment>
            <CohortTableToolbar
                loadVariants = {() => props.selectParticipants(selected, true)}
                sendSelected={ false }
                selected = {selected}
                numSelected={selected.length}
                total_participants={props.total_participants}/>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table">
                    <CohortTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={row_length}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row[participant_id]);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return <ParticipantRow
                                    participant_id = {participant_id}
                                    handleClick={handleClick}
                                    isItemSelected={isItemSelected}
                                    labelId={labelId}
                                    row={row}
                                    index={index}
                                    experiments={props.experiments.filter(d => d.Participant_ID === row[participant_id])}
                                />
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{disabled:BACK_DISABLED}}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={getText()}
            />
        </React.Fragment>
    }

    const showView = () => {

        return (state.showCharts) ? <AggregationView initData={props.aggregations}/> : showTable()

    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Box p={1}> <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={state.showCharts}
                                onChange={handleChange}
                                color="primary"
                                name="showCharts"
                            />
                        }
                        label="Show Participant Summary"
                    />
                </FormGroup>
                </Box>
                {(
                     (props.total !== undefined && totalObj.total > props.total))
                    ? <GPAP_Button onClick={ () => resetToSearchAll() } startIcon={<ArrowBackIcon/>}>Back to all participants</GPAP_Button>
                    : null}
                {(props.rows!== undefined)
                    ? showView()
                    : null
                }

            </Paper>
        </div>
    );
}