

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import _ from "lodash";
import TextField from "@material-ui/core/TextField/TextField";
import React, {useEffect, useState} from "react";


function GeneralAutoComplete(props){


    const [tags, setTags] = useState([]);
    const [options, setOptions] = useState([]);

    const onInputChange = (event,value) => {

        props.onInputChange(value);

    };


    useEffect(function(){

            setOptions(props.results);

    }, [props.results]);

   useEffect(function(){

        if(props.default_tags!== undefined){
            setTags([
                ...props.default_tags
            ]);
        }
    }, [props.default_tags]);


    const onTagsChange = (values, reason) => {

        //setTags([...props.default_tags, ...values]);
        // This will output an array of objects
        // given by Autocomplete options property.
        if (props.passSelected !== undefined) {

            //let field = this.getField();

            if (values !== undefined && values !== null) {
                props.passSelected(values, reason);
            } else {
                props.passSelected(undefined);
            }
        }
    };



    return <Autocomplete
        multiple
        options={options}
        autoHighlight={true}
        //limitTags={5}
        getOptionLabel={option => (option.label!==undefined) ?  option.label : ""}
        value={tags}
        onChange={(event, newValue, reason) => {
            setTags(
                [...newValue]);
            onTagsChange([ ...newValue], reason);
        }}
        onInputChange={_.debounce(onInputChange, 1000)} //** on every input change hitting my api**
        renderInput={params => (
            <TextField
                {...params}
                variant="outlined"
                label={props.title}
                margin="normal"
                fullWidth
            />
        )}
    />
}


export default GeneralAutoComplete;