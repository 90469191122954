



const solutions = [
    {experiment_id: "Case1C", variant: [{chr_coordinate: "19:39062815G>C", cDNA: "c13888G>C", protein: "p.Glu4630Gln", transcript:"ENST00000355481", gene: "RYR1"}] , disease: "#117000: Central Core Disease of Muscle ", inheritance: "Autosomal dominant  (de novo)", participant_id: "P0007498", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=20" },
    {experiment_id: "Case2C", variant: [{chr_coordinate: "4:16037357C>T", cDNA: "c.303+1G>A", protein: "----", transcript:"ENST00000510224", gene: "PROM1"}], disease: "#608051: macular Dystrophy, Retinal, 2; MCDR2", inheritance: "Autosomal dominant", participant_id: "P0007501", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=11" },
    {experiment_id: "Case3C", variant: [{chr_coordinate: "11:22257752G>T", cDNA: "c.[c.191dupA] + [c.692G>T]", protein: "p.[p.Asn64LysfsTer15]+[p.Gly231Val]", transcript:"----", gene: "ANO5"},{chr_coordinate: "11:22257752G>T", cDNA: "22242646C>CA", protein: "----", transcript:"", gene: "ANO5"} ], inheritance: "Autosomal recessive", disease: "#611307 Muscular Dystrophy, Limb-Girdle, Autosomal Recessive 12; LGMDR12", participant_id: "P0007504", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=12" },
    {experiment_id: "Case4C", variant: [{chr_coordinate: "15:89861960A>C ", cDNA: "c.3294T>G", protein: "p.Asn1098Lys", transcript:"ENST00000268124", gene: "POLG"}], disease: "#203700 Mytochondrial DNA Depletion Syndrome 4A (ALPERS TYPE); MTDPS4A", inheritance: "Autosomal recessive", participant_id: "P0007507", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=13" },
    {experiment_id: "Case5C", variant: [{chr_coordinate: "X:31241187C>T", cDNA: "c.9338G>A", protein: "p.Arg3113Gln", transcript:"ENST00000357033", gene: "DMD"}], disease:"#310200 Muscular dystrophy, duchenne type", inheritance: "X-Linked recessive", participant_id: "P0007510", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=14" },
    {experiment_id: "Case6C", variant: [{chr_coordinate: "17:41215920G>T", cDNA: "c.5123C>A", protein: "p.Ala1708Glu", transcript:"ENST00000357654", gene: "BRCA1"}], disease:"OMIM:604370 BREAST-OVARIAN CANCER, FAMILIAL, SUSCEPTIBILITY TO, 1; BROVCA1", inheritance: "Autosomal Dominant", participant_id: "P0008909", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=25" },
    {experiment_id: "Case7C", variant: [{chr_coordinate: "3:37053589C>T", cDNA: "c.676C>T", protein: "p.Arg226Ter", transcript:"ENST00000231790", gene: "MLH1"}], disease:"OMIM:276300 MISMATCH REPAIR CANCER SYNDROME 1; MMRCS1", inheritance: "Autosomal Recessive", participant_id: "P0008912", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=26" },
    {experiment_id: "Case8C", variant: [{chr_coordinate: "5:112111435G>C", cDNA: "c.531+1G>C", protein: "-----", transcript:"ENST00000457016", gene: "APC"}], disease:"Inherited digestive cancer- predisposing syndrome", inheritance: "Autosomal Recessive", participant_id: "P0008915", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=27" },
    {experiment_id: "Case9C", variant: [{chr_coordinate: "17:29490249C>T (de novo)", cDNA: "c.334C>T", protein: "p.Gln112Ter", transcript:"ENST00000358273", gene: "NF1"}], disease:"OMIM: 162200 NEUROFIBROMATOSIS, TYPE I; NF1\n", inheritance: "Autosomal Dominant", participant_id: "P0008918", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=28" },
    {experiment_id: "Case15C", variant: [{chr_coordinate: "SNV: 22:20030915C>T, CNV: 22:20030879-20052185", cDNA: "c.94C>T + CNV (deletion)", protein: "p.Arg32Ter + CNV (deletion)", transcript:"ENST00000327374", gene: "TANGO2"}], disease:"Metabolic disease with epilepsy", inheritance: "Autosomal Recessive - c.het", participant_id: "P0016732", link_to_gpap: "https://playground.rd-connect.eu/genomics/?filters=29" },




]


export default solutions;