


export function formatStudy(study){

    //let samples = [];
    //study.analyses.forEach( s => samples = samples.concat(s.samples));

    return {
        studyName:study.studyName,
        description: study.description,
        /*creationDate: study.creationDate,*/
        //samples: samples,
        info: study.info,
        permission: (study.permission!== undefined) ? study.permission : "public"
    }
}


function getDate(my_date){

    let date= new Date(my_date);
    if(isNaN(date)){
        return JSON.parse(my_date);
    }
    else{
        return new Date(my_date)
    }
}


// TODO: to finish
export function formatQuery (query, analysis_id){

    // GET CURRENT DATE;

    // remove samples_germline and samples_somatic
    let filters_json;
    if(query.filters!== undefined){
        filters_json = JSON.parse(query.filters);
    }
    else{
        filters_json = JSON.parse(JSON.stringify(query.filter_set.filters));
    }


    ["samples_germline", "samples_somatic"].forEach(function(k){
        delete filters_json[k];
    })
    
    console.log("query post", filters_json);

    return {
        "analysis_id": analysis_id,
        "name": (query.name!== undefined) ? query.name : query.queryName,
        "filter_set_id": (query.filter_set_id!== undefined) ? query.filter_set_id : -1,
        "filter_set_name": (query.filter_set_name!== undefined) ? query.filter_set_name : -1,
        "filters": filters_json,
        "creationDate": (query.creationDate!== undefined) ? query.creationDate : getDate(query.filter_set.creationDate),
        "outputs": query.outputs,
        "extra": (query.extra!== undefined) ? query.extra : []

    }

}


// TODO: to finish
export function formatQueryUpdate (query, analysis_id, query_id){

    // GET CURRENT DATE;

    return {
        "analysis_id": analysis_id,
        "name": (query.name!== undefined) ? query.name : query.queryName,
        "filter_set_id": -1,
        "filters": JSON.parse(query.filters),
        "creationDate": (query.creationDate!== undefined) ? query.creationDate : JSON.parse(query.filter_set.creationDate),
        "outputs": query.outputs,
        "query_id": query_id

    }



}


export function format_Analysis_To_Update(analysis){

    return {
        analysis_type: analysis.analysis_type,
        description: analysis.description,
        inheritance_mode_id: analysis.inheritance_mode_id,
        labelled: (analysis.labelled!==undefined) ? analysis.labelled : {},
        samples: analysis.samples,
        study_id: analysis.study_id,
        analysis_id: analysis.analysis_id
    }

}