import React from "react";
import { Grid, Typography } from "@mui/material";
import Fusions_Table from "./Fusions_config";
import { useSelector } from 'react-redux';
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";

function Fusions() {
  const token = useSelector(state => state.authorization.token);
  const baseUrl = window.config.apis_configuration.api_nextgpap_server_elastic;
  const url = `${baseUrl}/api/gene_fusion`;
  // const participant = useSelector(state => state.generaltable.allDataApis.participants[0]);
 
  return (
    <Grid container direction={"row"}>
      <Grid item lg={12}>
        <Typography variant={"h5"} style={{  fontWeight: "700" }}><MappedText text="Gene Fusions"/></Typography>
      </Grid>
      <Grid item lg={12}>
        <Fusions_Table
          url = {url}
          token={token}
          // participant={participant}
        />
      </Grid>
    </Grid>
  );
}

export default Fusions;
