
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

export default function MappedText(props){

    const { t, i18n } = useTranslation();



    return <Fragment>
        {t(props.text)}
    </Fragment>



}