import {getFamily} from "../../../../../../../services/api/get";


export async function getFamilyMembers(entry, token) {

    let {api_phenostore} = config.apis_configuration;
    //let entry = participant;
    //let keys = Object.keys(entry);
    let id = entry.report_id;
    let family_id = entry.famid;

    if(family_id !== ""){
        const family_json = await getFamily(api_phenostore, token, family_id).then(resp => resp.json());
        if(Array.isArray(family_json)){
            let members_with_id = family_json.filter(mem => mem["external_name"] !== undefined);
            // find proband
            let proband = family_json.find(d => d.proband === true);
            return {members: members_with_id, proband: proband, family_id: family_id};
        }
        else{
            return {members: [], proband: undefined, family_id: family_id};
        }
    }
    else{
        return {members: [], proband: undefined, family_id: family_id};
    }



}


