export const filters = () => {
  
  //Helper function to deal with population numeric filter logic
  const numericFilter = (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      if (rowValue === 'N/A' || rowValue === '' || rowValue === undefined) {
        return true;
      }
      const numericValue = parseFloat(rowValue);
      if (isNaN(numericValue)) {
        return false;
      }
      const roundedValue = Math.round(numericValue * 1e6) / 1e6;
      return roundedValue <= parseFloat(filterValue);
    });
  };

  return {
    text: (rows, id, filterValue) => {

      if (id == "AnnotSV population AFMax") {
        return numericFilter(rows, id, filterValue);
      }

      return rows.filter((row) => {
        const rowValue = row.values[id];

        if (id == "SV_chrom"){
          return String(rowValue) === String(filterValue);
        }
        
        return rowValue !== undefined
          ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
          : true;
      });
    },

    multiSelect: (rows, id, filterValue) => {
      if (filterValue.length === 0) {
        return rows;
      }

      return rows.filter((row) => {
        const rowValue = row.values[id];
        return filterValue.includes(rowValue);
      });
    },

    numeric: numericFilter
  };
};