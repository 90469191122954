
import React, { useState, useEffect } from 'react';
import {TextField, Button, Container, InputLabel} from '@material-ui/core';
import {useSelector} from "react-redux";
import {Grid, Paper, Typography} from "@mui/material";
import {GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import Select from '@mui/material/Select';

import dayjs, { Dayjs } from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from "@mui/material/FormControl";
import {DateTimePicker} from "@mui/x-date-pickers";
import SearchID from "../../study-page/create-study/analysis-page/components/samples-section/SearchID";
import GPAP_Dialog from "../../../gpap-lib/components/GPAP_Dialog";

import { experiments_view_new } from '../../../services/api/get';
import { dir_insert } from '../../../services/api/post';

import { ExperimentsAssociated } from './components/ExperimentsAssociated';


import { dirAnalysisTypes } from './UtilCreateDir'
import auth from '../../../services/session/Auth'

const config = window.config;
const URL_API_DM = config.apis_configuration.api_data_management; 


const CreateDir = () => {

    const token = useSelector(state => state.authorization.token);
    const groups = useSelector(state => state.authorization.groups);

    const [openDialog, setDialog] = useState({
        status: false,
        title: "",
        text: "",
        open: false,
        type: "success"
    });

    const [saved_DIR, setSaveDIR] = useState({});
    const [validate, setValidate] = useState(false);

    const [searchExperimentControl, setSearchExperimentControl] = useState(false);

    const [formValues, setFormValues] = useState({
        analysis: dirAnalysisTypes[0]['id'],
        assignee: '',
        clinical_referrer: '',
        clinical_referrer_contact: '',
        comments: '',
        deadline: new Date().toISOString().slice(0, 19).replace('T', ' '),
        tumor_experiment_id: '',
        control_experiment_id: '',
        group_owner: (groups.length>0) ? groups[0] : "",
        hospital_name: '',
        priority: 'medium',
        status: 'to do',
        filter_set: 0,
        predefined_list: 0,
    });

    const [formErrorValues, setFormErrorValues] = useState({
        tumor_experiment_id: "",
        control_experiment_id: ""
    });

    const [filterSetList, setFilterSetList] = useState([]);
    const [predefinedGeneList, setPredefindedGeneList] = useState([]);

    const [participantID, setParticipantID] = useState({tumor_participant_id: "", control_participant_id: ""});

    const [value, setValue] = useState(
        dayjs('2014-08-18T21:11:54'),
    );

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleCloseDialog = () => {
        setDialog({
            status: false,
            title: "",
            text: "",
            open: false,
            type: "success"
        });
    }

    const handleSearchExperiment = (field, experiment) => {
        if(experiment !== undefined){
            setFormValues({ ...formValues, [field]: experiment });
            setValidate(false);
            setFormErrorValues({ ...formErrorValues, [field]: ""});
            if (field === "tumor_experiment_id" && formValues.analysis === "somatic_tumor_normal"){
                setSearchExperimentControl(true);
            } 
        }
    }

    const handleSearchParticipant = (field, participant) => {
        if(participant !== undefined){
            setParticipantID({ ...participantID, [field]: participant });
            setFormValues({ ...formValues, ["control_experiment_id"]: "", ["tumor_experiment_id"]: "" });
            setValidate(false);
            setFormErrorValues({ ...formErrorValues, ["control_experiment_id"]: "", ["tumor_experiment_id"]: ""});
        }
    }

    const handleSubmit = () => {

        const payload = { ...formValues };

        dir_insert(payload)
        .then((response) => { 
            if (response.ok){ console.log(response); return response.json();}
            else {console.log(response); throw new Error(response.statusText);}
        })
        .then((data) => {
            console.log('Success:', data);
            setSaveDIR(data.created_dir);
            setDialog({
                status: true,
                title: "DIR " +  data["created dir"].dir_id +  " created successfully",
                text: "You can find the created DIR in the Intepretation Request page",
                open: true,
                type: "success"
            });
        })
        .catch((error) => {
            console.error('ERROR:', error);
            setDialog({
                status: false,
                title: "Error in creating DIR",
                text: "Please, review inserted data. Error code: " + error,
                open: true,
                type: "error"
            });
        });
    };

    const handleDateChange = (newValue) => {
        setFormValues({ ...formValues, deadline: newValue })
    };

    const renderDialog = () => {
        return <GPAP_Dialog
            open={openDialog.open}
            handleClose={handleCloseDialog}
            title={openDialog.title}
            text={openDialog.text}
            onExitText={false}
            onConfirmText={"Ok"}
            onExitAction={false}
            onConfirmAction={handleCloseDialog}
            type={openDialog.type}
        />
    }

    // Load Filter Set List when view is load fot the first time
    useEffect(() => {
        const url = window.config.apis_configuration.api_nextgpap_server;
        fetch( url+ '/filter_set', {
            method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": token }
        })
        .then((response) => response.json())
        .then((data) => {
                setFilterSetList(data.data.filter(list=>list.filter_set_name !== "").map(fs => ({name: fs.filter_set_name, id: fs.id})));
        })
        .catch((error) => {
            setDialog({
                status: false, open: true, type: "error",
                title: "Could not get filter set list",
                text: "Broken connection to back-end API. Please try again later."                
            });
        });
    }, []);

    // After Having the filter set loaded then require the gene list
    useEffect(() => {
        const url = window.config.apis_configuration.api_nextgpap_server;
        fetch( url+ '/get_all_lists', {
            method: 'GET', headers: { 'Content-Type': 'application/json', "Authorization": token }
        })
        .then((response) => response.json())
        .then((data) => {
            setPredefindedGeneList(data.data.map(gl => ({name: gl.title, id: gl.id})));
        })
        .catch((error) => {
            setDialog({
                status: false, open: true, type: "error",
                title: "Could not get predefined gene lists",
                text: "Broken connection to back-end API. Please try again later."
            });
        });
    }, [filterSetList]);

    useEffect(() =>{
        if (searchExperimentControl) {
            // Define body post to Experiment View
            const body = {
                "page":1,"pageSize":1,
                "fields":["ExperimentID","control_experiment_id"],
                "filtered":[{"id":"ExperimentID", "value":[formValues.tumor_experiment_id]}],
            }
            experiments_view_new(URL_API_DM,auth.getToken(),body)
            .then((response) => {
                 if (response.ok) return response.json();
                 else throw new Error(response.statusText);
            })
            .then((dataResponse) => {
                if (dataResponse.items.length > 0) {
                    let control_experiment_id = dataResponse.items[0].control_experiment_id;
                    if (control_experiment_id !== "" && control_experiment_id !== null) {
                        setFormValues({ ...formValues, "control_experiment_id": control_experiment_id });
                        setFormErrorValues({ ...formErrorValues, "control_experiment_id": "" });
                    } else {
                        setFormErrorValues({ ...formErrorValues, "control_experiment_id": "No control experiment id for tumor experiment id: " + formValues.tumor_experiment_id });
                    }
                }
                setSearchExperimentControl(false);
            })
            .catch((error) => {
                setSearchExperimentControl(false);
            });
        }
    },[searchExperimentControl]);

    return (
        <Container>
            {renderDialog()}
            <Paper elevation={3}>
                <Box p={3}>
                <Grid container spacing={2} direction={"column"}>
                    <Grid item={12}>
                        <Typography variant={"h3"}>Create a new DIR </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2}>
                            <form>
                                <Grid container spacing={2} direction={"row"}>
                                    <Grid item lg={12}>
                                        <Typography variant={"h6"}>DIR fields</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl style={{width: "300px"}}>
                                            <InputLabel>Analysis</InputLabel>
                                            <Select name="analysis"  value={formValues.analysis}
                                                    onChange={handleInputChange}>
                                                {dirAnalysisTypes.map((analysis) => (
                                                    <MenuItem value={analysis.id}> {analysis.label} </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            name="assignee"
                                            label="Assignee"
                                            value={formValues.assignee}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            name="clinical_referrer"
                                            label="Clinical Referrer"
                                            value={formValues.clinical_referrer}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            name="clinical_referrer_contact"
                                            label="Clinical Referrer Contact"
                                            value={formValues.clinical_referrer_contact}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "300px"}}>
                                        <InputLabel>Group Owner</InputLabel>
                                        <Select
                                            name="group_owner"
                                            value={formValues.group_owner}
                                            onChange={handleInputChange}>
                                            {groups.map(g =>  <MenuItem value={g}>{g}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            name="hospital_name"
                                            label="Hospital Name"
                                            value={formValues.hospital_name}
                                            onChange={handleInputChange}
                                        /></Grid>
                                    <Grid item xs={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                label="Deadline"
                                                value={value}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "300px"}}>
                                            <InputLabel>Priority</InputLabel>
                                            <Select
                                                name="priority"
                                                value={formValues.priority}
                                                onChange={handleInputChange}>
                                                <MenuItem value="low">low</MenuItem>
                                                <MenuItem value="medium">Medium</MenuItem>
                                                <MenuItem value="high">High</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "300px"}}>
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                                name="status"
                                                value={formValues.status}
                                                onChange={handleInputChange}>
                                                <MenuItem value="to do">To Do</MenuItem>
                                                <MenuItem value="in progress">In Progress</MenuItem>
                                                <MenuItem value="done">Done</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/*<TextField
                                            name="status"
                                            label="Status"
                                            value={formValues.status}
                                            onChange={handleInputChange}
                                        />*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "300px"}}>
                                            <InputLabel>Filter Set</InputLabel>
                                            <Select
                                                name="filter_set"
                                                value={formValues.filter_set}
                                                onChange={handleInputChange}>
                                                {filterSetList.map(fs =>  <MenuItem value={fs.id}>{fs.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={{width: "300px"}}>
                                            <InputLabel>Gene List</InputLabel>
                                            <Select
                                                name="predefined_list"
                                                value={formValues.predefined_list}
                                                onChange={handleInputChange}>
                                                {predefinedGeneList.map(gl =>  <MenuItem value={gl.id}>{gl.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                 
                                    <ExperimentsAssociated 
                                        analysis_type={formValues.analysis}
                                        error_tumor_experiment_id={formErrorValues.tumor_experiment_id}
                                        error_control_experiment_id={formErrorValues.control_experiment_id}
                                        handleSearchExperiment={handleSearchExperiment}
                                        handleSearchParticipant={handleSearchParticipant}
                                        control_experiment_id={formValues.control_experiment_id} 
                                        tumor_experiment_id={formValues.tumor_experiment_id}
                                        control_participant_id={participantID.control_participant_id} 
                                        tumor_participant_id={participantID.tumor_participant_id}
                                    />

                                    <Grid item lg={12}>
                                        <Typography variant={"h6"}>Leave any comment on this case</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            multiline
                                            name="comments"
                                            label="Comments"
                                            value={formValues.comments}
                                            onChange={handleInputChange}
                                        /></Grid>
                                    <Grid item xs={12}>
                                        <GPAP_Button onClick={handleSubmit}>
                                            Save DIR
                                        </GPAP_Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>

                    </Grid>
                    </Grid>
                </Box>
                    </Paper>

        </Container>

    );
};

export default CreateDir;
