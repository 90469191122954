import withStyles from "@material-ui/core/styles/withStyles";
import {Tooltip} from "@material-ui/core";

const GPAP_Orange_Tooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "#FF9800",
        fontSize: "12px"
    }
})(Tooltip);


export default GPAP_Orange_Tooltip