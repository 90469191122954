const coverageGenesData = [
    {
        "gene_symbol": "NA",
        "transcript": "NA",
        "mean_coverage": "NA",
        "median_coverage": "NA",
        "c1": "NA",
        "c10": "NA",
        "c15": "NA",
        "c20": "NA",
    },
    {
        "gene_symbol": "NA",
        "transcript": "NA",
        "mean_coverage": "NA",
        "median_coverage": "NA",
        "c1": "NA",
        "c10": "NA",
        "c15": "NA",
        "c20": "NA",
    },
    {
        "gene_symbol": "NA",
        "transcript": "NA",
        "mean_coverage": "NA",
        "median_coverage": "NA",
        "c1": "NA",
        "c10": "NA",
        "c15": "NA",
        "c20": "NA",
    }
];
export default coverageGenesData;
