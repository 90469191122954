

import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={0}>
                    <Typography component={"div"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



