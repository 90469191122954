
import React from "react";
import MappedText from "../../shared-components/mapText";



const tagged_treatments_sample = [{
    "gene": "KMT2C",
    "user": "test",
    "therapy": "None  Aromatase Inhibitor",
    "variant": "p.Lys2815fs",
    "timestamp": "12-02-2024 10:00:53",
    "match_type": "Gene",
    "cancer_type": "\"Skin Squamous Cell Carcinoma  Breast Cancer\"",
    "evidence_level": "B",
    "interpretation": "Treatment 1",
    "therapy_status": "sensitive",
    "comment": "Variant Scope, evidence blablabla"
}]

const clinical_evidence_columns = [
    {
        Header: <MappedText text='Gene'/>,
        accessor: "gene",
        filter: 'fuzzyText',
    },
    {
        Header: <MappedText text='variant'/>,
        accessor: "variant",
        filter: 'fuzzyText',
    },
    {
        Header: <MappedText text='Therapy'/>,
        accessor: "therapy",
        filter: 'fuzzyText',

    },
    {
        Header: <MappedText text='Interpretation'/>,
        accessor: "interpretation",
        filter: 'fuzzyText'
    },
    {
        Header: <MappedText text='Cancer Type'/>,
        accessor: "cancer_type",
        filter: 'fuzzyText'
    },
   /* {
        Header: <MappedText text='Comment'/>,
        accessor: "comment",
        filter: 'fuzzyText'
    }*/
]


export {clinical_evidence_columns,tagged_treatments_sample }