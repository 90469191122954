import React from 'react';
import {Paragraph, TextRun, HeadingLevel, Numbering, AlignmentType} from 'docx';
import {bodyText} from "../../config/template_config.js";
import { renderToString } from '../../../create-report/shared-components/renderToString.js';

// TODO: Ideally we should show this as bullet points..

const WriteReferences = (title, references) => {
    // Define a numbering instance for bullet points
    /*const numbering = {
        reference: 'bullet',
        levels: [
            {
                level: 0,
                format: 'bullet',
                text: '•',
                alignment: 'left',
                style: {
                    paragraph: {
                        indent: { left: 720, hanging: 260 },
                    },
                },
            },
        ],
    };

    // Create an array of TextRun components for references
    const referenceTextRuns = references.map((reference, index) => (
        new TextRun({
            text: reference,
        })
    ));*/
    const referenceParagraphs = references.map(reference => 
        new Paragraph({
            alignment: AlignmentType.JUSTIFIED,
            text: reference,
            spacing: { after: 120 }, 
        })
    );

    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("REFERENCES"),
                    size: 24,
                    color: '#000000',
                    bold: true,
                    heading: HeadingLevel.HEADING_2,
                    
                }),
            ]
        }),
        ...referenceParagraphs, 
    ];
};

export default WriteReferences;
