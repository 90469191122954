import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import GPAP_Tooltip from "./GPAP_Tooltip";


const useStyles = makeStyles((theme) => ({
    my_card: {
        color: "white",
        height: "120px",
        fontSize: "22px",
        textAlign:"start"
    },
    actionArea: {
        "&:hover": {
            backgroundColor: "orange"
        }
    },

}));

function GPAP_CardButton(props){

    const classes = useStyles();

    let val = (props.disable) ? "#d9d9d9" : props.color;
    return  <GPAP_Tooltip title={props.description}>
        <Card style={{backgroundColor: val}}
              className={classes.my_card}
              elevation={2}>
            <CardActionArea
                disabled={props.disable}
                classes={{
                    root: classes.actionArea,
                }}
                style={{height: "120px"}}
                onClick={() => {props.gpap_action(props.type)}}>
                <CardContent>
                    <Typography variant={"h6"}>{props.text}</Typography></CardContent>

            </CardActionArea>
        </Card>
    </GPAP_Tooltip>
}

export default GPAP_CardButton;