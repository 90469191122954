import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components";
export default function DateFilter({ setFilter }) {
  const valueDate = (date) => {
    const dateString = date.format("YYYY-MM-DD");
  
    setFilter(dateString);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerCustom onChange={valueDate} />
    </LocalizationProvider>
  );
}

const DatePickerCustom = styled (DatePicker)`

.MuiInputBase-root {
  font-size: 13px !important;
  height: 38px !important;
  
}
`;