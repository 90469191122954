import React from "react";
import {CLINICAL_DATA_HEADER} from "./config/clinical_data_header";
import CustomTable from "../../../general-table/components/tables/CustomTable";
import { useSelector } from "react-redux";
import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";

export default function ClinicalReportTable () {
    const token = useSelector(state => state.authorization.token);
    const { id } = useParams(); 
    const url = `${window.config.apis_configuration.api_nextgpap_server}/reports_by_dir/${id}`;
    const urlDownload = `${window.config.apis_configuration.api_nextgpap_server}`;

    return (
        <Grid container direction={"row"}>
            <Grid item lg={12}>
                <CustomTable
                    url= {url}
                    urlDownload= {urlDownload}
                    // urlBase = {urlBase}
                    // urlUpload= {urlUpload}
                    token={token}
                    locationData="clinical_reports"
                    dataHeader= {CLINICAL_DATA_HEADER}
                    // idRelation={"dir_id"}
                    // classRow= "Report_Clinical"
                    customFeatures= {[
                        { toggle: false },
                        { expanding: false },
                        { showHeader: false },
                        {
                            actions : {
                                downloadDoc: true,
                            },
                        },

                    ]}
                    buttons={[
                        {
                            nameButton: "Reports",
                        
                        },
                        {
                            nameButton: "Upload",
                        
                        },
                        
                    ]}
                />
            </Grid>
            </Grid>
            );
            };



