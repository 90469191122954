import React, { useEffect, useState } from "react";
import { axiosApi, preFilter, newColumns } from "../../utils";
import TableComponent from "./TableComponent";
import { useDispatch, useSelector } from "react-redux";
import useViewFeatures from "../../hooks/useViewFeatures";
import { setTable } from "../../../reducers/slices/tables/table.slice";
import FileUpload from "../../../scenes/instand/reports/create-report/shared-components/general-table/components/dialogs/FileUpload";
import { apiData } from "../../utils/apiData";
import { linkJoin } from "../../utils/linkJoin";

function CustomTable({
  classRow,
  dataApiExtended,
  url,
  urlUpload = null,
  urlBase,
  urlDownload,
  method,
  json,
  dataSend,
  configLink = [],
  dataFilterAdd = [],
  idRelation,
  locationData = false,
  addApiData = [],
  dataHeader = [],
  token,
  configFilter,
  nestedInfo = [],
  nestedInfoB = [],
  split = [],
  compactColumns,
  compactColumnsSplit,
  tooltipCompact,
  tooltipCompactSplit,
  customFeatures,
  chipFull = [],
  chipSmall = [],
  buttons,
  tableName,
  type = "toggle",
  participant, dataType,
  experiment,getTotal, setSelected,getAllTaggedVariants,all_tagged_variants
}) 

{
  const [data, setData] = useState([]);
  const [uniqueData, setUniqueData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataButton, setDataButton] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowIdSelected, setRowIdSelected] = useState(null);
  const [render, setRender] = useState(<h1>Test</h1>);
  const [resApi, setResApi] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [idExpanded, setIdExpanded] = useState({
    id: null,
    isExpanded: false,
  });
  const refresh = useSelector((state) => state.generaltable.refresh);
  const getUniqueRows = (data) => {
    const unique = new Map();
    data.forEach((item) => {
      const rowString = JSON.stringify(item);
      if (!unique.has(rowString)) {
        unique.set(rowString, item);
      }
    });
    return Array.from(unique.values());
  };
  const filterData = (data, dataFilter) => {
    const dataFiltered = data.filter((item) => {
      const matches = dataFiltered.every((filter) => {
        return item[filter.name] === filter.type;
      });
      return matches;
    });
    return dataFiltered;
  };
  

  const [testExpand, setTestExpand] = useState({});
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const dispatch = useDispatch();

  const states = {
    classRow,
    dataApiExtended,
    urlUpload,
    urlDownload,
    configLink,
    urlBase,
    mainData,
    dataFilterAdd,
    customFeatures,
    setMainData,
    data,
    resApi,
    addApiData,
    isCheck,
    setIsCheck,
    open,
    setOpen,
    render,
    setRender,
    idExpanded,
    setIdExpanded,
    isOpenPopover,
    setIsOpenPopover,
    dataButton,
    setDataButton,
    testExpand,
    setTestExpand,
    rowIdSelected,
    setRowIdSelected,
    dataFilter,
    setDataFilter,
    setSelected,
    getAllTaggedVariants,
    all_tagged_variants,  
    idRelation,
    isToggle,
    setIsToggle,
    participant,
    tableName,
  };


 useEffect(() => {
  dispatch(
    setTable({
      option: "allState",
      value: { mainData, data, customFeatures, addApiData },
    })
  );

  dispatch(
    setTable({
      option: "dataAdicional",
      value: mainData?.[0]?.infoAdicional,
    })
  );
}, [dispatch, data]);


const renderFeatures = useViewFeatures(states, customFeatures);


  useEffect(() => {


    const body = {
      "page":1,
      "pageSize":10000,
      "fields": [],
      "sorted":[

      ],
      "filtered":[
        {"id":"Experiment_ID", "value": experiment}

      ]
    }
    if (json) {
      setData(json);
      setMainData(json);
    } else {
    if(!initialized) {
      setInitialized(true);
      const options = {
        url,
        method,
        dataSend,
        dataFilterAdd,
        token,
        isCheck,
        locationData,
        split,
        dataHeader,
        nestedInfo,
        nestedInfoB,
        setData: (apiData) => {
       
          const uniqueRows = getUniqueRows(apiData); 
          setData(uniqueRows);
          setMainData(uniqueRows);
      },
        setMainData,
        setResApi,
        body,
        getTotal
      };

      axiosApi(options);
    }
  }
  }, [json, isCheck,experiment, refresh]);

  const nestedColumns = isCheck
    ? nestedInfoB.map((item) => {
        return {
          Header: item.nameColumn,
          accessor: item.dataSearch,
          tooltip: item.tooltip
        };
      })
    : nestedInfo.map((item) => {
        return {
          Header: item.nameColumn,
          accessor: item.dataSearch,
          tooltip: item.tooltip
        };
      });

  const selectArray = isCheck ? split : dataHeader;

  const [preFilteredData, setPreFilteredData] = useState([]);

useEffect(() => {
  let addData;
  const fetchData = async () => {
    if (dataApiExtended) {
      const response = await apiData(mainData, dataApiExtended);
      addData = response;
    } else {
      addData = mainData;
 
    }


  if (configFilter) {
    return preFilter(
      dataApiExtended ? addData : mainData,
      isCheck,
      configFilter,
      compactColumns,
      compactColumnsSplit,
      setPreFilteredData,
      setDataFilter
    );
  }
  if (dataFilterAdd.length > 0) {
    const addDataFilter = filterData(mainData, dataFilterAdd);
    addData = addDataFilter;  
  } 

  addData = linkJoin(addData, configLink);

  setPreFilteredData(addData);

};

fetchData();

}, [mainData, isCheck, configFilter]);

  useEffect(() => {
    let initialExpandState = {};

    preFilteredData.forEach((item, index) => {
      initialExpandState[index] = false;
    });
    
    setTestExpand(initialExpandState);
  }, [preFilteredData]);


  const columns = React.useMemo(
    () =>
      newColumns(
        selectArray,
        configFilter,
        isCheck,
        preFilteredData,
        compactColumns,
        compactColumnsSplit,
        tooltipCompact,
        tooltipCompactSplit
      ),
      
    [isCheck]
  );
  return (
<>
      <FileUpload />
      <TableComponent
        key={refresh} 
        columns={columns}
        data={ preFilteredData}
        nestedData={preFilteredData}
        type={type}
        nestedColumns={nestedColumns}
        buttons={buttons}
        states={states}
        renderFeatures={renderFeatures}
        tooltipCompact={tooltipCompact}
        chipFull={chipFull}
        chipSmall={chipSmall}
        dataType ={dataType}
      />
  </>
  );
}

export default CustomTable;
