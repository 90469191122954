import React from "react";
import DefaultSelect from "./DefaultSelect";
import { Autocomplete, TextField } from "@mui/material";

const OptionSelect = ({ optionType, options, setFilter }) => {
  const sortedOptions = [...options].sort((a, b) => {
    const getLabelValue = (label) => {
      if (Array.isArray(label) && label.length > 0) {
        return label[0];
      } else if (typeof label === 'string') {
        return label;
      }
      return '';
    };
  
    const labelA = getLabelValue(a.label);
    const labelB = getLabelValue(b.label);


    const numA = Number(labelA);
    const numB = Number(labelB);
  
    if (isNaN(numA) && isNaN(numB)) {
      return labelA.localeCompare(labelB);
    } else if (isNaN(numA)) {
      return 1;
    } else if (isNaN(numB)) {
      return -1;
    } else {
      return numA - numB;
    }
  });
  
  

  const defaultProps = {
    options: sortedOptions,
    getOptionLabel: (option) => {
      if (Array.isArray(option.label) && option.label.length > 0) {
        return option.label[0];
      } else if (typeof option.label === 'string') {
        return option.label;
      }
      return '';
    }
  };

  if (optionType === "default") {
    return (
      <Autocomplete
        {...defaultProps}
        id="auto-complete"
        size="small"
        autoComplete
        includeInputInList
        onChange={(event, newValue) => {
          setFilter(newValue?.label);
        }}
        renderInput={(params) => (

          <TextField {...params} variant="outlined" placeholder="Select"/>
          
        )}

       
      />
    );
  } else if (optionType === "multiple") {
    return <DefaultSelect setFilter={setFilter} options={sortedOptions} variant="outlined" />;
  }

  return <div>OptionSelect</div>;
};

export default OptionSelect;
