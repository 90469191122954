


import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {bindActionCreators} from "redux";
import * as Actions from "../actions";
import {connect} from "react-redux";
import { GPAP_Button } from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import BackupIcon from '@material-ui/icons/Backup';
import {AlertInfo} from "../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import DeleteIcon from "@material-ui/icons/Delete";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    geneListBreak: {
        paddingLeft: "5px",
        margin: "5px",
        maxWidth: "500px",
        wordWrap: "break-word"
    }
}));

function UploadGenes(props) {
    const classes = useStyles();

    let { operator, actions } = props;
    const [genes, setGenes] = useState([]);
    const [currentList, setCurrentList] = useState({genes:[]});


    useEffect(function(){

        let list_exists = props.applied_lists.find(s => s.id === "CSV");
        if(genes.length === 0 && list_exists!== undefined ){
            if(list_exists.genes.length !== currentList.genes.length){
                // add genes
                setGenes(list_exists.genes);
                if(operator=== "intersection"){
                    actions.intersect_genelist(list_exists.genes, "genes");
                }
                else{
                    actions.add_genes_to_specific_genelist(list_exists.genes, "genes");
                }
                setCurrentList(list_exists);
            }

        }
    })


    const handleGeneFile = (input) => {

        const my_gene_reader = new FileReader();
        my_gene_reader.onload = function(){
            let text;

            text = my_gene_reader.result.replace(/(\r\n|\n|\r)/gm,"");
            let geneList = text.split(",");
            setGenes(geneList);
            if(operator=== "intersection"){
                actions.intersect_genelist(geneList, "genes");
            }
            else{
                actions.add_genes_to_specific_genelist(geneList, "genes");
            }

            props.actions.reset_list_types("geneUpload");
            let list ={};
            list["type"] = "geneUpload";
            list["label"] = "CSV";
            list["id"] = "CSV";
            list["n_genes"] = geneList.length;
            list["genes"] = geneList;
            props.actions.add_applied_list(list);
        };
        my_gene_reader.readAsText(input.target.files[0]);

    };

    const clear = () => {

        props.actions.reset_list_types("Gene List Upload");
        actions.remove_genes_from_list(genes, "genes");
        setGenes([]);
    };

    return (
        <Box p={2}>
                <Typography variant={"body1"}><MappedText text={"Upload comma separated list of HGNC identifiers"}/></Typography>
                <Typography variant={"subtitle2"}><MappedText text={"(The file is not stored in GPAP)"}/></Typography>
            {(genes.length === 0)
                ? <div className={classes.root}>
                    <input
                        accept="text/*"
                        className={classes.input}
                        id="contained-gene-file"
                        multiple
                        type="file"
                        onChange={(input) => handleGeneFile(input)}
                    />
                    <label htmlFor="contained-gene-file">
                        <GPAP_Button variant="contained" color="primary" component="span" startIcon={<BackupIcon/>}>
                            <MappedText text={"Upload"}/>
                        </GPAP_Button>
                    </label>
                </div>
                : <Box>
                    <AlertInfo severity={"info"}
                               message={  <div className={classes.geneListBreak}>
                                   {genes.toString()}
                                   <IconButton onClick={clear}><DeleteIcon/></IconButton>
                               </div> }
                               title={"Genes Uploaded"}
                    />

                </Box>

            }
        </Box>
    );
}


function mapStateToProps (state) {
    if(state.form!==undefined)
    {
        let form = state.form;
        if(form.gene_section!== undefined && form.gene_section.values!== undefined){
            if(form.gene_section.values.predefined_list!== undefined)
                return {operator: state.geneList.operator, applied_lists: state.geneList.applied_lists };
            else{
                return {token: state.authorization.token, applied_lists: state.geneList.applied_lists }
            }
        }
        else{
            return {token: state.authorization.token}
        }
    }
    else{
        return {token: state.authorization.token}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(UploadGenes);