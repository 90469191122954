import React from "react";
import { Grid, Typography  } from "@mui/material";
import getTechnicalLimitations from "./technicalLimitationsConfig.js";


export default function TechnicalLimitations ({analysisType, experimentType}){
    const technicalLimitations = getTechnicalLimitations(analysisType);
    const limitationText = technicalLimitations[experimentType] || "";

    return (
        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography  variant="body1">
                     {limitationText}
                </Typography>
            </Grid>
        </Grid>
    )
}