import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import IconButton from "@material-ui/core/IconButton";
import {formatEffect, getAnnotationIcon} from "../cell_renderers";
import {getAnalysisAnnotations} from "../../getAnnotations";
import {getRow} from "../getHeaders";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import Link from "@material-ui/core/Link";
import GPAP_Tooltip from "../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import {HEADERS_TRANSCRIPTS} from "../../../../../../../../../../config-files/table-config/snv-table/transcript_config";
import makeStyles from "@material-ui/core/styles/makeStyles";
import getTranscriptLink from "./getTranscriptLink";


const useStyles = makeStyles(theme => ({
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#c6dbef"
        }
    },
    selected: {}

}));


function GPAP_Table_Row(props){

    let {row, index, handleClick, flagged, isItemSelected, labelId} = props;
    const [open, setOpen] = useState(false);

    const classes = useStyles();


    const renderTranscript = () => {

        let gene_id = (row.transcripts_all && row.transcripts_all.length > 0 && row.transcripts_all[0].gene_id) ? row.transcripts_all[0].gene_id : "NA";

        let rs = row.rs;
        let dbSNP_link = (rs!== undefined) ? "http://www.ncbi.nlm.nih.gov/snp/" + rs : "";

        let linkEl= (rs!== undefined) ? <a href={dbSNP_link} target={"_blank"}>{rs}</a> : "NA";


        return <TableRow key={"transcript_" + index}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Transcripts
                        </Typography>
                        <Typography variant="body1" gutterBottom component="div">
                            Gene ID: {gene_id}
                        </Typography>
                        <GPAP_Tooltip title={"dbSNP Reference SNP (rs or RefSNP) number is a locus accession for a variant type assigned by dbSNP"}>
                            <Typography variant="body1" gutterBottom component="div" style={{width:"150px"}}>
                                dbSNP: {linkEl}
                            </Typography>
                        </GPAP_Tooltip>
                        <Table key={"table_" + index} size="small" aria-label="transcripts" style={{ background: "rgb(234, 236, 247)" }}>
                            <TableHead>
                                <TableRow key={"cell_tr_sub" + index}>
                                    {HEADERS_TRANSCRIPTS.map((s,index) =>
                                    {
                                        return <TableCell align="left" key={"head_" + index}> <strong>{s} </strong></TableCell>
                                    })}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { (row.transcripts_all!== undefined) ? row.transcripts_all.map((transcriptRow, index) => (<TableRow key={transcriptRow.transcript_id + "_" + index}>
                                    <TableCell component="th" scope="row">
                                        <Link target="_blank"
                                              href={getTranscriptLink(transcriptRow, row)}>
                                            {transcriptRow.transcript_id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{transcriptRow.transcript_biotype}</TableCell>
                                    <TableCell>{transcriptRow.mane_select}</TableCell>
                                    <TableCell>{transcriptRow.mane_clinical_plus}</TableCell>
                                    <TableCell align="left">{transcriptRow.exon_rank}</TableCell>
                                    <TableCell align="left">
                                        {transcriptRow.functional_class}
                                    </TableCell>
                                    <TableCell align="left">
                                        <GPAP_Tooltip title={transcriptRow.amino_acid_length}><div className={"truncate"}> {transcriptRow.amino_acid_length} </div></GPAP_Tooltip>
                                    </TableCell>
                                    <TableCell align="left" >
                                        <GPAP_Tooltip title={transcriptRow.codon_change}>
                                            <div className={"truncate"}>{transcriptRow.codon_change}</div>
                                        </GPAP_Tooltip>
                                    </TableCell>
                                    <TableCell align="left">
                                        <GPAP_Tooltip title={transcriptRow.codon_change}>
                                            <div className={"truncate"}>
                                                <Link target="_blank"  href={"https://varseak.bio/ssp.php?gene=OR4F5&hgvs=" + transcriptRow.codon_change }>{transcriptRow.codon_change} </Link>
                                            </div>
                                        </GPAP_Tooltip>
                                    </TableCell>
                                    <TableCell align="left">
                                        <GPAP_Tooltip title={transcriptRow.amino_acid_change}>
                                            <div className={"truncate"}> {transcriptRow.amino_acid_change}</div>
                                        </GPAP_Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        { formatEffect(transcriptRow.effect_impact)}
                                    </TableCell>
                                    <TableCell align="left">
                                        <GPAP_Tooltip title={transcriptRow. effect}><div className={"truncate"}> {transcriptRow. effect} </div></GPAP_Tooltip>
                                    </TableCell>
                                </TableRow> )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>

    };

    return <React.Fragment>
        <TableRow
            hover
            onClick={(event) => handleClick(event, row.uniqueName, open)}
           /* onMouseEnter={(event) => handleHover(event, row.uniqueName)}*/
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={"row_" + index}
            classes={{ selected: classes.selected }}
            className={classes.tableRow}
            selected={isItemSelected}>
            <TableCell key={"cell_" + index}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>

            <TableCell padding="checkbox" key={"cell_cb" + index}>
                {getAnnotationIcon(row.uniqueName, getAnalysisAnnotations(props.studySettings, props.studyBucket),flagged)}
            </TableCell>
            {getRow(row, labelId, flagged, props.type, props.annotations_cols)}

        </TableRow>
        {renderTranscript()}
    </React.Fragment>

}


export default GPAP_Table_Row