import getCivicNumber from "./civic_data_config";


export  const linkFormatters = {
    "VarSome": (prefix, chrom, props, node) => `${prefix}${chrom}-${props.variant_current.pos}-${props.variant_current.ref}-${props.variant_current.alt}${node.url_appendix}`,
    "Franklin": (prefix, chrom, props, node) => `${prefix}${chrom}-${props.variant_current.pos}-${props.variant_current.ref}-${props.variant_current.alt}${node.url_appendix}`,
    "GA4GH beacon": (prefix, chrom, props, node) => `${prefix}${chrom}&pos=${props.variant_current.pos}&ref=${props.variant_current.ref}&allele=${props.variant_current.alt}${node.url_appendix}`,
    "UCSC": (prefix, chrom, props, node) => `${prefix}chr${props.variant_current.chrom}%3A${props.variant_current.pos}-${props.variant_current.pos}${node.url_appendix}`,
    "PubCaseFinder": (prefix, chrom, props, node) => {
        const string = [...new Set(props.hpos.map(h => h.id))].toString();
        return `${prefix}${string}/gene:HGNC:${props.variant_current.gene_name}/page:1%2C1/size:10%2C10`;
    },
    "gnomAD": (prefix, chrom, props, node) => node.input.length > 1 ? `${prefix}${chrom}-${Number(props.variant_current.pos) - 20}-${Number(props.variant_current.pos) + 20}${node.url_appendix}` : `${prefix}${props.variant_current[node.variable_category]}${node.url_appendix}`,
    "Ensembl": (prefix, chrom, props, node) => node.input.length > 1 ? `${prefix}${chrom}:${Number(props.variant_current.pos) - 10000}-${Number(props.variant_current.pos) + 10000}` : `${prefix}${props.variant_current[node.variable_category]}${node.url_appendix}`,
    "NCBI": (prefix, chrom, props, node) => `${prefix}${chrom}&from${props.variant_current.pos}`,
    "DGVa": (prefix, chrom, props, node) => `${prefix}${chrom}:${Number(props.variant_current.pos) - 10000}-${Number(props.variant_current.pos) + 10000}`,
    "GWASCentral": (prefix, chrom, props, node) => `${prefix}${chrom}:${Number(props.variant_current.pos) - 1000000}..${Number(props.variant_current.pos) + 1000000}`,
    "SpliceAI Lookup": (prefix, chrom, props, node) => `${prefix}chr${chrom}-${props.variant_current.pos}-${props.variant_current.ref}-${props.variant_current.alt}${node.url_appendix}`,
    "Trap Score": (prefix, chrom, props, node) => `${prefix}${chrom}-${props.variant_current.pos}-${props.variant_current.ref}-${props.variant_current.alt}`,
    "Regulation Spotter": () => {
        const add = props.variant_current.codon_change ? props.variant_current.codon_change.substr(props.variant_current.codon_change.length - 3) : undefined;
        return !props.variant_current.indel ? `${prefix}${chrom}:${props.variant_current.pos}${add}` : "Not applicable";
    },
    "dbSNP": (prefix, chrom, props, node) => `${prefix}${props.variant_current.rs}`,
    "Wintervar": (prefix, chrom, props, node) => `${prefix}${props.variant_current.chrom}&pos=${props.variant_current.pos}&ref=${props.variant_current.ref}&alt=${props.variant_current.alt}${node.url_appendix}`,
    "LOVD": (prefix, chrom, props, node) => {
        if (node.variable_category === "codon_change") {
            return `${prefix}${props.variant_current.codon_change}${node.url_appendix}`;
        } else if (node.variable_category === "gene_name") {
            return `${prefix}${props.variant_current.gene_name}`;
        }
    },
    "ClinGen Knowledge Repo": (prefix) => {return `${prefix}`},
    "CiVIC": (prefix, chrom, props, node) => {
        const geneName = props.variant_current.gene_name;
        const civicNumber = getCivicNumber(geneName);
        return civicNumber ? `${prefix}${civicNumber}${node.url_appendix}` : `${prefix}${geneName}${node.url_appendix}`;
    }
};


