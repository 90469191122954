

export const ADD_PATIENTS = 'ADD_PATIENTS';
export const RESET_PATIENTS = 'RESET_PATIENTS';
export const REMOVE_PATIENT = 'REMOVE_PATIENT';



export function add_patients(patients) {
    return {
        type: ADD_PATIENTS,
        patients: patients }
}


export function reset_patients(){
    return {
        type: RESET_PATIENTS
    }
}

export function remove_patient(participant_id){
    return {
        type: REMOVE_PATIENT,
        participant_id: participant_id
    }
}
