
import React, {Fragment} from "react";
import {getPercentage, gradient} from "../calculation";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import FlagIcon from '@material-ui/icons/Flag';
import "./row-style.css";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import Badge from "@material-ui/core/Badge";
import withStyles from "@material-ui/core/styles/withStyles";

import { scaleLinear } from "d3-scale";
import {significance_opts} from "../../toolbar/tag_dialog/tag_form_options";
import FlaggedBadge from "./FlaggedBadge";
import CancelIcon from "@material-ui/icons/Cancel";
import StarIcon from "@material-ui/icons/Star";
import getLabelIcon from "../../../../../../../../../gpap-lib/icons/GetLabelIcon";
import getGPAPLabelIcon from "../../../../../../../../../gpap-lib/icons/GetLabelIcon";



export function getSlidePopulation(value){

    // diverging array;
    let gradientArray = ['#d7191c','#fdae61','#1a9641'];

    let val;
    if(value === undefined){
        val = "NA"
    }
    else if(value === 0){
        val=parseFloat(value)
    }
    else{
        val=parseFloat(value).toFixed(5)
    }

    let bar = (value !== undefined) ?   <div
        style={{
            width: `${getPercentage(parseFloat(value),1)}%`,
            height: '10px',
            backgroundColor: gradient(parseFloat(value), 1, gradientArray),
            borderRadius: '2px',
            transition: 'all .2s ease-out',
            position: "relative"
        }}
    /> : null


    return (
        <div className="bar-background">
            {bar}
            { (val === "NA") ? <div className={"score_NA"}>{val}</div>
                             : <div className="score_text">{val}</div>
            }
        </div>
    )

}


export function getPredictorValue(value, predKey){

    if(value!== undefined && value!== ""){

        let colors_mt = {A: '#de2d26', D: '#fd8d3c', P: "#ffeda0", N: "#f0f0f0"};
        let colors_pp2 = { D: '#fd8d3c', P: "#ffeda0", B: '#31a354'};
        let colors_sift = {D: '#de2d26', T: '#fd8d3c',};


        let mt_keys = {
            "A": "Annotated as disease causing",
            "D": "Disease Causing",
            "P": "Polymorphism",
            "N": "Neutral"
        };

        let pp2 = {
            "D": "Probably damaging",
            "P": "Possibly damaging",
            "B": "Benign"
        };

        let sift = {
            "D": "Damaging",
            "T": "Tolerated",
        };

        let keys = {
            "sift_pred": sift,
            "polyphen2_hvar_pred": pp2,
            "mutationtaster_pred": mt_keys };

        let colors = {
            "sift_pred": colors_sift,
            "polyphen2_hvar_pred": colors_pp2,
            "mutationtaster_pred": colors_mt };


        let found = keys[predKey];
        let assignCol = colors[predKey];

        let title, color;

        if(predKey === "cadd_phred"){
            let linearScale = scaleLinear()
                .domain([0,20,50])
                .range(['#ffeda0','#feb24c','#f03b20']);

            let cadd_color = linearScale(value);
            color = (value!== undefined) ? cadd_color  : "";
            title = "CADD Pred."

            if(value < 20){
                value= "<20";
            }

        }
        else if(found!== undefined){
            //let vals = keys[predKey];
            title = found[value];
            color = assignCol[value];
        }

            return <Fragment>
                <GPAP_Tooltip title={title}>
                <div style={{
                    background: color,
                    fontWeight: "500",
                    borderRadius: "4px",
                    padding: "4px"
                }}

                >{value}</div>
                </GPAP_Tooltip>
            </Fragment>

    }
    else{
        return <div style={{color: "#aaaaaa"}}>NA</div>
    }

}

export function formatEffect(value){

    let gradientArray = {"HIGH": '#de2d26', "MODERATE": '#fd8d3c', "LOW": '#31a354', "MODIFIER": "#3182bd"};

    let val = gradientArray[value];
    if(val!== undefined){

        return <div style={{background: val, color: "white", fontWeight: "500", padding: "2px",
            borderRadius: "4px" }}>{value}</div>
    }
    else{

        return <div className={"effect_style"} >{value}</div>
    }

}

export function colorCodeClinVar(value, sep){

    let color;


    let split = (value!== undefined) ? value.split(sep) : "NA";
    let tooltip_text;

    if(Array.isArray(split)){
        let items = split.map(function(item){

            let item_s = significance_opts.find(s=> s.id === item);
            if(item_s!== undefined){
                return item_s.label;
            }
            else{ return ""}
        })

        tooltip_text = items.filter(s => s!== "").toString();
    }
    else{
        tooltip_text = value;
    }




    if(split.indexOf('LP')>-1){
        color = "#de2d26";
    }
    else if(split.indexOf('P') > -1){
        color = "#b10026";

    }
    else if(split.indexOf('C')>-1){
        tooltip_text="Conflicting";
        color = "#fd8d3c";
    }
    else if(split.indexOf('VUS') > -1){
        tooltip_text = "Variant of Uncertain Significance";
        color= "#3182bd"
    }
    else if(split.includes("LB") || split.includes("B") || split.includes("LB|B")){
        color= "green";
    }
    else if(split.indexOf("NA")){
        color = "#aaaaaa";
    }


    if(split!== "NA"){
        return <GPAP_Tooltip title={tooltip_text}>
            <div style={{background: color, color: "white"}} className={"effect_style"}>
                {value}
            </div>
        </GPAP_Tooltip>
    }
    else{
        return <div style={{color: "rgb(170, 170, 170)"}}
                    //className={"effect_style"}
        >
            NA
        </div>
    }

}

export function getFloatingBar(value) {

    let gradientArray = ['#bae4b3', '#74c476', '#238b45'];
    return (
        <div className="bar-background">
            <div
                style={{
                    width: `${getPercentage(parseInt(value),1)}%`,
                    height: '100%',
                    backgroundColor: gradient(parseInt(value), 1, gradientArray),
                    borderRadius: '2px',
                    transition: 'all .2s ease-out',
                    position: "relative"
                }}
            />
            <div className="score_text">
                {parseInt(value)}
            </div>
        </div>
    )
}




export function getAnnotationIcon(uniqueName, annotations, taggedVariants){

    let { labelled, tagged } = annotations;

    let keys = Object.keys(labelled);

    // check in tagged variants;

    let index = tagged.filter(tag => tag.uniqueName === uniqueName);

    let no_tag = true;

    if(index.length!== 0 && taggedVariants.length === 0)
    {
        no_tag = false;
        return <FlagIcon style={{ color: "#3182bd" }}/>
    }
    else{
        if(taggedVariants!== undefined )
        {

            // a different color for the Causative Variant could be added in the future.
            if(taggedVariants && taggedVariants.length!==0){
                //no_tag = false;
                let NUMBER = taggedVariants.length;

                let hasCausative = taggedVariants.find(s => s.status === "Causative");

                let color = "#3182bd"
                if(hasCausative!== undefined){
                    color= "red";
                }

                let html = taggedVariants.map(variant => {return <span><p>{variant.sample} with significance {variant.significance} </p></span>});

                return <GPAP_Tooltip  title={<Fragment>
                        <span><p>This variant has been tagged {NUMBER} times </p></span>
                        {html}
                    </Fragment>} ><FlaggedBadge badgeContent={NUMBER} ><FlagIcon style={{ color: color }}/></FlaggedBadge></GPAP_Tooltip>
            }
            /*else if(tagBool === 0){
                no_tag = false;
                return <Fragment><GPAP_Tooltip title={"Causative"}><FlagIcon style={{ color: "red" }}/></GPAP_Tooltip></Fragment>
            }*/

        }

    }

    // if tag doesn-t exist, show the label... to discuss
    if(no_tag){
        // check in labelled variants;
        let label = "";
        keys.forEach(function(key){
            let uniqueNames = labelled[key].map(s => s.uniqueName);
            if(uniqueNames.includes(uniqueName)){
                label = key;
            }
        });


        const labelIcon = getGPAPLabelIcon(label);

        if (labelIcon) {
            return (
                <GPAP_Tooltip title={label}>
                    {React.cloneElement(labelIcon, { style: { color: "#3182bd" } })}
                </GPAP_Tooltip>
            );
        } else {
            return null;
        }
    }


}

  