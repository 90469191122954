
import React from "react";
import {GPAP_Button, GPAP_Button_light} from "../../gpap-lib/components/Button_Collection";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";



function PlaygroundVersion(){


    if (window.config.config_keycloak.realm === "playground"){

        return   <Grid item>
            <Box p={1}>
            <GPAP_Button_light href={"#/playground_cases"} target={"_blank"}> Playground Solutions</GPAP_Button_light>
        </Box>
        </Grid>

    }
    else{
        return null;
    }



}


export default PlaygroundVersion