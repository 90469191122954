




const messages = {
    message_1: "No Experiments for this Participant",
    message_2: "There is no participant associated to this experiment",
    message_3: "No Family Found",
    message_4: "Experiments can't be collected.",
    message_5: "Server Error",
    message_6: "No family associated to the selected participant/experiment",
    message_7: "Several participants have multiple experiments. Please review the experiments and adjust the selection if necessary.",
    message_8: "No visible participant",
    message_9: "ENOD Service Error",
    message_10: "Unable to automatically build the family, as you are not a member of the group to which the index case of the family is assigned. Please disable the 'Automatically add family members' checkbox and manually add individuals, or request that the owner shares the index case with you."
}


export default messages