import React from "react";
import MappedText from "../../../shared-components/mapText";

export const GENETIC_FINDINGS_CNV = [
    {
        Header: <MappedText text="Genomic breakpoints detected"/>,
        accessor: "region",
        filter: "fuzzyText",
    },
    {
        Header: <MappedText text='Clinically relevant gene'/>,
        accessor: "associated_genes",
        maxWidth: 10,
        filter: "fuzzyText",
    },
    {
        Header: <MappedText text="Origin"/>,
        accessor: "origin",
        filter: "fuzzyText",
    },
    {
        Header: <MappedText text='Clinical significance**'/>,
        accessor: "significance",
        filter: 'fuzzyText',
    }, 
    {
        Header: <MappedText text='Zygosity'/>,
        accessor: "zygosity",
        filter: 'fuzzyText',
    },
    {
        Header: <MappedText text='Protein'/>,
        accessor: "protein",
        filter: 'fuzzyText',
    },
    
];
