

import React, {useEffect, useState} from "react";
import {getHpsCreg} from "../../../../../../../../../services/api/variant_external_calls";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import Divider from "@material-ui/core/Divider";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {GPAP_Button} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import DialogContentText from "@material-ui/core/DialogContentText";
import GeneralTable from "../../../../../../../../../gpap-lib/components/general-table/GeneralTable";
import {hpscreg_columns} from "./hspcreg/columns";
import Button from "@material-ui/core/Button";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function HpsCreg(props){


    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);


    useEffect(function(){

        let gene = props.input;

        let payload= "query=PREFIX%20rdfs%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F2000%2F01%2Frdf-schema%23%3E%0APREFIX%20obo%3A%20%3Chttp%3A%2F%2Fpurl.obolibrary.org%2Fobo%2F%3E%0APREFIX%20owl%3A%20%3Chttp%3A%2F%2Fwww.w3.org%2F2002%2F07%2Fowl%23%3E%0APREFIX%20hpscreg%3A%20%3Chttp%3A%2F%2Fpurl.obolibrary.org%2Fobo%2Fhpscreg%2F%3E%0A%0ASELECT%20DISTINCT%20%3Fcell_line%20%3Fcell_line_name%20%3Fhpscreg_url%20%3Fdisease_name%20%3Fprovider%20%3Fgene_name%20%7B%20%20%20%20%0A%20%20%20%20%23%20Input%20gene%20name%0A%20%20%20%20VALUES%20%3Fgene_name%20%7B%22" + gene + "%22%7D%0A%20%20%20%0A%20%20%20%20%23%20GET%20cell%20line%20and%20annotations%0A%20%20%20%20%3Fcell_line%20rdfs%3AsubClassOf%20%3Fgene_annotation%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20rdfs%3AsubClassOf%20%3Fdisease_annotation%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20rdfs%3Alabel%20%3Fcell_line_name%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20rdfs%3Asource%20%3Fhpscreg_url%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20hpscreg%3Agenerator%20%3Fprovider.%0A%20%20%20%0A%20%20%20%20%23%20GET%20gene%20annotation%0A%20%20%20%20%3Fgene_annotation%20owl%3AintersectionOf%20%5B%20%3Fp%20%3Fgene_annotation_node%5D.%0A%20%20%20%0A%20%20%20%20%23%20FILTER%20on%20gene_name%0A%20%20%20%20%3Fgene_annotation_node%20owl%3AonProperty%20obo%3ARO_0002292%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20owl%3AsomeValuesFrom%20%5Brdfs%3Alabel%20%3Fgene_name%20%5D.%0A%20%20%20%0A%20%20%20%20%23%20GET%20donor%20disease%20annotation%0A%20%20%20%20%3Fdisease_annotation%20owl%3AonProperty%20obo%3ACLO_0000015%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20owl%3AsomeValuesFrom%20%3Fdisease_uri.%20%20%20%20%0A%20%20%20%20%3Fdisease_uri%20rdfs%3Alabel%20%3Fdisease_name.%0A%20%20%20%0A%7D%20ORDER%20BY%20%3Fcell_line"

        setLoading(true);
        getHpsCreg(payload)
            .then(r => r.json())
            .then(function(json){

                setLoading(false);

                // parse data;

                if(json.results.bindings !== undefined){

                    let keys = json.head.vars;

                    let objects = json.results.bindings.map(function(el){

                        keys.forEach(function(keyItem){
                            el[keyItem] = el[keyItem].value;

                        })

                        return el;
                    })
                    setData(objects);

                }



        })





    }, [props.input])

    const renderData = () => {

        let text = "Open Dialog to see the results";

        if(!props.input){
            text = "Select a variant to query hPSCreg results";
        }
        else if(data.length === 0 ){
            text= "No data available for this gene"
        }


        return <div>
            <Box p={3}>
                    <Typography variant={"subtitle2"} align={"center"}>{text}</Typography>
                    <GPAP_Button disabled= { data.length === 0 || !props.input }
                                 onClick={() => setOpen(true)}>See results for {props.input}</GPAP_Button>

            </Box>
        </div>

    }

    const handleClose = () => {

        setOpen(false);

    }

    const renderDialog = () => {

        return <Dialog
            maxWidth={"xl"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"hPSCreg results"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Provided by <img src={"https://hpscreg.eu/assets/img/logo.svg"} style={{width: "50px"}}/>
                    <Divider/>
                    hPSCreg is a global registry for human pluripotent stem cell lines (hPSC-lines). It aims to provide to the community
                    To know more about hPSCreg, please visit <a href={"https://hpscreg.eu/"} target={"_blank"}>https://hpscreg.eu/</a>.

                </DialogContentText>

                <GeneralTable data={ data } columns={hpscreg_columns}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    }



    return <Grid item lg={12}>
        {renderDialog()}
        <div className={"my_paper_box"}>
            <Box p={1}>
                <Typography variant={"body1"}>hPSCreg <GPAP_InfoIcon style={{float: "right"}} title= <MappedText text={"Human Pluripotent Stem Cell Registry"}/> /></Typography>
                <Divider/>
                <div className={"boxResults"}>
                    {(loading)
                        ? <Box p={2}><CircularProgress/></Box>
                        : renderData()
                    }
                </div>
            </Box>
        </div>
    </Grid>




}

export default HpsCreg;