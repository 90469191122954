import { Box, Button, Checkbox, Dialog, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ViewColumn } from '@mui/icons-material';
import MappedText from '../../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useNavigate from '../../hooks/useNavigate';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setTable } from '../../../reducers/slices/tables/table.slice';


const ButtonGroup = ({  
  allColumns,
  getToggleHideAllColumnsProps,
  isClinicalReportsView,
  buttons,
  states,
  renderFeatures,
}) => {
  useEffect(() => {
    if (states.tableName === "dirs") {
    const hideColumn = [
      'tumor_experiment_id',
      'control_experiment_id',
      'creation_date',
      'creator',
      'experiment_id',
      'clinical_referrer',
      'clinical_referrer_contact',
      'hospital_name'
    ];
    
    hideColumn.forEach(column => {
      const columnToHide = allColumns.find(c => c.id === column);
      if (columnToHide) {
        columnToHide.toggleHidden(true);
        columnToHide.getToggleHiddenProps().disabled = true;
      }
    });
  }
}, [allColumns, states.tableName]); 

  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const showColumnsButton = states.customFeatures.some(feature => feature.columnsButton === true);


  const validButtons = Array.isArray(buttons) ? buttons : [];

  const reportsButtons = validButtons.filter(button => 
    button.nameButton === "Reports" || button.nameButton === "Upload"
  );

  const popoverButtons = validButtons.filter(button => 
    button.type === "popover" && states.rowIdSelected
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '3px', width: '100%' }}>
        {showColumnsButton && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '3px', width: '100%' }}>
          <Box display={'flex'} gap={'10px'}>
            <Button
              style={{ margin: '0 10px' }}
              variant='outlined'
              onClick={handleDialogOpen}
              aria-controls='column-hiding'
              aria-haspopup='true'
              startIcon={<ViewColumn />}
            >
              <MappedText text={'Columns'} />
            </Button>
            {renderFeatures}
          </Box>
        </div>
        )}
       <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '10px' }}>
    
        {validButtons.some(button => button.nameButton === 'Upload') && (
          <Button
            variant='outlined'
            startIcon={<CloudUploadIcon />} 
            onClick={() => dispatch(setTable({ option: 'isOpenUpload', value: true }))}
          >
            <MappedText text="Upload Report"/>
          </Button>
        )}
        

        {validButtons.some(button => button.nameButton === 'Reports') && (
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => navigate(`/dir_overview/${id}/new_clinical_report`)}
          >
            <MappedText text="Create report"/>
          </Button>
        )}
      </div>
        {popoverButtons.map(button => (
          <BasicPopover
            key={button.nameButton}
            states={states}
            text={button.nameButton}
            render={button.template}
            data={button.data}
          />
        ))}
      </div>
      <Dialog onClose={handleDialogClose} open={dialogOpen}>

      <DialogTitle style={{ textAlign: 'center', marginTop: '30px', marginBottom: '-20px' }}>Column Hiding</DialogTitle>
      <Box id="column-hiding" my={2}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Checkbox {...getToggleHideAllColumnsProps()} indeterminate style={{marginBottom: '20px' }}  />
      <Typography style={{ fontWeight: 'bold', marginBottom: '20px',fontFamily: 'Roboto!important' }}>Toggle All</Typography>
      </Box>
      <Box display="flex" flexWrap="wrap">

      {allColumns.map((column, index) => {
      if (index === 0 || index === allColumns.length - 1) {
        return null;
      }
      const displayName = column.Header || (column.id && column.id.charAt(0).toUpperCase() + column.id.slice(1));

      if (!displayName) {
      return null;
      }

      return (

      <Box key={column.id} flex="1" minWidth="50%">
      <Typography style={{ fontFamily: 'Roboto!important' }}>
      <Checkbox {...column.getToggleHiddenProps()} />
      {displayName}
      </Typography>
      </Box>
      );
      })}
      </Box>
      </Box>
      </Dialog>

</>

  );
};
export default ButtonGroup;
