
import React, {Component, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import "./general-query-view.css";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../../../reducers/study_actions";
import inheritances_keys from "../../../side-panels/study-store/components/list-material-ui/inheritance_list";
import StudyInfoEdit from "./StudyInfoEdit";
import QueryNameEdit from "./QueryNameEdit";
import Box from "@material-ui/core/Box";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


 function GeneralQueryView(props) {

    const classes = useStyles();

    const { studyBucket, studySettings } = props;


    //get the studyName

     let currentStudy = studyBucket.studies.find(study => study.localId === studySettings.id);

     let current_analysis = currentStudy.analyses.find(analysis=> analysis.localId === studySettings.current_analysis);
     let inheritanceName = "";
     let queryName = "";
     if(current_analysis!== undefined){
         let inheritanceMode = current_analysis.inheritance_mode_id;
         inheritanceName = inheritances_keys.find(key => key.key === inheritanceMode).label;
         let currentQuery = current_analysis.queries.find(query => query.localId === studySettings.current_query);
         queryName = (currentQuery!== undefined) ? currentQuery.name : <MappedText text="Query"/>;
     }


    return(
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <StudyInfoEdit
                        studyName = {currentStudy.studyName}
                        studyID = {studySettings.id}
                        analysisName = {inheritanceName}
                        queryName={queryName}
                        variants_n = { props.variants_n }
                        queryRunner={props.queryRunner}
                    />

                </Grid>
            </Grid>
        </div>)

}



function mapStateToProps (state) {

    if(state.authorization!==undefined)
    {

        return { studySettings: state.studySettings, studyBucket: state.studyBucket}


    }

}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(GeneralQueryView);


