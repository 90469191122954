
//

import {test_espanol} from "./test_espanol";
import {italiano} from "./italiano";
import {deutsch} from "./deutsch"

export const translation_dic = {
    "en": {
    // i18next uses the `translation` namespace by default.
    translation: {
            logo: "logo",
            demo: "This demo app was internationalized by i18next",
            "Cohort Analysis": "Cohort Analysis",
            "Case Analysis": "Case Analysis",
            // Interpolated datetime
            now: "Current date and time are {{currentDateTime, datetime}}",
    },
},
    "ar-EG": {
    translation: {
        logo: "رمز التطبيق",
            demo: "تم تدويل هذا التطبيق التجريبي بواسطة آي ايتين نكست",
            "Cohort Analysis": "Cohort Analysis",
            "Case Analysis": "Case Analysis",
            now: "التاريخ والوقت الحاليان هما {{currentDateTime, datetime}}",
    },
},
        "es": {
            translation: test_espanol
        },
        "it": {
            translation: italiano
        },
        "de": {
            translation: deutsch
        },
        "ca": {
        translation: {
            "Select type of analysis": "Selecciona el tipus d'anàlisi",
                "Cohort Analysis": "Anàlisi de cohort",
                "Case Analysis": "Anàlisi de cas",
                "Analyse a singleton or a family": "Analitza un individu o una família",
                "Clinical Report": "Informe clínic",
                "Search Across All Experiments": "Cerca a través de tots els experiments",
                "Patient Matchmaking": "Emparellament de pacients"
        }
}

}