




import React, {useEffect, useState} from 'react';
import {initialize, reduxForm} from 'redux-form';
import "../../../css/query-view.css";
import { connect } from 'react-redux'
import GeneList from "./components/genes-list/GeneList";
import * as Actions from "./../actions";
import {bindActionCreators} from "redux";
import {GPAP_Button_light} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import GeneFilteringAccordion from "./GeneFilteringAccordion";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {CircularProgress} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import OperatorChoice from "./OperatorChoice";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


function Gene_Section_View(props){

    const { handleSubmit, pristine, reset, submitting } = props;
    let {diseaseGene, symptomsGene, genes, pathways, total_gene_list,predefined_gene_lists } = props;

    const [selectedOption, setOperator] = useState(props.geneList_operator ?? "union");
    const { actions } = props;

    if(props.geneList_operator === "intersection"){
        actions.setGeneOperation("intersection");
    }

    const [resetForm, setResetForm] = useState(false);

// Run setGeneOperation once during component initialization if the operator is 'intersection'
    useEffect(() => {
        if (props.geneList_operator === "intersection") {
            actions.setGeneOperation("intersection");
        }
    }, []);

// Update gene operation when selectedOption changes
    useEffect(() => {
        actions.setGeneOperation(selectedOption);
    }, [selectedOption]);

    const handleOptionChange = (changeEvent) => {

        setOperator(changeEvent.target.value);
        actions.setGeneOperation(changeEvent.target.value);
    };

    const resetList = () => {

        // TODO: now the predefined gene list are in the redux. it's not really needed, and can be refractored later.
        props.dispatch(initialize(
            'gene_section',
            {},
        ));

    }


    const resetAll = () => {

        let { actions, reset } = props;
        actions.resetGenes([]);
        actions.reset_geneList("symptoms");
        reset('gene_section');
        setResetForm(true);
    };


    const renderCheckBoxes = () => {

            return  <Box p={1} style={{ float: "left", width: "100%"}}>
                <Grid container direction={"row"}>
                    <Grid item lg={5}>
                        <OperatorChoice
                            selectedOption={selectedOption}
                            handleOptionChange = {handleOptionChange}/>
                    </Grid>
                    <Grid item lg={5}>
                            <div style={{width: "100%", float: "left"}}>
                                <GPAP_Button_light
                                    size={"small"}
                                    style={{float:"right"}}
                                    onClick={resetAll}
                                    disabled={(props.total_gene_list === 0)}
                                    startIcon={<ClearIcon/>}>
                                    <MappedText text={"Clear gene list"}/>
                                </GPAP_Button_light>
                            </div>
                    </Grid>
                </Grid>
            </Box>
    };


    const renderForm =  () => {


        if(resetForm){

            setTimeout(() => { setResetForm(false)}, 1000);
            return <div style={{width: "600px", height: "500px"}}>
                <CircularProgress/>
            </div>

        }
        else{
            return  <form onSubmit={handleSubmit}>
                <div className={"form_container"}>
                    <GeneFilteringAccordion
                        by_gene_list={genes}
                        by_disease={diseaseGene}
                        by_symptoms={symptomsGene}
                        by_pathways={pathways}
                        by_predefined_list = {predefined_gene_lists}
                        resetList={ resetList }
                    />
                </div>
            </form>

        }



    }


    return (<div className={"form_section"}>
            {renderCheckBoxes()}
            {renderForm()}
            <GeneList/>
        </div>
    )
}



export function map_genes (state) {
    if(state.geneList!==undefined)
    {
        return {
            total_gene_list: state.geneList.geneList.length,
            diseaseGene: state.geneList.disease.length,
            symptomsGene: state.geneList.symptoms.length,
            genes:state.geneList.genes.length,
            geneList_operator: state.geneList.operator,
            predefined_gene_lists: state.geneList.predefined_genes.length,
            pathways:state.geneList.pathways.length }
    }
    else{
        return {diseaseGene: [], symptomsGene: [], genes:[], pathways:[] }
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});

Gene_Section_View = connect(
    map_genes,
    mapDispatchToProps
)(Gene_Section_View);


export default reduxForm({
    form: 'gene_section', // a unique identifier for this form,
    initialValues: {},
    destroyOnUnmount: true,
})(Gene_Section_View)


