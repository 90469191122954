

import React from "react";
import GenomeCoverage from '../components/GenomeCoverageTable.js';
import GenotypePhenotype from '../components/GenotypePhenotypeTable.js'
import VariantMetrics from '../components/VariantMetricsTable.js';
import Pharmacogenomics from '../components/Pharmacogenomics.js';
import CoverageGenes from '../components/CoverageGenes.js';
import { useSelector } from 'react-redux';
import MappedText from "../../../../shared-components/mapText.js";

export function sectionConfigGermline(annexesData, clinicalReportName, setClinicalReportName, additionalData) {
    const { allDataApis } = useSelector((state) => state.generaltable);
    return [
    {
        title: 'The following annexes complete the report, which must be evaluated in its entirety',
    },
    {
        title: 'Annex 1: Clinical record',
        columns:1,
        fields: [
            
            { title: 'Cancer Type', value: allDataApis?.participants?.[0].cancer_type  },
            { title: <MappedText text='Family History'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName },
        ],
    },
    {
        title: 'Annex 2: Methodology',
        fields: [
            { 
                title: 'Test method', 
                value: `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` 
              },
              { title: 'Type of variants assessed', value: allDataApis?.type_of_variants_assessed  },//Pending
              { title: 'Sample type', value: allDataApis?.samples?.sample_type  },
              { title: <MappedText text='Sample received'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName  },//Pending
              { title: 'Date of sample collection', value: allDataApis?.samples?.date_of_sample_collection },
        ],
    },
    {
        subTitle: 'Sample processing',
        fields: [
            { title: 'Centre', value: allDataApis?.centre },//Pending
                { title: 'Sequencing platform', value: allDataApis?.sequencing_platform },//Pending
                { title: 'Fragment (size and type)', value: allDataApis?.fragment },//Pending
            
        ],
    },
    {
        subTitle: 'Bioinformatic analysis',
        fields: [
            { title: 'Human reference genome', value: allDataApis?.human_reference_genome },//Pending
            { title: 'Aligner', value: allDataApis?.aligner },//Pending
            { title: 'Mark duplicates', value: allDataApis?.mark_duplicates},//Pending
            { title: 'Base recalibration', value: allDataApis?.base_recalibration },//Pending
            { title: 'Smart variants caller', value: allDataApis?.smart_variants_caller },//Pending
            { title: 'Analysis pipeline', value: allDataApis?.analysis_pipeline},//Pending
        ],
    },
    {
        title: 'Annex 3 : Quality control metrics',
       
    },
    {
        subTitle: 'Genome coverage metrics',
        render: () => <GenomeCoverage data={additionalData.genomeCoverageData} />
    },
    {
        paragraph: 'C10/C15/C20: percentage of bases covered by at least 10,15 and 20 reads, respectively.',
       
    },
    {
        fields: [
            { title: <MappedText text='List of diagnostically targeted genes'/>, component: 'TextField', value: clinicalReportName, setValue: setClinicalReportName },
        ],
    },
    {
        subTitle: 'Coverage of the genes of interest (RefSeq coding sections)',
    },
    {
        paragraph: 'These genes are the one that do NOT fit the coverage threshold (90%).',
       
    },

    {
        
        render: () => <CoverageGenes data={additionalData.coverageGenesData} />
    },
    
    {
        title: 'Annex 4 : Variant metrics and genotype-phenotype associations',
       
    },
    {
        subTitle: 'Variant metrics',
        render: () => <VariantMetrics data={additionalData.variantMetricsData} />
    },
    {
        paragraph: 'GT: genotype; GQ: genotype quality; DP: depth of coverage (total reads); REF count: reads supporting reference allele; ALT count: reads supporting alternative allele; AAF: alternative allele frequency',
       
    },
    
    {
        subTitle: 'Genotype-phenotype associations',
        render: () => <GenotypePhenotype data={additionalData.genotypePhenotypeData} />
    },
    {
        title: 'Pharmacogenomics',
       
    },
    {
        paragraph: 'These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report.',
       
    },
    {
        render: () => <Pharmacogenomics data={additionalData.pharmacogenomicsData} />
    },
    {
        subTitle: 'Pharmacogenomics interpretation',
    },
    {
        paragraph: 'These are the interpretation comments that were written in each haplotype tag. The original tag comment will not be modified here. Your modifications will only be visible in the exported report.',
       
    },
    
]

}