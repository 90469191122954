import {get_alfa, get_edgar} from "../../../../../../../../../services/api/variant_external_calls";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React, {useEffect, useState} from "react";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function EdgarDB(props){

    const [data, setData] = useState(false);


    useEffect(async function () {

        const L = props.selected_variant.length;
        let variant = props.selected_variant[L - 1];

        let {gene_name} = variant;

        //EDGAR (make a separate component?)
        let edgar = await get_edgar(gene_name).then(r => r.text());

        setData(edgar);

    }, [props.selected_variant])


    return     <div className={"my_paper_box"}>
        <Box p={1}>
            <Typography variant={"subtitle1"}>eDGAR - Disease-Gene associations</Typography>
            <div> {(data && data.indexOf("True") !== -1)
                ?  <Link target="_blank" href={(data.split(",")[0].split('url')[1].split(" ")[1].replace("'","").slice(0,-1))}>eDGAR Association</Link>
                : <MappedText text="No Associations"/>}
            </div>
        </Box>
    </div>





}

function mapStateToProps (state) {
    if(state.selected_variant!==undefined)
    {
        return {
            token: state.authorization.token,
            selected_variant: state.selected_variant,
            patients: state.patients}
    }
    else{
        return {snv: []}
    }
}

export default connect(mapStateToProps,null)(EdgarDB);